import React from 'react'

import { InvestmentListing } from '@guiker/investment-shared'
import { PropertySaleListingCard } from '@guiker/property-sale-components'
import { PropSharingListingCard } from '@guiker/propsharing-components'
import { useLayoutContext } from '@guiker/react-framework'
import { InfiniteList, InfiniteListProps, PaginatedGrid } from '@guiker/react-query-components'

type InvestmentListingGridProps = Omit<InfiniteListProps<InvestmentListing>, 'CellComponent'>

export const InvestmentListingGrid: React.FC<InvestmentListingGridProps> = (props) => {
  const { isMobile } = useLayoutContext()

  const CellComponent = ({ data }) => {
    if (data.type === 'PropertySaleListing') {
      return <PropertySaleListingCard listing={data} shadow={true} />
    }

    return <PropSharingListingCard listing={data} shadow={true} />
  }

  if (isMobile) {
    return <InfiniteList CellComponent={CellComponent} {...props} />
  }
  return <PaginatedGrid {...props} cacheKey={props.queryKey} CellComponent={CellComponent} />
}
