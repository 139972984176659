import React, { useState } from 'react'

import { useQuery } from '@guiker/react-query'
import { useNavigate } from '@guiker/router'

import { Box, FeedbackComponent, H4, Link, P, PageLayout, StepperLayout } from '../../components'
import { BackgroundCheckFormProvider } from '../../context'
import { useBackgroundCheckApiClient } from '../../hooks/use-background-check-api-client'
import { Trans, useTranslation } from '../../i18n'
import { Step01 } from '../Step01'
import { Step02 } from '../Step02'

const steps = [
  {
    name: 'step01',
    component: Step01,
  },
  {
    name: 'step02',
    component: Step02,
  },
]

const RequestBackgroundCheck: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [hasCreditsLeft, setHasCreditsLeft] = useState<boolean>()
  const apiClient = useBackgroundCheckApiClient()

  useQuery('backgroundCheck-getCredits', () => apiClient.readOrCreateBackgroundCheckProfile(), {
    onSuccess: (data) => {
      setHasCreditsLeft(data.credits > 0)
    },
  })

  if (hasCreditsLeft === false) {
    return (
      <PageLayout maxWidth={900}>
        <Box p={3}>
          <H4>{t('request:noCredits')}</H4>
          <P>
            <Trans i18nKey='request:contactSales'>
              <Link to='mailto:sales@guiker.com' />
            </Trans>
          </P>
        </Box>
      </PageLayout>
    )
  } else if (hasCreditsLeft === true) {
    return (
      <BackgroundCheckFormProvider>
        <StepperLayout
          nextLabel={t('request:next')}
          backLabel={t('request:back')}
          steps={steps}
          takeoverProps={{
            onClose: () => navigate('../'),
          }}
          title={t('request:title')}
        />
      </BackgroundCheckFormProvider>
    )
  } else {
    return <FeedbackComponent loading={true} />
  }
}

export { RequestBackgroundCheck }
