import React from 'react'

import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import { computeInvestorProfileProgression, InvestorProfileStatus } from '@guiker/propsharing-shared'
import {
  ColumnGridLayout,
  Flex,
  Link,
  P,
  PageLayout,
  ProgressTracker,
  TextButton,
  useTranslation,
} from '@guiker/react-framework'

const GridLayout: React.FC<React.PropsWithChildren> = (props) => (
  <ColumnGridLayout gridTemplateColumns={[2, 1]} sm={{ gridTemplateColumns: [1], gap: 2 }} gap={2} {...props} />
)

export const InvestorProfileOnboardScreen: React.FC = () => {
  const { t } = useTranslation(['common-propsharing', 'main-propsharing'])
  const { data: investorProfile } = useAuthenticatedPropSharingInvestorProfileContext()

  const tPrefix = {
    common: 'common-propsharing:investorProfile.steps',
    main: 'main-propsharing:screens.investorProfileOnboardScreen',
  }

  const progression = computeInvestorProfileProgression({ investorProfile })
  let nextStepOrder = 0

  const progressionItems = Object.values(progression).map((step) => {
    nextStepOrder = step.isNext ? step.order : nextStepOrder
    const isCompleted = step.status === 'complete'

    return {
      content: t(`${tPrefix.common}.step${step.order}.title`),
      link: step.isFirst ? undefined : `./onboard?step=step${step.order}`,
      disabled:
        investorProfile?.status === InvestorProfileStatus.INFORMATION_COMPLETED || (!step.isNext && !isCompleted),
      marker: {
        order: step.order,
        isCompleted,
        isFailed: false,
        isFirst: step.isFirst,
        isLast: step.isLast,
        isNext: step.isNext,
      },
    }
  })
  const nextProgressionItem = progressionItems.find((i) => i.marker.order === nextStepOrder)

  return (
    <PageLayout title={t(`${tPrefix.main}.title`)}>
      <GridLayout>
        <Flex flexDirection='column' maxWidth={680}>
          <Flex fullWidth flexDirection='column' mb={8} gap={2}>
            <P>{t(`${tPrefix.main}.description1`)}</P>
            <P>{t(`${tPrefix.main}.description2`)}</P>
          </Flex>
          {!!nextProgressionItem && (
            <Flex alignItems='center' gap={2}>
              <P mb={0}>{t(`${tPrefix.main}.nextStep`)}</P>
              <Link to={nextProgressionItem.link}>
                <TextButton>{nextProgressionItem.content}</TextButton>
              </Link>
            </Flex>
          )}
        </Flex>
        <Flex flexDirection='column' gap={3}>
          <ProgressTracker items={progressionItems} />
        </Flex>
      </GridLayout>
    </PageLayout>
  )
}
