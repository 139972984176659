import React, { useEffect, useMemo } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Breakdown, buildInvoiceBreakdown, PayInMethodSelector } from '@guiker/payment-app-components'
import { usePayInMethodContext } from '@guiker/payment-context'
import { getOnlineTransactionThreshold, payInMethodBuilder, PayInMethodType, ScopeType } from '@guiker/payment-shared'
import { useAuthApiClient } from '@guiker/propsharing-context'
import { StockOrder, stockOrderInvoiceDetailsBuilder } from '@guiker/propsharing-shared'
import {
  Checkbox,
  CircularProgress,
  Flex,
  FormSection,
  H3,
  Link,
  Note,
  NumericInput,
  P,
  PageSection,
  PageSectionProps,
  PBold,
  PSmall,
  useApiFormContext,
  useBlob,
  useFormContext,
  useGetInputProps,
  useQuery,
  useT,
  useWatch,
} from '@guiker/react-framework'
import { getCountryByCurrency, money } from '@guiker/shared-framework'

import { ReservationDisclosure } from '../ReservationDisclosure'

type ScreenFormSectionProps = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>
const ScreenFormSection: React.FC<ScreenFormSectionProps> = (props) => {
  return <PageSection {...props} TitleComponent={H3} titleMb={3} spacing={6} />
}

const HighValueInvestment: React.FC = () => {
  const { tMain } = useT({ domain: 'propsharing', screenName: 'screens.stockOrderCheckout' })

  return (
    <Flex fullWidth flexDirection='column' gap={2}>
      <PBold>{tMain('sections.highValueInvestment.description')}</PBold>
      <Note statusColor='alabaster'>
        <P>{tMain('sections.highValueInvestment.note')}</P>
      </Note>
    </Flex>
  )
}

export const CheckoutContent: React.FC<{
  defaultValue: StockOrder
  riskAcknowledgement: string
  isRiskAcknowledgementFetching: boolean
  setQuantity: React.Dispatch<(prevState: undefined) => undefined>
}> = ({ defaultValue, riskAcknowledgement, setQuantity, isRiskAcknowledgementFetching }) => {
  const { tMain } = useT({ domain: 'propsharing', screenName: 'screens.stockOrderCheckout' })
  const { readOnly } = useApiFormContext()
  const { selected: payInMethod, onPaymentMethodAdded, onPaymentMethodRemoved } = usePayInMethodContext()
  const { setValue } = useFormContext()
  const { user } = useAuthenticationContext()
  const { blobLink, generateBlobLink } = useBlob()

  const propSharingApiClient = useAuthApiClient()
  const inputProps = useGetInputProps({ defaultValue, readOnly, tPrefix: 'common-propsharing:stockOrder' })

  useEffect(() => {
    generateBlobLink({
      encoded: { content: riskAcknowledgement },
      output: { type: 'application/pdf' },
    })
  }, [riskAcknowledgement])

  const { stock } = { ...defaultValue }
  const { askedPrice, quantity: defaultQuantity } = { ...defaultValue?.share }
  const quantity = useWatch({ name: 'share.quantity', defaultValue: defaultQuantity })
  const region = getCountryByCurrency(stock.currency)
  const offlinePayInMethod = payInMethodBuilder({ userId: user.id, region }).offline()
  const threshold = getOnlineTransactionThreshold({
    scopeType: ScopeType.BUY_STOCK_ORDER,
    currency: stock.currency,
  })

  const { invoice, payment } = useMemo(() => {
    return stockOrderInvoiceDetailsBuilder.fromBuy.ipo({
      stockOrder: { ...defaultValue, share: { askedPrice, quantity: quantity ?? 0 } },
      payInMethod: payInMethod,
    })
  }, [defaultValue, quantity, payInMethod])

  const { data: stockAllowance } = useQuery(
    ['investor-profile-stock-allowance', stock.id],
    () =>
      propSharingApiClient
        .getInvestorProfileAllowanceForStock({
          pathParams: { stockId: stock.id },
        })
        .then((res) => res.stockAllowance),
    { retry: 1, enabled: !!stock?.id },
  )

  useEffect(() => {
    setQuantity(quantity)
    setValue('consent.isConsentGiven', false)
  }, [quantity])

  useEffect(() => {
    const isHighValueInvestment = quantity * askedPrice > threshold
    const isWireTransfer = isHighValueInvestment || defaultValue?.payInMethod?.type === PayInMethodType.OFFLINE
    if (isWireTransfer && payInMethod?.type !== PayInMethodType.OFFLINE) {
      onPaymentMethodAdded(offlinePayInMethod)
      setValue('payInMethodId', offlinePayInMethod.id)
    } else if (!isWireTransfer && payInMethod?.type === PayInMethodType.OFFLINE) {
      onPaymentMethodRemoved(offlinePayInMethod.id)
      setValue('payInMethodId', undefined)
    }
  }, [quantity, askedPrice, payInMethod, defaultValue?.payInMethod])

  const invoiceInfo = buildInvoiceBreakdown({ invoice, payInMethodType: payment.method?.type })

  return (
    <FormSection>
      <Note statusColor='alabaster'>
        <ReservationDisclosure />
      </Note>

      <ScreenFormSection>
        <Flex justifyContent='space-between' flexWrap='wrap' gap={1} alignItems='center'>
          <Flex flexDirection='column'>
            <P>
              {stock.label} - {stock.tickerSymbol}
            </P>
            <PSmall color={60}>
              {tMain(`sections.orderDetails.pricePerUnit`, {
                price: money.fromAmount(askedPrice, stock.currency).toString(),
              })}
            </PSmall>
          </Flex>
          <NumericInput
            {...inputProps('share.quantity')}
            canDirectInput
            readOnly={readOnly}
            width={'fit-content'}
            min={stockAllowance?.minQuantity ?? stock.minPerTransaction}
            max={stockAllowance?.maxQuantity ?? stock.maxPerAccount}
            helperText={tMain(`sections.orderDetails.maximumAvailable`, {
              max: stockAllowance?.maxQuantity ?? stock.maxPerAccount,
            })}
          />
        </Flex>
      </ScreenFormSection>
      {payInMethod?.type === PayInMethodType.OFFLINE ? (
        <ScreenFormSection title={tMain(`sections.highValueInvestment.title`)}>
          <HighValueInvestment />
        </ScreenFormSection>
      ) : (
        <ScreenFormSection title={tMain(`sections.paymentMethod.title`)}>
          <PayInMethodSelector name='payInMethodId' />
        </ScreenFormSection>
      )}
      <ScreenFormSection title={tMain(`sections.paymentDetails.title`)}>
        <Breakdown
          items={invoiceInfo.breakdownItems}
          totalPrice={money.fromAmount(invoiceInfo.totalAmount, stock.currency).toString(true)}
        />
      </ScreenFormSection>
      <ScreenFormSection
        title={tMain(`sections.riskAcknowledgement.title`)}
        subtitle={
          <Flex gap={1}>
            {isRiskAcknowledgementFetching && (
              <CircularProgress size={'1rem'} variant='indeterminate' color='inherit' />
            )}
            <Link target='_blank' isExternalLink to={blobLink} disabled={isRiskAcknowledgementFetching && !blobLink}>
              {tMain(`sections.riskAcknowledgement.cta`)}
            </Link>
          </Flex>
        }
      >
        <Checkbox
          maxWidth='100%'
          label={<PBold mb={0}>{tMain(`sections.riskAcknowledgement.consent`)}</PBold>}
          name='consent.isConsentGiven'
        />
      </ScreenFormSection>
    </FormSection>
  )
}
