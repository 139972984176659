import React, { useState } from 'react'

import { BookingApplicationNavigationList, BookingPanel } from '@guiker/booking-components'

import { Box, makeStyles, MobileVerticalSlidingPanels, theme } from '../../components'
import { useBookingInvitedLandlordScreenContext } from '../../context'
import { useTranslation } from '../../hooks'
import { BookingViewByLandlordApplication } from './BookingInvitedLandlordApplication'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  h3: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bookingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bookingModule: {
    maxWidth: '600px',
  },
})

const BookingViewByLandlordMobile: React.FC = () => {
  const { t } = useTranslation(['main-bookingApplicant', 'main-booking'])
  const classes = useStyles()
  const { handleSelectApplicant, selectedApplication, booking } = useBookingInvitedLandlordScreenContext()

  const [openUnitDetails, setOpenUnitDetails] = useState(false)

  return (
    <Box className={classes.root}>
      <MobileVerticalSlidingPanels
        topIn={openUnitDetails}
        topButtonLabel={t('main-booking:screens.bookingInvitedLandlord.drawer.close')}
        bottomButtonLabel={t('main-booking:screens.bookingInvitedLandlord.drawer.open')}
        onClick={() => setOpenUnitDetails(!openUnitDetails)}
        topContentClassName={classes.bookingContent}
        topContent={<BookingPanel className={classes.bookingModule} />}
        bottomContent={
          !!selectedApplication ? (
            <BookingViewByLandlordApplication />
          ) : (
            <Box pt={2}>
              <BookingApplicationNavigationList booking={booking} onClickApplicant={handleSelectApplicant} />
            </Box>
          )
        }
        isTakeover={true}
      />
    </Box>
  )
}

export { BookingViewByLandlordMobile }
