import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import {
  Divider,
  Flex,
  PageSection2,
  PageSection3,
  RouterLink,
  SecondaryButton,
  TabLayout,
  TwoColumnsGridLayout,
} from '@guiker/react-framework'
import { RentCollectionPerReceivedPayoutTable, RentCollectionPerTenantTable } from '@guiker/statistic-components'

import { RentCollectionMonthPicker, useRentCollectionContext } from '../../context'
import { useT } from '../../i18n'
import { MonthlyPerformanceSearchBar } from '../MonthlyPerformanceSearchBar'
import { RentCollectionPieChart } from '../RentCollectionPieChart'
import { RentCollectionSummaryKpis } from '../RentCollectionSummaryKpis'

type MonthlyPerformancesProps = {
  withSearchBar?: boolean
}

const MonthlyPerformances: React.FC<MonthlyPerformancesProps> = ({ withSearchBar = true }) => {
  const { tMain, tBase } = useT({ screenName: 'screens.dashboard.rentalDashboard' })
  const { date, region } = useRentCollectionContext()

  return (
    <PageSection2
      title={tMain('monthlyPerformances.title')}
      titleMb={0}
      headerAlignItems='flex-end'
      topActions={
        <Flex gap={4} alignItems='flex-end'>
          <Flex mb={1.5}>
            <RentCollectionMonthPicker />
          </Flex>
          {withSearchBar && <MonthlyPerformanceSearchBar />}
        </Flex>
      }
    >
      <Divider mt={2} />

      <TwoColumnsGridLayout>
        <RentCollectionPieChart region={region} date={date} />
      </TwoColumnsGridLayout>

      <PageSection3 title={tMain('rentCollection.title')}>
        <TabLayout
          tabs={[
            {
              label: tMain('rentCollection.tab.perTenant'),
              content: <RentCollectionPerTenantTable region={region} date={date} />,
              rightComponent: (
                <RouterLink to={mainPathBuilder.root.rentCollection.path}>
                  <SecondaryButton size='medium'>{tBase('actions.seeAll')}</SecondaryButton>
                </RouterLink>
              ),
            },
            {
              label: tMain('rentCollection.tab.perPayout'),
              content: <RentCollectionPerReceivedPayoutTable date={date} />,
              rightComponent: (
                <RouterLink to={mainPathBuilder.root.rentPayout.path}>
                  <SecondaryButton size='medium'>{tBase('actions.seeAll')}</SecondaryButton>
                </RouterLink>
              ),
            },
          ]}
        />
      </PageSection3>
    </PageSection2>
  )
}

export const RentalDashboard: React.FC = () => {
  return (
    <PageSection2>
      <RentCollectionSummaryKpis />
      <MonthlyPerformances />
    </PageSection2>
  )
}
