import React from 'react'

import { Flex, H4, P, useT } from '@guiker/react-framework'

const TermsAndDisclaimer: React.FC = () => {
  const { tMain } = useT({ domain: 'rentalListing' })
  const termspoints = ['point1', 'point2', 'point3', 'point4', 'point5']
  return (
    <Flex flexDirection='column' gap={8}>
      <div>
        <H4>{tMain('termsAndDisclaimer.terms')}</H4>
        <ul>
          {termspoints.map((p) => (
            <li key={p}>
              <P>{tMain(`termsAndDisclaimer.list.${p}`)}</P>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <H4>{tMain('termsAndDisclaimer.disclaimer.title')}</H4>
        <P>{tMain(`termsAndDisclaimer.disclaimer.description`)}</P>
      </div>
    </Flex>
  )
}

export { TermsAndDisclaimer }
