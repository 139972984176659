import React from 'react'

import { Flex, Label } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useFormContext, useTranslation, useWatch } from '../../../hooks'
import { RadioGroup, SecondaryButton } from '../../'

type Props = {
  defaultValue: Quebec.ServicesTaxesAndConsumptionCosts | null
  name: string
  readOnly: boolean
}

const Heating: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const { setValue } = useFormContext()

  const heating = useWatch({
    name: `${name}.heatingType`,
    defaultValue: defaultValue?.heatingType,
  }) as unknown as Quebec.LessorLesseeTypes | null

  const handleClear = () => {
    setValue(`${name}.heatingType`, null, {
      shouldDirty: true,
    })
  }

  return (
    <RadioGroup
      defaultValue={heating === undefined ? null : heating}
      readOnly={readOnly}
      label={
        <Flex fullWidth justifyContent='space-between' alignItems='center'>
          <Label text={t('quebecLeaseScreenE:heatingOfDwelling')} />
          {heating && !readOnly && (
            <SecondaryButton size='small' onClick={handleClear}>
              {t('common:clear')}
            </SecondaryButton>
          )}{' '}
        </Flex>
      }
      name={`${name}.heatingType`}
      options={[
        { value: Quebec.HeatingTypes.electricity, label: t('quebecLeaseScreenE:electricity') },
        { value: Quebec.HeatingTypes.gas, label: t('quebecLeaseScreenE:gas') },
        { value: Quebec.HeatingTypes.fuelOil, label: t('quebecLeaseScreenE:fuelOil') },
      ]}
    />
  )
}

export { Heating }
