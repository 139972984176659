import React from 'react'

import { ColumnGridLayout, FormSection3 } from '@guiker/components-library'

import { useTranslation } from '../../../hooks'
import { Quebec } from '../../../types'
import { Appliances } from './Appliances'
import { FurnitureType } from './FurnitureType'

interface Props {
  defaultValue: Quebec.Dwelling
  readOnly?: boolean
  name: string
}

const Furniture: React.FC<Props> = ({ defaultValue, readOnly, name }) => {
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])

  return (
    <>
      <FormSection3 title={t('quebecLeaseScreenB:appliances')}>
        <Appliances name={name} readOnly={readOnly} defaultValue={defaultValue} />
      </FormSection3>
      <FormSection3 title={t('quebecLeaseScreenB:furniture')}>
        <ColumnGridLayout gridTemplateColumns={1} gridColumn={{ span: 1 }}>
          {Object.keys(Quebec.FurnitureTypes).map((furnitureType: keyof typeof Quebec.FurnitureTypes) => (
            <FurnitureType
              key={furnitureType}
              defaultValue={defaultValue}
              readOnly={readOnly}
              name={name}
              furnitureType={furnitureType}
            />
          ))}
        </ColumnGridLayout>
      </FormSection3>
    </>
  )
}

export { Furniture }
