import { ArticleScreen, BlogHome, CategoryScreen } from '../screens'

const routes = {
  Home: {
    path: '/',
    Component: BlogHome,
  },
  Category: {
    path: '/:categorySlug',
    Component: CategoryScreen,
  },
  Article: {
    path: '/:categorySlug/:articleSlug',
    Component: ArticleScreen,
  },
} as const

export { routes }
