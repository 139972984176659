import React from 'react'

import { AssetUploader } from '@guiker/asset-uploader'
import { VerificationDocument } from '@guiker/payout-shared'
import { FormSubSection, Grid, TextField, useFormContext, useTranslation } from '@guiker/react-framework'

type VerificationDocumentsSectionProps = {
  defaultValue: VerificationDocument
  name: string
  documentType: string
  readOnly: boolean
  title: string
  required?: boolean
}

const RHFAssetInput: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  const methods = useFormContext()

  if (!methods) return

  return <input type='hidden' name={name} value={value} {...methods.register(name)} />
}

const VerificationDocumentsSection: React.FC<VerificationDocumentsSectionProps> = ({
  documentType,
  name,
  readOnly,
  title,
  required = false,
}) => {
  const { t } = useTranslation(['payout'])

  return (
    <FormSubSection title={`${title}${required ? ' *' : ''}`}>
      {documentType === 'supportingDocs' && (
        <Grid item xs={12} md={6}>
          <TextField
            name={`${name}.comment`}
            label={t('payout:payoutMethod.verificationDocuments.supportingDocsInputLabel')}
            rows={2}
            rowsMax={5}
            readOnly={readOnly}
            multiline
          />
        </Grid>
      )}
      <RHFAssetInput name={`${name}.type`} value={documentType} />
      <AssetUploader
        name={`${name}.assets`}
        groupName={documentType}
        readOnly={readOnly}
        displayDirection='row'
        withThumbnail
      />
    </FormSubSection>
  )
}

export { VerificationDocumentsSection }
