import React from 'react'

import { addressUtils, BookingPeriod } from '@guiker/booking-components'
import { Flex, makeStyles, PBold } from '@guiker/react-framework'

import { useBookingRoommateIntroReviewScreenContext } from '../../context'
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    zIndex: 20,
  },
  thumbnail: {
    width: '100%',
    height: 120,
    objectFit: 'cover',
    objectPosition: 'center',
  },
}))

export const BookingUnitPanel: React.FC = () => {
  const classes = useStyles()
  const { booking } = useBookingRoommateIntroReviewScreenContext()
  const { listing } = { ...booking }
  const { address, thumbnailUrl } = { ...listing }
  const { countryCode: country, apartmentNumber: suite, room } = { ...address }

  if (!booking) {
    return <></>
  }

  return (
    <Flex flexDirection='column' gap={3} p={3} className={classes.container}>
      <img src={thumbnailUrl} className={classes.thumbnail} alt='listing' />
      <Flex flexDirection='column' gap={1}>
        <PBold mb={0}>{addressUtils.printShortAddress({ ...address, room, suite, country })}</PBold>
        <BookingPeriod booking={booking} />
      </Flex>
    </Flex>
  )
}
