import React from 'react'

import { Listing } from '@guiker/base-entity'
import { Dropdown, Flex, PSmall, PSmaller, useWatch } from '@guiker/react-framework'

import { useT } from '../../i18n'

type BedroomCountDropdownProps = {
  readOnly?: boolean
  maxWidth?: number | `${number}%`
}

export const BedroomCountDropdown: React.FC<BedroomCountDropdownProps> = ({ maxWidth = 240, readOnly }) => {
  const { tMain } = useT({ screenName: 'components.bedroomCount' })
  const { bedroomCount } = useWatch()

  const options = Object.values(Listing.FloorPlans).map((key) => ({
    label: tMain(`options.${key}`),
    value: key,
  }))

  if (readOnly) {
    return (
      <Flex flexDirection='column'>
        <PSmaller mb={0}>{tMain('label')}</PSmaller>
        <PSmall mb={0}>{tMain(`options.${bedroomCount}`)}</PSmall>
      </Flex>
    )
  }

  return (
    <Dropdown
      maxWidth={maxWidth}
      name='bedroomCount'
      label={tMain('label')}
      options={options}
      defaultValue={'any'}
      width={'100%'}
    />
  )
}
