import React, { Suspense, useEffect, useState } from 'react'

import { FeedbackComponent, PageLayout } from '../../../components'
import { useAppContext, useQuebecLeaseContext, useTakeoverContext } from '../../../hooks'
import { LeaseFeedbackScreen } from '../../LeaseFeedbackScreen'

const InvitedLessorSignLeaseForm = React.lazy(() =>
  import('./InvitedLessorSignLeaseForm').then((module) => ({ default: module.InvitedLessorSignLeaseForm })),
)

const InvitedLessorSignLease: React.FC = () => {
  const { lease, isLoading } = useQuebecLeaseContext()
  const { onClose } = useTakeoverContext()
  const { claims } = useAppContext()
  const [hasParticipantSigned, setHasParticipantSigned] = useState(false)

  useEffect(() => {
    if (!claims || !claims.leaseId) {
      onClose()
    }
  }, [claims, onClose])

  if (hasParticipantSigned) {
    return <LeaseFeedbackScreen />
  }

  return (
    <PageLayout maxWidth={900}>
      {lease?.id ? (
        <Suspense fallback={<FeedbackComponent loading={true} />}>
          <InvitedLessorSignLeaseForm
            id={lease.id}
            data={lease.leaseInformation}
            setHasParticipantSigned={setHasParticipantSigned}
          />
        </Suspense>
      ) : (
        <FeedbackComponent loading={isLoading} />
      )}
    </PageLayout>
  )
}

export { InvitedLessorSignLease }
