import React from 'react'

import { BookingApplicationNavigationList } from '@guiker/booking-components'

import { PageLayout } from '../../components'
import { useBookingApplicationContext, useBookingContext, useNavigate, useTranslation } from '../../hooks'

const ListBookingApplicationScreen: React.FC = () => {
  const { booking, bookingChat, linkPath } = useBookingContext()
  const { bookingApplication, setBookingApplication } = useBookingApplicationContext()
  const { t } = useTranslation('main-bookingApplicant')
  const navigate = useNavigate()

  return (
    <PageLayout
      noBreadcrumpSpacer
      forceTakeover
      takeoverProps={{
        onBack: bookingChat.navigate,
        onClose: bookingChat.navigate,
        title: t('main-bookingApplicant:screens.listBookingApplications.header'),
      }}
    >
      <BookingApplicationNavigationList
        booking={booking}
        bookingApplication={bookingApplication}
        onClickApplicant={(applicantId) => {
          setBookingApplication(booking.applicants.find((a) => a.userId === applicantId)?.application)
          navigate(linkPath.applicants.byApplicantUserId(applicantId).application.path)
        }}
      />
    </PageLayout>
  )
}

export { ListBookingApplicationScreen }
