import React from 'react'

import { ApiForm, Box } from '../../../components'
import { FormContent as FormContentA } from '../../../components/ontario-lease/step-a/FormContent'
import { FormContent as FormContentB } from '../../../components/ontario-lease/step-b/FormContent'
import { FormContent as FormContentC } from '../../../components/ontario-lease/step-c/FormContent'
import { FormContent as FormContentD } from '../../../components/ontario-lease/step-d/FormContent'
import { FormContent as FormContentE } from '../../../components/ontario-lease/step-e/FormContent'
import { FormContent as FormContentF } from '../../../components/ontario-lease/step-f/FormContent'
import { FormContent as FormContentG } from '../../../components/ontario-lease/step-g/FormContent'
import { FormContent as FormContentH } from '../../../components/ontario-lease/step-h/FormContent'
import { FormContent as FormContentI } from '../../../components/ontario-lease/step-i/FormContent'
import { Ontario } from '../../../types'

type Props = {
  id: string
  data: Ontario.LeaseInformation
}

export const ViewLeaseContent: React.FC<Props> = ({ data }) => {
  return (
    <ApiForm formName={'ViewLeaseForm'}>
      <Box pb={4}>
        <FormContentA lessors={data.lessors} lessees={data.lessees} rentalUnit={data.rentalUnit} />
        <FormContentB contactInformation={data.contactInformation} />
        <FormContentC leaseTerm={data.leaseTerm} rent={data.rent} />
        <FormContentD servicesAndUtilities={data.servicesAndUtilities} />
        <FormContentE rentDiscounts={data.rentDiscounts} rentDeposit={data.rentDeposit} keyDeposit={data.keyDeposit} />
        <FormContentF smoking={data.smoking} tenantsInsurance={data.tenantsInsurance} />
        <FormContentG />
        <FormContentH additionalTerms={data.additionalTerms} />
        <FormContentI lessors={data.lessors} lessees={data.lessees} />
      </Box>
    </ApiForm>
  )
}
