import React from 'react'

import { Initials, Signature } from '../../../types'
import { FormSignatureContainer } from './FormSignatureContainer'
import { StaticSignatureContainer } from './StaticSignatureContainer'

type MaxWidth = string | number

export interface SignatureContainerProps {
  className?: string
  label: string
  name?: string | undefined
  defaultValue?: Signature | Initials | null
  disabled?: boolean
  readOnly?: boolean
  datePickerDisabled?: boolean
  type?: 'initials' | 'signature'
  onChange?: (event: any) => void
  inputClassName?: string
  maxWidth?: MaxWidth
}

const SignatureContainer: React.FC<SignatureContainerProps> = ({ className, disabled, readOnly, name, ...props }) => {
  return disabled || readOnly ? (
    <StaticSignatureContainer name={name} {...props} />
  ) : (
    <FormSignatureContainer name={name} disabled={false} {...props} />
  )
}

export { SignatureContainer }
