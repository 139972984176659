import React from 'react'

import { H3 } from '@guiker/components-library'
import { PaginatedListingsGrid as BasePaginatedListingsGrid } from '@guiker/rental-listing-components'

import { useRecommendedListingAuthApiClient, useTenantPreferenceContext } from '../../hooks'
import { useT } from '../../i18n'
import { EmptyState } from './EmptyState'

const PaginatedListingsGrid: React.FC = () => {
  const { tMain } = useT()
  const { tenantPreference } = useTenantPreferenceContext()
  const recommendedListingAuthApiClient = useRecommendedListingAuthApiClient()
  const isBasedOnInquiry = !tenantPreference?.id

  const fetcher = ({ page, perPage }: { page: number; perPage: number }) =>
    recommendedListingAuthApiClient.readAllRecommendedListingsByTenantPreferences({
      queryParams: { page, perPage },
    })

  return (
    <>
      {isBasedOnInquiry && <H3>{tMain('basedOnYourInquiry')}</H3>}
      <BasePaginatedListingsGrid
        cacheKey={['recommended-listings', tenantPreference?.id, tenantPreference?.updatedAt]}
        fetcher={fetcher}
        emptyState={<EmptyState tenantPreference={tenantPreference} />}
        withLink={false}
      />
    </>
  )
}

export { PaginatedListingsGrid }
