import React, { useEffect } from 'react'

import { StockOrder } from '@guiker/propsharing-shared'
import {
  Checkbox,
  CircularProgress,
  Flex,
  FormSection,
  H3,
  Link,
  NumericInput,
  P,
  PageSection,
  PageSectionProps,
  PBold,
  PSmall,
  useApiFormContext,
  useBlob,
  useGetInputProps,
  useT,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

type ScreenFormSectionProps = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>
const ScreenFormSection: React.FC<ScreenFormSectionProps> = (props) => {
  return <PageSection {...props} TitleComponent={H3} titleMb={3} spacing={6} />
}

export const ClaimContent: React.FC<{
  defaultValue: StockOrder
  riskAcknowledgement: string
  isRiskAcknowledgementFetching: boolean
}> = ({ defaultValue, riskAcknowledgement, isRiskAcknowledgementFetching }) => {
  const { tMain } = useT({ domain: 'propsharing', screenName: 'screens.stockOrderCheckout' })
  const { readOnly } = useApiFormContext()
  const { generateBlobLink, blobLink } = useBlob()

  const inputProps = useGetInputProps({ defaultValue, readOnly, tPrefix: 'common-propsharing:stockOrder' })

  useEffect(() => {
    generateBlobLink({
      encoded: { content: riskAcknowledgement },
      output: { type: 'application/pdf' },
    })
  }, [riskAcknowledgement])

  const { stock, share } = { ...defaultValue }

  return (
    <FormSection>
      <ScreenFormSection>
        <Flex justifyContent='space-between' flexWrap='wrap' gap={1} alignItems='center'>
          <Flex flexDirection='column'>
            <P>
              {stock.label} - {stock.tickerSymbol}
            </P>
            <PSmall color={60}>
              {tMain(`sections.orderDetails.pricePerUnit`, {
                price: money.fromAmount(share.askedPrice, stock.currency).toString(),
              })}
            </PSmall>
          </Flex>
          <NumericInput {...inputProps('share.quantity')} width={'fit-content'} readOnly />
        </Flex>
      </ScreenFormSection>
      <ScreenFormSection
        title={tMain(`sections.riskAcknowledgement.title`)}
        subtitle={
          <Flex gap={1}>
            {isRiskAcknowledgementFetching && (
              <CircularProgress size={'1rem'} variant='indeterminate' color='inherit' />
            )}
            <Link target='_blank' isExternalLink to={blobLink} disabled={isRiskAcknowledgementFetching && !blobLink}>
              {tMain(`sections.riskAcknowledgement.cta`)}
            </Link>
          </Flex>
        }
      >
        <Checkbox
          maxWidth='100%'
          label={<PBold mb={0}>{tMain(`sections.riskAcknowledgement.consent`)}</PBold>}
          name='consent.isConsentGiven'
        />
      </ScreenFormSection>
    </FormSection>
  )
}
