import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { findLatestSeries, PropSharingListing, WithStockLatestFinancialStatements } from '@guiker/propsharing-shared'
import { math, money } from '@guiker/shared-framework'

import { useInvestmentAssumptionT, useT } from '../../i18n'

export const buildKpiGrid = (listing: WithStockLatestFinancialStatements<PropSharingListing>) => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.offeringDetails.kpi' })
  const { investmentResults, currency } = useInvestmentAssumptionContext()
  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)
  const series = findLatestSeries(listing.stock)
  const financialStatements = listing.stock.latestFinancialStatements
  const propertyValue = financialStatements.balanceSheet.assets.find((a) => a.label === 'propertyValue')
  const mortgage = financialStatements.balanceSheet.liabilities.find((a) => a.label === 'mortgage')

  return [
    {
      label: tMain('totalPurchaseCost'),
      tip: tMain('totalPurchaseCostTip'),
      description: moneyFormatter(money.fromAmount(investmentResults.totalProjectCost, currency).monetized),
    },
    {
      label: tMain('purchasePrice'),
      tip: tMain('purchasePriceTip'),
      description: moneyFormatter(money.fromAmount(propertyValue.amount, currency).monetized),
    },
    {
      label: tMain('leverage'),
      tip: tMain('leverageTip'),
      description: moneyFormatter(money.fromAmount(mortgage.amount, currency).monetized),
    },
    {
      label: tMain('interest'),
      description: `${math.decimal.round(series.target.deal.liabilities.loan.rate * 100, 2)}%`,
    },
  ]
}

export const buildPurchaseBreakdown = () => {
  const { investmentResults, investmentAssumptions } = useInvestmentAssumptionContext()
  const { tShared } = useInvestmentAssumptionT({ entity: 'purchase.costs' })
  const cashReserve = investmentResults?.computedPurchaseCosts.find((c) => c.type === 'cashReserve')?.computedValue ?? 0
  const reno = investmentAssumptions.purchase.costs.initialRenovation?.value.amount?.value ?? 0
  const totalRenoReserve = cashReserve + reno
  const holdingCost = investmentResults?.computedPurchaseCosts.find((c) => c.type === 'holdingCost')?.computedValue ?? 0
  const offeringCost =
    investmentResults?.computedPurchaseCosts.find((c) => c.type === 'offeringCost')?.computedValue ?? 0
  const closingCost = investmentResults?.computedPurchaseCosts.find((c) => c.type === 'closingCost')?.computedValue ?? 0
  const taxes = investmentResults?.computedPurchaseTaxes?.reduce((acc, cur) => cur.computedValue + acc, 0)
  const otherCosts = holdingCost + offeringCost + closingCost + taxes

  return [
    {
      label: tShared('purchasePrice'),
      value: investmentAssumptions.purchase.price.amount,
    },
    {
      label: tShared('renovation'),
      value: totalRenoReserve,
      collapsibleItems: [
        {
          label: tShared('propertyImprovement'),
          value: reno,
        },
        {
          label: tShared('cashReserve'),
          value: cashReserve,
        },
      ],
    },
    {
      label: tShared('otherCosts'),
      value: otherCosts,
      collapsibleItems: [
        {
          label: tShared('taxes'),
          value: taxes,
        },
        {
          label: tShared('closingCost'),
          value: closingCost,
        },
        {
          label: tShared('offeringCost'),
          value: offeringCost,
        },
        {
          label: tShared('holdingCost'),
          value: holdingCost,
        },
      ],
    },
  ]
}
