import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useBookingContext } from '@guiker/booking-context'
import { getApplicantFullName, isUnitManager } from '@guiker/booking-shared'
import { NavigationList, PageLayout } from '@guiker/components-library'
import { PageNotFound } from '@guiker/error-pages'
import { useDateFormatter, useTranslation } from '@guiker/i18n'
import { generatePath } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'

const BookingApplications: React.FC = () => {
  const { booking, bookingChat } = useBookingContext()
  const { t } = useTranslation('bookingApplication')
  const { user } = useAuthenticationContext()
  const { formatWithRelativeDate } = useDateFormatter()
  const applications = booking.applicants.map((a) => a.application)

  if (!isUnitManager({ booking, userId: user.id })) {
    return <PageNotFound />
  }

  const reviewableApplications = applications.map(({ userId, statusHistory, rentalApplicationId }) => ({
    applicant: booking.applicants.find((a) => a.userId === userId),
    currentStatus: statusHistory[statusHistory.length - 1],
    rentalApplicationId,
    bookingId: booking.id,
  }))

  return (
    <TakeoverContextProvider onBack={bookingChat.navigate} onClose={bookingChat.navigate} title={t('all_applications')}>
      <PageLayout maxWidth={900 as const}>
        <NavigationList
          items={reviewableApplications.map(({ applicant, currentStatus, bookingId, rentalApplicationId }) => ({
            primaryText: getApplicantFullName(applicant),
            avatar: applicant?.avatarUrl,
            disabled: !rentalApplicationId,
            url: generatePath('/rental-applications/:bookingId/application/:rentalApplicationId', {
              bookingId,
              rentalApplicationId: `${rentalApplicationId}`,
            }),
            secondaryText: `${t(`application_status_${currentStatus.status.toLowerCase()}`)}: ${formatWithRelativeDate(
              currentStatus.changedAt,
            )}`,
          }))}
        />
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { BookingApplications }
