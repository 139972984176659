import React from 'react'

import { PlusSmallIcon } from '@guiker/icons'

import { useFieldArray, useFormContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { Link, OneColumnGridLayout, TextField } from '../../'
import { TwoColumnsGridLayout } from '../../common'

type Props = {
  name: 'rent.rentOtherAmounts'
  readOnly?: boolean
  disabled?: boolean
}

export const OtherServicesAndUtils: React.FC<Props> = ({ name, readOnly, disabled }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<Types.LeaseInformation>()
  const { fields, append } = useFieldArray({
    control,
    name,
  })

  const handleAddService = () => {
    append({ otherDescription: '', otherAmount: 0 })
  }

  return (
    <div>
      {fields.map((value, i) => (
        <TwoColumnsGridLayout key={value.id}>
          <TextField
            defaultValue={value.otherDescription}
            name={`${name}.${i}.otherDescription`}
            label={t('common:description')}
            disabled={disabled}
            readOnly={readOnly}
          />
          <TextField
            defaultValue={value.otherAmount}
            name={`${name}.${i}.otherAmount`}
            label={t('common:price')}
            disabled={disabled}
            readOnly={readOnly}
          />
        </TwoColumnsGridLayout>
      ))}
      <OneColumnGridLayout>
        <Link adornment={<PlusSmallIcon />} disabled onClick={handleAddService}>
          {t('ontarioLeaseScreenC:addServices')}
        </Link>
      </OneColumnGridLayout>
    </div>
  )
}
