import { initialize } from '@guiker/i18n'
import { useT as baseUseT } from '@guiker/i18n'
export * from '@guiker/i18n'

const i18n = initialize({
  resources: {},
})

export const useT = (args: { screenName?: string; entity?: string } = {}) =>
  baseUseT({ ...args, domain: 'myInvestments' })

export { i18n }
