import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { money } from '@guiker/money'
import { FundingAccountDepositStatus, FundingAccountWithdrawalStatus } from '@guiker/payment-shared'
import {
  Button,
  Flex,
  FullScreenSpinner,
  GridLayout,
  PageSection1,
  PBold,
  PSmall,
  useModal,
  useQuery,
} from '@guiker/react-framework'

import {
  DepositModal,
  FundingAccountEmptyState,
  FundingDepositsTable,
  FundingWithdrawalsTable,
  Highlight,
  WithdrawalModal,
} from '../../components'
import { useAuthenticatedFundingAccountContext, useAuthenticatedPaymentApiClient } from '../../hooks'
import { useT } from '../../i18n'

type FundingStatus = FundingAccountDepositStatus | FundingAccountWithdrawalStatus
const sumByStatus = <T extends { status: FundingStatus; amount: number }>(data: T[], status: FundingStatus) => {
  return data?.filter((d) => d.status === status).reduce((acc, curr) => acc + curr.amount, 0) ?? 0
}

const FundingAccountScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.fundingAccount' })
  const { tMain: tNavigation } = useT({ screenName: 'sideMenu.navItem' })
  const apiClient = useAuthenticatedPaymentApiClient()
  const { isOpen: isDepositModalOpen, openModal: openDepositModal, closeModal: closeDepositModal } = useModal()
  const { isOpen: isWithdrawalModalOpen, openModal: openWithdrawalModal, closeModal: closeWithdrawalModal } = useModal()
  const { data: fundingAccount, isFetching } = useAuthenticatedFundingAccountContext()
  const { data: deposits, refetch: refetchDeposits } = useQuery(
    [`readAllDeposits-${fundingAccount?.id}`],
    () => apiClient.readAllDeposits({ pathParams: { fundingAccountId: fundingAccount?.id } }),
    { enabled: !!fundingAccount?.id },
  )

  const { data: withdrawals, refetch: refetchWithdrawals } = useQuery(
    [`readAllWithdrawals-${fundingAccount?.id}`],
    () => apiClient.readAllWithdrawals({ pathParams: { fundingAccountId: fundingAccount?.id } }),
    { enabled: !!fundingAccount?.id },
  )

  return (
    <AccountPageLayout breadcrumbItems={[{ label: tNavigation('wallet') }, { label: tNavigation('fundingAccount') }]}>
      <PageSection1 title={tMain(`title`)}>
        {isFetching ? (
          <FullScreenSpinner />
        ) : !fundingAccount?.id ? (
          <FundingAccountEmptyState />
        ) : (
          <>
            <GridLayout gap={4} columnMinWidth={200}>
              <Highlight
                header={tMain('balance')}
                value={
                  <Flex gap={1} alignItems='center'>
                    <PBold mb={0}>
                      {money.fromAmount(fundingAccount.balance, fundingAccount.currency).toString('onlySymbol')}
                    </PBold>
                    <PSmall mb={0} color={30}>
                      {fundingAccount.currency}
                    </PSmall>
                  </Flex>
                }
              />
              <Highlight
                header={tMain('deposited')}
                value={
                  <Flex gap={1} alignItems='center'>
                    <PBold mb={0}>
                      {money
                        .fromAmount(
                          sumByStatus(deposits, FundingAccountDepositStatus.PROCESSING),
                          fundingAccount.currency,
                        )
                        .toString('onlySymbol')}
                    </PBold>
                    <PSmall mb={0} color={30}>
                      {fundingAccount.currency}
                    </PSmall>
                  </Flex>
                }
              />
              <Highlight
                header={tMain('withdrawn')}
                value={
                  <Flex gap={1} alignItems='center'>
                    <PBold mb={0}>
                      {money
                        .fromAmount(
                          sumByStatus(withdrawals, FundingAccountWithdrawalStatus.PROCESSING),
                          fundingAccount.currency,
                        )
                        .toString('onlySymbol')}
                    </PBold>
                    <PSmall mb={0} color={30}>
                      {fundingAccount.currency}
                    </PSmall>
                  </Flex>
                }
              />
            </GridLayout>

            <FundingDepositsTable
              deposits={deposits}
              cta={<Button onClick={openDepositModal}>{tMain('secondaryCta')}</Button>}
            />
            <FundingWithdrawalsTable
              withdrawals={withdrawals}
              cta={<Button onClick={openWithdrawalModal}>{tMain('cta')}</Button>}
            />
            <DepositModal
              fundingAccount={fundingAccount}
              open={isDepositModalOpen}
              onClose={closeDepositModal}
              refetch={refetchDeposits}
            />
            <WithdrawalModal
              fundingAccount={fundingAccount}
              open={isWithdrawalModalOpen}
              onClose={closeWithdrawalModal}
              refetch={refetchWithdrawals}
            />
          </>
        )}
      </PageSection1>
    </AccountPageLayout>
  )
}

export { FundingAccountScreen }
