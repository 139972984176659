import React from 'react'

import { TwoColumnsGridLayout } from '@guiker/components-library'

import { useTranslation, useWatch } from '../../../hooks'
import { Quebec } from '../../../types'
import { Checkbox } from '../../'

type ApplianceType = keyof typeof Quebec.ApplianceTypes

interface Props {
  defaultValue: Quebec.Dwelling
  readOnly?: boolean
  name: string
}

interface ApplianceProps extends Props {
  applianceType: ApplianceType
}

const Appliance: React.FC<ApplianceProps> = ({ defaultValue, readOnly, name, applianceType }) => {
  const { t } = useTranslation(['quebecLeaseScreenB'])
  const applianceChecked = useWatch({
    name: `${name}.appliances.${applianceType}`,
    defaultValue: defaultValue?.appliances?.[applianceType],
  })

  return (
    <Checkbox
      readOnly={readOnly}
      defaultValue={applianceChecked}
      name={`${name}.appliances.${applianceType}`}
      label={t(`quebecLeaseScreenB:${applianceType}`)}
    />
  )
}

const Appliances: React.FC<Props> = (props) => {
  return (
    <TwoColumnsGridLayout>
      {Object.keys(Quebec.ApplianceTypes).map((applianceType: ApplianceType) => (
        <Appliance key={applianceType} applianceType={applianceType} {...props} />
      ))}
    </TwoColumnsGridLayout>
  )
}

export { Appliances }
