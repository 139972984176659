import React from 'react'

import { SoftcheckReport } from '@guiker/background-check-shared'

import { useTranslation } from '../i18n'
import { Box } from '.'
import { SoftcheckReportCardItem } from './SoftcheckReportCardItem'

interface SoftcheckReportCardProps {
  softcheck: SoftcheckReport
}

export const SoftcheckReportCard: React.FC<SoftcheckReportCardProps> = ({ softcheck }) => {
  const { t } = useTranslation()

  const scanStatuses = [
    { name: 'publicCourtRecords', status: softcheck.scanStatus.publicCourtRecords },
    { name: 'criminalScan', status: softcheck.scanStatus.criminalScan },
    { name: 'fraudScan', status: softcheck.scanStatus.fraudScan },
    { name: 'knownAffiliationScan', status: softcheck.scanStatus.knownAffiliationScan },
    { name: 'ofacGlobalTerroristScan', status: softcheck.scanStatus.ofacGlobalTerroristScan },
    { name: 'publicSafetyScan', status: softcheck.scanStatus.publicSafetyScan },
    { name: 'sexOffenderScan', status: softcheck.scanStatus.sexOffenderScan },
    { name: 'globalClearanceScan', status: softcheck.scanStatus.globalClearanceScan },
    { name: 'otherScan', status: softcheck.scanStatus.otherScan },
    { name: 'publicProfileScan', status: softcheck.scanStatus.publicProfileScan },
  ]

  return (
    <Box>
      {scanStatuses.map((scanStatus) => (
        <SoftcheckReportCardItem
          key={scanStatus.name}
          title={t(`common:softcheck.scanStatuses.${scanStatus.name}`)}
          status={scanStatus.status}
        />
      ))}
    </Box>
  )
}
