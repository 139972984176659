import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  return (
    <Routes>
      <Route {...routes.guarantorSign} {...props} />
      <Route {...routes.preApplication} {...props} />
      <Route {...routes.createTenantApplication} {...props} />
      <Route {...routes.viewTenantApplication} {...props} />
      <Route {...routes.tenantApplicationFeedback} {...props} />
      <Route {...routes.noConsent} {...props} />
    </Routes>
  )
}

export { Router }
