import React from 'react'

import { makeStyles, theme } from '@guiker/react-framework'

import { ListingDetail } from './ListingDetail'

const useStyles = makeStyles({
  appContainer: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    maxWidth: 360,
    zIndex: 20,
    padding: theme.spacing(2),
  },
})

export const ListingInquiryModule: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.appContainer}>
      <ListingDetail />
    </div>
  )
}
