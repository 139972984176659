import React, { useEffect } from 'react'

import { PageNotFound } from '@guiker/error-pages'

import { useClaimsAuthenticationContext, useLocationQuery } from '../hooks'
import { AppContext, AppContextType, LeaseContextProvider } from '.'

export const InvitedLessorAppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()

  useEffect(() => {
    setClaimsFromToken(token)
  }, [token])

  const value = {
    booking: null,
    claims: claims as AppContextType['claims'],
    disabledForCurrentUser: (_) => !claims?.emailAddress,
    isUnitManager: false,
    canCreateLease: false,
    participant: null,
    participantType: 'invited-lessor' as const,
    readOnly: true,
    user: null,
  }

  if (!value.claims) {
    return <PageNotFound />
  }

  return (
    <AppContext.Provider value={value}>
      <LeaseContextProvider leaseId={value.claims.leaseId}>{children} </LeaseContextProvider>
    </AppContext.Provider>
  )
}
