import React from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { ParticipantStepStatus } from '@guiker/booking-shared'
import { PageNotFound } from '@guiker/error-pages'
import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  const { bookingUser } = useBookingContext()
  const documentsStatus = bookingUser.steps['document']?.status
  return (
    <Routes>
      <Route {...routes.createSuccess} {...props} />
      {bookingUser.type === 'unitManager' && documentsStatus === ParticipantStepStatus.NOT_STARTED && (
        <Route {...routes.newBookingDocument} {...props} />
      )}
      <Route {...routes.viewBookingDocuments} {...props} />
      <Route path='*' Component={PageNotFound} />
    </Routes>
  )
}

export { Router }
