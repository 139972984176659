import { useAuthenticationContext } from '@guiker/authentication-context'
import { AuthenticatedApiClient } from '@guiker/booking-shared'
import { useConfig } from '@guiker/config-context'
import { v4 as uuid } from '@guiker/uuid'

export const useBookingPaymentApiClient = () => {
  const { apiBaseUrl: baseUrl, debug } = useConfig()
  const {
    user: { accessToken },
  } = useAuthenticationContext()

  return AuthenticatedApiClient({
    accessToken,
    baseUrl,
    correlatedRequestId: uuid(),
    logger: debug ? console : undefined,
  })
}
