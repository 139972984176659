import React from 'react'

import { AuthInvoiceNestedRouter, useInvoiceContext } from '@guiker/payment-app-components'
import { ScopeType } from '@guiker/payment-shared'

export const DisputedInvoiceNestedRouter = () => {
  const { invoice } = useInvoiceContext()

  return <AuthInvoiceNestedRouter scope={{ type: ScopeType.DISPUTED_INVOICE, id: invoice.id }} />
}
