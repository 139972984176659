import * as React from 'react'

import { Article, getCategory, getMediaUrl } from '@guiker/blog-entity'
import { routes } from '@guiker/blog-routes'
import { Box, Chip, Flex, H3, H4, Link, makeStyles, PBold, theme, useLayoutContext } from '@guiker/components-library'
import { useDateFormatter } from '@guiker/i18n'

import { useTranslation } from '../../hooks'

const IMAGE_WIDTH = 380
const IMAGE_HEIGHT = 270

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      height: '100%',
      minWidth: 300,
      minHeight: 322,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    image: ({ height }: ArticleCardProps) => ({
      width: '100%',
      objectFit: 'cover',
      height: height || IMAGE_HEIGHT,
      minHeight: height || IMAGE_HEIGHT,
    }),
    address: {
      lineHeight: '24px',
      fontSize: 14,
    },
    topLine: {
      lineHeight: '19px',
    },
    link: {
      width: '100%',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    truncate: {
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
    },
  },
  { name: 'ListingCard' },
)

export type ArticleCardProps = {
  article: Article
  maxWidth?: number
  height?: number
  className?: string
}

const ArticleCard: React.FC<ArticleCardProps> = (props) => {
  const { article } = props
  const classes = useStyles(props)
  const url = getMediaUrl(article, 'large')
  const categories = article.categories?.map((category) => getCategory(article, category))
  const { isMobile } = useLayoutContext()
  const { t } = useTranslation()
  const { formatWithRelativeDateTime } = useDateFormatter()

  const HeadingComponent = isMobile ? H4 : H3

  return (
    <div className={classes.root}>
      <Link
        to={routes.blog.categoryNested(categories && categories[0]?.slug).article(article.slug).path}
        className={classes.link}
      >
        <img
          src={url}
          className={classes.image}
          alt={article.title.rendered}
          width={IMAGE_WIDTH}
          height={IMAGE_HEIGHT}
        />
      </Link>
      <Flex pt={1} flexDirection='column' flexGrow={1}>
        <Box mb={1}>
          {categories?.map((category) => (
            <Link
              color='white'
              underline={false}
              hoverUnderline={false}
              to={routes.blog.categoryNested(category.slug).path}
              key={category.slug}
            >
              <Chip color='primary' className={classes.chip}>
                {category?.name}
              </Chip>
            </Link>
          ))}
        </Box>

        <Link
          to={routes.blog.categoryNested(categories && categories[0]?.slug).article(article.slug).path}
          underline={false}
          hoverUnderline={false}
        >
          <HeadingComponent mb={2} className={classes.truncate}>
            {article.title.rendered}
          </HeadingComponent>
        </Link>
        <PBold mb={2} color={60}>
          {t('main-blog:article.writtenBy', {
            name: article._embedded?.author[0].name,
            date: formatWithRelativeDateTime(article.date),
          })}
        </PBold>
      </Flex>
    </div>
  )
}

export { ArticleCard }
