import React from 'react'

import { PublicListingNestedRouter } from '@guiker/rental-listing-context'
import { useParams } from '@guiker/router'
import { Outlet } from '@guiker/router'

const ListingInquiryNestedRouter: React.FC = () => {
  const { id: listingId } = useParams()

  /** @todo use nested auth router to fetch listing inquiry */
  return (
    <PublicListingNestedRouter listingId={listingId.split('-')[0]}>
      <Outlet />
    </PublicListingNestedRouter>
  )
}

export { ListingInquiryNestedRouter }
