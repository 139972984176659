import React, { useEffect, useState } from 'react'

import { checkConfirmReadiness } from '@guiker/booking-shared'
import { PageLayout } from '@guiker/components-library'
import { Status } from '@guiker/lease-shared'

import { Box, Button, H4, P } from '../../components'
import { useAppContext, useLeaseContext, useTakeoverContext, useTranslation } from '../../hooks'

const BookingParticipantSuccessButton: React.FC = () => {
  const { t } = useTranslation()
  const { onClose } = useTakeoverContext()
  const { booking, participantType } = useAppContext()
  const [hasCompletedPaymentStep, setHasCompletedPaymentStep] = useState(false)

  const tPrefix = 'common:screens.leaseFeedbackScreen'

  useEffect(() => {
    if (participantType === 'applicant' && !!booking) {
      setHasCompletedPaymentStep(checkConfirmReadiness(booking).payments)
    }
  }, [booking, participantType])

  if (participantType === 'unitManager') {
    return (
      <Box textAlign='center' maxWidth={420}>
        <H4>{t(`${tPrefix}.signed`)}</H4>
        <Button onClick={onClose}>{t(`${tPrefix}.cta`)}</Button>
      </Box>
    )
  }

  const tApplicant = `${tPrefix}.forApplicant.${
    hasCompletedPaymentStep ? 'paymentStepComplete' : 'paymentStepIncomplete'
  }`

  return (
    <Box textAlign='center' maxWidth={420}>
      <H4>{t(`${tApplicant}.title`)}</H4>
      <P>{t(`${tApplicant}.message`)}</P>
      <Button onClick={onClose}>{t(`${tApplicant}.cta`)}</Button>
    </Box>
  )
}

const LeaseFeedbackScreen: React.FC = () => {
  const { t } = useTranslation()
  const { participantType } = useAppContext()
  const { lease } = useLeaseContext()
  const isBookingParticipant = ['applicant', 'unitManager'].includes(participantType)

  return (
    <PageLayout maxWidth={900 as const}>
      <Box display='flex' flexDirection='column' alignItems='center' pb={4}>
        {!isBookingParticipant && (
          <H4>
            {t(
              `common:screens.leaseFeedbackScreen.${
                lease?.status === Status.SIGNED_BY_ALL_PARTIES ? 'complete' : 'signed'
              }`,
            )}
          </H4>
        )}
        {isBookingParticipant && <BookingParticipantSuccessButton />}
      </Box>
    </PageLayout>
  )
}

export { LeaseFeedbackScreen }
