import { useJwtBookingRentPaymentsPlanScopeNestedContext } from '@guiker/rent-payment-context'
import { RentPaymentRoleNames } from '@guiker/rent-payment-shared'

import { useAuthenticationContext, useBookingContext } from '../../hooks'

export const useRentPaymentsPlanScreen = () => {
  const { user } = useAuthenticationContext()
  const { accessControl } = useBookingContext()
  const { data: rentPaymentsPlan, isFetching } = useJwtBookingRentPaymentsPlanScopeNestedContext()

  const isPayer = rentPaymentsPlan.payers.some((p) => p.userId === user.id)
  const isReceiver = accessControl.claims?.acl.payment?.roles.includes(RentPaymentRoleNames.UNIT_MANAGER)
  const accessibleStastus = ['ACTIVE', 'TERMINATED']

  const isAuthorized = accessibleStastus.includes(rentPaymentsPlan.status) && (isPayer || isReceiver)

  return { isPayer, isReceiver, isAuthorized, isFetching, rentPaymentsPlan }
}
