import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { money } from '@guiker/money'
import { math } from '@guiker/shared-framework'

import { useT } from '../../i18n'

export const buildKpiGrid = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.investmentPerformance.kpi' })
  const { investmentResults, investmentAssumptions, currency } = useInvestmentAssumptionContext()
  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)

  return [
    {
      label: tMain('firstYearCashflow'),
      description: moneyFormatter(
        money.fromAmount(investmentResults.yearlyCashflows[0].netCashFlow, currency).monetized,
      ),
    },
    {
      label: tMain('irr'),
      description: `${math.decimal.round(investmentResults.irr * 100, 2)}%`,
      tip: tMain('irrTip'),
    },
    {
      label: tMain('estimatedSalePrice'),
      description: moneyFormatter(money.fromAmount(investmentResults.estimatedSalesPrice, currency).monetized),
    },
    {
      label: tMain('holdingPeriod'),
      unit: tMain('year'),
      description: `${investmentAssumptions.base.holdingPeriod}`,
    },
  ]
}
