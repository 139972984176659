import React from 'react'

import { DropdownMenu } from '@guiker/components-library'

import { useTranslation } from '../../i18n'

type Props = {
  canEditDocuments: boolean
  handleCancelModal: () => void
  handleHistoryModal: () => void
}

export const DocumentActionsDropdownMenu: React.FC<Props> = ({
  canEditDocuments,
  handleCancelModal,
  handleHistoryModal,
}) => {
  const { t } = useTranslation(['common', 'main-bookingDocument'])
  const tPrefix = 'main-bookingDocument:screens.viewBookingDocuments'
  const tablePrefix = `${tPrefix}.columns`

  const actions = (['edit', 'cancel', 'history', 'download'] as const)
    .filter((action) => {
      if (['edit', 'cancel'].includes(action)) {
        return canEditDocuments
      } else {
        return true
      }
    })
    .map((text) => {
      let onClick: () => void
      switch (text) {
        case 'history':
          onClick = () => handleHistoryModal()
          break
        case 'cancel':
          onClick = () => canEditDocuments && !!handleCancelModal && handleCancelModal()
          break
        case 'download':
        case 'edit':
        default:
          break
      }
      return { text, onClick }
    })

  return (
    <DropdownMenu
      text={t(`${tablePrefix}.body.action`)}
      items={actions.map(({ text, onClick }) => ({
        onClick,
        text: `${text === 'history' ? `main-bookingDocument:actions` : 'common:actions'}.${text}`,
      }))}
    />
  )
}
