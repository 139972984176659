import React from 'react'

import { Inquiry, useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { PageSection2 } from '@guiker/react-framework'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'

export const RentalDetails: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.rentalDetails' })
  const { listing, handleNote } = useListingScreenContext()
  const { investmentAssumptions } = useInvestmentAssumptionContext()

  return (
    <PageSection2 title={tMain('header')}>
      <Inquiry.RentalDetails handleNote={handleNote} property={listing.property} assumptions={investmentAssumptions} />
    </PageSection2>
  )
}
