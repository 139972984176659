import React from 'react'

import { FormSection2 } from '@guiker/components-library'

import { useTranslation } from '../../../hooks'
import { Quebec } from '../../../types'
import { HeaderSecondaryDescriptionBar } from '../header'
import { Guarantors } from './Guarantors'
import { Liability } from './Liability'
import { Notice } from './Notice'
import { Signatures } from './Signatures'

type Props = {
  liability?: Quebec.Liability
  lessors?: Quebec.Lessor[]
  lessees?: Quebec.Lessee[]
}

export const FormContent: React.FC<Props> = ({ liability, lessors, lessees }) => {
  const { t } = useTranslation('quebecLeaseScreenH')

  return (
    <FormSection2
      title={<HeaderSecondaryDescriptionBar index='H.' description={t('quebecLeaseScreenH:headerLabel')} />}
    >
      <Signatures lessors={lessors} lessees={lessees} />
      <Liability defaultValue={liability} lessees={lessees} />
      <Guarantors lessees={lessees} />
      <Notice />
    </FormSection2>
  )
}
