import React from 'react'

type Props<P extends object = object> = {
  Component: React.FC<unknown>
  props: P
  memoHint: unknown
}

const MemoizedComponent: React.FC<Props> = ({ Component, props }) => <Component {...props} />

const shouldRenderMemoized = (prev: Props, next: Props) => {
  const prevHint = JSON.stringify(prev.memoHint || {})
  const nextHint = JSON.stringify(next.memoHint || {})
  return prevHint === nextHint
}

export default React.memo(MemoizedComponent, shouldRenderMemoized)
