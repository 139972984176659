import React from 'react'

import { mainPathBuilder } from '@guiker/booking-shared'
import { InvoiceContent, useInvoiceContext } from '@guiker/payment-main-components'
import { PageLayout, useNavigate } from '@guiker/react-framework'

import { useAuthenticationContext, useBookingContext } from '../../hooks'

export const ViewInvoiceScreen: React.FC = () => {
  const { user } = useAuthenticationContext()
  const { isLoading } = useInvoiceContext()
  const { bookingId } = useBookingContext()
  const navigate = useNavigate()

  return (
    <PageLayout
      forceTakeover
      maxWidth={900}
      isLoading={isLoading}
      takeoverProps={{
        onClose: () => navigate(mainPathBuilder.root.byId(bookingId).rentPayment.path),
      }}
    >
      <InvoiceContent user={user} />
    </PageLayout>
  )
}
