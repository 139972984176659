import React from 'react'

import { ColumnGridLayout } from '@guiker/components-library'

import { useTranslation, useWatch } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Types.ContactInformation
  readOnly?: boolean
}

export const LandlordContact: React.FC<Props> = ({ defaultValue, readOnly }) => {
  const { t } = useTranslation()
  const name = 'isLandlordProvidingContactInfo'
  const isLandlordProvidingContactInfo = useWatch<Types.ContactInformation, typeof name>({
    name,
    defaultValue: defaultValue?.isLandlordProvidingContactInfo,
  })

  return (
    <ColumnGridLayout gap={2}>
      <RadioGroup
        defaultValue={isLandlordProvidingContactInfo}
        options={[
          { value: true, label: t('common:yes') },
          { value: false, label: t('common:no') },
        ]}
        label={t('ontarioLeaseScreenB:emergencyContact')}
        name={name}
        readOnly={readOnly}
      />
      <TextField
        defaultValue={defaultValue?.landlordContactInfo}
        name={`landlordContactInfo`}
        label={t('ontarioLeaseScreenB:provideInfo')}
        disabled={!isLandlordProvidingContactInfo}
        readOnly={readOnly}
        maxWidth='100%'
      />
    </ColumnGridLayout>
  )
}
