import React from 'react'

import { useFormContext, useTranslation, useWatch } from '../../../hooks'
import { makeStyles } from '../../../style'
import { Box, Button, PBold, RadioGroup, RadioGroupProps } from '../../'

type Props = Omit<RadioGroupProps, 'options'>

const useStyles = makeStyles({
  marginBottomZero: {
    marginBottom: 0,
  },
})

export const LandlordTenantRadio: React.FC<Props> = ({ defaultValue, name, label, readOnly, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const value = useWatch({ name, defaultValue })
  const { setValue } = useFormContext()

  const handleClear = () => {
    value &&
      setValue(name, null, {
        shouldDirty: true,
      })
  }

  return (
    <Box mb={2} width={420}>
      <Box height={40} mb={2} display='flex' justifyContent='space-between' alignItems='center'>
        <PBold className={classes.marginBottomZero}>{label}</PBold>
        {value && !readOnly && (
          <Button size='small' onClick={handleClear}>
            {t('common:clear')}
          </Button>
        )}
      </Box>
      <RadioGroup
        defaultValue={value === undefined ? null : value}
        name={name}
        options={[
          { value: 'lessor', label: t('ontarioLeaseScreenD:landlord') },
          { value: 'lessee', label: t('ontarioLeaseScreenD:tenant') },
        ]}
        gridContainerProps={{ spacing: 1 }}
        maxWidth={420}
        readOnly={readOnly}
        {...props}
      />
    </Box>
  )
}
