import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'

import { useTranslation } from '../../../hooks'
import { makeStyles } from '../../../style'
import { Note, P } from '../../'
import { FormSection2, HeaderDescriptionBar } from '../../common'

const useStyles = makeStyles({
  preLine: {
    whiteSpace: 'pre-line',
  },
})

export const FormContent: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <FormSection2 title={<HeaderDescriptionBar index='12.' description={t('ontarioLeaseScreenG:section12Header')} />}>
        <P className={classes.preLine}>{t('ontarioLeaseScreenG:section12Description')}</P>
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='13.' description={t('ontarioLeaseScreenG:section13Header')} />}>
        <P className={classes.preLine}>{t('ontarioLeaseScreenG:section13Description')}</P>
        <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
          {t('ontarioLeaseScreenG:section13Note')}
        </Note>
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='14.' description={t('ontarioLeaseScreenG:section14Header')} />}>
        <P>{t('ontarioLeaseScreenG:section14Description')}</P>
        <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
          {t('ontarioLeaseScreenG:section14Note')}
        </Note>
      </FormSection2>
    </>
  )
}
