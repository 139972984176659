import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'
import { Route, Routes } from '@guiker/router'

import { useAppContext, useLeaseContext } from '../hooks'
import { BookingAddressState, getLeaseScreens } from '../screens'
import { routes } from '.'

const Router: React.FC = (props) => {
  const { isUnitManager, booking, canCreateLease } = useAppContext()
  const { can } = useLeaseContext()
  const bookingAddressState = booking?.listing?.address?.state

  const canDraftLease = canCreateLease || can(LeaseActions.DraftLease)
  const canViewLease = can(LeaseActions.ViewLease)

  const { CreateLease, SignLeaseScreen, ViewLeaseScreen, LeaseFeedbackScreen } = getLeaseScreens(
    bookingAddressState as BookingAddressState,
  )

  return (
    <Routes>
      {isUnitManager && canDraftLease && <Route {...routes.createLease} Component={CreateLease} {...props} />}
      {isUnitManager && !canDraftLease && canViewLease && (
        <Route {...routes.viewLease} Component={ViewLeaseScreen} {...props} />
      )}
      {!isUnitManager && <Route {...routes.signLease} Component={SignLeaseScreen} {...props} />}
      <Route {...routes.leaseFeedbackScreen} Component={LeaseFeedbackScreen} {...props} />
    </Routes>
  )
}

export { Router }
