import React, { createContext, useState } from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { Application, Booking } from '@guiker/booking-shared'
import { generateUseContext } from '@guiker/react-framework'

type AppContextType = {
  selectedApplication: Application
  handleCloseApplication: () => void
  actionOnSuccess: () => void
  handleSelectApplicant: (applicantId: string) => void
  booking: Booking
  isFetching: boolean
}

export const BookingInvitedLandlordScreenContext = createContext<AppContextType>(null)

export const BookingInvitedLandlordScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { booking, isFetching, refetchBooking } = useBookingContext()
  const [selectedApplication, setSelectedApplication] = useState<Application>(null)

  const handleSelectApplicant = (applicantId: string) => {
    const application = booking.applicants.find((applicant) => applicant.userId === applicantId)?.application
    setSelectedApplication(application)
  }

  const handleCloseApplication = () => {
    setSelectedApplication(null)
  }

  const actionOnSuccess = () => {
    handleCloseApplication()
    refetchBooking()
  }

  const value = {
    selectedApplication,
    handleCloseApplication,
    actionOnSuccess,
    handleSelectApplicant,
    booking,
    isFetching,
  }

  return (
    <BookingInvitedLandlordScreenContext.Provider value={value}>
      {children}
    </BookingInvitedLandlordScreenContext.Provider>
  )
}

export const useBookingInvitedLandlordScreenContext = generateUseContext(BookingInvitedLandlordScreenContext)
