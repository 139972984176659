import React, { createContext } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { PayoutStatus } from '@guiker/payment-shared'
import { CLDatePicker, generateUseContext, useT } from '@guiker/react-framework'
import { useSearchParams } from '@guiker/router'
import { CountryCode, DateTime, parseDate, toISODate } from '@guiker/shared-framework'
import { RentCollectionStatus } from '@guiker/statistic-shared'
import { getRegion, hasRentalListing } from '@guiker/user-metadata-shared'

import { RentalEmptyState } from '../components/RentalEmptyState'

export const RentCollectionContext = createContext<{
  range: {
    min: DateTime
    max: DateTime
  }
  region: CountryCode
  date: string
  setDate: (date: DateTime) => void
  rentStatuses: RentCollectionStatus[]
  payoutStatuses: PayoutStatus[]
  setRentStatuses: (statuses: RentCollectionStatus[]) => void
  setPayoutStatuses
}>(undefined)

export const RentCollectionContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const defaultDate = DateTime.local().startOf('month')

  const { user } = useAuthenticationContext()
  const { getSearchParam, getSearchArrayParams, addSearchParam } = useSearchParams()
  const date = DateTime.fromFormat(getSearchParam('date') ?? defaultDate.toFormat('yyyy-MM'), 'yyyy-MM')
  const rentStatuses = getSearchArrayParams('rent-statuses') ?? ([] as RentCollectionStatus[])
  const payoutStatuses = getSearchArrayParams('payout-statuses') ?? ([] as PayoutStatus[])
  const region = getRegion(user?.metadata)
  const userHasRentalListing = hasRentalListing(user?.metadata)

  const setDate = (date: DateTime) => {
    addSearchParam('date', date.toFormat('yyyy-MM'))
  }

  const setRentStatuses = (statuses: RentCollectionStatus[]) => {
    addSearchParam('rent-statuses', statuses)
  }

  const setPayoutStatuses = (statuses: PayoutStatus[]) => {
    addSearchParam('payout-statuses', statuses)
  }

  if (!userHasRentalListing) {
    return <RentalEmptyState />
  }

  const value = {
    region,
    range: {
      min: user.createdAt ? DateTime.fromISO(user.createdAt) : DateTime.local().minus({ years: 2 }),
      max: DateTime.local().plus({ years: 2 }),
    },
    payoutStatuses: payoutStatuses as PayoutStatus[],
    rentStatuses: rentStatuses as RentCollectionStatus[],
    setRentStatuses,
    setPayoutStatuses,
    setDate,
    date: date.toFormat('yyyy-MM'),
  }

  return <RentCollectionContext.Provider value={value}>{children}</RentCollectionContext.Provider>
}

export const RentCollectionMonthPicker: React.FC = () => {
  const { date, setDate, range } = useRentCollectionContext()
  const { tMain } = useT({ screenName: 'screens' })

  return (
    <CLDatePicker
      minDate={toISODate(range.min)}
      maxDate={toISODate(range.max)}
      label={tMain('rentCollection.selectDate')}
      views={['month']}
      value={toISODate(`${date}-01`)}
      onChange={(date) => setDate(parseDate(date))}
      pager={{ step: 'month', format: 'yyyy MMM' }}
    />
  )
}

export const useRentCollectionContext = generateUseContext(RentCollectionContext)
