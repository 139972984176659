import React from 'react'

import { Flex, Note } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useLeaseContext, useTranslation } from '../../../hooks'
import { Quebec } from '../../../types'
import { PBold, RadioGroup, Typography } from '../../'
import { SignatureContainer } from '../../common/SignatureContainer'

interface Props {
  lessees?: Quebec.Lessee[]
  defaultValue: Quebec.Liability
}

export const Liability: React.FC<Props> = ({ defaultValue, lessees }) => {
  const name = 'liability'
  const { disabledForCurrentUser } = useAppContext()
  const { can } = useLeaseContext()
  const { t } = useTranslation(['common', 'quebecLeaseScreenH'])

  const canDraftLease = can(LeaseActions.DraftLease)
  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)

  return (
    <Note statusColor='alabaster'>
      <Flex flexDirection='column' gap={2}>
        <Typography mb={0}>
          {t('quebecLeaseScreenH:lesseeLeaseLiableLabel')}
          <PBold mb={0} component='span'>
            {t('quebecLeaseScreenH:lesseeLeaseLiableBoldLabel')}
          </PBold>
        </Typography>
        <RadioGroup
          defaultValue={defaultValue?.lesseesAreSolidarilyLiable}
          name={`${name}.lesseesAreSolidarilyLiable`}
          readOnly={!canDraftLease}
          options={[
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
          ]}
        />
        {(lessees || []).map((lessee: Quebec.Lessee, index: number) => {
          return (
            <SignatureContainer
              key={index}
              readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
              name={`${name}.initials`}
              type='initials'
              defaultValue={lessees[index]?.liability?.initials}
              label={t('quebecLeaseScreenH:initialsOfLessee') || ''}
            />
          )
        })}
      </Flex>
    </Note>
  )
}
