import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'

import { TFunction, useWatch } from '../../../hooks'
import { Ontario, Ontario as Types } from '../../../types'
import { Box, Collapse, FormSection3, Note, P, RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Types.Smoking
  name: 'smoking'
  readOnly?: boolean
  t: TFunction
}

export const Smoking: React.FC<Props> = ({ defaultValue, name, readOnly, t }) => {
  const smokingName = `${name}.isSmokingRulesAgreed` as const
  const isSmokingRulesAgreed = useWatch<Ontario.LeaseInformation, typeof smokingName>({
    name: smokingName,
    defaultValue: defaultValue?.isSmokingRulesAgreed,
  })

  return (
    <FormSection3>
      <P>{t('ontarioLeaseScreenF:smokingAgreement')}</P>
      <div>
        <RadioGroup
          defaultValue={isSmokingRulesAgreed}
          name={smokingName}
          label={t('ontarioLeaseScreenF:selectOne')}
          options={[
            { value: false, label: t('ontarioLeaseScreenF:none') },
            { value: true, label: t('ontarioLeaseScreenF:smokingRules') },
          ]}
          gridContainerProps={{ spacing: 2 }}
          readOnly={readOnly}
          maxWidth={420}
        />
        <Collapse in={isSmokingRulesAgreed}>
          <Box mt={2}>
            <P>{t('ontarioLeaseScreenF:smokingRulesDescription')}</P>
            <TextField
              defaultValue={defaultValue?.smokingRulesDescription}
              name={`${name}.smokingRulesDescription`}
              readOnly={readOnly}
              maxWidth={420}
            />
          </Box>
        </Collapse>
      </div>
      <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
        {t('ontarioLeaseScreenF:smokingRulesNote')}
      </Note>
    </FormSection3>
  )
}
