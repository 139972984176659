import React from 'react'

import { Checkbox, FormSection, FullScreenSpinner, Link, P, useQuery } from '@guiker/react-framework'
import { upperFirst } from '@guiker/shared-framework'

import { useEditLinkContext } from '../../context'
import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { useT } from '../../i18n'
import { EditButton } from '../EditButton'

type FormContentProps = {
  readOnly?: boolean
  title?: string
}

const TermsAndConditionsFormContent: React.FC<FormContentProps> = ({ readOnly = false, title }) => {
  const { payoutMethod } = usePayoutMethodNestedContext()
  const apiClient = useAuthenticatedPayoutApiClient()
  const { tMain } = useT({ domain: 'payout' })
  const { editUrl } = useEditLinkContext({ shouldThrowIfUndefined: false }) ?? {}

  const { data: termsAndConditions, isLoading } = useQuery('readAllPayoutMethodTermsAndConditions', () =>
    apiClient.readAllPayoutMethodTermsAndConditions({ pathParams: { payoutMethodId: payoutMethod.id } }),
  )

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <FormSection title={title} topActions={readOnly && <EditButton url={editUrl} />}>
      <div>
        <P>
          {tMain('termsAndConditions.subText')}
          {termsAndConditions?.map(({ link, provider }, index) => {
            return !!link ? (
              <>
                {index > 0 ? ' / ' : ''}
                <Link isExternalLink to={link} target={'_blank'}>
                  {upperFirst(provider.toLowerCase())}
                </Link>
              </>
            ) : (
              <></>
            )
          })}
        </P>
        <Checkbox
          name='agreed'
          defaultValue={payoutMethod.consent?.agreed || false}
          label={tMain('termsAndConditions.acceptTermsAndConditions')}
          maxWidth='100%'
          readOnly={readOnly}
        />
      </div>
    </FormSection>
  )
}

export { TermsAndConditionsFormContent }
