import React from 'react'

import { FinancialBreakdown, KpiGrid } from '@guiker/base-listing-components'
import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { getStockHelper, SeriesType } from '@guiker/propsharing-shared'
import { Flex, Note, PageSection2, PageSection3, ProcessCostIcon } from '@guiker/react-framework'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'
import { buildKpiGrid, buildPurchaseBreakdown } from './utils'

const PurchaseBreakdown: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.offeringDetails' })
  const { investmentResults, currency } = useInvestmentAssumptionContext()

  return (
    <PageSection3 title={tMain('breakdown.title')}>
      <Flex flexDirection='column' width='100%' maxWidth={700}>
        <FinancialBreakdown
          currency={currency}
          items={buildPurchaseBreakdown()}
          total={{
            label: tMain('kpi.totalPurchaseCost'),
            value: investmentResults.totalProjectCost,
          }}
        />
      </Flex>
    </PageSection3>
  )
}

const OfferingDetails: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen' })
  const { handleNote, listing } = useListingScreenContext()
  const { latestSeries } = getStockHelper(listing)

  if (latestSeries.seriesType === SeriesType.REFINANCING) {
    return
  }

  return (
    <PageSection2 title={tMain(`tab1.sections.offeringDetails.title`)}>
      <Note
        type='collapsible'
        title={tMain(`tab1.sections.offeringDetails.note.title`)}
        icon={<ProcessCostIcon />}
        statusColor='alabaster'
        isCollapsed={true}
        onMobileOpen={() =>
          handleNote({
            title: tMain(`tab1.sections.offeringDetails.note.title`),
            content: tMain(`tab1.sections.offeringDetails.note.description`),
          })
        }
      >
        {tMain(`tab1.sections.offeringDetails.note.description`)}
      </Note>
      <KpiGrid items={buildKpiGrid(listing)} />
      <PurchaseBreakdown />
    </PageSection2>
  )
}

export { OfferingDetails }
