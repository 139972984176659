import React from 'react'

import { PageLayout } from '../../components'
import { useBookingContext } from '../../hooks'
import { useTranslation } from '../../i18n'
import { BookingDocumentApplicantView } from './BookingDocumentApplicantView'
import { BookingDocumentUnitManagerView } from './BookingDocumentUnitManagerView'

export const ViewBookingDocumentsScreen: React.FC = () => {
  const { t } = useTranslation(['main-bookingDocument'])
  const { booking, bookingUser, bookingChat, isFetching, accessControl } = useBookingContext()

  return (
    <PageLayout
      title={t('screens.viewBookingDocuments.title')}
      takeoverProps={{ onClose: bookingChat.navigate, hasBorder: true }}
      maxWidth={900}
      isLoading={isFetching || !booking || !accessControl}
      forceTakeover
    >
      {bookingUser.type === 'unitManager' && (
        <BookingDocumentUnitManagerView booking={booking} accessControlToken={accessControl?.token} />
      )}
      {bookingUser.type === 'applicant' && (
        <BookingDocumentApplicantView
          booking={booking}
          accessControlToken={accessControl?.token}
          userId={bookingUser.userId}
        />
      )}
    </PageLayout>
  )
}
