import React from 'react'

import { useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { Box, TextField, TwoColumnsGridLayout } from '../../'

type Props = {
  defaultValue: Types.Person
  index: number
  readOnly?: boolean
  name?: string
  mb: number
}

export const FormPerson: React.FC<Props> = ({ defaultValue, index, name, readOnly, mb }) => {
  const { t } = useTranslation()
  return (
    <Box mb={mb}>
      <TwoColumnsGridLayout>
        <TextField
          defaultValue={defaultValue?.firstName}
          label={`${index + 1}. ${t('common:firstName')}`}
          maxWidth='100%'
          name={`${name}[${index}].firstName`}
          readOnly={readOnly}
        />
        <TextField
          defaultValue={defaultValue?.lastName}
          label={t('common:lastName')}
          maxWidth='100%'
          name={`${name}[${index}].lastName`}
          readOnly={readOnly}
        />
      </TwoColumnsGridLayout>
    </Box>
  )
}
