import React from 'react'

import { DealType } from '@guiker/base-listing-shared'
import { Dropdown, Flex, PSmall, PSmaller, useFormContext, useWatch } from '@guiker/react-framework'
import { BuildingTypes } from '@guiker/real-estate-shared'
import { camelCase } from '@guiker/shared-framework'

import { useT } from '../../i18n'

const propertyTypeMapping = {
  condoNew: {
    value: 'condoNew',
    buildingType: BuildingTypes.CONDO,
    dealType: DealType.NEW,
  },
  condoPrecon: {
    value: 'condoPrecon',
    buildingType: BuildingTypes.CONDO,
    dealType: DealType.PRECON,
  },
  condoResale: { value: 'condoResale', buildingType: BuildingTypes.CONDO, dealType: DealType.RESALE },
  plex: {
    value: 'plex',
    buildingType: BuildingTypes.PLEX,
    dealType: undefined,
  },
  singleFamily: {
    value: 'singleFamily',
    buildingType: BuildingTypes.SINGLE_FAMILY,
    dealType: undefined,
  },
  any: {
    value: 'any',
    buildingType: undefined,
    dealType: undefined,
  },
}

type PropertyTypeDropdownProps = {
  readOnly?: boolean
  maxWidth?: number | `${number}%`
}

export const PropertyTypeDropdown: React.FC<PropertyTypeDropdownProps> = ({ maxWidth = 240, readOnly }) => {
  const { tMain } = useT({ screenName: 'components.propertyType' })
  const { setValue } = useFormContext()
  const { propertyType } = useWatch()

  const options = ['any', 'condoNew', 'condoPrecon', 'condoResale', 'plex', 'singleFamily'].map((key) => ({
    label: tMain(`options.${key}`),
    value: propertyTypeMapping[camelCase(key)].value,
  }))

  if (readOnly) {
    return (
      <Flex flexDirection='column'>
        <PSmaller mb={0}>{tMain('label')}</PSmaller>
        <PSmall mb={0}>{tMain(`options.${propertyType}`)}</PSmall>
      </Flex>
    )
  }

  return (
    <Dropdown
      maxWidth={maxWidth}
      name='propertyType'
      label={tMain('label')}
      options={options}
      defaultValue={propertyTypeMapping.any.value}
      onChange={(event) => {
        setValue('buildingType', propertyTypeMapping[event.target.value].buildingType)
      }}
      width={'100%'}
    />
  )
}
