import React from 'react'

import { FormSection2, FormSection3, Note, OneColumnGridLayout, TwoColumnsGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Quebec as Types } from '../../../types'
import { Checkbox, RadioGroup, TextField } from '../../'
import { FormAddress } from '../form-components/FormAddress/FormAddress'
import { HeaderSecondaryDescriptionBar } from '../header'
import { AccessoriesAndDependencies } from './AccessoriesAndDependencies'
import { Furniture } from './Furniture'
import { LockerOrStorageSpace } from './LockerOrStorageSpace'
import { ParkingInformation } from './ParkingInformation'
import { Purpose } from './Purpose'
import { Signatures } from './Signatures'

type Props = {
  dwelling?: Types.Dwelling
  lessors: Types.Lessor[]
  lessees: Types.Lessee[]
}

export const FormContent: React.FC<Props> = ({ dwelling, lessors, lessees }) => {
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])
  const { can } = useLeaseContext()
  const name = 'dwelling'

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection2
      title={<HeaderSecondaryDescriptionBar index='B.' description={t('quebecLeaseScreenB:headerDescription')} />}
    >
      <FormSection3>
        <TwoColumnsGridLayout>
          <FormAddress readOnly={true} defaultValue={dwelling?.address} name={`${name}.address`} />
          <TextField
            label={t('quebecLeaseScreenB:numberOfRooms')}
            readOnly={!canDraftLease}
            name={`${name}.numberOfRooms`}
            defaultValue={dwelling?.numberOfRooms}
          />
        </TwoColumnsGridLayout>
      </FormSection3>
      <FormSection3>
        <OneColumnGridLayout>
          <Purpose defaultValue={dwelling?.purpose} readOnly={!canDraftLease} name={name} />
          <RadioGroup
            defaultValue={dwelling?.isCoOwnership}
            label={t('quebecLeaseScreenB:dwellingCoOwnerShipLabel')}
            readOnly={!canDraftLease}
            name={`${name}.isCoOwnership`}
            options={[
              { value: true, label: t('common:yes') },
              { value: false, label: t('common:no') },
            ]}
          />
        </OneColumnGridLayout>
      </FormSection3>
      <FormSection3>
        <OneColumnGridLayout>
          <ParkingInformation
            checkBoxLabel={t('quebecLeaseScreenB:outdoorParking')}
            name={`${name}.outdoorParking`}
            defaultValue={dwelling?.outdoorParking}
            readOnly={!canDraftLease}
          />
          <ParkingInformation
            checkBoxLabel={t('quebecLeaseScreenB:indoorParking')}
            name={`${name}.indoorParking`}
            defaultValue={dwelling?.indoorParking}
            readOnly={!canDraftLease}
          />
          <LockerOrStorageSpace readOnly={!canDraftLease} name={name} defaultValue={dwelling?.lockerOrStorageSpace} />
          <AccessoriesAndDependencies
            readOnly={!canDraftLease}
            name={name}
            defaultValue={dwelling?.accessoriesAndDependencies}
          />
          <Checkbox
            readOnly={!canDraftLease}
            defaultValue={dwelling?.isFurnished}
            name={`${name}.isFurnished`}
            label={t('quebecLeaseScreenB:isFurnished')}
          />
        </OneColumnGridLayout>
      </FormSection3>
      <Furniture defaultValue={dwelling} readOnly={!canDraftLease} name={name} />

      <FormSection3>
        <Note>{t('quebecLeaseScreenB:signatureLabel')}</Note>
        <Signatures lessees={lessees} lessors={lessors} name={`${name}.smokeDetectorsInitials`} />
      </FormSection3>
    </FormSection2>
  )
}
