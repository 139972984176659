import React from 'react'

import { Box, H4, Link, makeStyles, useLayoutContext } from '@guiker/react-framework'

type Props = {
  title: string
  linkTo: string
  chip: React.ReactElement
  icon: React.ReactElement
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    width: 440,
    background: theme.palette.grey[5],
  },
}))

export const PayoutMethodCard: React.FC<Props> = (props) => {
  const { chip, icon, linkTo, title } = props
  const { isMobile } = useLayoutContext()
  const classes = useStyles({ isMobile })

  return (
    <Link to={linkTo}>
      <Box className={classes.container}>
        <Box height='100%' mr={2}>
          {icon}
        </Box>
        <Box display='flex' flexDirection='column' height='100%'>
          <H4 mb={1}>{title}</H4>
          {chip}
        </Box>
      </Box>
    </Link>
  )
}
