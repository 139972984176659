import React from 'react'

import { FormSection2 } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation } from '../../../hooks'
import { HeaderSecondaryDescriptionBar } from '../header'
import { PaymentDate } from './PaymentDate'
import { PaymentInformation } from './PaymentInformation'
import { PaymentMethod } from './PaymentMethod'
import { PaymentPlace } from './PaymentPlace'
import { Rent } from './Rent'

type Props = {
  rent: Quebec.Rent
}

export const FormContent: React.FC<Props> = ({ rent }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])
  const name = 'rent'

  return (
    <FormSection2
      title={<HeaderSecondaryDescriptionBar index='D.' description={t('quebecLeaseScreenD:primaryHeaderLabel')} />}
    >
      <Rent name={name} defaultValue={rent} />
      <PaymentDate name={name} defaultValue={rent} />
      <PaymentMethod name={name} defaultValue={rent} />
      <PaymentPlace name={name} defaultValue={rent} />
      <PaymentInformation name={name} />
    </FormSection2>
  )
}
