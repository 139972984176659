import ontarioLeaseScreenA from '../components/ontario-lease/step-a/en.i18n.json'
import ontarioLeaseScreenB from '../components/ontario-lease/step-b/en.i18n.json'
import ontarioLeaseScreenC from '../components/ontario-lease/step-c/en.i18n.json'
import ontarioLeaseScreenD from '../components/ontario-lease/step-d/en.i18n.json'
import ontarioLeaseScreenE from '../components/ontario-lease/step-e/en.i18n.json'
import ontarioLeaseScreenF from '../components/ontario-lease/step-f/en.i18n.json'
import ontarioLeaseScreenG from '../components/ontario-lease/step-g/en.i18n.json'
import ontarioLeaseScreenH from '../components/ontario-lease/step-h/en.i18n.json'
import ontarioLeaseScreenI from '../components/ontario-lease/step-i/en.i18n.json'
import quebecLeaseScreenA from '../components/quebec-lease/step-a/en.i18n.json'
import quebecLeaseScreenB from '../components/quebec-lease/step-b/en.i18n.json'
import quebecLeaseScreenC from '../components/quebec-lease/step-c/en.i18n.json'
import quebecLeaseScreenD from '../components/quebec-lease/step-d/en.i18n.json'
import quebecLeaseScreenE from '../components/quebec-lease/step-e/en.i18n.json'
import quebecLeaseScreenF from '../components/quebec-lease/step-f/en.i18n.json'
import quebecLeaseScreenG from '../components/quebec-lease/step-g/en.i18n.json'
import quebecLeaseScreenH from '../components/quebec-lease/step-h/en.i18n.json'
import common from './common/en.i18n.json'
import errors from './errors/en.i18n.json'

export default {
  common,
  errors,
  quebecLeaseScreenA,
  quebecLeaseScreenB,
  quebecLeaseScreenC,
  quebecLeaseScreenD,
  quebecLeaseScreenE,
  quebecLeaseScreenF,
  quebecLeaseScreenG,
  quebecLeaseScreenH,
  ontarioLeaseScreenA,
  ontarioLeaseScreenB,
  ontarioLeaseScreenC,
  ontarioLeaseScreenD,
  ontarioLeaseScreenE,
  ontarioLeaseScreenF,
  ontarioLeaseScreenG,
  ontarioLeaseScreenH,
  ontarioLeaseScreenI,
}
