import React from 'react'

import { FormSection1, Step, StepProps } from '../../'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepG: React.FC<Props> = (props) => (
  <Step formName={'OntarioLeaseFormStepG'} skipMutation onSubmit={() => props.onClickNext()} {...props}>
    <FormSection1>
      <FormContent />
    </FormSection1>
  </Step>
)
