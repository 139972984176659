import React, { useMemo } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { usePaymentApiClient } from '@guiker/payment-context'
import { FundingAccount } from '@guiker/payment-shared'
import { PerformanceIndicator } from '@guiker/propsharing-components'
import { getPortfolioStats, Portfolio } from '@guiker/propsharing-shared'
import { KpiRow, useQueryMutation } from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type InvestmentKpisProps = {
  portfolio: Portfolio
}

export const InvestmentKpis: React.FC<InvestmentKpisProps> = ({ portfolio }) => {
  const { tMain } = useT({ screenName: 'screens.dashboard.investmentDashboard.kpis' })
  const { user } = useAuthenticationContext()
  const { useQuery } = useQueryMutation<FundingAccount>({ queryKey: ['InvestmentKpiWallet', user?.id] })
  const paymentApiClient = usePaymentApiClient()
  const { data: fundingAccount, isLoading } = useQuery(paymentApiClient.readOneFundingAccountByUser, {
    enabled: !!user,
  })
  const walletBalance = fundingAccount?.balance ?? 0
  const {
    balance,
    currency,
    performanceSign,
    performancePercent,
    isPositive,
    positionOwned,
    propertyInvestedIn,
    targetEquity,
  } = useMemo(() => getPortfolioStats(portfolio), [portfolio])

  if (isLoading || !balance) return <></>

  const totalValue = balance.amount + walletBalance
  const unrealized = balance.amount - targetEquity.amount
  const items = [
    {
      color: 'primary' as const,
      top: {
        label: tMain('investment.mainLabel'),
        value: money.fromAmount(totalValue, currency).toString('onlySymbol', false),
      },
      bottom: [
        {
          label: tMain('investment.secondaryLabel'),
          value: balance.toString('onlySymbol', false) ?? 'N/A',
        },
        {
          label: tMain('investment.tertiaryLabel'),
          value: money.fromAmount(walletBalance, currency).toString('onlySymbol', false),
        },
      ],
    },
    {
      color: 'secondary' as const,
      top: {
        valueAdornment: <PerformanceIndicator isPositive={unrealized >= 0} />,
        label: tMain('performance.mainLabel'),
        value: money.fromAmount(unrealized, currency).toString('onlySymbol', false),
      },
      bottom: [
        {
          label: tMain('performance.secondaryLabel'),
          value: targetEquity.amount !== 0 ? targetEquity.toString('onlySymbol', false) : 'N/A',
        },
        {
          valueAdornment: isPositive != null && <PerformanceIndicator isPositive={isPositive} />,
          label: tMain('performance.tertiaryLabel'),
          value: `${performanceSign}${performancePercent}%`,
        },
      ],
    },
    {
      color: 'secondary' as const,
      top: {
        label: tMain('positionOwned.mainLabel'),
        value: positionOwned,
        unit: tMain('positionOwned.mainUnit'),
      },
      bottom:
        propertyInvestedIn !== 0
          ? {
              label: tMain('positionOwned.secondaryLabel'),
              value: propertyInvestedIn,
              unit: tMain('positionOwned.secondaryUnit'),
            }
          : null,
    },
  ]

  return <KpiRow items={items} minWidth={300} />
}
