import React, { createContext } from 'react'

import { generateUseContext } from '@guiker/react-framework'

type EditUrlContextProviderType = {
  url: string
  children: React.ReactNode | RenderChildren
}

type Context = {
  editUrl: string
}

type RenderChildren = (context: Context) => React.ReactNode

export const EditLinkContext = createContext<Context>(null)
export const useEditLinkContext = generateUseContext(EditLinkContext)

export const EditUrlContextProvider: React.FC<EditUrlContextProviderType> = ({ url, children }) => {
  const value = {
    editUrl: url,
  }
  return (
    <EditLinkContext.Provider value={value}>
      {typeof children === 'function' ? (children as RenderChildren)(value) : children}
    </EditLinkContext.Provider>
  )
}
