import React from 'react'

import { Flex, PSmall } from '..'

type HighlightProps = {
  header: string
  value: React.ReactNode
}

const Highlight: React.FC<HighlightProps> = ({ header, value }) => {
  return (
    <Flex flexDirection='column' gap={1}>
      <PSmall mb={0}>{header}</PSmall>
      {value}
    </Flex>
  )
}

export { Highlight }
