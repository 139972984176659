import React from 'react'

import { Document } from '@guiker/base-listing-components'
import { Flex, FormSection4 } from '@guiker/components-library'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { ProjectWithDeveloper } from '@guiker/real-estate-shared'

import { useTranslation } from '../../i18n'

type DocumentsProps = {
  listing: PropertySaleListing
  project: ProjectWithDeveloper
}

const Documents: React.FC<DocumentsProps> = ({ listing, project }) => {
  const { t } = useTranslation('main-propertySale')

  const developerDocuments = [...(project?.documents || []), ...(project?.developer?.documents || [])]
  const listingDocuments = listing.documents

  return (
    <>
      {!!listingDocuments && listingDocuments.length > 0 && (
        <FormSection4 title={t('components.projectTab.documents.sections.listing')}>
          <Flex flexDirection='column' gap={3}>
            {listingDocuments.map((document) => (
              <Document key={document.id} url={document.url} displayName={document.displayName ?? document.fileName} />
            ))}
          </Flex>
        </FormSection4>
      )}
      {!!developerDocuments.length && developerDocuments.length > 0 && (
        <FormSection4 title={t('components.projectTab.documents.sections.developer')}>
          <Flex flexDirection='column' gap={3}>
            {developerDocuments.map((document) => (
              <Document key={document.id} url={document.url} displayName={document.displayName ?? document.fileName} />
            ))}
          </Flex>
        </FormSection4>
      )}
    </>
  )
}

export { Documents }
