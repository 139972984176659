import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  const neighbourhoodNested = routes.listings.routes.countryNested.routes.cityNested.routes.neighbourhoodNested

  return (
    <Routes>
      <Route {...routes.root} {...props} />
      <Route {...routes.listings} {...props}>
        <Route {...routes.listings.routes.idNested} {...props}>
          <Route {...routes.listings.routes.idNested.routes.id} />
        </Route>
        <Route {...routes.listings.routes.countryNested} {...props}>
          <Route {...routes.listings.routes.countryNested.routes.cityNested} {...props}>
            <Route {...neighbourhoodNested} {...props}>
              <Route {...neighbourhoodNested.routes.idNested} {...props}>
                <Route {...neighbourhoodNested.routes.idNested.routes.id} {...props} />
                <Route {...neighbourhoodNested.routes.idNested.routes.slugified} {...props} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export { Router }
