import React from 'react'

import { FormSection2 } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { HeaderSecondaryDescriptionBar } from '../header'
import { Conditions } from './Conditions'
import { Costs } from './Costs'
import { Immovable } from './Immovable'
import { JanitorServices } from './JanitorServices'
import { OtherServices } from './OtherServices'
import { Text } from './Text'
import { WorkRepairs } from './WorkRepairs'

type Props = {
  servicesAndConditions: Quebec.ServicesAndConditions
}

export const FormContent: React.FC<Props> = ({ servicesAndConditions }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const name = 'servicesAndConditions'

  const { can } = useLeaseContext()
  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection2
      title={
        <HeaderSecondaryDescriptionBar index='E.' description={t('quebecLeaseScreenE:servicesAndConditionsHeader')} />
      }
    >
      <Immovable name={name} readOnly={!canDraftLease} defaultValue={servicesAndConditions} />
      <WorkRepairs name={name} readOnly={!canDraftLease} defaultValue={servicesAndConditions?.workAndRepairs} />
      <JanitorServices name={name} readOnly={!canDraftLease} defaultValue={servicesAndConditions?.janitorialServices} />
      <Costs
        name={name}
        readOnly={!canDraftLease}
        defaultValue={servicesAndConditions?.servicesTaxesAndConsumptionCosts}
      />
      <Conditions name={name} readOnly={!canDraftLease} defaultValue={servicesAndConditions?.conditions} />
      <OtherServices name={name} readOnly={!canDraftLease} defaultValue={servicesAndConditions} />
      <Text />
    </FormSection2>
  )
}
