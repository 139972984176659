import React, { useEffect } from 'react'

import { FullScreenSpinner, makeStyles, theme, useLayoutContext, useMediaQuery } from '@guiker/react-framework'
import { getListingAvailability, ListingAvailability } from '@guiker/rental-listing-shared'

import { ConfirmListingInquiryScreenContextProvider, useConfirmListingInquiryScreenContext } from '../../context'
import { useNavigate } from '../../hooks'
import { BookVisit, ConfirmListingDetail, ConfirmListingInquiryMobile, ListingInquiryModule } from './components'

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    flexGrow: 1,
    maxHeight: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
    overflow: 'hidden',
  },
  screenContainer: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    maxWidth: 1440,
    width: '100%',
  },
  listingInquiryModuleContainer: {
    padding: theme.spacing(2),
    minWidth: 400,
    height: '100%',
    overflowY: 'scroll',
  },
  confirmListingDetail: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    borderLeft: `1px solid ${theme.palette.grey[30]}`,
    padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    overflowY: 'scroll',
  },
})

const Content: React.FC = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  const { isConfirmed, listing, listingId, isLoading } = useConfirmListingInquiryScreenContext()
  const listingStatus: ListingAvailability = getListingAvailability(listing)

  if (!isLoading && (!listing || listingStatus !== 'listed')) {
    navigate(`/listings/${listingId}/tenant-preferences`)
    return <FullScreenSpinner />
  }

  if (isMobile) return <ConfirmListingInquiryMobile listing={listing} />

  return (
    <div className={classes.root}>
      <div className={classes.screenContainer}>
        <div className={classes.listingInquiryModuleContainer}>
          <ListingInquiryModule />
        </div>
        <div className={classes.confirmListingDetail}>{isConfirmed ? <BookVisit /> : <ConfirmListingDetail />}</div>
      </div>
    </div>
  )
}

const ConfirmListingInquiryScreen: React.FC = () => {
  const { setFooterIsDisplayed } = useLayoutContext()

  useEffect(() => {
    setFooterIsDisplayed(false)
  }, [])

  return (
    <ConfirmListingInquiryScreenContextProvider>
      <Content />
    </ConfirmListingInquiryScreenContextProvider>
  )
}

export { ConfirmListingInquiryScreen }
