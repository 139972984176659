import React, { createContext, useState } from 'react'

import { Applicant, BookingPayment, Contribution } from '@guiker/booking-shared'

import { useAuthenticationContext, useBookingContext } from '../hooks'

type Context = {
  applicants: Applicant[]
  bookingId: string
  userContribution?: Contribution
  setUserContribution: (contribution: Contribution) => void
  bookingPayment?: BookingPayment
  setBookingPayment: (bookingPayment: BookingPayment) => void
  isSingleTenant: boolean
  refetchBooking: () => Promise<void>
}

const AppContext = createContext<Context>(undefined)

const findUserContribution = (bookingPayment: BookingPayment, userId: string) => {
  return bookingPayment.contributions.find((u) => u.userId === userId)
}

const AppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useAuthenticationContext()
  const { booking, refetchBooking = () => Promise.resolve() } = useBookingContext()

  const [bookingPayment, setBookingPaymentState] = useState<BookingPayment>(booking.bookingPayment)
  const [userContribution, setUserContribution] = useState<Contribution>(findUserContribution(bookingPayment, user.id))

  const setBookingPayment = (bookingPayment: BookingPayment) => {
    setUserContribution(findUserContribution(bookingPayment, user.id))
    setBookingPaymentState(bookingPayment)
  }

  const value = {
    applicants: booking.applicants,
    bookingId: booking.id,
    userContribution,
    setUserContribution,
    bookingPayment,
    setBookingPayment,
    refetchBooking,
    isSingleTenant: bookingPayment.contributions.length === 1,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export { AppContext, AppContextProvider }
