import React from 'react'

import { TwoColumnsGridLayout } from '@guiker/components-library'
import { DateTime } from '@guiker/date'

import { useFormContext, useTranslation } from '../../../hooks'
import { Initials, Signature } from '../../../types'
import { get } from '../../../utils'
import { DatePicker as DatePickerComponent } from '../../'
import { SignatureInput } from '..'

export interface FormSignatureContainerProps {
  name?: string | undefined
  disabled?: boolean
  readOnly?: boolean
  defaultValue?: Signature | Initials | null
  type?: 'initials' | 'signature'
  label?: string
  onChange?: (event: any) => void
}

const DatePicker = (props) => <DatePickerComponent {...props} />

const FormSignatureContainer: React.FC<FormSignatureContainerProps> = ({
  disabled,
  readOnly,
  name,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const dateFieldName = `${name}.date`

  const onSignatureInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(dateFieldName, event.target.value ? DateTime.local() : null)

    if (get(errors, dateFieldName)) {
      clearErrors(dateFieldName)
    }
  }

  return (
    <TwoColumnsGridLayout>
      <SignatureInput
        disabled={disabled}
        name={`${name}.content`}
        defaultValue={defaultValue?.content}
        onChange={onSignatureInputChange}
        {...props}
      />
      <DatePicker
        readOnly={readOnly}
        disabled
        name={dateFieldName}
        defaultValue={defaultValue?.date}
        label={t('common:date')}
        maxWidth='100%'
      />
    </TwoColumnsGridLayout>
  )
}

export { FormSignatureContainer }
