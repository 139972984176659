import quebecLeaseScreenA from '../components/quebec-lease/step-a/zh.i18n.json'
import quebecLeaseScreenB from '../components/quebec-lease/step-b/zh.i18n.json'
import quebecLeaseScreenC from '../components/quebec-lease/step-c/zh.i18n.json'
import quebecLeaseScreenD from '../components/quebec-lease/step-d/zh.i18n.json'
import quebecLeaseScreenE from '../components/quebec-lease/step-e/zh.i18n.json'
import quebecLeaseScreenF from '../components/quebec-lease/step-f/zh.i18n.json'
import quebecLeaseScreenG from '../components/quebec-lease/step-g/zh.i18n.json'
import quebecLeaseScreenH from '../components/quebec-lease/step-h/zh.i18n.json'
import common from './common/zh.i18n.json'
import errors from './errors/zh.i18n.json'

export default {
  common,
  errors,
  quebecLeaseScreenA,
  quebecLeaseScreenB,
  quebecLeaseScreenC,
  quebecLeaseScreenD,
  quebecLeaseScreenE,
  quebecLeaseScreenF,
  quebecLeaseScreenG,
  quebecLeaseScreenH,
}
