import React from 'react'

import {
  Flex,
  FloatingBadge,
  FullScreenSpinner,
  H3,
  H5,
  P,
  PageSection3,
  RouterLink,
  TextButton,
} from '@guiker/react-framework'
import { TaskWithHighlight } from '@guiker/task-components'
import { useAuthenticatedTaskContext } from '@guiker/task-context'

import { useT } from '../../i18n'

type TaskSectionProps = {
  hideIfEmpty?: boolean
  header?: string
  taskContext: string[]
}

export const TaskSection: React.FC<TaskSectionProps> = ({ taskContext, header, hideIfEmpty = true }) => {
  const { getFilteredTasks } = useAuthenticatedTaskContext()
  const tasks = getFilteredTasks({ context: taskContext })
  const { tBase, tMain } = useT({ screenName: 'taskSection' })

  if (hideIfEmpty && !tasks?.length) return

  return (
    <PageSection3
      title={
        <FloatingBadge badgeNumber={tasks?.length > 0 ? tasks?.length : null}>
          <H3 mb={0}>{header}</H3>
        </FloatingBadge>
      }
      topActions={
        <RouterLink to={'/my-investments/todos'}>
          <TextButton>{tBase('actions.seeAll')}</TextButton>
        </RouterLink>
      }
    >
      {!tasks ? (
        <FullScreenSpinner />
      ) : tasks?.length > 0 ? (
        <TaskWithHighlight tasks={tasks} />
      ) : (
        <Flex flexDirection='column' alignItems='center'>
          <Flex flexDirection='column' maxWidth={400} alignItems='center'>
            <H5 textAlign='center'>{tMain('label')}</H5>
            <P textAlign='center'>{tMain('description')}</P>
          </Flex>
        </Flex>
      )}
    </PageSection3>
  )
}
