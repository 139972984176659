import React from 'react'

import { Box } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { ApiForm, ButtonWithLoader, Grid } from '../../../components'
import { FormContent as FormContentA } from '../../../components/quebec-lease/step-a/FormContent'
import { FormContent as FormContentB } from '../../../components/quebec-lease/step-b/FormContent'
import { FormContent as FormContentC } from '../../../components/quebec-lease/step-c/FormContent'
import { FormContent as FormContentD } from '../../../components/quebec-lease/step-d/FormContent'
import { FormContent as FormContentE } from '../../../components/quebec-lease/step-e/FormContent'
import { FormContent as FormContentF } from '../../../components/quebec-lease/step-f/FormContent'
import { FormContent as FormContentH } from '../../../components/quebec-lease/step-h/FormContent'
import { useLeaseJwtAuthenticatedApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Quebec } from '../../../types'

type Props = {
  id: string
  data: Quebec.LeaseInformation
  setHasParticipantSigned: (hasParticipantSigned: boolean) => void
}

const InvitedLessorSignLeaseForm: React.FC<Props> = ({ data, id, setHasParticipantSigned }) => {
  const jwtApiClient = useLeaseJwtAuthenticatedApiClient()
  const { t } = useTranslation()
  const { setLease } = useQuebecLeaseContext()
  const lessorData = data.lessors[0]

  const onSubmit = (payload: any) =>
    jwtApiClient.updateQuebecLeaseInvitedLessorSignatureAndInitials({
      pathParams: { leaseId: id },
      payload: QuebecSchemas.invitedLessorSignatureAndInitials.cast(payload, { stripUnknown: true }),
    })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(QuebecSchemas.invitedLessorSignatureAndInitials),
        defaultValues: {
          smokeDetectorsInitials: lessorData.dwelling.smokeDetectorsInitials,
          signature: lessorData.signature,
          initials: lessorData.initials,
          newLesseeNotice: data.newLesseeNotice,
        },
      }}
      apiOptions={{
        onSuccess: (lease) => {
          setLease(lease)
          setHasParticipantSigned(true)
        },
      }}
      formName={'QuebecInvitedLessorSignLeaseForm'}
    >
      {({ isLoading }) => (
        <Box px={4} pb={4}>
          <Grid container spacing={4} direction='column'>
            <Grid item>
              <FormContentA lessors={data.lessors} lessees={data.lessees} />
            </Grid>
            <Grid item>
              <FormContentB dwelling={data.dwelling} lessors={data.lessors} lessees={data.lessees} />
            </Grid>
            <Grid item>
              <FormContentC leaseTerm={data.leaseTerm} />
            </Grid>
            <Grid item>
              <FormContentD rent={data.rent} />
            </Grid>
            <Grid item>
              <FormContentE servicesAndConditions={data.servicesAndConditions} />
            </Grid>
            <Grid item>
              <FormContentF
                lessors={data.lessors}
                leaseRestrictionsAndModifications={data.leaseRestrictionsAndModifications}
                newLesseeNotice={data.newLesseeNotice}
              />
            </Grid>
            <Grid item>
              <FormContentH lessors={data.lessors} lessees={data.lessees} liability={data.liability} />
            </Grid>
            <Grid item>
              <ButtonWithLoader isLoading={isLoading}>{t('common:confirmAndSign')}</ButtonWithLoader>
            </Grid>
          </Grid>
        </Box>
      )}
    </ApiForm>
  )
}

export { InvitedLessorSignLeaseForm }
