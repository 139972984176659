import React from 'react'

import { TimerCountdown } from '@guiker/booking-components'
import { useAuthenticatedBookingApiClient } from '@guiker/booking-context'
import { ApiFormAction, Flex, useT } from '@guiker/react-framework'

import { useBookingApplicationContext, useBookingContext } from '../../hooks'

const UnitManagerActions: React.FC = () => {
  const { tMain } = useT({ domain: 'tenantApplication' })
  const { booking, bookingChat, refetchBooking } = useBookingContext()
  const { bookingApplication } = useBookingApplicationContext()
  const apiClient = useAuthenticatedBookingApiClient()

  const approveRoommateIntro = () =>
    apiClient.approveBookingRoommateIntro({
      pathParams: { bookingId: booking.id, userId: bookingApplication.userId },
    })

  return (
    <Flex alignItems='center' gap={2}>
      <TimerCountdown />
      <ApiFormAction
        onClick={approveRoommateIntro}
        options={{
          onSuccess: () => {
            refetchBooking()
            bookingChat.navigate()
          },
        }}
      >
        {tMain('screens.roommateProfile.approve')}
      </ApiFormAction>
    </Flex>
  )
}

export { UnitManagerActions }
