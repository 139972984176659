import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { yup } from '@guiker/yup-util'

import { useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../common/Step'
import { FormContent } from './FormContent'

type Props = StepProps

const StepB: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { lessors, lessees, dwelling },
    },
    draftedAs,
  } = useQuebecLeaseContext()

  const onSubmit = ({ dwelling }: { dwelling: QuebecSchemas.Dwelling }) =>
    apiClient.updateQuebecLeaseDwelling({ pathParams: { leaseId }, payload: { ...dwelling, draftedAs } })

  return (
    <Step
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(yup.object({ dwelling: QuebecSchemas.dwelling }), {
          defaultValues: { dwelling: { draftedAs, smokeDetectorsInitials: null } },
        }),
        defaultValues: { dwelling },
      }}
      formName={'QuebecLeaseFormStepB'}
      {...props}
    >
      <FormContent dwelling={dwelling} lessors={lessors} lessees={lessees} />
    </Step>
  )
}

export { StepB }
