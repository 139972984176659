import React from 'react'

import { FormSection1, FormSection2, FormSection3 } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { HeaderSecondaryDescriptionBar } from '../header'
import { FormContent as NewLesseeNotice } from '../step-g/FormContent'
import { RadioOptions } from './RadioOptions'
import { Text } from './Text'

type Props = {
  leaseRestrictionsAndModifications: Quebec.LeaseRestrictionsAndModifications
  newLesseeNotice: Quebec.NewLesseeNotice
  lessors: Quebec.Lessor[]
}

export const FormContent: React.FC<Props> = ({ leaseRestrictionsAndModifications, newLesseeNotice, lessors }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenF'])
  const name = 'leaseRestrictionsAndModifications'

  const { can } = useLeaseContext()
  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection1 hasDivider={false}>
      <FormSection2
        title={<HeaderSecondaryDescriptionBar index='F.' description={t('quebecLeaseScreenF:headerLabel')} />}
      >
        <FormSection3 subtitle={t('quebecLeaseScreenF:label')}>
          <RadioOptions name={name} readOnly={!canDraftLease} defaultValue={leaseRestrictionsAndModifications} />
          <Text />
        </FormSection3>
      </FormSection2>
      <NewLesseeNotice newLesseeNotice={newLesseeNotice} lessors={lessors} />
    </FormSection1>
  )
}
