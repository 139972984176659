import { mainRoutes } from '@guiker/investment-shared'
import { webAppRouterBuilder } from '@guiker/react-framework'

import { InvestmentsFAQScreen, InvestmentsSearchScreen } from '../screens'

export const Router = webAppRouterBuilder(
  mainRoutes,
  {
    root: {
      routes: {
        search: InvestmentsSearchScreen,
        faq: InvestmentsFAQScreen,
        propSharing: InvestmentsSearchScreen,
        propertySale: InvestmentsSearchScreen,
      },
    },
  },
  {
    requireAuthentication: false,
  },
)
