import React from 'react'

import { CollapsibleDescription } from '@guiker/base-listing-components'
import { Flex, H4, Link, useTranslation } from '@guiker/react-framework'
import { RentalListing } from '@guiker/rental-listing-shared'

type DescriptionComponentProps = {
  listing: RentalListing
  onClickEdit?: () => void
}
export const DescriptionComponent: React.FC<DescriptionComponentProps> = ({ listing, onClickEdit }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Flex justifyContent='space-between'>
        <H4>{t('main-rentalListing:description')}</H4>
        {onClickEdit && <Link onClick={onClickEdit}>{t('common:actions.edit')}</Link>}
      </Flex>
      <CollapsibleDescription description={listing.description} />
    </div>
  )
}
