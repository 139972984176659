import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../common/Step'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepF: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { lessors, newLesseeNotice, leaseRestrictionsAndModifications },
    },
    draftedAs,
  } = useQuebecLeaseContext()

  const onSubmit = (payload: QuebecSchemas.NewLesseeNoticeAndLeaseRestrictionsAndModifications) =>
    apiClient.updateQuebecLeaseNewLesseeNoticeAndLeaseRestrictionsAndModifications({
      pathParams: { leaseId },
      payload: {
        leaseRestrictionsAndModifications: {
          ...payload.leaseRestrictionsAndModifications,
        },
        newLesseeNotice: {
          ...payload.newLesseeNotice,
          draftedAs,
        },
      },
    })

  return (
    <Step
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(QuebecSchemas.newLesseeNoticeAndLeaseRestrictionsAndModifications, {
          defaultValues: { newLesseeNotice: { draftedAs, lessorSignature: null } },
        }),
        defaultValues: {
          newLesseeNotice,
          leaseRestrictionsAndModifications,
        },
      }}
      formName={'QuebecLeaseFormStepF'}
      {...props}
    >
      <FormContent
        leaseRestrictionsAndModifications={leaseRestrictionsAndModifications}
        newLesseeNotice={newLesseeNotice}
        lessors={lessors}
      />
    </Step>
  )
}
