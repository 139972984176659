import React from 'react'

import { ButtonContainer, FormSection1 } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { extractAllLeaseEmails, Lease, QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { ApiForm, ButtonWithLoader, MemoizedComponent } from '../../../components'
import { FormContent as FormContentA } from '../../../components/quebec-lease/step-a/FormContent'
import { FormContent as FormContentB } from '../../../components/quebec-lease/step-b/FormContent'
import { FormContent as FormContentC } from '../../../components/quebec-lease/step-c/FormContent'
import { FormContent as FormContentD } from '../../../components/quebec-lease/step-d/FormContent'
import { FormContent as FormContentE } from '../../../components/quebec-lease/step-e/FormContent'
import { FormContent as FormContentF } from '../../../components/quebec-lease/step-f/FormContent'
import { FormContent as FormContentH } from '../../../components/quebec-lease/step-h/FormContent'
import { useAppContext, useLeaseApiClient, useNavigate, useQuebecLeaseContext } from '../../../hooks'
import { Quebec } from '../../../types'

type Props = {
  id: string
  data: Quebec.LeaseInformation
}

const SignLeaseForm: React.FC<Props> = ({ data, id }) => {
  const apiClient = useLeaseApiClient()
  const { user } = useAppContext()
  const { setLease } = useQuebecLeaseContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const lesseeData = data.lessees.find((l) => l.userId === user.id)

  const onSubmit = (payload: unknown) =>
    apiClient.updateQuebecLeaseSignatureAndLesseeInformation({
      pathParams: { leaseId: id },
      payload: QuebecSchemas.signatureAndLesseeInformation.cast(payload, { stripUnknown: true }),
    })

  const resolver = yupResolver(QuebecSchemas.signatureAndLesseeInformation, {
    context: { emails: [user.emailAddress, ...extractAllLeaseEmails(data as unknown as Lease)] },
  })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver,
        defaultValues: lesseeData,
      }}
      apiOptions={{
        onSuccess: (lease) => {
          setLease(lease)
          navigate('../feedback')
        },
      }}
      formName={'QuebecSignLeaseForm'}
    >
      {({ isLoading, formState: { errors } }) => (
        <>
          <FormSection1 hasDivider={false}>
            <MemoizedComponent
              Component={FormContentA}
              props={{ lessors: data.lessors, lessees: data.lessees }}
              memoHint={[errors?.leaseTerm, errors?.lessees]}
            />
            <MemoizedComponent
              Component={FormContentB}
              props={{
                dwelling: data.dwelling,
                lessors: data.lessors,
                lessees: data.lessees,
              }}
              memoHint={[errors?.dwelling, errors?.lessors, errors?.lessees]}
            />
            <MemoizedComponent
              Component={FormContentC}
              props={{ leaseTerm: data.leaseTerm }}
              memoHint={errors?.leaseTerm}
            />
            <MemoizedComponent Component={FormContentD} props={{ rent: data.rent }} memoHint={errors?.rent} />
            <MemoizedComponent
              Component={FormContentE}
              props={{ servicesAndConditions: data.servicesAndConditions }}
              memoHint={errors?.servicesAndConditions}
            />
            <MemoizedComponent
              Component={FormContentF}
              props={{
                lessors: data.lessors,
                leaseRestrictionsAndModifications: data.leaseRestrictionsAndModifications,
                newLesseeNotice: data.newLesseeNotice,
              }}
              memoHint={[errors?.lessors, errors?.leaseRestrictionsAndModifications, errors?.newLesseeNotice]}
            />
            <MemoizedComponent
              Component={FormContentH}
              props={{
                lessors: data.lessors,
                lessees: data.lessees,
                liability: data.liability,
              }}
              memoHint={[errors?.lessors, errors?.lessees, errors?.liability]}
            />
          </FormSection1>
          <ButtonContainer>
            <ButtonWithLoader isLoading={isLoading}>{t('common:confirmAndSign')}</ButtonWithLoader>
          </ButtonContainer>
        </>
      )}
    </ApiForm>
  )
}

export { SignLeaseForm }
