import React, { useState } from 'react'

import { PayInMethodModals, PayInMethodSelector } from '@guiker/payment-app-components'
import {
  AuthApi,
  FundingAccount,
  FundingAccountDepositAllowedPayInMethodTypes,
  getSupportedCountryFromCurrency,
} from '@guiker/payment-shared'
import { yupResolver } from '@guiker/react-hook-form'

import {
  PayInMethodContextProvider,
  useAuthenticatedFundingAccountContext,
  useAuthenticatedPaymentApiClient,
  usePayInMethodContext,
} from '../../hooks'
import { useT } from '../../i18n'
import { ApiForm, ButtonWithLoader, CurrencyTextField, Flex, H4, Modal, ModalProps, SecondaryButton } from '..'
import { Breakdown } from './Breakdown'

export type DepositModalProps = ModalProps & {
  fundingAccount: FundingAccount
  refetch: () => void
}

export type DepositFormProps = {
  fundingAccount: FundingAccount
  onClose: () => void
  refetch: () => void
}

const DepositForm: React.FC<DepositFormProps> = ({ fundingAccount, onClose, refetch }) => {
  const { tMain } = useT({ screenName: 'components.depositModal' })
  const { refetch: refetchFundingAccount } = useAuthenticatedFundingAccountContext()
  const { selected } = usePayInMethodContext()
  const apiClient = useAuthenticatedPaymentApiClient()
  const [amount, setAmount] = useState(0)

  const onSubmit = async () =>
    selected &&
    apiClient.depositFundingAccount({
      pathParams: { fundingAccountId: fundingAccount.id },
      payload: { payInMethodId: selected.id, amount },
    })

  const onSuccess = () => {
    refetch()
    refetchFundingAccount()
    onClose()
  }

  const handleAmountChange = (input: string) => {
    const monetizedAmount = Number(input) ?? 0
    setAmount(monetizedAmount)
  }

  const resolver = yupResolver(AuthApi.FundingAccountSchemas.createDepositSchema, {
    defaultValues: { payInMethodId: selected?.id },
  })

  return (
    <ApiForm
      onSubmit={onSubmit}
      apiOptions={{ onSuccess }}
      formName='CreateFundingAccountDeposit'
      formOptions={{ resolver }}
    >
      {({ isLoading, formState: { errors } }) => (
        <Flex flexDirection='column' gap={5}>
          <CurrencyTextField
            maxWidth={200}
            label={tMain('depositAmount')}
            name='amount'
            defaultValue={amount}
            onChange={(v) => handleAmountChange(v)}
          />
          <div>
            <H4>{tMain('selectPayment')}</H4>
            <PayInMethodSelector condensed showLabel={false} />
          </div>
          <div>
            <H4>{tMain('breakdown')}</H4>
            <Breakdown fundingAccount={fundingAccount} amount={amount} />
          </div>
          <Flex gap={2} justifyContent='flex-end'>
            <SecondaryButton onClick={() => onClose()}>{tMain('cancel')}</SecondaryButton>
            <ButtonWithLoader disabled={!selected} isLoading={isLoading} errors={errors} type='submit'>
              {tMain('cta')}
            </ButtonWithLoader>
          </Flex>
        </Flex>
      )}
    </ApiForm>
  )
}

const DepositModal: React.FC<DepositModalProps> = ({ open, onClose, fundingAccount, refetch }) => {
  const { tMain } = useT({ screenName: 'components.depositModal' })

  const region = getSupportedCountryFromCurrency(fundingAccount.currency)
  const allowedTypes = FundingAccountDepositAllowedPayInMethodTypes

  return (
    <Modal open={open} keepMounted={false} title={tMain('title')} onClose={onClose} maxWidth={500}>
      <PayInMethodContextProvider region={region} allowedTypes={allowedTypes}>
        <DepositForm onClose={onClose} fundingAccount={fundingAccount} refetch={refetch} />
        <PayInMethodModals />
      </PayInMethodContextProvider>
    </Modal>
  )
}

export { DepositModal }
