import React from 'react'

import parse, { domToReact } from 'html-react-parser'

import { clsx } from '@guiker/react-framework'

import { Caption, H2, H3, H4, Link, makeStyles, P as BaseP, theme } from '../'

type HTMLToComponentLibraryProps = {
  html: string
}

type ComponentWithClassName = React.PropsWithChildren & {
  className?: string
}

const useStyles = makeStyles(
  {
    bold: {
      fontWeight: 'bold',
    },
    figure: {
      padding: 0,
      margin: 0,
      marginBottom: theme.spacing(4),
    },
    image: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
    figCaption: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    list: {
      marginBottom: theme.spacing(4),
    },
  },
  { name: 'WPElement' },
)

const Figure: React.FC<ComponentWithClassName> = ({ className, ...props }) => {
  const classes = useStyles()

  return <figure className={clsx(className, classes.figure)} {...props} />
}

const Image: React.FC<ComponentWithClassName> = ({ className, children, ...props }) => {
  const classes = useStyles()
  const renamedAttributes = renameAttributes(props)

  if (renamedAttributes.src) {
    renamedAttributes.src = (renamedAttributes.src as string).replace(/^http:\/\//, 'https://')
  }

  return <img className={clsx(className, classes.image)} {...renamedAttributes} />
}

const LI: React.FC<ComponentWithClassName> = ({ children, ...props }) => {
  return (
    <li {...props}>
      <BaseP component='span' mb={0}>
        {children}
      </BaseP>
    </li>
  )
}

const UL: React.FC<ComponentWithClassName> = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <ul className={clsx(className, classes.list)} {...props}>
      {children}
    </ul>
  )
}

const OL: React.FC<ComponentWithClassName> = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <ol className={clsx(className, classes.list)} {...props}>
      {children}
    </ol>
  )
}

const FigCaption: React.FC<ComponentWithClassName> = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <figcaption className={clsx(className, classes.figCaption)} {...props}>
      <Caption mb={0}>{children}</Caption>
    </figcaption>
  )
}

const A: React.FC<ComponentWithClassName & { href: string }> = ({ href, ...props }) => {
  return <Link {...props} to={href} isExternalLink />
}

const P: React.FC<ComponentWithClassName> = ({ ...props }) => {
  return <BaseP mb={4} {...props} />
}

const PBold: React.FC<ComponentWithClassName> = ({ ...props }) => {
  const classes = useStyles()

  return <span className={classes.bold} {...props} />
}

const renameAttributes = (attr: object): Record<string, unknown> => {
  const cleanedAttributes = {}

  Object.entries(attr).forEach(([key, value]) => {
    if (ATTRIBUTES_REPLACE_MAP[key]) {
      cleanedAttributes[ATTRIBUTES_REPLACE_MAP[key]] = value
    } else {
      cleanedAttributes[key] = value
    }
  })

  return cleanedAttributes
}

const ATTRIBUTES_REPLACE_MAP = {
  srcset: 'srcSet',
}

const REPLACE_MAP = {
  p: P,
  h1: H2,
  h2: H3,
  h3: H4,
  h4: H4,
  figure: Figure,
  img: Image,
  li: LI,
  ul: UL,
  ol: OL,
  a: A,
  strong: PBold,
  figcaption: FigCaption,
}

const HTMLToComponentLibrary: React.FC<HTMLToComponentLibraryProps> = ({ html }) => {
  const options = {
    replace: function ({
      name,
      attribs: { class: className, style, ...attribs } = { class: null, style: null },
      children,
    }) {
      if (REPLACE_MAP[name]) {
        const Component = REPLACE_MAP[name]

        if (name === 'p' && children[0] && children[0].name === 'img') {
          return domToReact(children, options)
        }

        return <Component {...attribs}>{domToReact(children, options)}</Component>
      }

      return children ? <>{domToReact(children, options)}</> : children
    },
  }

  return <>{parse(html, options)}</>
}

export { HTMLToComponentLibrary }
