import { useContext } from 'react'

import { AppContext } from '../context'

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext can only be used inside AppContext')
  }

  return context
}
