import React, { useState } from 'react'

import { Paginate } from '@guiker/api-definition'
import { useAuthenticationContext } from '@guiker/authentication-context'
import { searchOptions } from '@guiker/booking-components'
import { BookingStatus, ParticipantType } from '@guiker/booking-shared'
import { Dropdown, Flex } from '@guiker/components-library'
import { PaginatedTable } from '@guiker/react-query-components'

import { H3, Inline, PageLayout } from '../../components'
import { useAuthenticatedBookingApiClient } from '../../hooks'
import { getBookingColumns } from './get-booking-columns'

export const ListBookingsScreen: React.FC = () => {
  const { user } = useAuthenticationContext()
  const apiClient = useAuthenticatedBookingApiClient()

  const [status, setStatus] = useState<BookingStatus | 'ALL'>('ALL')
  const [participantType, setParticipantType] = useState<ParticipantType | 'ALL'>('ALL')

  const fetcher = (paginate: Paginate) => {
    const filter = {
      ...((status === 'ALL' ? {} : { status }) as { status: BookingStatus }),
      ...((participantType === 'ALL' ? {} : { participantType }) as { participantType: ParticipantType }),
      includeSpectator: false,
    }

    return apiClient.readAllBookings({ queryParams: { ...filter, ...paginate } })
  }

  return (
    <PageLayout>
      <H3>Bookings</H3>
      <Flex alignItems='center' my={2} gap={3}>
        <Inline>List bookings with Status: </Inline>
        <Dropdown
          options={searchOptions.statusOptions}
          onChange={(e) => setStatus(e.target.value as BookingStatus)}
          defaultValue={'ALL'}
        />
        <Inline>As participant type: </Inline>
        <Dropdown
          options={searchOptions.participantOptions}
          onChange={(e) => setParticipantType(e.target.value as ParticipantType)}
          defaultValue={'ALL'}
        />
      </Flex>
      <PaginatedTable
        isRowCollapsible
        queryKey={['readAllBookings', user?.id].join('-')}
        fetcher={fetcher}
        columns={getBookingColumns()}
        refetchTrigger={[status, participantType].join(':')}
        pagination={{
          sort: 'updatedAt',
          sortOrder: -1,
          perPage: 20,
        }}
      />
    </PageLayout>
  )
}
