import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useAuthApiClient } from '@guiker/propsharing-context'
import { webappRoutes } from '@guiker/propsharing-shared'
import { FullScreenSpinner, Redirect, useQuery } from '@guiker/react-framework'

export const FindOrCreateInvestorProfileAndRedirectScreen: React.FC = () => {
  const apiClient = useAuthApiClient()
  const { user } = useAuthenticationContext()

  const { data } = useQuery(
    [user.id, 'read-or-create-investor-profile'],
    (_) =>
      apiClient.readOneOrCreateInvestorProfileByUserId({
        pathParams: { userId: user.id },
      }),
    {
      retry: 1,
      enabled: !!user,
    },
  )

  if (data) {
    return <Redirect path={webappRoutes.investorProfile.path} />
  }

  return <FullScreenSpinner />
}
