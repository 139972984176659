import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Flex, Link, PSmall, theme, useMediaQuery, useModal } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { DollarIcon } from '@guiker/icons'
import { money } from '@guiker/money'
import { useJwtBookingRentPaymentsPlanScopeNestedContext } from '@guiker/rent-payment-context'
import { RentPaymentsPlanStatus } from '@guiker/rent-payment-shared'

import { ChangeContributionModal } from './ChangeContributionModal'

export const ChangeContributionLink: React.FC = () => {
  const { t } = useTranslation(['main-bookingRentPayment'])
  const { data: rentPaymentsPlan } = useJwtBookingRentPaymentsPlanScopeNestedContext()
  const { openModal, closeModal, isOpen } = useModal()
  const { user: currentUser } = useAuthenticationContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const payer = rentPaymentsPlan.payers.find((p) => p.userId === currentUser.id)

  if (rentPaymentsPlan.status !== RentPaymentsPlanStatus.ACTIVE || rentPaymentsPlan.payers.length <= 1) {
    return <></>
  }

  return (
    <Flex fullWidth alignItems={isMobile ? 'flex-end' : 'flex-start'} flexDirection='column'>
      <Link color={50} onClick={() => openModal()} underline>
        <Flex alignItems={!!payer.tentativeAmount ? 'flex-start' : 'center'} gap={1}>
          <DollarIcon />
          <PSmall mb={0}>{t('rentPaymentsPlanTable.actions.changeContributionMethod')}</PSmall>
        </Flex>
      </Link>
      {!!payer.tentativeAmount && (
        <Flex
          fullWidth
          alignItems={isMobile ? 'flex-end' : 'space-between'}
          justifyContent={isMobile ? 'flex-end' : 'space-between'}
          flexDirection={isMobile ? 'column' : 'row'}
          pl={3}
          gap={isMobile ? 0 : 1}
        >
          <PSmall mb={0} color={50}>
            {t('components.changeContribution.tentativeAmount')}
          </PSmall>
          <PSmall mb={0} color={50}>
            {money.fromAmount(payer.tentativeAmount, rentPaymentsPlan.total.currency).toString()}
          </PSmall>
        </Flex>
      )}
      <ChangeContributionModal isOpen={isOpen} onClose={closeModal} />
    </Flex>
  )
}
