import { createContext } from 'react'

import { Booking, getParticipantType, Participant } from '@guiker/booking-shared'

export type AppContextType = {
  booking: Booking | null
  isUnitManager: boolean
  canCreateLease: boolean
  participant: Participant | null
  participantType: ReturnType<typeof getParticipantType> | 'guarantor' | 'invited-lessor' | ''
  user: {
    id: string
    accessToken: string
    emailAddress: string
  } | null
  claims: {
    lesseeId?: string
    leaseId?: string
    emailAddress?: string
  } | null
  disabledForCurrentUser: ({ userId }: { userId?: string }) => boolean
}

export const AppContext = createContext<AppContextType>(null)
