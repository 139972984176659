import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'

import { TFunction, useFormContext, useLeaseContext, useWatch } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { Checkbox, Collapse, TextField } from '../../'
import { FormSection4, TwoColumnsGridLayout } from '../../common'
import { SignatureContainer } from '../../common/SignatureContainer'

type Props = {
  defaultValue: Types.Guarantor
  t: TFunction
  readOnly?: boolean
}

export const Guarantors: React.FC<Props> = ({ defaultValue, t, readOnly = false }) => {
  const { can } = useLeaseContext()
  const { setValue } = useFormContext()
  const isGuarantor = can(LeaseActions.SignLeaseAsGuarantor)
  const canActAsLessor = can(LeaseActions.SignLeaseAsLessor)
  const canDraftLease = can(LeaseActions.DraftLease)
  const isLessee = can(LeaseActions.SignLeaseAsLessee)

  const prefix = isGuarantor ? '' : 'guarantorWithoutSignature.'
  const hasGuarantor =
    (!readOnly || canActAsLessor) &&
    useWatch({ name: `${prefix}hasGuarantor`, defaultValue: defaultValue?.hasGuarantor })

  const clearGuarantor = () => {
    setValue(`guarantorWithoutSignature`, {
      firstName: null,
      lastName: null,
      emailAddress: null,
      hasGuarantor: null,
      signature: {
        content: null,
        date: null,
      },
    })
  }

  const handleGuarantor = () => {
    if (isLessee) {
      hasGuarantor && clearGuarantor()
    }
  }

  return (
    <FormSection4>
      <Checkbox
        defaultValue={defaultValue?.hasGuarantor}
        name={`${prefix}hasGuarantor`}
        label={t('ontarioLeaseScreenI:addGuarantor')}
        readOnly={readOnly || !isLessee}
        onClick={handleGuarantor}
        maxWidth={420}
      />
      <Collapse in={hasGuarantor || (readOnly && defaultValue?.hasGuarantor)}>
        <TwoColumnsGridLayout>
          <TextField
            defaultValue={defaultValue?.firstName}
            name={`${prefix}firstName`}
            label={t('common:guarantorFirstName')}
            readOnly={canDraftLease || canActAsLessor}
            maxWidth={420}
          />
          <TextField
            defaultValue={defaultValue?.lastName}
            name={`${prefix}lastName`}
            label={t('common:guarantorLastName')}
            readOnly={canDraftLease || canActAsLessor}
            maxWidth={420}
          />
          <TextField
            defaultValue={defaultValue?.emailAddress}
            name={`${prefix}emailAddress`}
            label={t('common:guarantorEmailAddress')}
            readOnly={canDraftLease || canActAsLessor}
            maxWidth={420}
            type='email'
          />
        </TwoColumnsGridLayout>
        <SignatureContainer
          defaultValue={defaultValue?.signature}
          name={`${prefix}signature`}
          readOnly={!isGuarantor}
          label={t('ontarioLeaseScreenI:signature')}
          maxWidth={420}
        />
      </Collapse>
    </FormSection4>
  )
}
