import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { FormSection2, HeaderDescriptionBar, RadioGroup } from '../../'
import { Smoking } from './Smoking'

type Props = {
  smoking: Types.Smoking
  tenantsInsurance: Types.TenantsInsurance
}

export const FormContent: React.FC<Props> = ({ smoking, tenantsInsurance }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <>
      <FormSection2 title={<HeaderDescriptionBar index='10.' description={t('ontarioLeaseScreenF:section10Header')} />}>
        <Smoking defaultValue={smoking} name='smoking' readOnly={!canDraftLease} t={t} />
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='11.' description={t('ontarioLeaseScreenF:section11Header')} />}>
        <RadioGroup
          defaultValue={tenantsInsurance?.isTenantsInsuranceRequired}
          name='tenantsInsurance.isTenantsInsuranceRequired'
          label={t('ontarioLeaseScreenF:selectOne')}
          options={[
            { value: false, label: t('ontarioLeaseScreenF:noInsuranceRequirements') },
            { value: true, label: t('ontarioLeaseScreenF:tenantsInsurance') },
          ]}
          gridContainerProps={{ spacing: 2 }}
          readOnly={!canDraftLease}
          maxWidth={420}
        />
      </FormSection2>
    </>
  )
}
