import React from 'react'

import { PayInMethodSelector } from '@guiker/payment-app-components'
import { PageLayout } from '@guiker/react-framework'

import { useT } from '../../i18n'

export const InsufficientFundFlaggedScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'insufficientFunds' })

  return (
    <PageLayout title={tMain('title')} subtitle={tMain('description')}>
      <PayInMethodSelector />
    </PageLayout>
  )
}
