import React from 'react'

import { AuthenticatedApi } from '@guiker/background-check-shared'
import { ColumnGridItem, FormSection1, FormSection2, TwoColumnsGridLayout } from '@guiker/components-library'
import { CountryDropdown } from '@guiker/country-dropdown'
import { yupResolver } from '@guiker/react-hook-form'
import { useMutation } from '@guiker/react-query'
import { useNavigate } from '@guiker/router'

import { ApiForm, DatePicker, P, Step, StepProps, TextField } from '../../components'
import { useBackgroundCheckApiClient, useBackgroundCheckFormContext } from '../../hooks'
import { useTranslation } from '../../i18n'

type FormData = Omit<
  AuthenticatedApi.Schemas.CreateBackgroundCheckPayload,
  'requestSoftcheck' | 'requestCreditReport' | 'targetId'
>

export type Step02Props = StepProps
const Step02: React.FC<Step02Props> = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { backgroundCheckForm } = useBackgroundCheckFormContext()
  const apiClient = useBackgroundCheckApiClient()

  const { mutate: createBackgroundCheck } = useMutation(
    ({ payload }: { payload: any /* CreateBackgroundCheckPayload */ }) => apiClient.createBackgroundCheck({ payload }),
    {
      onSuccess: () => navigate(`../`),
    },
  )

  const onSubmit = async (data: FormData) => {
    const payload: AuthenticatedApi.Schemas.CreateBackgroundCheckPayload = {
      ...data,
      requestCreditReport: backgroundCheckForm.requestCreditReport,
      requestSoftcheck: backgroundCheckForm.requestSoftcheck,
      targetId: backgroundCheckForm.targetId,
    }

    return createBackgroundCheck({ payload })
  }

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(
          (AuthenticatedApi.Schemas.createBackgroundCheckPayloadValidator as any).pick([
            'firstName',
            'lastName',
            'address',
            'city',
            'provinceState',
            'postalCode',
            'country',
            'dateOfBirth',
            'ssn',
            'email',
          ]),
        ),
      }}
      formName={'BackgroundCheckRequestStep02'}
    >
      {({ isLoading, formState: { errors } }) => (
        <Step isSubmitting={isLoading} errors={errors} {...props}>
          <FormSection1>
            <FormSection2 title={t('step02:applicantPersonalInformation')}>
              <TwoColumnsGridLayout>
                <ColumnGridItem md={2}>
                  <P mt={2}>{t('step02:consentEmailWillBeSent')}</P>
                </ColumnGridItem>
                <TextField label={t('step02:firstName')} name='firstName' required />
                <TextField label={t('step02:lastName')} name='lastName' required />
                <TextField label={t('step02:email')} name='email' type='email' required />
                <DatePicker label={t('step02:dateOfBirth')} name='dateOfBirth' required />
                <TextField label={t('step02:sinNumber')} name='ssn' />
              </TwoColumnsGridLayout>
            </FormSection2>

            <FormSection2 title={t('step02:applicantAddress')}>
              <TwoColumnsGridLayout>
                <TextField label={t('step02:address')} name='address' required />
                <TextField label={t('step02:city')} name='city' required />
                <TextField label={t('step02:provinceState')} name='provinceState' required />
                <TextField label={t('step02:postalCode')} name='postalCode' required />
                <CountryDropdown
                  label={t('step02:country')}
                  name='country'
                  supportedCountries={['CA', 'US']}
                  required
                />
              </TwoColumnsGridLayout>
            </FormSection2>
          </FormSection1>
        </Step>
      )}
    </ApiForm>
  )
}

export { Step02 }
