import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { Flex } from '@guiker/react-framework'
import { RentCollectionPerReceivedPayoutTable } from '@guiker/statistic-components'
import { findPayoutGroupNameByStatus, PayoutStatusGroupName } from '@guiker/statistic-shared'

import { DashboardLayout } from '../../components'
import { RentCollectionContextProvider, RentCollectionMonthPicker, useRentCollectionContext } from '../../context'
import { useT } from '../../i18n'

const TableContent: React.FC = () => {
  const { date, payoutStatuses, setPayoutStatuses } = useRentCollectionContext()
  const statusGroupName = findPayoutGroupNameByStatus(payoutStatuses ?? [])

  return (
    <Flex flexDirection='column' gap={4} pt={2}>
      <RentCollectionMonthPicker />
      <RentCollectionPerReceivedPayoutTable
        date={date}
        isPaginated={false}
        selectedStatusGroup={statusGroupName as PayoutStatusGroupName}
        onSelect={setPayoutStatuses}
      />
    </Flex>
  )
}

export const RentPayoutScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens' })
  const title = tMain('rentCollectionPayout.title')

  return (
    <DashboardLayout
      breadcrumbItems={[
        { to: mainPathBuilder.root.path, label: tMain('dashboard.title') },
        { to: mainPathBuilder.root.rentalDashboard.path, label: tMain('dashboard.rentalDashboard.title') },
        { label: title },
      ]}
      title={title}
    >
      <RentCollectionContextProvider>
        <TableContent />
      </RentCollectionContextProvider>
    </DashboardLayout>
  )
}
