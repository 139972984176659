import { Outlet, webAppRouterBuilder } from '@guiker/react-framework'
import { mainRoutes } from '@guiker/rental-tenancy-shared'

import { Home, TenantPreferencesScreen } from '../screens'

export const Router = webAppRouterBuilder(mainRoutes, {
  root: {
    Component: Outlet,
    routes: {
      home: Home,
      tenantPreferences: TenantPreferencesScreen,
    },
  },
})
