import React from 'react'

import { getAllowedCapabilities, PayoutMethod } from '@guiker/payout-shared'
import { Autocomplete, FormSection, useT } from '@guiker/react-framework'

import { useEditLinkContext } from '../../context'
import { EditButton } from '../EditButton'

export type SelectCapabilitiesFormContentProps = {
  defaultValue: Partial<PayoutMethod>
  readOnly?: boolean
  title?: string
}

const SelectCapabilitiesFormContent: React.FC<SelectCapabilitiesFormContentProps> = ({
  defaultValue,
  readOnly = false,
  title,
}) => {
  const { tMain } = useT({ domain: 'payout' })
  const allowedCapabilities = getAllowedCapabilities(defaultValue as PayoutMethod)
  const { editUrl } = useEditLinkContext({ shouldThrowIfUndefined: false }) ?? {}

  return (
    <FormSection hasDivider title={title} topActions={readOnly && <EditButton url={editUrl} />}>
      <Autocomplete
        readOnly={readOnly}
        type='tag'
        name={'capabilities'}
        label={readOnly ? undefined : tMain('selectCapabilities.label')}
        options={allowedCapabilities}
        defaultValue={defaultValue?.capabilities}
        getOptionLabel={(value: string) => {
          return tMain(`selectCapabilities.options.${value.toLowerCase()}`)
        }}
      />
    </FormSection>
  )
}

export { SelectCapabilitiesFormContent }
