import React from 'react'

import { initializeAddress, PayoutMethod } from '@guiker/payout-shared'
import { DeepPartial, useFieldArray } from '@guiker/react-hook-form'

import { AccountHolderFormContent } from './AccountHolderFormContent'

type AccountHolderFormProps = {
  defaultValue?: DeepPartial<PayoutMethod>
  readOnly: boolean
  title?: string
}

const MIN_YEARS_AT_ADDRESS = 3

const AccountHolderForm: React.FC<AccountHolderFormProps> = ({ defaultValue, title, readOnly }) => {
  const prefix = 'accountHolder' as const

  const {
    fields: addresses,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray<PayoutMethod, 'accountHolder.addresses'>({
    name: `${prefix}.addresses`,
  })

  const computeTotalYears = (byIndex: number) =>
    addresses.reduce((sum, { yearsAtAddress }, index) => {
      return index <= byIndex ? Number(sum) + Number(yearsAtAddress) : Number(sum)
    }, 0)

  const updateAddressFieldArray = (index: number, value: number) => {
    const lastIndex = addresses.length - 1
    addresses[index].yearsAtAddress = value
    const total = computeTotalYears(lastIndex)

    if (index < lastIndex && computeTotalYears(index) >= MIN_YEARS_AT_ADDRESS) {
      removeAddress(index + 1)
    } else if (total < MIN_YEARS_AT_ADDRESS || computeTotalYears(lastIndex) < MIN_YEARS_AT_ADDRESS) {
      appendAddress(initializeAddress(defaultValue?.type))
    }
  }

  return (
    <AccountHolderFormContent
      defaultValue={defaultValue}
      addresses={addresses}
      readOnly={readOnly}
      title={title}
      onYearAtAddressChange={updateAddressFieldArray}
    />
  )
}

export { AccountHolderForm }
