import React from 'react'

import { useAuthenticationModalContext } from '@guiker/authentication-components'
import { AuthType, useAuthenticationContext } from '@guiker/authentication-context'
import { BookingContextProvider, useBookingContext } from '@guiker/booking-context'
import { FullScreenSpinner } from '@guiker/components-library'
import { PageNotFound } from '@guiker/error-pages'
import { Outlet, useParams } from '@guiker/router'

const Content: React.FC = () => {
  const { isFetching, booking, error } = useBookingContext()

  if (error) {
    return <PageNotFound />
  }

  if (isFetching || !booking) {
    return <FullScreenSpinner />
  }

  return <Outlet />
}

const BookingNestedRouter: React.FC = () => {
  const { user } = useAuthenticationContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const { bookingId } = useParams()

  if (!user) {
    openAuthenticationModal(AuthType.LOGIN)
    return <FullScreenSpinner />
  }

  return (
    <BookingContextProvider bookingId={bookingId}>
      <Content />
    </BookingContextProvider>
  )
}

export { BookingNestedRouter }
