import React from 'react'

import { getApplicationStatusAfterSubmit } from '@guiker/booking-components'
import { yupResolver } from '@guiker/react-hook-form'
import { CurrentAddressFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, TenantApplication } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useBookingApplicationContext } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '../'

type CurrentAddressStepProps = StepProps
const CurrentAddressStep: React.FC<CurrentAddressStepProps> = ({ onClickNext, ...props }) => {
  const { isLoading, bookingApplication, setBookingApplication } = useBookingApplicationContext()

  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const resolver = yupResolver(AuthenticatedApi.Schema.updateTenantApplicationCurrentAddressOccupationPayloadValidator)
  const handleSubmit = (
    payload: AuthenticatedApi.Schema.UpdateTenantApplicationCurrentAddressOccupationPayloadValidator,
  ) =>
    apiClient.updateAddressAndOccupation({
      payload: AuthenticatedApi.Schema.updateTenantApplicationCurrentAddressOccupationPayloadValidator.cast(payload),
    })

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      formName='BookingApplicationCurrentAddressStep'
      formOptions={{
        resolver,
        defaultValues: bookingApplication?.content as TenantApplication,
      }}
      onSubmit={handleSubmit}
      apiOptions={{
        onSuccess: (content) => {
          setBookingApplication({
            ...bookingApplication,
            content,
            status: getApplicationStatusAfterSubmit(bookingApplication?.status),
          })
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { errors, isSubmitting } }) => (
        <Step isSubmitting={isSubmitting} errors={errors} {...props}>
          <CurrentAddressFormContent
            tenantApplication={bookingApplication?.content as TenantApplication}
            schema={AuthenticatedApi.Schema.updateTenantApplicationCurrentAddressOccupationPayloadValidator}
            {...props}
          />
        </Step>
      )}
    </ApiForm>
  )
}

export { CurrentAddressStep }
