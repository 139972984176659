import React, { useEffect } from 'react'

import { Contributions, PageLayout, useNavigate } from '../../components'
import { useAppContext } from '../../hooks'
import { useTranslation } from '../../i18n'

const PaymentContributions: React.FC = () => {
  const { t } = useTranslation('common')
  const { isSingleTenant } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    isSingleTenant && navigate('./method')
  }, [isSingleTenant])

  if (isSingleTenant) return <></>

  return (
    <PageLayout
      title={t('addContribution')}
      subtitle={t('mustSpecifyContribution')}
      maxWidth={900 as const}
      hasButtonContainer
    >
      <Contributions />
    </PageLayout>
  )
}

export { PaymentContributions }
