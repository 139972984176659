import React from 'react'

import { Button, Card, Flex, H3, Link, P, TwoColumnsGridLayout } from '@guiker/components-library'
import { mainPathBuilder } from '@guiker/payout-shared'

import { useTranslation } from '../../i18n'
import { PayoutEmptyStateIllustration } from './PayoutEmptyStateIllustration'

export const PayoutPreferencesEmptyState: React.FC = () => {
  const { t } = useTranslation('main-account')
  const tPrefix = 'components.payoutPreferencesEmptyState'

  return (
    <Card shadow='medium' p={4}>
      <TwoColumnsGridLayout gap={10}>
        <Flex flexDirection='column' gap={4}>
          <H3 mb={0}>{t(`${tPrefix}.title`)}</H3>
          <div>
            <P>{t(`${tPrefix}.text`)}</P>
            <P mb={0}>{t(`${tPrefix}.text2`)}</P>
          </div>
          <Link to={mainPathBuilder.root.create.path}>
            <Button>{t(`${tPrefix}.cta`)}</Button>
          </Link>
        </Flex>
        <PayoutEmptyStateIllustration />
      </TwoColumnsGridLayout>
    </Card>
  )
}
