import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'

import { useTranslation } from '../../../hooks'
import { Note, PBold, Typography } from '../../'

export const Text: React.FC = () => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenF'])

  return (
    <Note statusColor='info' title={t('common:note')} icon={<MessagesBubbleIcon />}>
      <Typography>
        <PBold component='span'>{t('quebecLeaseScreenF:ifOneOFTheTwoCheckedLabelBold')}</PBold>
        {t('quebecLeaseScreenF:ifOneOFTheTwoCheckedLabel')}
      </Typography>
      <Typography>
        <PBold component='span'>{t('quebecLeaseScreenF:ifNoneOfTheAboveCheckedLabelBold')}</PBold>
        {t('quebecLeaseScreenF:ifNoneOfTheAboveCheckedLabel')}
      </Typography>
    </Note>
  )
}
