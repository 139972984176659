import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  return (
    <Routes>
      <Route {...routes.payoutMethod} {...props}>
        <Route {...routes.payoutMethod.routes.root} {...props} />
        <Route {...routes.payoutMethod.routes.edit} {...props} />
        <Route {...routes.payoutMethod.routes.investment} {...props} />
      </Route>
      <Route {...routes.new} {...props} />
      <Route {...routes.list} {...props} />
      <Route {...routes.paysafeTermsAndConditions} {...props} />
    </Routes>
  )
}

export { Router }
