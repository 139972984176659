import React from 'react'

import { AuthType, useAuthenticationContext } from '@guiker/authentication-context'
import { ButtonWithLoader, useMutation, useNavigate, useTranslation } from '@guiker/react-framework'

import { useAuthenticationModalContext } from '../../hooks'
import { useAuthLeaseContractApiClient } from '../../lib'

export const SignContractButton: React.FC = () => {
  const { t } = useTranslation(['main-rentalListing'])
  const { user: currentUser } = useAuthenticationContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const leaseContractApiClient = useAuthLeaseContractApiClient()
  const navigate = useNavigate()

  const { mutate: generateSignUrl, isLoading } = useMutation(async () => leaseContractApiClient.generateSignUrl(), {
    onSuccess: (url: string) => navigate(url, { isExternalLink: true }),
  })

  const handleOnClick = () =>
    currentUser?.status === 'ACTIVE'
      ? generateSignUrl()
      : openAuthenticationModal(AuthType.ACTIVATE, { onSuccess: () => generateSignUrl() })

  return (
    <ButtonWithLoader onClick={() => handleOnClick()} isLoading={isLoading}>
      {t('actions.signContractAndPublish')}
    </ButtonWithLoader>
  )
}
