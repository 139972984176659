import React from 'react'

import { PayoutMethodNestedContextProvider } from '@guiker/payout-context'
import { checkCapabilities, IMMUTABLE_STATUS } from '@guiker/payout-shared'
import { FullScreenSpinner, Redirect, useParams } from '@guiker/react-framework'

import { ViewPayoutMethodScreen } from './ViewPayoutMethodScreen'

const PayoutMethodScreen: React.FC = () => {
  const { id } = useParams()

  return (
    <PayoutMethodNestedContextProvider id={id}>
      {({ isFetching, payoutMethod }) => {
        if (isFetching || !payoutMethod) {
          return <FullScreenSpinner />
        } else if (IMMUTABLE_STATUS.includes(payoutMethod.status)) {
          return <ViewPayoutMethodScreen />
        } else if (checkCapabilities.hasOnly.investment(payoutMethod.capabilities)) {
          return <Redirect path='./investment' />
        } else {
          return <Redirect path='./edit' />
        }
      }}
    </PayoutMethodNestedContextProvider>
  )
}

export { PayoutMethodScreen }
