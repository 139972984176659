import React from 'react'

import { useT } from '../../i18n'
import { PriceSlider } from '../PriceSlider'

export const PRICE_RANGE = {
  min: 0,
  max: 100000000,
}

type PriceRangeSliderProps = {
  readOnly?: boolean
}

export const PriceRangeSlider: React.FC<PriceRangeSliderProps> = ({ readOnly }) => {
  const { tMain } = useT({ screenName: 'components.priceRange' })

  return <PriceSlider name='price' label={tMain('label')} readOnly={readOnly} step={1000000} {...PRICE_RANGE} />
}
