import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'

import { useAuthenticationContext, useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { getCurrentParticipant } from '../../../utils/get-current-participant'
import { FullScreenSpinner, Step, StepProps } from '../../common'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepAEdit: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()
  const { user } = useAuthenticationContext()

  const { leaseId, lease } = useQuebecLeaseContext()
  const { lessors = [], lessees = [] } = { ...lease?.leaseInformation }

  const onSubmit = (payload: QuebecSchemas.Participant) => {
    return apiClient.updateQuebecLeaseCurrentParticipant({ pathParams: { leaseId }, payload })
  }

  if (!lease?.leaseInformation) {
    return <FullScreenSpinner />
  }

  return (
    <Step
      hasBackButton={false}
      onSubmit={onSubmit}
      formOptions={{
        defaultValues: getCurrentParticipant({ lessors, lessees, userId: user.id }),
      }}
      formName={'QuebecLeaseFormStepA'}
      {...props}
    >
      <FormContent lessors={lessors} lessees={lessees} />
    </Step>
  )
}
