import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { yup } from '@guiker/yup-util'

import { useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../common/Step'
import { FormContent } from './FormContent'

type StepCProps = StepProps

const StepC: React.FC<StepCProps> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { leaseTerm },
    },
  } = useQuebecLeaseContext()

  const onSubmit = ({ leaseTerm }: { leaseTerm: QuebecSchemas.LeaseTerm }) =>
    apiClient.updateQuebecLeaseTerm({ pathParams: { leaseId }, payload: leaseTerm })

  return (
    <Step
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(yup.object({ leaseTerm: QuebecSchemas.leaseTerm })),
        defaultValues: { leaseTerm },
      }}
      formName={'QuebecLeaseFormStepC'}
      {...props}
    >
      <FormContent leaseTerm={leaseTerm} />
    </Step>
  )
}

export { StepC }
