import React from 'react'

import { BookingActions, getParticipant, getParticipantType } from '@guiker/booking-shared'

import { useAuthenticationContext, useBookingContext } from '../hooks'
import { AppContext, LeaseContextProvider } from '.'

const ParticipantAppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { booking, can, bookingId, accessControl } = useBookingContext()
  const { user } = useAuthenticationContext()

  const participant =
    booking && user
      ? getParticipant({
          booking: booking,
          user: user,
        })
      : null

  const participantType = participant && getParticipantType(participant)
  const isUnitManager = participantType === 'unitManager'
  const canCreateLease = can(BookingActions.DraftLease)

  return (
    <AppContext.Provider
      value={{
        booking,
        canCreateLease,
        claims: null,
        isUnitManager,
        participant,
        participantType,
        disabledForCurrentUser: ({ userId }: { userId?: string }) => userId !== user.id,
        user,
      }}
    >
      <LeaseContextProvider bookingId={bookingId} accessControl={accessControl}>
        {children}
      </LeaseContextProvider>
    </AppContext.Provider>
  )
}

export { ParticipantAppContextProvider }
