import React from 'react'

import { ApiFormAction, ConfirmDialog, Features, useFeatureFlag } from '@guiker/react-framework'
import { addressFormatter } from '@guiker/shared-framework'

import { useAuthenticationContext, useBookingContext } from '../../hooks'
import { useTranslation } from '../../i18n'
import { useBookingApiClient } from '../../lib'

type BookingSubmissionModalProps = {
  open: boolean
  onClose: () => void
}

export const BookingSubmissionModal: React.FC<BookingSubmissionModalProps> = ({ onClose, open }) => {
  const { t } = useTranslation(['common', 'main-bookingApplicant', 'main-tenantApplication'])
  const { bookingId, booking, bookingChat } = useBookingContext()
  const { user } = useAuthenticationContext()
  const bookingApiClient = useBookingApiClient()
  const isPromiseToLeaseEnabled = useFeatureFlag().isEnabled(Features.PromiseToLease)
  const submitApplication = () =>
    bookingApiClient.submitBookingApplication({
      pathParams: { bookingId, applicantUserId: user?.id },
      payload: {
        leasePromise: isPromiseToLeaseEnabled
          ? t('main-bookingApplicant:screens.viewBookingApplication.submissionModal.leasePromise')
          : null,
      },
    })

  return (
    <ConfirmDialog
      open={open}
      title={t('main-bookingApplicant:screens.viewBookingApplication.submissionModal.title')}
      onClose={onClose}
      description={
        isPromiseToLeaseEnabled
          ? t('main-bookingApplicant:screens.viewBookingApplication.submissionModal.leasePromise')
          : t('main-bookingApplicant:screens.viewBookingApplication.submissionModal.description', {
              listing: addressFormatter.printShortAddress(booking.listing.address),
            })
      }
      cancelButtonProps={{ onClick: onClose, children: t('common:cancel') }}
    >
      <ApiFormAction onClick={submitApplication} options={{ onSuccess: bookingChat.navigate }}>
        {t('main-bookingApplicant:actions.submit')}
      </ApiFormAction>
    </ConfirmDialog>
  )
}
