import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { Capabilities } from '@guiker/payout-shared'
import { PageLayout, Redirect, useLocationQuery, useNavigate, useQuery, useTranslation } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient } from '../../hooks'

const CreatePayoutMethodScreen: React.FC = () => {
  const apiClient = useAuthenticatedPayoutApiClient()
  const navigate = useNavigate()
  const { t } = useTranslation('main-payout')
  const { type } = useLocationQuery('type')

  const { data: payoutMethod, isLoading } = useQuery('readOrCreatePayoutMethod', () =>
    apiClient.createPayoutMethod({
      payload: { capabilities: !!type && type === 'invest' ? [Capabilities.INVESTMENT] : [] },
    }),
  )

  if (payoutMethod) {
    return <Redirect path={`../${payoutMethod.id}`} />
  }

  return (
    <PageLayout
      isLoading={isLoading}
      forceTakeover={true}
      maxWidth={900 as const}
      takeoverProps={{
        onClose: () => navigate(webappRoutes.root.wallet().payoutPreferences().path),
      }}
      title={t('main-payout:createPayoutMethodScreen.title')}
    />
  )
}

export { CreatePayoutMethodScreen }
