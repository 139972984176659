import React from 'react'

import { CreditReportOverallScoreChip, SoftcheckOverallScoreChip } from '@guiker/background-check-components'
import { useQuery } from '@guiker/react-query'
import { useNavigate, useParams } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'

import { FeedbackComponent, H4, NavigationList, P, PageLayout } from '../../components'
import { useBackgroundCheckApiClient } from '../../hooks/use-background-check-api-client'
import { useTranslation } from '../../i18n'

const Report: React.FC = () => {
  const { applicantId } = useParams()
  const navigate = useNavigate()
  const apiClient = useBackgroundCheckApiClient()
  const { t } = useTranslation()

  const { data: report, isLoading } = useQuery(['backgroundCheck-readOne', applicantId], () =>
    apiClient.readOneBackgroundCheck({ pathParams: { applicantId } }),
  )

  const items = []

  if (report?.requestSoftcheck) {
    items.push({
      primaryText: <H4 mb={2}>{t('report:softcheckReport')}</H4>,
      disabled: isLoading || !report.results.softcheck,
      hasAvatar: false,
      url: './softcheck-report',
      secondaryText: <SoftcheckOverallScoreChip status={report.results.softcheck.overallScore} />,
    })
  }

  if (report?.requestCreditReport) {
    const score = report?.results?.creditReport?.creditScore
    const status = report?.results?.creditReport?.overallScore
    let disabled
    let secondaryText

    if (!status || status === 'NONE') {
      disabled = true
      secondaryText = (
        <P mb={0} color={60}>
          {t(`reports:stillAnalyzing`)}
        </P>
      )
    } else {
      disabled = false
      secondaryText = <CreditReportOverallScoreChip creditScore={score} />
    }

    items.push({
      primaryText: <H4 mb={1}>{t('report:creditReport')}</H4>,
      disabled: isLoading || disabled,
      hasAvatar: false,
      url: './credit-report',
      secondaryText,
    })
  }

  return (
    <TakeoverContextProvider
      onClose={() => navigate('../..')}
      title={t('report:title', { name: report ? `${report.firstName} ${report.lastName}` : '' })}
    >
      <PageLayout>
        <FeedbackComponent loading={isLoading} />
        {report && <NavigationList hasAvatar={false} items={items} />}
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { Report }
