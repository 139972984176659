import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { FormSection1 } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { ChangePasswordForm } from './FormContent'

const SecurityScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.security' })

  return (
    <AccountPageLayout hasButtonContainer>
      <FormSection1 title={tMain('title')} hasDivider={false}>
        <ChangePasswordForm />
      </FormSection1>
    </AccountPageLayout>
  )
}

export { SecurityScreen }
