import React from 'react'

import { FormSection3, FormSection4, OneColumnGridLayout } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation } from '../../../hooks'
import { Heating } from './Heating'
import { LessorOrLesseeToggle } from './LessorOrLesseeToggle'

type Props = {
  defaultValue: Quebec.ServicesTaxesAndConsumptionCosts | null
  name: string
  readOnly: boolean
}

export const Costs: React.FC<Props> = ({ defaultValue, name: baseName, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const name = `${baseName}.servicesTaxesAndConsumptionCosts`

  return (
    <FormSection3 title={t('quebecLeaseScreenE:costsLabel')}>
      <OneColumnGridLayout>
        <Heating defaultValue={defaultValue} name={name} readOnly={readOnly} />

        <LessorOrLesseeToggle
          defaultValue={defaultValue?.heating}
          description={t('quebecLeaseScreenE:costsBorneBold')}
          readOnly={readOnly}
          name={`${name}.heating`}
        />
      </OneColumnGridLayout>

      <FormSection4 title={t('quebecLeaseScreenE:otherThanHeatingBoldLabel')}>
        <OneColumnGridLayout>
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.gas}
            description={t('quebecLeaseScreenE:otherThanHeatingGas')}
            readOnly={readOnly}
            name={`${name}.gas`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.electricity}
            description={t('quebecLeaseScreenE:otherThanHeatingElectricity')}
            readOnly={readOnly}
            name={`${name}.electricity`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.waterHeater}
            description={t('quebecLeaseScreenE:otherThanHeatingHotWaterHeater')}
            readOnly={readOnly}
            name={`${name}.waterHeater`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.hotWater}
            description={t('quebecLeaseScreenE:otherThanHeatingHotWaterUserFees')}
            readOnly={readOnly}
            name={`${name}.hotWater`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.waterConsumption}
            description={t('quebecLeaseScreenE:otherThanHeatingWaterConsumption')}
            readOnly={readOnly}
            name={`${name}.waterConsumption`}
          />
        </OneColumnGridLayout>
      </FormSection4>

      <FormSection4 title={t('quebecLeaseScreenE:snowIceRemovalBoldLabel')}>
        <OneColumnGridLayout>
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.snowRemoval?.parkingArea}
            description={t('quebecLeaseScreenE:snowIceRemovalParkingArea')}
            readOnly={readOnly}
            name={`${name}.snowRemoval.parkingArea`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.snowRemoval?.balcony}
            description={t('quebecLeaseScreenE:snowIceRemovalBalcony')}
            readOnly={readOnly}
            name={`${name}.snowRemoval.balcony`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.snowRemoval?.entrance}
            description={t('quebecLeaseScreenE:snowIceRemovalEntranceDrivewayWalkway')}
            readOnly={readOnly}
            name={`${name}.snowRemoval.entrance`}
          />
          <LessorOrLesseeToggle
            defaultValue={defaultValue?.snowRemoval?.stairs}
            description={t('quebecLeaseScreenE:snowIceRemovalStairs')}
            readOnly={readOnly}
            name={`${name}.snowRemoval.stairs`}
          />
        </OneColumnGridLayout>
      </FormSection4>
    </FormSection3>
  )
}
