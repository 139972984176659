import { useContext } from 'react'

import { Ontario } from '@guiker/lease-shared'

import { LeaseContext } from '../context'

export const useOntarioLeaseContext = () => {
  const context = useContext(LeaseContext)

  if (context === undefined) {
    throw new Error('useOntarioLeaseContext() can only be used inside LeaseContextProvider')
  }

  if (context.lease && context.lease.type !== 'canada-ontario') {
    throw new Error('useOntarioLeaseContext() can only be used for a "canada-ontario" Lease')
  }

  return {
    ...context,
    lease: context.lease as Ontario.Lease,
  }
}
