import React from 'react'

import { Card, Flex, H3, P, TwoColumnsGridLayout } from '@guiker/components-library'

type EmptyStateLayoutProps = {
  illustration: React.ReactNode
  title: string
  text: string
  cta: React.ReactNode
  direction?: 'ltr' | 'rtl'
}

export const EmptyStateLayout: React.FC<EmptyStateLayoutProps> = ({
  cta,
  direction = 'ltr',
  illustration,
  text,
  title,
}) => {
  return (
    <Card shadow='medium' p={4}>
      <Flex flexDirection='column' gap={5}>
        <TwoColumnsGridLayout gap={10}>
          {direction === 'rtl' && illustration}
          <Flex flexDirection='column' gap={5}>
            <H3 mb={0}>{title}</H3>
            <Flex flexDirection='column' gap={4}>
              <P mb={0}>{text}</P>
              {cta}
            </Flex>
          </Flex>
          {direction === 'ltr' && illustration}
        </TwoColumnsGridLayout>
      </Flex>
    </Card>
  )
}
