import React, { useEffect } from 'react'

import { IdentityVerificationSummary } from '@guiker/identity-verification-components'
import { StockOrderStatus, webappRoutes } from '@guiker/propsharing-shared'
import { Flex, P, Step, StepProps } from '@guiker/react-framework'
import { useNavigate } from '@guiker/router'

import { useAuthenticatedPropSharingInvestorProfileContext } from '../../../hooks'
import { useT } from '../../../i18n'

export const IdentityVerification: React.FC<StepProps> = (props) => {
  const { apiClient, data: investorProfile, refetch } = useAuthenticatedPropSharingInvestorProfileContext()
  const navigate = useNavigate()
  const { tBase, tMain } = useT({})

  const tPrefix = 'screens.createInvestorProfileScreen.step5'

  const navigateToPage = async () => {
    const inquiries = await apiClient.readAllInquiries({})
    const stockOrder = inquiries.find(({ stockOrder }) => stockOrder?.status === StockOrderStatus.CREATED)?.stockOrder
    const path = stockOrder ? webappRoutes.stockOrder.byId(stockOrder.id).checkout.path : '/my-investments'
    navigate(path)
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Step
      {...props}
      nextLabel={!investorProfile.identityVerification ? tMain(`${tPrefix}.cta`) : tBase(`actions.done`)}
      nextButtonType='button'
      onClickNext={async () =>
        !investorProfile.identityVerification ? navigate('./identity-verification') : await navigateToPage()
      }
    >
      {!!investorProfile.identityVerification ? (
        <IdentityVerificationSummary defaultValue={investorProfile.identityVerification} />
      ) : (
        <Flex flexDirection='column' gap={2}>
          <P>{tMain(`${tPrefix}.text1`)}</P>
          <P>{tMain(`${tPrefix}.text2`)}</P>
          <P>{tMain(`${tPrefix}.text3`)}</P>
          <P>{tMain(`${tPrefix}.text4`)}</P>
        </Flex>
      )}
    </Step>
  )
}
