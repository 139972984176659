import React from 'react'

import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  Toggle,
  useMutation,
  useQueryClient,
} from '@guiker/react-framework'

import { useAuthenticationContext, useNotificationAuthenticatedApiClient } from '../../hooks'
import { useT } from '../../i18n'

type NotificationOptionsProps = {
  defaultValues: any
}

const NotificationOptions: React.FC<NotificationOptionsProps> = ({ defaultValues }) => {
  const { tMain } = useT({ screenName: 'screens.notifications' })

  return (
    <>
      <Toggle
        maxWidth={'fit-content'}
        defaultValue={defaultValues?.bookingUpdates?.sms}
        name={'bookingUpdates.sms'}
        label={tMain('options.bookingUpdates.sms')}
      />
      <Toggle
        maxWidth={'fit-content'}
        name={'listingRecommendations.email'}
        defaultValue={defaultValues?.listingRecommendations?.email}
        label={tMain('options.listingRecommendations.email')}
      />
    </>
  )
}

type FormContentProp = {
  isSuccess: boolean
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
  defaultValues: any
}

const ConfigureNotificationPreferencesForm: React.FC<FormContentProp> = ({
  isSuccess,
  setIsSuccess,
  defaultValues,
}) => {
  const { tBase } = useT({ screenName: 'screens.notifications' })
  const apiClient = useNotificationAuthenticatedApiClient()
  const { user } = useAuthenticationContext()
  const client = useQueryClient()

  const { mutate: updateNotificationPreferenceConfig, isLoading } = useMutation(
    async (payload: any) => apiClient.upsertNotificationPreference({ payload }),
    {
      onSuccess: () => {
        setIsSuccess(true)
        client.getQueryCache().find([`readNotificationPreference`, user.id])?.fetch()
      },
    },
  )

  return (
    <ApiForm formName='ConfigureNotificationPreferences' onSubmit={updateNotificationPreferenceConfig}>
      <NotificationOptions defaultValues={defaultValues} />
      <ButtonContainer>
        <ButtonWithLoader type='submit' disabled={isSuccess} isLoading={isLoading}>
          {tBase('actions.save')}
        </ButtonWithLoader>
      </ButtonContainer>
    </ApiForm>
  )
}

export { ConfigureNotificationPreferencesForm }
