import React from 'react'

import { AccountHolderType, PayoutMethod } from '@guiker/payout-shared'
import { FormSection, RadioGroup, useT } from '@guiker/react-framework'

import { useEditLinkContext } from '../../context'
import { EditButton } from '../EditButton'

export type SelectAccountHolderTypeFormContentProps = {
  defaultValue: Partial<PayoutMethod>
  readOnly: boolean
  title?: string
}

const SelectAccountHolderTypeFormContent: React.FC<SelectAccountHolderTypeFormContentProps> = ({
  defaultValue,
  readOnly,
  title,
}) => {
  const { tMain } = useT({ domain: 'payout' })
  const { editUrl } = useEditLinkContext({ shouldThrowIfUndefined: false }) ?? {}

  return (
    <FormSection hasDivider title={title} topActions={readOnly && <EditButton url={editUrl} />}>
      <RadioGroup
        name='accountHolderType'
        defaultValue={defaultValue.accountHolderType}
        readOnly={readOnly}
        options={[
          {
            value: AccountHolderType.INDIVIDUAL,
            label: tMain('selectAccountHolderType.individual.label'),
            description: tMain('selectAccountHolderType.individual.description'),
          },
          {
            value: AccountHolderType.COMPANY,
            label: tMain('selectAccountHolderType.company.label'),
            description: tMain('selectAccountHolderType.company.description'),
          },
        ]}
      />
    </FormSection>
  )
}

export { SelectAccountHolderTypeFormContent }
