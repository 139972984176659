import React from 'react'

import { DownloadMiniIcon, MessagesBubbleIcon } from '@guiker/icons'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Trans } from '../../../i18n'
import { makeStyles } from '../../../style'
import { Ontario } from '../../../types'
import { Button, Note, P, RadioGroup } from '../../'
import { FormSection2, FormSection3, HeaderDescriptionBar, OneColumnGridLayout } from '../../common'

const useStyles = makeStyles({
  preLine: {
    whiteSpace: 'pre-line',
  },
})

type FormContentProps = {
  additionalTerms: Ontario.AdditionalTerms
}

export const FormContent: React.FC<FormContentProps> = ({ additionalTerms }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <>
      <FormSection2 title={<HeaderDescriptionBar index='15.' description={t('ontarioLeaseScreenH:section15Header')} />}>
        <FormSection3>
          <P>{t('ontarioLeaseScreenH:additionalTermsSubHeader')}</P>
          <ul>
            <P className={classes.preLine}>
              <Trans i18nKey='ontarioLeaseScreenH:additionalTerms'>
                <li />
              </Trans>
            </P>
          </ul>
          <OneColumnGridLayout>
            <RadioGroup
              defaultValue={additionalTerms?.hasAdditionalTerms}
              name='hasAdditionalTerms'
              label={t('ontarioLeaseScreenH:selectOne')}
              options={[
                { value: false, label: t('ontarioLeaseScreenH:noAdditionalTerms') },
                { value: true, label: t('ontarioLeaseScreenH:isAdditionalTerms') },
              ]}
              readOnly={!canDraftLease}
            />
            <Button size='small' startIcon={<DownloadMiniIcon />} disabled>
              {t('ontarioLeaseScreenH:uploadDoc')}
            </Button>
          </OneColumnGridLayout>
        </FormSection3>
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='16.' description={t('ontarioLeaseScreenH:section16Header')} />}>
        <P>{t('ontarioLeaseScreenH:changesToTheAgreement')}</P>
        <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
          {t('ontarioLeaseScreenH:changesToTheAgreementNote')}
        </Note>
      </FormSection2>
    </>
  )
}
