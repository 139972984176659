import React from 'react'

import { Link, TextButton, useT } from '@guiker/react-framework'

export const EditButton: React.FC<{ url?: string }> = ({ url }) => {
  const { tBase } = useT({})
  if (!url) return
  return (
    <Link to={url}>
      <TextButton>{tBase('actions.edit')}</TextButton>
    </Link>
  )
}
