import React from 'react'

import { FormSection3 } from '@guiker/components-library'
import { Inquiry } from '@guiker/investment-assumption-components'

import { useTranslation } from '../../../i18n'

export const FinancialDetailsTab: React.FC = () => {
  const { t } = useTranslation(['main-propertySale'])
  const tPrefix = 'main-propertySale:screens.listingScreen'

  return (
    <>
      <FormSection3 title={t(`${tPrefix}.tab3.sections.yearlyPAndL`)}>
        <Inquiry.YearlyPAndL />
      </FormSection3>
    </>
  )
}
