import React from 'react'

import { TFunction, useAuthenticationContext } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { CurrencyTextField, P, TextField, TwoColumnsGridLayout } from '../../'
import { FormSection3 } from '../../common'

type Props = {
  defaultValue: Types.Rent
  name: string
  readOnly?: boolean
  disabled?: boolean
  t: TFunction
}

export const TenantWillPay: React.FC<Props> = ({ defaultValue, name, readOnly, disabled, t }) => {
  const { user } = useAuthenticationContext()
  const isAdmin = user?.isAdmin

  return (
    <FormSection3>
      <P>{t('ontarioLeaseScreenC:tenantWillPay')}</P>
      <TwoColumnsGridLayout>
        <CurrencyTextField
          defaultValue={defaultValue?.rentAmount}
          name={`${name}.rentAmount`}
          label={t('ontarioLeaseScreenC:rentAmount')}
          disabled={isAdmin ? false : !disabled}
          readOnly={isAdmin ? false : readOnly}
          maxWidth='100%'
        />
        <TextField
          defaultValue={defaultValue?.parkingAmount}
          name={`${name}.parkingAmount`}
          label={t('ontarioLeaseScreenC:parkingAmount')}
          disabled={disabled}
          readOnly={readOnly}
          maxWidth='100%'
        />
      </TwoColumnsGridLayout>
    </FormSection3>
  )
}
