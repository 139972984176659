import React from 'react'

import { AuthenticatedListingUnitNestedRouter } from '@guiker/rental-listing-context'
import { useParams } from '@guiker/router'

const ListingUnitNestedRouter: React.FC = () => {
  const { listingId, inventoryId } = useParams()

  return <AuthenticatedListingUnitNestedRouter listingId={listingId} unitId={inventoryId} />
}

export { ListingUnitNestedRouter }
