import React, { useState } from 'react'

import { Flex, P, PBold } from '@guiker/components-library'
import { Currency, money } from '@guiker/money'
import { CurrencyTextField } from '@guiker/react-framework'
import { Payer } from '@guiker/rent-payment-shared'

import { useAuthenticationContext } from '../../hooks'
import { isPositiveNumber } from './utils'

type Props = {
  payer: Payer
  setAmount: React.Dispatch<React.SetStateAction<number>>
  currency: Currency
  total: number
}

export const Contribution: React.FC<Props> = ({ payer, setAmount, currency, total }) => {
  const { user: currentUser } = useAuthenticationContext()
  const [error, setError] = useState(false)

  const handleContributionAmountChange = (input: string) => {
    const validNumber = isPositiveNumber(input)
    const monetizedAmount = validNumber ? Number(input) : 0
    const isValid = validNumber && Number(input) <= total
    setError(!isValid)
    setAmount(monetizedAmount)
  }

  const isCurrentUserContribution = payer.userId === currentUser.id

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <PBold mb={0}>{payer.firstName}</PBold>
      {!isCurrentUserContribution && (
        <P mb={0}>{money.fromAmount(payer.tentativeAmount ?? payer.amount, currency).toString()}</P>
      )}
      {isCurrentUserContribution && (
        <CurrencyTextField
          name='amount'
          defaultValue={payer.tentativeAmount ?? payer.amount}
          onChange={(v) => handleContributionAmountChange(v)}
          error={error}
        />
      )}
    </Flex>
  )
}
