import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../common/Step'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepH: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()
  const {
    leaseId,
    lease: {
      leaseInformation: { lessors, lessees, liability },
    },
    draftedAs,
  } = useQuebecLeaseContext()

  const lessor = lessors[0]

  const onSubmit = (payload: any) =>
    apiClient.updateQuebecLeaseSignatureAndLiability({
      pathParams: { leaseId },
      payload: { ...payload, draftedAs },
    })

  return (
    <Step
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(QuebecSchemas.signatureAndLiability, {
          defaultValues: { draftedAs, signature: null, initials: null },
        }),
        defaultValues: {
          liability,
          signature: lessor.signature,
          initials: lessor.initials,
        },
      }}
      formName={'QuebecLeaseFormStepH'}
      {...props}
    >
      <FormContent lessors={lessors} lessees={lessees} liability={liability} />
    </Step>
  )
}
