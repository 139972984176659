import React from 'react'

import { Flex, Label } from '@guiker/components-library'
import { CloseIcon } from '@guiker/icons'
import { Quebec } from '@guiker/lease-shared'

import { useFormContext, useTranslation, useWatch } from '../../../hooks'
import { makeStyles, theme } from '../../../style'
import { RadioGroup } from '../../'

export interface Props {
  name: string
  defaultValue: Quebec.LessorLesseeTypes | null
  readOnly?: boolean
  description: string | React.ReactNode
}

const useStyles = makeStyles({
  closeIcon: {
    height: 24,
    width: 24,
    color: theme.palette.grey[30],
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.hover,
    },
    '&:active': {
      color: theme.palette.primary.main,
    },
  },
})

const LessorOrLesseeToggle: React.FC<Props> = ({ defaultValue, name, description, readOnly }) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const { setValue } = useFormContext()

  const value = useWatch({ name, defaultValue }) as Quebec.LessorLesseeTypes | null

  const handleClear = () => {
    setValue(name, null, {
      shouldDirty: true,
    })
  }

  return (
    <div>
      {description && <Label text={description} />}
      <Flex justifyContent='space-between' alignItems='center' gap={2}>
        <Flex flexGrow={1}>
          <RadioGroup
            defaultValue={value === undefined ? null : value}
            direction='row'
            readOnly={readOnly}
            name={name}
            options={[
              {
                value: Quebec.LessorLesseeTypes.lessor,
                label: t('quebecLeaseScreenE:lessor'),
                gridItemProps: { style: { flexGrow: 1 } },
              },
              {
                value: Quebec.LessorLesseeTypes.lessee,
                label: t('quebecLeaseScreenE:lessee'),
                gridItemProps: { style: { flexGrow: 1 } },
              },
            ]}
          />
        </Flex>
        {value && !readOnly && (
          <div className={classes.closeIcon} onClick={handleClear}>
            <CloseIcon />
          </div>
        )}
      </Flex>
    </div>
  )
}

export { LessorOrLesseeToggle }
