import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Flex, FullScreenSpinner, H2, H4, KpiRow, numberFormatter, useQuery } from '@guiker/react-framework'
import { DateTime, getCurrencyByCountry, money } from '@guiker/shared-framework'

import { useRentCollectionContext } from '../../context'
import { useAuthenticatedStatisticApiClient } from '../../hooks'
import { useT } from '../../i18n'

export const RentCollectionSummaryKpis: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.dashboard.rentalDashboard.summaryKpis' })
  const { region: country } = useRentCollectionContext()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { user } = useAuthenticationContext()

  const { data, isLoading } = useQuery(
    ['rentCollectionSummaryKpi', user.id],
    () => statisticApi.readRentCollectionSummaryKpi({ queryParams: { country } }),
    {
      enabled: !!user.metadata && !!country,
      retry: 1,
    },
  )

  if (isLoading) return <FullScreenSpinner />
  if (!data) return <></>

  const { rentCollection, payoutSummary } = data
  const { complete, incomplete } = rentCollection
  const total = complete + incomplete
  const percentage = numberFormatter.percentage(2).toDisplay(complete / total || 0)
  const { last, next } = payoutSummary

  return (
    <KpiRow
      minWidth={300}
      items={[
        {
          color: 'primary',
          top: {
            label: tMain('rentCollection.complete'),
            value: (
              <Flex alignItems='center' gap={1}>
                <H2 color={'textSecondary'}>{`${money
                  .fromAmount(complete, getCurrencyByCountry(country))
                  .toString('onlySymbol')}`}</H2>
                <H4 color={'textSecondary'} mb={3}>{`(${percentage}%)`}</H4>
              </Flex>
            ),
          },
          bottom: [
            {
              label: tMain('rentCollection.total'),
              value: `${money.fromAmount(total, getCurrencyByCountry(country)).toString('onlySymbol')}`,
            },
            {
              label: tMain('rentCollection.incomplete'),
              value: `${money.fromAmount(incomplete, getCurrencyByCountry(country)).toString('onlySymbol')}`,
            },
          ],
        },
        {
          color: 'secondary',
          top: {
            label: tMain('payout.last'),
            value: (
              <Flex alignItems='center' gap={1}>
                <H2 mb={0}>{`${money
                  .fromAmount(last.amount, getCurrencyByCountry(country))
                  .toString('onlySymbol')}`}</H2>
                {last.expectedPayoutDate && (
                  <H4 mb={0}>{`(${DateTime.fromISO(last.expectedPayoutDate).toFormat('dd MMM')})`}</H4>
                )}
              </Flex>
            ),
          },
          bottom: [
            {
              label: tMain('payout.next'),
              value: (
                <Flex alignItems='center' gap={1}>
                  <H2 mb={0}>{`${money
                    .fromAmount(next.amount, getCurrencyByCountry(country))
                    .toString('onlySymbol')}`}</H2>
                  {next.expectedPayoutDate && (
                    <H4 mb={0}>{`(${DateTime.fromISO(next.expectedPayoutDate).toFormat('dd MMM')})`}</H4>
                  )}
                </Flex>
              ),
            },
          ],
        },
      ]}
    />
  )
}
