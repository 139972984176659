import React from 'react'

import { ColumnGridItem, FormSection3, FormSection4, TwoColumnsGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useAuthenticationContext, useLeaseContext, useTranslation, useWatch } from '../../../hooks'
import { Quebec } from '../../../types'
import { Checkbox, Collapse, TextField } from '../../'
import { SignatureContainer } from '../../common/SignatureContainer'

interface Props {
  lessees: Quebec.Lessee[]
}

interface GuarantorProps {
  name?: string
  hasGuarantorFieldName: string
  lessee: Quebec.Lessee
}

const Guarantor: React.FC<GuarantorProps> = ({ lessee, name, hasGuarantorFieldName }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenH'])
  const { disabledForCurrentUser } = useAppContext()
  const { can } = useLeaseContext()
  const hasGuarantor = useWatch({ name: hasGuarantorFieldName, defaultValue: lessee.hasGuarantor })
  const prefix = name ? `${name}.` : ''

  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)
  const canSignAsGuarantor = can(LeaseActions.SignLeaseAsGuarantor)

  const readOnly = (!canSignAsLessee && !canSignAsGuarantor) || disabledForCurrentUser(lessee)
  const guarantor = lessee.guarantor

  return (
    <FormSection4 title={lessee.fullName}>
      <Checkbox
        readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
        name={hasGuarantorFieldName}
        defaultValue={!canSignAsLessee ? hasGuarantor : undefined}
        label={t('quebecLeaseScreenH:lesseeHasGuarantor')}
        description={lessee.fullName}
      />
      <Collapse in={hasGuarantor}>
        <TwoColumnsGridLayout>
          <TextField
            readOnly={readOnly}
            name={`${prefix}fullName`}
            defaultValue={guarantor?.fullName}
            label={t('quebecLeaseScreenH:guarantorName')}
          />

          <TextField
            readOnly={readOnly}
            name={`${prefix}capacity`}
            defaultValue={guarantor?.capacity || 'Surety'}
            label={t('quebecLeaseScreenH:guarantorCapacity')}
          />
          <TextField
            readOnly={readOnly}
            name={`${prefix}emailAddress`}
            defaultValue={guarantor?.emailAddress}
            label={t('quebecLeaseScreenH:emailAddress')}
          />
          <TextField
            readOnly={readOnly}
            name={`${prefix}phoneNumber`}
            defaultValue={guarantor?.phoneNumber}
            label={t('quebecLeaseScreenH:guarantorPhone')}
          />
          <ColumnGridItem md={2}>
            <TextField
              readOnly={readOnly}
              name={`${prefix}address`}
              defaultValue={guarantor?.address}
              label={t('quebecLeaseScreenH:addressOfSignatory')}
            />
          </ColumnGridItem>
          <ColumnGridItem md={2}>
            <SignatureContainer
              readOnly={readOnly}
              disabled={!can(LeaseActions.SignLeaseAsGuarantor)}
              name={`${prefix}signature`}
              defaultValue={guarantor?.signature}
              label={t('quebecLeaseScreenH:guarantorSignature')}
            />
          </ColumnGridItem>
        </TwoColumnsGridLayout>
      </Collapse>
    </FormSection4>
  )
}

const Guarantors: React.FC<Props> = ({ lessees }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenH'])
  const { can } = useLeaseContext()
  const { user } = useAuthenticationContext()

  return (
    <FormSection3 subtitle={t('quebecLeaseScreenH:guarantorLabel')} hasDivider={true}>
      {lessees?.map((lessee, index) => {
        let name = ''
        let hasGuarantorFieldName = ''

        if (can(LeaseActions.SignLeaseAsGuarantor)) {
          name = ''
          hasGuarantorFieldName = ``
        } else if (can(LeaseActions.SignLeaseAsLessee)) {
          if (lessee.userId === user?.id) {
            name = `guarantor`
            hasGuarantorFieldName = `hasGuarantor`
          }
        } else {
          name = `lessees[${index}].guarantor`
          hasGuarantorFieldName = `lessees[${index}].hasGuarantor`
        }

        return <Guarantor key={index} lessee={lessee} name={name} hasGuarantorFieldName={hasGuarantorFieldName} />
      })}
    </FormSection3>
  )
}

export { Guarantors }
