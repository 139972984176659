import React from 'react'

import {
  ApiForm,
  Box,
  H1,
  H3,
  makeStyles,
  P,
  SplitView,
  theme,
  TypographyProps,
  useMediaQuery,
  useNavigate,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'
import { mainPathBuilder } from '@guiker/rental-tenancy-shared'
import { SimplifiedTenantPreferenceForm } from '@guiker/tenant-preference-component'
import { AuthenticatedApi } from '@guiker/tenant-preference-shared'

import {
  useAuthenticationContext,
  usePublicListingContext,
  useStaticAssetContext,
  useTenantPreferenceAuthenticatedApiClient,
} from '../../hooks'
import { transformListingToSimplifiedTenantPreference } from '../../utils'

const useStyle = makeStyles({
  image: {
    alignSelf: 'flex-start',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
})

const SetTenantPreferenceScreen: React.FC = () => {
  const { t } = useTranslation(['main-tenantPreference'])
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyle()
  const apiClient = useTenantPreferenceAuthenticatedApiClient()
  const navigate = useNavigate()
  const { user: currentUser } = useAuthenticationContext()
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('tenant-preference')
  const { data } = usePublicListingContext()

  const isAvailable = data?.rentalOptions?.length > 0
  const tenantPreference = transformListingToSimplifiedTenantPreference(data)

  const onSubmit = async (payload: AuthenticatedApi.Schema.UpsertTenantPreference) => {
    return apiClient.upsertTenantPreference({ pathParams: { userId: currentUser.id }, payload })
  }

  const Header: React.FC<React.PropsWithChildren & { mb: TypographyProps['mb'] }> = isMobile ? H3 : H1

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(AuthenticatedApi.Schema.upsertTenantPreference, {
          defaultValues: {
            tenantPreference,
          },
        }),
        defaultValues: {
          tenantPreference,
        },
      }}
      apiOptions={{
        onSuccess: () => {
          navigate(mainPathBuilder.root.tenantPreferences.path)
        },
      }}
      formName='UpdateTenantPreference'
    >
      {({ isLoading }) => (
        <SplitView
          maxWidth={750}
          image={
            <img
              src={assets.image2}
              alt={'simplifiedTenantPreference'}
              title={'simplifiedTenantPreference'}
              className={classes.image}
            />
          }
        >
          <>
            <Box ml={2} mr={isMobile ? 2 : 0}>
              <Header mb={2}>
                {t(`main-tenantPreference:screens.simplifiedTenantPreference.${isAvailable ? 'title' : 'unavailable'}`)}
              </Header>
              <P color={60}>
                {t(
                  `main-tenantPreference:screens.simplifiedTenantPreference.${
                    isAvailable ? 'description' : 'unavailableDescription'
                  }`,
                )}
              </P>
            </Box>
            <SimplifiedTenantPreferenceForm
              tenantPreference={tenantPreference}
              buttonMarginTop={isMobile ? theme.spacing(2) : undefined}
              isLoading={isLoading}
            />
          </>
        </SplitView>
      )}
    </ApiForm>
  )
}

export { SetTenantPreferenceScreen }
