import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { useAuthenticatedPaymentPayInMethodContext } from '@guiker/payment-methods-context'
import { PlaidLink } from '@guiker/payment-verification-components'
import { PageLayout, Spinner, useMutation, useNavigate } from '@guiker/react-framework'

import { useT } from '../../i18n'

export const PlaidTokenExpiredScreen: React.FC = () => {
  const { tMain, tShared } = useT({ screenName: 'screens.plaidTokenExpired' })
  const { data: payInMethod, isFetching, apiClient } = useAuthenticatedPaymentPayInMethodContext()
  const navigate = useNavigate()

  const { mutate: resolvePlaidTokenExpired } = useMutation(
    () => apiClient.resolvePlaidTokenExpired({ pathParams: { payInMethodId: payInMethod.id } }),
    { onSuccess: () => navigate(webappRoutes.root.wallet().payments().path) },
  )

  if (isFetching) {
    return <Spinner />
  }

  return (
    <PageLayout maxWidth={900} title={tMain('title')} subtitle={tMain('description')}>
      <PlaidLink
        label={tShared('connector.link')}
        countryCode={payInMethod.region}
        plaidAccessToken={payInMethod.details['plaidAccessToken']}
        onSelect={() => resolvePlaidTokenExpired()}
        openOnLoad={false}
      />
    </PageLayout>
  )
}
