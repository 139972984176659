import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { yup } from '@guiker/yup-util'

import { useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../common/Step'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepE: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { servicesAndConditions },
    },
  } = useQuebecLeaseContext()

  const onSubmit = ({ servicesAndConditions }: { servicesAndConditions: QuebecSchemas.ServicesAndConditions }) =>
    apiClient.updateQuebecLeaseServicesAndConditions({
      pathParams: { leaseId },
      payload: servicesAndConditions,
    })

  return (
    <Step
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(yup.object({ servicesAndConditions: QuebecSchemas.servicesAndConditions })),
        defaultValues: { servicesAndConditions },
      }}
      formName={'QuebecLeaseFormStepE'}
      {...props}
    >
      <FormContent servicesAndConditions={servicesAndConditions} />
    </Step>
  )
}
