import React from 'react'

import { SoftcheckScanStatus } from '@guiker/background-check-shared'

import { useTranslation } from '../i18n'
import { Chip, ChipProps } from '.'

interface ScanStatusChipProps {
  status: SoftcheckScanStatus
}

const ScanStatusChip: React.FC<ScanStatusChipProps> = ({ status }) => {
  const { t } = useTranslation()
  let color: ChipProps['color'] = null

  switch (status.toUpperCase()) {
    case 'CLEARED':
      color = 'success'
      break
    case 'WARN':
      color = 'warning'
      break
    case 'FAIL':
      color = 'alert'
      break
  }

  return <Chip color={color}>{t(`common:softcheck.scanStatus.${status.toLowerCase()}`)}</Chip>
}

export { ScanStatusChip }
