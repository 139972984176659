import { currency as currencyEntity, money } from '@guiker/money'
import { PriceOption } from '@guiker/rental-listing-shared'

export const getPriceBoundaries = (priceOptions: PriceOption[]) => {
  const currencyISO = priceOptions[0].price.currency
  const currency = currencyEntity[currencyISO]
  let lowestAmount = Infinity
  let highestAmount = -Infinity

  priceOptions.forEach((option) => {
    const priceAmount = option.price.amount
    lowestAmount = Math.min(lowestAmount, priceAmount)
    highestAmount = Math.max(highestAmount, priceAmount)
  })

  const lowerPriceBracket = money.fromAmount(lowestAmount, currency).toString(true, true)

  return lowestAmount === highestAmount
    ? lowerPriceBracket
    : `${lowerPriceBracket}-${money.fromAmount(highestAmount, currency).toString(false, true)}`
}
