import React from 'react'

import { ColumnGridItem } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { useWatch } from '@guiker/react-hook-form'

import { Quebec } from '../../../types'
import { Checkbox, Collapse, Note, TextField } from '../../'

interface Props {
  readOnly?: boolean
  name: string
  defaultValue: Quebec.AccessoriesAndDependencies
}

const AccessoriesAndDependencies: React.FC<Props> = ({ defaultValue, readOnly, name }) => {
  const accessoriesAndDependencies = useWatch({ name: `${name}.accessoriesAndDependencies`, defaultValue })
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])

  return (
    <ColumnGridItem md={2}>
      <div>
        <Checkbox
          defaultValue={accessoriesAndDependencies?.isAvailable}
          name={`${name}.accessoriesAndDependencies.isAvailable`}
          label={t('quebecLeaseScreenB:otherAccessories')}
          readOnly={readOnly}
        />
        <Collapse in={accessoriesAndDependencies?.isAvailable} unmountOnExit>
          <Note>
            <TextField
              name={`${name}.accessoriesAndDependencies.description`}
              defaultValue={accessoriesAndDependencies?.description}
              readOnly={readOnly}
              label={t('quebecLeaseScreenB:other')}
            />
          </Note>
        </Collapse>
      </div>
    </ColumnGridItem>
  )
}

export { AccessoriesAndDependencies }
