import React from 'react'

import { OntarioSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useOntarioLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../'
import { FormSection1 } from '../../common'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepB: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { contactInformation },
    },
  } = useOntarioLeaseContext()

  const onSubmit = (payload: OntarioSchemas.ContactInformation) =>
    apiClient.updateOntarioLeaseContactInformation({
      pathParams: { leaseId },
      payload,
    })

  return (
    <Step
      hasBackButton={true}
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.contactInformation),
        defaultValues: { contactInformation },
      }}
      formName={'OntarioLeaseFormStepB'}
      {...props}
    >
      <FormSection1>
        <FormContent contactInformation={contactInformation} />
      </FormSection1>
    </Step>
  )
}
