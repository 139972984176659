import React from 'react'

import { AuthenticatedPaymentPayInMethodNestedRouter } from '@guiker/payment-methods-context'
import { useParams } from '@guiker/react-framework'

const PaymentPayInMethodNestedRouter: React.FC = () => {
  const { payInMethodId } = useParams()
  return <AuthenticatedPaymentPayInMethodNestedRouter payInMethodId={payInMethodId} />
}

export { PaymentPayInMethodNestedRouter }
