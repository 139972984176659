import React from 'react'

import { TFunction, useWatch } from '../../../hooks'
import { Ontario, Ontario as Types } from '../../../types'
import { Collapse, FormSection3, RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Types.RentDiscounts
  name: 'rentDiscounts'
  readOnly?: boolean
  disabled?: boolean
  t: TFunction
}

export const RentDiscounts: React.FC<Props> = ({ defaultValue, name, readOnly, disabled, t }) => {
  const rentDiscountsName = `${name}.isDiscounted` as const
  const isDiscounted = useWatch<Ontario.LeaseInformation, typeof rentDiscountsName>({
    name: rentDiscountsName,
    defaultValue: defaultValue?.isDiscounted,
  })

  return (
    <FormSection3>
      <RadioGroup
        defaultValue={isDiscounted}
        name={rentDiscountsName}
        label={t('ontarioLeaseScreenE:selectOne')}
        options={[
          { value: false, label: t('ontarioLeaseScreenE:noRentDiscount') },
          { value: true, label: t('ontarioLeaseScreenE:rentDiscount') },
        ]}
        gridContainerProps={{ spacing: 1 }}
        disabled={disabled}
        readOnly={readOnly}
        maxWidth={420}
      />
      <Collapse in={isDiscounted}>
        <TextField
          defaultValue={defaultValue?.discountAmount}
          name={`${name}.discountAmount`}
          label={t('ontarioLeaseScreenE:addDetails')}
          readOnly={readOnly}
          maxWidth={420}
        />
      </Collapse>
    </FormSection3>
  )
}
