import React, { useEffect, useState } from 'react'

import { JwtAuthenticatedApi } from '@guiker/background-check-shared'
import { makeStyles, theme } from '@guiker/components-core'
import { yupResolver } from '@guiker/react-hook-form'
import { useLocationQuery, useNavigate, useParams } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'

import { ApiForm, Box, Button, H4, P, PageLayout } from '../../components'
import {
  useAuthenticationContext,
  useBackgroundCheckJwtAuthenticatedApiClient,
  useClaimsAuthenticationContext,
} from '../../hooks'
import { useTranslation } from '../../i18n'
import { SSNConsentForm } from './RequestSSNForm'

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
})

export const RequestSSNScreen: React.FC = () => {
  const { t } = useTranslation(['common', 'main-backgroundCheck'])
  const classes = useStyles()
  const { user: currentUser } = useAuthenticationContext()
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()
  const navigate = useNavigate()
  const { token } = useLocationQuery(['token'])
  const { backgroundCheckId } = useParams()
  const apiClient = useBackgroundCheckJwtAuthenticatedApiClient()
  const [requested, setRequested] = useState(false)

  const onSubmit = async (payload: JwtAuthenticatedApi.Schema.SsnAndConsentBackgroundCheckPayload) =>
    apiClient.updateSsnAndConsentBackgroundCheck({ pathParams: { backgroundCheckId }, payload })

  const navigateToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    token && setClaimsFromToken(token)
  }, [currentUser, token])

  return (
    <TakeoverContextProvider
      onClose={navigateToHome}
      title={t('main-backgroundCheck:screens.requestInformation.title')}
    >
      <PageLayout maxWidth={900 as const}>
        <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
          {requested ? (
            <Box className={classes.container}>
              <H4 mt={4}>{t('main-backgroundCheck:screens.requestInformation.successHeader')}</H4>
              <P my={4}>
                {t('main-backgroundCheck:screens.requestInformation.successDescription', {
                  requesterName: claims?.fullLandlordName,
                })}
              </P>
              <Button onClick={navigateToHome}>{t('common:actions.close')}</Button>
            </Box>
          ) : (
            <ApiForm
              apiOptions={{ onSuccess: () => setRequested(true) }}
              onSubmit={onSubmit}
              formName={'SSNConsentForm'}
              formOptions={{
                resolver: yupResolver(JwtAuthenticatedApi.Schema.ssnAndConsentBackgroundCheckPayloadValidator, {
                  defaultValues: { consent: true },
                }),
                defaultValues: {
                  consent: true,
                },
              }}
            >
              {({ isLoading }) => <SSNConsentForm isLoading={isLoading} />}
            </ApiForm>
          )}
        </Box>
      </PageLayout>
    </TakeoverContextProvider>
  )
}
