import React from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { Box, H3, P, useLayoutContext } from '@guiker/react-framework'
import { RentalListing } from '@guiker/rental-listing-shared'

const TitleComponent: React.FC<{ listing: RentalListing }> = ({ listing }) => {
  const { isMobile } = useLayoutContext()

  return (
    <Box>
      <H3 component={isMobile ? 'span' : 'h3'} mb={0}>
        {addressFormatter.printStreetInformation(listing.address)}
        {
          <P mb={0} ml={isMobile && 1} component={isMobile ? 'span' : 'h4'}>
            {addressFormatter.printAddressLine2(listing.address, false)}
          </P>
        }
      </H3>
    </Box>
  )
}

export { TitleComponent }
