import consent from '../screens/ConsentBackgroundCheck/zh.i18n.json'
import creditReport from '../screens/CreditReport/zh.i18n.json'
import notConsent from '../screens/NotConsentBackgroundCheck/zh.i18n.json'
import report from '../screens/Report/zh.i18n.json'
import reports from '../screens/Reports/zh.i18n.json'
import request from '../screens/RequestBackgroundCheck/zh.i18n.json'
import softcheckReport from '../screens/SoftcheckReport/zh.i18n.json'
import step01 from '../screens/Step01/zh.i18n.json'
import step02 from '../screens/Step02/zh.i18n.json'
import common from './common/zh.i18n.json'

export default {
  common,
  creditReport,
  report,
  reports,
  request,
  consent,
  notConsent,
  softcheckReport,
  step01,
  step02,
}
