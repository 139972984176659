import React from 'react'

import { BookingContextProvider } from '@guiker/booking-context'
import { Outlet, useParams } from '@guiker/router'

import { RentPaymentsPlanContextProvider } from '../context'

const RentPaymentPlanNestedRouter: React.FC = () => {
  const { bookingId } = useParams()

  return (
    <BookingContextProvider bookingId={bookingId}>
      <RentPaymentsPlanContextProvider bookingId={bookingId}>
        <Outlet />
      </RentPaymentsPlanContextProvider>
    </BookingContextProvider>
  )
}

export { RentPaymentPlanNestedRouter }
