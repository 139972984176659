import React from 'react'

import { getApplicationStatusAfterSubmit } from '@guiker/booking-components'
import { isRoommateIntroIncomplete } from '@guiker/booking-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { RoommateProfileFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, TenantApplication } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useBookingApplicationContext } from '../../hooks'
import { useBookingApiClient } from '../../lib'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '..'

export type MyRoommateProfileStepStepProps = StepProps

const MyRoommateProfileStep: React.FC<MyRoommateProfileStepStepProps> = ({ onClickNext, ...props }) => {
  const { isLoading, booking, bookingApplication, setBookingApplication, refetchBooking } =
    useBookingApplicationContext()
  const bookingApiClient = useBookingApiClient()
  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const handleSubmit = async (payload: AuthenticatedApi.Schema.UpdateTenantApplicationRoommateProfilePayload) => {
    const tenantApplication = await apiClient.updateRoommateProfile({ payload })
    isRoommateIntroIncomplete(bookingApplication?.roommateIntro?.status) &&
      (await bookingApiClient.submitBookingApplication({
        pathParams: { bookingId: booking.id, applicantUserId: bookingApplication.userId },
        payload: { leasePromise: null },
      }))
    return tenantApplication
  }

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      formName='TenantApplicationRoommateProfileStep'
      formOptions={{
        resolver: yupResolver(AuthenticatedApi.Schema.updateTenantApplicationRoommateProfilePayloadValidator),
        defaultValues: bookingApplication?.content as TenantApplication,
      }}
      onSubmit={handleSubmit}
      apiOptions={{
        onSuccess: (res) => {
          setBookingApplication({
            ...bookingApplication,
            content: res,
            status: getApplicationStatusAfterSubmit(bookingApplication?.status),
          })
          refetchBooking()
          onClickNext()
        },
        ...(!isRoommateIntroIncomplete(bookingApplication?.roommateIntro?.status)
          ? { onSubmitWithoutChange: () => onClickNext() }
          : {}),
      }}
    >
      {({ formState: { errors, isSubmitting } }) => (
        <Step isSubmitting={isSubmitting} errors={errors} hasBackButton={false} {...props}>
          <RoommateProfileFormContent
            tenantApplication={bookingApplication?.content as TenantApplication}
            schema={AuthenticatedApi.Schema.updateTenantApplicationRoommateProfilePayloadValidator}
          />
        </Step>
      )}
    </ApiForm>
  )
}

export { MyRoommateProfileStep }
