import React, { useEffect } from 'react'

import { useClaimsAuthenticationContext, useLocationQuery } from '../hooks'
import { AppContext, AppContextType, LeaseContextProvider } from '.'

export const GuarantorAppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()
  useEffect(() => {
    setClaimsFromToken(token)
  }, [token])

  const value = {
    booking: null,
    claims: claims as AppContextType['claims'],
    disabledForCurrentUser: ({ userId }: { userId?: string }) => userId !== claims?.lesseeId,
    isUnitManager: false,
    canCreateLease: false,
    participant: null,
    participantType: 'guarantor' as const,
    readOnly: true,
    user: null,
  }

  return (
    value.claims && (
      <AppContext.Provider value={value}>
        <LeaseContextProvider leaseId={value.claims.leaseId}>{children} </LeaseContextProvider>
      </AppContext.Provider>
    )
  )
}
