import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  const neighbourhoodNested = routes.listings.routes.countryNested.routes.cityNested.routes.neighbourhoodNested

  return (
    <Routes>
      <Route {...routes.root} {...props} />
      <Route {...routes.listings} {...props}>
        <Route {...routes.listings.routes.idNested} {...props}>
          <Route {...routes.listings.routes.idNested.routes.id} />
        </Route>
        <Route {...routes.listings.routes.countryNested} {...props}>
          <Route {...routes.listings.routes.countryNested.routes.cityNested} {...props}>
            <Route {...neighbourhoodNested} {...props}>
              <Route {...neighbourhoodNested.routes.idNested} {...props}>
                <Route {...neighbourhoodNested.routes.idNested.routes.id} {...props} />
                <Route {...neighbourhoodNested.routes.idNested.routes.slugified} {...props} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route {...routes.findOrCreateInvestorProfile} {...props} />
      <Route {...routes.investorProfile} {...props}>
        <Route {...routes.investorProfile.routes.root} {...props} />
        <Route {...routes.investorProfile.routes.identityVerification} {...props} />
        <Route {...routes.investorProfile.routes.onboard} {...props} />
        <Route {...routes.investorProfile.routes.requestTIN} {...props} />
      </Route>
      <Route {...routes.stockOrder} {...props}>
        <Route {...routes.stockOrder.routes.idNested} {...props}>
          <Route {...routes.stockOrder.routes.idNested.routes.checkout} {...props} />
          <Route {...routes.stockOrder.routes.idNested.routes.claimPromotion} {...props} />
          <Route {...routes.stockOrder.routes.idNested.routes.wireTransferInstruction} {...props} />
        </Route>
      </Route>
      <Route path='*' Component={PageNotFound} />
    </Routes>
  )
}

export { Router }
