import React from 'react'

import {
  Applicant,
  Booking,
  BookingApplicantEvents,
  BookingDocumentEvents,
  BookingEvents,
  BookingLeaseEvents,
  BookingPaymentEvents,
  getApplicantFullName,
  isAdminManagedBooking,
  UnitManager,
} from '@guiker/booking-shared'
import { Event, FullEvent } from '@guiker/event'
import { ActivityChip, ActivityLabel, ActivityMarker, Source, useDateFormatter, useT } from '@guiker/react-framework'
import {
  TenantInstalment,
  TenantInstalmentEvent,
  TenantInstalmentEventHistory,
  TenantInstalmentEventTypes,
  TenantInstalmentInvoiceEventStatus,
} from '@guiker/rent-payment-shared'
import { addressFormatter, money, optionalConcat } from '@guiker/shared-framework'

const typeChecker = {
  isTenantInstalment: (event: Event): event is Event<TenantInstalment> => {
    return event.entity === 'TENANT_INSTALMENT' && event.context === 'RENT_PAYMENT'
  },
  isBookingPayment: (event: Event): event is Event<Booking> => {
    return event.entity === 'PAYMENT' && event.context === 'BOOKING'
  },
  isBookingApplication: (event: Event): event is Event<Booking> => {
    return event.entity === 'APPLICANT' && event.context === 'BOOKING'
  },
  isBookingDocument: (event: Event): event is Event<Booking> => {
    return event.entity === 'BOOKING_DOCUMENT' && event.context === 'BOOKING'
  },
  isBookingBooking: (event: Event): event is Event<Booking> => {
    return event.entity === 'BOOKING' && event.context === 'BOOKING'
  },
}

const getApplicantByUserId = ({ userId, booking }: { userId: string; booking: Booking }): Applicant =>
  booking.applicants.find((applicant) => applicant.userId === userId)

const getParticipantByUserId = ({ userId, booking }: { userId: string; booking: Booking }): UnitManager | Applicant => {
  return (
    getApplicantByUserId({ userId, booking }) ||
    booking.unitManagers.find((unitManager) => unitManager.userId === userId)
  )
}

const useFindBookingEventSource = (event: Event<Booking>, booking) => {
  const { formatDateTime } = useDateFormatter()
  const time = formatDateTime(event.emittedAt)
  if (!event.sourceUserId) return time
  const participant = getParticipantByUserId({ userId: event.sourceUserId, booking: event.data })
  const bookingApplicant = booking.applicants.find((a) => a.userId === event.sourceUserId)
  if (!!bookingApplicant) {
    return time + `- by ${getApplicantFullName(bookingApplicant)} <${bookingApplicant.emailAddress}>`
  }
  return (
    time + `- by ${optionalConcat([participant.firstName, participant.lastName], ' ')} <${participant.emailAddress}>`
  )
}

const useFindTenantInstalmentEventSource = (event: Event<TenantInstalment>, booking: Booking) => {
  const { formatDateTime } = useDateFormatter()
  const time = formatDateTime(event.emittedAt)
  const bookingApplicant = booking.applicants.find((a) => a.userId === event.data.tenantUserId)
  if (!!bookingApplicant) {
    return time + `- by ${getApplicantFullName(bookingApplicant)} <${bookingApplicant.emailAddress}>`
  }
  return time
}

const useMapBookingEventToActivity = (
  event: Event<Booking>,
  booking: Booking,
): { label: React.ReactNode; content: React.ReactNode } => {
  const { tMain } = useT({ domain: 'booking' })
  const { formatDate } = useDateFormatter()
  const timeStamp = useFindBookingEventSource(event, booking)
  const isAdminManaged = isAdminManagedBooking(event.data.unitManagers)
  switch (event.type) {
    case 'STARTED':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, { name: getApplicantFullName(event.data.applicants[0]) })}
          />
        ),
      }
    case 'BOOKED':
    case 'REJECTED':
    case 'LEASE_SIGNED_BY_ALL_PARTIES':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`)}
            chip={
              event.type === 'BOOKED' ? (
                <ActivityChip content={tMain('activity.status.booked')} color='success' />
              ) : undefined
            }
          />
        ),
      }
    case 'APPLICANT_LEFT':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              name: event.eventData
                ? getApplicantFullName(
                    (event as FullEvent<typeof BookingEvents.Events.APPLICANT_LEFT>).eventData.applicant,
                  )
                : 'An applicant',
            })}
          />
        ),
      }
    case 'APPLICANTS_ADDED':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              name: optionalConcat(
                event.eventData
                  ? (event as FullEvent<typeof BookingEvents.Events.APPLICANTS_ADDED>).eventData?.applicants?.map((a) =>
                      getApplicantFullName(a),
                    )
                  : ['Applicants'],
                ', ',
              ),
            })}
          />
        ),
      }
    case 'WITHDRAWN':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              name: event.eventData
                ? getApplicantFullName((event as FullEvent<typeof BookingEvents.Events.WITHDRAWN>).eventData.applicant)
                : 'an applicant',
            })}
          />
        ),
      }
    case 'LEASE_TERM_EXTENDED':
    case 'EARLY_TERMINATION_SET':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              date: formatDate(event.data.info.period.from),
            })}
          />
        ),
      }
    case 'LISTING_UNIT_REPLACED':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              listing: addressFormatter.printShortAddress(event.data.listing.address),
            })}
          />
        ),
      }
    case 'CONFIRM_REQUESTED':
      const sourceUser = getParticipantByUserId({ userId: event.sourceUserId, booking: event.data })
      const name = isAdminManaged ? 'Leasing Team' : optionalConcat([sourceUser.firstName, sourceUser.lastName], ' ')
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              name,
            })}
          />
        ),
      }
    case 'LEASE_READY_TO_BE_SIGNED':
      //no sourceUserId
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}${isAdminManaged ? '_BY_ADMIN' : ''}`, {
              name: isAdminManaged ? 'Leasing Team' : '',
            })}
          />
        ),
      }
    case 'LEASE_PARTICIPANT_SIGNED':
      //nosourceId
      if (!event.eventData) return
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKING.${event.type}`, {
              name: (event as FullEvent<typeof BookingLeaseEvents.Events.LEASE_PARTICIPANT_SIGNED>).eventData.lease
                .participants[0].fullName,
            })}
          />
        ),
      }
    default: {
      return
    }
  }
}

const useMapBookingDocumentToActivity = (
  event: Event<Booking>,
  booking: Booking,
): { label: React.ReactNode; content: React.ReactNode } => {
  const { tMain } = useT({ domain: 'booking' })
  const timeStamp = useFindBookingEventSource(event, booking)
  switch (event.type) {
    case 'SENT':
    case 'COMPLETED':
      return {
        content: <Source content={timeStamp} />,
        label: <ActivityLabel content={tMain(`activity.DOCUMENT.${event.type}`)} />,
      }
    case 'PARTICIPANT_SIGNED':
      const participant = getParticipantByUserId({
        userId: (event as FullEvent<typeof BookingDocumentEvents.Events.PARTICIPANT_SIGNED>).eventData.participant
          .userId,
        booking,
      })
      const applicant = event.data.applicants.find((a) => a.userId === participant.userId)
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.DOCUMENT.${event.type}`, {
              name: applicant
                ? getApplicantFullName(applicant)
                : optionalConcat([participant.firstName, participant.lastName], ' '),
            })}
          />
        ),
      }
    default: {
      return
    }
  }
}

const useMapBookingApplicantToActivity = (
  event: Event<Booking>,
  booking: Booking,
): { label: React.ReactNode; content: React.ReactNode } => {
  const { tMain } = useT({ domain: 'booking' })
  const timeStamp = useFindBookingEventSource(event, booking)
  switch (event.type) {
    case 'APPLICATION_ACCEPTED':
    case 'APPLICATION_REJECTED':
    case 'APPLICATION_CHANGE_REQUESTED':
    case 'APPLICATION_SUBMITTED':
    case 'BACKGROUND_CHECK_RESULT_UPDATED':
    case 'APPLICATION_STARTED':
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.APPLICANT.${event.type}`, {
              name: getApplicantFullName(
                (event as FullEvent<typeof BookingApplicantEvents.Events.APPLICATION_STARTED>).eventData.applicants[0],
              ),
            })}
          />
        ),
      }
    default: {
      return
    }
  }
}

const useMapBookingPaymentToActivity = (
  event: Event<Booking>,
  booking: Booking,
): { label: React.ReactNode; content: React.ReactNode } => {
  const { tMain } = useT({ domain: 'booking' })
  const timeStamp = useFindBookingEventSource(event, booking)
  switch (event.type) {
    case 'CONTRIBUTION_RESPLIT_COMPLETED':
      return {
        content: <Source content={timeStamp} />,
        label: <ActivityLabel content={tMain(`activity.BOOKINGPAYMENT.${event.type}`)} />,
      }
    case 'PAY_IN_METHOD_REJECTED':
    case 'PAY_IN_METHOD_VERIFIED':
    case 'PAY_IN_METHOD_WAIVED':
    case 'PENDING_PAY_IN_METHOD_VERIFICATION':
      const applicant = getApplicantByUserId({
        userId: (event as FullEvent<typeof BookingPaymentEvents.Events.PAY_IN_METHOD_REJECTED>).eventData
          .applicantUserId,
        booking: event.data,
      })
      return {
        content: <Source content={timeStamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.BOOKINGPAYMENT.${event.type}`, { name: getApplicantFullName(applicant) })}
          />
        ),
      }
    default: {
      return
    }
  }
}

const isRepeatStatus = (
  type: TenantInstalmentEventTypes,
  status: TenantInstalmentInvoiceEventStatus,
  events: TenantInstalmentEventHistory[],
) => {
  return events.filter((event) => event.type === type && event.status === status).length > 1
}

const useMapTenantInstalmentToActivity = (
  event: Event<TenantInstalment>,
  booking: Booking,
  useEventSource: boolean,
  isPaidOffline: boolean,
): { content: React.ReactNode; label: React.ReactNode } => {
  const { tMain } = useT({ domain: 'booking' })
  const { formatWithRelativeDateTime } = useDateFormatter()
  const { tMain: tPaymentMain } = useT({ domain: 'payment' })
  const timeStamp = !useEventSource
    ? formatWithRelativeDateTime(event.emittedAt)
    : useFindTenantInstalmentEventSource(event, booking)
  switch (event.type) {
    case 'TRANSACTION_EVENT_ADDED':
      const addedEvent = (event as FullEvent<typeof TenantInstalmentEvent.Events.TRANSACTION_EVENT_ADDED>).eventData
        .event
      const bookingApplicant = booking.applicants.find((a) => a.userId === event.data.tenantUserId)
      const name = getApplicantFullName(bookingApplicant)
      const chargeAmount = addedEvent.amount - (addedEvent.transactionFee ?? 0)
      const amount = money.fromAmount(chargeAmount, addedEvent.currency).toString('onlySymbol')
      if (addedEvent.type === 'INVOICE') {
        switch (addedEvent.status) {
          case TenantInstalmentInvoiceEventStatus.CHARGE_PENDING:
            const isRepeated = isRepeatStatus(addedEvent.type, addedEvent.status, event.data.events)
            return {
              content: <Source content={timeStamp} />,
              label: (
                <ActivityLabel
                  content={tMain(`activity.TENANTINSTALMENT.${addedEvent.status}${isRepeated ? '_REPEAT' : ''}`, {
                    name,
                    amount,
                  })}
                />
              ),
            }
          case TenantInstalmentInvoiceEventStatus.REFUND_SUCCEED:
          case TenantInstalmentInvoiceEventStatus.CHARGE_REFUND_FAILED:
            return {
              content: <Source content={timeStamp} />,
              label: (
                <ActivityLabel
                  content={tMain(`activity.TENANTINSTALMENT.${addedEvent.status}`, {
                    name,
                    amount,
                  })}
                />
              ),
            }
          case TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED:
            return {
              content: <Source content={timeStamp} />,
              label: (
                <ActivityLabel
                  chip={
                    addedEvent.status === TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED ? (
                      <ActivityChip
                        content={tMain(`activity.status.${isPaidOffline ? 'paidOffline' : 'collected'}`)}
                        color='success'
                        variant='outlined'
                      />
                    ) : undefined
                  }
                  content={tMain(`activity.TENANTINSTALMENT.${isPaidOffline ? 'PAID_OFFLINE' : addedEvent.status}`, {
                    name,
                    amount,
                  })}
                />
              ),
            }
          case TenantInstalmentInvoiceEventStatus.SETTLED:
            const transferAmount = money
              .fromAmount(addedEvent.transferAmount, addedEvent.currency)
              .toString('onlySymbol')
            const receiver = event.data.processedWith.receiver
            return {
              content: isPaidOffline ? undefined : <Source content={timeStamp} />,
              label: isPaidOffline ? undefined : (
                <ActivityLabel
                  chip={<ActivityChip content={tMain(`activity.status.received`)} color='success' />}
                  content={tMain(`activity.TENANTINSTALMENT.${addedEvent.status}`, {
                    name: optionalConcat([receiver.firstName, receiver.lastName], ' '),
                    amount: transferAmount,
                  })}
                />
              ),
            }
          default:
            return {
              content: undefined,
              label: undefined,
            }
        }
      }
      return {
        content: undefined,
        label: undefined,
      }
    case 'TRANSACTION_FAILED_EVENT_ADDED':
      const eventData = (event as FullEvent<typeof TenantInstalmentEvent.Events.TRANSACTION_FAILED_EVENT_ADDED>)
        .eventData
      if (eventData.transactionEvent.type === 'INVOICE' && eventData.transactionEvent.status === 'CHARGE_FAILED') {
        const error = eventData.transactionEvent.error?.code?.toLowerCase() ?? 'unknown'
        const errorType = tPaymentMain(`status.${error}`)
        const payer = eventData.payer
        return {
          content: <Source content={timeStamp} />,
          label: (
            <ActivityLabel
              content={tMain(`activity.TENANTINSTALMENT.${event.type}.${error}`, {
                name: optionalConcat([payer.firstName, payer.lastName], ' '),
              })}
              chip={<ActivityChip content={errorType} color='alert' variant='outlined' />}
            />
          ),
        }
      }
      return {
        content: undefined,
        label: undefined,
      }
    default: {
      return {
        content: undefined,
        label: undefined,
      }
    }
  }
}

export const useTransformEventToActivity = ({
  event,
  booking,
  useEventSource = true,
  isPaidOffline = false,
}: {
  event: Event
  booking: Booking
  useEventSource?: boolean
  isPaidOffline?: boolean
}) => {
  if (typeChecker.isBookingBooking(event)) {
    return { ...useMapBookingEventToActivity(event, booking) }
  }
  if (typeChecker.isBookingDocument(event)) {
    return { ...useMapBookingDocumentToActivity(event, booking) }
  }
  if (typeChecker.isBookingApplication(event)) {
    return { ...useMapBookingApplicantToActivity(event, booking) }
  }
  if (typeChecker.isBookingPayment(event)) {
    return { ...useMapBookingPaymentToActivity(event, booking) }
  }
  if (typeChecker.isTenantInstalment(event)) {
    return { ...useMapTenantInstalmentToActivity(event, booking, useEventSource, isPaidOffline) }
  }
}

export const useTransformEventsToActivityItems = ({
  events,
  booking,
  isPaidOffline,
}: {
  events: Event[]
  booking: Booking
  isPaidOffline: boolean
}) => {
  const filteredEvents = events
    ?.map((event) => useTransformEventToActivity({ booking, event, useEventSource: false, isPaidOffline }))
    .filter((item) => !!item?.content)

  return filteredEvents?.map((item, index) => {
    return {
      ...item,
      marker: {
        isFirst: index === 0,
        isLast: index === filteredEvents.length - 1,
        icon: <ActivityMarker />,
      },
    }
  })
}
