import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { Features, useFeatureFlag } from '@guiker/react-framework'

import { FormSection1, LeaseDocumentsTable, PropSharingDocumentsTable } from '../../components'
import { useT } from '../../i18n'

const DocumentsScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.documents' })
  const isLeaseDocumentsEnabled = useFeatureFlag().isEnabled(Features.LeaseDocuments)

  return (
    <AccountPageLayout>
      <FormSection1 title={tMain('title')}>
        <>
          {isLeaseDocumentsEnabled && <LeaseDocumentsTable />}
          <PropSharingDocumentsTable />
        </>
      </FormSection1>
    </AccountPageLayout>
  )
}

export { DocumentsScreen }
