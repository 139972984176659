import React from 'react'

import { Flex, PSmall, PSmaller, Slider, sliderNumberFormatter, useWatch } from '@guiker/react-framework'

type PercentRangeSliderProps = {
  label: string
  max: number
  min: number
  name: string
  readOnly?: boolean
  step: number
}

const readOnlyPercentRange = (values: number[]) => {
  const formatter = sliderNumberFormatter.percentage(2).toDisplay
  return `${formatter(values[0])} -
        ${formatter(values[1])}`
}

export const PercentRangeSlider: React.FC<PercentRangeSliderProps> = ({ label, max, min, name, readOnly, step }) => {
  const data = useWatch()

  if (readOnly) {
    return (
      <Flex flexDirection='column'>
        <PSmaller mb={0}>{label}</PSmaller>
        <PSmall mb={0}>{readOnlyPercentRange(data[name])}</PSmall>
      </Flex>
    )
  }

  return (
    <Slider
      name={name}
      label={label}
      width={'100%'}
      min={min}
      max={max}
      step={step}
      dirtyFormOnlyOnClickRelease={true}
      formatter={sliderNumberFormatter.percentage(2)}
    />
  )
}
