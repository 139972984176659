import React, { useState } from 'react'

import { Applicant, Contribution, ContributionStatus } from '@guiker/booking-shared'
import { money } from '@guiker/money'
import { Flex, makeStyles, P, PBold, TextField, theme } from '@guiker/react-framework'

import { isPositiveNumber } from '../../helper/helper'
import { useAppContext, useAuthenticationContext } from '../../hooks'

const useStyles = makeStyles({
  contributionInput: {
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
})

type Props = {
  contribution: Contribution
  canChangeContribution: boolean
}

const Contribution: React.FC<Props> = ({ contribution, canChangeContribution }) => {
  const classes = useStyles()
  const { user: currentUser } = useAuthenticationContext()
  const {
    applicants,
    setUserContribution,
    bookingPayment: { contributions, total },
  } = useAppContext()
  const currency = money.currency[total.currency]
  const isCurrentUserContribution = contribution.userId === currentUser.id
  const isAmountSet = contribution.amount !== null && contribution.status !== ContributionStatus.INITIAL_STATE

  const [amountInputError, setAmountInputError] = useState(false)

  const handleContributionAmountChange = (input: string) => {
    const validNumber = isPositiveNumber(input)
    const monetizedAmount = validNumber ? Number(input) : 0
    const userInputAmount = money.toAmount(monetizedAmount, currency).amount
    const isValid =
      validNumber &&
      ((contributions.length === 1 && userInputAmount === total.amount) || total.amount - userInputAmount >= 0)
    setAmountInputError(!isValid)
    setUserContribution({ ...contribution, amount: userInputAmount })
  }

  const contributors = contributions.reduce((aggregation, c) => {
    aggregation[c.userId] = applicants.find((a) => a.userId === c.userId)
    return aggregation
  }, {}) as { [key in string]: Applicant }

  return (
    <Flex mb={3} gap={2} justifyContent='space-between' alignItems='center'>
      <PBold>{contributors[contribution.userId].firstName}</PBold>
      {(!isCurrentUserContribution || !canChangeContribution) && (
        <P>{money.fromAmount(isAmountSet ? contribution.amount : 0, currency).toString()}</P>
      )}
      {isCurrentUserContribution && canChangeContribution && (
        <TextField
          maxWidth={400}
          name='amount'
          defaultValue={money.fromAmount(contribution.amount, currency).monetized}
          onChange={(e) => handleContributionAmountChange(e.target.value)}
          startAdornment={
            <P mb={0} ml={1}>
              {currency.symbol}
            </P>
          }
          inputProps={{
            className: classes.contributionInput,
          }}
          error={amountInputError}
        />
      )}
    </Flex>
  )
}

export { Contribution }
