import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { FullScreenSpinner, Redirect, useParams, useQuery } from '@guiker/react-framework'

import { useAuthenticatedStatisticApiClient } from '../../hooks'

export const TenantInstalmentRedirect: React.FC = () => {
  const { id } = useParams()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { data, isLoading } = useQuery(
    ['readStatisticTenantInstalmentById', id],
    () => statisticApi.readTenantInstalmentById({ pathParams: { tenantInstalmentId: id } }),
    { enabled: !!id },
  )

  if (isLoading) return <FullScreenSpinner />

  const { rentPaymentsPlan } = data
  const bookingPath = mainPathBuilder.root.rentalTenancy.bookings.byBookingId(rentPaymentsPlan.scope.id)
  return <Redirect path={bookingPath.tenantInstalments.byTenantInstalmentId(data.id).path} />
}
