import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { PayoutMethodNestedContextProvider, usePayoutMethodNestedContext } from '@guiker/payout-context'
import { StepConfig, StepperLayout, useNavigate, useParams } from '@guiker/react-framework'

import { AccountHolderStep, BankInformationStep, InvestmentVerificationDocumentsStep } from '../../components'
import { useT } from '../../i18n'

const CompleteInvestmentPayoutMethodScreenContent: React.FC = () => {
  const navigate = useNavigate()
  const { tMain, tBase } = useT({ domain: 'payout' })
  const { isFetching, isSettled } = usePayoutMethodNestedContext()

  const steps: StepConfig[] = [
    {
      name: '1',
      component: AccountHolderStep,
    },
    {
      name: '2',
      component: BankInformationStep,
    },
    {
      name: '3',
      component: InvestmentVerificationDocumentsStep,
    },
  ]

  const onClose = () => navigate(webappRoutes.root.wallet().payoutPreferences().path)

  return (
    <StepperLayout
      isLoading={isFetching || !isSettled}
      pageLayoutProps={{ isLoading: isFetching, hasButtonContainer: true }}
      takeoverProps={{
        onClose,
        onBack: undefined,
      }}
      onLastStepClicked={onClose}
      finalLabel={tBase('submit')}
      nextLabel={tBase('next')}
      backLabel={tBase('back')}
      steps={steps}
      title={tMain('createPayoutMethodScreen.title')}
    />
  )
}

const CompleteInvestmentPayoutMethodScreen: React.FC = () => {
  const { id } = useParams()

  return (
    <PayoutMethodNestedContextProvider id={id}>
      <CompleteInvestmentPayoutMethodScreenContent />
    </PayoutMethodNestedContextProvider>
  )
}

export { CompleteInvestmentPayoutMethodScreen }
