import React, { useEffect } from 'react'

import { Box, PageLayout, useLayoutContext, useParams, useQuery } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient } from '../../hooks'

const PaysafeTermsAndConditionsScreen: React.FC = () => {
  const apiClient = useAuthenticatedPayoutApiClient()
  const { setAppBarIsDisplayed } = useLayoutContext()
  const { id: payoutMethodId, payoutProviderAccountId } = useParams()

  useEffect(() => {
    setAppBarIsDisplayed(false)
  }, [])

  const { data: termsAndConditions, isLoading } = useQuery(
    'readPaysafeTermsAndConditions',
    () =>
      apiClient.readPayoutProviderTermsAndConditions({
        pathParams: { payoutMethodId, payoutProviderName: 'paysafe', payoutProviderAccountId },
      }),
    { retry: true },
  )

  return (
    <PageLayout maxWidth={900} isLoading={isLoading}>
      <Box dangerouslySetInnerHTML={{ __html: termsAndConditions.content }} />
    </PageLayout>
  )
}

export { PaysafeTermsAndConditionsScreen }
