import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Route, Routes } from '@guiker/router'

import { bookingApplicationRoutes, routes } from './routes'

const Router: React.FC = (props) => {
  const { user } = useAuthenticationContext()

  return (
    <Routes>
      <Route {...routes.LeaseGuarantor} {...props} />
      <Route {...routes.LeaseInvitedLessor} {...props} />
      <Route {...routes.BookingRoommateIntro} {...props} />
      <Route {...routes.BookingInvitedLandlord} {...props} />
      {user?.emailAddress.includes('@guiker.com') && <Route {...routes.ListBookings} {...props} />}

      <Route {...routes.BookingId} {...props}>
        <Route {...routes.BookingId.routes.BookingApplicants} {...props} />
        <Route {...routes.BookingId.routes.BookingDocument} {...props} />
        <Route {...routes.BookingId.routes.BookingModule} {...props} />
        <Route {...routes.BookingId.routes.BookingPayment} {...props} />
        <Route {...routes.BookingId.routes.BookingRentPayment} {...props} />
        <Route {...bookingApplicationRoutes.BookingApplications} {...props}>
          <Route {...bookingApplicationRoutes.BookingApplications.routes.SelectApplication} {...props} />
        </Route>
        <Route {...routes.BookingId.routes.Lease} {...props} />
      </Route>
    </Routes>
  )
}

export { Router }
