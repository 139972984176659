import React from 'react'

import { H4, P, Trans } from '@guiker/react-framework'
import { RentalListing } from '@guiker/rental-listing-shared'
import { currency, money } from '@guiker/shared-framework'

const PriceComponent: React.FC<{ listing: RentalListing }> = ({ listing }) => {
  const findLowestPrice = (listing: RentalListing) => {
    let lowestPrice = null
    let hasMultiplePrices = false

    listing.rentalOptions.forEach((ro) =>
      ro.priceOptions.forEach((po) => {
        if (lowestPrice && po.price.amount !== lowestPrice?.amount) {
          hasMultiplePrices = true
        }

        if (!lowestPrice || po.price.amount <= lowestPrice.amount) {
          lowestPrice = po.price
        }
      }),
    )

    return { lowestPrice, hasMultiplePrices }
  }
  const { lowestPrice, hasMultiplePrices } = findLowestPrice(listing)

  return (
    <P component='span' color={30}>
      <Trans
        i18nKey={
          hasMultiplePrices
            ? 'main-rentalListing:listingDetails.pricePerMonthStartingAt'
            : 'main-rentalListing:listingDetails.pricePerMonth'
        }
        values={{ price: money.fromAmount(lowestPrice.amount, currency[lowestPrice.currency]).toString(true, true) }}
      >
        <H4 ml={0.5} component='span' color='textPrimary' />
      </Trans>
    </P>
  )
}

export { PriceComponent }
