import React, { useMemo } from 'react'

import { KpiGrid } from '@guiker/base-listing-components'
import { InvestmentAssumptions } from '@guiker/base-listing-shared/'
import { Inquiry } from '@guiker/investment-assumption-components'
import {
  DisplayBody,
  InvestmentPerformanceIcon,
  Note,
  PageSection2,
  Slider,
  sliderNumberFormatter,
  TwoColumnsGridLayout,
} from '@guiker/react-framework'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'
import { buildKpiGrid } from './utils'

const { findMinAndMax, findMinAndMaxWithRange, getInputProps } = Inquiry.investmentAssumptionsUtils

export const InvestmentPerformance: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.investmentPerformance' })
  const { listing, handleNote } = useListingScreenContext()
  const readOnly = false

  const { rentalGrowthRateMinMax, propertyAppreciationRateMinMax, capRateMinMax } = useMemo(
    () => ({
      rentalRevenueMinMax: findMinAndMax(listing?.assumptions.revenue.rentalRevenue, 0.5),
      rentalGrowthRateMinMax: findMinAndMaxWithRange(listing?.assumptions.revenue.rentalGrowthRate, 0.25, 0.05),
      capRateMinMax: findMinAndMaxWithRange(listing?.assumptions.base.capRate, 0.25, 0.05),
      propertyAppreciationRateMinMax: findMinAndMaxWithRange(
        listing?.assumptions.base.propertyAppreciationRate,
        0.25,
        0.03,
      ),
    }),
    [listing?.assumptions.revenue],
  )

  const inputProps = getInputProps(listing, 'assumptions', undefined, readOnly)

  return (
    <PageSection2 title={tMain('header')}>
      <Note
        type='collapsible'
        title={tMain('note.title')}
        icon={<InvestmentPerformanceIcon />}
        statusColor='alabaster'
        isCollapsed={true}
        onMobileOpen={() =>
          handleNote({
            title: tMain('note.title'),
            content: tMain('note.description'),
          })
        }
      >
        <DisplayBody mb={0}>{tMain('note.description')}</DisplayBody>
      </Note>
      <KpiGrid items={buildKpiGrid()} />
      <TwoColumnsGridLayout>
        <Slider
          {...inputProps.getRevenueInputProps('rentalGrowthRate')}
          track={false}
          min={rentalGrowthRateMinMax.min}
          max={rentalGrowthRateMinMax.max}
          formatter={sliderNumberFormatter.percentage(2)}
          disabled={readOnly}
          step={0.0025}
          marks={[
            {
              value: listing.assumptions.revenue.rentalGrowthRate,
            },
          ]}
        />
        {listing.assumptions.base.estimatedSalePriceCalculationMethod ===
        InvestmentAssumptions.EstimatedSalePriceCalculationMethod.APPRECIATION_RATE ? (
          <Slider
            {...inputProps.getBaseInputProps('propertyAppreciationRate')}
            track={false}
            min={propertyAppreciationRateMinMax.min}
            max={propertyAppreciationRateMinMax.max}
            step={0.0025}
            formatter={sliderNumberFormatter.percentage(2)}
            marks={[
              {
                value: listing.assumptions.base.propertyAppreciationRate,
              },
            ]}
          />
        ) : (
          <Slider
            {...inputProps.getBaseInputProps('capRate')}
            track={false}
            min={capRateMinMax.min}
            max={capRateMinMax.max}
            step={0.0025}
            formatter={sliderNumberFormatter.percentage(2)}
            marks={[
              {
                value: listing.assumptions.base.capRate,
              },
            ]}
          />
        )}
      </TwoColumnsGridLayout>
    </PageSection2>
  )
}
