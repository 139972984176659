import React from 'react'

import { FormSection3, RichTextEditor } from '@guiker/components-library'
import { SaleStatus } from '@guiker/property-sale-shared'

import { FinancialDetails, FinancingAssumptions, InvestmentAssumptions } from '../../../components'
import { useListingScreenContext } from '../../../context'
import { useTranslation } from '../../../i18n'

export const DetailsTab: React.FC = () => {
  const { t } = useTranslation(['main-propertySale'])
  const { listing } = useListingScreenContext()
  const tPrefix = 'main-propertySale:screens.listingScreen'
  const isSold = listing.propertySale?.saleStatus === SaleStatus.SOLD

  return (
    <>
      <FormSection3 title={t(`${tPrefix}.tab1.sections.description`)}>
        <RichTextEditor readOnly defaultValue={listing?.description} />
      </FormSection3>
      {!isSold && (
        <>
          <FormSection3 title={t(`${tPrefix}.tab1.sections.mortgageCalculator`)}>
            <FinancingAssumptions />
          </FormSection3>
          <FormSection3 title={t(`${tPrefix}.tab1.sections.investmentAssumptions.header`)}>
            <InvestmentAssumptions />
          </FormSection3>
          <FormSection3 title={t(`${tPrefix}.tab1.sections.financialDetails.header`)}>
            <FinancialDetails listing={listing} />
          </FormSection3>
        </>
      )}
    </>
  )
}
