import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { getColumns } from '@guiker/payment-app-components'
import { PaginatedTable, useNavigate } from '@guiker/react-framework'
import { Paginate } from '@guiker/shared-framework'

import { usePaymentApiClient } from '../../hooks'

const AllLegacyInvoicesTable: React.FC = () => {
  const apiClient = usePaymentApiClient()
  const navigate = useNavigate()

  const fetcher = (paginate: Paginate) => apiClient.readAllLegacyInvoices({ queryParams: paginate })

  return (
    <PaginatedTable
      queryKey={'readAllLegacyInvoices'}
      fetcher={fetcher}
      columns={getColumns({ withAction: true })}
      onRowClick={(invoice) => navigate(webappRoutes.root.wallet().payments().invoice().byId(invoice.id).path)}
    />
  )
}

export { AllLegacyInvoicesTable }
