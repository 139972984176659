import React, { useState } from 'react'

import { Roommate } from '@guiker/base-entity'
import { Carousel } from '@guiker/picture-gallery-components'
import { isCrawlerUserAgent } from '@guiker/prerender'
import { Flex, PSmall, safelyGetWindow, useTranslation } from '@guiker/react-framework'
import { Location } from '@guiker/rental-listing-components'
import { isRoommatable, RentalListing } from '@guiker/rental-listing-shared'

import { findMlsCompliance } from '../../utils'
import { AmenityComponent } from '../AmenityComponent'
import { DescriptionComponent } from '../DescriptionComponent'
import { HeadingComponent } from '../HeadingComponent/HeadingComponent'
import { OptionComponent } from '../OptionComponent'
import { PictureDisplay } from '../PictureDisplay'
import { RoommatesSection } from '../RoommatesSection'
import { TermsAndDisclaimer } from '../TermsAndDisclaimer'

type ListingContentProps = {
  onClickEdit?: (route: string) => void
  listing: RentalListing
  roommates?: Roommate[]
  isRentedOut: boolean
}

export const ListingContent: React.FC<ListingContentProps> = ({ listing, roommates, isRentedOut, onClickEdit }) => {
  const { t } = useTranslation(['main-rentalListing'])

  const [isCarouselOpen, setIsCarouselOpen] = useState(false)

  const openCarousel = () => !isRentedOut && setIsCarouselOpen(true)
  const closeCarousel = () => setIsCarouselOpen(false)
  const mlsCompliance = findMlsCompliance(listing)

  const userAgent = safelyGetWindow()?.navigator.userAgent
  const isCrawler = userAgent && isCrawlerUserAgent(userAgent, true)

  const center = listing.address?.coordinates
  const hasUnknownLocation = !center || (center?.lat === 0 && center?.lng === 0)
  const shouldShowMap = !isCrawler && !hasUnknownLocation && !isRentedOut
  const isRoommate = isRoommatable(listing)

  return (
    <>
      <Flex>
        <Flex flexDirection='column' gap={8}>
          <PictureDisplay
            pictures={listing.pictures}
            onClick={openCarousel}
            onClickEdit={onClickEdit && (() => onClickEdit?.('listing'))}
          />
          <Carousel pictures={listing.pictures} open={isCarouselOpen} closeModal={closeCarousel} />
          <HeadingComponent listing={listing} isRentedOut={isRentedOut} />
          <DescriptionComponent listing={listing} onClickEdit={onClickEdit && (() => onClickEdit('rentals'))} />
          <OptionComponent listing={listing} />
          {!!roommates?.length && <RoommatesSection roommates={roommates} />}
          <AmenityComponent
            buildingAmenities={listing.buildingAmenities}
            onClickEdit={onClickEdit && (() => onClickEdit('rentals'))}
          />
          {shouldShowMap && <Location listing={listing} />}
          {isRoommate && <TermsAndDisclaimer />}
        </Flex>
      </Flex>

      {mlsCompliance && !isRentedOut && (
        <PSmall my={2} color={60}>
          {t(mlsCompliance.disclaimer.i18nKey)}
        </PSmall>
      )}
    </>
  )
}
