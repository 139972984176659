import { useAuthenticationContext } from '@guiker/authentication-context'
import { usePaymentApiClient } from '@guiker/payment-context'
import { useQuery } from '@guiker/react-framework'

export const useAccountWalletPayments = () => {
  const { user } = useAuthenticationContext()

  const apiClient = usePaymentApiClient()
  const paginate = { sort: 'createdAt', sortOrder: -1, perPage: 5, page: 1 } as const
  const queryOption = { retry: 1, enabled: !!user?.id }

  const { data: lateInvoices, isLoading: isLateInvoicesLoading } = useQuery(
    ['readLateOrFailedInvoices', user?.id],
    () => apiClient.readAllLateInvoices(),
    queryOption,
  )

  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useQuery(
    ['list-payInMethods', user?.id],
    () => apiClient.readAllPayInMethods(),
    queryOption,
  )

  const { data: topInvoices, isLoading: isReadAllInvoiceLoading } = useQuery(
    [`readTopInvoices`, user?.id],
    () => apiClient.readAllInvoices({ queryParams: paginate }),
    queryOption,
  )

  return {
    lateInvoices,
    topInvoices,
    paymentMethods,
    isLoading: isLateInvoicesLoading || isPaymentMethodsLoading || isReadAllInvoiceLoading,
    isEmptyState: !lateInvoices?.length && !topInvoices?.data.length && !paymentMethods?.length,
  }
}
