import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from '.'

const Router: React.FC = (props) => {
  return (
    <Routes>
      <Route {...routes.reports} {...props} />
      <Route {...routes.report} {...props} />
      <Route {...routes.softcheckReport} {...props} />
      <Route {...routes.creditReport} {...props} />
      <Route {...routes.requestBackgroundCheck} {...props} />
      <Route {...routes.requestSIN} {...props} />
      <Route {...routes.requestSSN} {...props} />
      <Route {...routes.consent} {...props} />
      <Route {...routes.notConsent} {...props} />
    </Routes>
  )
}

export { Router }
