import React from 'react'

import { Divider, Flex, isMobile, makeStyles, theme, useMediaQuery } from '@guiker/react-framework'

import {
  BookingRoommateIntroApplicants,
  BookingRoommateMobile,
  BookingUnitPanel,
} from '../../components/BookingRoommate'
import { BookingRoommateIntroReviewScreenContextProvider } from '../../context'

const useStyle = makeStyles({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    paddingTop: theme.dimensions.appBar.height.desktop,
    [isMobile]: {
      paddingTop: theme.dimensions.appBar.height.mobile,
    },
  },
  bookingPanelContainer: {
    height: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
    overflow: 'scroll',
    padding: theme.spacing(2),
    width: 400,
    minWidth: 400,
  },
  contentContainer: {
    height: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
    width: '100%',
    overflow: 'scroll',
  },
})

export const BookingRoommateIntroReviewScreen: React.FC = () => {
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile)
    return (
      <BookingRoommateIntroReviewScreenContextProvider>
        <BookingRoommateMobile />
      </BookingRoommateIntroReviewScreenContextProvider>
    )
  return (
    <BookingRoommateIntroReviewScreenContextProvider>
      <Flex className={classes.root}>
        <Flex className={classes.bookingPanelContainer}>
          <BookingUnitPanel />
        </Flex>
        <Divider orientation='vertical' m={0} />
        <Flex className={classes.contentContainer}>
          <BookingRoommateIntroApplicants />
        </Flex>
      </Flex>
    </BookingRoommateIntroReviewScreenContextProvider>
  )
}
