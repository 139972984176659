import React from 'react'

import { AppLayout } from './components/AppLayout'
import { useQuery } from './hooks'
import { Router } from './routes'
import { getFilteredCategories } from './utils'

const App: React.FC<React.PropsWithChildren> = () => {
  const { data: categories } = useQuery('getFilteredCategories', getFilteredCategories)

  return (
    <AppLayout categories={categories}>
      <Router />
    </AppLayout>
  )
}

export { App }
