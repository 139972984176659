import React from 'react'

import { Button, Link } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { mainPathBuilder } from '@guiker/investment-shared'

import { EmptyStateLayout } from '../EmptyStateLayout'
import { InvestmentEmptyStateIllustration } from './InvestmentEmptyStateIllustration'

export const InvestmentEmptyState = () => {
  const { t } = useTranslation(['main-myInvestments'])
  const tPrefix = 'main-myInvestments:investments.emptyState'

  return (
    <EmptyStateLayout
      title={t(`${tPrefix}.title`)}
      text={t(`${tPrefix}.text`)}
      cta={
        <Link to={mainPathBuilder.root.path}>
          <Button>{t(`${tPrefix}.cta`)}</Button>
        </Link>
      }
      illustration={<InvestmentEmptyStateIllustration />}
    />
  )
}
