import React from 'react'

import { Trans } from 'react-i18next'

import { FormSection4 } from '@guiker/components-library'
import { MessagesBubbleIcon } from '@guiker/icons'

import { useTranslation } from '../../../hooks'
import { clsx, makeStyles, theme } from '../../../style'
import { Note, P, PBold, Typography } from '../../'

const useStyles = makeStyles({
  placeOfPayment: {
    fontWeight: 500,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginBottomZero: {
    marginBottom: 0,
  },
})

type Props = {
  name: string
}

export const PaymentInformation: React.FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])

  return (
    <FormSection4>
      <Note statusColor='info' title={t('quebecLeaseScreenD:rentAndPaymentInfo')} icon={<MessagesBubbleIcon />}>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenD:text1'>
            <PBold color='info' component='span' />
          </Trans>
        </P>
        <Typography className={classes.marginBottom}>{t('quebecLeaseScreenD:text2')}</Typography>
        <PBold className={classes.marginBottom}>{t('quebecLeaseScreenD:text3')}</PBold>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenD:text4'>
            <PBold color='info' component='span' />
            <PBold component='span' />
          </Trans>
        </P>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenD:text5'>
            <PBold color='info' component='span' />
            <PBold component='span' />
          </Trans>
        </P>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenD:text6'>
            <PBold color='info' component='span' />
            <PBold component='span' />
          </Trans>
        </P>
        <P className={classes.marginBottomZero}>
          <Trans i18nKey='quebecLeaseScreenD:text7'>
            <PBold color='info' component='span' />
          </Trans>
        </P>
      </Note>
      <Note>
        <P className={classes.marginBottomZero}>
          <Trans i18nKey='quebecLeaseScreenD:placeOfPaymentAgreement'>
            <PBold className={clsx(classes.placeOfPayment)} color='info' component='span' />
          </Trans>
        </P>
      </Note>
    </FormSection4>
  )
}
