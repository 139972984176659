import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'

import { TFunction, useWatch } from '../../../hooks'
import { makeStyles } from '../../../style'
import { Ontario, Ontario as Types } from '../../../types'
import { Collapse, CurrencyTextField, FormSection3, Note, P, RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Types.KeyDeposit
  name: 'keyDeposit'
  readOnly?: boolean
  t: TFunction
}

const useStyles = makeStyles({
  marginBottomZero: {
    marginBottom: 0,
  },
})

export const KeyDeposit: React.FC<Props> = ({ defaultValue, name, readOnly, t }) => {
  const classes = useStyles()
  const keyDepositName = `${name}.isKeyDepositRequired` as const
  const isKeyDepositRequired = useWatch<Ontario.LeaseInformation, typeof keyDepositName>({
    name: keyDepositName,
    defaultValue: defaultValue?.isKeyDepositRequired,
  })

  return (
    <FormSection3>
      <RadioGroup
        defaultValue={isKeyDepositRequired}
        name={keyDepositName}
        label={t('ontarioLeaseScreenE:selectOne')}
        options={[
          { value: false, label: t('ontarioLeaseScreenE:noKeyDeposit') },
          { value: true, label: t('ontarioLeaseScreenE:keyDeposit') },
        ]}
        gridContainerProps={{ spacing: 1 }}
        readOnly={readOnly}
        maxWidth={420}
      />
      <Collapse in={isKeyDepositRequired}>
        <CurrencyTextField
          className={classes.marginBottomZero}
          defaultValue={defaultValue?.keyDepositAmount}
          name={`${name}.keyDepositAmount`}
          label={t('ontarioLeaseScreenE:keyDepositLabel')}
          readOnly={readOnly}
          maxWidth={420}
        />
      </Collapse>

      <P>{t('ontarioLeaseScreenE:coverCost')}</P>
      <P>{t('ontarioLeaseScreenE:keyDepositDetails')}</P>
      <TextField
        defaultValue={defaultValue?.keyDepositDetails || ''}
        name={`${name}.keyDepositDetails`}
        label={t('ontarioLeaseScreenE:details')}
        readOnly={readOnly}
        maxWidth={420}
      />
      <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
        {t('ontarioLeaseScreenE:keyDepositNote')}
      </Note>
    </FormSection3>
  )
}
