import React, { useEffect } from 'react'

import { Divider, Flex, H2, PageLayout, PageMetaTags, useLayoutContext } from '@guiker/react-framework'

import { Question, QuestionWithLink, QuestionWithList } from '../../components'
import { useT } from '../../i18n'

const InvestmentsFAQScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.investmentsFAQ' })
  const { setFooterIsDisplayed } = useLayoutContext()

  useEffect(() => {
    setFooterIsDisplayed(true)
  }, [])

  return (
    <PageLayout title={tMain('title')}>
      <PageMetaTags subtitle={tMain('subtitle')} />
      <Flex flexDirection='column' gap={10}>
        <Flex flexDirection='column' gap={4}>
          <H2 mb={0}>{tMain('about.title')}</H2>
          <Divider width='100%' />
          <Question question={tMain('about.guiker.q')} answer={tMain('about.guiker.a')} />
          <Question question={tMain('about.founded.q')} answer={tMain('about.founded.a')} />
          <Question question={tMain('about.regulated.q')} answer={tMain('about.regulated.a')} />
          <Question question={tMain('about.REIT.q')} answer={tMain('about.REIT.a')} />
          <Question question={tMain('about.crowdFunding.q')} answer={tMain('about.crowdFunding.a')} />
        </Flex>
        <Flex flexDirection='column' gap={4}>
          <H2 mb={0}>{tMain('how.title')}</H2>
          <Divider width='100%' />
          <Question question={tMain('how.company.q')} answer={tMain('how.company.a')} />
          <Question question={tMain('how.fails.q')} answer={tMain('how.fails.a')} />
          <Question question={tMain('how.value.q')} answer={tMain('how.value.a')} />
          <Question question={tMain('how.liability.q')} answer={tMain('how.liability.a')} />
          <Question question={tMain('how.tenants.q')} answer={tMain('how.tenants.a')} />
          <Question question={tMain('how.living.q')} answer={tMain('how.living.a')} />
          <Question question={tMain('how.damaged.q')} answer={tMain('how.damaged.a')} />
          <Question question={tMain('how.risk.q')} answer={tMain('how.risk.a')} />
          <Question question={tMain('how.ownProperties.q')} answer={tMain('how.ownProperties.a')} />
          <Question question={tMain('how.creditScore.q')} answer={tMain('how.creditScore.a')} />
          <Question question={tMain('how.criteria.q')} answer={tMain('how.criteria.a')} />
        </Flex>
        <Flex flexDirection='column' gap={4}>
          <H2 mb={0}>{tMain('process.title')}</H2>
          <Divider width='100%' />
          <Question question={tMain('process.obtain.q')} answer={tMain('process.obtain.a')} />
          <Question question={tMain('process.target.q')} answer={tMain('process.target.a')} />
          <Question question={tMain('process.location.q')} answer={tMain('process.location.a')} />
          <Question question={tMain('process.own.q')} answer={tMain('process.own.a')} />
          <Question question={tMain('process.predetermined.q')} answer={tMain('process.predetermined.a')} />
          <Question question={tMain('process.sell.q')} answer={tMain('process.sell.a')} />
          <Question question={tMain('process.preExisting.q')} answer={tMain('process.preExisting.a')} />
          <Question question={tMain('process.losingTenants.q')} answer={tMain('process.losingTenants.a')} />
        </Flex>
        <Flex flexDirection='column' gap={4}>
          <H2 mb={0}>{tMain('gettingStarted.title')}</H2>
          <Divider width='100%' />
          <QuestionWithLink
            question={tMain('gettingStarted.signUp.q')}
            answer='main-investments:screens.investmentsFAQ.gettingStarted.signUp.a'
            location='/invest'
          />
          <QuestionWithList
            question={tMain('gettingStarted.info.q')}
            topAnswer={tMain('gettingStarted.info.a')}
            list={[
              tMain('gettingStarted.info.li1'),
              tMain('gettingStarted.info.li2'),
              tMain('gettingStarted.info.li3'),
              tMain('gettingStarted.info.li4'),
            ]}
          />
          <QuestionWithList
            question={tMain('gettingStarted.recommendations.q')}
            topAnswer={tMain('gettingStarted.recommendations.a1')}
            list={[
              tMain('gettingStarted.recommendations.li1'),
              tMain('gettingStarted.recommendations.li2'),
              tMain('gettingStarted.recommendations.li3'),
            ]}
            bottomAnswer={tMain('gettingStarted.recommendations.a2')}
          />
          <Question
            question={tMain('gettingStarted.documentation.q')}
            answer={tMain('gettingStarted.documentation.a')}
          />
          <Question question={tMain('gettingStarted.fees.q')} answer={tMain('gettingStarted.fees.a')} />
          <Question question={tMain('gettingStarted.eligible.q')} answer={tMain('gettingStarted.eligible.a')} />
        </Flex>
        <Flex flexDirection='column' gap={4}>
          <H2 mb={0}>{tMain('makingMoney.title')}</H2>
          <Divider width='100%' />
          <Question question={tMain('makingMoney.owning.q')} answer={tMain('makingMoney.owning.a')} />
          <Question question={tMain('makingMoney.money.q')} answer={tMain('makingMoney.money.a')} />
          <Question question={tMain('makingMoney.sell.q')} answer={tMain('makingMoney.sell.a')} />
          <QuestionWithList
            question={tMain('makingMoney.why.q')}
            topAnswer={tMain('makingMoney.why.a')}
            list={[
              tMain('makingMoney.why.li1'),
              tMain('makingMoney.why.li2'),
              tMain('makingMoney.why.li3'),
              tMain('makingMoney.why.li4'),
            ]}
          />
          <Question question={tMain('makingMoney.taxes.q')} answer={tMain('makingMoney.taxes.a')} />
          <Question question={tMain('makingMoney.trading.q')} answer={tMain('makingMoney.trading.a')} />
        </Flex>
        <Flex flexDirection='column' gap={4}>
          <H2 mb={0}>{tMain('impact.title')}</H2>
          <Divider width='100%' />
          <Question question={tMain('impact.affordibility.q')} answer={tMain('impact.affordibility.a')} />
        </Flex>
      </Flex>
    </PageLayout>
  )
}

export { InvestmentsFAQScreen }
