import React from 'react'

import { TwoColumnsGridLayout } from '@guiker/components-library'

import { useTranslation } from '../../../hooks'
import { makeStyles } from '../../../style'
import { Quebec } from '../../../types'
import { TextField } from '../../'
import { FormAddress } from '../form-components/FormAddress/FormAddress'

export type Props = {
  defaultValue: Quebec.Person
  fullNameFieldLabel: string
  name?: string
  disabled?: boolean
  readOnly?: boolean
}

const useStyles = makeStyles({
  root: {
    marginBottom: 0,
  },
})

export const FormPerson: React.FC<Props> = ({ defaultValue, fullNameFieldLabel, disabled, readOnly, name = '' }) => {
  const { t } = useTranslation(['common', 'errors'])
  const classes = useStyles()
  const sharedProps = { className: classes.root, disabled, readOnly, maxWidth: '100%' }

  if (name) {
    name = `${name}.`
  }

  return (
    <TwoColumnsGridLayout>
      <TextField
        defaultValue={defaultValue?.fullName}
        label={fullNameFieldLabel}
        name={`${name}fullName`}
        {...sharedProps}
      />
      <FormAddress
        name={`${name}address`}
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={defaultValue.address}
      />
      <TextField
        defaultValue={defaultValue?.phoneNumber}
        label={t('common:phoneNumber')}
        name={`${name}phoneNumber`}
        {...sharedProps}
      />
      <TextField
        defaultValue={defaultValue?.emailAddress}
        label={t('common:emailAddress')}
        name={`${name}emailAddress`}
        {...sharedProps}
      />
    </TwoColumnsGridLayout>
  )
}
