import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { AuthBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { AuthenticatedApi, PayoutMethodType } from '@guiker/payout-shared'
import {
  ApiForm,
  FullScreenSpinner,
  Step,
  StepProps,
  useNavigate,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { getPayoutMethodAssetData } from '../../utils'
import { VerificationDocumentsFormContent } from '../VerificationDocumentsFormContent'

export type InvestmentVerificationDocumentsStepProps = StepProps

const InvestmentVerificationDocumentsStepContent: React.FC<InvestmentVerificationDocumentsStepProps> = ({
  onClickNext,
  ...props
}) => {
  const { t } = useTranslation(['main-payout'])
  const { payoutMethod, updatePayoutMethod, isSettled, isFetching } = usePayoutMethodNestedContext()
  const { bulkUpdate } = useBulkAssetUploaderContext()
  const navigate = useNavigate()
  const apiClient = useAuthenticatedPayoutApiClient()
  const resolver = yupResolver(AuthenticatedApi.Schemas.updateVerificationDocumentsSchema, {
    context: { payoutMethodType: payoutMethod?.type ?? PayoutMethodType.CANADIAN_BANK_ACCOUNT },
  })

  const onSubmit = async (payload: AuthenticatedApi.Schemas.UpdateVerificationDocumentsSchema) => {
    return apiClient.updatePayoutInvestmentVerificationDocuments({
      pathParams: { payoutMethodId: payoutMethod.id },
      payload,
    })
  }

  if (!isSettled || isFetching) {
    return <FullScreenSpinner />
  }
  if (isSettled && !!payoutMethod.verification) {
    navigate(webappRoutes.root.wallet().payoutPreferences().path)
  }
  return (
    <ApiForm
      beforeValidation={() => bulkUpdate()}
      onSubmit={onSubmit}
      formName={'InvestmentVerificationDocumentsStep'}
      formOptions={{
        resolver,
        defaultValues: payoutMethod,
      }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext()
        },
      }}
    >
      {({ formState: { isSubmitting, errors } }) => (
        <Step
          {...props}
          errors={errors}
          isSubmitting={isSubmitting}
          title={t('main-payout:createPayoutMethodScreen.verificationDocuments.subtitle')}
        >
          <VerificationDocumentsFormContent defaultValue={payoutMethod} readOnly={false} showExplanation={false} />
        </Step>
      )}
    </ApiForm>
  )
}

const InvestmentVerificationDocumentsStep: React.FC<InvestmentVerificationDocumentsStepProps> = (props) => {
  const { payoutMethod, isFetching } = usePayoutMethodNestedContext()

  if (isFetching) {
    return <FullScreenSpinner />
  }

  const { assetGroups, scope } = getPayoutMethodAssetData(payoutMethod)
  return (
    <AuthBulkAssetUploaderContextProvider assetGroups={assetGroups} scope={scope}>
      <InvestmentVerificationDocumentsStepContent {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}

export { InvestmentVerificationDocumentsStep }
