import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { webappRoutes } from '@guiker/account-shared'
import { InvoiceContent } from '@guiker/payment-main-components'
import { ScopeType } from '@guiker/payment-shared'
import { Flex, FullScreenSpinner, useParams, useQuery } from '@guiker/react-framework'

import { useAuthenticatedPaymentApiClient, useAuthenticationContext } from '../../hooks'
import { useT } from '../../i18n'

export const ViewDisputeRecoveryInvoiceScreen: React.FC = () => {
  const { id: invoiceId } = useParams()
  const apiClient = useAuthenticatedPaymentApiClient()
  const { data: invoice, isLoading } = useQuery(['readOneRecoveryDisputeInvoice', invoiceId], () => {
    return apiClient.readLastInvoiceByScope({
      pathParams: { scopeId: invoiceId, scopeType: ScopeType.DISPUTED_INVOICE },
    })
  })
  const { user } = useAuthenticationContext()
  const { tMain: tNav } = useT({ screenName: 'sideMenu.navItem' })

  if (isLoading) return <FullScreenSpinner />

  const basePath = webappRoutes.root.wallet().payments()
  const breadcrumbItems = [
    { label: tNav('wallet'), to: '../../' },
    { label: tNav('payments'), to: basePath.path },
    { label: tNav('paymentHistory'), to: basePath.history().path },
    { label: invoice.scope.id, to: basePath.invoice().byId(invoice.scope.id).path },
    { label: tNav('recoveryDisputeInvoice') },
  ]

  return (
    <AccountPageLayout breadcrumbItems={breadcrumbItems} isLoading={isLoading}>
      <Flex flexDirection='column' maxWidth={900}>
        <InvoiceContent user={user} />
      </Flex>
    </AccountPageLayout>
  )
}
