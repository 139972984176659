import React, { Children, cloneElement } from 'react'

import {
  Button,
  Card,
  FilterIcon,
  Flex,
  IconButton,
  Modal,
  theme,
  useLayoutContext,
  useModal,
} from '@guiker/react-framework'
import { Sticky } from '@guiker/react-sticky'

import { useT } from '../../i18n'

export const SearchBar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAtLeastTablette } = useLayoutContext()
  const { isOpen, openModal, closeModal } = useModal()
  const { tMain } = useT({ screenName: 'components.searchBar' })
  const stickyOffset = theme.dimensions.appBar.height.mobile

  if (!isAtLeastTablette) {
    return (
      <>
        <Modal open={isOpen} onClose={closeModal} title={tMain('modal.title')}>
          <Flex flexGrow={1} gap={6} flexDirection='column' fullWidth>
            {Children.toArray(children)
              .filter((child) => !!child)
              .reduce((acc: any[], node: React.ReactElement) => {
                return [
                  ...acc,
                  cloneElement(node, {
                    maxWidth: '100%',
                  }),
                ]
              }, [])}
            <Button onClick={closeModal}>{tMain('modal.cta')}</Button>
          </Flex>
        </Modal>
        <div>
          <Sticky
            topOffset={-stickyOffset}
            stickyStyle={{
              top: stickyOffset,
              zIndex: 100,
            }}
          >
            <Card shadow='medium' px={1.5} py={1}>
              <Flex color={60} justifyContent='space-between' fullWidth alignItems='center' gap={2}>
                <Flex justifyContent='space-between' fullWidth alignItems='center' flexWrap='wrap' gap={1}>
                  {Children.toArray(children)
                    .filter((child) => !!child)
                    .reduce((acc: any[], node: React.ReactElement) => {
                      return [
                        ...acc,
                        cloneElement(node, {
                          readOnly: true,
                        }),
                      ]
                    }, [])}
                </Flex>
                <IconButton onClick={openModal}>
                  <FilterIcon />
                </IconButton>
              </Flex>
            </Card>
          </Sticky>
        </div>
      </>
    )
  }

  return (
    <Flex flexGrow={1} gap={6} flexDirection='row' fullWidth>
      {children}
    </Flex>
  )
}
