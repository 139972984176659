import React from 'react'

import { ReportChip } from '@guiker/background-check-components'
import { BackgroundCheck } from '@guiker/background-check-shared'
import { Paginate } from '@guiker/paginate'
import { useQuery } from '@guiker/react-query'
import { Fetcher } from '@guiker/react-query-components'
import { useLocation, useNavigate, usePaginationQueryParams } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'

import {
  Button,
  ColumnGridItem,
  FeedbackComponent,
  Flex,
  H4,
  NavigationList,
  OneColumnGridLayout,
  P,
  PageLayout,
  PBold,
  Spinner,
  TablePagination,
} from '../../components'
import { useBackgroundCheckApiClient } from '../../hooks/use-background-check-api-client'
import { useTranslation } from '../../i18n'

type Props = {
  cacheKey: string | string[]
  enabled?: boolean
  emptyState?: React.ReactNode
  fetcher?: Fetcher<BackgroundCheck>
}

const PaginatedReports: React.FC<Props> = ({ cacheKey, enabled = true, fetcher, emptyState }) => {
  if (!enabled) {
    return null
  }

  const { t } = useTranslation()
  const { page } = usePaginationQueryParams()
  const location = useLocation()
  const navigate = useNavigate()
  const perPage = 10

  const { data: reports, isLoading } = useQuery([cacheKey, page, perPage], () => fetcher({ page, perPage }), {
    enabled,
    keepPreviousData: true,
  })

  if (isLoading) {
    return <Spinner />
  } else if (emptyState && !reports?.data?.length) {
    return (
      <OneColumnGridLayout>
        <Button onClick={() => navigate('./request')}>{t('reports:cta')}</Button>
        {emptyState}
      </OneColumnGridLayout>
    )
  }

  const items = reports.data.map((report) => {
    const fullName = `${report.firstName} ${report.lastName}`
    const requestStatus = report.results.overall?.status
    let secondaryText

    if (requestStatus !== 'Returned') {
      if (report.consent === true) {
        secondaryText = (
          <P mb={0} color={60}>
            {t(`reports:stillAnalyzing`)}
          </P>
        )
      } else if (report.consent === false) {
        secondaryText = (
          <P mb={0} color={60}>
            {t(`reports:refusedConsent`)}
          </P>
        )
      } else {
        secondaryText = (
          <P mb={0} color={60}>
            {t(`reports:waitForConsent`)}
          </P>
        )
      }
    } else {
      const creditScore = report.results.creditReport?.creditScore
      const creditStatus = report.results.creditReport?.overallScore
      const score = creditScore

      secondaryText = <ReportChip score={score} creditStatus={creditStatus} />
    }

    const url =
      report.requestCreditReport && report.requestSoftcheck
        ? `report/${report.applicantId}`
        : report.requestCreditReport
        ? `report/${report.applicantId}/credit-report`
        : `report/${report.applicantId}/softcheck-report`

    return {
      disabled: report.consent !== true,
      primaryText: (
        <Flex fullWidth flexWrap='wrap' justifyContent='space-between' alignItems='center' gap={2}>
          <PBold mb={0}>{fullName}</PBold>
          {secondaryText}
        </Flex>
      ),
      url,
    }
  })

  return (
    <OneColumnGridLayout>
      <FeedbackComponent loading={isLoading} />
      <Button onClick={() => navigate('./request')}>{t('reports:cta')}</Button>
      <ColumnGridItem md={2}>
        <NavigationList hasAvatar={false} items={items} />
        {reports.meta.totalPages > 1 && (
          <Flex mt={2} fullWidth justifyContent='center'>
            <TablePagination
              withLink={true}
              currentUrl={location.pathname.replace(/\/$/, '')}
              totalPages={reports?.meta?.totalPages}
              page={page}
            />
          </Flex>
        )}
      </ColumnGridItem>
    </OneColumnGridLayout>
  )
}

export const Reports: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const apiClient = useBackgroundCheckApiClient()

  const fetcher = (paginate: Paginate) => apiClient.readAllBackgroundChecks({ queryParams: paginate })

  return (
    <TakeoverContextProvider onClose={() => navigate('..')} title={t('reports:title')}>
      <PageLayout maxWidth={900}>
        <PaginatedReports
          cacheKey={'backgroundCheck-readAllBackgroundCheck'}
          emptyState={<H4>{t('reports:emptyStateText')}</H4>}
          fetcher={fetcher}
        />
      </PageLayout>
    </TakeoverContextProvider>
  )
}
