import React from 'react'

import { useT } from '../../i18n'
import { PriceSlider } from '../PriceSlider'

export const PER_UNIT_PRICE_RANGE = {
  min: 0,
  max: 10000,
}

type PricePerUnitRangeSliderProps = {
  readOnly?: boolean
}

export const PricePerUnitRangeSlider: React.FC<PricePerUnitRangeSliderProps> = ({ readOnly }) => {
  const { tMain } = useT({ screenName: 'components.pricePerUnit' })

  return (
    <PriceSlider name='pricePerUnit' label={tMain('label')} readOnly={readOnly} {...PER_UNIT_PRICE_RANGE} step={100} />
  )
}
