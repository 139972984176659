import React from 'react'

import { Booking } from '@guiker/booking-shared'

import { BookingDocumentsTable } from '../../components'

export const BookingDocumentApplicantView: React.FC<{
  booking: Booking
  accessControlToken: string
  userId: string
}> = ({ booking, accessControlToken, userId }) => {
  return <BookingDocumentsTable booking={booking} accessControlToken={accessControlToken} applicantId={userId} />
}
