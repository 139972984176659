import React from 'react'

import { OntarioSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useOntarioLeaseContext } from '../../../hooks'
import { FormSection1, Step, StepProps } from '../../'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepE: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { rentDiscounts, rentDeposit, keyDeposit },
    },
  } = useOntarioLeaseContext()

  const onSubmit = (payload: OntarioSchemas.RentDepositRentDiscountsAndKeyDeposit) =>
    apiClient.updateOntarioLeaseRentDepositRentDiscountsAndKeyDeposit({
      pathParams: { leaseId },
      payload,
    })

  return (
    <Step
      hasBackButton={true}
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.rentDepositRentDiscountsAndKeyDeposit),
        defaultValues: { rentDiscounts, rentDeposit, keyDeposit },
      }}
      formName={'OntarioLeaseFormStepE'}
      {...props}
    >
      <FormSection1>
        <FormContent rentDiscounts={rentDiscounts} rentDeposit={rentDeposit} keyDeposit={keyDeposit} />
      </FormSection1>
    </Step>
  )
}
