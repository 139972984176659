import React from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { JwtBookingRentPaymentsPlanScopeNestedRouter } from '@guiker/rent-payment-context'

const RentPaymentsPlanScopeNestedRouter: React.FC = () => {
  const { accessControl } = useBookingContext()
  return <JwtBookingRentPaymentsPlanScopeNestedRouter acl={accessControl} />
}

export { RentPaymentsPlanScopeNestedRouter }
