import React from 'react'

import { makeStyles, theme, useLayoutContext } from '..'
import { AppBar } from '../AppBar'

const useStyles = makeStyles(
  {
    main: ({ isMobile }: { isMobile: boolean }) => ({
      position: 'relative',
      top: theme.dimensions.appBar.height[isMobile ? 'mobile' : 'desktop'],
    }),
  },
  { name: 'ArticlePageLayout' },
)

type ArticlePageLayoutProps = React.PropsWithChildren & {
  categories: string[]
}

const ArticlePageLayout: React.FC<ArticlePageLayoutProps> = ({ children, categories }) => {
  const { isMobile } = useLayoutContext()
  const classes = useStyles({ isMobile })

  return (
    <div>
      <header>
        <AppBar categories={categories} />
      </header>
      <main className={classes.main}>{children}</main>
    </div>
  )
}

export { ArticlePageLayout }
