import { AuthApi } from '@guiker/propsharing-shared'
import { StepWithSchema } from '@guiker/react-framework'

import { TFunction } from '../../i18n'
import { Step } from './steps'

const tPrefix = {
  common: 'common-propsharing:investorProfile.steps',
  main: 'main-propsharing:screens.createInvestorProfileScreen',
}

export const getSteps = (t: TFunction): StepWithSchema<unknown>[] => [
  {
    name: 'step2',
    component: Step.AboutYou,
    label: t(`${tPrefix.common}.step2.title`),
    description: t(`${tPrefix.main}.subtitle`),
    schema: AuthApi.Schema.updateInvestorProfileStepperSchema.AboutYou,
    schemaPath: 'personal',
  },
  {
    name: 'step3',
    component: Step.EmploymentInformation,
    label: t(`${tPrefix.common}.step3.title`),
    description: t(`${tPrefix.main}.subtitle`),
    schema: AuthApi.Schema.updateInvestorProfileStepperSchema.EmploymentInformation,
    schemaPath: 'employment',
  },
  {
    name: 'step4',
    component: Step.FinancialProfile,
    label: t(`${tPrefix.common}.step4.title`),
    description: t(`${tPrefix.main}.subtitle`),
    schema: AuthApi.Schema.updateInvestorProfileStepperSchema.FinancialProfile,
    schemaPath: 'financial',
  },
  {
    name: 'step5',
    component: Step.IdentityVerification,
    label: t(`${tPrefix.common}.step5.title`),
    description: t(`${tPrefix.main}.subtitle`),
  },
]
