import React from 'react'

import { AuthenticatedApi } from '@guiker/background-check-shared'
import { yupResolver } from '@guiker/react-hook-form'

import {
  ApiForm,
  BackgroundCheckCreditReport,
  BackgroundCheckSoftCheck,
  Box,
  Checkbox,
  Grid,
  H4,
  Step,
  StepProps,
} from '../../components'
import { useBackgroundCheckFormContext } from '../../hooks/use-background-check-form-context'
import { useTranslation } from '../../i18n'

export type Step01Props = StepProps

const Step01: React.FC<Step01Props> = ({ onClickNext, ...props }) => {
  const { backgroundCheckForm, setBackgroundCheckForm } = useBackgroundCheckFormContext()
  const { t } = useTranslation()

  const onSubmit = async (values: { requestSoftcheck: boolean; requestCreditReport: boolean }) => {
    setBackgroundCheckForm((backgroundCheck) => ({ ...backgroundCheck, ...values }))

    onClickNext()
  }

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(
          (AuthenticatedApi.Schemas.createBackgroundCheckPayloadValidator as any).pick([
            'requestCreditReport',
            'requestSoftcheck',
          ]),
        ),
        defaultValues: {
          requestCreditReport: backgroundCheckForm.requestCreditReport,
          requestSoftcheck: backgroundCheckForm.requestSoftcheck,
        },
      }}
      apiOptions={{ skipMutation: true }}
      formName={'BackgroundCheckRequestStep01'}
    >
      {({ isLoading, formState: { errors } }) => (
        <Step hasBackButton={false} isSubmitting={isLoading} errors={errors} {...props}>
          <Box p={3} display='flex' flexDirection='column'>
            <H4 mb={3}>{t('step01:title')}</H4>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Checkbox
                  label={t('step01:softCheck')}
                  name='requestSoftcheck'
                  adornment={<BackgroundCheckSoftCheck color='secondary' />}
                  description={t('step01:softCheckDescription')}
                />
              </Grid>
              <Grid item>
                <Checkbox
                  label={t('step01:creditReport')}
                  name='requestCreditReport'
                  adornment={<BackgroundCheckCreditReport color='secondary' />}
                  description={t('step01:creditReportDescription')}
                />
              </Grid>
            </Grid>
          </Box>
        </Step>
      )}
    </ApiForm>
  )
}

export { Step01 }
