import React from 'react'

import { Box, Button, H4, PageLayout, TakeoverContextProvider } from '../../components'
import { useNavigate, useTakeoverContext } from '../../hooks'
import { useTranslation } from '../../i18n'

const Content: React.FC = () => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const { onClose } = useTakeoverContext()

  return (
    <PageLayout maxWidth={900}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <H4>{t(`main-tenantApplication:screens.tenantApplicationFeedback.updated`)}</H4>
        <Button onClick={onClose}>{t('common:actions.close')}</Button>
      </Box>
    </PageLayout>
  )
}

export const TenantApplicationFeedbackScreen: React.FC = () => {
  const navigate = useNavigate()

  return (
    <TakeoverContextProvider onClose={() => navigate('/')}>
      <Content />
    </TakeoverContextProvider>
  )
}
