import React from 'react'

import { ColumnGridItem, OneColumnGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'
import { Quebec } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation, useWatch } from '../../../hooks'
import { Collapse, Note, RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Quebec.Rent
  name: 'rent'
}

export const SubsidyEligibility: React.FC<Props> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])
  const isLesseeEligibleForSubsidy = useWatch<Quebec.LeaseInformation, `${typeof name}.isLesseeEligibleForSubsidy`>({
    name: `${name}.isLesseeEligibleForSubsidy`,
    defaultValue: defaultValue?.isLesseeEligibleForSubsidy,
  })

  const { can } = useLeaseContext()
  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <ColumnGridItem md={2}>
      <div>
        <RadioGroup
          defaultValue={isLesseeEligibleForSubsidy}
          label={t('quebecLeaseScreenD:rentSubsidyLabel')}
          readOnly={!canDraftLease}
          name={`${name}.isLesseeEligibleForSubsidy`}
          options={[
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
          ]}
        />
        <Collapse in={isLesseeEligibleForSubsidy} unmountOnExit>
          <Note>
            <OneColumnGridLayout>
              <TextField
                defaultValue={defaultValue?.rentSubsidy}
                readOnly={!canDraftLease}
                name={`${name}.rentSubsidy`}
                label={t('common:specify')}
              />
            </OneColumnGridLayout>
          </Note>
        </Collapse>
      </div>
    </ColumnGridItem>
  )
}
