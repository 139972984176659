import React from 'react'

import { FormSection3, OneColumnGridLayout } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { TextField } from '../../'

type Props = {
  defaultValue: Quebec.ServicesAndConditions
  name: string
  readOnly: boolean
}

export const OtherServices: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const servicesAndConditions = useWatch({ name, defaultValue })

  return (
    <FormSection3
      title={t('quebecLeaseScreenE:otherServicesLabel')}
      subtitle={t('quebecLeaseScreenE:otherServicesDescription')}
    >
      <OneColumnGridLayout>
        <TextField
          multiline
          readOnly={readOnly}
          label={t('common:specify')}
          defaultValue={servicesAndConditions?.otherServicesRestrictionCondition}
          name={`${name}.otherServicesRestrictionCondition`}
        />
      </OneColumnGridLayout>
    </FormSection3>
  )
}
