import React from 'react'

import { TwoColumnsGridLayout } from '@guiker/components-library'

import { useTranslation } from '../../../hooks'
import { Initials, Signature } from '../../../types'
import { DatePicker as DatePickerComponent, TextField } from '../../'

export interface StaticSignatureContainerProps {
  defaultValue?: Signature | Initials | null
  type?: 'initials' | 'signature'
  name?: string
  readOnly?: boolean
}

const DatePicker = (props) => <DatePickerComponent {...props} />

const StaticSignatureContainer: React.FC<StaticSignatureContainerProps> = ({ defaultValue, name, ...props }) => {
  const { t } = useTranslation()

  return (
    <TwoColumnsGridLayout>
      <TextField readOnly name={`${name}.content`} defaultValue={defaultValue?.content} {...props} />
      <DatePicker readOnly name={`${name}.date`} defaultValue={defaultValue?.date} label={t('common:date')} />
    </TwoColumnsGridLayout>
  )
}

export { StaticSignatureContainer }
