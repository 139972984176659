import { PayoutMethod } from '@guiker/payout-shared'

export const getPayoutMethodAssetData = (payoutMethod: PayoutMethod) => ({
  assetGroups: payoutMethod?.verificationDocuments.map((group, groupIndex) => ({
    ...group,
    assets: group.assets.map((asset, index) => ({
      ...asset,
      name: `verificationDocuments[${groupIndex}].assets[${index}]`,
    })),
  })),
  scope: {
    type: 'PayoutMethod',
    id: payoutMethod?.id,
  },
})
