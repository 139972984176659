import React from 'react'

import { RouteConfig } from '@guiker/router'
import { ApplicationComponentsContextProvider } from '@guiker/tenant-application-components'
import {
  AuthTenantApplicationContextProvider,
  JwtTenantApplicationContextProvider,
} from '@guiker/tenant-application-context'

import {
  CreateTenantApplicationScreen,
  GuarantorNoConsentScreen,
  PreApplicationScreen,
  TenantApplicationApplicantView,
  TenantApplicationFeedbackScreen,
  TenantApplicationGuarantorView,
} from '../screens'

const queryKey = 'TenantApplicationContext-readOneTenantApplication'

type ContextProviderProps = {
  ChildComponent: React.FC
}

const AuthContextProvider: React.FC<ContextProviderProps & { forceRender: boolean }> = ({
  ChildComponent,
  forceRender,
  ...props
}) => (
  <AuthTenantApplicationContextProvider forceRender={forceRender}>
    <ApplicationComponentsContextProvider queryKey={queryKey}>
      <ChildComponent {...props} />
    </ApplicationComponentsContextProvider>
  </AuthTenantApplicationContextProvider>
)

const JwtContextProvider: React.FC<ContextProviderProps> = ({ ChildComponent, ...props }) => (
  <JwtTenantApplicationContextProvider>
    <ApplicationComponentsContextProvider queryKey={queryKey}>
      <ChildComponent {...props} />
    </ApplicationComponentsContextProvider>
  </JwtTenantApplicationContextProvider>
)

const defaultRouteConfig: Partial<RouteConfig> = {
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes: { [key: string]: RouteConfig } = {
  createTenantApplication: {
    ...defaultRouteConfig,
    path: '/',
    Component: (props) => <AuthContextProvider forceRender ChildComponent={CreateTenantApplicationScreen} {...props} />,
  },
  preApplication: {
    path: 'preapplication',
    exact: true,
    Component: (props) => <AuthContextProvider forceRender ChildComponent={PreApplicationScreen} {...props} />,
  },
  viewTenantApplication: {
    ...defaultRouteConfig,
    path: 'view',
    Component: (props) => <AuthContextProvider ChildComponent={TenantApplicationApplicantView} {...props} />,
  },
  guarantorSign: {
    path: 'guarantor',
    Component: (props) => <JwtContextProvider ChildComponent={TenantApplicationGuarantorView} {...props} />,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
  tenantApplicationFeedback: {
    path: 'feedback',
    Component: (props) => <AuthContextProvider ChildComponent={TenantApplicationFeedbackScreen} {...props} />,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
  noConsent: {
    path: 'no-consent',
    Component: (props) => <AuthContextProvider ChildComponent={GuarantorNoConsentScreen} {...props} />,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
}

export { routes }
