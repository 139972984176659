import React from 'react'

import { defaultsDeep } from '@guiker/lodash'
import { AuthenticatedApi } from '@guiker/payout-shared'
import { ApiForm, FullScreenSpinner, Step, StepProps, useTranslation } from '@guiker/react-framework'
import { yupResolver } from '@guiker/react-hook-form'

import { useAuthenticatedPayoutApiClient, useAuthenticationContext, usePayoutMethodNestedContext } from '../../hooks'
import { AccountHolderForm } from '../AccountHolderFormContent'

export type AccountHolderStepProps = StepProps

const AccountHolderStep: React.FC<AccountHolderStepProps> = ({ onClickNext, ...props }) => {
  const { t } = useTranslation('main-payout')
  const apiClient = useAuthenticatedPayoutApiClient()
  const { payoutMethod, updatePayoutMethod, isFetching } = usePayoutMethodNestedContext()
  const { user } = useAuthenticationContext()

  const buildDefaultAccountHolder = () => ({
    accountHolder: {
      accountHolderType: payoutMethod.accountHolderType,
      payoutMethodType: payoutMethod.type,
      emailAddress: user.emailAddress,
    },
  })

  const resolver = yupResolver(AuthenticatedApi.Schemas.updateAccountHolderSchema, {
    defaultValues: payoutMethod ? buildDefaultAccountHolder() : {},
  })

  const onSubmit = (payload: AuthenticatedApi.Schemas.UpdateAccountHolderSchema) =>
    apiClient.updatePayoutMethodAccountHolder({
      pathParams: { payoutMethodId: payoutMethod.id },
      payload: defaultsDeep(payload, payoutMethod ? buildDefaultAccountHolder() : {}),
    })

  if (isFetching || !payoutMethod) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      onSubmit={onSubmit}
      formName={'PayoutAccountHolder'}
      formOptions={{ resolver, defaultValues: payoutMethod }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { isSubmitting, errors } }) => (
        <Step
          {...props}
          hasBackButton={true}
          isSubmitting={isSubmitting}
          title={t('main-payout:createPayoutMethodScreen.accountHolderForm.subtitle')}
          errors={errors}
        >
          <AccountHolderForm defaultValue={payoutMethod} readOnly={false} />
        </Step>
      )}
    </ApiForm>
  )
}

export { AccountHolderStep }
