import React, { useState } from 'react'

import { getGradeColor } from '@guiker/background-check-components'
import { calculateReportItems, CreditReport } from '@guiker/background-check-shared'
import { AnyColor, lighten, makeStyles, theme } from '@guiker/components-core'
import { ChevronDownIcon, ChevronUpIcon } from '@guiker/icons'
import { currency as baseCurrency, CurrencyISO, money } from '@guiker/money'

import { Trans, useTranslation } from '../i18n'
import { Avatar, Box, Chip, Collapse, Divider, H4, Link, P, PBold, PSmall } from '.'
import { GradeSystem } from './GradeSystem'

type CreditReportCardProps = {
  creditReport: CreditReport
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  avatar: {
    color: ({ color }: { color: string }) => color,
    backgroundColor: ({ color }: { color: string }) => lighten(color, 0.7),
  },
  arrow: {
    marginLeft: theme.spacing(1),
    height: 12,
    width: 12,
  },
  link: {
    marginBottom: theme.spacing(1),
  },
})

const CreditReportCard: React.FC<CreditReportCardProps> = ({ creditReport }) => {
  const score = creditReport.creditScore

  const { grade, defaultChance } = calculateReportItems(score)
  const color = getGradeColor(grade)
  const classes = useStyles({ color })
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  const toggleDetails = () => setShowDetails((curr) => !curr)

  const toPrice = (value?: string) => {
    if (value) {
      const isPositiveNumber = value !== '' && value !== null && Math.sign(Number(value)) >= 0
      const valueNumber = isPositiveNumber ? Number(value) : 0
      return money.toAmount(valueNumber, baseCurrency[CurrencyISO.CAD]).toString(true)
    }
    return null
  }

  const getSummaryData = (label: string, hasIssue: boolean, keys?: { failed?: string; pass?: string }) => {
    const { failed = 'failed', pass = 'cleared' } = keys || {}
    const prefix = 'common:creditReport.financialSummary'

    return {
      label,
      color: hasIssue ? 'alert' : undefined,
      text: hasIssue ? t(`${prefix}.${failed}`) : t(`${prefix}.${pass}`),
    }
  }

  return (
    <Box>
      <H4 mb={4}>{t('creditReport.summary')}</H4>
      <Box className={classes.card}>
        <Avatar className={classes.avatar}>{grade}</Avatar>
        <Box ml={3}>
          <P>
            <Trans i18nKey='creditReport:score' values={{ score }}>
              <PBold component='span' />
            </Trans>
          </P>

          <P mb={0}>
            <Trans i18nKey='creditReport:defaultChance' values={{ defaultChance }}>
              <PBold component='span' />
            </Trans>
          </P>
        </Box>
      </Box>
      <Link onClick={toggleDetails} className={classes.link}>
        {t('creditReport.seeMoreDetails')}
        {showDetails ? <ChevronUpIcon className={classes.arrow} /> : <ChevronDownIcon className={classes.arrow} />}
      </Link>
      <Collapse in={showDetails}>
        <Box mb={2}>
          <GradeSystem />
        </Box>
      </Collapse>

      <H4 my={4}>{t('creditReport:financialSummary')}</H4>
      {[
        { ...getSummaryData('legalItems', creditReport['legalItems'].length > 0) },
        {
          label: 'totalDebt',
          color: undefined,
          text: toPrice(creditReport['totalDebt'] || '0'),
        },
        { ...getSummaryData('bankruptcies', creditReport['bankruptcies'].length > 0) },
        { ...getSummaryData('collections', creditReport['collections'].length > 0) },
        { ...getSummaryData('notableRisks', creditReport['redFlags'].length > 0, { pass: 'noRisks' }) },
        { ...getSummaryData('creditCheckErrors', creditReport['creditCheckErrors'].length > 0, { pass: 'noRecords' }) },
        {
          ...getSummaryData('creditCheckWarnings', creditReport['creditCheckWarnings'].length > 0, {
            pass: 'noRecords',
          }),
        },
      ].map(({ label, color, text }) => (
        <Box key={label} display='flex' justifyContent='space-between' alignItems='center' mt={2} mb={2}>
          <P m={0}>{t(`common:creditReport.financialSummary.${label}`)}</P>
          <Chip color={(color || 'success') as AnyColor}>{text}</Chip>
        </Box>
      ))}

      {creditReport['addresses'] && creditReport['addresses'].length > 0 && (
        <>
          <H4 my={4}>{t('creditReport:previousAddresses')}</H4>
          {creditReport['addresses'].map(({ address, civicNumber, streetName, city, province }, index) => {
            const items = [
              { label: 'unit', value: civicNumber },
              { label: 'street', value: streetName },
              { label: 'city', value: city },
              { label: 'province', value: province },
              { label: 'details', value: address },
            ]
            return (
              <Box key={index}>
                {index > 0 && <Divider />}
                <Box my={4}>
                  {items.map(({ label, value }) => (
                    <Box key={label}>
                      <PSmall mb={0}>{t(`common:creditReport.addresses.${label}`)}</PSmall>
                      <PBold>{value || '-'}</PBold>
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          })}
        </>
      )}

      {creditReport['employers'] && creditReport['employers'].length > 0 && (
        <>
          <H4 my={4}>{t('creditReport:employment')}</H4>
          {creditReport['employers'].map(({ position, company_name }, index) => {
            const items = [
              { label: 'occupation', value: position },
              { label: 'employer', value: company_name },
            ]
            return (
              <Box key={index}>
                {index > 0 && <Divider />}
                <Box my={4}>
                  {items.map(({ label, value }) => (
                    <Box key={label}>
                      <PSmall mb={0}>{t(`common:creditReport.employment.${label}`)}</PSmall>
                      <PBold>{value || '-'}</PBold>
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          })}
        </>
      )}

      <H4 my={4}>{t('creditReport:debtSummary')}</H4>
      <PBold my={4}>{t('creditReport:revolvingCredit')}</PBold>
      {[
        {
          label: 'totalBalance',
          value: toPrice(creditReport['otherDebtsTotalBalance']),
        },
        {
          label: 'maxLimit',
          value: toPrice(creditReport['otherDebtsMaxTotalLimit']),
        },
        {
          label: 'numberOfCreditsUsed',
          value: creditReport['otherDebtsPercentCreditUsed'],
        },
        {
          label: 'numberOfCards',
          value: creditReport['otherDebtsNumberOfDebts'],
        },
        {
          label: 'pastDueAmount',
          value: toPrice(creditReport['otherDebtsPastDueAmount']),
        },
        {
          label: 'latePayments',
          value: `${creditReport['otherDebtsLatePayments30'] || '-'} / ${
            creditReport['otherDebtsLatePayments60'] || '-'
          } / ${creditReport['otherDebtsLatePayments90'] || '-'}`,
        },
        {
          label: 'monthlyPayments',
          value: toPrice(creditReport['otherDebtsMonthlyPayments']),
        },
      ].map(({ label, value }, index) => (
        <Box key={index}>
          <PSmall mb={0}>{t(`common:creditReport.debt.${label}`)}</PSmall>
          <PBold>{value || '-'}</PBold>
        </Box>
      ))}

      {[
        {
          label: 'mortgageLoans',
          key: 'mortgageLoansTotalBalance',
          noRecord: 'noMortgage',
        },
        {
          label: 'studentLoans',
          key: 'studentLoansTotalBalance',
          noRecord: 'noStudentLoan',
        },
      ].map(({ label, key, noRecord }) => (
        <>
          <Divider />
          <PBold mt={2} mb={4}>
            {t(`creditReport:${label}`)}
          </PBold>
          <PSmall mb={0}>{t(`common:creditReport.debt.totalBalance`)}</PSmall>
          <PBold>{toPrice(creditReport[key]) || t(`creditReport:${noRecord}`)}</PBold>
        </>
      ))}
    </Box>
  )
}

export { CreditReportCard }
