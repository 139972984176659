import React from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import { useNavigate, yupResolver } from '@guiker/react-framework'
import { PreApplicationForm } from '@guiker/tenant-application-components'
import { AuthenticatedApi } from '@guiker/tenant-application-shared'

import { ApiForm, FormSection1, makeStyles, SplitView, theme } from '../../components'
import {
  useAuthenticatedTenantApplicationApiClient,
  useStaticAssetContext,
  useTenantApplicationContext,
  useTranslation,
} from '../../hooks'

const useStyle = makeStyles({
  image: {
    alignSelf: 'flex-start',
    objectFit: 'cover',
    minHeight: `max(100%,calc(100vh - ${theme.dimensions.appBar.height.desktop}px))`,
    height: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
    width: '100%',
  },
})

const PreApplicationScreen: React.FC = () => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const { getAsset } = useStaticAssetContext()
  const { tenantApplication } = useTenantApplicationContext()
  const assets = getAsset('tenant-application')
  const apiClient = useAuthenticatedTenantApplicationApiClient()
  const classes = useStyle()
  const navigate = useNavigate()

  const { profile, occupation, guarantor } = { ...tenantApplication }
  const defaultValues = {
    hasGuarantor: tenantApplication?.hasGuarantor ?? false,
    profile: {
      estimatedCreditScore: profile?.estimatedCreditScore,
    },
    occupation: {
      type: occupation?.type,
      title: occupation?.title,
      student: {
        annualSalary: occupation?.student?.annualSalary,
      },
      professional: {
        annualSalary: occupation?.professional?.annualSalary,
      },
      guarantor: {
        profile: guarantor?.profile
          ? {
              annualSalary: guarantor.profile.annualSalary,
              occupation: guarantor.profile.occupation,
              estimatedCreditScore: guarantor.profile.estimatedCreditScore,
            }
          : {},
      },
    },
  }

  const onSubmit = async (payload: AuthenticatedApi.Schema.UpdatePreApplicationPayloadValidator) =>
    apiClient.upsertPreApplication({ payload })

  const resolver = yupResolver(AuthenticatedApi.Schema.updatePreApplicationPayloadValidator, {
    defaultValues: { hasGuarantor: false },
  })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formName='preApplication'
      formOptions={{ resolver, defaultValues }}
      apiOptions={{ onSuccess: () => navigate(mainPathBuilder.root.messages.path()) }}
    >
      {({ isLoading }) => (
        <SplitView
          imageAlign='right'
          image={<img src={assets.image1} alt={'preApplication'} title={'preApplication'} className={classes.image} />}
        >
          <FormSection1
            title={t('main-tenantApplication:screens.preApplication.title')}
            subtitle={t('main-tenantApplication:screens.preApplication.description')}
          >
            <PreApplicationForm defaultValue={defaultValues} isLoading={isLoading} />
          </FormSection1>
        </SplitView>
      )}
    </ApiForm>
  )
}

export { PreApplicationScreen }
