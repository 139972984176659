import React from 'react'

import { isApplicant, isUnitManager } from '@guiker/booking-shared'
import { PageNotFound } from '@guiker/error-pages'
import { Route, Routes } from '@guiker/router'

import { useAuthenticationContext, useBookingContext } from '../hooks'
import { routes } from './routes'

const Router: React.FC = (props) => {
  const { booking } = useBookingContext()
  const { user } = useAuthenticationContext()
  return (
    <Routes>
      {user && (
        <Route {...routes.bookingApplicantNested} {...props}>
          {isApplicant({ booking, userId: user.id }) && (
            <Route {...routes.bookingApplicantNested.routes.viewBookingApplicationScreen} {...props} />
          )}
          {isApplicant({ booking, userId: user.id }) && (
            <Route {...routes.bookingApplicantNested.routes.editBookingApplicationScreen} {...props} />
          )}
          {isUnitManager({ booking, userId: user.id }) && (
            <Route {...routes.bookingApplicantNested.routes.unitManagerViewBookingApplicationScreen} {...props} />
          )}
          {isUnitManager({ booking, userId: user.id }) && (
            <Route
              {...routes.bookingApplicantNested.routes.unitManagerViewBookingApplicationRoommateIntroScreen}
              {...props}
            />
          )}
          {isUnitManager({ booking, userId: user.id }) && (
            <Route {...routes.bookingApplicantNested.routes.listBookingApplicationsScreen} {...props} />
          )}
        </Route>
      )}
      <Route path='*' Component={PageNotFound} />
    </Routes>
  )
}

export { Router }
