import React from 'react'

import { useConfig } from '@guiker/config-context'

import { Box, Flex, IconTooltip, Link, makeStyles, SecondaryButton, theme } from '../../components'
import { useTranslation } from '../../i18n'

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
})

export const PageHeader: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation(['main-bookingDocument'])
  const {
    docusign: { templateUrl },
  } = useConfig()
  const tPrefix = 'screens.newBookingDocumentsScreen'

  return (
    <Box className={classes.headerContainer}>
      <Flex>
        <Link color='textPrimary' isExternalLink to={templateUrl} underline={false} hoverUnderline={false}>
          <SecondaryButton size='small'>{t(`${tPrefix}.cta`)}</SecondaryButton>
        </Link>

        <Box ml={2}>
          <IconTooltip title={t(`${tPrefix}.tooltip`)} />
        </Box>
      </Flex>
    </Box>
  )
}
