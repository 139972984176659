import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'

import { AllLegacyInvoicesTable, FormSection1 } from '../../components'
import { useT } from '../../i18n'

const RentalPaymentHistoryScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.rentalPaymentHistory' })

  return (
    <AccountPageLayout
      breadcrumbItems={[
        {
          to: '../',
          label: tMain('payments'),
        },
        {
          to: '/',
          label: tMain('rentalPaymentHistory'),
        },
      ]}
    >
      <FormSection1 hasDivider={false} title={tMain('title')}>
        <AllLegacyInvoicesTable />
      </FormSection1>
    </AccountPageLayout>
  )
}

export { RentalPaymentHistoryScreen }
