import React from 'react'

import { JwtBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { CountryCode } from '@guiker/base-entity'
import { PageNotFound } from '@guiker/error-pages'
import { useWatch, yupResolver } from '@guiker/react-hook-form'
import {
  BackgroundCheckConsentFormContent,
  GuarantorAddress,
  GuarantorProfile,
  GuarantorSupportingDocument,
} from '@guiker/tenant-application-components'
import { useJwtTenantApplicationApiClient } from '@guiker/tenant-application-context'
import { documentTypes, JwtAuthenticatedApi, SupportingDocument } from '@guiker/tenant-application-shared'

import {
  ApiForm,
  Box,
  ButtonWithLoader,
  Divider,
  FullScreenSpinner,
  H4,
  IconTooltip,
  P,
  PageLayout,
  SsnTextField,
  TakeoverContextProvider,
} from '../../components'
import { useMutation, useNavigate, useTenantApplicationContext } from '../../hooks'
import { useTranslation } from '../../i18n'

const FormContent: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation(['main-tenantApplication', 'common'])
  const { tenantApplication } = useTenantApplicationContext()
  const { assetGroups } = useBulkAssetUploaderContext()

  const { address, backgroundCheck } = { ...tenantApplication.guarantor }
  const country = useWatch({ name: 'guarantor.address.country', defaultValue: address?.country })

  return (
    <Box px={2} py={4}>
      <Box display='flex' flexDirection='row' alignItems='center' mb={2}>
        <H4 mb={0} mr={2}>
          {t('main-tenantApplication:screens.steps.guarantor.title')}
        </H4>
        <IconTooltip title={t('main-tenantApplication:screens.steps.guarantor.tooltip')} />
      </Box>
      <P color={60} mb={3}>
        {t('main-tenantApplication:screens.steps.guarantor.description')}
      </P>
      <Box mb={2}>
        <GuarantorProfile
          schema={JwtAuthenticatedApi.Schema.updateInvitedGuarantorPayloadValidator}
          defaultValue={tenantApplication}
          formPrefix='guarantor.profile'
        />
      </Box>
      <Box mb={2}>
        <GuarantorAddress
          schema={JwtAuthenticatedApi.Schema.updateInvitedGuarantorPayloadValidator}
          defaultValue={tenantApplication?.guarantor?.address}
          formPrefix='guarantor.address'
        />
      </Box>
      <Box display='flex' flexDirection={'column'} mt={1}>
        {documentTypes.map((documentType, index) => (
          <React.Fragment key={index}>
            <Box mb={2} px={2} width='100%'>
              <GuarantorSupportingDocument
                defaultValue={(assetGroups as SupportingDocument[]).find((doc) => doc?.type === documentType)}
                documentType={documentType}
                name={`guarantor.supportingDocuments[${index}]`}
                readOnly={false}
              />
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <Divider />
      <Box mt={3} mb={4}>
        <H4>{t(`main-tenantApplication:screens.steps.backgroundCheck.title`)}</H4>
        <BackgroundCheckConsentFormContent
          defaultValue={tenantApplication?.guarantor?.backgroundCheck?.consent}
          formPrefix='guarantor.backgroundCheck.consent'
          consentFor='guarantor'
        />
        {country === CountryCode.US && (
          <Box mt={2}>
            <SsnTextField defaultValue={backgroundCheck?.ssn} name='guarantor.backgroundCheck.ssn' />
          </Box>
        )}
      </Box>

      <Box width='100%' display='flex' flexDirection='row-reverse'>
        <ButtonWithLoader type='submit' isLoading={isLoading}>
          {t('common:actions.submit')}
        </ButtonWithLoader>
      </Box>
    </Box>
  )
}

const Content: React.FC = () => {
  const jwtTenantApplicationApiClient = useJwtTenantApplicationApiClient()
  const navigate = useNavigate()

  const { isLoading: isFetching, tenantApplication } = useTenantApplicationContext()
  const { bulkUpdate } = useBulkAssetUploaderContext()

  const resolver = yupResolver(JwtAuthenticatedApi.Schema.updateInvitedGuarantorPayloadValidator)

  const { mutate: handleSubmit } = useMutation(
    async (payload: JwtAuthenticatedApi.Schema.UpdateInvitedGuarantorPayloadValidator) => {
      return await jwtTenantApplicationApiClient.updateInvitedGuarantor({
        pathParams: { tenantApplicationId: tenantApplication.id },
        payload,
      })
    },
    {
      onSuccess: (res) => {
        res.guarantor?.backgroundCheck?.consent?.isConsentGiven ? navigate('../feedback') : navigate('../no-consent')
      },
    },
  )

  if (!tenantApplication && !isFetching) {
    return <PageNotFound />
  }

  return (
    <ApiForm
      formName='TenantApplicationGuarantorView'
      formOptions={{ defaultValues: tenantApplication, resolver }}
      onSubmit={(payload) => {
        handleSubmit(payload)
      }}
      beforeValidation={() => bulkUpdate()}
    >
      {({ isLoading }) => <FormContent isLoading={isLoading} />}
    </ApiForm>
  )
}

export const TenantApplicationGuarantorView: React.FC = () => {
  const { isLoading, tenantApplication } = useTenantApplicationContext()
  const navigate = useNavigate()

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <TakeoverContextProvider hasBorder onClose={() => navigate('/')}>
      <PageLayout maxWidth={900 as const}>
        <JwtBulkAssetUploaderContextProvider
          assetGroups={tenantApplication?.guarantor?.supportingDocuments?.map((group, groupIndex) => ({
            ...group,
            assets:
              group.assets?.map((asset, index) => ({
                ...asset,
                name: `guarantor.supportingDocuments[${groupIndex}].assets[${index}]`,
              })) || [],
          }))}
          scope={{
            type: 'TenantApplication',
            id: tenantApplication?.id,
          }}
        >
          <Content />
        </JwtBulkAssetUploaderContextProvider>
      </PageLayout>
    </TakeoverContextProvider>
  )
}
