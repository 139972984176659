import React from 'react'

import { Dropdown, Flex, getLocalizedCityFromLocation, PSmall, PSmaller, useWatch } from '@guiker/react-framework'
import { getLocationService } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type CityDropdownProps = {
  readOnly?: boolean
  maxWidth?: number | `${number}%`
  supportedCities?: string[]
}

export const getCityDropdownLabel = (key: string) => {
  const { t, tMain } = useT({ screenName: 'components.city' })
  const locationService = getLocationService()

  return key === 'any' ? tMain('options.any') : getLocalizedCityFromLocation(t, locationService.getCityBySlug(key))
}

export const CityDropdown: React.FC<CityDropdownProps> = ({ readOnly, supportedCities, maxWidth = 240 }) => {
  const { tMain } = useT({ screenName: 'components.city' })
  const { city } = useWatch()

  const options = supportedCities.map((key) => ({
    label: getCityDropdownLabel(key),
    value: key,
  }))

  if (readOnly) {
    return (
      <Flex flexDirection='column'>
        <PSmaller mb={0}>{tMain('label')}</PSmaller>
        <PSmall mb={0}>{getCityDropdownLabel(city)}</PSmall>
      </Flex>
    )
  }

  return (
    <Dropdown
      maxWidth={maxWidth}
      name='city'
      label={tMain('label')}
      options={options}
      defaultValue={'any'}
      width={'100%'}
    />
  )
}
