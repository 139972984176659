import React from 'react'

import {
  Flex,
  Link,
  PSmall,
  SynchronizeArrowsMiniIcon,
  theme,
  useMediaQuery,
  useModal,
  useT,
} from '@guiker/react-framework'
import { useJwtBookingRentPaymentsPlanScopeNestedContext } from '@guiker/rent-payment-context'
import { RentPaymentsPlanStatus } from '@guiker/rent-payment-shared'

import { ChangePaymentMethodModal } from './ChangePaymentMethodModal'

export const ChangePaymentMethodLink: React.FC<{ hasPayInMethod: boolean }> = ({ hasPayInMethod }) => {
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'screens.rentPayment' })
  const { data: rentPaymentsPlan } = useJwtBookingRentPaymentsPlanScopeNestedContext()
  const { openModal, closeModal, isOpen } = useModal()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (rentPaymentsPlan.status !== RentPaymentsPlanStatus.ACTIVE) {
    return <></>
  }

  return (
    <Flex fullWidth justifyContent={isMobile ? 'flex-end' : 'flex-start'}>
      <Link color={50} onClick={() => openModal()} underline>
        <Flex alignItems='center' gap={1}>
          <SynchronizeArrowsMiniIcon />
          <PSmall mb={0}>{tMain(hasPayInMethod ? 'changePaymentMethod' : 'addPaymentMethod')}</PSmall>
        </Flex>
      </Link>
      <ChangePaymentMethodModal isOpen={isOpen} onClose={closeModal} />
    </Flex>
  )
}
