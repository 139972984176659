import React from 'react'

import { Route, Routes } from '@guiker/router'

import { useLeaseContext } from '../hooks'
import { getLeaseScreens } from '../screens'
import { routes } from '.'

const GuarantorRouter: React.FC = (props) => {
  const { lease } = useLeaseContext()

  const { GuarantorSignLease } = getLeaseScreens(lease.type === 'canada-quebec' ? 'QC' : 'ON')

  return (
    <Routes>
      <Route {...routes.signLeaseFromTokenLink} Component={GuarantorSignLease} {...props} />
    </Routes>
  )
}

export { GuarantorRouter }
