import React from 'react'

import { ColumnGridItem, FormSection2, Note, OneColumnGridLayout } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'
import { LeaseActions } from '@guiker/lease-shared'

import { useFormContext, useTranslation, useWatch } from '../../../hooks'
import { useLeaseContext } from '../../../hooks'
import { LeaseTermTypes } from '../../../types'
import { Collapse, DatePicker as DatePickerComponent, RadioGroup, Typography } from '../../'
import { HeaderSecondaryDescriptionBar } from '../header'
import { LeaseTermFixed } from './LeaseTermFixed'

type Props = {
  leaseTerm: Quebec.LeaseTerm
}

const DatePicker = (props) => <DatePickerComponent {...props} />

export const FormContent: React.FC<Props> = ({ leaseTerm }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenC'])
  const {
    formState: { errors },
  } = useFormContext()
  const { can } = useLeaseContext()
  const name = 'leaseTerm'
  const typeName = `${name}.type`
  const type = useWatch({ name: typeName, defaultValue: leaseTerm.type })

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection2
      title={<HeaderSecondaryDescriptionBar index='C.' description={t('quebecLeaseScreenC:headerLabel')} />}
    >
      <div>
        <RadioGroup
          defaultValue={type}
          value={LeaseTermTypes.Fixed}
          readOnly={!canDraftLease}
          disabled={canDraftLease}
          name={typeName}
          options={[
            { value: LeaseTermTypes.Fixed, label: t('quebecLeaseScreenC:fixedTermLease') },
            { value: LeaseTermTypes.Indeterminate, label: t('quebecLeaseScreenC:indeterminateTermLease') },
          ]}
        />
        <Collapse in={type === LeaseTermTypes.Fixed}>
          <Note>
            <LeaseTermFixed defaultValue={leaseTerm} name={name} />
          </Note>
        </Collapse>
        <Collapse in={type === LeaseTermTypes.Indeterminate}>
          <Note>
            <OneColumnGridLayout>
              <ColumnGridItem md={2}>
                <Typography>{t('quebecLeaseScreenC:indeterminateLeaseStart')}</Typography>
              </ColumnGridItem>
              <DatePicker
                readOnly={!canDraftLease}
                disabled={canDraftLease}
                name={`${name}.startDate`}
                error={!!errors.startDate}
                label={t('common:from')}
              />
              <ColumnGridItem md={2}>
                <Typography>{t('quebecLeaseScreenC:termText')}</Typography>
              </ColumnGridItem>
            </OneColumnGridLayout>
          </Note>
        </Collapse>
      </div>
    </FormSection2>
  )
}
