import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { TabLayout, useLocation } from '@guiker/react-framework'

import { InvestmentDashboard, PageLayout, RentalDashboard } from '../../components'
import { TaskDashboard } from '../../components'
import { RentCollectionContextProvider } from '../../context'
import { useSideMenuItems } from '../../hooks'
import { useT } from '../../i18n'

export const Dashboard: React.FC = () => {
  const { tMain } = useT()
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)
  const location = useLocation()

  const findInPath = (keyword: string) => {
    const regex = new RegExp(`${keyword}`, 'gi')
    return regex.test(location.pathname)
  }

  const indexFromLoc = findInPath('todos') ? 2 : findInPath('rental-dashboard') ? 1 : 0

  return (
    <PageLayout sideMenuProps={{ items: sideMenuNavItems }}>
      <TabLayout
        gap={4}
        defaultIndex={indexFromLoc}
        tabs={[
          {
            label: tMain('screens.dashboard.investmentDashboard.title'),
            path: { replace: true, to: '/investment-dashboard' },
            content: <InvestmentDashboard />,
          },
          {
            label: tMain('screens.dashboard.rentalDashboard.title'),
            path: { replace: true, to: '/rental-dashboard' },
            content: (
              <RentCollectionContextProvider>
                <RentalDashboard />
              </RentCollectionContextProvider>
            ),
          },
          {
            label: tMain('screens.dashboard.taskDashboard.title'),
            path: { replace: true, to: '/todos' },
            content: <TaskDashboard />,
          },
        ]}
      />
    </PageLayout>
  )
}
