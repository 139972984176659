import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { FormSection2, HeaderDescriptionBar } from '../../common'
import { KeyDeposit } from './KeyDeposit'
import { RentDeposit } from './RentDeposit'
import { RentDiscounts } from './RentDiscounts'

type Props = {
  rentDiscounts: Types.RentDiscounts
  rentDeposit: Types.RentDeposit
  keyDeposit: Types.KeyDeposit
}

export const FormContent: React.FC<Props> = ({ rentDiscounts, rentDeposit, keyDeposit }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <>
      <FormSection2 title={<HeaderDescriptionBar index='7.' description={t('ontarioLeaseScreenE:section7Header')} />}>
        <RentDiscounts
          defaultValue={rentDiscounts}
          name='rentDiscounts'
          readOnly={!canDraftLease}
          disabled={!canDraftLease}
          t={t}
        />
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='8.' description={t('ontarioLeaseScreenE:section8Header')} />}>
        <RentDeposit defaultValue={rentDeposit} name='rentDeposit' readOnly={!canDraftLease} t={t} />
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='9.' description={t('ontarioLeaseScreenE:section9Header')} />}>
        <KeyDeposit defaultValue={keyDeposit} name='keyDeposit' readOnly={!canDraftLease} t={t} />
      </FormSection2>
    </>
  )
}
