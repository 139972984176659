import React from 'react'

import { CurrencyISO, money } from '@guiker/money'

import { Flex, PBold, PSmall } from '..'

type CurrencyHighlightBodyProps = {
  currency: CurrencyISO
  amount: number
}

const CurrencyHighlightBody: React.FC<CurrencyHighlightBodyProps> = ({ currency, amount }) => {
  return (
    <Flex gap={1} alignItems='center'>
      <PBold mb={0}>{money.fromAmount(amount || 0, currency).toString('onlySymbol')}</PBold>
      <PSmall mb={0} color={30}>
        {currency}
      </PSmall>
    </Flex>
  )
}

export { CurrencyHighlightBody }
