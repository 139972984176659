import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { H4, Note, P, PBold, TextField } from '../../'
import { FormSection2, FormSection3, HeaderDescriptionBar, OneColumnGridLayout } from '../../common'
import { LandlordTenantRadio } from './LandlordTenantRadio'
import { YesNoRadioGroup } from './YesNoRadioGroup'

type Props = {
  servicesAndUtilities: Types.ServicesAndUtilities
}

export const FormContent: React.FC<Props> = ({ servicesAndUtilities }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection2 title={<HeaderDescriptionBar index='6.' description={t('ontarioLeaseScreenD:section6Header')} />}>
      <FormSection3>
        <P>{t('ontarioLeaseScreenD:followingServicesIncluded')}</P>
        <OneColumnGridLayout>
          <YesNoRadioGroup
            name={'gas'}
            defaultValue={servicesAndUtilities?.gas}
            label={<PBold>{t('ontarioLeaseScreenD:gas')}</PBold>}
            readOnly={!canDraftLease}
          />
          <YesNoRadioGroup
            name={'airConditioning'}
            defaultValue={servicesAndUtilities?.airConditioning}
            label={<PBold>{t('ontarioLeaseScreenD:airConditioning')}</PBold>}
            readOnly={!canDraftLease}
          />
          <YesNoRadioGroup
            name={'onSiteLaundry'}
            defaultValue={servicesAndUtilities?.onSiteLaundry}
            label={<PBold>{t('ontarioLeaseScreenD:onSiteLaundry')}</PBold>}
            readOnly={!canDraftLease}
            withCharge
          />
          <YesNoRadioGroup
            name={'guestParking'}
            defaultValue={servicesAndUtilities?.guestParking}
            label={<PBold>{t('ontarioLeaseScreenD:guestParking')}</PBold>}
            readOnly={!canDraftLease}
            withCharge
          />
        </OneColumnGridLayout>
      </FormSection3>

      <H4>{t('ontarioLeaseScreenD:otherServicesAndUtilitiesLabel')}</H4>
      <TextField
        defaultValue={servicesAndUtilities?.otherServicesAndUtilities}
        name={'otherServicesAndUtilities'}
        label={t('ontarioLeaseScreenD:otherServicesAndUtilities')}
        readOnly={!canDraftLease}
        maxWidth={420}
      />
      <FormSection3>
        <P>{t('ontarioLeaseScreenD:theFollowingUtilities')}</P>
        <OneColumnGridLayout>
          <LandlordTenantRadio
            defaultValue={servicesAndUtilities?.electricity}
            name={'electricity'}
            label={t('ontarioLeaseScreenD:electricity')}
            readOnly={!canDraftLease}
          />
          <LandlordTenantRadio
            defaultValue={servicesAndUtilities?.heat}
            name={'heat'}
            label={t('ontarioLeaseScreenD:heat')}
            readOnly={!canDraftLease}
          />
          <LandlordTenantRadio
            defaultValue={servicesAndUtilities?.water}
            name={'water'}
            label={t('ontarioLeaseScreenD:water')}
            readOnly={!canDraftLease}
          />
        </OneColumnGridLayout>
      </FormSection3>
      <FormSection3>
        <P>{t('ontarioLeaseScreenD:tenantIsResponsible')}</P>
        <TextField
          defaultValue={servicesAndUtilities?.lesseeResponsibilityDetails}
          name={'lesseeResponsibilityDetails'}
          label={t('ontarioLeaseScreenD:details')}
          readOnly={!canDraftLease}
          maxWidth={420}
        />
        <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
          {t('ontarioLeaseScreenD:tenantIsResponsibleNote')}
        </Note>
      </FormSection3>
    </FormSection2>
  )
}
