import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import {
  DatePicker,
  Divider,
  FormSection2,
  HeaderDescriptionBar,
  Note,
  P,
  TextField,
  TwoColumnsGridLayout,
} from '../../'
import { FirstRentalPeriod } from './FirstRentalPeriod'
import { OtherServicesAndUtils } from './OtherServicesAndUtils'
import { RentToBePaid } from './RentToBePaid'
import { TenancyAgreement } from './TenancyAgreement'
import { TenantWillPay } from './TenantWillPay'

type Props = {
  leaseTerm: Types.LeaseTerm
  rent: Types.Rent
}

export const FormContent: React.FC<Props> = ({ leaseTerm, rent }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)
  const allowProps = { readOnly: !canDraftLease, disabled: !canDraftLease }

  return (
    <>
      <FormSection2 title={<HeaderDescriptionBar index='4.' description={t('ontarioLeaseScreenC:section4Header')} />}>
        <TwoColumnsGridLayout>
          <DatePicker
            name={'leaseTerm.startDate'}
            defaultValue={leaseTerm?.startDate}
            readOnly={!canDraftLease}
            label={t('ontarioLeaseScreenC:tenancyStartsOn')}
            maxWidth={420}
          />
        </TwoColumnsGridLayout>
        <Divider />
        <TenancyAgreement defaultValue={leaseTerm} name='leaseTerm' readOnly={!canDraftLease} />
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='5.' description={t('ontarioLeaseScreenC:section5Header')} />}>
        <RentToBePaid defaultValue={rent} name='rent' t={t} {...allowProps} />
        <Divider />

        <TenantWillPay defaultValue={rent} name='rent' t={t} {...allowProps} />
        <P>{t('ontarioLeaseScreenC:otherServices')}</P>
        <OtherServicesAndUtils name={`rent.rentOtherAmounts`} {...allowProps} />
        <P>{t('ontarioLeaseScreenC:lawfulRent')}</P>
        <Divider />

        <P>{t('ontarioLeaseScreenC:rentPayableTo')}</P>
        <TextField
          defaultValue={rent?.rentPayableTo}
          name={'rent.rentPayableTo'}
          label={t('ontarioLeaseScreenC:rentReceiver')}
          readOnly={!canDraftLease}
          maxWidth={420}
        />
        <Divider />

        <P>{t('ontarioLeaseScreenC:paymentMethodLabel')}</P>
        <TextField
          defaultValue={rent?.paymentMethod}
          name={'rent.paymentMethod'}
          label={t('ontarioLeaseScreenC:paymentMethod')}
          readOnly={!canDraftLease}
          maxWidth={420}
        />
        <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
          {t('ontarioLeaseScreenC:paymentMethodNote')}
        </Note>
        <Divider />

        <P>{t('ontarioLeaseScreenC:firstRentalPeriod')}</P>
        <FirstRentalPeriod defaultValue={rent} name='rent' readOnly={!canDraftLease} t={t} />
        <Divider />

        <P>{t('ontarioLeaseScreenC:nsfChargeAmountLabel')}</P>
        <TextField
          defaultValue={rent?.nsfChargeAmount}
          name={'rent.nsfChargeAmount'}
          label='$'
          maxWidth={420}
          {...allowProps}
        />
        <P>{t('ontarioLeaseScreenC:plusCharges')}</P>
        <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
          {t('ontarioLeaseScreenC:nsfChargeAmountNote')}
        </Note>
      </FormSection2>
    </>
  )
}
