import React from 'react'

import { deprecatedRouterBuilder, RedirectNoIndex } from '@guiker/router'

import { DocumentsScreen, NotificationPreferencesScreen, ProfileScreen, SecurityScreen } from '../screens'

const WalletComponent = React.lazy(() => import('../components/Wallet'))

export const { routes, Router } = deprecatedRouterBuilder({
  root: {
    path: '/',
    Component: () => <RedirectNoIndex path={`./profile`} />,
  },
  documents: {
    path: '/documents/*',
    Component: DocumentsScreen,
  },
  wallet: {
    path: '/wallet/*',
    Component: WalletComponent as React.FC,
  },
  notifications: {
    path: '/notification-preferences/*',
    Component: NotificationPreferencesScreen,
  },
  profile: {
    path: '/profile/*',
    Component: ProfileScreen,
  },
  security: {
    path: '/security/*',
    Component: SecurityScreen,
  },
})
