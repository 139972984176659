import React from 'react'

import { AuthenticatedApi } from '@guiker/payout-shared'
import { ApiForm, Step, StepProps, useTranslation, yupResolver } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { SelectAccountHolderTypeFormContent } from '../SelectAccountHolderTypeFormContent'

export type SelectAccountHolderTypeStepProps = StepProps

const SelectAccountHolderTypeStep: React.FC<SelectAccountHolderTypeStepProps> = ({ onClickNext, ...props }) => {
  const apiClient = useAuthenticatedPayoutApiClient()
  const { payoutMethod, updatePayoutMethod } = usePayoutMethodNestedContext()
  const { t } = useTranslation(['main-payout'])

  const resolver = yupResolver(AuthenticatedApi.Schemas.updateAccountHolderTypeSchema)
  const onSubmit = (payload: AuthenticatedApi.Schemas.UpdateAccountHolderTypeSchema) =>
    apiClient.updatePayoutMethodAccountHolderType({ pathParams: { payoutMethodId: payoutMethod.id }, payload })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formName={'PayoutSelectAccountHolderType'}
      formOptions={{
        resolver,
        defaultValues: payoutMethod,
      }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext()
        },
        onSubmitWithoutChange: () => onClickNext(),
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step
          {...props}
          hasBackButton={true}
          isSubmitting={isSubmitting}
          title={t('main-payout:payoutMethodScreen.accountHolderTypeForm.subtitle')}
        >
          {payoutMethod && <SelectAccountHolderTypeFormContent defaultValue={payoutMethod} readOnly={false} />}
        </Step>
      )}
    </ApiForm>
  )
}

export { SelectAccountHolderTypeStep }
