import { useContext } from 'react'

import { LeaseContext } from '../context'

export const useLeaseContext = () => {
  const context = useContext(LeaseContext)

  if (context === undefined) {
    throw new Error('useLeaseContext can only be used inside useLeaseContext')
  }

  return context
}
