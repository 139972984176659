import React from 'react'

import { TFunction } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { CurrencyTextField, DatePicker as DatePickerComponent, P } from '../../'
import { TwoColumnsGridLayout } from '../../common'

type Props = {
  defaultValue: Types.Rent
  name: string
  readOnly?: boolean
  disabled?: boolean
  t: TFunction
}

const DatePicker = (props) => <DatePickerComponent {...props} />

export const FirstRentalPeriod: React.FC<Props> = ({ defaultValue, disabled, name, readOnly, t }) => (
  <>
    <TwoColumnsGridLayout>
      <CurrencyTextField
        defaultValue={defaultValue?.partialPaymentAmount}
        name={`${name}.partialPaymentAmount`}
        label={t('ontarioLeaseScreenC:firstPaymentAmount')}
        readOnly={readOnly}
        maxWidth={420}
      />
      <DatePicker
        defaultValue={defaultValue?.firstPaymentDate}
        name={`${name}.firstPaymentDate`}
        label={t('common:date')}
        disabled={disabled}
        readOnly={readOnly}
        maxWidth={420}
      />
    </TwoColumnsGridLayout>
    <P>{t('ontarioLeaseScreenC:partialRent')}</P>
    <TwoColumnsGridLayout>
      <DatePicker
        defaultValue={defaultValue?.partialRentCoversFromDate}
        name={`${name}.partialRentCoversFromDate`}
        label={t('common:from')}
        disabled={disabled}
        readOnly={readOnly}
        maxWidth={420}
      />
      <DatePicker
        defaultValue={defaultValue?.partialRentCoversToDate}
        name={`${name}.partialRentCoversToDate`}
        label={t('ontarioLeaseScreenC:to')}
        disabled={disabled}
        readOnly={readOnly}
        maxWidth={420}
      />
    </TwoColumnsGridLayout>
  </>
)
