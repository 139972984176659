import React from 'react'

import { ColumnGridItem, Note, OneColumnGridLayout } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { Collapse, RadioGroup, TextField, Typography } from '../../'

type Props = {
  defaultValue: Quebec.NewLesseeNotice
  readOnly: boolean
  disabled?: boolean
  name: 'newLesseeNotice'
}

const Conditions: React.FC<Props> = ({ defaultValue, name, readOnly, disabled }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenG'])
  const sameCondition = useWatch<Quebec.LeaseInformation, `${typeof name}.sameCondition`>({
    name: `${name}.sameCondition`,
    defaultValue: defaultValue?.sameCondition,
  })

  return (
    <div>
      <RadioGroup
        defaultValue={sameCondition}
        label={t('quebecLeaseScreenG:isPropertyOfferByLessorAndLeaseConditionSameLabel')}
        readOnly={readOnly}
        disabled={disabled}
        name={`${name}.sameCondition`}
        options={[
          { value: true, label: t('common:yes') },
          { value: false, label: t('common:no') },
        ]}
      />
      <Collapse in={sameCondition === false} unmountOnExit>
        <Note>
          <OneColumnGridLayout>
            <ColumnGridItem md={2}>
              <Typography mb={0}>{t('quebecLeaseScreenG:differentConditions')}</Typography>
            </ColumnGridItem>
            <TextField
              defaultValue={defaultValue?.differentConditions}
              readOnly={readOnly}
              disabled={disabled}
              label={t('common:specify')}
              multiline
              name={`${name}.differentConditions`}
            />
          </OneColumnGridLayout>
        </Note>
      </Collapse>
    </div>
  )
}

export { Conditions }
