import React from 'react'

import { Box } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { OntarioSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { ApiForm, ButtonWithLoader } from '../../../components'
import { FormContent as FormContentA } from '../../../components/ontario-lease/step-a/FormContent'
import { FormContent as FormContentB } from '../../../components/ontario-lease/step-b/FormContent'
import { FormContent as FormContentC } from '../../../components/ontario-lease/step-c/FormContent'
import { FormContent as FormContentD } from '../../../components/ontario-lease/step-d/FormContent'
import { FormContent as FormContentE } from '../../../components/ontario-lease/step-e/FormContent'
import { FormContent as FormContentF } from '../../../components/ontario-lease/step-f/FormContent'
import { FormContent as FormContentG } from '../../../components/ontario-lease/step-g/FormContent'
import { FormContent as FormContentH } from '../../../components/ontario-lease/step-h/FormContent'
import { FormContent as FormContentI } from '../../../components/ontario-lease/step-i/FormContent'
import { useLeaseJwtAuthenticatedApiClient, useOntarioLeaseContext } from '../../../hooks'
import { Ontario } from '../../../types'

type Props = {
  id: string
  data: Ontario.LeaseInformation
  setHasParticipantSigned: (hasParticipantSigned: boolean) => void
}

const InvitedLessorSignLeaseForm: React.FC<Props> = ({ data, id, setHasParticipantSigned }) => {
  const jwtApiClient = useLeaseJwtAuthenticatedApiClient()
  const { t } = useTranslation()
  const { setLease } = useOntarioLeaseContext()
  const lessorData = data.lessors[0]

  const onSubmit = (payload: any) =>
    jwtApiClient.updateOntarioLeaseInvitedLessorSignature({
      pathParams: { leaseId: id },
      payload: OntarioSchemas.invitedLessorSignature.cast(payload, { stripUnknown: true }),
    })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.invitedLessorSignature),
        defaultValues: {
          signature: lessorData.signature,
        },
      }}
      apiOptions={{
        onSuccess: (lease) => {
          setLease(lease)
          setHasParticipantSigned(true)
        },
      }}
      formName={'OntarioGuarantorSignLeaseForm'}
    >
      {({ isLoading }) => (
        <Box px={4} pb={4}>
          <FormContentA lessors={data.lessors} lessees={data.lessees} rentalUnit={data.rentalUnit} />
          <FormContentB contactInformation={data.contactInformation} />
          <FormContentC leaseTerm={data.leaseTerm} rent={data.rent} />
          <FormContentD servicesAndUtilities={data.servicesAndUtilities} />
          <FormContentE
            rentDiscounts={data.rentDiscounts}
            rentDeposit={data.rentDeposit}
            keyDeposit={data.keyDeposit}
          />
          <FormContentF smoking={data.smoking} tenantsInsurance={data.tenantsInsurance} />
          <FormContentG />
          <FormContentH additionalTerms={data.additionalTerms} />
          <FormContentI lessors={data.lessors} lessees={data.lessees} />
          <ButtonWithLoader isLoading={isLoading}>{t('common:confirmAndSign')}</ButtonWithLoader>
        </Box>
      )}
    </ApiForm>
  )
}

export { InvitedLessorSignLeaseForm }
