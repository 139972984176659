import React from 'react'

import { Flex, P, PageLayout } from '../../components'
import { useBookingApiClient, useBookingContext, useQuery } from '../../hooks'
import { useTranslation } from '../../i18n'
import { PageHeader } from './PageHeader'
import { TemplateListForm } from './TemplateListForm'

const tPrefix = 'main-bookingDocument:screens.newBookingDocumentsScreen'

export const NewBookingDocumentScreen: React.FC = () => {
  const { t } = useTranslation(['main-bookingDocument', 'common'])
  const apiClient = useBookingApiClient()
  const { bookingId, bookingChat } = useBookingContext()

  const title = t(`${tPrefix}.title`)

  const { data: templates, isLoading } = useQuery(
    [`${bookingId}-read-all-booking-document-templates`],
    () => apiClient.readAllBookingDocumentTemplates({ pathParams: { bookingId } }),
    {
      enabled: !!bookingId,
      retry: 1,
    },
  )

  return (
    <PageLayout
      title={title}
      takeoverProps={{ onClose: bookingChat.navigate }}
      isLoading={isLoading}
      forceTakeover
      hasButtonContainer
    >
      <Flex fullWidth flexDirection='column' height='100%' gap={2}>
        <PageHeader />
        {templates?.length ? (
          <TemplateListForm templates={templates} bookingId={bookingId} tPrefix={tPrefix} />
        ) : (
          <P>{t(`${tPrefix}.emptyDescription`)}</P>
        )}
      </Flex>
    </PageLayout>
  )
}
