import React from 'react'

import { Inquiry } from '@guiker/investment-assumption-components'
import { FormSection3, useTranslation } from '@guiker/react-framework'

export const FinancialDetailsTab: React.FC = () => {
  const { t } = useTranslation(['main-propsharing'])
  const tPrefix = 'main-propsharing:screens.listingScreen'

  return (
    <>
      <FormSection3 title={t(`${tPrefix}.tab3.sections.yearlyPAndL`)}>
        <Inquiry.YearlyPAndL />
      </FormSection3>
    </>
  )
}
