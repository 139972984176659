import React from 'react'

import { theme } from '../../styles'
import { makeStyles } from '..'

const useStyles = makeStyles(
  {
    container: {
      height: 80,
      width: 80,
      zIndex: 1200,
      position: 'fixed',
      top: '50%',
      left: 'calc(50% - 40px)',
    },
    shadow: {
      filter: 'drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))',
    },
    shape: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      clipPath: 'url(#full)',
    },
    gradient: {
      // To make it a bit larger than the actual content, to avoid dead zone while rotating
      margin: -10,
      padding: 10,
      boxSizing: 'content-box',
      backgroundImage: `conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, rgba(255, 255, 255, 0) 180deg, rgba(255, 255, 255, 0.5) 270deg, rgba(255, 255, 255, 0.8) 360deg);`,
      animation: '$circular-rotate 1.4s linear infinite',
    },
    mask: {
      position: 'relative',
      top: -95,
    },
    '@keyframes circular-rotate': {
      '0%': {
        // Fix IE 11 wobbly
        transformOrigin: '50% 50%',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
  { name: 'GuikerSpinner' },
)

const GuikerSpinner: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.shadow}>
        <div className={classes.shape}>
          <div className={classes.gradient}>
            <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <defs>
                <clipPath
                  id='full'
                  width='80'
                  height='80'
                  viewBox='0 0 80 80'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M51.9911 63.1783H28.2227C27.2423 63.1783 26.3741 62.5453 26.0743 61.6119L18.8046 38.9804C18.5068 38.0534 18.8373 37.0399 19.6243 36.4665L38.8271 22.476C39.619 21.8991 40.6927 21.8991 41.4846 22.476L60.6824 36.4628C61.4718 37.0379 61.8016 38.0557 61.4995 38.9846L54.1369 61.6198C53.8346 62.5492 52.9684 63.1783 51.9911 63.1783Z'
                    fill='currentColor'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M40.1253 79.2785C29.3989 79.2785 17.587 78.8026 17.4687 78.7974V78.7974C15.9746 78.7372 14.676 77.7531 14.2141 76.331L0.300425 33.5024C-0.161675 32.08 0.310148 30.5203 1.48319 29.5925V29.5925C1.5755 29.5196 10.8521 22.1881 19.5307 15.8826C28.2067 9.57709 38.045 3.018 38.1438 2.95298V2.95298C39.3436 2.15296 40.9069 2.15296 42.1067 2.95298V2.95298C42.2055 3.018 52.0425 9.57709 60.7198 15.8826C69.3984 22.1881 78.675 29.5196 78.7673 29.5925V29.5925C79.9403 30.5203 80.4122 32.08 79.9501 33.5025L66.0364 76.331C65.5745 77.7531 64.2759 78.7372 62.7818 78.7974V78.7974C62.6635 78.8026 50.8503 79.2785 40.1253 79.2785ZM40.1254 71.8735C31.4443 71.8735 21.8845 71.4883 21.7887 71.4841V71.4841C20.5795 71.4354 19.5286 70.639 19.1547 69.488L7.8939 34.8254C7.51991 33.6742 7.90177 32.4119 8.85115 31.661V31.661C8.92586 31.602 16.4337 25.6684 23.4576 20.5651C30.4793 15.4619 38.4418 10.1534 38.5218 10.1008V10.1008C39.4928 9.45328 40.758 9.45328 41.7291 10.1008V10.1008C41.809 10.1534 49.7705 15.4619 56.7933 20.5651C63.8171 25.6684 71.325 31.602 71.3997 31.661V31.661C72.3491 32.4119 72.7309 33.6742 72.357 34.8254L61.0962 69.488C60.7223 70.639 59.6713 71.4354 58.4621 71.4841V71.4841C58.3664 71.4883 48.8055 71.8735 40.1254 71.8735Z'
                    fill='currentColor'
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={classes.mask}>
            <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M51.9911 63.1783H28.2227C27.2423 63.1783 26.3741 62.5453 26.0743 61.6119L18.8046 38.9804C18.5068 38.0534 18.8373 37.0399 19.6243 36.4665L38.8271 22.476C39.619 21.8991 40.6927 21.8991 41.4846 22.476L60.6824 36.4628C61.4718 37.0379 61.8016 38.0557 61.4995 38.9846L54.1369 61.6198C53.8346 62.5492 52.9684 63.1783 51.9911 63.1783Z'
                fill='currentColor'
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export { GuikerSpinner }
