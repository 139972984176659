import React, { useEffect, useState } from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import {
  Button,
  Chip,
  FormSection2,
  FormSection3,
  FullScreenSpinner,
  H1,
  Link,
  P,
  PageLayout,
  PBold,
  ThreeColumnsGridLayout,
  useParams,
  useT,
} from '@guiker/react-framework'
import { PayerRentPaymentsPlanTable } from '@guiker/rent-payment-components'
import { useAuthenticatedRentPaymentsPlanScopeNestedContext } from '@guiker/rent-payment-context'
import { Payer, RentPaymentsPlanStatus } from '@guiker/rent-payment-shared'
import { addressFormatter, money, optionalConcat } from '@guiker/shared-framework'

import { Flex } from '../../components'

export const TenantScreen: React.FC = () => {
  const { tMain } = useT({ domain: 'myInvestments' })
  const { tenantId } = useParams()
  const { data: rentPaymentsPlan } = useAuthenticatedRentPaymentsPlanScopeNestedContext()
  const [payer, setPayer] = useState<Payer>()
  const address = addressFormatter.printShortAddress(rentPaymentsPlan?.unit?.address)
  const name = optionalConcat([payer?.firstName, payer?.lastName], ' ')
  const title = name ?? address
  useEffect(() => {
    if (rentPaymentsPlan) setPayer(rentPaymentsPlan.payers.find((payer) => payer.userId === tenantId))
  }, [rentPaymentsPlan?.id])

  if (!payer) <FullScreenSpinner />
  return (
    <PageLayout
      title={
        <Flex gap={3} alignItems='center'>
          <H1 mb={0}>{title}</H1>
          <Chip size='small' color={rentPaymentsPlan.status === RentPaymentsPlanStatus.ACTIVE ? 'success' : 'info'}>
            {tMain(`screens.tenant.status.${rentPaymentsPlan.status}`)}
          </Chip>
        </Flex>
      }
      topActions={
        <Link to={mainPathBuilder.root.messages.path({ bookingId: rentPaymentsPlan.scope.id })}>
          <Button>{tMain('screens.tenant.cta')}</Button>
        </Link>
      }
    >
      <FormSection2 hasDivider={false}>
        <ThreeColumnsGridLayout>
          <Flex flexDirection='column'>
            <PBold>{tMain('screens.tenant.address')}</PBold>
            <P>{address}</P>
          </Flex>
          <Flex flexDirection='column'>
            <PBold>{tMain('screens.tenant.tenantName')}</PBold>
            <P>{name}</P>
          </Flex>
          <Flex flexDirection='column'>
            <PBold>{tMain('screens.tenant.totalRent')}</PBold>
            <P>{money.fromAmount(rentPaymentsPlan.total.amount, rentPaymentsPlan.total.currency).toString()}</P>
          </Flex>
        </ThreeColumnsGridLayout>
        <FormSection3 title={tMain('screens.tenant.rentalTable')}>
          {payer && <PayerRentPaymentsPlanTable rentPaymentsPlan={rentPaymentsPlan} payer={payer} />}
        </FormSection3>
      </FormSection2>
    </PageLayout>
  )
}
