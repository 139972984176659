import React, { useState } from 'react'

import { ChevronDownIcon, ChevronUpIcon, InfoTriangle } from '@guiker/icons'
import { PayInMethod } from '@guiker/payment-shared'
import { Card, Collapse, Divider, Flex, makeStyles, P, PBold, useTranslation } from '@guiker/react-framework'
import { Payer, RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'
import { compareDate, DateTime, money, parseDateTime } from '@guiker/shared-framework'

import { useBookingContext } from '../../hooks'
import { PayInMethodInfo } from './PayInMethodInfo'

type MobileRentPaymentsPlanDetailsProps = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
  payInMethod: PayInMethod
}

const useStyle = makeStyles({
  pointer: {
    cursor: 'pointer',
  },
})

export const MobileRentPaymentsPlanDetails: React.FC<MobileRentPaymentsPlanDetailsProps> = ({
  rentPaymentsPlan,
  payer,
  payInMethod,
}) => {
  const { t } = useTranslation(['main-bookingRentPayment', 'common'])
  const classes = useStyle()
  const { booking } = useBookingContext()
  const tPrefix = 'main-bookingRentPayment:rentPaymentsPlanTable.mobile'
  const [openDetails, setOpenDetails] = useState(false)

  const {
    tenantInstalments,
    total: { currency },
  } = rentPaymentsPlan

  const { from, to } = booking.info.period
  const leaseDuration = `${parseDateTime(from).toFormat('MMM dd yyyy')} - ${parseDateTime(to).toFormat('MMM dd yyyy')}`

  const today = DateTime.local()
  const outstandingTenantInstalments = tenantInstalments.filter(
    ({ status, chargeDate }) => ['CREATED', 'FAILED'].includes(status) && today > parseDateTime(chargeDate),
  )
  const outstandingAmount = outstandingTenantInstalments.reduce((prev, current) => {
    const { processedWith } = { ...current }
    const amount = processedWith ? processedWith.amount : payer.amount
    return prev + amount
  }, 0)
  const outstandingBalance = money.fromAmount(outstandingAmount, money.currency[currency]).toString()
  const rent = money.fromAmount(payer.amount, money.currency[currency]).toString()

  const { months, days } = compareDate(to).getDifference(today, ['months', 'days'])
  const monthToDate = `${months && `${months} ${t('common:duration.month_plural')} `}${
    days && `${days} ${t('common:duration.day_plural')}`
  }`

  return (
    <Card p={2} shadow='medium' radius={2}>
      <Flex flexDirection='column'>
        <Flex
          className={classes.pointer}
          alignItems='center'
          justifyContent='space-between'
          gap={2}
          mb={2}
          onClick={() => setOpenDetails(!openDetails)}
        >
          <P mb={0}>{t(`${tPrefix}.headers.details.header`, { leaseDuration })}</P>
          <div>{openDetails ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
        </Flex>
        <Collapse in={openDetails}>
          <Flex flexDirection='column' mb={2}>
            <P mb={0}>{t(`${tPrefix}.headers.details.monthToDate`, { monthToDate })}</P>
            <P mb={0}>{t(`${tPrefix}.headers.details.rent`, { rent })}</P>
          </Flex>
        </Collapse>
        <Flex justifyContent='space-between' fullWidth>
          <PBold mb={0}>{t(`${tPrefix}.headers.details.outstandingBalance`)}</PBold>
          <Flex gap={1} alignItems='center'>
            {outstandingAmount > 0 && <InfoTriangle color='alert' />}
            <PBold mb={0} color={outstandingAmount > 0 ? 'alert' : 'info'}>
              {outstandingBalance}
            </PBold>
          </Flex>
        </Flex>
      </Flex>
      <Divider my={2} color={10} />
      <PayInMethodInfo rentPaymentsPlan={rentPaymentsPlan} payInMethod={payInMethod} />
    </Card>
  )
}
