import React from 'react'

import { Box, clsx, isMobile, makeStyles, padding, theme, useLayoutContext } from '@guiker/react-framework'

const useStyle = makeStyles(
  {
    mainContainer: {
      backgroundImage: ({ backgroundUrl }: { backgroundUrl: string }) => `url("${backgroundUrl}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      marginTop: -theme.dimensions.appBar.height.desktop,
      padding: padding(5, 10),
      paddingTop: theme.dimensions.appBar.height.desktop,

      [isMobile]: {
        marginTop: -theme.dimensions.appBar.height.mobile,
        padding: padding(5, 2),
        paddingTop: theme.dimensions.appBar.height.mobile,
      },
      height: 290,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  {
    name: 'ListingHeroSection',
  },
)

type HeroSectionProps = React.PropsWithChildren & {
  className?: string
  sectionContainerClassName?: string
  backgroundUrl: string
}

const HeroSection: React.FC<HeroSectionProps> = ({
  className,
  sectionContainerClassName,
  backgroundUrl,
  children,
  ...props
}) => {
  const { isMobile } = useLayoutContext()
  const classes = useStyle({ backgroundUrl, isMobile })

  return (
    <Box className={clsx(classes.mainContainer, className)} {...props}>
      {children}
    </Box>
  )
}

export { HeroSection }
