import React from 'react'

import { Document } from '@guiker/base-listing-components'
import { PropSharingListing } from '@guiker/propsharing-shared'
import { Flex, PageSection2 } from '@guiker/react-framework'

import { useT } from '../../i18n'

type ListingDocumentsProps = {
  listing: PropSharingListing
}

export const ListingDocuments: React.FC<ListingDocumentsProps> = ({ listing }) => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.listingDocuments' })
  const listingDocuments = listing.documents

  return (
    <PageSection2 title={tMain('header')}>
      {listingDocuments?.length > 0 && (
        <Flex flexDirection='column' gap={3} maxWidth={700} width='100%'>
          {listingDocuments.map((document) => (
            <Document key={document.id} url={document.url} displayName={document.displayName ?? document.fileName} />
          ))}
        </Flex>
      )}
    </PageSection2>
  )
}
