import React from 'react'

import { buildBankInformation, PayoutMethod } from '@guiker/payout-shared'
import {
  DeepPartial,
  OneColumnGridLayout,
  RadioGroup,
  TextField,
  ThreeColumnsGridLayout,
  useGetInputProps,
  useTranslation,
} from '@guiker/react-framework'
import { CountryCode } from '@guiker/use-plaid-link'

type FormContentProps = {
  defaultValue: DeepPartial<PayoutMethod> & { bankInformation: ReturnType<typeof buildBankInformation> }
  readOnly?: boolean
  countryCode?: CountryCode
}

const tPrefix = 'payout:payoutMethod.bankInformation'
const namespaces = ['payout', 'main-payout', 'common']

const ManualInputContent: React.FC<FormContentProps> = ({
  readOnly,
  defaultValue,
  countryCode = CountryCode.Canada,
}) => {
  const { t } = useTranslation([])
  const formPrefix = 'bankInformation.details'
  const inputProps = useGetInputProps({ formPrefix, namespaces, tPrefix, readOnly, defaultValue })
  const errorMessages = {
    matches: t('payout:payoutMethod.errors.bankAccountInfo'),
  }
  const GridComponent = readOnly ? ThreeColumnsGridLayout : OneColumnGridLayout

  return (
    <GridComponent>
      {countryCode === CountryCode.Canada ? (
        <>
          <TextField {...inputProps('institutionNumber')} errorMessages={errorMessages} />
          <TextField {...inputProps('transitNumber')} errorMessages={errorMessages} />
        </>
      ) : (
        <TextField {...inputProps('routingNumber')} errorMessages={errorMessages} />
      )}
      <TextField {...inputProps('accountNumber')} errorMessages={errorMessages} />
      <RadioGroup
        {...inputProps('accountType')}
        condensed={readOnly}
        options={[
          { value: 'checking', label: t(`${tPrefix}.checkingAccount`) },
          { value: 'savings', label: t(`${tPrefix}.savingsAccount`) },
        ]}
      />
    </GridComponent>
  )
}

export { ManualInputContent }
