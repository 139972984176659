import { RouteConfig } from '@guiker/router'

import {
  CompleteInvestmentPayoutMethodScreen,
  CompletePayoutMethodScreen,
  CreatePayoutMethodScreen,
  PayoutMethodScreen,
  PaysafeTermsAndConditionsScreen,
} from '../screens'

const defaultRouteConfig: Partial<RouteConfig> = {
  exact: true,
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes: { [key: string]: RouteConfig } = {
  payoutMethod: {
    ...defaultRouteConfig,
    path: '/:id',
    isOutlet: true,
    routes: {
      root: {
        ...defaultRouteConfig,
        path: '/',
        Component: PayoutMethodScreen,
      },
      investment: {
        ...defaultRouteConfig,
        path: '/investment',
        Component: CompleteInvestmentPayoutMethodScreen,
      },
      edit: {
        ...defaultRouteConfig,
        path: '/edit',
        Component: CompletePayoutMethodScreen,
      },
    },
  },
  new: {
    ...defaultRouteConfig,
    path: '/new',
    Component: CreatePayoutMethodScreen,
  },
  paysafeTermsAndConditions: {
    ...defaultRouteConfig,
    path: '/:id/providers/paysafe/:payoutProviderAccountId/terms-and-conditions',
    Component: PaysafeTermsAndConditionsScreen,
  },
}

export { routes }
