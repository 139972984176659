import React from 'react'

import { SoftcheckScanStatus } from '@guiker/background-check-shared'

import { Box, P } from '.'
import { ScanStatusChip } from './ScanStatusChip'

interface SoftcheckReportCardItemProps {
  title: string
  status: SoftcheckScanStatus
}

export const SoftcheckReportCardItem: React.FC<SoftcheckReportCardItemProps> = ({ title, status }) => {
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mt={2} mb={2}>
      <P m={0}>{title}</P>
      <ScanStatusChip status={status} />
    </Box>
  )
}
