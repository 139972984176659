import React, { useMemo, useState } from 'react'

import {
  Collapse,
  Divider,
  DropdownIcon,
  Flex,
  isMobile,
  makeStyles,
  P,
  PBig,
  theme,
  Trans,
  useTranslation,
} from '@guiker/react-framework'
import { printBedAndBathCount } from '@guiker/rental-listing-components'

import { ListingInquiryRentalOption } from '../../entity'
import { getPriceBoundaries } from '../../utils'
import { ListingRentalOptionPriceOption } from './ListingRentalOptionPriceOption'

type RentalOptionProps = {
  rentalOption: ListingInquiryRentalOption
  openByDefault?: boolean
  shouldCreateListingInquiry?: boolean
}

const useStyle = makeStyles(
  {
    root: {
      width: '100%',
      borderRadius: 0,
      border: `1px solid ${theme.palette.grey[10]}`,
      padding: theme.spacing(2),
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      [isMobile]: {
        alignItems: 'flex-start',
      },
    },
    bedAndBath: {
      fontWeight: theme.typography.variants.bodyBold.fontWeight,
      marginBottom: 0,
    },
    mobilePrice: {
      display: 'none',
      marginBottom: 0,
      [isMobile]: {
        display: 'block',
      },
    },
    desktopPrice: {
      marginRight: theme.spacing(1),
      display: 'block',
      [isMobile]: {
        display: 'none',
      },
    },
    iconCollapsed: {
      transform: 'rotate(180deg)',
    },
  },
  {
    name: 'RentalOption',
  },
)

export const RentalOption: React.FC<RentalOptionProps> = ({
  rentalOption,
  openByDefault,
  shouldCreateListingInquiry = true,
}) => {
  const { t } = useTranslation(['main-rentalListing', 'listing'])
  const classes = useStyle()
  const [openRentalOption, setOpenRentalOption] = useState(openByDefault)
  const { roomCount, priceOptions } = rentalOption
  const handleExpand = () => setOpenRentalOption(!openRentalOption)

  const memoizedPriceOptions = useMemo(
    () =>
      priceOptions
        .sort((a, b) => a.price.amount - b.price.amount)
        .map((option, i) => (
          <React.Fragment key={`price-option-${i}`}>
            <Divider mt={2} mb={2} color={10} />
            <ListingRentalOptionPriceOption
              key={option.legacyOptionId}
              priceOption={option}
              openByDefault={openByDefault && i === 0}
              shouldCreateListingInquiry={shouldCreateListingInquiry}
            />
          </React.Fragment>
        )),
    [priceOptions],
  )

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer} onClick={handleExpand}>
        <Flex flexDirection='column' gap={1}>
          <PBig className={classes.bedAndBath}>{printBedAndBathCount(t, roomCount)}</PBig>
          <div className={classes.mobilePrice}>
            <Trans
              i18nKey='main-rentalListing:listingDetails.pricePerMonth'
              values={{ price: getPriceBoundaries(priceOptions) }}
            >
              <P component='span' color='textPrimary' />
              <P component='span' color={30} />
            </Trans>
          </div>
          {priceOptions.length > 1 && (
            <P mb={0} color={60}>
              {t('main-rentalListing:listingDetails.priceOptions', { count: priceOptions.length })}
            </P>
          )}
        </Flex>
        <Flex flexDirection='row' alignItems='center'>
          <div className={classes.desktopPrice}>
            <Trans
              i18nKey='main-rentalListing:listingDetails.pricePerMonth'
              values={{ price: getPriceBoundaries(priceOptions) }}
            >
              <P component='span' color='textPrimary' />
              <P component='span' color={30} />
            </Trans>
          </div>
          {openRentalOption ? <DropdownIcon className={classes.iconCollapsed} /> : <DropdownIcon />}
        </Flex>
      </div>
      <Collapse in={openRentalOption} unmountOnExit={true}>
        {memoizedPriceOptions}
      </Collapse>
    </div>
  )
}
