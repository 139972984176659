import React, { useEffect } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { LabeledMonetaryValue } from '@guiker/base-listing-components'
import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { mainPathBuilder } from '@guiker/investment-shared'
import { usePayInMethodContext } from '@guiker/payment-context'
import { getPaymentHelpers } from '@guiker/payment-shared'
import {
  PropSharingListing,
  SeriesStatus,
  Stock,
  stockOrderTransactionFee,
  WithFundingData,
  WithInquiry,
  WithStockLatestFinancialStatements,
} from '@guiker/propsharing-shared'
import {
  Button,
  Divider,
  Flex,
  makeStyles,
  PSmall,
  PSmaller,
  RouterLink,
  Slider,
  sliderNumberFormatter,
  TwoColumnsGridLayout,
  useDateFormatter,
  useFormContext,
  useLayoutContext,
} from '@guiker/react-framework'
import { Currency, money } from '@guiker/shared-framework'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'
import { isListingWithInquiry } from '../utils'
import { CardResults } from './CardResults'
import { FundingData } from './FundingData'
import { ResultItem } from './ResultItem'
import { usePriceInfo } from './use-price-info'

type FormContentProps = {
  currency: Currency
  isSuccess?: boolean
  stock: Stock
  isTallScreen?: boolean
}

const useStyle = makeStyles(
  () => ({
    formContainer: {
      width: '100%',
      height: '100%',
    },
  }),
  {
    name: 'PropSharingInquiryFormContent',
  },
)

export const FormContent: React.FC<FormContentProps> = ({ stock, currency, isSuccess, isTallScreen }) => {
  const { tMain, tShared, MainTrans } = useT({ entity: 'listing', screenName: 'components.inquiryCard' })
  const { formatDate } = useDateFormatter()
  const classes = useStyle()

  const {
    listing,
    helpers: {
      allowance,
      canPurchaseStock,
      stock: { isFullyFunded, latestSeries, isSold },
    },
    refetchStockAllowance,
  } = useListingScreenContext()

  const { user } = useAuthenticationContext()
  const { reset } = useFormContext()
  const { isAtMostTablette } = useLayoutContext()
  const { selected: paymentMethod } = usePayInMethodContext()
  const { hasFees, fees } = getPaymentHelpers(paymentMethod)

  useEffect(() => {
    user && refetchStockAllowance()
  }, [user])

  useEffect(() => {
    isSuccess && reset()
  }, [isSuccess])

  const inquiryQuantity = isListingWithInquiry(listing)
    ? (listing as WithInquiry<PropSharingListing>).fractionalOwnershipInquiry?.unitAmount
    : undefined

  const priceInfo = usePriceInfo(stock, 'total')
  const totalFees = hasFees ? Math.round((priceInfo.amount * 3) / 100) : 0
  const feesString = money.fromAmount(totalFees, currency).toString()
  const unitCost = priceInfo.amount + totalFees
  const transactionFee = stockOrderTransactionFee[currency.iso]
  const totalPrice = unitCost + transactionFee
  const { investmentResults } = useInvestmentAssumptionContext()
  const isTallMobile = isAtMostTablette && isTallScreen

  return (
    <Flex flexDirection='column' className={classes.formContainer} gap={4} mb={3} mt={1}>
      {isAtMostTablette ? (
        <TwoColumnsGridLayout
          justifyItems='center'
          xs={{ gridTemplateColumns: 2, gridColumn: { start: 'auto', span: 1 } }}
        >
          <ResultItem
            i18nKey='listing.results.irr'
            size='large'
            name='results.irr'
            direction='column'
            value={investmentResults?.irr ?? listing.results?.irr}
          />
          <ResultItem
            i18nKey='stock.series.pricePerUnit'
            type='currency'
            currency={currency}
            withoutDecimal={false}
            direction='column'
            size='large'
            value={latestSeries?.pricePerUnit}
          />
        </TwoColumnsGridLayout>
      ) : (
        <ResultItem
          i18nKey='listing.results.irr'
          size='large'
          name='results.irr'
          value={investmentResults?.irr ?? listing.results?.irr}
        />
      )}
      {isTallMobile && (
        <FundingData listing={listing as WithStockLatestFinancialStatements<WithFundingData<PropSharingListing>>} />
      )}
      <CardResults isDrawer={isAtMostTablette} />
      {!isTallMobile && (
        <FundingData listing={listing as WithStockLatestFinancialStatements<WithFundingData<PropSharingListing>>} />
      )}
      {canPurchaseStock && (
        <Flex flexDirection='column' gap={3}>
          <Slider
            label={tMain('unitAmount')}
            name='unitAmount'
            track={false}
            max={allowance.maxQuantity}
            min={allowance.minQuantity}
            step={1}
            defaultValue={inquiryQuantity}
            formatter={sliderNumberFormatter.units()}
            withValueLabel={false}
            withTextField={true}
            textFieldEndAdornment={<PSmall mb={0}>{tShared('stock.units')}</PSmall>}
          />
          <Flex flexDirection='column' gap={0.5}>
            <LabeledMonetaryValue
              label={tShared('stock.unitCost')}
              currencySymbol='onlySymbol'
              noDecimal={false}
              value={{ value: unitCost, currency }}
              direction='row'
              size='small'
            />
            <LabeledMonetaryValue
              label={tShared('stock.fees')}
              currencySymbol='onlySymbol'
              noDecimal={false}
              value={{ value: transactionFee, currency }}
              direction='row'
              size='small'
            />
            <Divider mt={0} mb={0} />
            <LabeledMonetaryValue
              label={tShared('stock.total')}
              currencySymbol='onlySymbol'
              noDecimal={false}
              value={{ value: totalPrice, currency }}
              direction='row'
            />

            {hasFees && <PSmaller mb={0}>{tMain('includingFees', { fees: feesString, percent: fees * 100 })}</PSmaller>}
          </Flex>
        </Flex>
      )}
      {isFullyFunded || isSold ? (
        <Flex flexDirection='column' alignItems='center' gap={3}>
          <PSmall mb={0} textAlign='center'>
            {isSold ? tMain('sold') : tMain('fullyFunded')}
          </PSmall>
          <RouterLink to={mainPathBuilder.root.propSharing.path}>
            <Button>{tMain('fullyFundedCTA')}</Button>
          </RouterLink>
        </Flex>
      ) : (
        latestSeries.status === SeriesStatus.COMING_SOON && (
          <Flex flexDirection='column' alignItems='center' gap={3}>
            <PSmall mb={0} textAlign='center'>
              {tMain('comingSoon')}
            </PSmall>
            {latestSeries.startDate && (
              <PSmall mb={0} textAlign='center'>
                <MainTrans
                  i18nKey='comingSoonDate'
                  values={{
                    date: formatDate(latestSeries.startDate),
                  }}
                >
                  <PSmall mb={0} component='span' fontWeight={500} />
                </MainTrans>
              </PSmall>
            )}
          </Flex>
        )
      )}
    </Flex>
  )
}
