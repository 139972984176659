import React from 'react'

import { FeedbackComponent } from '../../../components'
import { Stepper } from '../../../components/quebec-lease/stepper'
import { useQuebecLeaseContext } from '../../../hooks'

const CreateFromBooking: React.FC = () => {
  const { lease, isLoading } = useQuebecLeaseContext()

  return (
    <>
      <FeedbackComponent loading={isLoading} />
      {!isLoading && <Stepper skipPrepare={!!lease?.id} />}
    </>
  )
}

export { CreateFromBooking }
