import React from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { Flex } from '@guiker/react-framework'
import { RentPaymentsPlanTable } from '@guiker/rent-payment-components'
import { Payer, RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'

import { RentPaymentStatus } from '../'
import { PayInMethodInfo } from './PayInMethodInfo'

type RentPaymentProps = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
  payInMethod: PayInMethod
}

export const RentPaymentByPayer: React.FC<RentPaymentProps> = ({ rentPaymentsPlan, payer, payInMethod }) => {
  return (
    <>
      <Flex justifyContent='space-between' p={3}>
        <PayInMethodInfo rentPaymentsPlan={rentPaymentsPlan} payInMethod={payInMethod} />
        <RentPaymentStatus rentPaymentsPlan={rentPaymentsPlan} payer={payer} />
      </Flex>
      <Flex mt={10} mx={3} pb={10}>
        <RentPaymentsPlanTable rentPaymentsPlan={rentPaymentsPlan} payInMethod={payInMethod} payer={payer} />
      </Flex>
    </>
  )
}
