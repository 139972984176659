import React from 'react'

import { ApiForm, Flex, isMobile, makeStyles, PageLayout, useLocationQuery } from '@guiker/react-framework'

import {
  InvestmentListingGrid,
  PER_UNIT_PRICE_RANGE,
  PERCENT_FUNDED_RANGE,
  PROJECTED_IRR_RANGE,
} from '../../components'
import { usePublicApiClient } from '../../hooks'
import { InvestmentScreenContextProvider, useInvestmentScreenContext } from './InvestmentScreenContext'
import { InvestmentsSearchTabs } from './InvestmentsSearchTabs'
import { SearchHeader } from './SearchHeader'

type InvestmentsSearchScreenProps = {}

const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      body: {
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.short,
        }),

        background: `linear-gradient(180deg, ${theme.palette.common.black} 0px, ${theme.palette.common.black} 300px, ${theme.palette.common.white} 300px, ${theme.palette.common.white} 100%)`,
      },
    },
    root: {
      paddingTop: theme.spacing(5),
      [isMobile]: {
        paddingTop: theme.spacing(2),
      },
      backgroundColor: theme.palette.common.white,
    },
  }),
  { name: 'InvestmentsSearchScreen' },
)

export const InvestmentsSearchScreenContent: React.FC<InvestmentsSearchScreenProps> = () => {
  const { currentIndex } = useInvestmentScreenContext()
  const apiClient = usePublicApiClient()

  const payload =
    currentIndex === 2
      ? { type: 'PropertySaleListing' }
      : currentIndex === 1
      ? { type: 'FractionalOwnershipListing' }
      : {}

  const fetcher = ({ page }: { page: number }) =>
    apiClient.readAllListing({ queryParams: { page, perPage: 6 }, payload })

  return <InvestmentListingGrid queryKey={['InvestmentsSearchScreen', 'readAllListing', payload]} fetcher={fetcher} />
}

export const InvestmentsSearchScreen: React.FC = () => {
  const { city, pricePerUnit: pricePerUnitStr } = useLocationQuery(['city', 'pricePerUnit'])
  const pricePerUnit = pricePerUnitStr && pricePerUnitStr.split(',')
  const classes = useStyles()

  return (
    <InvestmentScreenContextProvider>
      <PageLayout header={<SearchHeader />} className={classes.root} noPaddingTop noBreadcrumpSpacer>
        <Flex flexDirection='column' gap={5}>
          <InvestmentsSearchTabs />
          <ApiForm
            formName='InvestmentsSearchScreen'
            formOptions={{
              defaultValues: {
                city: city || 'any',
                pricePerUnit: [
                  pricePerUnit?.[0] || PER_UNIT_PRICE_RANGE.min,
                  pricePerUnit?.[1] || PER_UNIT_PRICE_RANGE.max,
                ],
                projectedIRR: [PROJECTED_IRR_RANGE.min, PROJECTED_IRR_RANGE.max],
                percentFunded: [PERCENT_FUNDED_RANGE.min, PERCENT_FUNDED_RANGE.max],
              },
            }}
          >
            <InvestmentsSearchScreenContent />
          </ApiForm>
        </Flex>
      </PageLayout>
    </InvestmentScreenContextProvider>
  )
}
