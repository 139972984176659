import React from 'react'

import { FormSection3, Note, OneColumnGridLayout, P } from '@guiker/components-library'
import { Trans } from '@guiker/i18n'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { Collapse, PBold, RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Quebec.Conditions
  name: string
  readOnly: boolean
}

export const Conditions: React.FC<Props> = ({ defaultValue, name: prefix, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const name = `${prefix}.conditions`
  const accessToLandName = `${name}.accessToLand`
  const rightToKeepAnimalName = `${name}.rightToKeepAnimal`
  const conditions = useWatch({ name, defaultValue })

  return (
    <FormSection3 title={t('quebecLeaseScreenE:conditionLabel')}>
      <div>
        <RadioGroup
          label={
            <P mb={0}>
              <Trans i18nKey='quebecLeaseScreenE:accessToLandLabel'>
                <PBold mb={0} component='span' />
              </Trans>
            </P>
          }
          defaultValue={conditions?.accessToLand?.hasAccess}
          readOnly={readOnly}
          name={`${accessToLandName}.hasAccess`}
          options={[
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
          ]}
        />
        <Collapse in={conditions?.accessToLand?.hasAccess} unmountOnExit>
          <Note>
            <OneColumnGridLayout>
              <TextField
                readOnly={readOnly}
                defaultValue={conditions?.accessToLand.description}
                label={t('common:specify')}
                name={`${accessToLandName}.description`}
              />
            </OneColumnGridLayout>
          </Note>
        </Collapse>
      </div>
      <div>
        <RadioGroup
          label={
            <P mb={0}>
              <Trans i18nKey='quebecLeaseScreenE:rightToKeepAnimalLabel'>
                <PBold mb={0} component='span' />
              </Trans>
            </P>
          }
          defaultValue={conditions?.rightToKeepAnimal?.hasRight}
          readOnly={readOnly}
          name={`${rightToKeepAnimalName}.hasRight`}
          options={[
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
          ]}
        />
        <Collapse in={conditions?.rightToKeepAnimal?.hasRight} unmountOnExit>
          <Note>
            <OneColumnGridLayout>
              <TextField
                label={t('common:specify')}
                readOnly={readOnly}
                defaultValue={conditions?.rightToKeepAnimal?.description}
                name={`${rightToKeepAnimalName}.description`}
              />
            </OneColumnGridLayout>
          </Note>
        </Collapse>
      </div>
    </FormSection3>
  )
}
