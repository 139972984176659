import React from 'react'

import { getGradeColor } from '@guiker/background-check-components'
import { calculateReportItems } from '@guiker/background-check-shared'
import { makeStyles } from '@guiker/components-library'

import { useTranslation } from '../i18n'
import { Grid, P } from '.'

const SCORES = [800, 750, 700, 650, 600, 550, 500, 499]

const useStyles = makeStyles({
  root: {
    maxWidth: 460,
  },
  colorSquare: {
    width: 40,
    height: 40,
    borderRadius: 8,
  },
})

const GradeSystem: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction='column' spacing={1} className={classes.root}>
      <Grid item container>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <P mb={0}>{t('common:grade')}</P>
        </Grid>
        <Grid item xs={4}>
          <P mb={0}>{t('common:score')}</P>
        </Grid>
        <Grid item xs={2}>
          <P mb={0}>{t('common:defaultPercent')}</P>
        </Grid>
      </Grid>

      {SCORES.map((score) => {
        const { grade, range, defaultChance } = calculateReportItems(score)
        const color = getGradeColor(grade)

        return (
          <Grid item container alignItems='center' key={score}>
            <Grid item xs={2}>
              <div className={classes.colorSquare} style={{ backgroundColor: color }} />
            </Grid>
            <Grid item xs={2}>
              <P mb={0}>{grade}</P>
            </Grid>
            <Grid item xs={4}>
              <P mb={0}>{range}</P>
            </Grid>
            <Grid item xs={2}>
              <P mb={0}>{defaultChance}</P>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export { GradeSystem }
