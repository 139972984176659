import React from 'react'

import { Flex, PSmall, useLayoutContext, useTranslation } from '@guiker/react-framework'
import { RentalListing } from '@guiker/rental-listing-shared'
import { DateTime } from '@guiker/shared-framework'

import { findMlsCompliance } from '../../utils'
import { PriceComponent } from '../PriceComponent'
import { TitleComponent } from '../TitleComponent'

type HeadingComponentProps = {
  listing: RentalListing
  isRentedOut: boolean
}

export const HeadingComponent: React.FC<HeadingComponentProps> = ({ listing, isRentedOut }) => {
  const { isMobile } = useLayoutContext()
  const { t } = useTranslation(['main-rentalListing'])
  const mlsCompliance = findMlsCompliance(listing)

  return (
    <Flex
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      gap={2}
    >
      {isMobile && !isRentedOut && <PriceComponent listing={listing} />}
      <TitleComponent listing={listing} />
      <Flex flexDirection='column' alignItems={isMobile ? 'flex-start' : 'flex-end'}>
        {!isMobile && !isRentedOut && <PriceComponent listing={listing} />}
        <div>
          <PSmall mb={0} color={30} textAlign={isMobile ? 'start' : 'end'}>
            {t('updated', { at: DateTime.fromISO(listing.updatedAt).toRelative() })}
          </PSmall>
          {mlsCompliance && (
            <PSmall mb={0} color={30} textAlign={isMobile ? 'start' : 'end'}>
              {mlsCompliance.identifier.label} {mlsCompliance.identifier.value}
            </PSmall>
          )}
        </div>
      </Flex>
    </Flex>
  )
}
