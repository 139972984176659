import React, { useState } from 'react'

import { mainPathBuilder } from '@guiker/investment-shared'
import { generateUseContext, InvestIcon, PropertySaleIcon, PropSharingIcon, useLocation } from '@guiker/react-framework'

import { useT } from '../../i18n'

type Tab = {
  key: string
  label: string
  content: string
  icon: React.ReactElement
}

type InvestmentScreenContext = {
  tabs: Tab[]
  currentIndex: number
  currentTab: Tab
  setCurrentIndex: (value: number) => unknown
}

const InvestmentScreenContext = React.createContext<InvestmentScreenContext>(null)

type InvestmentScreenContextProviderProps = React.PropsWithChildren & {}

export const InvestmentScreenContextProvider: React.FC<InvestmentScreenContextProviderProps> = ({ children }) => {
  const { tMain } = useT({ screenName: 'screens.searchScreen.tabs' })
  const currentLocation = useLocation()
  const defaultIndex = currentLocation.pathname.includes(mainPathBuilder.root.propertySale.path)
    ? 2
    : currentLocation.pathname.includes(mainPathBuilder.root.propSharing.path)
    ? 1
    : 0

  const [currentIndex, setCurrentIndex] = useState(defaultIndex)

  const tabs = [
    {
      key: 'allProperties.tab',
      icon: <InvestIcon strokeWidth={1} />,
      label: tMain('allProperties.tab'),
      content: tMain('allProperties.content'),
    },
    {
      key: 'propsharing.tab',
      icon: <PropSharingIcon strokeWidth={1} />,
      label: tMain('propsharing.tab'),
      content: tMain('propsharing.content'),
    },
    {
      key: 'propertySale.tab',
      icon: <PropertySaleIcon strokeWidth={1} />,
      label: tMain('propertySale.tab'),
      content: tMain('propertySale.content'),
    },
  ]

  return (
    <InvestmentScreenContext.Provider value={{ tabs, currentTab: tabs[currentIndex], currentIndex, setCurrentIndex }}>
      {children}
    </InvestmentScreenContext.Provider>
  )
}

export const useInvestmentScreenContext = generateUseContext(InvestmentScreenContext)
