import React from 'react'

import { AgentIcon, LandlordIcon } from '@guiker/icons'

import { useTranslation } from '../../../hooks'
import { LeaseRoleNames } from '../../../types'
import { RadioGroup, RadioGroupProps } from '../..'

type Props = Omit<RadioGroupProps, 'options' | 'name'>

export const PrepareAsRadioGroup: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation(['common'])
  return (
    <RadioGroup
      name='draftedAs'
      defaultValue=''
      options={[
        { value: LeaseRoleNames.DRAFTER, label: t('agentOnBehalf'), adornment: <AgentIcon color='secondary' /> },
        { value: LeaseRoleNames.LESSOR, label: t('landlord'), adornment: <LandlordIcon color='secondary' /> },
      ]}
      {...props}
    />
  )
}
