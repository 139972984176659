import { mainRoutes } from '@guiker/conversation-shared'
import { webAppRouterBuilder } from '@guiker/react-framework'

import { InquiryChatScreen } from '../screens'

export const Router = webAppRouterBuilder(mainRoutes, {
  root: {
    routes: {
      messages: InquiryChatScreen,
    },
  },
})
