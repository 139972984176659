import React from 'react'

import { Schema } from '@guiker/lease-shared'
import { LeaseRoleNames } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { useMutation } from '@guiker/react-query'

import { useBookingContext, useLeaseApiClient, useOntarioLeaseContext, useTranslation } from '../../../hooks'
import { Box, H4, PrepareAsRadioGroup } from '../../'
import { Step, StepProps } from '../../common'

type Props = StepProps

export const PrepareAsStep: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common'])
  const apiClient = useLeaseApiClient()
  const { bookingId } = useBookingContext()
  const { setLease } = useOntarioLeaseContext()

  const resolver = yupResolver(Schema.createLeaseFromBookingPayloadSchema)
  const { mutate: createOntarioLeaseFromBooking, isLoading: isSubmitting } = useMutation(
    (payload: { draftedAs: LeaseRoleNames.LESSOR | LeaseRoleNames.DRAFTER }) => {
      return apiClient.createOntarioLeaseFromBooking({
        pathParams: {
          bookingId,
        },
        payload,
      })
    },
    {
      onSuccess: (lease) => setLease(lease),
    },
  )

  return (
    <Step
      hasBackButton={false}
      onSubmit={createOntarioLeaseFromBooking}
      formOptions={{ resolver }}
      formName={'OntarioLeaseFormPrepareAsStep'}
      isSubmitting={isSubmitting}
      {...props}
    >
      <Box mx={2}>
        <H4 mb={3}>{t('whoAreYou')}</H4>
        <PrepareAsRadioGroup />
      </Box>
    </Step>
  )
}
