import React from 'react'

import { AuthenticatedApi } from '@guiker/listing-inquiry-shared'
import {
  ApiForm,
  ButtonWithLoader,
  Checkbox,
  ColumnGridItem,
  Flex,
  FormSection3,
  FormSection4,
  isAtMostTablette,
  makeStyles,
  OneColumnGridLayout,
  SecondaryButton,
  Slide,
  TextField,
  theme,
  TwoColumnsGridLayout,
  useMediaQuery,
  useT,
  yupResolver,
} from '@guiker/react-framework'

import { PhoneNumber, Sticky, TimeSlotScheduler } from '../../../components'
import { useConfirmListingInquiryScreenContext } from '../../../context'
import { useAuthenticationContext } from '../../../hooks'

const useStyles = makeStyles({
  contactLabel: {
    marginTop: theme.spacing(5),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginTop: 0,
    [isAtMostTablette]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  buttonContainer: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    [isAtMostTablette]: {
      paddingBottom: 0,
    },
  },
})

type BookVisitProp = {
  isStickyButtonVisible?: boolean
}

export const BookVisit: React.FC<BookVisitProp> = ({ isStickyButtonVisible = true }) => {
  const { updateListingInquiry, listingInquiry, selectedOption, isConfirmed, toggleConfirm, isLoading } =
    useConfirmListingInquiryScreenContext()

  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { tMain, tBase } = useT({ domain: 'rentalListingInquiry', screenName: 'components.bookVisit' })
  const { user } = useAuthenticationContext()

  const resolver = yupResolver(AuthenticatedApi.Schema.bookVisitSchema)
  const isUnitRental = selectedOption.type === 'ENTIRE'

  return (
    <Slide direction='left' in={isConfirmed}>
      <Flex fullWidth>
        <ApiForm
          formName='MainCreateBookVisitFromListingInquiry'
          formOptions={{ resolver }}
          onSubmit={updateListingInquiry}
        >
          <FormSection3 title={tMain(`title.${isUnitRental ? 'unit' : 'room'}`)}>
            {isUnitRental && (
              <OneColumnGridLayout>
                <TimeSlotScheduler minSlots={1} />
              </OneColumnGridLayout>
            )}
            <FormSection4 title={tMain('contact.title')}>
              <TwoColumnsGridLayout>
                <TextField
                  name='firstName'
                  required
                  label={tBase('user.firstName')}
                  defaultValue={(listingInquiry && listingInquiry.user?.firstName) || user?.firstName}
                />
                <TextField
                  name='lastName'
                  required
                  label={tBase('user.lastName')}
                  defaultValue={(listingInquiry && listingInquiry.user?.lastName) || user?.lastName}
                  maxWidth='100%'
                  mb={0}
                />
                <PhoneNumber
                  name='phone'
                  required
                  disableFlags
                  label={tBase('user.phoneNumber')}
                  defaultValue={listingInquiry && listingInquiry.user?.phone}
                  maxWidth='100%'
                />
                <ColumnGridItem md={2} xs={1}>
                  <Checkbox
                    name='isBookingNotificationEnabled'
                    defaultValue={true}
                    maxWidth='100%'
                    label={tMain('contact.smsConsent')}
                  />
                </ColumnGridItem>
                <ColumnGridItem md={2} xs={1}>
                  <TextField
                    defaultValue={listingInquiry?.message}
                    name='message'
                    multiline
                    rows={3}
                    label={tMain('contact.label')}
                  />
                </ColumnGridItem>
              </TwoColumnsGridLayout>
            </FormSection4>
            <Sticky mode='bottom' disabled={!isMobile || !isStickyButtonVisible}>
              <Flex
                fullWidth
                className={classes.buttonContainer}
                flexDirection={isMobile ? 'column-reverse' : 'row-reverse'}
              >
                <ButtonWithLoader type='submit' className={classes.button} isLoading={isLoading}>
                  {tMain(`confirm.${isUnitRental ? 'unit' : 'room'}`)}
                </ButtonWithLoader>
                <SecondaryButton onClick={toggleConfirm}>{tMain('back')}</SecondaryButton>
              </Flex>
            </Sticky>
          </FormSection3>
        </ApiForm>
      </Flex>
    </Slide>
  )
}
