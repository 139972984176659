import React from 'react'

import { Document } from '@guiker/base-listing-components'
import { Flex } from '@guiker/components-library'
import { ProjectWithDeveloper } from '@guiker/real-estate-shared'

type ProjectDocumentsProps = {
  project: ProjectWithDeveloper
}

const ProjectDocuments: React.FC<ProjectDocumentsProps> = ({ project }) => {
  const developerDocuments = [...(project?.documents || []), ...(project?.developer?.documents || [])]

  return (
    <>
      {!!developerDocuments.length && developerDocuments.length > 0 && (
        <Flex flexDirection='column' gap={3}>
          {developerDocuments.map((document) => (
            <Document key={document.id} url={document.url} displayName={document.displayName ?? document.fileName} />
          ))}
        </Flex>
      )}
    </>
  )
}

export { ProjectDocuments }
