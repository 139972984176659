import { initialize, useT as baseUseT } from '@guiker/i18n'
import { en, fr, zh } from '@guiker/i18n-resources'
export * from '@guiker/i18n'

const i18n = initialize({
  resources: {
    en,
    fr,
    zh,
  },
})

export const useT = (args: { screenName?: string; entity?: string }) => baseUseT({ ...args, domain: 'propsharing' })

export const useInvestmentAssumptionT = (args: { screenName?: string; entity?: string }) =>
  baseUseT({ ...args, domain: 'investmentAssumption' })

export { i18n }
