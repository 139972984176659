import React, { useState } from 'react'

import { Box, ButtonWithLoader, P, TextField } from '@guiker/react-framework'

import { useTranslation } from '../../i18n'

export const SSNConsentForm: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation(['common', 'main-backgroundCheck'])

  const [ssnNumber, setSsnNumber] = useState<string>('')

  const parseSsn = (value) => {
    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{3})/, '$1-')
    value = value.replace(/-(\d{2})/, '-$1-')
    value = value.replace(/(\d)-(\d{4}).*/, '$1-$2')

    return value
  }

  return (
    <Box maxWidth={400} width='100%'>
      <P color={60} breakline>
        {t('main-backgroundCheck:screens.requestInformation.description')}
      </P>
      <TextField
        placeholder={'000-00-0000'}
        name='ssn'
        defaultValue={ssnNumber}
        value={ssnNumber}
        maxWidth='100%'
        onChange={(event) => setSsnNumber(parseSsn(event.target.value))}
      />
      <Box display='flex' flexDirection='row-reverse'>
        <ButtonWithLoader type='submit' isLoading={isLoading}>
          {t('common:actions.submit')}
        </ButtonWithLoader>
      </Box>
    </Box>
  )
}
