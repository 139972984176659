import React, { useEffect, useState } from 'react'

import { makeStyles } from '@guiker/components-core'
import { Flex, H4, Typography } from '@guiker/components-library'
import { money } from '@guiker/money'
import { CREDIT_CARD_TRANSACTION_RATE, PayInMethodType } from '@guiker/payment-shared'

import { useAppContext, usePayInMethodContext } from '../../hooks'
import { useTranslation } from '../../i18n'

const useStyles = makeStyles({
  breakdownBoxLabelTotal: {
    fontWeight: 'bold',
  },
})

type BreakdownProps = {
  className?: string
  label: string
  amount: number
}

const Breakdown: React.FC<BreakdownProps> = ({ className, label, amount }) => {
  const {
    bookingPayment: { total },
  } = useAppContext()
  const currency = money.currency[total.currency]

  return (
    <Flex justifyContent='space-between' mb={2}>
      <Typography className={className} component='div'>
        {label}
      </Typography>
      <Typography component='div'>{money.fromAmount(amount, currency).toString()}</Typography>
    </Flex>
  )
}

const PaymentBreakdown: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    userContribution: { amount },
  } = useAppContext()
  const { selected: selectedPayInMethod } = usePayInMethodContext()
  const [transactionFee, setTransactionFee] = useState({ amount: 0, percentage: 0 })

  useEffect(() => {
    if (selectedPayInMethod) {
      const rate = selectedPayInMethod.type === PayInMethodType.CREDIT_CARD ? CREDIT_CARD_TRANSACTION_RATE : 0
      setTransactionFee({ amount: Math.round(amount * rate), percentage: rate * 100 })
    }
  }, [selectedPayInMethod])

  return (
    <>
      <H4 mt={3}>{t('rentBreakdown')}</H4>
      <Breakdown label={t('yourFirstMonthRentPayment')} amount={amount} />
      <Breakdown
        label={t('transactionFee', {
          transactionFeePercentage: transactionFee?.percentage ? `(${transactionFee?.percentage}%)*` : '*',
        })}
        amount={transactionFee?.amount}
      />
      <Breakdown
        className={classes.breakdownBoxLabelTotal}
        label={t('total')}
        amount={amount * (1 + transactionFee?.percentage / 100)}
      />
    </>
  )
}

export { PaymentBreakdown }
