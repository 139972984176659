import { Contribution, ContributionStatus } from '@guiker/booking-shared'

const getOccupiedAmount = (contributions: Contribution[], userId: string) => {
  return contributions.reduce((sum, c) => {
    const amount = c.status !== ContributionStatus.INITIAL_STATE && c.amount !== null ? c.amount : 0
    return (sum += c.userId !== userId ? amount : 0)
  }, 0)
}

const isPositiveNumber = (value: string) => {
  return value !== '' && value !== null && Math.sign(Number(value)) >= 0
}

export { getOccupiedAmount, isPositiveNumber }
