import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { routes } from '@guiker/blog-routes'
import { AppBar as BaseAppBar, DropdownMenu, NavigationItemProps, useScreenSize } from '@guiker/components-library'
import { TFunction } from '@guiker/i18n'

import { useTranslation } from '../../hooks'

export type AppBarProps = {
  categories: string[]
}

const splitItems = ({ items, count, t }: { items: NavigationItemProps[]; count: number; t: TFunction }) => {
  return [
    ...items.slice(0, count),
    {
      Component: () =>
        items.length > count ? (
          <DropdownMenu
            darkMode
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            text={t('common:more')}
            items={items.slice(count)}
          />
        ) : null,
    },
  ]
}

const AppBar: React.FC<AppBarProps> = ({ categories }) => {
  const { user } = useAuthenticationContext()
  const { t } = useTranslation()
  const { isLg, isMd, isXl } = useScreenSize()

  const items =
    categories?.map((categorySlug) => ({
      to: routes.blog.categoryNested(categorySlug).path,
      text: t(`main-blog:categories.${categorySlug}`),
    })) || []

  const { navBarItems, topMenuItems } = isXl
    ? {
        navBarItems: splitItems({ items, count: 8, t }),
        topMenuItems: [],
      }
    : isLg
    ? {
        navBarItems: splitItems({ items, count: 6, t }),
        topMenuItems: [],
      }
    : isMd
    ? {
        navBarItems: splitItems({ items, count: 4, t }),
        topMenuItems: [],
      }
    : {
        navBarItems: [],
        topMenuItems: items,
      }

  return (
    <BaseAppBar
      subTitle={t('main-blog:subtitle')}
      subTitleUrl={routes.blog.path}
      hasBorder={true}
      navBarItems={navBarItems}
      topMenuItems={topMenuItems}
      user={user}
    />
  )
}

export { AppBar }
