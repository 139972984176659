import React from 'react'

import { useAssetFetcher } from '@guiker/asset-uploader'
import { getPayoutConfigs, PayoutMethod, VerificationDocument } from '@guiker/payout-shared'
import { DeepPartial, FormSection, TextButton, useModal, useT } from '@guiker/react-framework'

import { useEditLinkContext } from '../../context'
import { EditButton } from '../EditButton'
import { DocumentExplanationModal } from './DocumentExplanationModal'
import { VerificationDocumentsSection } from './VerificationDocumentsSection'

type VerificationDocumentsFormContentProps = {
  defaultValue: DeepPartial<PayoutMethod>
  readOnly?: boolean
  title?: string
  showExplanation?: boolean
}

const VerificationDocumentsFormContent: React.FC<VerificationDocumentsFormContentProps> = ({
  defaultValue,
  readOnly,
  title,
  showExplanation = true,
}) => {
  const { t } = useT({ domain: 'payout' })
  const { getOneAssetGroupByName } = useAssetFetcher()
  const payoutConfig = getPayoutConfigs(defaultValue.type, {
    isManualInput: true,
    capabilities: defaultValue?.capabilities,
  })
  const documentTypes = payoutConfig?.documentTypes || []
  const { editUrl } = useEditLinkContext({ shouldThrowIfUndefined: false }) ?? {}
  const { isOpen, openModal, closeModal } = useModal()

  return (
    <>
      <FormSection title={title} topActions={readOnly && <EditButton url={editUrl} />}>
        {!readOnly && showExplanation && (
          <TextButton onClick={openModal}>{t('payout:payoutMethod.verificationDocuments.faq.title')}</TextButton>
        )}
        <DocumentExplanationModal isOpen={isOpen} onClose={closeModal} />
        <FormSection hasDivider={true}>
          {documentTypes.map((documentType, index) => (
            <VerificationDocumentsSection
              required={payoutConfig?.requiredDocumentTypes.includes(documentType)}
              key={index}
              defaultValue={getOneAssetGroupByName(documentType) as VerificationDocument}
              documentType={documentType}
              name={`verificationDocuments[${index}]`}
              title={t(`payout:payoutMethod.verificationDocuments.${documentType}`)}
              readOnly={readOnly}
            />
          ))}
        </FormSection>
      </FormSection>
    </>
  )
}

export { VerificationDocumentsFormContent }
