import React, { createContext, useEffect, useState } from 'react'

import { useBookingRoommateContext } from '@guiker/booking-context'
import {
  Booking,
  findTenantByUserId,
  isTimerValid,
  RoommateIntroTenant,
  RoommateIntroTenantDecision,
  RoommateIntroTenantDecisionReason,
  transformBookingsToRoommates,
} from '@guiker/booking-shared'
import { generateUseContext, useModal, UseMutateFunction, useMutation, useNavigate } from '@guiker/react-framework'
import { Roommate } from '@guiker/shared-framework'

import { useBookingApiClient } from '../lib'

type ContextType = {
  actionOnSuccess: () => void
  approveRoommateIntro: UseMutateFunction<never, unknown, void, unknown>
  booking: Booking
  closeModal: () => void
  isFetching: boolean
  isOpen: boolean
  openModal: () => void
  rejectRoommateIntro: UseMutateFunction<
    never,
    unknown,
    {
      reason: RoommateIntroTenantDecisionReason
      otherReason: string
    },
    unknown
  >
  roommates: Roommate[]
  tenant: RoommateIntroTenant
  isRejecting: boolean
  isExpired: boolean
}

export const BookingRoommateIntroReviewScreenContext = createContext<ContextType>(null)

export const BookingRoommateIntroReviewScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { booking, isFetching, refetchBooking, tenantId } = useBookingRoommateContext()
  const { isOpen, closeModal, openModal } = useModal()
  const [roommates, setRoommates] = useState<Roommate[]>()
  const [tenant, setTenant] = useState<RoommateIntroTenant>()
  const [isExpired, setIsExpired] = useState(false)
  const bookingApiClient = useBookingApiClient()
  const navigate = useNavigate()

  const actionOnSuccess = async () => {
    await refetchBooking()
    navigate('/')
  }

  useEffect(() => {
    if (booking) {
      setIsExpired(!isTimerValid(booking.applicants))
    }
  }, [booking])

  const { mutateAsync: approveRoommateIntro } = useMutation(
    async () =>
      bookingApiClient.updateRoommateIntroTenantDecision({
        pathParams: { bookingId: booking.id },
        payload: { tenantDecision: RoommateIntroTenantDecision.ACCEPTED, reason: null, otherReason: null },
      }),
    {
      onSuccess: () => {
        actionOnSuccess()
      },
    },
  )

  const { mutateAsync: rejectRoommateIntro, isLoading } = useMutation(
    async (payload: { reason: RoommateIntroTenantDecisionReason; otherReason: string }) =>
      bookingApiClient.updateRoommateIntroTenantDecision({
        pathParams: { bookingId: booking.id },
        payload: { tenantDecision: RoommateIntroTenantDecision.REJECTED, ...payload },
      }),
    {
      onSuccess: () => {
        actionOnSuccess()
      },
    },
  )

  useEffect(() => {
    if (!!booking && tenantId) {
      setRoommates(transformBookingsToRoommates([booking]))
      setTenant(findTenantByUserId({ booking, userId: tenantId }))
    }
  }, [booking, tenantId])

  const value = {
    actionOnSuccess,
    approveRoommateIntro,
    booking,
    closeModal,
    isFetching,
    isOpen,
    openModal,
    rejectRoommateIntro,
    roommates,
    tenant,
    isRejecting: isLoading,
    isExpired,
  }

  return (
    <BookingRoommateIntroReviewScreenContext.Provider value={value}>
      {children}
    </BookingRoommateIntroReviewScreenContext.Provider>
  )
}

export const useBookingRoommateIntroReviewScreenContext = generateUseContext(BookingRoommateIntroReviewScreenContext)
