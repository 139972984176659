import React from 'react'

import { PageLayout, SuccessPage } from '../../components'
import { useBookingContext } from '../../hooks'
import { useTranslation } from '../../i18n'

export const CreateSuccessScreen: React.FC = () => {
  const { t } = useTranslation(['main-bookingDocument'])
  const { bookingChat } = useBookingContext()

  return (
    <PageLayout takeoverProps={{ onClose: bookingChat.navigate, hasBorder: true }} forceTakeover>
      <SuccessPage buttonProps={{ onClick: bookingChat.navigate, children: t('actions.backToBooking') }} />
    </PageLayout>
  )
}
