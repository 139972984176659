import React from 'react'

import { useT } from '../../i18n'
import { PercentRangeSlider } from '../PercentRangeSlider'

export const PERCENT_FUNDED_RANGE = {
  min: 0,
  max: 1,
}

type PercentFundedSliderProps = {
  readOnly?: boolean
}

export const PercentFundedSlider: React.FC<PercentFundedSliderProps> = ({ readOnly }) => {
  const { tMain } = useT({ screenName: 'components.percentFunded' })

  return (
    <PercentRangeSlider
      name='percentFunded'
      label={tMain('label')}
      step={0.01}
      readOnly={readOnly}
      {...PERCENT_FUNDED_RANGE}
    />
  )
}
