import React from 'react'

import { FormSection3, OneColumnGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation, useWatch } from '../../../hooks'
import { Quebec } from '../../../types'
import { Collapse, RadioGroup, TextField } from '../../'

type Props = {
  name: string
  defaultValue: Quebec.Rent
}

export const PaymentMethod: React.FC<Props> = ({ name, defaultValue }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])
  const paymentMethod = useWatch({ name: `${name}.paymentMethod`, defaultValue: defaultValue?.paymentMethod })
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection3 title={t('quebecLeaseScreenD:paymentMethod')}>
      <OneColumnGridLayout>
        <RadioGroup
          aria-label='paymentMethod'
          defaultValue={paymentMethod}
          readOnly={!canDraftLease}
          disabled={!canDraftLease}
          name={`${name}.paymentMethod`}
          options={[
            { value: Quebec.PaymentMethod.cash, label: t('quebecLeaseScreenD:cash') },
            { value: Quebec.PaymentMethod.cheque, label: t('quebecLeaseScreenD:cheque') },
            {
              value: Quebec.PaymentMethod.electronicBankTransfer,
              label: t('quebecLeaseScreenD:electronicBankTransfer'),
            },
            { value: Quebec.PaymentMethod.other, label: t('quebecLeaseScreenD:other') },
          ]}
        />
        <Collapse in={paymentMethod === Quebec.PaymentMethod.other} unmountOnExit>
          <TextField
            name={`${name}.otherPayment`}
            readOnly={!canDraftLease}
            disabled={!canDraftLease}
            defaultValue={defaultValue?.otherPayment || ''}
            label={t('common:specify')}
          />
        </Collapse>
        <Collapse in={paymentMethod === Quebec.PaymentMethod.cheque} unmountOnExit>
          <RadioGroup
            defaultValue={defaultValue?.lesseeAgreeOnPostDatedCheque}
            label={t('quebecLeaseScreenD:lesseePostDatedCheque')}
            readOnly={!canDraftLease}
            disabled={!canDraftLease}
            name={`${name}.lesseeAgreeOnPostDatedCheque`}
            options={[
              { value: true, label: t('common:yes') },
              { value: false, label: t('common:no') },
            ]}
          />
        </Collapse>
      </OneColumnGridLayout>
    </FormSection3>
  )
}
