import React, { useEffect, useState } from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { PageNotFound, UnexpectedError } from '@guiker/error-pages'
import { useMutation } from '@guiker/react-query'
import { useNavigate, useParams } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'

import { Box, Button, H3, P, PageLayout, useLocationQuery } from '../../components'
import { useBackgroundCheckJwtAuthenticatedApiClient, useClaimsAuthenticationContext } from '../../hooks'
import { useTranslation } from '../../i18n'

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(4),
  },
})

export const ConsentBackgroundCheck: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const { backgroundCheckId } = useParams()
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()
  const [error, setError] = useState<boolean>(null)
  const apiClient = useBackgroundCheckJwtAuthenticatedApiClient()

  const { mutate: consent } = useMutation(
    () =>
      apiClient.consentBackgroundCheck({
        pathParams: { backgroundCheckId },
        payload: { consent: true },
      }),
    {
      onError: () => setError(true),
    },
  )

  useEffect(() => {
    setClaimsFromToken(token)
  }, [token])

  useEffect(() => {
    if (claims?.token) {
      consent()
    }
  }, [claims])

  if (!token) {
    return <PageNotFound />
  }

  return (
    <TakeoverContextProvider onClose={() => navigate('/')} title={t('consent:guiker')}>
      <PageLayout maxWidth={900}>
        {error && (
          <UnexpectedError>
            <Button
              onClick={() => {
                setError(false)
                navigate('/')
              }}
            >
              {t('consent:goToGuiker')}
            </Button>
          </UnexpectedError>
        )}
        {!error && (
          <Box className={classes.container}>
            <H3 mt={4}>{t('consent:thankYou')}</H3>
            <P my={4}>{t('consent:consentReceived')}</P>
          </Box>
        )}
      </PageLayout>
    </TakeoverContextProvider>
  )
}
