import React from 'react'

import { FormSection3, OneColumnGridLayout } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { TextField } from '../../'

type Props = {
  defaultValue: Quebec.Rent
  name: string
}

export const PaymentPlace: React.FC<Props> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection3 title={t('quebecLeaseScreenD:placeOfPaymentLabel')}>
      <OneColumnGridLayout>
        <TextField
          defaultValue={defaultValue.rentPayableAt}
          label={t('quebecLeaseScreenD:rentPayableAtLabel')}
          readOnly={!canDraftLease}
          name={`${name}.rentPayableAt`}
        />
      </OneColumnGridLayout>
    </FormSection3>
  )
}
