import React, { useState } from 'react'

import { getLocationService } from '@guiker/base-entity'
import { parseDateTime } from '@guiker/date'
import {
  Box,
  getLocalizedCityFromLocation,
  getLocalizedNeighbourhood,
  Grid,
  SecondaryButton,
  Trans,
  useT,
} from '@guiker/react-framework'
import { TenantPreferenceModal } from '@guiker/tenant-preference-component'

import { useTenantPreferenceContext } from '../../hooks'

const FilterGroup: React.FC = () => {
  const { tMain, t } = useT({ domain: 'rentalTenancy' })
  const { tenantPreference } = useTenantPreferenceContext()
  const leasePreferences = tenantPreference?.leasePreferences

  const [openTenantPreferenceModal, setOpenTenantPreferenceModal] = useState<boolean>(false)

  const handleTenantPreferenceModal = () => setOpenTenantPreferenceModal(!openTenantPreferenceModal)

  const location = getLocationService().getCityBySlug(leasePreferences?.location)

  const locationLabel = location && getLocalizedCityFromLocation(t, location)

  const neighbourhoods = leasePreferences?.neighbourhoods?.map((neighbourhood) =>
    getLocalizedNeighbourhood(t, {
      countryCode: location?.country?.countryCode,
      citySlug: location?.city?.slug,
      neighbourhoodSlug: neighbourhood,
    }),
  )

  const preferenceFilterTypes = [
    {
      type: 'location',
      i18nKey: 'filter.location',
      values: {
        city: locationLabel,
        count: neighbourhoods?.length
          ? neighbourhoods.length > 1
            ? ` (${tMain('filter.neighbourhoods', { value: neighbourhoods.length })})`
            : ` (${tMain('filter.neighbourhood', { neighbourhood: neighbourhoods[0] })})`
          : '',
      },
    },
    {
      type: 'budget',
      i18nKey: 'filter.budget',
      values: {
        budget: leasePreferences?.budget,
      },
    },
    {
      type: 'floorPlan',
      i18nKey: `filter.floorPlan.${leasePreferences?.floorPlan}`,
    },
    {
      type: 'leaseStart',
      i18nKey: 'filter.leaseStart',
      values: {
        leaseStart:
          (leasePreferences?.leaseStart && parseDateTime(leasePreferences?.leaseStart).toFormat('MMM yyyy')) ?? '',
      },
    },
    {
      type: 'leaseDuration',
      i18nKey: 'filter.leaseDuration',
      values: {
        leaseDuration: leasePreferences?.leaseDuration,
      },
    },
    {
      type: 'furniture',
      i18nKey: 'filter.furniture',
    },
  ]

  return (
    <>
      <Box mb={6}>
        <Grid container spacing={2} direction='row'>
          {preferenceFilterTypes
            .filter((filter) => leasePreferences && leasePreferences[filter.type])
            .map((filter, index) => (
              <Grid item key={filter.type + index}>
                <SecondaryButton onClick={handleTenantPreferenceModal}>
                  <Trans i18nKey={`main-rentalTenancy:${filter.i18nKey}`} values={filter.values} />
                </SecondaryButton>
              </Grid>
            ))}
          <Grid item>
            <SecondaryButton onClick={handleTenantPreferenceModal}>{tMain('editPreference')}</SecondaryButton>
          </Grid>
        </Grid>
      </Box>
      <TenantPreferenceModal
        tenantPreference={tenantPreference}
        open={openTenantPreferenceModal}
        onClose={handleTenantPreferenceModal}
      />
    </>
  )
}

export { FilterGroup }
