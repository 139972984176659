import React from 'react'

import { Flex, Note, TwoColumnsGridLayout } from '@guiker/components-library'

import { useTranslation, useWatch } from '../../../hooks'
import { makeStyles, theme } from '../../../style'
import { Quebec } from '../../../types'
import { Checkbox, Collapse, NumericInput, TextField } from '../../'

interface Props {
  defaultValue?: Quebec.Dwelling
  readOnly?: boolean
  name?: string
  furnitureType?: keyof typeof Quebec.FurnitureTypes
}

type CollapseElementProps = Props & {
  className?: string
  label?: string
  t?: (key: string, options?: object) => {}
}

const useStyles = makeStyles({
  textField: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  },
  checkbox: {
    width: 0,
  },
})

const BedCollapseElement: React.FC<CollapseElementProps> = ({ name, defaultValue, t, readOnly }) => {
  const furniture = useWatch({ name: `${name}.furniture`, defaultValue: defaultValue.furniture })
  const bedsNumber = furniture?.bedsNumber ? parseInt(furniture?.bedsNumber as unknown as string) : 0

  return (
    <Flex gap={2} flexDirection='column'>
      <NumericInput
        readOnly={readOnly}
        defaultValue={bedsNumber}
        name={`${name}.furniture.bedsNumber`}
        max={10}
        label={t('quebecLeaseScreenB:howMany') as string}
      />
      <TwoColumnsGridLayout>
        {[...Array(bedsNumber)].fill(0).map((_, i) => (
          <TextField
            key={i}
            defaultValue={furniture.bedsSize ? furniture.bedsSize[i] : ''}
            name={`${name}.furniture.bedsSize[${i}]`}
            readOnly={readOnly}
            label={t('quebecLeaseScreenB:bedSize', { index: i + 1 })}
          />
        ))}
      </TwoColumnsGridLayout>
    </Flex>
  )
}

const TextFieldCollapseElement: React.FC<CollapseElementProps> = ({
  defaultValue,
  name,
  readOnly,
  furnitureType,
  t,
  ...props
}) => {
  return (
    <TextField
      name={`${name}.furniture.${furnitureType}Description`}
      defaultValue={defaultValue.furniture?.[`${furnitureType}Description`]}
      readOnly={readOnly}
      label={t('common:description')}
      {...props}
    />
  )
}

const NumericInputCollapseElement: React.FC<CollapseElementProps> = ({
  defaultValue,
  name,
  readOnly,
  furnitureType,
  t,
  ...props
}) => {
  return (
    <NumericInput
      name={`${name}.furniture.${furnitureType}Number`}
      defaultValue={defaultValue?.furniture?.[`${furnitureType}Number`]}
      readOnly={readOnly}
      label={t('quebecLeaseScreenB:howMany') as string}
      max={10}
      {...props}
    />
  )
}

const FurnitureType: React.FC<Props> = ({ defaultValue, name, readOnly, furnitureType }) => {
  const classes = useStyles()
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])
  const furniture = useWatch({
    name: `${name}.furniture.${furnitureType}`,
    defaultValue: defaultValue?.furniture?.[furnitureType],
  })

  let collapseElement = <div></div>

  if (furnitureType === 'other') {
    collapseElement = (
      <TextFieldCollapseElement
        defaultValue={defaultValue}
        furnitureType={furnitureType}
        className={classes.textField}
        readOnly={readOnly}
        name={name}
        label={t('common:description')}
        t={t}
      />
    )
  } else if (furnitureType === 'beds') {
    collapseElement = <BedCollapseElement defaultValue={defaultValue} readOnly={readOnly} t={t} name={name} />
  } else {
    collapseElement = (
      <NumericInputCollapseElement
        readOnly={readOnly}
        defaultValue={defaultValue}
        furnitureType={furnitureType}
        name={name}
        t={t}
      />
    )
  }

  return (
    <div>
      <Checkbox
        readOnly={readOnly}
        defaultValue={furniture}
        name={`${name}.furniture.${furnitureType}`}
        label={t(`quebecLeaseScreenB:${furnitureType}`)}
      />
      <Collapse in={furniture} unmountOnExit>
        <Note>{collapseElement}</Note>
      </Collapse>
    </div>
  )
}

export { FurnitureType }
