import React, { useState } from 'react'

import {
  Button,
  Collapse,
  Flex,
  FurnishedIcon,
  HandShakingIcon,
  makeStyles,
  P,
  PBold,
  SecondaryButton,
  theme,
  useT,
  useTranslation,
} from '@guiker/react-framework'
import { printBedAndBathCount } from '@guiker/rental-listing-components'
import { DateTime, money, optionalConcat } from '@guiker/shared-framework'

import { ListingInquiryPriceOption } from '../../entity'
import { useListingInquiryContext } from '../../hooks'
import { Appliances } from './Appliances'
import { RentalOptionInfoLabel } from './RentalOptionInfoLabel'
import { Utilities } from './Utilities'

type ListingRentalOptionPriceOptionProps = {
  priceOption: ListingInquiryPriceOption
  openByDefault?: boolean
  shouldCreateListingInquiry?: boolean
}

const useStyle = makeStyles(
  {
    availability: {
      marginBotton: theme.spacing(2),
    },
  },
  {
    name: 'PriceOption',
  },
)

export const ListingRentalOptionPriceOption: React.FC<ListingRentalOptionPriceOptionProps> = ({
  priceOption,
  openByDefault,
  shouldCreateListingInquiry = true,
}) => {
  const classes = useStyle()
  const { tMain, tShared, MainTrans } = useT({ domain: 'rentalListing' })
  const { t: tListing } = useTranslation(['listing'])
  const [openDetails, setOpenDetails] = useState(openByDefault)
  const { setSelectedPriceOptionId, setOpenInquiryCardModal } =
    useListingInquiryContext({ shouldThrowIfUndefined: false }) ?? {}
  const { suiteNumber, roomCount, unitAmenities, utilities, furnished } = priceOption
  const { size, type, price, availablityDate, duration } = priceOption
  const isFurnished = furnished === 'fully'

  const handleExpand = () => setOpenDetails(!openDetails)
  const handleSelectPriceOption = () => {
    setSelectedPriceOptionId(priceOption.legacyOptionId.toString())
    setOpenInquiryCardModal(true)
  }

  const availability = DateTime.fromISO(availablityDate)
  const isFuture = availability.diffNow('day').days > 0
  const availabilityLabel = isFuture ? availability.toFormat('LLLL yyyy') : tMain('listingDetails.availableNow')

  const optionLabel = optionalConcat(
    [
      suiteNumber ? tListing('unitNumber', { unit: suiteNumber }) : printBedAndBathCount(tListing, roomCount),
      `${optionalConcat([tListing(`rentalType.${type}`), size], ' | ')}`,
    ],
    ': ',
  )

  return (
    <Flex flexDirection='column' gap={2}>
      <Flex flexDirection='row' justifyContent='space-between'>
        <PBold>{optionLabel}</PBold>
        <PBold color='textPrimary'>{money.fromPrice(price).toString(true, true)}</PBold>
      </Flex>
      <div className={classes.availability}>
        <MainTrans i18nKey='listingDetails.formattedAvailable' values={{ date: availabilityLabel }}>
          <PBold component='span' />
          <P component='span' />
        </MainTrans>
      </div>
      <Flex gap={1} flexDirection='column'>
        <Flex minWidth={300}>
          <RentalOptionInfoLabel
            adornment={HandShakingIcon}
            label={tMain('duration.lease', {
              count: duration.count,
              unit: tMain(`duration.${duration.unit}`),
            })}
            isAvailable
          />
        </Flex>
        <Flex minWidth={300}>
          <RentalOptionInfoLabel adornment={FurnishedIcon} label={tShared('furnished')} isAvailable={isFurnished} />
        </Flex>
      </Flex>
      <Collapse in={openDetails} unmountOnExit={true}>
        <Utilities utilities={utilities} />
        <Appliances appliances={unitAmenities} />
      </Collapse>
      <Flex justifyContent='flex-end' gap={2}>
        <SecondaryButton size='small' onClick={handleExpand}>
          {openDetails ? tMain('actions.hideDetails') : tMain('actions.seeDetails')}
        </SecondaryButton>
        {shouldCreateListingInquiry && (
          <Button size='small' color='primary' onClick={handleSelectPriceOption}>
            {tMain('actions.message')}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
