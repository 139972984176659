import React from 'react'

import { TFunction, useWatch } from '../../../hooks'
import { Ontario, Ontario as Types } from '../../../types'
import { Collapse, CurrencyTextField, FormSection3, RadioGroup } from '../../'

type Props = {
  defaultValue: Types.RentDeposit
  name: 'rentDeposit'
  readOnly?: boolean
  t: TFunction
}

export const RentDeposit: React.FC<Props> = ({ defaultValue, name, readOnly, t }) => {
  const rentDepositName = `${name}.isDepositRequired` as const
  const isDepositRequired = useWatch<Ontario.LeaseInformation, typeof rentDepositName>({
    name: rentDepositName,
    defaultValue: defaultValue?.isDepositRequired,
  })

  return (
    <FormSection3>
      <RadioGroup
        defaultValue={isDepositRequired}
        name={rentDepositName}
        label={t('ontarioLeaseScreenE:selectOne')}
        options={[
          { value: false, label: t('ontarioLeaseScreenE:noRentDeposit') },
          { value: true, label: t('ontarioLeaseScreenE:rentDeposit') },
        ]}
        gridContainerProps={{ spacing: 1 }}
        readOnly={readOnly}
        maxWidth={420}
      />
      <Collapse in={isDepositRequired}>
        <CurrencyTextField
          defaultValue={defaultValue?.depositAmount}
          name={`${name}.depositAmount`}
          label={t('ontarioLeaseScreenE:rentDepositLabel')}
          readOnly={readOnly}
          maxWidth={420}
        />
      </Collapse>
    </FormSection3>
  )
}
