import React from 'react'

import { AnyColor, Label, makeStyles, P } from '@guiker/react-framework'

const useStyles = makeStyles({
  notAvailable: {
    width: 16,
    textAlign: 'center',
  },
})

const RentalOptionInfoLabel: React.FC<{
  adornment: React.FC<{ color: AnyColor }>
  label: string
  isAvailable: boolean
}> = ({ adornment: Adornment, label, isAvailable }) => {
  const classes = useStyles()

  return (
    <Label
      adornment={{
        node: isAvailable ? <Adornment color={'info'} /> : <div className={classes.notAvailable}>-</div>,
        position: 'start',
      }}
      text={
        <P color={isAvailable ? 'textPrimary' : 30} mb={0}>
          {label}
        </P>
      }
    />
  )
}

export { RentalOptionInfoLabel }
