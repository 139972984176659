import React from 'react'

import { ColumnGridItem, Flex, P } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { Collapse, Note } from '../../'
import { RadioGroup, TextField } from '../../'

interface Props {
  defaultValue: Quebec.DwellingPurpose
  readOnly?: boolean
  name: 'dwelling'
}

const Purpose: React.FC<Props> = ({ name, defaultValue, readOnly }) => {
  const dwellingPurpose = useWatch<Quebec.LeaseInformation, `${typeof name}.purpose`>({
    name: `${name}.purpose`,
    defaultValue,
  })

  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])
  const options = [
    { value: true, label: t('common:yes') },
    { value: false, label: t('common:no') },
  ]

  return (
    <ColumnGridItem md={2}>
      <div>
        <RadioGroup
          options={options}
          readOnly={readOnly}
          defaultValue={dwellingPurpose.isResidentialOnly}
          name={`${name}.purpose.isResidentialOnly`}
          label={t('quebecLeaseScreenB:dwellingPurposeLabel')}
        />
        <Collapse in={!dwellingPurpose?.isResidentialOnly} unmountOnExit={true}>
          <Note>
            <Flex gap={1} flexDirection='column'>
              <P mb={0}>{t('quebecLeaseScreenB:dwellingLeaseCombineLabel')}</P>
              <TextField
                readOnly={readOnly}
                defaultValue={dwellingPurpose.additionalPurpose}
                name={`${name}.purpose.additionalPurpose`}
                label={t('quebecLeaseScreenB:specifyDwellingLease')}
              />
              <P mb={0}>{t('quebecLeaseScreenB:disclaimerOfOneThirdArea')}</P>
            </Flex>
          </Note>
        </Collapse>
      </div>
    </ColumnGridItem>
  )
}

export { Purpose }
