import { findLatestSeries, Stock } from '@guiker/propsharing-shared'
import { useFormContext, useWatch } from '@guiker/react-hook-form'

import { useT } from '../../i18n'

export const usePriceInfo = (stock: Stock, type: 'total' | 'perUnit') => {
  const { tBase } = useT({ screenName: 'listing.stock' })
  const { getValues } = useFormContext()
  const inquiryQuantity = useWatch({ name: 'unitAmount', defaultValue: getValues().unitAmount }) as number
  const series = findLatestSeries(stock)
  const pricePerUnit = series.pricePerUnit
  const common = { inquiryQuantity, currency: stock.currency }

  return type === 'total'
    ? {
        label: tBase('total'),
        amount: pricePerUnit * (inquiryQuantity ?? 0),
        ...common,
      }
    : {
        label: tBase('pricePerUnit'),
        amount: pricePerUnit,
        ...common,
      }
}
