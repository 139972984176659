import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { PageMetaTags } from '@guiker/react-framework'
import { routesFromListing } from '@guiker/rental-listing-shared'
import { Redirect } from '@guiker/router'

import { usePublicListingContext } from '../hooks'
import { addressFormatter } from '../utils'

const DeprecatedListingRoute: React.FC = () => {
  const { data: listing } = usePublicListingContext()
  const subtitle = listing ? addressFormatter.printFullAddress(listing.address) : undefined

  return (
    <>
      <PageMetaTags subtitle={subtitle} description={listing?.description} noIndex />
      {listing ? <Redirect path={routesFromListing(listing).listing} retainQueryParams /> : <PageNotFound />}
    </>
  )
}

export { DeprecatedListingRoute }
