import React from 'react'

import { AuthInvoiceNestedRouter } from '@guiker/payment-app-components'
import { ScopeType } from '@guiker/payment-shared'
import { deprecatedRouterBuilder, useParams } from '@guiker/router'

import { ViewBookingRentPaymentsPlanScreen, ViewInvoiceScreen } from '../screens'
import { RentPaymentsPlanScopeNestedRouter } from './RentPaymentsPlanScopeNestedRouter'

const PaymentInvoiceAuthNestedRouter = () => {
  const { tenantInstalmentId } = useParams()

  return <AuthInvoiceNestedRouter scope={{ id: tenantInstalmentId, type: ScopeType.TENANT_INSTALMENT }} />
}

export const { routes, Router } = deprecatedRouterBuilder({
  bookingRentPayment: {
    path: '/',
    Component: RentPaymentsPlanScopeNestedRouter,
    isOutlet: true,
    routes: {
      root: {
        path: '',
        Component: ViewBookingRentPaymentsPlanScreen,
      },
      instalments: {
        path: '/instalments',
        routes: {
          byId: {
            path: '/:tenantInstalmentId',
            isOutlet: true,
            routes: {
              viewInvoice: {
                Component: PaymentInvoiceAuthNestedRouter,
                path: '/invoice',
                isOutlet: true,
                routes: {
                  root: {
                    Component: ViewInvoiceScreen,
                    path: '/',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
