import React from 'react'

import { AuthBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { yupResolver } from '@guiker/react-hook-form'
import { SupportingDocumentsContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, buildAssetGroups, SupportingDocument } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useTenantApplicationContext } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '..'

export type SupportingDocumentsStepProps = StepProps

const SupportingDocumentsStepContent: React.FC<SupportingDocumentsStepProps> = ({ onClickNext, ...props }) => {
  const resolver = yupResolver(AuthenticatedApi.Schema.updateTenantApplicationSupportingDocumentsPayloadValidator)
  const { setTenantApplication, tenantApplication } = useTenantApplicationContext()
  const { assetGroups, bulkUpdate, isUploading } = useBulkAssetUploaderContext()
  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const handleSubmit = async (payload: AuthenticatedApi.Schema.UpdateTenantApplicationSupportingDocumentsPayload) =>
    await apiClient.updateSupportingDocuments({ payload })

  return (
    <ApiForm
      formName='TenantApplicationSupportingDocumentStep'
      formOptions={{
        resolver,
        defaultValues: tenantApplication,
      }}
      onSubmit={handleSubmit}
      beforeValidation={() => bulkUpdate()}
      apiOptions={{
        onSuccess: (res) => {
          setTenantApplication(res)
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step hasBackButton isSubmitting={isUploading || isSubmitting} {...props}>
          <SupportingDocumentsContent defaultValue={assetGroups as SupportingDocument[]} readOnly={false} />
        </Step>
      )}
    </ApiForm>
  )
}

const SupportingDocumentsStep: React.FC<SupportingDocumentsStepProps> = (props) => {
  const { isLoading, tenantApplication } = useTenantApplicationContext()

  if (isLoading) {
    return <FullScreenSpinner />
  }

  const assetGroups = buildAssetGroups(tenantApplication?.supportingDocuments, 'supportingDocuments')

  return (
    <AuthBulkAssetUploaderContextProvider
      assetGroups={assetGroups}
      scope={{
        type: 'TenantApplication',
        id: tenantApplication?.id,
      }}
    >
      <SupportingDocumentsStepContent {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}

export { SupportingDocumentsStep }
