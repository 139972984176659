import React, { useState } from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { FeedbackComponent, FormSection1, FullScreenSpinner } from '@guiker/components-library'
import { useQuery } from '@guiker/react-query'

import { useAuthenticationContext, useNotificationAuthenticatedApiClient } from '../../hooks'
import { useT } from '../../i18n'
import { ConfigureNotificationPreferencesForm } from './FormContent'
import { transformNotificationPreference } from './utils'

const NotificationPreferencesScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.notifications' })
  const apiClient = useNotificationAuthenticatedApiClient()
  const { user } = useAuthenticationContext()
  const [isSuccess, setIsSuccess] = useState(false)

  const { data, isLoading: isFetchingData } = useQuery(
    [`readNotificationPreference`, user.id],
    () => apiClient.readNotificationPreference().then((data) => transformNotificationPreference(data)),
    {
      enabled: !!user,
    },
  )

  return (
    <AccountPageLayout hasButtonContainer>
      <FormSection1 title={tMain('title')}>
        {isFetchingData ? (
          <FullScreenSpinner />
        ) : (
          <ConfigureNotificationPreferencesForm
            setIsSuccess={setIsSuccess}
            defaultValues={data}
            isSuccess={isSuccess}
          />
        )}
        {isSuccess && (
          <FeedbackComponent
            toasterMessage={tMain('success')}
            toasterType='success'
            onToasterClose={() => setIsSuccess(false)}
          />
        )}
      </FormSection1>
    </AccountPageLayout>
  )
}

export { NotificationPreferencesScreen }
