import React, { useEffect, useState } from 'react'

import { ContextUser } from '@guiker/authentication-context'
import { mainPathBuilder } from '@guiker/conversation-shared'
import { PayInMethod } from '@guiker/payment-shared'
import { useQueryMutation } from '@guiker/react-framework'
import { RentPaymentByReceiver } from '@guiker/rent-payment-components'
import { RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'
import { addressFormatter } from '@guiker/shared-framework'

import {
  MobileRentPaymentsPlan,
  PageLayout,
  PageNotFound,
  RentPaymentByPayer,
  Spinner,
  theme,
  useMediaQuery,
} from '../../components'
import { useAuthenticationContext, useBookingContext, useLayoutContext, usePaymentApiClient } from '../../hooks'
import { useT } from '../../i18n'
import { useRentPaymentsPlanScreen } from './use-rent-payments-plan-screen'

type Props = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  user: ContextUser
}

const PayerContent: React.FC<Props> = ({ rentPaymentsPlan, user }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [payInMethod, setPayInMethod] = useState<PayInMethod>()

  const apiClient = usePaymentApiClient()
  const payer = rentPaymentsPlan.payers.find((p) => p.userId === user.id)
  const props = { rentPaymentsPlan, payer, payInMethod }

  const { useQuery } = useQueryMutation({ queryKey: ['RentPaymentsByPayer', payer.payInMethod.id, user?.id] })
  const onSuccess = (payInMethods: PayInMethod[]) => {
    setPayInMethod(payInMethods.find(({ id }) => id === payer.payInMethod?.id))
  }
  useQuery(() => apiClient.readAllPayInMethods(), { onSuccess, enabled: !!user })

  return isMobile ? <MobileRentPaymentsPlan {...props} /> : <RentPaymentByPayer {...props} />
}

const ReceiverContent: React.FC<Props> = ({ rentPaymentsPlan }) => {
  return <RentPaymentByReceiver rentPaymentsPlan={rentPaymentsPlan} />
}

const ViewBookingRentPaymentsPlanScreen: React.FC = () => {
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'screens.rentPayment' })
  const { user } = useAuthenticationContext()
  const { setAppBarIsDisplayed } = useLayoutContext()
  const { bookingId, booking } = useBookingContext()
  const { rentPaymentsPlan, isFetching, isAuthorized, isPayer, isReceiver } = useRentPaymentsPlanScreen()
  const { countryCode: country, apartmentNumber: suite } = booking.listing.address

  useEffect(() => {
    setAppBarIsDisplayed(true)
  }, [])

  if (isFetching || !rentPaymentsPlan) {
    return <Spinner />
  }

  if (!isAuthorized) {
    return <PageNotFound />
  }

  return (
    <PageLayout
      title={tMain('title')}
      breadcrumbItems={[
        {
          to: mainPathBuilder.root.messages.path({ bookingId }),
          label: `${tMain('booking')} - ${addressFormatter.printStreetInformation({
            ...booking.listing.address,
            suite,
            country,
          })}`,
        },
        {
          label: `${tMain('title')}`,
        },
      ]}
    >
      {isPayer && <PayerContent rentPaymentsPlan={rentPaymentsPlan} user={user} />}
      {isReceiver && <ReceiverContent rentPaymentsPlan={rentPaymentsPlan} user={user} />}
    </PageLayout>
  )
}

export { ViewBookingRentPaymentsPlanScreen }
