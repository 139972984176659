import React from 'react'

import { PageLayout, useNavigate } from '@guiker/components-library'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import { WireTransferInstruction } from '@guiker/payment-app-components'
import { ScopeType } from '@guiker/payment-shared'
import { useT } from '@guiker/react-framework'

export const WireTransferInstructionScreen: React.FC = () => {
  const { tShared } = useT({ domain: 'payment' })
  const navigate = useNavigate()

  const onClose = () => {
    navigate(mainPathBuilder.root.investmentDashboard.path)
  }

  return (
    <PageLayout forceTakeover maxWidth={900} title={tShared('wireTransfer.title')} takeoverProps={{ onClose }}>
      <WireTransferInstruction scopeType={ScopeType.BUY_STOCK_ORDER} />
    </PageLayout>
  )
}
