import { Ontario, Quebec } from '@guiker/lease-shared'
type Participant = 'applicant' | 'unitManager' | 'invited-lessor' | 'guarantor' | ''

export const hasQuebecParticipantSigned = (participant: Participant, emailAddress: string, lease: Quebec.Lease) => {
  switch (participant) {
    case 'applicant':
      return !!lease?.leaseInformation.lessees.find((user) => user.emailAddress === emailAddress)?.signature?.content
    case 'guarantor':
      return !!lease?.leaseInformation.lessees.find((user) => user.guarantor.emailAddress === emailAddress)?.signature
        ?.content
    case 'invited-lessor':
    case 'unitManager':
      return !!lease?.leaseInformation.lessors.find((user) => user.emailAddress === emailAddress)?.signature?.content
    default:
      return false
  }
}

export const hasOntarioParticipantSigned = (participant: Participant, emailAddress: string, lease: Ontario.Lease) => {
  switch (participant) {
    case 'applicant':
      return !!lease?.leaseInformation.lessees.find((user) => user.emailAddress === emailAddress)?.signature?.content
    case 'guarantor':
      return !!lease?.leaseInformation.lessees.find((user) => user.guarantor.emailAddress === emailAddress)?.signature
        ?.content
    case 'invited-lessor':
    case 'unitManager':
      return !!lease?.leaseInformation.lessors.find((user) => user.emailAddress === emailAddress)?.signature?.content
    default:
      return false
  }
}
