import React from 'react'

import { Booking } from '@guiker/booking-shared'

import { BookingDocumentsTable } from '../../components'

export const BookingDocumentUnitManagerView: React.FC<{ booking: Booking; accessControlToken: string }> = ({
  booking,
  accessControlToken,
}) => {
  return <BookingDocumentsTable booking={booking} accessControlToken={accessControlToken} />
}
