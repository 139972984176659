import React from 'react'

import { Inquiry } from '@guiker/investment-assumption-components'

import { useListingScreenContext } from '../../context'

export const InvestmentAssumptions: React.FC = () => {
  const { listing } = useListingScreenContext()

  const assumptionsProps = {
    name: 'assumptions',
    defaultValue: listing,
    readOnly: false,
  }

  return <Inquiry.InvestmentAssumptions {...assumptionsProps} />
}
