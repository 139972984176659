import React from 'react'

import { Button, Flex, P, SecondaryButton } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { webappRoutes } from '@guiker/main-webapp-shared'
import { webappRoutes as listingWebappRoutes } from '@guiker/rental-listing-shared'
import { Link } from '@guiker/router'

import { EmptyStateLayout } from '../EmptyStateLayout'
import { RentalEmptyStateIllustration } from './RentalEmptyStateIllustration'

export const RentalEmptyState = () => {
  const { t } = useTranslation(['common', 'main-myInvestments'])
  const tPrefix = 'main-myInvestments:rental.emptyState'

  return (
    <EmptyStateLayout
      direction='rtl'
      title={t(`${tPrefix}.title`)}
      text={t(`${tPrefix}.text`)}
      cta={
        <Flex>
          <Flex flexDirection='column' gap={1}>
            <Link to={webappRoutes.Services.path}>
              <Button fullWidth={true}>{t(`${tPrefix}.cta`)}</Button>
            </Link>
            <P mb={0} textAlign='center'>
              {t('common:or')}
            </P>
            <Link isExternalLink to={listingWebappRoutes.manageListing.addListing().path}>
              <SecondaryButton fullWidth={true}>{t(`${tPrefix}.alternativeCTA`)}</SecondaryButton>
            </Link>
          </Flex>
        </Flex>
      }
      illustration={<RentalEmptyStateIllustration />}
    />
  )
}
