import React, { useEffect, useState } from 'react'

import { AuthenticatedApi } from '@guiker/booking-shared'
import { inferApplicantStepStatus, ParticipantStepStatus } from '@guiker/booking-shared'
import { money } from '@guiker/money'
import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  Divider,
  Flex,
  H4,
  P,
  PSmall,
  yupResolver,
} from '@guiker/react-framework'
import { useNavigate } from '@guiker/router'

import { getOccupiedAmount } from '../../helper/helper'
import { useAppContext, useAuthenticationContext, useBookingPaymentApiClient } from '../../hooks'
import { useTranslation } from '../../i18n'
import { routes } from '../../routes'
import { BookingPaymentUpdatedModal } from '../BookingPaymentUpdatedModal'
import { Contribution } from './Contribution'

const Contributions: React.FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const resolver = yupResolver(AuthenticatedApi.Schema.updateOneBookingPaymentContributionSchema)

  const apiClient = useBookingPaymentApiClient()
  const { user: currentUser } = useAuthenticationContext()
  const { bookingId, bookingPayment, setBookingPayment, userContribution, refetchBooking, isSingleTenant } =
    useAppContext()
  const { contributions, total, eligibility } = bookingPayment
  const { amount, status, isWaived: bookingPaymentWaived } = { ...userContribution }

  const [isValidContribution, setIsValidContribution] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const unprocessableStepStatus = [ParticipantStepStatus.PROCESSING, ParticipantStepStatus.FINALIZED]
  const canChangeContribution = !unprocessableStepStatus.includes(inferApplicantStepStatus(status))

  const occupiedAmount = getOccupiedAmount(contributions, currentUser.id)
  const remainingRentAmount = total?.amount - occupiedAmount - (amount || 0)
  const currency = money.currency[total?.currency]

  useEffect(() => {
    setIsValidContribution(
      amount !== null && (isSingleTenant ? remainingRentAmount === 0 : total?.amount - amount >= 0),
    )
  }, [amount])

  const onSubmit = async ({ amount }: AuthenticatedApi.Schema.UpdateOneBookingPaymentContributionSchema) => {
    return apiClient.updateOneBookingPaymentContribution({
      pathParams: { bookingId },
      payload: { amount: money.toAmount(amount, currency).amount },
    })
  }
  return (
    <>
      <ApiForm
        formName={'BookingPaymentContributionForm'}
        onSubmit={onSubmit}
        formOptions={{ resolver }}
        apiOptions={{
          onSuccess: async (bookingPaymentResult) => {
            if (bookingPaymentWaived || amount === 0) {
              await refetchBooking()
              setSuccessModalOpen(true)
            } else {
              /**
               * booking returns res with eligibility queried from payout service
               * but, bookingPayment updateOne returns eligibility without consulting payout service
               */
              setBookingPayment({ ...bookingPaymentResult, eligibility })
              navigate(routes.Root.routes.PayInMethod.path)
            }
          },
        }}
      >
        {({ isLoading, isSuccess }) => (
          <Flex flexDirection='column'>
            {contributions.map((c) => (
              <Contribution key={c.userId} canChangeContribution={canChangeContribution} contribution={c} />
            ))}

            <Divider />

            <Flex mt={2} alignItems='center' justifyContent='space-between'>
              <H4>{t('remainingAmount')}</H4>
              <P>{money.fromAmount(remainingRentAmount, currency).toString()}</P>
            </Flex>

            {bookingPaymentWaived && <PSmall color='info'>{t('arrangeToPayOffline')}</PSmall>}
            {!isSingleTenant && amount === 0 && <PSmall color='info'>{t('dontNeedPayInMethod')}</PSmall>}
            {isSingleTenant && remainingRentAmount !== 0 && (
              <PSmall color='alert'>{t('remainingContributionShallBeZero')}</PSmall>
            )}

            {canChangeContribution && (
              <ButtonContainer>
                <ButtonWithLoader type='submit' isLoading={isLoading} disabled={isSuccess || !isValidContribution}>
                  {t('next')}
                </ButtonWithLoader>
              </ButtonContainer>
            )}
          </Flex>
        )}
      </ApiForm>
      <BookingPaymentUpdatedModal open={successModalOpen} />
    </>
  )
}

export { Contributions }
