import React from 'react'

import { Scope } from '@guiker/base-entity'
import { BookingDocument } from '@guiker/booking-shared'
import { DateTime } from '@guiker/date'
import { EnvelopeStatus, ParticipantDocumentStatus } from '@guiker/document-shared'
import { buildUrl } from '@guiker/url'

import { useBookingContext, useBookingDocumentJwtAuthenticatedApiClient, useConfig, useMutation } from '../../hooks'
import { useTranslation } from '../../i18n'
import { ButtonWithLoader, ColumnConfig, P } from '../'

type EnvelopeSummary = {
  name: string
  status: { signed: number; total: number }
  lastModified: string
  envelopeStatus: EnvelopeStatus
}

const documentDeliveredStatus = [EnvelopeStatus.SENT, EnvelopeStatus.COMPLETED]

export const getBookingDocumentColumns = ({
  scope: { id, type },
  accessControlToken: token,
  isApplicant = false,
}: {
  scope: Scope
  accessControlToken: string
  isApplicant?: boolean
}): ColumnConfig<EnvelopeSummary>[] => {
  const { t } = useTranslation(['common', 'main-bookingDocument'])
  const tPrefix = 'main-bookingDocument:screens.viewBookingDocuments'
  const tablePrefix = `${tPrefix}.columns`

  const { appBaseUrl } = useConfig()
  const { bookingChat } = useBookingContext()
  const jwtApiClient = useBookingDocumentJwtAuthenticatedApiClient()

  const redirectUrl = buildUrl({
    baseUrl: appBaseUrl,
    path: bookingChat.path,
  })

  const navigateToDocusign = (url: string) => {
    window.top.location.href = url
  }

  const { mutate: fetchAndNavigateToDocusignUrl, isLoading } = useMutation(
    ({ scopeType, scopeId }: { scopeType: string; scopeId: string }) =>
      jwtApiClient.generateDocusignLink({
        pathParams: { scopeType, scopeId },
        accessControl: { token },
        payload: { redirectUrl },
      }),
    {
      onSuccess: (res) => {
        navigateToDocusign(res)
      },
    },
  )

  return [
    {
      label: t(`${tablePrefix}.headers.subject`),
      name: 'name',
      size: 1.5,
      renderValue: ({ name }) => <P mb={0}>{name}</P>,
    },
    {
      label: t(`${tablePrefix}.headers.status`),
      name: 'status',
      size: 1,
      renderValue: ({ status: { signed, total }, envelopeStatus }) => (
        <P mb={0}>
          {isApplicant
            ? t(`${tablePrefix}.body.documentStatus`, { signed, total })
            : t(`${tablePrefix}.body.${envelopeStatus.toLowerCase()}`)}
        </P>
      ),
    },
    {
      label: t(`${tablePrefix}.headers.lastModified`),
      name: 'lastModified',
      size: 1.2,
      renderValue: ({ lastModified }) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const parsedDate = DateTime.fromJSDate(new Date(lastModified), { zone: timeZone })
        return (
          <P mb={0}>
            {`${parsedDate.toFormat('yyyy-MM-dd')}
              ${parsedDate.toLocaleString({
                hour: '2-digit',
                minute: '2-digit',
                timeZone,
              })}`}
          </P>
        )
      },
    },
    {
      label: t(`${tablePrefix}.headers.action`),
      name: 'actions',
      size: 1,
      renderValue: ({ envelopeStatus }) => (
        <ButtonWithLoader
          onClick={() => fetchAndNavigateToDocusignUrl({ scopeId: id, scopeType: type })}
          isLoading={isLoading}
          disabled={isApplicant && !documentDeliveredStatus.includes(envelopeStatus)}
        >
          {t('main-bookingDocument:actions.viewDocuments')}
        </ButtonWithLoader>
      ),
    },
  ]
}

export const parseBookingDocumentColumns = (bookingEnvelope: BookingDocument[], applicantId?: string) => {
  return bookingEnvelope.map((doc) => {
    const status = { signed: 0, total: 0 }
    if (applicantId) {
      const participant = doc.participants.find((user) => user.userId === applicantId)
      participant.documents.forEach((participantDoc) => {
        participantDoc.status === ParticipantDocumentStatus.COMPLETED && status.signed++
        status.total++
      })
    } else {
      for (const participant of doc.participants) {
        const completedDocument = participant.documents.every(
          (document) => document.status === ParticipantDocumentStatus.COMPLETED,
        )
        completedDocument && status.signed++
        status.total++
      }
    }

    return {
      name: doc.envelopeId,
      status,
      lastModified: doc.updatedAt,
      envelopeStatus: doc.status,
    }
  })
}
