import React from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { CREDIT_CARD_TRANSACTION_RATE, FundingAccount, typeChecker } from '@guiker/payment-shared'
import { money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { Flex, P, PBold } from '..'

type DepositModalProps = {
  amount: number
  fundingAccount: FundingAccount
}

const Breakdown: React.FC<DepositModalProps> = ({ amount, fundingAccount }) => {
  const { tMain } = useT({ screenName: 'components.depositModal' })
  const { selected } = usePayInMethodContext()

  const hasFees = selected && typeChecker.isCreditCard(selected)

  return (
    <Flex flexDirection='column' gap={1}>
      {hasFees && (
        <>
          <Flex justifyContent='space-between' alignItems='center'>
            <P mb={0}>{tMain('amount')}</P>
            <P mb={0}>{money.fromAmount(amount, fundingAccount.currency).toString()}</P>
          </Flex>
          <Flex justifyContent='space-between' alignItems='center'>
            <P mb={0}>
              {typeChecker.isCreditCard(selected)
                ? tMain('processingFee', {
                    processingFeePercentage: `${CREDIT_CARD_TRANSACTION_RATE * 100}%`,
                  })
                : tMain('noProcessingFee')}
            </P>
            <P mb={0}>{money.fromAmount(amount * CREDIT_CARD_TRANSACTION_RATE, fundingAccount.currency).toString()}</P>
          </Flex>
        </>
      )}
      <Flex justifyContent='space-between' alignItems='center' pt={1}>
        <PBold mb={0}>{tMain('total')}</PBold>
        <P mb={0}>
          {money
            .fromAmount(
              amount * (selected && typeChecker.isCreditCard(selected) ? CREDIT_CARD_TRANSACTION_RATE + 1 : 1),
              fundingAccount.currency,
            )
            .toString()}
        </P>
      </Flex>
    </Flex>
  )
}

export { Breakdown }
