import React from 'react'

import { AuthenticatedPropSharingStockOrderIdNestedRouter } from '@guiker/propsharing-context'
import { useParams } from '@guiker/router'

const StockOrderIdNestedRouter: React.FC = () => {
  const { id } = useParams()
  return <AuthenticatedPropSharingStockOrderIdNestedRouter id={id} />
}

export { StockOrderIdNestedRouter }
