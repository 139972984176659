import React from 'react'

import { OntarioSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useOntarioLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepI: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()
  const {
    leaseId,
    lease: {
      leaseInformation: { lessors, lessees },
    },
    draftedAs,
  } = useOntarioLeaseContext()
  const lessor = lessors[0]

  const onSubmit = (payload: OntarioSchemas.LessorSignature) =>
    apiClient.updateOntarioLeaseLessorSignature({
      pathParams: { leaseId },
      payload: {
        ...payload,
        draftedAs,
      },
    })

  return (
    <Step
      hasBackButton={true}
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.lessorSignature, {
          defaultValues: { draftedAs, signature: null },
        }),
        defaultValues: {
          emailAddress: lessor.emailAddress,
          signature: lessor.signature,
        },
      }}
      formName={'OntarioLeaseFormStepI'}
      {...props}
    >
      <FormContent lessors={lessors} lessees={lessees} />
    </Step>
  )
}
