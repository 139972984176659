import React from 'react'

import { Payout } from '@guiker/payout-shared'
import { ActivityTracker } from '@guiker/react-framework'
import { Event } from '@guiker/shared-framework'

import { usePayoutContext } from '../../context'
import { useTransformPayoutEventsToActivityItems } from './utils'

type PayoutActivityLogProps = {
  events: Event<Payout>[]
}

export const PayoutActivityLog: React.FC<PayoutActivityLogProps> = ({ events }) => {
  const { isPaidOffline } = usePayoutContext()
  const items = useTransformPayoutEventsToActivityItems({ events, isPaidOffline })

  return <ActivityTracker items={items} />
}
