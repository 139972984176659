import React, { useMemo } from 'react'

import { Listing as BaseListing } from '@guiker/base-entity'
import {
  AirconditionerIcon,
  BalconyIcon,
  Box,
  CheckmarkMiniIcon,
  ColumnGridLayout,
  DishwasherIcon,
  Flex,
  FridgeIcon,
  OvenIcon,
  PBold,
  PetIcon,
  useTranslation,
  WasherDryerIcon,
} from '@guiker/react-framework'

import { RentalOptionInfoLabel } from './RentalOptionInfoLabel'

const Appliances: React.FC<{
  appliances: BaseListing.UnitAppliances<boolean> & BaseListing.KitchenAppliances<boolean>
}> = ({ appliances }) => {
  const { t } = useTranslation(['common-rentalListing'])
  const applianceInfos = [
    { adornment: AirconditionerIcon, label: 'airConditioner' },
    { adornment: BalconyIcon, label: 'balcony' },
    { adornment: DishwasherIcon, label: 'dishwasher' },
    { adornment: FridgeIcon, label: 'fridge' },
    { adornment: OvenIcon, label: 'oven' },
    { adornment: PetIcon, label: 'petsAllowedSmall' },
    { adornment: PetIcon, label: 'petsAllowedLarge' },
    { adornment: WasherDryerIcon, label: 'washer' },
  ]

  const memoizedAppliances = useMemo(() => {
    const filtered = {
      available: applianceInfos.filter(({ label }) => appliances[label]),
      unavailable: applianceInfos.filter(({ label }) => !appliances[label]),
    }
    const orderedAppliances = filtered.available.concat(filtered.unavailable)

    return orderedAppliances.map((appliance, index) => (
      <Flex alignItems='center' key={index}>
        <RentalOptionInfoLabel
          adornment={CheckmarkMiniIcon}
          label={t(`appliances.${appliance.label}`)}
          isAvailable={appliances[appliance.label]}
        />
      </Flex>
    ))
  }, [appliances])

  return (
    <Box mt={2}>
      <PBold mb={2}>{t('appliances.title')}</PBold>
      <ColumnGridLayout
        gap={2}
        gridColumn={{ start: 'auto', span: 1 }}
        gridTemplateColumns={3}
        xs={{ gridTemplateColumns: 1, gridColumn: { span: 1 } }}
      >
        {memoizedAppliances}
      </ColumnGridLayout>
    </Box>
  )
}

export { Appliances }
