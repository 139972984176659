import React from 'react'

import { PayInMethodLabel } from '@guiker/payment-app-components'
import { PayInMethod } from '@guiker/payment-shared'
import { Flex, Note, P, PBold, useT } from '@guiker/react-framework'
import {
  RentPaymentsPlanStatus,
  RentPaymentsPlanWithTenantInstalments,
  TenantInstalmentStatus,
} from '@guiker/rent-payment-shared'
import { compareDate, DateTime, last } from '@guiker/shared-framework'

import { ChangeContributionLink } from '../ChangeContribution'
import { ChangePaymentMethodLink } from '../ChangePaymentMethodLink'

type Props = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payInMethod: PayInMethod
}

export const PayInMethodInfo: React.FC<Props> = ({ rentPaymentsPlan, payInMethod }) => {
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'screens.rentPayment' })

  const tenantInstalments = rentPaymentsPlan.tenantInstalments.filter((t) => !t.processedWith)
  const today = DateTime.local().toFormat('yyyy-MM-dd')
  const nextChargeDate = tenantInstalments.find(({ chargeDate }) => today < chargeDate)?.chargeDate || new Date()
  const { days } = compareDate(nextChargeDate).getDifference(today, 'days')

  const hasLastInstalmentCompleted =
    last(rentPaymentsPlan.tenantInstalments)?.status === TenantInstalmentStatus.completed
  const isTerminated = rentPaymentsPlan.status === RentPaymentsPlanStatus.TERMINATED

  if (isTerminated || hasLastInstalmentCompleted) {
    return (
      <Note statusColor='alabaster' title={tMain('terminatedNote.title')}>
        <P>{tMain('terminatedNote.description')}</P>
      </Note>
    )
  }

  return (
    <Flex flexDirection='column' gap={2}>
      {days <= 7 && <PBold mb={0}>{tMain(`dueDays`, { days })}</PBold>}
      {payInMethod && <PayInMethodLabel payInMethod={payInMethod} />}
      <ChangePaymentMethodLink hasPayInMethod={!!payInMethod} />
      <ChangeContributionLink />
    </Flex>
  )
}
