import React from 'react'

import { Inquiry } from '@guiker/investment-assumption-components'

import { useListingScreenContext } from '../../context'

export const FinancingAssumptions: React.FC = () => {
  const { listing } = useListingScreenContext()

  return <Inquiry.FinancingAssumptions name='assumptions.financing' defaultValue={listing} />
}
