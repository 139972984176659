import React from 'react'

import { Trans } from '@guiker/i18n'
import { MessagesBubbleIcon } from '@guiker/icons'

import { useTranslation } from '../../../hooks'
import { makeStyles, theme } from '../../../style'
import { Box, Note, P, PBold, Typography } from '../../'

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginBottomZero: {
    marginBottom: 0,
  },
})

export const Text: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])

  return (
    <Note statusColor='info' title={t('common:note')} icon={<MessagesBubbleIcon />}>
      <Box pt={1}>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenE:text1'>
            <Typography color='info' component='span' />
          </Trans>
        </P>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenE:text2'>
            <Typography color='info' component='span' />
            <PBold component='span' />
          </Trans>
        </P>
        <PBold className={classes.marginBottom}>{t('quebecLeaseScreenE:text3')}</PBold>
        <P className={classes.marginBottom}>
          <Trans i18nKey='quebecLeaseScreenE:text4'>
            <Typography color='info' component='span' />
          </Trans>
        </P>
        <PBold className={classes.marginBottom}>{t('quebecLeaseScreenE:text5')}</PBold>
        <P className={classes.marginBottomZero}>
          <Trans i18nKey='quebecLeaseScreenE:text6'>
            <Typography color='info' component='span' />
          </Trans>
        </P>
      </Box>
    </Note>
  )
}
