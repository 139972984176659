import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  return (
    <Routes>
      <Route {...routes.Home} {...props} />
      <Route {...routes.Category} {...props} />
      <Route {...routes.Article} {...props} />
    </Routes>
  )
}

export { Router }
