import React from 'react'

import { getLocationService } from '@guiker/base-entity'
import { Box, Button, Flex, H1, H2, H3, makeStyles, P, PageLayout, PageMetaTags, theme } from '@guiker/react-framework'
import { LivingSection, PaginatedListingsGrid } from '@guiker/rental-listing-components'
import { upperFirst } from '@guiker/shared-framework'
import { useStaticAssetContext } from '@guiker/static-asset-context'

import { EmptyState, HeroSection, NeighbourhoodComponent } from '../../components'
import { usePaginationQueryParams, useRecommendedListingApiClient } from '../../hooks'
import {
  getLocalizedCity,
  getLocalizedCountry,
  getLocalizedNeighbourhood,
  getLocalizedState,
  useTranslation,
} from '../../i18n'
import { useLocationFromPath } from '../../services'
import { getNeighbourhoodBreadcrumbItemsFromLocation } from '../../utils/breadcrumb-helper'

const useStyle = makeStyles(
  {
    background: {
      backgroundColor: 'white',
    },
    h2: {
      ...theme.typography.variants.h2,
    },
    pBig: {
      ...theme.typography.variants.bodyBig,
    },
  },
  { name: 'ListingCityScreen' },
)

const NeighbourhoodScreen: React.FC = () => {
  const classes = useStyle()
  const location = useLocationFromPath()
  const { city, country, neighbourhood, state } = location
  const apiClient = useRecommendedListingApiClient()
  const { t } = useTranslation(['common', 'main-rentalListing'])
  const { page } = usePaginationQueryParams()

  const locationService = getLocationService()
  const cityLabel = getLocalizedCity(t, { countryCode: country.countryCode, citySlug: city.slug })
  const stateLabel = getLocalizedState(t, { countryCode: country.countryCode, stateCode: state.stateCode })
  const contryLabel = getLocalizedCountry(t, { countryCode: country.countryCode })
  const neighbourhoodLabel = getLocalizedNeighbourhood(t, {
    countryCode: country.countryCode,
    citySlug: city.slug,
    neighbourhoodSlug: neighbourhood.slug,
  })

  const seoI18nBase = `main-rentalListing:neighbourhoodScreen.seo.${country.countryCode}.${state.stateCode}.${city.slug}.neighbourhoods.${neighbourhood.slug}`
  const baseDescription = t(`${seoI18nBase}.description`)
  const keywords = t(`${seoI18nBase}.keywords`)

  const fetcher = ({ page, perPage }: { page: number; perPage: number }) =>
    apiClient.readAllRecommendedListingPerNeighbourhood({
      pathParams: { cityId: city.slug, neighbourhoodSlug: neighbourhood.slug },
      queryParams: { page, perPage },
    })

  const { getAsset } = useStaticAssetContext()
  const home = getAsset('home')
  const showAllSections = !locationService.newCities.includes(city.slug)

  return (
    <div className={classes.background}>
      <PageMetaTags
        subtitle={t('main-rentalListing:neighbourhoodScreen.seo.subtitle', {
          page,
          city: cityLabel,
          neighbourhood: neighbourhoodLabel,
          state: stateLabel,
        })}
        description={`${baseDescription || neighbourhoodLabel} - ${page}`}
        keywords={keywords.split(',')}
      />
      {showAllSections && <HeroSection backgroundUrl={home.v1.explore[city.slug]} />}
      <PageLayout breadcrumbItems={getNeighbourhoodBreadcrumbItemsFromLocation(t, location)}>
        {showAllSections && (
          <Flex flexDirection='column' alignItems='center' mb={8}>
            <H1 mb={2} className={classes.h2}>
              {neighbourhoodLabel}
            </H1>
            <P color={60} textAlign='center'>
              {t('main-rentalListing:neighbourhoodScreen.findRentals.title', {
                cityName: cityLabel.toLocaleUpperCase(),
                stateName: stateLabel.toLocaleUpperCase(),
                countryName: contryLabel.toLocaleUpperCase(),
              })}
            </P>
            <H2 textAlign='center' mb={6} className={classes.pBig}>
              {keywords
                .split(',')
                .map((s) => upperFirst(s.trim()))
                .join(' / ')}
            </H2>
            <Button
              onClick={() =>
                document.getElementById('neighbourhoodPageScrollButton').scrollIntoView({ behavior: 'smooth' })
              }
            >
              {t('main-rentalListing:neighbourhoodScreen.findRentals.button')}
            </Button>
          </Flex>
        )}
        {showAllSections && (
          <Flex mt={2} mb={8}>
            <LivingSection location={location} isNeighbourhood={true} tPrefix={seoI18nBase} />
          </Flex>
        )}
        <H3 id={'neighbourhoodPageScrollButton'}>
          {t(`main-rentalListing:neighbourhoodScreen.listings.title`, { neighbourhoodName: neighbourhoodLabel })}
        </H3>
        <Box mt={2} mb={10}>
          <PaginatedListingsGrid
            cacheKey={['readAllRecommendedListingPerNeighbourhood', city.slug, neighbourhood.slug]}
            fetcher={fetcher}
            emptyState={<EmptyState />}
            withLink={false}
          />
        </Box>
        <NeighbourhoodComponent
          city={city.slug}
          country={country.countryCode}
          state={state.stateCode}
          otherNeighbourhoodTitle={true}
        />
      </PageLayout>
    </div>
  )
}

export { NeighbourhoodScreen }
