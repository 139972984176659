import React from 'react'

import { PageMetaTags } from '@guiker/react-framework'
import { usePaginationQueryParams } from '@guiker/router'

import { FullScreenSpinner, PageLayout, PaginatedArticleGrid } from '../../components'
import { useQuery, useTranslation } from '../../hooks'
import { getApiClient } from '../../utils'

const BlogHome: React.FC = () => {
  const { t } = useTranslation()
  const { page } = usePaginationQueryParams()

  const { data: response, isLoading: articlesAreLoading } = useQuery(['readAllArticles', page], () =>
    getApiClient().readAllArticles({ queryParams: { page } }),
  )

  if (articlesAreLoading) {
    return <FullScreenSpinner />
  }

  return (
    <PageLayout>
      <PageMetaTags subtitle={t('main-blog:subtitle')} />
      <PaginatedArticleGrid
        articles={response?.body}
        page={page}
        totalPage={parseInt(response?.headers['x-wp-totalpages'])}
      />
    </PageLayout>
  )
}

export { BlogHome }
