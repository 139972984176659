import { useContext } from 'react'

import { Quebec } from '@guiker/lease-shared'

import { LeaseContext } from '../context'

export const useQuebecLeaseContext = () => {
  const context = useContext(LeaseContext)

  if (context === undefined) {
    throw new Error('useQuebecLeaseContext can only be used inside LeaseContextProvider')
  }

  if (context.lease && context.lease.type !== 'canada-quebec') {
    throw new Error('useQuebecLeaseContext can only be used for a "canada-quebec" Lease')
  }

  return {
    ...context,
    lease: context.lease as Quebec.Lease,
  }
}
