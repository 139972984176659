import React, { createContext, useEffect, useState } from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import {
  AuthenticatedApi,
  ListingInquiry,
  ListingInquirySource,
  ListingInquiryStatus,
} from '@guiker/listing-inquiry-shared'
import {
  FullScreenSpinner,
  generateUseContext,
  UseMutateFunction,
  useMutation,
  useQuery,
  useT,
} from '@guiker/react-framework'
import { formatInquiryMessage } from '@guiker/rental-listing-inquiry-context'
import {
  extractAsLeadOption,
  initializeDropdownRentalOptions,
  LeadOption,
  RentalListing,
  TransformedRentalOption,
} from '@guiker/rental-listing-shared'
import { stringToNumber } from '@guiker/shared-framework'

import { useAuthenticationContext, useListingInquiryApiClient, useNavigate, usePublicListingContext } from '../hooks'

type AppContextType = {
  toggleConfirm: () => void
  listing: RentalListing
  listingId: string
  isConfirmed: boolean
  isLoading: boolean
  selectedOption: LeadOption
  rentalOptions: TransformedRentalOption[]
  setSelectedOptionId: (priceOptionId: string) => void
  selectedOptionId: string
  listingInquiry: ListingInquiry
  setRentalOptions: React.Dispatch<React.SetStateAction<TransformedRentalOption[]>>
  updateListingInquiry: UseMutateFunction<ListingInquiry, unknown, undefined, unknown>
  createListingInquiry: UseMutateFunction<ListingInquiry, unknown, undefined, unknown>
}

export const ConfirmListingInquiryScreenContext = createContext<AppContextType>(null)

export const ConfirmListingInquiryScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useT({ domain: 'rentalListingInquiry' })
  const { data: listing, listingId } = usePublicListingContext()
  const { user } = useAuthenticationContext()
  const listingInquiryApiClient = useListingInquiryApiClient()
  const navigate = useNavigate()

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [listingInquiry, setListingInquiry] = useState<ListingInquiry>(null)
  const [selectedOptionId, setSelectedOptionId] = useState<string>(
    listing?.leadOption?.priceOption?.legacyOptionId?.toString(),
  )
  const [rentalOptions, setRentalOptions] = useState<TransformedRentalOption[]>(
    initializeDropdownRentalOptions(listing),
  )
  const [selectedOption, setSelectedOption] = useState<LeadOption>(
    extractAsLeadOption(listing, listing?.leadOption?.priceOption?.legacyOptionId),
  )

  useEffect(() => {
    if (selectedOptionId !== selectedOption.priceOption.legacyOptionId.toString()) {
      const rentalOption = extractAsLeadOption(listing, stringToNumber(selectedOptionId))
      setSelectedOption(rentalOption)
      setSelectedOptionId(rentalOption.priceOption?.legacyOptionId?.toString())
    }
  }, [selectedOptionId])

  const toggleConfirm = () => setIsConfirmed(!isConfirmed)

  const { data: fetchedData, isFetching } = useQuery(
    ['listing-inquiry', listing?.id],
    () => listingInquiryApiClient.readLatestListingInquiry({ pathParams: { listingId: listing.id } }),
    {
      retry: 1,
      enabled: !!listing?.id,
      onSuccess: (data) => {
        if (data && data.status === ListingInquiryStatus.STARTED) {
          setIsConfirmed(true)
          setSelectedOptionId(data.rentalOptionId)
          setListingInquiry(data)
        } else if (data && data.status === ListingInquiryStatus.COMPLETED) {
          navigate(mainPathBuilder.root.messages.path())
        }
      },
    },
  )

  const { mutate: createListingInquiry, isLoading: isCreatingListingInquiry } = useMutation(
    () => {
      return !listingInquiry
        ? listingInquiryApiClient.createListingInquiry({
            payload: {
              rentalOptionId: selectedOptionId,
              listingId: listing?.id,
              user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                emailAddress: user.emailAddress,
                phone: { country: undefined, number: undefined },
              },
              isBookingNotificationEnabled: undefined,
              visitSchedules: [],
              moveInDate: undefined,
              message: undefined,
              status: ListingInquiryStatus.STARTED,
              source: ListingInquirySource.MULTIPOSTING,
            },
          })
        : listingInquiryApiClient.updateListingInquiryOptionId({
            pathParams: {
              id: listingInquiry.id,
            },
            payload: {
              rentalOptionId: selectedOptionId,
              listingId: listing?.id,
            },
          })
    },
    {
      onSuccess: (data) => {
        setSelectedOption(extractAsLeadOption(listing, Number(data.rentalOptionId)))
        setSelectedOptionId(data.rentalOptionId)
        setListingInquiry(data)
        toggleConfirm()
      },
    },
  )

  const { mutate: updateListingInquiry, isLoading: isUpdating } = useMutation(
    async (args: AuthenticatedApi.Schema.BookVisitSchema) => {
      const { visitSchedules, message, firstName, lastName, phone } = args
      const formattedMessage = formatInquiryMessage({
        t,
        listing,
        phone: phone.number,
        visitSchedules,
        message,
        rentalOptionId: selectedOptionId,
      })
      return listingInquiryApiClient.updateListingInquiry({
        pathParams: { listingInquiryId: listingInquiry.id },
        payload: {
          ...listingInquiry,
          user: {
            id: user.id,
            firstName: firstName || user.firstName,
            lastName: lastName || user.lastName,
            emailAddress: user.emailAddress,
            phone: phone,
          },
          visitSchedules,
          moveInDate: '',
          isBookingNotificationEnabled: args.isBookingNotificationEnabled ?? true,
          message: formattedMessage,
          rentalOptionId: selectedOptionId,
          status: ListingInquiryStatus.COMPLETED,
          source: ListingInquirySource.MULTIPOSTING,
        },
      })
    },
    {
      onSuccess: () => {
        navigate(`/tenant-application/preapplication`)
      },
    },
  )

  const value = {
    isConfirmed,
    toggleConfirm,
    listing,
    listingId,
    selectedOption,
    isLoading: isCreatingListingInquiry || isUpdating,
    listingInquiry,
    updateListingInquiry,
    createListingInquiry,
    rentalOptions,
    selectedOptionId,
    setSelectedOptionId,
    setRentalOptions,
  }

  if (isFetching || fetchedData?.status === ListingInquiryStatus.COMPLETED) {
    return <FullScreenSpinner />
  }

  return (
    <ConfirmListingInquiryScreenContext.Provider value={value}>{children}</ConfirmListingInquiryScreenContext.Provider>
  )
}

export const useConfirmListingInquiryScreenContext = generateUseContext(ConfirmListingInquiryScreenContext)
