import React from 'react'

import { getApplicationStatusAfterSubmit } from '@guiker/booking-components'
import { yupResolver } from '@guiker/react-hook-form'
import { MyProfileFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, TenantApplication } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useBookingApplicationContext } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '..'

export type MyProfileStepStepProps = StepProps

const MyProfileStep: React.FC<MyProfileStepStepProps> = ({ onClickNext, ...props }) => {
  const { isLoading, bookingApplication, setBookingApplication } = useBookingApplicationContext()

  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const handleSubmit = (payload: AuthenticatedApi.Schema.UpdateTenantApplicationProfilePayload) =>
    apiClient.updateProfile({ payload })

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      formName='BookingApplicationProfileStep'
      formOptions={{
        resolver: yupResolver(AuthenticatedApi.Schema.updateTenantApplicationProfilePayloadValidator),
        defaultValues: bookingApplication?.content as TenantApplication,
      }}
      onSubmit={handleSubmit}
      apiOptions={{
        onSuccess: (res) => {
          setBookingApplication({
            ...bookingApplication,
            content: res,
            status: getApplicationStatusAfterSubmit(bookingApplication?.status),
          })
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { errors, isSubmitting } }) => (
        <Step isSubmitting={isSubmitting} errors={errors} hasBackButton={false} {...props}>
          <MyProfileFormContent
            tenantApplication={bookingApplication?.content as TenantApplication}
            schema={AuthenticatedApi.Schema.updateTenantApplicationProfilePayloadValidator}
          />
        </Step>
      )}
    </ApiForm>
  )
}

export { MyProfileStep }
