import React, { createContext, useMemo, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import {
  InvestmentAssumptionContextProvider,
  useInvestmentAssumptionContext,
} from '@guiker/investment-assumption-components'
import { PayInMethod } from '@guiker/payment-shared'
import {
  getListingHelpers,
  InvestorProfile,
  PropSharingInquiry,
  PropSharingListing,
  PropSharingListingWithInquiry,
  StockAllowance,
  WithFundingData,
  WithInquiry,
  WithStockLatestFinancialStatements,
} from '@guiker/propsharing-shared'
import { FullScreenSpinner, generateUseContext, useModal, useQuery } from '@guiker/react-framework'
import { ProjectWithDeveloper } from '@guiker/real-estate-shared'
import { Currency, currency as baseCurrency } from '@guiker/shared-framework'

import {
  useAuthApiClient,
  useAuthenticatedPropSharingListingIdContext,
  useInvestorProfileContext,
  usePayInMethodContext,
  usePublicApiClient,
  usePublicPropSharingListingIdContext,
  usePublicRealEstateApiClient,
} from '../hooks'

type ScreenContextType = {
  currency: Currency
  listing: WithFundingData<WithStockLatestFinancialStatements<WithInquiry<PropSharingListing> | PropSharingListing>>
  project: ProjectWithDeveloper
  investmentAssumptions: InvestmentAssumptions.Assumptions
  investmentResults: InvestmentAssumptions.Results
  investorProfile: InvestorProfile
  refetchInvestorProfile: () => void
  payInMethod: PayInMethod
  inquiry: PropSharingInquiry
  setInquiry: (data: PropSharingInquiry) => void
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
  refetchListing: () => Promise<unknown>
  stockAllowance: StockAllowance
  refetchStockAllowance: () => Promise<unknown>
  helpers: ReturnType<typeof getListingHelpers>
  handleNote: ({ title, content }: { title: string; content: string }) => void
  isNoteOpen: boolean
  noteContent: { title: string; content: string }
}

export const ListingScreenContext = createContext<ScreenContextType>(null)

type WrappedListingScreenContextProviderProps = React.PropsWithChildren & {
  listing: WithFundingData<WithStockLatestFinancialStatements<PropSharingListingWithInquiry | PropSharingListing>>
  refetchStockAllowance?: () => Promise<unknown>
  refetchListing: () => Promise<unknown>
  stockAllowance?: StockAllowance
}

export const WrappedListingScreenContextProvider: React.FC<WrappedListingScreenContextProviderProps> = ({
  children,
  listing,
  stockAllowance,
  refetchListing,
  refetchStockAllowance,
}) => {
  const [inquiry, setInquiry] = useState<PropSharingInquiry>()
  const { investorProfile, refetchInvestorProfile } = useInvestorProfileContext()

  const { selected: payInMethod } = usePayInMethodContext()
  const realEstateApiClient = usePublicRealEstateApiClient()
  const { investmentResults, investmentAssumptions, currency } = useInvestmentAssumptionContext()
  const { isOpen: isModalOpen, openModal, closeModal } = useModal(false)
  const { isOpen: isNoteOpen, openModal: openNote, closeModal: closeNote } = useModal()
  const [noteContent, setNoteContent] = useState({ title: undefined, content: undefined })

  const { data: project } = useQuery(
    `project-${listing.id}`,
    (_) =>
      realEstateApiClient.readOneProject({
        pathParams: {
          id: listing?.property?.building?.construction?.projectId,
        },
      }),
    {
      retry: 1,
      enabled: !!listing && !!listing.property?.building?.construction?.projectId,
    },
  )

  const helpers = useMemo(() => listing && getListingHelpers(listing, stockAllowance), [listing, stockAllowance])

  const handleNote = ({ title, content }: { title: string; content: string }) => {
    setNoteContent({ title, content })
    isNoteOpen ? closeNote() : openNote()
  }

  const value = {
    handleNote,
    isNoteOpen,
    noteContent,
    currency,
    investmentAssumptions,
    investorProfile,
    refetchInvestorProfile,
    inquiry,
    setInquiry,
    listing,
    project,
    investmentResults,
    isModalOpen,
    openModal,
    closeModal,
    refetchListing,
    payInMethod,
    stockAllowance,
    refetchStockAllowance,
    helpers,
  }

  return <ListingScreenContext.Provider value={value}>{children}</ListingScreenContext.Provider>
}

export const ListingScreenContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { user } = useAuthenticationContext()

  if (user) {
    return <AuthListingScreenContextProviderWrapper {...props} />
  } else {
    return <PublicListingScreenContextProvider {...props} />
  }
}

const AuthListingScreenContextProviderWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const propSharingApiClient = useAuthApiClient()
  const { data: listing, refetch: refetchListing } = useAuthenticatedPropSharingListingIdContext({
    shouldThrowIfUndefined: false,
  })

  const { data: stockAllowance, refetch: refetchStockAllowance } = useQuery(
    'investor-profile-stock-allowance',
    (_) =>
      propSharingApiClient
        .getInvestorProfileAllowanceForStock({
          pathParams: {
            stockId: listing?.stock?.id,
          },
        })
        .then((res) => res.stockAllowance),
    {
      retry: 1,
      enabled: !!listing,
    },
  )

  const currency = baseCurrency[listing?.stock?.currency]

  if (!listing || !stockAllowance) {
    return <FullScreenSpinner />
  }

  return (
    <InvestmentAssumptionContextProvider assumptions={listing?.assumptions} currency={currency}>
      <WrappedListingScreenContextProvider
        listing={listing}
        stockAllowance={stockAllowance}
        refetchListing={refetchListing}
        refetchStockAllowance={refetchStockAllowance}
      >
        {children}
      </WrappedListingScreenContextProvider>
    </InvestmentAssumptionContextProvider>
  )
}

const PublicListingScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: listing, refetch: refetchListing } = usePublicPropSharingListingIdContext({
    shouldThrowIfUndefined: false,
  })
  const propSharingApiClient = usePublicApiClient()

  const currency = baseCurrency[listing?.stock?.currency]

  const { data: stockAllowance, refetch: refetchStockAllowance } = useQuery(
    'investor-profile-stock-allowance',
    (_) =>
      propSharingApiClient.getAllowanceForStock({
        pathParams: {
          stockId: listing?.stock?.id,
        },
      }),
    {
      retry: 1,
      enabled: !!listing?.stock?.id,
    },
  )

  if (!listing) {
    return <FullScreenSpinner />
  }

  return (
    <InvestmentAssumptionContextProvider assumptions={listing?.assumptions} currency={currency}>
      <WrappedListingScreenContextProvider
        listing={listing}
        refetchListing={refetchListing}
        refetchStockAllowance={refetchStockAllowance}
        stockAllowance={stockAllowance}
      >
        {children}
      </WrappedListingScreenContextProvider>
    </InvestmentAssumptionContextProvider>
  )
}

export const useListingScreenContext = generateUseContext(ListingScreenContext)
