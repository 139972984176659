import { compact, CountryCode } from '@guiker/shared-framework'
import { getRegion, UserMetadata } from '@guiker/user-metadata-shared'

import { useT } from '../i18n'

export const useSideMenuItems = (metadata: Omit<UserMetadata, 'id' | 'userId'>) => {
  const { tMain } = useT()
  const region = getRegion(metadata)

  const menu = [
    {
      text: tMain('screens.dashboard.title'),
      href: '/my-investments/investment-dashboard',
    },
    {
      text: tMain('sideMenu.navItem.listings'),
      href: '/my-investments/rental-listings',
    },
    region === CountryCode.CA
      ? {
          text: tMain('sideMenu.navItem.tenants'),
          href: '/my-investments/tenants',
        }
      : undefined,
  ]

  return compact(menu)
}
