import React from 'react'

import { hasTwoOrMoreAttributes, KpiGrid, UnitConfig } from '@guiker/base-listing-components'
import { DisplayBody, ExpandableFadingBlock, Flex, PageSection2 } from '@guiker/react-framework'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'
import { buildKpiGrid } from './utils'

export const PropertyDescription: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen' })
  const { listing } = useListingScreenContext()

  return (
    <Flex flexDirection='column' gap={8}>
      <KpiGrid items={buildKpiGrid()} />
      {listing?.description && (
        <PageSection2 title={tMain(`tab1.sections.description.title`)} hasDivider={false}>
          {listing?.property && hasTwoOrMoreAttributes(listing.property) && <UnitConfig property={listing?.property} />}
          <ExpandableFadingBlock maxHeight={100}>
            <DisplayBody>{listing?.description}</DisplayBody>
          </ExpandableFadingBlock>
        </PageSection2>
      )}
    </Flex>
  )
}
