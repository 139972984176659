import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { computeInvoiceFees, computeInvoiceTotalAmount, transferTypeChecker } from '@guiker/payment-shared'
import { Payout, transferHelper } from '@guiker/payout-app-components'
import { TransferProjection, TransferScopeType } from '@guiker/payout-shared'
import { ColumnConfig, Flex, P, PSmall, PSmaller, TextButton, useNavigate, useT } from '@guiker/react-framework'
import { compact, money } from '@guiker/shared-framework'

type InvoiceTransfer = TransferProjection<TransferScopeType.INVOICE>

export const usePayoutTransferColumns = (): ColumnConfig<InvoiceTransfer>[] => {
  const { tBase, tShared } = useT({ domain: 'components', entity: 'statistic.rentCollectionPayout.table' })
  const navigate = useNavigate()

  const hasScopeData = (transfer: TransferProjection) => {
    return transferTypeChecker.isInvoice(transfer) && !!transfer.scope.data
  }

  return compact([
    {
      label: tShared('headers.payer'),
      name: 'payer',
      size: 1.4,
      renderValue: (transfer) => {
        if (!hasScopeData(transfer)) return <></>
        return (
          <Flex flexDirection='column'>
            <PSmall mb={0}>{transfer.info.payerName}</PSmall>
            <PSmaller mb={0}>{transfer.info.payerEmailAddress}</PSmaller>
          </Flex>
        )
      },
    },
    {
      label: tShared('headers.reason'),
      name: 'reason',
      size: 2,
      renderValue: (transfer) => {
        const isOtherPayments = !hasScopeData(transfer)
        const display = transferHelper.getInfoDisplay(transfer)
        const label = isOtherPayments ? tShared('transfers.otherPayments') : display.label

        return (
          <Flex flexDirection='column' pr={1}>
            <PSmall mb={0} color={isOtherPayments ? 60 : 'inherit'}>
              {label}
            </PSmall>
            {!!display.date && <PSmaller mb={0}>{display.date}</PSmaller>}
          </Flex>
        )
      },
    },
    {
      label: tShared('headers.status'),
      name: 'status',
      size: 0.4,
      renderValue: (transfer) => (hasScopeData(transfer) ? <Payout.TransferStatusChip transfer={transfer} /> : <></>),
    },
    {
      label: tShared('headers.rent'),
      name: 'rent',
      size: 1.0,
      headerOptions: { textAlign: 'end' },
      renderValue: (transfer) => {
        if (!hasScopeData(transfer)) return '-'
        const amount = computeInvoiceTotalAmount(transfer.scope.data)
        return money.fromAmount(amount, transfer.currency).toString('onlySymbol')
      },
    },
    {
      label: tShared('headers.fee'),
      name: 'fee',
      size: 0.8,
      headerOptions: { textAlign: 'end' },
      renderValue: (transfer) => {
        if (!hasScopeData(transfer)) return '-'
        const amount = computeInvoiceFees(transfer.scope.data)
        return money.fromAmount(amount, transfer.currency).toString('onlySymbol')
      },
    },
    {
      label: tShared('headers.receivable'),
      name: 'receivable',
      size: 1.0,
      headerOptions: { textAlign: 'end' },
      renderValue: (transfer) => {
        const { amount, color } = transferHelper.formatAmount(transfer)
        return (
          <P color={color} textAlign='right' mb={0}>
            {amount}
          </P>
        )
      },
    },
    {
      label: '',
      name: 'action',
      size: 0.5,
      sortable: false,
      headerOptions: { textAlign: 'end' },
      renderValue: (transfer) => {
        if (!hasScopeData(transfer)) return <></>
        const id = transfer.scope.data?.scope.id
        const path = mainPathBuilder.root.rentalTenancy.tenantInstalments.byId(id).path
        return (
          <TextButton size='small' onClick={() => navigate(path)}>
            <PSmaller mb={0}>{tBase('actions.view')}</PSmaller>
          </TextButton>
        )
      },
    },
  ])
}
