import React from 'react'

import { SearchBar } from '@guiker/search-components'
import { SearchableEntities } from '@guiker/search-shared'
import { Scope } from '@guiker/shared-framework'

import { useSearch } from '../../hooks'
import { useT } from '../../i18n'

type MonthlyPerformanceSearchBarProps = {
  searchableEntities?: SearchableEntities[]
  label?: string
  scope?: Scope
}

export const MonthlyPerformanceSearchBar: React.FC<MonthlyPerformanceSearchBarProps> = ({
  searchableEntities = [SearchableEntities.LISTING, SearchableEntities.LISTING_UNIT],
  label,
  scope,
}) => {
  const { tMain } = useT({ screenName: 'components.monthlyPerformanceSearchBar' })
  const { useMutationPerformSearch } = useSearch({
    entities: searchableEntities,
    scope,
  })

  return (
    <SearchBar
      showSeeAll={false}
      withAdornment={false}
      menuMaxWidth={450}
      textFieldProps={{ maxWidth: 340, label: label ?? tMain('label'), condensed: true }}
      useMutationPerformSearch={useMutationPerformSearch}
      anchorOriginHorizontal={'right'}
    />
  )
}
