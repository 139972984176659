import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { Paginate } from '@guiker/paginate'
import { ViewPaymentMethod } from '@guiker/payment-app-components'
import { payInMethodInfoBuilder } from '@guiker/payment-shared'
import { Flex, FullScreenSpinner, useParams, useQuery } from '@guiker/react-framework'

import { usePaymentApiClient } from '../../hooks'
import { useT } from '../../i18n'

const PaymentMethodScreen: React.FC = ({}) => {
  const { tMain: tNavigation } = useT({ screenName: 'sideMenu.navItem' })
  const { id } = useParams()
  const apiClient = usePaymentApiClient()

  const { data: paymentMethod, isLoading } = useQuery(
    [`payInMethod-${id}`],
    () => apiClient.readOnePayInMethod({ pathParams: { payInMethodId: id } }),
    { retry: 1, enabled: !!id },
  )

  const fetcher = (paginate: Paginate) =>
    apiClient.readAllInvoicesByPaymentMethod({
      pathParams: { id: !!paymentMethod.legacyId ? String(paymentMethod.legacyId) : paymentMethod.id },
      queryParams: paginate,
    })

  if (isLoading) {
    return <FullScreenSpinner />
  }

  const label = payInMethodInfoBuilder(paymentMethod)

  return (
    <AccountPageLayout
      breadcrumbItems={[
        {
          label: tNavigation('wallet'),
        },
        {
          to: '../',
          label: tNavigation('payments'),
        },
        {
          to: '/',
          label: `···· ${label.getLastDigits()}`,
        },
      ]}
    >
      <Flex flexDirection='column' gap={3}>
        <ViewPaymentMethod paymentMethod={paymentMethod} fetcher={fetcher} />
      </Flex>
    </AccountPageLayout>
  )
}

export { PaymentMethodScreen }
