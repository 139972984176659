import React from 'react'

import { getCategory, getMediaUrl } from '@guiker/blog-entity'
import { routes } from '@guiker/blog-routes'
import { useDateFormatter, useTranslation } from '@guiker/i18n'
import { useParams } from '@guiker/router'

import {
  ArticleNotFound,
  Avatar,
  Box,
  BreadcrumbProps,
  Chip,
  Flex,
  FullScreenSpinner,
  H2,
  HTMLToComponentLibrary,
  HTMLToHead,
  Link,
  makeStyles,
  P,
  PageLayout,
  PSmall,
  theme,
  useMediaQuery,
  useScreenSize,
} from '../../components'
import { useQuery } from '../../hooks'
import { getApiClient } from '../../utils'

const IMAGE_HEIGHT = {
  mobile: 500,
  desktop: 500,
}

const useStyle = makeStyles(
  {
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    text: {
      lineHeight: '16px',
    },
    smallText: {
      lineHeight: '14px',
    },
    image: ({ isMd }: { isMd: boolean }) => ({
      width: isMd ? '100%' : `calc(100% + ${theme.spacing(2) * 2}px)`,
      minWidth: '100%',
      objectFit: 'cover',
      marginLeft: isMd ? 0 : -theme.spacing(2),
      marginRight: isMd ? 0 : -theme.spacing(2),
      height: isMd ? IMAGE_HEIGHT.desktop : IMAGE_HEIGHT.mobile,
      minHeight: isMd ? IMAGE_HEIGHT.desktop : IMAGE_HEIGHT.mobile,
    }),
    content: {
      marginTop: theme.spacing(5),
    },
  },
  { name: 'ArticleScreen' },
)

const ArticleScreen: React.FC = () => {
  const isSmallerThanContent = useMediaQuery(theme.breakpoints.downPx(720))
  const { isMd } = useScreenSize()
  const classes = useStyle({ isSmallerThanContent, isMd })
  const { t } = useTranslation()
  const { formatWithRelativeDateTime } = useDateFormatter()
  const { articleSlug } = useParams()

  const {
    data: articleResponse,
    isLoading: articleIsLoading,
    isSuccess,
  } = useQuery(['readOneArticle', articleSlug], () =>
    getApiClient().readOneArticle({ queryParams: { slug: articleSlug } }),
  )

  if (articleIsLoading) {
    return <FullScreenSpinner />
  } else if (isSuccess && !articleResponse?.body) {
    return <ArticleNotFound />
  }

  const breadcrumbItems: BreadcrumbProps['items'] = [
    {
      to: '/blog',
      label: t('main-blog:subtitle'),
    },
  ]

  const article = articleResponse?.body
  const articleCategories = article?.categories.map((category) => getCategory(article, category)) || []

  if (articleCategories.length > 0) {
    breadcrumbItems.push({
      to: `/blog/${articleCategories[0].slug}`,
      label: articleCategories[0].name,
    })
  }

  breadcrumbItems.push({
    label: article.title.rendered,
  })

  const pagePath = articleCategories.length > 0 && `${articleCategories[0].slug}/${article.slug}`
  const url = getMediaUrl(article, 'large')
  const author = article._embedded?.author[0]
  const avatarUrl = author?.avatar_urls['96']

  return (
    <PageLayout breadcrumbItems={breadcrumbItems}>
      <HTMLToHead html={article.yoast_head} pagePath={pagePath} />
      <H2 mb={2}>{article.title.rendered}</H2>
      <Flex mb={2} alignItems='center'>
        <Avatar src={avatarUrl} />
        <Box ml={2}>
          <P mb={1} className={classes.text}>
            {author.name}
          </P>
          <PSmall color={60} mb={0} className={classes.smallText}>
            {formatWithRelativeDateTime(article.date)}
          </PSmall>
        </Box>
      </Flex>

      <Box mb={2}>
        {articleCategories?.map((category) => (
          <Link
            color='white'
            underline={false}
            hoverUnderline={false}
            to={routes.blog.categoryNested(category.slug).path}
            key={category.slug}
          >
            <Chip color='primary' className={classes.chip}>
              {category?.name}
            </Chip>
          </Link>
        ))}
      </Box>
      <img src={url} className={classes.image} alt={article.title.rendered} />

      <div className={classes.content}>
        <HTMLToComponentLibrary html={article.content.rendered} />
      </div>
    </PageLayout>
  )
}

export { ArticleScreen }
