import React from 'react'

import { AuthBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { AuthenticatedApi, checkCapabilities, PayoutMethodType } from '@guiker/payout-shared'
import { ApiForm, FullScreenSpinner, Step, StepProps, useTranslation, yupResolver } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { getPayoutMethodAssetData } from '../../utils'
import { VerificationDocumentsFormContent } from '../VerificationDocumentsFormContent'

export type VerificationDocumentsStepProps = StepProps

const VerificationDocumentsStepContent: React.FC<VerificationDocumentsStepProps> = ({ onClickNext, ...props }) => {
  const { t } = useTranslation(['main-payout'])
  const { payoutMethod, updatePayoutMethod } = usePayoutMethodNestedContext()
  const { bulkUpdate } = useBulkAssetUploaderContext()
  const apiClient = useAuthenticatedPayoutApiClient()

  const isInvestmentOnly = checkCapabilities.hasOnly.investment(payoutMethod.capabilities)
  const resolver = yupResolver(AuthenticatedApi.Schemas.updateVerificationDocumentsSchema, {
    context: { payoutMethodType: payoutMethod.type ?? PayoutMethodType.CANADIAN_BANK_ACCOUNT },
  })

  const onSubmit = async (payload: AuthenticatedApi.Schemas.UpdateVerificationDocumentsSchema) => {
    const args = { pathParams: { payoutMethodId: payoutMethod.id }, payload }
    return isInvestmentOnly
      ? apiClient.updatePayoutInvestmentVerificationDocuments(args)
      : apiClient.updatePayoutVerificationDocuments(args)
  }

  return (
    <ApiForm
      beforeValidation={() => bulkUpdate()}
      onSubmit={onSubmit}
      formName={'VerificationDocumentsStep'}
      formOptions={{
        resolver,
        defaultValues: payoutMethod,
      }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext({ asLastStep: isInvestmentOnly })
        },
      }}
    >
      {({ formState: { isSubmitting, errors } }) => (
        <Step
          {...props}
          errors={errors}
          isSubmitting={isSubmitting}
          title={t('main-payout:createPayoutMethodScreen.verificationDocuments.subtitle')}
        >
          <VerificationDocumentsFormContent defaultValue={payoutMethod} readOnly={false} />
        </Step>
      )}
    </ApiForm>
  )
}

const VerificationDocumentsStep: React.FC<VerificationDocumentsStepProps> = (props) => {
  const { payoutMethod, isFetching } = usePayoutMethodNestedContext()

  if (isFetching) {
    return <FullScreenSpinner />
  }

  const { assetGroups, scope } = getPayoutMethodAssetData(payoutMethod)
  return (
    <AuthBulkAssetUploaderContextProvider assetGroups={assetGroups} scope={scope}>
      <VerificationDocumentsStepContent {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}

export { VerificationDocumentsStep }
