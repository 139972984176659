import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { FormSection1 } from '@guiker/components-library'

import { useT } from '../../i18n'
import { UpdateProfileForm } from './FormContext'

const ProfileScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.profile' })

  return (
    <AccountPageLayout hasButtonContainer>
      <FormSection1 title={tMain('title')}>
        <UpdateProfileForm />
      </FormSection1>
    </AccountPageLayout>
  )
}

export { ProfileScreen }
