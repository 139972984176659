import React from 'react'

import { usePayoutMethodNestedContext } from '@guiker/payout-context'
import { checkCapabilities, PayoutMethod } from '@guiker/payout-shared'
import { ApiForm, PageLayout, useNavigate, useTranslation } from '@guiker/react-framework'

import { AllFormContent } from './AllFormContent'

const ViewPayoutMethodScreen: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('main-payout')
  const { isFetching, payoutMethod } = usePayoutMethodNestedContext()

  return (
    <ApiForm formName='ViewPayoutMethodScreen'>
      <PageLayout
        takeoverProps={{
          onClose: () => navigate('../../'),
        }}
        isLoading={isFetching}
        maxWidth={900 as const}
        title={t(
          `main-payout:viewPayoutMethodScreen.${
            checkCapabilities.hasOnly.investment(payoutMethod.capabilities) ? 'investmentTitle' : 'title'
          }`,
        )}
      >
        <AllFormContent payoutMethod={payoutMethod as PayoutMethod} readOnly={true} />
      </PageLayout>
    </ApiForm>
  )
}

export { ViewPayoutMethodScreen }
