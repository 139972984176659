import React from 'react'

import { FormSection3, FormSection4, OneColumnGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Quebec } from '../../../types'
import { DatePicker as DatePickerComponent, RadioGroup, TextField } from '../../'

type Props = {
  name: string
  defaultValue: Quebec.Rent
}

const DatePicker = (props) => <DatePickerComponent {...props} />

export const PaymentDate: React.FC<Props> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection3 title={t('quebecLeaseScreenD:dateOFPaymentLabel')}>
      <FormSection4 title={t('quebecLeaseScreenD:firstPaymentPeriod')}>
        <OneColumnGridLayout>
          <DatePicker
            defaultValue={defaultValue?.firstPaymentDate}
            readOnly={!canDraftLease}
            label={t('quebecLeaseScreenD:firstPaymentDatePickerLabel')}
            name={`${name}.firstPaymentDate`}
          />
        </OneColumnGridLayout>
      </FormSection4>
      <FormSection4 title={t('quebecLeaseScreenD:otherPayments')}>
        <OneColumnGridLayout>
          <RadioGroup
            defaultValue={defaultValue?.paymentDate}
            readOnly={!canDraftLease}
            disabled={canDraftLease}
            name={`${name}.paymentDate`}
            label={t('quebecLeaseScreenD:firstDayPaymentLabel')}
            options={[
              {
                value: Quebec.PaymentDates.firstDayOfMonth,
                label: t('quebecLeaseScreenD:firstPaymentDatePickerForMonth'),
              },
              {
                value: Quebec.PaymentDates.firstDayOfWeek,
                label: t('quebecLeaseScreenD:firstPaymentDatePickerForWeek'),
              },
            ]}
          />
          <TextField
            readOnly={!canDraftLease}
            disabled={canDraftLease}
            defaultValue={defaultValue?.paymentDateOther || ''}
            label={t('quebecLeaseScreenD:otherPaymentLabel')}
            name={`${name}.paymentDateOther`}
          />
        </OneColumnGridLayout>
      </FormSection4>
    </FormSection3>
  )
}
