import React, { useEffect, useState } from 'react'

import { useGlosseryDrawerContext } from '@guiker/glossery-drawer'
import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import {
  AuthApi,
  businessTypes,
  Employment,
  EmploymentType,
  incomeSources,
  InvestorProfile,
} from '@guiker/propsharing-shared'
import {
  ApiFormStep,
  Collapse,
  Dropdown,
  FormSection,
  FormSection2,
  FormSection3,
  InfoMiniIcon,
  OneColumnGridLayout,
  P,
  RadioGroup,
  StepProps,
  TextField,
  Trans,
  useApiFormStepContext,
  useGetInputProps,
  useTranslation,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'

import { Paragraph } from './Typography'

type Props = {
  readOnly?: boolean
  defaultValue: Employment
}

const schema = AuthApi.Schema.updateInvestorProfileStepperSchema.EmploymentInformation

const DrawerContent: React.FC = () => {
  const { t } = useTranslation('main-propsharing')
  const tPrefix = 'main-propsharing:screens.createInvestorProfileScreen.step3.helperText'
  return (
    <>
      <Paragraph transKey={`${tPrefix}.foreignPep.header`} id={'pep'} />
      <ul>
        <Trans i18nKey={`${tPrefix}.foreignPep.list`}>
          <li />
        </Trans>
      </ul>
      <P>{t(`${tPrefix}.foreignPep.description`)}</P>
      <Paragraph transKey={`${tPrefix}.domesticPep.header`} />
      <ul>
        <Trans i18nKey={`${tPrefix}.domesticPep.list`}>
          <li />
        </Trans>
      </ul>
      <P>{t(`${tPrefix}.domesticPep.description`)}</P>
      <Paragraph transKey={`${tPrefix}.hio`} />
      <Paragraph transKey={`${tPrefix}.family`} />
      <Paragraph transKey={`${tPrefix}.associate`} />
      <Paragraph transKey={`${tPrefix}.businessType`} id={'businessType'} />
      <Paragraph transKey={`${tPrefix}.incomeSource`} id={'incomeSource'} />
    </>
  )
}

const Content: React.FC<Props> = ({ defaultValue, readOnly }) => {
  const { t } = useTranslation(['common', 'common-propsharing', 'main-propsharing'])
  const { onOpen } = useGlosseryDrawerContext()
  const [detailRequired, setDetailRequired] = useState({
    employerName: false,
    businessType: false,
    jobTitle: false,
    incomeSource: false,
  })
  const tPrefix = {
    common: 'common-propsharing:investorProfile.employment',
    main: 'main-propsharing:screens.createInvestorProfileScreen.step3',
  }

  const inputProps = useGetInputProps({ defaultValue, schema, readOnly, tPrefix: `${tPrefix.main}.form` })
  const employmentType = useWatch({ name: 'type', defaultValue: defaultValue?.type }) as EmploymentType

  useEffect(() => {
    employmentType &&
      setDetailRequired({
        employerName: EmploymentType.Employed === employmentType,
        jobTitle: EmploymentType.Employed === employmentType,
        businessType: [EmploymentType.Employed, EmploymentType.SelfEmployed].includes(employmentType),
        incomeSource: ![EmploymentType.Employed, EmploymentType.SelfEmployed].includes(employmentType),
      })
  }, [employmentType])

  return (
    <FormSection>
      <RadioGroup
        {...inputProps('isPEPorHIO')}
        labelAdornment={{
          node: <InfoMiniIcon onClick={(e) => onOpen(e, 'pep')} />,
          align: 'baseline',
        }}
        direction='row'
        options={[
          { value: true, label: t('common:yes') },
          { value: false, label: t('common:no') },
        ]}
      />
      <OneColumnGridLayout>
        <FormSection2 title={t(`${tPrefix.main}.title`)}>
          <Dropdown
            {...inputProps('type')}
            options={Object.values(EmploymentType).map((type) => ({
              label: t(`${tPrefix.common}.type.${type}`),
              value: type,
            }))}
          />
          <Collapse in={!!employmentType} mountOnEnter>
            <FormSection3>
              {detailRequired.businessType && (
                <Dropdown
                  {...inputProps('businessType')}
                  labelAdornment={{
                    node: <InfoMiniIcon onClick={(e) => onOpen(e, 'businessType')} />,
                    align: 'baseline',
                  }}
                  options={businessTypes.map((businessType) => ({
                    label: t(`${tPrefix.common}.businessType.${businessType}`),
                    value: businessType,
                  }))}
                />
              )}
              {detailRequired.employerName && <TextField {...inputProps('employerName')} />}
              {detailRequired.jobTitle && <TextField {...inputProps('jobTitle')} />}
              {detailRequired.incomeSource && (
                <Dropdown
                  {...inputProps('incomeSource')}
                  labelAdornment={{
                    node: <InfoMiniIcon onClick={(e) => onOpen(e, 'incomeSource')} />,
                    align: 'baseline',
                  }}
                  options={incomeSources[employmentType]?.map((incomeSource: string) => ({
                    label: t(`${tPrefix.common}.incomeSource.${incomeSource}`),
                    value: incomeSource,
                  }))}
                />
              )}
            </FormSection3>
          </Collapse>
        </FormSection2>
      </OneColumnGridLayout>
    </FormSection>
  )
}

export const EmploymentInformation: React.FC<StepProps> = (props) => {
  const { data: investorProfile } = useApiFormStepContext<InvestorProfile>()
  const { apiClient } = useAuthenticatedPropSharingInvestorProfileContext()
  const resolver = yupResolver(schema)
  const { anchor, setContent, onClose } = useGlosseryDrawerContext()
  useEffect(() => {
    setContent(<DrawerContent />)
    return () => {
      onClose()
    }
  }, [])

  const onSubmit = (payload: AuthApi.Schema.UpdateInvestorProfileStepperSchema['EmploymentInformation']) => {
    return apiClient.updateInvestorProfileStepEmploymentInformation({
      payload: schema.cast(payload, { stripUnknown: true }),
    })
  }

  return (
    <ApiFormStep
      {...props}
      isContentDrawerOpened={!!anchor}
      apiFormProps={{
        onSubmit,
        formOptions: {
          resolver,
          defaultValues: investorProfile?.employment,
        },
      }}
    >
      <Content defaultValue={investorProfile?.employment} />
    </ApiFormStep>
  )
}
