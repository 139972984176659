import React, { useMemo } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useBookingContext } from '@guiker/booking-context'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import { PayInMethodLabel } from '@guiker/payment-app-components'
import { usePaymentApiClient } from '@guiker/payment-context'
import { findTaxByRegion, isPaidOffline as isPaidOfflineBase, TaxBreakdown } from '@guiker/payment-shared'
import {
  Features,
  Flex,
  FullScreenSpinner,
  GridText,
  Link,
  Note,
  P,
  PageLayout,
  PageSection2,
  PageSection3,
  PageSection4,
  PBig,
  PBold,
  PSmall,
  PSmaller,
  theme,
  useDateFormatter,
  useFeatureFlag,
  useMediaQuery,
  useQuery,
} from '@guiker/react-framework'
import { TenantInstalmentEventsTimeline, TenantInstalmentStatusChip } from '@guiker/rent-payment-components'
import { buildTenantInstalmentPaymentInfo } from '@guiker/rent-payment-shared'
import { addressFormatter, CurrencyISO, formatDate, money, optionalConcat } from '@guiker/shared-framework'

import { BookingActivityLog } from '../../components'
import { useRentPaymentsPlanContext } from '../../context'
import { useAuthenticatedStatisticApiClient, useSideMenuItems } from '../../hooks'
import { useT } from '../../i18n'

type TenantInstalmentScreenProps = {
  tenantInstalmentId: string
}

type BreakdownProps = {
  totalAmount: number
  fee: number
  receivedAmount: number
  taxes: TaxBreakdown[]
  currency: CurrencyISO
}

const BreakdownContent: React.FC<BreakdownProps> = ({ totalAmount, fee, receivedAmount, taxes, currency }) => {
  const { tMain } = useT()

  return (
    <PageSection4 title={tMain('screens.instalment.breakdown.title')}>
      <Flex justifyContent='space-between' maxWidth={500}>
        <Flex flexDirection='column' gap={1}>
          <P fontWeight={300} mb={0}>
            {tMain('screens.instalment.breakdown.rent')}
          </P>
          {!!taxes?.length
            ? taxes.map((tax) => (
                <P mb={0} fontWeight={300}>
                  {tax?.type}
                </P>
              ))
            : null}
          <P fontWeight={300} mb={0}>
            {tMain('screens.instalment.breakdown.fee')}
          </P>
        </Flex>
        <Flex flexDirection='column' gap={1}>
          <PBold mb={0} textAlign='right'>
            {money.fromAmount(totalAmount, currency).toString()}
          </PBold>
          {!!taxes?.length
            ? taxes.map((tax) => (
                <PSmall mb={0} textAlign='right'>{`(${money.fromAmount(tax.amount, currency).toString()})`}</PSmall>
              ))
            : null}
          <PSmall mb={0} textAlign='right'>{`(${money.fromAmount(fee, currency).toString()})`}</PSmall>
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' maxWidth={500}>
        <PBold mb={0}>{tMain('screens.instalment.breakdown.received')}</PBold>
        <PBold textAlign='right' mb={0}>
          {money.fromAmount(receivedAmount, currency).toString()}
        </PBold>
      </Flex>
    </PageSection4>
  )
}

export const ViewTenantInstalmentScreen: React.FC<TenantInstalmentScreenProps> = ({ tenantInstalmentId }) => {
  const { tMain } = useT()
  const { booking, isFetching: isBookingFetching } = useBookingContext()
  const { rentPaymentsPlan, isLoading } = useRentPaymentsPlanContext()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)
  const apiClient = usePaymentApiClient()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { formatMonthAndYear } = useDateFormatter()
  const canVisitUserProfile = useFeatureFlag().isEnabled(Features.HideLinkToTenantProfile)
  const canVisitTenancyDetails = useFeatureFlag().isEnabled(Features.HideLinkToBooking)
  const { data: allTaxes } = useQuery(['taxes'], () => apiClient.readAllTaxes())
  const { data: tenantInstalmentEvents } = useQuery([`tenant-instalment-activity`, tenantInstalmentId], () =>
    statisticApi.readTenantInstalmentEvents({ pathParams: { tenantInstalmentId: tenantInstalmentId } }),
  )
  const { tenantInstalments, instalments, fee, payoutMethod } = { ...rentPaymentsPlan }
  const { address, listingId } = { ...rentPaymentsPlan?.unit }
  const tenantInstalment = tenantInstalments?.find(({ id }) => id === tenantInstalmentId)
  const payer =
    tenantInstalment?.processedWith?.payer ||
    rentPaymentsPlan?.payers.find((payer) => payer.userId === tenantInstalment.tenantUserId)
  const { chargeDate } = { ...tenantInstalment }
  const instalment = instalments?.find(({ chargeDate: date }) => date === chargeDate)
  const paymentInfo = useMemo(() => {
    if (!tenantInstalment) return
    const applicableTax = findTaxByRegion({
      taxes: allTaxes,
      region: { name: address?.state, currency: payoutMethod?.currency },
    })

    return buildTenantInstalmentPaymentInfo({
      payer,
      instalment,
      serviceFee: fee,
      total: rentPaymentsPlan?.total,
      applicableTax,
    })
  }, [allTaxes, tenantInstalment])

  const { amount, transferAmount, serviceFeeAmount, serviceFeeTaxBreakdown } = { ...paymentInfo }

  if (isBookingFetching || isLoading) return <FullScreenSpinner />
  if (!booking || !rentPaymentsPlan) return

  const bookingStartDate = formatDate(instalments[0].chargeDate)
  const bookingEndDate = formatDate(instalments[instalments.length - 1].chargeDate)
  const formattedAddress = addressFormatter.printShortAddress(address)

  const { firstName, lastName, emailAddress, payInMethod } = payer
  const isPaidOffline = isPaidOfflineBase(payInMethod?.type)
  const isZeroAmount = amount <= 0
  const payerFullName = optionalConcat([firstName, lastName], ' ')

  const breadcrumbItems = [
    { label: '..', to: mainPathBuilder.root.path },
    { label: '..', to: mainPathBuilder.root.rentalDashboard.path },
    { label: '..', to: mainPathBuilder.root.rentalDashboard.listings.byListingId(listingId).path },
    { label: tMain('screens.instalment.breadcrumb.address', { address: formattedAddress }) }, // to: mainPathBuilder.root.rentalDashboard.listings.byListingId(listingId).units.byInventoryId(inventoryId).path
    { label: `${bookingStartDate} - ${bookingEndDate}`, to: canVisitTenancyDetails ? '/' : null }, // @todo add link to booking
    { label: payerFullName, to: canVisitUserProfile ? '/' : null }, // @todo add link to user profile
    {
      label: tMain('screens.instalment.breadcrumb.instalmentDate', {
        date: formatMonthAndYear(chargeDate),
      }),
    },
  ]

  return (
    <PageLayout
      sideMenuProps={{ items: sideMenuNavItems }}
      breadcrumbItems={breadcrumbItems}
      title={tMain('screens.instalment.title', {
        name: `${payerFullName} - ${formatMonthAndYear(chargeDate)}`,
      })}
    >
      <PageSection2
        title={tMain('screens.instalment.paymentDetails')}
        topActions={
          <Flex gap={1} alignItems='center'>
            <PBig fontWeight={500} mb={0}>
              {money.fromAmount(transferAmount, payoutMethod.currency).toString()}
            </PBig>
            {!isZeroAmount && <TenantInstalmentStatusChip tenantInstalment={tenantInstalment} />}
          </Flex>
        }
      >
        <PageSection3>
          <Flex
            justifyContent='space-between'
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 5 : undefined}
          >
            {/** @todo link and page to user profile */}
            <GridText
              title={tMain('screens.instalment.payer.title')}
              mainText={payerFullName}
              secondaryText={emailAddress}
              link={
                canVisitUserProfile ? (
                  <Link target='_blank' to={'/'}>
                    <PSmaller>{tMain('screens.instalment.payer.link')}</PSmaller>
                  </Link>
                ) : null
              }
            />
            <GridText
              title={tMain('screens.instalment.payout.title')}
              mainText={formatDate(chargeDate)}
              textAlign='end'
              alignItems={isMobile ? 'flex-start' : 'flex-end'}
            />
          </Flex>
          <Flex
            justifyContent='space-between'
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 5 : undefined}
          >
            {/** @todo link and page to tenancy details / booking */}
            <GridText
              title={tMain('screens.instalment.address.title')}
              mainText={<P mb={0}>{tMain('screens.instalment.address.mainText', { address: formattedAddress })}</P>}
              secondaryText={
                <P mb={0} color={60}>
                  {formatMonthAndYear(chargeDate)}
                </P>
              }
              link={
                canVisitTenancyDetails ? (
                  <Link target='_blank' to={'/'}>
                    <PSmaller>{tMain('screens.instalment.address.link')}</PSmaller>
                  </Link>
                ) : null
              }
            />
            {!isZeroAmount && (
              <GridText
                alignItems={isMobile ? 'flex-start' : 'flex-end'}
                title={tMain('screens.instalment.payment.title')}
                mainText={<PayInMethodLabel payInMethod={payInMethod} />}
                textAlign='end'
              />
            )}
          </Flex>
        </PageSection3>
        {!isZeroAmount ? (
          <>
            <PageSection4 title={tMain('screens.instalment.timeline.title')}>
              <TenantInstalmentEventsTimeline events={tenantInstalment.events} payInMethod={payInMethod} />
            </PageSection4>
            <BreakdownContent
              totalAmount={amount}
              fee={serviceFeeAmount}
              receivedAmount={transferAmount}
              taxes={serviceFeeTaxBreakdown}
              currency={payoutMethod.currency}
            />
            {(!!tenantInstalmentEvents?.length || !!booking) && (
              <PageSection4 title={tMain('screens.instalment.activityLog.title')}>
                <BookingActivityLog events={tenantInstalmentEvents} booking={booking} isPaidOffline={isPaidOffline} />
              </PageSection4>
            )}
          </>
        ) : (
          <Note statusColor='alabaster' title={tMain('screens.instalment.zeroAmount.title')}>
            {tMain('screens.instalment.zeroAmount.content')}
          </Note>
        )}
      </PageSection2>
    </PageLayout>
  )
}
