import React from 'react'

import { Listing as BaseListing } from '@guiker/base-entity'
import { Flex, H4, Link, PBold, useTranslation } from '@guiker/react-framework'
import { BuildingAmenities } from '@guiker/real-estate-components'

import { mapLegacyAmenitiesToBuildingAmenities } from './utils'

const AmenityComponent: React.FC<{
  buildingAmenities: BaseListing.BuildingAmenities<boolean>
  onClickEdit?: () => void
}> = ({ buildingAmenities, onClickEdit }) => {
  const { t } = useTranslation(['common-rentalListing', 'main-rentalListing'])
  const selectedBuildingAmenities = mapLegacyAmenitiesToBuildingAmenities(buildingAmenities)

  return (
    <div>
      <Flex justifyContent='space-between'>
        <H4>{t('main-rentalListing:buildingInfo')}</H4>
        {onClickEdit && <Link onClick={onClickEdit}>{t('common:actions.edit')}</Link>}
      </Flex>
      <PBold mb={2}>{t('common-rentalListing:amenities.title')}</PBold>
      <BuildingAmenities buildingAmenities={selectedBuildingAmenities} />
    </div>
  )
}

export { AmenityComponent }
