import { RouteConfig } from '@guiker/router'

import { UnsubscribeNotificationPreferenceScreen } from '../screens'

const routes: { [key: string]: RouteConfig } = {
  unsubscribeNotificationPreferenceScreen: {
    exact: true,
    path: '/',
    Component: UnsubscribeNotificationPreferenceScreen,
    requireAuthentication: false,
  },
}

export { routes }
