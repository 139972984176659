import { useAuthenticationContext } from '@guiker/authentication-context'
import { AuthenticatedApiClient } from '@guiker/booking-shared'
import { useAuthenticatedApiClient } from '@guiker/react-framework'

export const useBookingApiClient = () => {
  const {
    user: { accessToken },
  } = useAuthenticationContext()
  return useAuthenticatedApiClient(AuthenticatedApiClient)({ accessToken })
}
