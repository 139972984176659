import React from 'react'

import { CollapseWithHeader, DisplayBody, Flex, PageSection2, useT as baseUseT } from '@guiker/react-framework'

import { useT } from '../../i18n'

export const CommonQuestions: React.FC = () => {
  const { tMain: tMainInvestment } = baseUseT({ domain: 'investments', screenName: 'screens.investmentsFAQ' })
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.commonQuestions' })

  return (
    <PageSection2 title={tMain('header')}>
      <Flex flexDirection='column' gap={2} maxWidth={700} width='100%'>
        <CollapseWithHeader header={tMainInvestment('how.title')}>
          <DisplayBody>{tMainInvestment('how.company.a')}</DisplayBody>
        </CollapseWithHeader>
        <CollapseWithHeader header={tMainInvestment('process.reservation.q')}>
          <DisplayBody breakline>{tMainInvestment('process.reservation.a')}</DisplayBody>
        </CollapseWithHeader>
        <CollapseWithHeader header={tMainInvestment('process.title')}>
          <DisplayBody>{tMainInvestment('process.target.a')}</DisplayBody>
        </CollapseWithHeader>
        <CollapseWithHeader header={tMainInvestment('makingMoney.title')}>
          <DisplayBody breakline mb={1}>
            {tMainInvestment('makingMoney.money.a')}
          </DisplayBody>
          <DisplayBody breakline>{tMainInvestment('makingMoney.sell.a')}</DisplayBody>
        </CollapseWithHeader>
      </Flex>
    </PageSection2>
  )
}
