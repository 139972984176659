import React from 'react'

import { Flex, PSmall, PSmaller, Slider, sliderNumberFormatter, useWatch } from '@guiker/react-framework'
import { currency } from '@guiker/shared-framework'

type PriceSliderProps = {
  label: string
  max: number
  min: number
  name: string
  readOnly?: boolean
  step: number
}

const getFormatter = (max: number) => ({
  toActual: sliderNumberFormatter.currency(currency.CAD).toActual,
  toDisplay: (value: string) => {
    const formatted = sliderNumberFormatter.currency(currency.CAD).toDisplay(value)

    return `${value}` === `${max}` ? `${formatted}+` : formatted
  },
})

const readOnlyValue = (max: number, values: number[]) => {
  const formatter = getFormatter(max)
  return `${formatter.toDisplay(`${values[0]}`)} -
        ${formatter.toDisplay(`${values[1]}`)}`
}

export const PriceSlider: React.FC<PriceSliderProps> = ({ label, max, min, name, readOnly, step }) => {
  const data = useWatch()
  const formatter = getFormatter(max)

  if (readOnly) {
    return (
      <Flex flexDirection='column'>
        <PSmaller mb={0}>{label}</PSmaller>
        <PSmall mb={0}>{readOnlyValue(max, data[name])}</PSmall>
      </Flex>
    )
  }

  return (
    <Slider
      name={name}
      label={label}
      width={'100%'}
      min={min}
      max={max}
      step={step}
      dirtyFormOnlyOnClickRelease={true}
      formatter={formatter}
    />
  )
}
