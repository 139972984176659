import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { PayoutMethodNestedContextProvider, usePayoutMethodNestedContext } from '@guiker/payout-context'
import { Capabilities, getAllowedCapabilities } from '@guiker/payout-shared'
import { StepConfig, StepperLayout, useNavigate, useParams, useT } from '@guiker/react-framework'

import {
  AccountHolderStep,
  BankInformationStep,
  SelectAccountHolderTypeStep,
  SelectCapabilitiesStep,
  SelectTypeStep,
  TermsAndConditionsStep,
  VerificationDocumentsStep,
} from '../../components'

const CompletePayoutMethodScreenContent: React.FC = () => {
  const navigate = useNavigate()
  const { tMain, tBase } = useT({ domain: 'payout' })
  const { isFetching, payoutMethod, isSettled } = usePayoutMethodNestedContext()

  const steps: StepConfig[] = [
    {
      name: '1',
      component: SelectTypeStep,
    },
    {
      name: '2',
      component: SelectCapabilitiesStep,
    },
    {
      name: '3',
      component: SelectAccountHolderTypeStep,
    },
    {
      name: '4',
      component: AccountHolderStep,
    },
    {
      name: '5',
      component: BankInformationStep,
    },
    {
      name: '6',
      component: VerificationDocumentsStep,
    },
    {
      name: '7',
      component: TermsAndConditionsStep,
    },
  ]

  const onClose = () => navigate(webappRoutes.root.wallet().payoutPreferences().path)

  return (
    <StepperLayout
      isLoading={isFetching || !isSettled}
      pageLayoutProps={{ isLoading: isFetching, hasButtonContainer: true }}
      takeoverProps={{
        onClose,
        onBack: undefined,
      }}
      onLastStepClicked={onClose}
      onStepLoad={({ currentStep, direction, goToStep }) => {
        const shouldSkip =
          (currentStep === 1 && getAllowedCapabilities(payoutMethod).length <= 1) ||
          (currentStep === 2 && payoutMethod.capabilities?.includes(Capabilities.INVESTMENT))
        shouldSkip && goToStep(direction === 'next' ? currentStep + 1 : currentStep - 1, direction)
      }}
      nextLabel={tBase('next')}
      backLabel={tBase('back')}
      steps={steps}
      title={tMain('createPayoutMethodScreen.title')}
    />
  )
}

const CompletePayoutMethodScreen: React.FC = () => {
  const { id } = useParams()

  return (
    <PayoutMethodNestedContextProvider id={id}>
      <CompletePayoutMethodScreenContent />
    </PayoutMethodNestedContextProvider>
  )
}

export { CompletePayoutMethodScreen }
