import React from 'react'

import { I18nextProvider } from '@guiker/i18n'

import { i18n } from './i18n'
import { Router } from './routes'

const RentalTenancy: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  )
}

export default RentalTenancy
