import React from 'react'

import { RoommateContent } from '@guiker/base-listing-components'
import {
  AuthenticatedApi,
  isRoommateIntroTenantDecisionReviewable,
  RoommateIntroTenantDecision,
  RoommateIntroTenantDecisionReason,
} from '@guiker/booking-shared'
import {
  ApiForm,
  ApiFormAction,
  ButtonContainer,
  Card,
  ConfirmDialog,
  Dropdown,
  Flex,
  FullScreenSpinner,
  H2,
  isMobile,
  Note,
  OutlinedAlertIcon,
  P,
  PageLayoutContextProvider,
  SecondaryButton,
  TextField,
  theme,
  useT,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import { pascalCase, Roommate } from '@guiker/shared-framework'

import { useBookingRoommateIntroReviewScreenContext } from '../../context'

type RoommateCardProp = {
  roommate: Roommate
}

const RoommateInfo: React.FC<RoommateCardProp> = ({ roommate }) => {
  return (
    <Flex flexDirection='column' gap={2}>
      <H2>{pascalCase(roommate?.firstName)}</H2>
      <RoommateContent roommate={roommate} />
    </Flex>
  )
}
const RoommateTenantCard: React.FC<RoommateCardProp> = ({ roommate }) => {
  return (
    <Card shadow='medium' p={4} width={'350px'}>
      <RoommateInfo roommate={roommate} />
    </Card>
  )
}

const RejectConfirmDialog: React.FC = () => {
  const { tMain, tBase } = useT({ domain: 'booking', screenName: 'screens.bookingRoommmateIntroReview.modal' })
  const { tShared } = useT({ domain: 'baseListing', entity: 'roommateCard.reason' })
  const { isOpen, closeModal } = useBookingRoommateIntroReviewScreenContext()
  const reason = useWatch({ name: 'reason', defaultValue: undefined })
  const { rejectRoommateIntro, actionOnSuccess, isRejecting } = useBookingRoommateIntroReviewScreenContext()

  return (
    <ConfirmDialog
      open={isOpen}
      title={tMain('title')}
      description={
        <Flex flexDirection='column' gap={2}>
          <P>{tMain('subtitle')}</P>
          <Dropdown
            name={'reason'}
            label={tShared('label')}
            options={Object.values(RoommateIntroTenantDecisionReason).map((reason) => ({
              label: tShared(`${reason}`),
              value: reason,
            }))}
            fullWidth
            required
          />
          {reason === RoommateIntroTenantDecisionReason.OTHER && (
            <TextField name='otherReason' label={tMain('other')} rows={5} multiline required />
          )}
        </Flex>
      }
      onClose={closeModal}
      cancelButtonProps={{ onClick: closeModal, children: tBase('cancel') }}
    >
      <ApiFormAction
        onClick={rejectRoommateIntro}
        options={{
          onSuccess: actionOnSuccess,
        }}
        isLoading={isRejecting}
      >
        {tMain('cta')}
      </ApiFormAction>
    </ConfirmDialog>
  )
}

export const BookingRoommateIntroApplicants: React.FC = () => {
  const {
    booking,
    isFetching,
    roommates,
    tenant,
    openModal,
    approveRoommateIntro,
    rejectRoommateIntro,
    actionOnSuccess,
    isExpired,
  } = useBookingRoommateIntroReviewScreenContext()
  const { tMain, tBase } = useT({ domain: 'booking', screenName: 'screens.bookingRoommmateIntroReview.modal' })
  const { tShared } = useT({ domain: 'baseListing', entity: 'roommateCard.reason' })

  const resolver = yupResolver(AuthenticatedApi.Schema.updateRoommateIntroTenantDecisionPayloadSchema)

  if (!booking && isFetching) {
    return <FullScreenSpinner />
  }

  return (
    <PageLayoutContextProvider maxWidth={theme.dimensions.pageLayout.maxWidth.desktop}>
      <Flex fullWidth p={5} flexDirection='column' gap={3}>
        {isExpired && (
          <Note mb={!isMobile ? 2 : 0} statusColor='info'>
            <Flex gap={2} alignItems='center'>
              <OutlinedAlertIcon />
              <P>{tMain('expired')}</P>
            </Flex>
          </Note>
        )}
        {!!tenant && !isExpired && isRoommateIntroTenantDecisionReviewable(tenant.decision) && (
          <Note
            mb={!isMobile ? 2 : 0}
            statusColor={tenant.decision === RoommateIntroTenantDecision.ACCEPTED ? 'success' : 'alert'}
          >
            {tMain(`${tenant.decision}${tenant.reason === RoommateIntroTenantDecisionReason.OTHER ? '_OTHER' : ''}`, {
              reason: tShared(`${tenant.reason}`).toLowerCase(),
              otherReason: tenant.otherReason,
            })}
          </Note>
        )}
        <Flex flexWrap='wrap' gap={3}>
          {roommates?.length > 1
            ? roommates?.map((roommate) => <RoommateTenantCard roommate={roommate} />)
            : roommates && <RoommateInfo roommate={roommates[0]} />}
        </Flex>
        <ApiForm formName='BookingRoommateIntroApproval'>
          <ButtonContainer>
            <SecondaryButton color='alert' onClick={openModal} disabled={isExpired}>
              {tMain('cta')}
            </SecondaryButton>
            <ApiFormAction onClick={approveRoommateIntro} options={{ onSuccess: actionOnSuccess }} disabled={isExpired}>
              {tBase('actions.approve')}
            </ApiFormAction>
          </ButtonContainer>
        </ApiForm>
        <ApiForm
          formName='BookingRoommateIntroRejection'
          onSubmit={rejectRoommateIntro}
          formOptions={{ resolver, defaultValues: { tenantDecision: RoommateIntroTenantDecision.REJECTED } }}
          apiOptions={{
            onSuccess: actionOnSuccess,
          }}
        >
          <RejectConfirmDialog />
        </ApiForm>
      </Flex>
    </PageLayoutContextProvider>
  )
}
