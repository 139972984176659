import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'

import { useTranslation } from '../../../hooks'
import { makeStyles, theme } from '../../../style'
import { Box, Note, P, PBold } from '../../'

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
})

export const Notice: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation('quebecLeaseScreenH')

  return (
    <>
      <Box mb={2}>
        <Note statusColor='info' title={t('common:note')} icon={<MessagesBubbleIcon />}>
          <Box pt={1}>
            <P className={classes.marginBottom}>
              <PBold component='span'>{t('quebecLeaseScreenH:lesseeCopyLabelBold')}</PBold>
              {t('quebecLeaseScreenH:lesseeCopyLabel')}
            </P>
          </Box>
        </Note>
      </Box>
      <Note statusColor='warning' title={t('common:note')} icon={<MessagesBubbleIcon />}>
        <Box pt={1}>
          <P className={classes.marginBottom}>{t('quebecLeaseScreenH:additionalServicesLabel')}</P>
        </Box>
      </Note>
    </>
  )
}
