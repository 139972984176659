import React from 'react'

import { routes } from '@guiker/blog-routes'
import { usePaginationQueryParams, useParams } from '@guiker/router'

import { CategoryNotFound, FullScreenSpinner, HTMLToHead, PageLayout, PaginatedArticleGrid } from '../../components'
import { useQuery, useTranslation } from '../../hooks'
import { getApiClient } from '../../utils'

const CategoryScreen: React.FC = () => {
  const { t } = useTranslation()
  const { categorySlug } = useParams()
  const { page } = usePaginationQueryParams()

  const {
    data: categoryResponse,
    isLoading: categoryIsLoading,
    isSuccess,
  } = useQuery(['readOneCategory', categorySlug], () =>
    getApiClient().readOneCategory({ queryParams: { slug: categorySlug } }),
  )

  const { data: articlesResponse, isLoading: articlesAreLoading } = useQuery(
    ['readAllArticles', categoryResponse?.body?.id],
    () =>
      getApiClient().readAllArticles({
        queryParams: { page, categories: `${categoryResponse.body.id}` },
      }),
    { enabled: !!categoryResponse?.body?.id },
  )

  if (categoryIsLoading || articlesAreLoading) {
    return <FullScreenSpinner />
  } else if (isSuccess && !categoryResponse?.body) {
    return <CategoryNotFound />
  }

  const breadcrumbItems = [
    {
      to: routes.blog.path,
      label: t('main-blog:subtitle'),
    },
    {
      label: categoryResponse?.body?.name,
    },
  ]

  return (
    <PageLayout breadcrumbItems={breadcrumbItems}>
      <HTMLToHead html={categoryResponse?.body?.yoast_head} pagePath={categoryResponse?.body?.slug} />
      <PaginatedArticleGrid
        articles={articlesResponse.body}
        page={page}
        totalPage={parseInt(articlesResponse?.headers['x-wp-totalpages'])}
      />
    </PageLayout>
  )
}

export { CategoryScreen }
