import React, { createContext, useState } from 'react'

import { BookingAccessControl } from '@guiker/booking-shared'
import { PageNotFound } from '@guiker/error-pages'
import { LeaseActions, LeaseRoleResolver } from '@guiker/lease-shared'
import {
  Creator,
  Lease,
  LeaseRoleNames,
  LeaseTypes,
  Ontario,
  Quebec,
  transformUserToCollaborator,
} from '@guiker/lease-shared'
import { initializeCanWithUser } from '@guiker/permissions'
import { CanFunction } from '@guiker/permissions'
import { useGetFromQueryCache, useQuery } from '@guiker/react-query'

import { FeedbackComponent } from '../components'
import {
  useAppContext,
  useAuthenticationContext,
  useClaimsAuthenticationContext,
  useLeaseApiClient,
  useLeaseJwtAuthenticatedApiClient,
} from '../hooks'
import { hasOntarioParticipantSigned, hasQuebecParticipantSigned } from '../utils'

type LeaseContextProviderType = {
  bookingId?: string
  accessControl?: BookingAccessControl
  leaseId?: string
  isSpectator?: boolean
  children: React.ReactNode | RenderChildren
}

type RenderChildren = (context: LeaseContext) => React.ReactNode

export type LeaseContext = {
  leaseId: string | null
  lease: Lease | null
  draftedAs: Creator['draftedAs']
  isLoading: boolean
  can: CanFunction<LeaseActions>
  canOrThrow: CanFunction<LeaseActions>
  setLease: (string) => void
  hasParticipantSigned: (lease) => boolean
}

export const LeaseContext = createContext<LeaseContext>(null)

/** @todo Rename folder to booking-lease */
const LeaseContextProvider: React.FC<LeaseContextProviderType> = ({ bookingId, accessControl, leaseId, children }) => {
  const apiClient = useLeaseApiClient()

  const jwtApiClient = useLeaseJwtAuthenticatedApiClient()
  const { claims } = useClaimsAuthenticationContext()
  const { user } = useAuthenticationContext()
  const { participantType } = useAppContext()
  const [leaseState, setLease] = useState<Lease>()

  const isSpectator = accessControl?.claims && accessControl.claims.acl.lease?.roles.includes(LeaseRoleNames.SPECTATOR)
  const queryKey = ['fetchLease', leaseId, bookingId, isSpectator]
  const lease = useGetFromQueryCache({ data: leaseState, queryKey })

  const {
    isLoading: isLoadingLease,
    isFetching: isFetchingLease,
    error,
  } = useQuery(
    queryKey,
    () => {
      return claims && leaseId // check claims first to overwrite access if both claims and user exist
        ? jwtApiClient.readOneLeaseByToken({ pathParams: { leaseId } })
        : user && isSpectator
        ? jwtApiClient.readOneLeaseByScopeAndRoleToken({
            pathParams: { scopeType: 'booking', scopeId: bookingId },
            accessControl: { token: accessControl.token },
          })
        : apiClient.readOneLeaseByScope({ pathParams: { scopeType: 'booking', scopeId: bookingId } })
    },
    {
      enabled: !!(user || claims) && !!(leaseId || bookingId),
      retry: false,
      onSuccess: (lease) => {
        isSpectator && transformUserToCollaborator(lease, user, 'SPECTATOR')
        setLease(lease)
      },
    },
  )

  let roleOwner: { id?: string; emailAddress?: string }
  if (participantType === 'guarantor') {
    // Is there a better way to type this?
    const lesses: { userId?: string; guarantor?: { emailAddress: string } }[] = lease?.leaseInformation?.lessees
    const lessee = lesses?.find(({ userId }) => userId === claims?.lesseeId)
    roleOwner = { emailAddress: lessee?.guarantor?.emailAddress }
  } else if (participantType === 'invited-lessor') {
    const isCollaboratorSigner = !!lease?.collaborators?.find(
      (c) => c.type === 'SIGNER' && c.emailAddress === claims?.emailAddress,
    )
    const lessorEmailAddress = isCollaboratorSigner
      ? lease?.leaseInformation?.lessors[0]?.emailAddress
      : (claims?.emailAddress as string)
    roleOwner = { emailAddress: lessorEmailAddress }
  } else {
    roleOwner = user
  }

  const { can, canOrThrow } = initializeCanWithUser<Lease, LeaseActions>({
    user: roleOwner,
    roleResolver: LeaseRoleResolver,
    entity: lease,
  })

  const hasParticipantSigned = (_lease: Ontario.Lease | Quebec.Lease) =>
    _lease.type === LeaseTypes.CanadaOntario
      ? hasOntarioParticipantSigned(participantType, user.emailAddress, _lease as Ontario.Lease)
      : hasQuebecParticipantSigned(participantType, user.emailAddress, _lease as Quebec.Lease)

  const isLoading = isLoadingLease || isFetchingLease

  const value = {
    leaseId: leaseId || lease?.id,
    lease,
    setLease,
    draftedAs: lease?.creator?.draftedAs,
    isLoading,
    can,
    canOrThrow,
    error,
    hasParticipantSigned,
  }

  if (isLoading) {
    return <FeedbackComponent loading={isLoading} />
  }

  if (!lease && isSpectator) {
    return <PageNotFound />
  }

  return (
    <LeaseContext.Provider value={value}>
      {typeof children === 'function' ? (children as RenderChildren)(value) : children}
    </LeaseContext.Provider>
  )
}

export { LeaseContextProvider }
