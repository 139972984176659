import React from 'react'

import { useAppContext } from '../../../hooks'
import { CreateEmptyLease } from './CreateEmptyLease'
import { CreateFromBooking } from './CreateFromBooking'

export const CreateLease: React.FC = () => {
  const { booking } = useAppContext()

  if (booking) {
    return <CreateFromBooking />
  } else {
    return <CreateEmptyLease />
  }
}
