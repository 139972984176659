import React, { useEffect, useState } from 'react'

import { H4, useTranslation } from '@guiker/react-framework'
import { ListingInquiryRentalOption, RentalListing } from '@guiker/rental-listing-shared'

import { transformRentalOptions } from '../../utils'
import { RentalOption } from '../RentalOption'

const OptionComponent: React.FC<{
  listing: RentalListing
  shouldCreateListingInquiry?: boolean
  onClickEdit?: () => void
}> = ({ listing, shouldCreateListingInquiry = true }) => {
  const { t } = useTranslation(['main-rentalListing', 'common-rentalListing'])
  const [rentalOptions, setRentalOptions] = useState<ListingInquiryRentalOption[]>([])

  useEffect(() => {
    setRentalOptions(transformRentalOptions(listing.rentalOptions))
  }, [listing])

  return (
    <div>
      <H4>{t('main-rentalListing:rentalOptions')}</H4>
      {rentalOptions.map((rentalOption, i) => (
        <RentalOption
          key={`rentalOption-${i}`}
          rentalOption={rentalOption}
          openByDefault={i === 0}
          shouldCreateListingInquiry={shouldCreateListingInquiry}
        />
      ))}
    </div>
  )
}

export { OptionComponent }
