import React, { useEffect, useState } from 'react'

import { PageLayout } from '@guiker/components-library'

import { FeedbackComponent } from '../../../components'
import { useAppContext, useOntarioLeaseContext, useTakeoverContext } from '../../../hooks'
import { LeaseFeedbackScreen } from '../../LeaseFeedbackScreen'
import { InvitedLessorSignLeaseForm } from './InvitedLessorSignLeaseForm'

const InvitedLessorSignLease: React.FC = () => {
  const context = useAppContext()
  const { lease, isLoading } = useOntarioLeaseContext()
  const { onClose } = useTakeoverContext()
  const { claims } = context

  const [hasParticipantSigned, setHasParticipantSigned] = useState(false)

  useEffect(() => {
    if (!claims || !claims.leaseId) {
      onClose()

      return
    }
  }, [claims, onClose])

  if (hasParticipantSigned) {
    return <LeaseFeedbackScreen />
  }

  return (
    <PageLayout maxWidth={900}>
      <FeedbackComponent loading={isLoading} />
      {lease?.id && (
        <InvitedLessorSignLeaseForm
          id={lease.id}
          data={lease.leaseInformation}
          setHasParticipantSigned={setHasParticipantSigned}
        />
      )}
    </PageLayout>
  )
}

export { InvitedLessorSignLease }
