import React, { createContext, useState } from 'react'

import { AuthenticatedApi } from '@guiker/background-check-shared'

type CreateBackgroundCheckPayload = AuthenticatedApi.Schemas.CreateBackgroundCheckPayload

export interface BackgroundCheckFormContextType {
  backgroundCheckForm?: Partial<CreateBackgroundCheckPayload>
  setBackgroundCheckForm?: React.Dispatch<React.SetStateAction<Partial<CreateBackgroundCheckPayload>>>
  targetId?: string
}

export const BackgroundCheckFormContext = createContext<BackgroundCheckFormContextType>(undefined)

type Props = React.PropsWithChildren

const BackgroundCheckFormProvider: React.FC<Props> = (props) => {
  const [backgroundCheckForm, setBackgroundCheckForm] = useState<Partial<CreateBackgroundCheckPayload>>({})

  const state: BackgroundCheckFormContextType = {
    backgroundCheckForm,
    setBackgroundCheckForm,
  }

  return <BackgroundCheckFormContext.Provider value={state}>{props.children}</BackgroundCheckFormContext.Provider>
}

export { BackgroundCheckFormProvider }
