import React from 'react'

import { FormSection3, OneColumnGridLayout } from '@guiker/components-library'
import { Trans } from '@guiker/i18n'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation } from '../../../hooks'
import { DatePicker as DatePickerComponent, P, Typography } from '../../'

type Props = {
  defaultValue: Quebec.ServicesAndConditions
  readOnly: boolean
  name: string
}

const DatePicker = (props) => <DatePickerComponent {...props} />

export const Immovable: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])

  return (
    <>
      <FormSection3
        title={t('quebecLeaseScreenE:immovableLabel')}
        subtitle={
          <P>
            <Trans i18nKey='quebecLeaseScreenE:immovableByLaw'>
              <Typography component='span' />
            </Trans>
          </P>
        }
      >
        <OneColumnGridLayout>
          <DatePicker
            readOnly={readOnly}
            defaultValue={defaultValue?.byLawsImmovableDate}
            label={t('quebecLeaseScreenE:datePickerLabel')}
            name={`${name}.byLawsImmovableDate`}
          />
        </OneColumnGridLayout>
      </FormSection3>
      <FormSection3 title={t('quebecLeaseScreenE:dividedCoOwnershipLabel')}>
        <OneColumnGridLayout>
          <DatePicker
            readOnly={readOnly}
            defaultValue={defaultValue?.coOwnerShipImmovableDate}
            label={t('quebecLeaseScreenE:datePickerLabel')}
            name={`${name}.coOwnerShipImmovableDate`}
          />
        </OneColumnGridLayout>
      </FormSection3>
    </>
  )
}
