import React from 'react'

import { useTranslation, useWatch } from '../../../hooks'
import { Quebec } from '../../../types'
import { Collapse, DatePicker as DatePickerComponent, RadioGroup, Typography } from '../../'

type Props = {
  defaultValue: Quebec.LeaseRestrictionsAndModifications
  name: string
  readOnly: boolean
}

const DatePicker = (props) => <DatePickerComponent {...props} />

export const RadioOptions: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const leaseRestrictionsAndModifications = useWatch({ name, defaultValue })
  const { t } = useTranslation(['common', 'quebecLeaseScreenF', 'errors'])

  return (
    <>
      <RadioGroup
        defaultValue={leaseRestrictionsAndModifications?.type || null}
        readOnly={readOnly}
        options={[
          {
            value: Quebec.LeaseRestrictionsAndModificationsTypes.erectedFiveYearAgoOrLess,
            label: t('quebecLeaseScreenF:erectedFiveYearAgoOrLessLabel'),
          },
          {
            value: Quebec.LeaseRestrictionsAndModificationsTypes.destinationChangedFiveYearAgoOrLess,
            label: t('quebecLeaseScreenF:destinationChangedFiveYearAgoOrLessLabel'),
          },
          {
            value: Quebec.LeaseRestrictionsAndModificationsTypes.noneOfTheAbove,
            label: t('common:noneOfTheAbove'),
          },
        ]}
        name={`${name}.type`}
      />
      <Collapse
        in={
          leaseRestrictionsAndModifications?.type &&
          leaseRestrictionsAndModifications?.type !== Quebec.LeaseRestrictionsAndModificationsTypes.noneOfTheAbove
        }
        unmountOnExit
      >
        <DatePicker
          readOnly={readOnly}
          defaultValue={leaseRestrictionsAndModifications?.immovableReadinessDate}
          name={`${name}.immovableReadinessDate`}
          label={t('quebecLeaseScreenF:immovableReadinessDate')}
        />
      </Collapse>
      <Typography color='black'>{t('quebecLeaseScreenF:tribunalNotice')}</Typography>
    </>
  )
}
