import React, { useState } from 'react'

import { Box, ButtonWithLoader, P, TextField } from '@guiker/react-framework'

import { useTranslation } from '../../i18n'

const RequestSINForm: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation(['common', 'main-backgroundCheck'])

  const [sinNumber, setSinNumber] = useState<string>('')

  const parseSin = (value) => {
    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{3})/, '$1-')
    value = value.replace(/-(\d{3})/, '-$1-')
    value = value.replace(/(\d{3})-(\d{3})-(\d{3}).*/, '$1-$2-$3')

    return value
  }

  return (
    <Box maxWidth={400} width='100%'>
      <P color={60} breakline>
        {t('main-backgroundCheck:screens.requestInformation.descriptionSin')}
      </P>
      <TextField
        placeholder={'000-000-000'}
        name='sin'
        defaultValue={sinNumber}
        value={sinNumber}
        maxWidth='100%'
        onChange={(event) => setSinNumber(parseSin(event.target.value))}
      />
      <Box display='flex' flexDirection='row-reverse'>
        <ButtonWithLoader type='submit' isLoading={isLoading}>
          {t('common:actions.submit')}
        </ButtonWithLoader>
      </Box>
    </Box>
  )
}

export { RequestSINForm }
