import React from 'react'

import { AuthenticatedApi } from '@guiker/payout-shared'
import { ApiForm, Step, StepProps, useTranslation, yupResolver } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { TermsAndConditionsFormContent } from '..'

export type TermsAndConditionsStepProps = StepProps

const TermsAndConditionsStep: React.FC<TermsAndConditionsStepProps> = ({ onClickNext, nextLabel, ...props }) => {
  const { t } = useTranslation()
  const apiClient = useAuthenticatedPayoutApiClient()
  const { payoutMethod, updatePayoutMethod } = usePayoutMethodNestedContext()
  const resolver = yupResolver(AuthenticatedApi.Schemas.consentSchema)

  const onSubmit = (payload: AuthenticatedApi.Schemas.ConsentSchema) =>
    apiClient.updatePayoutMethodConsent({ pathParams: { payoutMethodId: payoutMethod.id }, payload })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formName={'PayoutTermsAndConditions'}
      formOptions={{ resolver }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext()
        },
        onSubmitWithoutChange: () => onClickNext(),
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step
          {...props}
          isSubmitting={isSubmitting}
          nextLabel={t('common:confirm')}
          title={t('main-payout:termsAndConditions.title')}
        >
          <TermsAndConditionsFormContent />
        </Step>
      )}
    </ApiForm>
  )
}

export { TermsAndConditionsStep }
