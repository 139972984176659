import React from 'react'

import { Flex } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation, useWatch } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { Box, Collapse, DatePicker, OneColumnGridLayout, RadioGroup, TextField } from '../../'

type Props = {
  defaultValue: Types.LeaseTerm
  name: string
  readOnly?: boolean
}

export const TenancyAgreement: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()
  const typeName = `${name}.type`
  const type = useWatch({ name: typeName, defaultValue: defaultValue?.type })
  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <div>
      <Flex gap={4}>
        <RadioGroup
          name={typeName}
          defaultValue={type}
          label={t('ontarioLeaseScreenC:agreementFor')}
          options={[
            { value: Types.LeaseTermTypes.Fixed, label: t('ontarioLeaseScreenC:fixedLength') },
            { value: Types.LeaseTermTypes.Monthly, label: t('ontarioLeaseScreenC:monthlyTenancy') },
            { value: Types.LeaseTermTypes.Other, label: t('ontarioLeaseScreenC:otherDescription') },
          ]}
          disabled={!canDraftLease}
          readOnly={readOnly}
          gridContainerProps={{ spacing: 1 }}
          maxWidth='100%'
        />
      </Flex>
      <Collapse in={type && type !== Types.LeaseTermTypes.Monthly}>
        <Box mt={2}>
          <OneColumnGridLayout>
            {type === Types.LeaseTermTypes.Fixed ? (
              <DatePicker
                name={`${name}.endDate`}
                defaultValue={defaultValue?.endDate}
                label={t('common:date')}
                readOnly={readOnly || !canDraftLease}
                maxWidth='100%'
              />
            ) : (
              <TextField
                name={`${name}.otherDescription`}
                defaultValue={defaultValue?.otherDescription}
                label={t('common:specify')}
                readOnly={readOnly}
                maxWidth='100%'
              />
            )}
          </OneColumnGridLayout>
        </Box>
      </Collapse>
    </div>
  )
}
