import { initialize } from '@guiker/i18n'

export * from '@guiker/i18n'

// to deprecate:
import { default as bookingApplicationEn } from '../components/BookingApplication/en.i18n.json'
import { default as bookingApplicationFr } from '../components/BookingApplication/fr.i18n.json'
import { default as bookingApplicationZh } from '../components/BookingApplication/zh.i18n.json'
const en = { bookingApplication: bookingApplicationEn }
const fr = { bookingApplication: bookingApplicationFr }
const zh = { bookingApplication: bookingApplicationZh }
//

const i18n = initialize({
  resources: {
    en,
    fr,
    zh,
  },
})

export { i18n }
