import React from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { useTranslation } from '@guiker/i18n'
import { PayInMethodModals, PayInMethodSelector } from '@guiker/payment-app-components'
import { usePayInMethodContext } from '@guiker/payment-context'
import { PayInMethodContextProvider } from '@guiker/payment-context'
import { getSupportedCountryFromCurrency } from '@guiker/payment-shared'
import { ApiForm, ButtonWithLoader, Divider, Flex, Modal, SecondaryButton } from '@guiker/react-framework'
import {
  useAuthRentPaymentApiClient,
  useJwtBookingRentPaymentsPlanScopeNestedContext,
} from '@guiker/rent-payment-context'
import { getAllowedTypesFromEligibility, ScopeType } from '@guiker/rent-payment-shared'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ChangePayInMethodForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation(['common'])
  const { refetch } = useJwtBookingRentPaymentsPlanScopeNestedContext()
  const { selected } = usePayInMethodContext()
  const { bookingId } = useBookingContext()
  const apiClient = useAuthRentPaymentApiClient()

  const onSubmit = async () =>
    selected &&
    apiClient.changePayInMethod({
      pathParams: { scopeType: ScopeType.BOOKING, scopeId: bookingId },
      payload: { payInMethodId: selected.id },
    })

  const onSuccess = async () => {
    await refetch()
    onClose()
  }

  return (
    <ApiForm formName='ChangeRentPaymentsPlanPayInMethodForm' onSubmit={onSubmit} apiOptions={{ onSuccess }}>
      {({ isLoading, formState: { errors } }) => (
        <Flex flexDirection='column' gap={2}>
          <PayInMethodSelector />
          <Divider />
          <Flex gap={2} justifyContent='flex-end'>
            <SecondaryButton onClick={() => onClose()}>{t('actions.cancel')}</SecondaryButton>
            <ButtonWithLoader disabled={!selected} isLoading={isLoading} errors={errors}>
              {t('actions.submit')}
            </ButtonWithLoader>
          </Flex>
        </Flex>
      )}
    </ApiForm>
  )
}

export const ChangePaymentMethodModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['main-bookingRentPayment'])
  const { data: rentPaymentsPlan } = useJwtBookingRentPaymentsPlanScopeNestedContext()

  const region = getSupportedCountryFromCurrency(rentPaymentsPlan.total.currency)
  const allowedTypes = getAllowedTypesFromEligibility(rentPaymentsPlan.payoutMethod)

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      title={t('rentPaymentsPlanTable.actions.changePaymentMethod')}
    >
      <PayInMethodContextProvider region={region} allowedTypes={allowedTypes}>
        <ChangePayInMethodForm onClose={onClose} />
        <PayInMethodModals />
      </PayInMethodContextProvider>
    </Modal>
  )
}
