import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { useParams } from '@guiker/router'

import { PublicListingNestedRouter as PublicListingRouter } from '../hooks'

const PublicListingNestedRouter: React.FC = () => {
  const { id } = useParams()

  if (!id) {
    return <PageNotFound />
  }

  return <PublicListingRouter listingId={id.split('-')[0]} />
}

export { PublicListingNestedRouter }
