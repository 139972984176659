import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import { AuthApi } from '@guiker/propsharing-shared'
import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  FormSection,
  FullScreenSpinner,
  Note,
  PageLayout,
  SuccessDialog,
  TextField,
  useModal,
  useNavigate,
  useT,
} from '@guiker/react-framework'
import { TakeoverContextProvider } from '@guiker/takeover-context'

export const RequestTINScreen: React.FC = () => {
  const { tMain, tBase } = useT({ domain: 'propsharing', screenName: 'screens.requestTIN' })
  const { data: investorProfile, isFetching, apiClient } = useAuthenticatedPropSharingInvestorProfileContext()
  const { isOpen, openModal, closeModal } = useModal()
  const navigate = useNavigate()

  const onClose = () => navigate('/')
  const handleModalClose = () => {
    closeModal()
    onClose()
  }

  const onSubmit = async (payload: AuthApi.Schema.FinancialTaxIdSchema) => {
    return apiClient.updateFinancialTaxIdNumber({ payload })
  }

  if (isFetching) return <FullScreenSpinner />
  if (!investorProfile) return <PageNotFound />

  return (
    <TakeoverContextProvider onClose={onClose} title={tMain('title')}>
      <PageLayout maxWidth={900} hasButtonContainer>
        <ApiForm
          formName='InvestorProfileRequestTINForm'
          onSubmit={onSubmit}
          apiOptions={{ onSuccess: () => openModal() }}
          formOptions={{ defaultValues: { taxIdNumber: null } }}
        >
          <FormSection>
            <Note statusColor='alabaster' title={tMain('note.title')}>
              {tMain('note.content')}
            </Note>

            <TextField name='taxIdNumber' label={tMain('form.fieldLabel')} placeholder='### ### ###' />
          </FormSection>

          <ButtonContainer>
            <ButtonWithLoader color='primary' type='submit'>
              {tBase('actions.submit')}
            </ButtonWithLoader>
          </ButtonContainer>
        </ApiForm>
      </PageLayout>
      <SuccessDialog
        open={isOpen}
        buttonProps={{
          onClick: () => handleModalClose(),
          children: tBase('actions.close'),
        }}
        text={tMain('form.successMessage')}
      />
    </TakeoverContextProvider>
  )
}
