import { RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'

export const findContribution = (rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments, userId: string) => {
  const payer = rentPaymentsPlan.payers.find((p) => p.userId === userId)
  return payer.tentativeAmount ?? payer.amount
}

export const getOccupiedAmount = (rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments, userId: string) => {
  return rentPaymentsPlan.payers.reduce(
    (acc, payer) => (payer.userId !== userId ? acc + (payer.tentativeAmount ?? payer.amount) : acc),
    0,
  )
}

export const isPositiveNumber = (value: string) => {
  return value !== '' && value !== null && Math.sign(Number(value)) >= 0
}
