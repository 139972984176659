import React from 'react'

import { Gallery } from '@guiker/picture-gallery-components'
import { Flex, Link, makeStyles, theme, useMediaQuery, useTranslation } from '@guiker/react-framework'

type PictureDisplayProps = {
  pictures: { url: string }[]
  onClick: () => void
  onClickEdit?: () => void
}

const useStyle = makeStyles({
  link: {
    marginBottom: theme.spacing(2),
    right: 0,
    float: 'right',
  },
})

export const PictureDisplay: React.FC<PictureDisplayProps> = ({ pictures, onClick, onClickEdit }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const classes = useStyle({ isMobile })

  return (
    <>
      {onClickEdit && (
        <Flex justifyContent='flex-end'>
          <Link className={classes.link} onClick={onClickEdit}>
            {t('common:actions.edit')}
          </Link>
        </Flex>
      )}
      <Gallery pictures={pictures} onClick={onClick} />
    </>
  )
}
