import React, { useMemo } from 'react'

import { Listing as BaseListing } from '@guiker/base-entity'
import {
  Box,
  CheckmarkMiniIcon,
  ElectricityIcon,
  Flex,
  HeatingIcon,
  HotWaterIcon,
  PBold,
  TwoColumnsGridLayout,
  useTranslation,
  WifiIcon,
} from '@guiker/react-framework'

import { RentalOptionInfoLabel } from './RentalOptionInfoLabel'

const Utilities: React.FC<{ utilities: BaseListing.Utilities<boolean> }> = ({ utilities }) => {
  const { t } = useTranslation(['common-rentalListing'])
  const utilityInfos = [
    { adornment: ElectricityIcon, label: 'electricity' },
    { adornment: HeatingIcon, label: 'heating' },
    { adornment: HotWaterIcon, label: 'hotWater' },
    { adornment: WifiIcon, label: 'wifi' },
  ]

  const memoizedUtilities = useMemo(() => {
    const filtered = {
      available: utilityInfos.filter(({ label }) => utilities[label]),
      unavailable: utilityInfos.filter(({ label }) => !utilities[label]),
    }
    const orderedUtilities = filtered.available.concat(filtered.unavailable)

    return orderedUtilities.map((utility, index) => (
      <Flex alignItems='center' key={`${utility.label}-${index}`}>
        <RentalOptionInfoLabel
          adornment={CheckmarkMiniIcon}
          label={t(`utilities.${utility.label}`)}
          isAvailable={utilities[utility.label]}
        />
      </Flex>
    ))
  }, [utilities])

  return (
    <Box my={2}>
      <PBold mb={2}>{t('utilities.title')}</PBold>
      <TwoColumnsGridLayout gap={2}>{memoizedUtilities}</TwoColumnsGridLayout>
    </Box>
  )
}

export { Utilities }
