import React, { Suspense } from 'react'

import { PageLayout } from '@guiker/components-library'

import { FeedbackComponent } from '../../../components'
import { useQuebecLeaseContext } from '../../../hooks'

const SignLeaseForm = React.lazy(() => import('./SignLeaseForm').then((module) => ({ default: module.SignLeaseForm })))

const SignLeaseScreen: React.FC = () => {
  const { lease, isLoading } = useQuebecLeaseContext()

  return (
    <PageLayout maxWidth={900 as const} hasButtonContainer={true}>
      {lease?.id ? (
        <Suspense fallback={<FeedbackComponent loading={true} />}>
          <SignLeaseForm id={lease.id} data={lease.leaseInformation} />
        </Suspense>
      ) : (
        <FeedbackComponent loading={isLoading} />
      )}
    </PageLayout>
  )
}

export { SignLeaseScreen }
