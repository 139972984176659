import React from 'react'

import { useTranslation } from '../../../../hooks'
import { Address } from '../../../../types'
import { TextField } from '../../../'

export type Props = {
  name: string
  readOnly?: boolean
  disabled?: boolean
  defaultValue?: Address
}

const FormAddress: React.FC<Props> = ({ defaultValue = null, name, disabled, readOnly }) => {
  const { t } = useTranslation(['common', 'errors'])
  const sharedProps = { disabled, readOnly }

  if (name) {
    name = `${name}.`
  }

  return (
    <>
      <TextField
        defaultValue={defaultValue?.apartmentNumber}
        label={t('common:address.apartmentNumber')}
        name={`${name}apartmentNumber`}
        {...sharedProps}
      />
      <TextField
        defaultValue={defaultValue?.streetNumber}
        label={t('common:address.streetNumber')}
        name={`${name}streetNumber`}
        {...sharedProps}
      />
      <TextField
        defaultValue={defaultValue?.street}
        label={t('common:address.street')}
        name={`${name}street`}
        {...sharedProps}
      />
      <TextField
        defaultValue={defaultValue?.city}
        label={t('common:address.city')}
        name={`${name}city`}
        {...sharedProps}
      />
      <TextField
        defaultValue={defaultValue?.postalCode}
        label={t('common:address.postalCode')}
        name={`${name}postalCode`}
        {...sharedProps}
      />
    </>
  )
}

export { FormAddress }
