import React, { createContext } from 'react'

import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { FullScreenSpinner } from '@guiker/components-library'
import {
  InvestmentAssumptionContextProvider,
  useInvestmentAssumptionContext,
} from '@guiker/investment-assumption-components'
import { Currency, currency as baseCurrency } from '@guiker/money'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { generateUseContext } from '@guiker/react-framework'
import { useQuery } from '@guiker/react-query'
import { ProjectWithDeveloper } from '@guiker/real-estate-shared'

import { usePublicPropertySaleListingIdContext, usePublicRealEstateApiClient } from '../hooks'

type ScreenContextType = {
  currency: Currency
  listing: PropertySaleListing
  project: ProjectWithDeveloper
  investmentAssumptions: InvestmentAssumptions.Assumptions
  investmentResults: InvestmentAssumptions.Results
}

export const ListingScreenContext = createContext<ScreenContextType>(null)

export const WrappedListingScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: listing } = usePublicPropertySaleListingIdContext()
  const realEstateApiClient = usePublicRealEstateApiClient()
  const { investmentResults, investmentAssumptions, currency } = useInvestmentAssumptionContext()

  const { data: project } = useQuery(
    `project-${listing.id}`,
    (_) =>
      realEstateApiClient.readOneProject({
        pathParams: { id: listing?.property?.building?.construction?.projectId },
      }),
    {
      retry: 1,
      enabled: !!listing && !!listing.property?.building?.construction?.projectId,
    },
  )

  if (!listing) {
    return <FullScreenSpinner />
  }

  const value = {
    currency,
    investmentAssumptions,
    listing,
    project,
    investmentResults,
  }

  return <ListingScreenContext.Provider value={value}>{children}</ListingScreenContext.Provider>
}

export const ListingScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: listing } = usePublicPropertySaleListingIdContext()
  const currency = baseCurrency[listing.price.currency]

  return (
    <InvestmentAssumptionContextProvider assumptions={listing?.assumptions} currency={currency}>
      <WrappedListingScreenContextProvider>{children}</WrappedListingScreenContextProvider>
    </InvestmentAssumptionContextProvider>
  )
}

export const useListingScreenContext = generateUseContext(ListingScreenContext)
