import React, { useEffect } from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import { PageNotFound } from '@guiker/error-pages'
import { TakeoverContextProvider } from '@guiker/takeover-context'
import { useApplicationComponentsContext, ViewTenantApplication } from '@guiker/tenant-application-components'

import { ApiForm, Button, ButtonContainer, PageLayout } from '../../components'
import { useNavigate, useTenantApplicationContext, useTranslation } from '../../hooks'

const TenantApplicationApplicantView: React.FC = () => {
  const { t } = useTranslation(['main-tenantApplication', 'common'])
  const { isLoading, tenantApplication } = useTenantApplicationContext()
  const { setEditRoutePath, editRoutePath, setCanViewBackgroundCheck } = useApplicationComponentsContext()
  const navigate = useNavigate()
  const onClose = () => navigate(mainPathBuilder.root.messages.path())

  useEffect(() => {
    setCanViewBackgroundCheck(false)
    setEditRoutePath('/tenant-application')
  }, [])

  if (!isLoading && !tenantApplication) {
    return <PageNotFound />
  }

  return (
    <TakeoverContextProvider
      onClose={onClose}
      title={t('main-tenantApplication:screens.createTenantApplication.title')}
    >
      <PageLayout maxWidth={900} hasButtonContainer>
        <ApiForm formName='TenantApplicationApplicantView'>
          <ViewTenantApplication
            isLoading={isLoading}
            tenantApplication={tenantApplication}
            showRoommateProfile={true}
            editRoutePath={editRoutePath}
          />

          <ButtonContainer>
            <Button color='primary' onClick={onClose}>
              {t('common:actions.done')}
            </Button>
          </ButtonContainer>
        </ApiForm>
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { TenantApplicationApplicantView }
