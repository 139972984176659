import React, { useState } from 'react'

import { Flex, makeStyles, MobileVerticalSlidingPanels, theme, useT } from '@guiker/react-framework'

import { BookingRoommateIntroApplicants } from './BookingRoommateIntroApplicant'
import { BookingUnitPanel } from './BookingUnitPanel'

const useStyles = makeStyles({
  root: {
    overflowY: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  bookingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

export const BookingRoommateMobile: React.FC = () => {
  const { tMain } = useT({ domain: 'booking', screenName: 'screens.bookingRoommmateIntroReview.drawer' })
  const classes = useStyles()

  const [openUnitDetails, setOpenUnitDetails] = useState(false)

  return (
    <Flex flexDirection='column' flex={1} className={classes.root}>
      <MobileVerticalSlidingPanels
        topIn={openUnitDetails}
        topButtonLabel={tMain('close')}
        bottomButtonLabel={tMain('open')}
        onClick={() => setOpenUnitDetails(!openUnitDetails)}
        topContentClassName={classes.bookingContent}
        topContent={<BookingUnitPanel />}
        bottomContent={<BookingRoommateIntroApplicants />}
        isTakeover={true}
      />
    </Flex>
  )
}
