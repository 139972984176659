import React from 'react'

import { BookingApplicationContextProvider } from '@guiker/booking-application-context'
import { PageNotFound } from '@guiker/error-pages'
import { Outlet } from '@guiker/router'
import { ApplicationComponentsContextProvider } from '@guiker/tenant-application-components'

import { FullScreenSpinner } from '../components'
import { useAuthenticationContext, useBookingContext, useParams } from '../hooks'

const Content: React.FC = () => {
  const { isFetching, booking, error } = useBookingContext()

  if (error) {
    return <PageNotFound />
  }

  if (isFetching || !booking) {
    return <FullScreenSpinner />
  }

  return <Outlet />
}

const ApplicantNestedRouter: React.FC = () => {
  // Should be able to get param of nested children in the newest react router see https://github.com/remix-run/react-router/issues/7960
  const params = useParams()
  const applicantId = params['*'].includes('/') ? params['*'].split('/')[0] : null
  const { user } = useAuthenticationContext()

  return (
    <BookingApplicationContextProvider applicantId={applicantId || user.id}>
      <ApplicationComponentsContextProvider queryKey='booking'>
        <Content />
      </ApplicationComponentsContextProvider>
    </BookingApplicationContextProvider>
  )
}

export { ApplicantNestedRouter }
