import React from 'react'

import { PageLayout } from '@guiker/components-library'

import { FeedbackComponent } from '../../../components'
import { useOntarioLeaseContext } from '../../../hooks'
import { SignLeaseForm } from './SignLeaseForm'

const SignLeaseScreen: React.FC = () => {
  const { lease, isLoading } = useOntarioLeaseContext()

  return (
    <PageLayout maxWidth={900 as const} hasButtonContainer={true}>
      <FeedbackComponent loading={isLoading} />
      {lease?.id && <SignLeaseForm id={lease.id} data={lease.leaseInformation} />}
    </PageLayout>
  )
}

export { SignLeaseScreen }
