import React from 'react'

import { useQuery } from '@guiker/react-query'
import { useNavigate, useParams } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'

import { CreditReportCard, FeedbackComponent, PageLayout, TypographyProps } from '../../components'
import { useBackgroundCheckApiClient } from '../../hooks/use-background-check-api-client'
import { useTranslation } from '../../i18n'

const CreditReport: React.FC = () => {
  const { applicantId } = useParams()
  const navigate = useNavigate()
  const apiClient = useBackgroundCheckApiClient()
  const { t } = useTranslation()

  const { data: report, isLoading } = useQuery(['backgroundCheck-readOne', applicantId], () =>
    apiClient.readOneBackgroundCheck({ pathParams: { applicantId } }),
  )

  let color: TypographyProps['color'] = 'secondary'

  switch (report?.results?.creditReport?.overallScore) {
    case 'PASS':
      color = 'success'
      break
    case 'WARN':
      color = 'warning'
      break
    case 'FAIL':
      color = 'alert'
      break
  }

  return (
    <TakeoverContextProvider
      onBack={() => navigate('..')}
      onClose={() => navigate('/background-check')}
      title={t('creditReport:title')}
      subtitle={report ? `${report.firstName} ${report.lastName}` : ''}
      subtitleProps={{ color }}
    >
      <PageLayout>
        <FeedbackComponent loading={isLoading} />
        {!isLoading && report && <CreditReportCard creditReport={report.results.creditReport} />}
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { CreditReport }
