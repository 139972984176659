import React from 'react'

import { InvitedLessorAppContextProvider } from './context'
import { i18n, I18nextProvider } from './i18n'
import { InvitedLessorRouter } from './routes'

const InvitedLessorApp: React.FC = () => {
  return (
    <InvitedLessorAppContextProvider>
      <I18nextProvider i18n={i18n}>
        <InvitedLessorRouter />
      </I18nextProvider>
    </InvitedLessorAppContextProvider>
  )
}

export { InvitedLessorApp }
