import React from 'react'

import { Helmet } from '@guiker/react-framework'

import { sanitizeHeadElements } from '../../utils'

type HTMLToHeadProps = {
  html: string
  pagePath: string
}

const HTMLToHead: React.FC<HTMLToHeadProps> = ({ html, pagePath }) => {
  return <Helmet>{sanitizeHeadElements(html, pagePath)}</Helmet>
}

export { HTMLToHead }
