import React from 'react'

import { AuthBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { yupResolver } from '@guiker/react-hook-form'
import { GuarantorAndBackgroundCheckFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, buildAssetGroups } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useTenantApplicationContext, useTranslation } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '..'

export type GuarantorAndBackgroundCheckStepProps = StepProps

const GuarantorAndBackgroundCheckContent: React.FC<GuarantorAndBackgroundCheckStepProps> = ({
  onClickNext,
  ...props
}) => {
  const { t } = useTranslation('main-tenantApplication')

  const { tenantApplication, setTenantApplication } = useTenantApplicationContext()
  const { bulkUpdate } = useBulkAssetUploaderContext()
  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const resolver = yupResolver(
    AuthenticatedApi.Schema.updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator,
    { context: { email: tenantApplication?.profile?.emailAddress } },
  )

  const handleSubmit = (
    payload: AuthenticatedApi.Schema.UpdateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator,
  ) =>
    apiClient.updateGuarantorAndBackgroundCheck({
      payload: AuthenticatedApi.Schema.updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator.cast(payload),
    })

  return (
    <ApiForm
      formName='TenantApplicationGuarantorAndBackgroundCheckStep'
      formOptions={{
        resolver,
        defaultValues: tenantApplication,
      }}
      onSubmit={handleSubmit}
      beforeValidation={() => bulkUpdate()}
      apiOptions={{
        onSuccess: (res) => {
          setTenantApplication(res)
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step
          isSubmitting={isSubmitting}
          title={t('screens.steps.guarantor.title')}
          subtitle={t('main-tenantApplication:hasGuarantor.description')}
          {...props}
        >
          <GuarantorAndBackgroundCheckFormContent
            tenantApplication={tenantApplication}
            schema={AuthenticatedApi.Schema.updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator}
          />
        </Step>
      )}
    </ApiForm>
  )
}

const GuarantorAndBackgroundCheckStep: React.FC<GuarantorAndBackgroundCheckStepProps> = (props) => {
  const { isLoading, tenantApplication } = useTenantApplicationContext()

  if (isLoading) {
    return <FullScreenSpinner />
  }

  const assetsGroups = buildAssetGroups(
    tenantApplication?.guarantor?.supportingDocuments,
    'guarantor.supportingDocuments',
  )

  return (
    <AuthBulkAssetUploaderContextProvider
      assetGroups={assetsGroups}
      scope={{
        type: 'TenantApplication',
        id: tenantApplication?.id,
      }}
    >
      <GuarantorAndBackgroundCheckContent {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}

export { GuarantorAndBackgroundCheckStep }
