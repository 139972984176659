import React from 'react'

import { useTranslation } from '../../../hooks'
import { Box, RadioGroup, RadioGroupProps } from '../../'

type Props = Omit<RadioGroupProps, 'options'> & {
  withCharge?: boolean
}

export const YesNoRadioGroup: React.FC<Props> = ({ withCharge, ...props }) => {
  const { t } = useTranslation()
  return (
    <Box mb={2}>
      <RadioGroup
        options={[
          { value: 'yes', label: t('common:yes') },
          ...(withCharge
            ? [
                {
                  value: 'noCharge',
                  label: t('ontarioLeaseScreenD:noCharge'),
                  gridItemProps: { style: { height: '64px' } },
                },
                {
                  value: 'payPerUse',
                  label: t('ontarioLeaseScreenD:payPerUse'),
                  gridItemProps: { style: { height: '64px' } },
                },
              ]
            : []),
          { value: 'no', label: t('common:no') },
        ]}
        gridContainerProps={{ spacing: 1 }}
        maxWidth={420}
        {...props}
      />
    </Box>
  )
}
