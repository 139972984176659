import React from 'react'

import { AuthenticatedListingNestedRouter } from '@guiker/rental-listing-context'
import { Outlet, useParams } from '@guiker/router'

const ListingNestedRouter: React.FC = () => {
  const { listingId } = useParams()

  return (
    <AuthenticatedListingNestedRouter listingId={listingId}>
      <Outlet />
    </AuthenticatedListingNestedRouter>
  )
}

export { ListingNestedRouter }
