import React from 'react'

import { AuthInvoiceNestedRouter } from '@guiker/payment-app-components'
import { useParams } from '@guiker/react-framework'

export const InvoiceNestedRouter = () => {
  const { id } = useParams()

  return <AuthInvoiceNestedRouter invoiceId={id} />
}
