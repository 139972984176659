import React, { useEffect } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { CountryCode } from '@guiker/base-entity'
import { ListingPictures } from '@guiker/base-listing-components'
import { hasTwoOrMoreAttributes, UnitConfig } from '@guiker/base-listing-components'
import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { mainPathBuilder } from '@guiker/investment-shared'
import { PropertySaleInquiryCard } from '@guiker/property-sale-components'
import { usePublicApiClient } from '@guiker/property-sale-context'
import { PublicApi, SaleStatus } from '@guiker/property-sale-shared'
import { buildDisplayName, hasDocument } from '@guiker/property-sale-shared'
import {
  ApiForm,
  Box,
  Flex,
  PageLayout,
  PageMetaTags,
  PBig,
  TabLayout,
  theme,
  useMediaQuery,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import { RecaptchaProvider, useRecaptcha } from '@guiker/react-recaptcha'
import { RecaptchaAction } from '@guiker/recaptcha-action'

import { Header } from '../../components'
import { ListingScreenContextProvider, useListingScreenContext } from '../../context'
import { useTranslation } from '../../i18n'
import { DetailsTab, FinancialDetailsTab, ProjectAndDocumentsTab } from './tabs'

const Content: React.FC = () => {
  const tPrefix = 'main-propertySale:screens.listingScreen'
  const { t } = useTranslation(['common-propertySale', 'main-propertySale'])
  const { project, listing } = useListingScreenContext()
  const { setInvestmentAssumptions } = useInvestmentAssumptionContext()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const assumptions = useWatch({ name: 'assumptions', defaultValue: listing.assumptions })
  const isSold = listing.propertySale?.saleStatus === SaleStatus.SOLD

  useEffect(() => {
    setInvestmentAssumptions(assumptions)
  }, [assumptions])

  const tabs = [
    {
      content: (
        <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
          <DetailsTab />
        </Flex>
      ),
      label: t(`${tPrefix}.tab1.label`),
    },
    project || hasDocument(listing, project)
      ? {
          content: (
            <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
              <ProjectAndDocumentsTab project={project} listing={listing} />
            </Flex>
          ),
          label: t(`${tPrefix}.tab2.label.${project ? 'project' : 'documents'}`),
        }
      : undefined,
    !isSold && {
      content: (
        <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
          <FinancialDetailsTab />
        </Flex>
      ),
      label: t(`${tPrefix}.tab3.label`),
    },
  ].filter((t) => !!t)

  return (
    <Flex flexDirection='column' maxWidth='100%' pb={isMobile ? 22 : 0}>
      <ListingPictures listing={listing} showGallery={!isSold} />
      <Flex flexDirection='column' gap={2} mt={6}>
        <Header listing={listing} project={project} />
        {listing?.source?.mlsNumber && (
          <PBig mb={0}>{`${t(`${tPrefix}.mlsNumber`)}: ${listing.source.mlsNumber}`}</PBig>
        )}
      </Flex>
      {listing?.property && hasTwoOrMoreAttributes(listing.property) ? (
        <Box my={10}>
          <UnitConfig property={listing.property} />
        </Box>
      ) : (
        <Box my={2} />
      )}
      {tabs.length > 1 ? <TabLayout tabs={tabs} /> : tabs[0]?.content}
    </Flex>
  )
}

export const ListingScreenFormWrapped: React.FC = () => {
  const { t } = useTranslation(['common-propertySale', 'main-propertySale'])
  const { project, listing } = useListingScreenContext()
  const publicPropertySaleApiClient = usePublicApiClient()
  const { user: currentUser } = useAuthenticationContext()
  const { executeRecaptcha } = useRecaptcha()

  const onSubmit = async (payload: Required<PublicApi.Schema.CreatePropertySaleInquirySchema>) => {
    const recaptchaToken = await executeRecaptcha(RecaptchaAction.propertySaleInquiry)
    return publicPropertySaleApiClient.createPropertySaleInquiry({ payload: { recaptchaToken, ...payload } })
  }

  const address = buildDisplayName(listing.property, project)
  const leadPicture = listing.pictures[0]

  return (
    <PageLayout
      breadcrumbItems={[
        {
          to: mainPathBuilder.root.propertySale.path,
          label: t('main-propertySale:screens.searchListingScreen.title'),
        },
        {
          label: buildDisplayName(listing.property, project),
        },
      ]}
    >
      <PageMetaTags
        subtitle={address}
        description={`${listing.description} - ${address}`}
        imageUrl={leadPicture?.url}
      />
      <ApiForm
        onSubmit={onSubmit}
        formOptions={{
          resolver: yupResolver((PublicApi.Schema.createPropertySaleInquirySchema as any).omit(['recaptchaToken'])),
          defaultValues: {
            listingId: listing.id,
            user: {
              userId: currentUser?.id,
              firstName: currentUser?.firstName,
              lastName: currentUser?.lastName,
              emailAddress: currentUser?.emailAddress,
              phone: {
                country: (listing.property?.building?.address?.country as CountryCode) ?? ('CA' as CountryCode),
                number: currentUser?.phoneNumber,
              },
            },
            assumptions: {},
            results: {},
          },
        }}
        formName='PropertySaleInquiryForm'
      >
        {({ isLoading, isSuccess }) => (
          <Flex maxWidth='100%'>
            <Content />
            <PropertySaleInquiryCard
              isLoading={isLoading}
              isSuccess={isSuccess}
              listing={listing}
              displayName={buildDisplayName(listing.property, project)}
            />
          </Flex>
        )}
      </ApiForm>
    </PageLayout>
  )
}

export const ListingScreen: React.FC = () => {
  return (
    <ListingScreenContextProvider>
      <RecaptchaProvider>
        <ListingScreenFormWrapped />
      </RecaptchaProvider>
    </ListingScreenContextProvider>
  )
}
