import React from 'react'

import { BookingPanel } from '@guiker/booking-components'
import { Divider, isMobile, makeStyles, theme, useMediaQuery } from '@guiker/components-library'

import { BookingInvitedLandlord, BookingViewByLandlordMobile } from '../../components/BookingInvitedLandlord'
import { BookingInvitedLandlordScreenContextProvider } from '../../context'

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    paddingTop: theme.dimensions.appBar.height.desktop,
    [isMobile]: {
      paddingTop: theme.dimensions.appBar.height.mobile,
    },
  },
  divider: {
    margin: 0,
  },
  bookingModuleContainer: {
    height: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
    overflow: 'scroll',
    padding: theme.spacing(2),
    width: 400,
    minWidth: 400,
  },
  contentContainer: {
    height: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
    width: '100%',
    overflow: 'scroll',
  },
})

export const Content: React.FC = () => {
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile) return <BookingViewByLandlordMobile />

  return (
    <div className={classes.root}>
      <div className={classes.bookingModuleContainer}>
        <BookingPanel />
      </div>
      <Divider orientation='vertical' className={classes.divider} />
      <div className={classes.contentContainer}>
        <BookingInvitedLandlord />
      </div>
    </div>
  )
}

export const BookingInvitedLandlordScreen: React.FC = () => {
  return (
    <BookingInvitedLandlordScreenContextProvider>
      <Content />
    </BookingInvitedLandlordScreenContextProvider>
  )
}
