import React, { useMemo } from 'react'

import { AuthenticatedApi, buildBankInformation, checkCapabilities } from '@guiker/payout-shared'
import { ApiForm, Step, StepProps, useTranslation, yupResolver } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { BankInformationFormContent } from '..'

export type BankInformationStepProps = StepProps

const BankInformationStep: React.FC<BankInformationStepProps> = ({ onClickNext, ...props }) => {
  const { t } = useTranslation(['main-payout'])

  const { payoutMethod, updatePayoutMethod, isSettled } = usePayoutMethodNestedContext()
  const apiClient = useAuthenticatedPayoutApiClient()
  const defaultValue = useMemo(
    () => ({ ...payoutMethod, bankInformation: buildBankInformation(payoutMethod) }),
    [payoutMethod],
  )

  const resolver = yupResolver(AuthenticatedApi.Schemas.updateBankInformationSchema)
  const isInvestmentOnly = checkCapabilities.hasOnly.investment(payoutMethod.capabilities)

  const onSubmit = (payload: AuthenticatedApi.Schemas.UpdateBankInformationSchema) => {
    return isInvestmentOnly && !payload?.bankInformation?.isManualInput
      ? apiClient.updatePayoutBankInformationAndSubmit({ pathParams: { payoutMethodId: payoutMethod.id }, payload })
      : apiClient.updatePayoutBankInformation({ pathParams: { payoutMethodId: payoutMethod.id }, payload })
  }
  return (
    <ApiForm
      onSubmit={onSubmit}
      formName={'PayoutBankInformation'}
      formOptions={{ resolver, defaultValues: defaultValue }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext({ asLastStep: isInvestmentOnly && !!res.verification?.details })
        },
        onSubmitWithoutChange: !!defaultValue?.details ? onClickNext : undefined,
      }}
    >
      {({ formState: { isSubmitting, errors } }) => (
        <Step
          {...props}
          isSubmitting={isSubmitting}
          errors={errors}
          title={t('main-payout:createPayoutMethodScreen.bankInformation.subtitle')}
        >
          <BankInformationFormContent defaultValue={defaultValue} isLoading={!isSettled} />
        </Step>
      )}
    </ApiForm>
  )
}

export { BankInformationStep }
