import React, { useEffect } from 'react'

import { ChannelType, PreferenceType } from '@guiker/notification-shared'
import { PageMetaTags } from '@guiker/react-framework'
import { useMutation } from '@guiker/react-query'

import { Button, FeedbackComponent, Link, PageLayout, PSmall } from '../../components'
import { useClaimsAuthenticationContext, useLocationQuery, useNotificationJwtAuthenticatedApiClient } from '../../hooks'
import { useTranslation } from '../../i18n'

const UnsubscribeNotificationPreferenceScreen: React.FC = () => {
  const { t } = useTranslation('main-notification')
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()
  const apiClient = useNotificationJwtAuthenticatedApiClient()

  const { mutate: unsubscribe, isLoading } = useMutation(() =>
    apiClient.upsertNotificationPreference({
      payload: {
        preferenceType: claims?.preferenceType as PreferenceType,
        channelType: ChannelType.EMAIL,
        isEnabled: false,
      },
    }),
  )

  useEffect(() => {
    token && setClaimsFromToken(token)
  }, [token])

  useEffect(() => {
    claims?.preferenceType && unsubscribe()
  }, [unsubscribe, claims])

  if (isLoading) {
    return <FeedbackComponent loading={isLoading} />
  }

  return (
    <>
      <PageMetaTags noIndex />
      <PageLayout title={t('unsubscribeNotificationPreferenceScreen.title')}>
        <PSmall mb={6}>{t('unsubscribeNotificationPreferenceScreen.description')}</PSmall>
        <Link isExternalLink to='/'>
          <Button>{t('unsubscribeNotificationPreferenceScreen.action')}</Button>
        </Link>
      </PageLayout>
    </>
  )
}

export { UnsubscribeNotificationPreferenceScreen }
