import React from 'react'

import { ColumnGridItem, OneColumnGridLayout, PBold } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { Collapse, Note, RadioGroup, TextField, Typography } from '../../'

type Props = {
  defaultValue: Quebec.NewLesseeNotice
  readOnly: boolean
  disabled?: boolean
  name: string
}

const LowestPrecedingRent: React.FC<Props> = ({ defaultValue, readOnly, disabled, name }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenG'])
  const lowestPrecedingRentFrequency = useWatch({
    name: `${name}.lowestPrecedingRentFrequency`,
    defaultValue: defaultValue?.lowestPrecedingRentFrequency,
  })

  return (
    <OneColumnGridLayout>
      <ColumnGridItem md={2}>
        <PBold>{t('quebecLeaseScreenG:mandatoryLabelBold')}</PBold>
        <Typography>{t('quebecLeaseScreenG:lowestRentNotificationLabel')}</Typography>
      </ColumnGridItem>
      <TextField
        type='number'
        defaultValue={defaultValue?.lowestPrecedingRent}
        readOnly={readOnly}
        disabled={disabled}
        label={t('quebecLeaseScreenG:rent')}
        name={`${name}.lowestPrecedingRent`}
      />
      <ColumnGridItem md={2}>
        <div>
          <RadioGroup
            defaultValue={lowestPrecedingRentFrequency || null}
            readOnly={readOnly}
            disabled={disabled}
            name={`${name}.lowestPrecedingRentFrequency`}
            options={[
              { value: Quebec.Frequency.perMonth, label: t('quebecLeaseScreenG:perMonth') },
              { value: Quebec.Frequency.perWeek, label: t('quebecLeaseScreenG:perWeek') },
              { value: Quebec.Frequency.other, label: t('common:other') },
            ]}
          />
          <Collapse in={lowestPrecedingRentFrequency === Quebec.Frequency.other} unmountOnExit>
            <Note>
              <OneColumnGridLayout>
                <TextField
                  defaultValue={defaultValue?.lowestPrecedingRentFrequencyOther}
                  readOnly={readOnly}
                  disabled={disabled}
                  name={`${name}.lowestPrecedingRentFrequencyOther`}
                  label={t('common:specify')}
                />
              </OneColumnGridLayout>
            </Note>
          </Collapse>
        </div>
      </ColumnGridItem>
    </OneColumnGridLayout>
  )
}

export { LowestPrecedingRent }
