import React from 'react'

import { PageLayout, useT } from '@guiker/react-framework'
import { TenantPreferenceContextProvider } from '@guiker/tenant-preference-context'

import { FilterGroup, PaginatedListingsGrid } from '../../components'
import { useAuthenticationContext, useSideMenuItems } from '../../hooks'

export const TenantPreferencesScreen: React.FC = () => {
  const { tMain } = useT({ domain: 'rentalTenancy', screenName: 'screens.tenantPreferences' })
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems()

  return (
    <PageLayout title={tMain('title')} sideMenuProps={{ items: sideMenuNavItems }} hasButtonContainer>
      <TenantPreferenceContextProvider userId={user.id} isAdmin={false} columnNumber={2}>
        <FilterGroup />
        <PaginatedListingsGrid />
      </TenantPreferenceContextProvider>
    </PageLayout>
  )
}
