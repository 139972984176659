import { useAuthenticatedApiClient } from '@guiker/react-framework'
import { getUseSearch } from '@guiker/search-components'
import { AuthenticatedApiClient, SearchableEntities } from '@guiker/search-shared'
import { Scope } from '@guiker/shared-framework'

import { useGetMapperOverride } from '../utils/search-mapper'

const useSearchAdminApiClient = useAuthenticatedApiClient(AuthenticatedApiClient)

export const useSearch = ({ entities, scope }: { entities?: SearchableEntities[]; scope?: Scope } = {}) => {
  const apiClient = useSearchAdminApiClient()
  const mapperOverride = useGetMapperOverride()
  return getUseSearch(apiClient, mapperOverride, { entities, scope })
}
