import React from 'react'

import { MessagesBubbleIcon } from '@guiker/icons'
import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useLeaseContext, useTranslation } from '../../../hooks'
import { makeStyles } from '../../../style'
import { Ontario as Types } from '../../../types'
import { Divider, Note, P, TextField } from '../../'
import {
  FormSection2,
  FormSection3,
  HeaderDescriptionBar,
  SignatureContainer,
  TwoColumnsGridLayout,
} from '../../common'
import { Guarantors } from './Guarantors'

type Props = {
  lessors: Types.Lessor[]
  lessees: Types.Lessee[]
}

const useStyles = makeStyles({
  preLine: {
    whiteSpace: 'pre-line',
  },
})

const PARTICIPANT_LIST_DIVDER_COLOR = 15

export const FormContent: React.FC<Props> = ({ lessors, lessees }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { disabledForCurrentUser } = useAppContext()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)
  const canSignAsLessor = can(LeaseActions.SignLeaseAsLessor)
  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)
  const isGuarantor = can(LeaseActions.SignLeaseAsGuarantor)

  return (
    <FormSection2 title={<HeaderDescriptionBar index='17.' description={t('ontarioLeaseScreenI:section17Header')} />}>
      <P className={classes.preLine}>{t('ontarioLeaseScreenI:signaturesDescription')}</P>
      <FormSection3>
        <P>{t('ontarioLeaseScreenI:landlords')}</P>
        {lessors.map((lessor, index) => (
          <>
            <TwoColumnsGridLayout key={lessor.userId}>
              <TextField
                defaultValue={lessor.firstName}
                name={`lessors[${index}].firstName`}
                label={t('common:firstName')}
                readOnly={!canDraftLease || disabledForCurrentUser(lessor)}
                maxWidth={420}
              />
              <TextField
                defaultValue={lessor.lastName}
                name={`lessors[${index}].lastName`}
                label={t('common:lastName')}
                readOnly={!canDraftLease || disabledForCurrentUser(lessor)}
                maxWidth={420}
              />
              <TextField
                defaultValue={lessor.emailAddress}
                name='emailAddress'
                label={t('common:emailAddress')}
                readOnly={!canDraftLease}
                maxWidth={420}
                type='email'
              />
            </TwoColumnsGridLayout>
            <SignatureContainer
              type='signature'
              name='signature'
              readOnly={!canSignAsLessor || disabledForCurrentUser(lessor)}
              defaultValue={lessor?.signature}
              label={t('ontarioLeaseScreenI:signature')}
              maxWidth={420}
            />
            {index !== lessors.length - 1 && <Divider color={PARTICIPANT_LIST_DIVDER_COLOR} />}
          </>
        ))}
      </FormSection3>
      <Divider />

      <FormSection3>
        <P>{t('ontarioLeaseScreenI:tenants')}</P>
        {lessees.map((lessee, index) => (
          <>
            <TwoColumnsGridLayout key={lessee.userId}>
              <TextField
                defaultValue={lessee.firstName}
                name={`lessees[${index}].firstName`}
                label={t('common:firstName')}
                readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
                maxWidth={420}
              />
              <TextField
                defaultValue={lessee.lastName}
                name={`lessees[${index}].lastName`}
                label={t('common:lastName')}
                readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
                maxWidth={420}
              />
              <TextField
                defaultValue={lessee.emailAddress}
                name='emailAddress'
                label={t('common:emailAddress')}
                readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
                maxWidth={420}
                type='email'
              />
            </TwoColumnsGridLayout>
            <SignatureContainer
              defaultValue={lessee.signature}
              type='signature'
              name='signature'
              readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
              label={t('ontarioLeaseScreenI:signature')}
              maxWidth={420}
            />
            <Guarantors
              defaultValue={lessee.guarantor}
              t={t}
              readOnly={!(canSignAsLessee || isGuarantor) || disabledForCurrentUser(lessee)}
            />
            {index !== lessees.length - 1 && <Divider color={PARTICIPANT_LIST_DIVDER_COLOR} />}
          </>
        ))}
      </FormSection3>
      <Divider />

      <Note title={t('common:note')} icon={<MessagesBubbleIcon />}>
        {t('ontarioLeaseScreenI:signaturesNote')}
      </Note>
    </FormSection2>
  )
}
