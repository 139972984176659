import React from 'react'

import { AuthInvoiceNestedRouter } from '@guiker/payment-app-components'
import { useParams } from '@guiker/react-framework'

export const InvoiceNestedRouter: React.FC = () => {
  const { invoiceId } = useParams()

  return <AuthInvoiceNestedRouter invoiceId={invoiceId} />
}
