import React from 'react'

import {
  Divider,
  Flex,
  getLocalizedState,
  Link,
  makeStyles,
  P,
  PageLayout,
  theme,
  useNavigate,
  useT,
} from '@guiker/react-framework'
import { RentalListing, routesFromListing } from '@guiker/rental-listing-shared'
import { startCase } from '@guiker/shared-framework'

import { TakeoverContextProvider } from '../../../components'
import { useConfirmListingInquiryScreenContext } from '../../../context'
import { BookVisit } from './BookVisit'
import { ConfirmListingDetail } from './ConfirmListingDetail'

const useStyles = makeStyles({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  listingImage: {
    borderRadius: 4,
    width: '100%',
    maxWidth: 520,
    maxHeight: 425,
    objectFit: 'cover',
    objectPosition: 'center',
    marginBottom: theme.spacing(2),
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  subtitle: {
    fontSize: '18px',
  },
  suiteNumber: {
    color: theme.palette.grey[30],
    marginLeft: theme.spacing(2),
  },
})

type ConfirmListingInquiryMobileProps = {
  listing: RentalListing
}

const ConfirmListingInquiryMobile: React.FC<ConfirmListingInquiryMobileProps> = () => {
  const { tMain, t } = useT({ domain: 'rentalListingInquiry' })
  const { isConfirmed, listing, selectedOption: rentalOption, rentalOptions } = useConfirmListingInquiryScreenContext()
  const classes = useStyles()
  const navigate = useNavigate()

  const { streetNumber, street, state: stateCode, postalCode, country: countryCode } = listing.address

  if (!rentalOption && !rentalOptions?.length) {
    return <></>
  }

  const link = routesFromListing(listing).listing

  const { suiteNumber } = rentalOption

  return (
    <TakeoverContextProvider onClose={() => navigate('/')} title={tMain('components.confirmListingDetail.appBarTitle')}>
      <PageLayout>
        <Flex flexDirection='column' className={classes.root}>
          {isConfirmed ? (
            <BookVisit isStickyButtonVisible={true} />
          ) : (
            <>
              <Flex flexDirection='column' gap={2}>
                <Link className={classes.link} isExternalLink underline={false} to={link} color={'textPrimary'}>
                  <Flex flexDirection='column'>
                    <P>{`${suiteNumber ? `${startCase(suiteNumber)} - ` : ''}${streetNumber} ${startCase(street)}`}</P>
                    <P>{`${getLocalizedState(t, {
                      countryCode,
                      stateCode,
                    })} ${postalCode.toUpperCase()}`}</P>
                  </Flex>
                </Link>
                <img src={listing.pictures[0].url} className={classes.listingImage} alt='listing' />
              </Flex>
              <Divider />
              <ConfirmListingDetail withPrice />
            </>
          )}
        </Flex>
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { ConfirmListingInquiryMobile }
