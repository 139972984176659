import React from 'react'

import { useBookingContext } from '@guiker/booking-application-context'
import { ApplicationStatus, isRoommatable } from '@guiker/booking-shared'
import { TenantApplicationStepper } from '@guiker/tenant-application-components'
import { TenantApplication } from '@guiker/tenant-application-shared'

import {
  CurrentAddressStep,
  FullScreenSpinner,
  GuarantorAndBackgroundCheckStep,
  MyProfileStep,
  SupportingDocumentsStep,
} from '../../components'
import { MyRoommateProfileStep } from '../../components/MyRoommateProfileStep'
import { useBookingApplicationContext, useNavigate, useTranslation } from '../../hooks'

export const EditBookingApplicationScreen: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { bookingChat, booking } = useBookingContext()
  const { isLoading, bookingApplication } = useBookingApplicationContext()

  if (bookingApplication?.status === ApplicationStatus.ACCEPTED) {
    navigate('../application')
  }

  const getSteps = () => [
    {
      name: '1',
      component: MyProfileStep,
    },
    {
      name: '2',
      component: CurrentAddressStep,
    },
    {
      name: '3',
      component: GuarantorAndBackgroundCheckStep,
    },
    {
      name: '4',
      component: SupportingDocumentsStep,
    },
    ...(isRoommatable(booking.listing) && booking.hasEnabledStep.roommate
      ? [
          {
            name: '5',
            component: MyRoommateProfileStep,
          },
        ]
      : []),
  ]

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <TenantApplicationStepper
      takeoverProps={{ onClose: bookingChat.navigate, onBack: undefined }}
      onLastStepClicked={() => navigate('../application')}
      isLoading={isLoading}
      nextLabel={t('common:next')}
      backLabel={t('common:back')}
      steps={getSteps()}
      tenantApplication={bookingApplication?.content as TenantApplication}
    />
  )
}
