import React from 'react'

import { ColumnGridItem, Note, OneColumnGridLayout } from '@guiker/components-library'

import { useTranslation, useWatch } from '../../../hooks'
import { Quebec } from '../../../types'
import { Checkbox, Collapse, NumericInput, TextField } from '../../'

interface Props {
  checkBoxLabel: string
  name: string
  readOnly?: boolean
  defaultValue: Quebec.ParkingInformation
}

const ParkingInformation: React.FC<Props> = ({ checkBoxLabel, name, readOnly, defaultValue }) => {
  const parkingInformation = useWatch({ name, defaultValue })
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])

  return (
    <ColumnGridItem md={2}>
      <div>
        <Checkbox
          readOnly={readOnly}
          defaultValue={parkingInformation?.isAvailable}
          label={checkBoxLabel}
          name={`${name}.isAvailable`}
        />
        <Collapse in={parkingInformation?.isAvailable} unmountOnExit>
          <Note>
            <OneColumnGridLayout>
              <NumericInput
                readOnly={readOnly}
                defaultValue={parkingInformation.numberOfPlaces}
                name={`${name}.numberOfPlaces`}
                min={0}
                max={10}
                label={t('quebecLeaseScreenB:numberOfPlace')}
              />
              <TextField
                name={`${name}.spaces`}
                defaultValue={parkingInformation?.spaces}
                readOnly={readOnly}
                label={t('quebecLeaseScreenB:parkingSpace')}
              />
            </OneColumnGridLayout>
          </Note>
        </Collapse>
      </div>
    </ColumnGridItem>
  )
}

export { ParkingInformation }
