import React from 'react'

import { ColumnGridItem, OneColumnGridLayout } from '@guiker/components-library'
import { Interval, parseDateTime } from '@guiker/date'
import { TFunction } from '@guiker/i18n'
import { Quebec } from '@guiker/lease-shared'
import { LeaseActions } from '@guiker/lease-shared'

import { useFormContext, useLeaseContext, useTranslation, useWatch } from '../../../hooks'
import { compact } from '../../../utils'
import { DatePicker as DatePickerComponent, TextField, Typography } from '../../'

type Props = {
  name: string
  defaultValue: Quebec.LeaseTerm
}

const computeDuration = ({ t, startDate, endDate }: { t: TFunction; startDate?: string; endDate?: string }): string => {
  const startDateTime = parseDateTime(startDate)
  const endDateTime = parseDateTime(endDate).plus({ days: 1 })
  const { days, months, years } = Interval.fromDateTimes(startDateTime, endDateTime).toDuration([
    'years',
    'month',
    'days',
  ])

  return compact([
    years >= 1 ? t('durations.year', { count: years, lngs: ['en', 'fr'] }) : null,
    months >= 1 ? t('durations.month', { count: months, lngs: ['en', 'fr'] }) : null,
    days >= 1 ? t('durations.day', { count: days, lngs: ['en', 'fr'] }) : null,
  ]).join(', ')
}

const DatePicker = (props) => <DatePickerComponent {...props} />

const LeaseTermFixed: React.FC<Props> = ({ name, defaultValue }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenC'])
  const form = useFormContext()
  const { can } = useLeaseContext()

  const leaseTerm = useWatch({
    name,
    defaultValue: { duration: computeDuration({ t, ...defaultValue }), ...defaultValue },
  })

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <OneColumnGridLayout>
      <Typography mb={0}>{t('quebecLeaseScreenC:fixedTermOfLease')}</Typography>
      <TextField
        readOnly={!canDraftLease}
        defaultValue={leaseTerm.duration}
        label={t('quebecLeaseScreenC:fixedTermSpecifyDuration')}
        name={`${name}.duration`}
      />
      <DatePicker
        defaultValue={leaseTerm.startDate}
        readOnly={!canDraftLease}
        name={`${name}.startDate`}
        error={!!form.formState.errors[`${name}.startDate`]}
        label={t('common:from')}
      />
      <DatePicker
        readOnly={!canDraftLease}
        defaultValue={leaseTerm.endDate}
        name={`${name}.endDate`}
        error={!!form.formState.errors[`${name}.endDate`]}
        label={t('common:to')}
      />
      <ColumnGridItem md={2}>
        <Typography>{t('quebecLeaseScreenC:termText')}</Typography>
      </ColumnGridItem>
    </OneColumnGridLayout>
  )
}

export { LeaseTermFixed }
