import React from 'react'

import { ListingBanner } from '@guiker/base-listing-components'
import { Chip, RouterLink, SecondaryButton, useConfig, useTranslation } from '@guiker/react-framework'

const OperatorComponent: React.FC<{ listingId: String; status: string }> = ({ listingId, status }) => {
  const { t } = useTranslation(['main-rentalListing'])
  const { appBaseUrl } = useConfig()

  return (
    <ListingBanner color='alabaster' text={t('listingManagedByYou')}>
      <Chip variant='outlined' color={status === 'LISTED' ? 'success' : 60} size='small'>
        {t(`listingDescriptors.${status.toLowerCase()}`)}
      </Chip>
      <RouterLink to={`${appBaseUrl}/myaccount/listings/${listingId}/listing`} isExternalLink>
        <SecondaryButton size='small'>{t('actions.manageListing')}</SecondaryButton>
      </RouterLink>
    </ListingBanner>
  )
}

export { OperatorComponent }
