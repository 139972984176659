import React from 'react'

import { Box, Flex, H5, P, PBold } from '@guiker/components-library'
import { Trans } from '@guiker/i18n'
import { InfoMiniIcon } from '@guiker/icons'

type TitleProps = {
  header: string
  onClick?: (event: React.SyntheticEvent, id?: string) => void
  id?: string
  TitleComponent: React.FC<React.PropsWithChildren>
  mb?: number
}

export const Title: React.FC<TitleProps> = ({ header, onClick, id, TitleComponent, mb = 3 }) => {
  return (
    <Flex gap={2} alignItems='center'>
      <TitleComponent>{header}</TitleComponent>
      <Box mb={mb}>
        <InfoMiniIcon onClick={(e) => onClick(e, id)} />
      </Box>
    </Flex>
  )
}

type TypeProps = {
  transKey: string
  id?: string
}

export const Paragraph: React.FC<TypeProps> = ({ transKey, id }) => {
  return (
    <div id={id}>
      <Trans i18nKey={transKey}>
        <H5 />
        <P />
      </Trans>
    </div>
  )
}

export const SpanDefinition: React.FC<TypeProps> = ({ transKey }) => {
  return (
    <Box mb={2}>
      <Trans i18nKey={transKey}>
        <PBold component='span' />
        <P component='span' />
      </Trans>
    </Box>
  )
}
