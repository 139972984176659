import React, { useEffect, useState } from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { PayInMethodModals } from '@guiker/payment-app-components'
import { PayInMethodContextProvider, usePayInMethodContext } from '@guiker/payment-context'
import { getOnlineTransactionThreshold, PayInMethodType, ScopeType, SupportedCountries } from '@guiker/payment-shared'
import { useAuthenticatedPropSharingStockOrderIdContext, useInvestorProfileContext } from '@guiker/propsharing-context'
import {
  allowedPaymentTypes,
  hasTIN,
  isAbleToPurchase,
  isCompleted,
  isStockOrderPaid,
  StockOrder,
  webappRoutes,
} from '@guiker/propsharing-shared'
import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  Flex,
  PageLayout,
  Redirect,
  SecondaryButton,
  useNavigate,
  useQuery,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'

import { CheckoutContent } from '../../components'
import { CheckoutStockOrderSchema, checkoutStockOrderSchema } from './form-schema'

const tPrefix = 'main-propsharing:screens.stockOrderCheckout'

const StockOrderCheckoutContent: React.FC = () => {
  const { t } = useTranslation(['common', 'main-propsharing'])
  const { data: stockOrder, apiClient } = useAuthenticatedPropSharingStockOrderIdContext()
  const { selected: selectedPayInMethod } = usePayInMethodContext()
  const { investorProfile, isFetching: isInvestorProfileFetching } = useInvestorProfileContext()
  const [quantity, setQuantity] = useState()
  const navigate = useNavigate()

  const onClose = () => {
    navigate(-1, { fallback: mainPathBuilder.root.investmentDashboard.path })
  }

  const onSuccess = (data: StockOrder) => {
    const { askedPrice, quantity } = data.share
    const threshold = getOnlineTransactionThreshold({
      scopeType: ScopeType.BUY_STOCK_ORDER,
      currency: data.stock.currency,
    })

    if (askedPrice * quantity > threshold || data?.payInMethod?.type === PayInMethodType.OFFLINE) {
      navigate('../how-to-wire-transfer')
    } else {
      navigate(mainPathBuilder.root.investmentDashboard.path)
    }
  }

  const {
    data: riskAcknowledgement,
    refetch,
    isFetching,
  } = useQuery(
    [`stock-order-${stockOrder.id}-risk-acknowledgement`],
    () => apiClient.previewRiskAcknowledgement({ pathParams: { id: stockOrder.id }, queryParams: { quantity } }),
    {
      retry: 1,
      enabled: !!stockOrder?.id && !!quantity && !!investorProfile && isAbleToPurchase(investorProfile),
    },
  )

  useEffect(() => {
    quantity && refetch()
  }, [quantity])

  useEffect(() => {
    if (!!investorProfile && !hasTIN(investorProfile) && isCompleted(investorProfile)) {
      navigate(webappRoutes.investorProfile.requestTIN)
    }
    if (!isInvestorProfileFetching && (!investorProfile || !isCompleted(investorProfile))) {
      navigate(webappRoutes.investorProfile.onboard)
    }
  }, [isInvestorProfileFetching, investorProfile])

  const onSubmit = async (payload: CheckoutStockOrderSchema) => {
    return apiClient.checkoutStockOrder({
      pathParams: { id: stockOrder.id },
      payload: {
        payInMethodId: selectedPayInMethod.id,
        share: {
          quantity: payload.share.quantity,
        },
        consent: {
          isConsentGiven: payload.consent.isConsentGiven,
          fingerprint: riskAcknowledgement?.fingerprint,
          date: riskAcknowledgement?.date,
        },
      },
    })
  }

  if (!!stockOrder && isStockOrderPaid(stockOrder)) {
    return <Redirect path={mainPathBuilder.root.investmentDashboard.path} />
  }

  return (
    <PageLayout
      maxWidth={900}
      title={t(`${tPrefix}.title`)}
      subtitle={t(`${tPrefix}.subtitle`)}
      takeoverProps={{
        onClose,
        hideSubtitle: true,
        subtitleProps: { mb: 0 },
      }}
      noBreadcrumpSpacer
      hasButtonContainer
      forceTakeover
    >
      <ApiForm
        formName='StockOrderCheckoutForm'
        onSubmit={onSubmit}
        apiOptions={{ onSuccess }}
        formOptions={{
          defaultValues: stockOrder,
          resolver: yupResolver(checkoutStockOrderSchema),
        }}
      >
        {({ isLoading, formState: { errors } }) => (
          <Flex flexDirection='column'>
            {stockOrder && (
              <CheckoutContent
                defaultValue={stockOrder}
                riskAcknowledgement={riskAcknowledgement?.content}
                setQuantity={setQuantity}
                isRiskAcknowledgementFetching={isFetching}
              />
            )}
            <ButtonContainer>
              <SecondaryButton onClick={() => onClose()}>{t('common:actions.back')}</SecondaryButton>
              <ButtonWithLoader disabled={isLoading || isFetching} isLoading={isLoading} errors={errors}>
                {t(`${tPrefix}.cta`)}
              </ButtonWithLoader>
            </ButtonContainer>
          </Flex>
        )}
      </ApiForm>
    </PageLayout>
  )
}

export const StockOrderCheckoutScreen: React.FC = () => {
  const { investorProfile } = useInvestorProfileContext()

  return (
    <PayInMethodContextProvider
      selectedId={investorProfile?.payInMethod?.id}
      region={SupportedCountries.Canada}
      allowedTypes={allowedPaymentTypes}
    >
      <StockOrderCheckoutContent />
      <PayInMethodModals />
    </PayInMethodContextProvider>
  )
}
