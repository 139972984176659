import React from 'react'

import { BookingJwtContextProvider, BookingRoommateContextProvider, useBookingContext } from '@guiker/booking-context'
import {
  getApplicantSteps,
  mainPathBuilder as bookingMainPathBuilder,
  ParticipantStepStatus,
} from '@guiker/booking-shared'
import { mainPathBuilder as conversationPathBuilder } from '@guiker/conversation-shared'
import { RouteConfig as BaseRouteConfig, useNavigate, useParams } from '@guiker/router'
import { TakeoverContextProvider } from '@guiker/takeover-context'
import { ApplicationComponentsContextProvider } from '@guiker/tenant-application-components'

import { BookingApplications } from '../components/BookingApplication/BookingApplications'
import { BookingInvitedLandlordScreen } from '../screens/BookingInvitedLandlordScreen'
import { ListBookingsScreen } from '../screens/ListBookingsScreen'
import { BookingNestedRouter } from './BookingNestedRouter'

const BookingModuleComponent = React.lazy(() => import('../components/MicroComponent/BookingModule'))
import BookingApplicantComponent from '../components/MicroComponent/BookingApplicant'
import { BookingRoommateIntroReviewScreen } from '../screens'
const BookingDocumentComponent = React.lazy(() => import('../components/MicroComponent/BookingDocument'))
const BookingPaymentComponent = React.lazy(() => import('../components/MicroComponent/BookingPayment'))
const BookingRentPaymentComponent = React.lazy(() => import('../components/MicroComponent/BookingRentPayment'))
const GuarantorLeaseComponent = React.lazy(() => import('../components/MicroComponent/GuarantorLease'))
const InvitedLessorLeaseComponent = React.lazy(() => import('../components/MicroComponent/InvitedLessorLease'))
const LeaseComponent = React.lazy(() => import('../components/MicroComponent/Lease'))

export type RouteConfig = BaseRouteConfig & {
  requireAuthentication: boolean
  Component: React.FC<any>
  routes?: Routes
}

export type Routes = {
  [key: string]: RouteConfig
}

const defaultRouteConfig: Partial<RouteConfig> = {
  exact: false,
  requireAuthentication: false,
  authenticationMethod: 'user',
}

const inquiryPath = (bookingId: string) => conversationPathBuilder.root.messages.path({ bookingId })
const bookingPaymentPath = (bookingId: string) => bookingMainPathBuilder.root.byId(bookingId).payment.contribution.path

const TakeoverComponent = ({ ChildComponent, ...props }) => {
  const { bookingId } = useParams()
  const navigate = useNavigate()
  const onClose = () => navigate(inquiryPath(bookingId))

  return (
    <TakeoverContextProvider onClose={onClose}>
      <ChildComponent {...props} />
    </TakeoverContextProvider>
  )
}

const BookingLeaseTakeoverComponent = ({ ChildComponent, ...props }) => {
  const { bookingId } = useParams()
  const { booking, bookingUser } = useBookingContext({ shouldThrowIfUndefined: false })
  const navigate = useNavigate()
  const applicant = booking?.applicants.find((a) => a.userId === bookingUser?.id)
  const steps = booking && applicant ? getApplicantSteps(booking, applicant) : undefined
  const incompleteStatus = [ParticipantStepStatus.NOT_STARTED, ParticipantStepStatus.STARTED]

  const onClose = () => {
    const goToPayment = bookingUser?.type === 'applicant' && incompleteStatus.includes(steps?.payment.status)
    navigate(goToPayment ? bookingPaymentPath(booking.id) : inquiryPath(bookingId))
  }

  return (
    <TakeoverContextProvider onClose={onClose}>
      <ChildComponent {...props} />
    </TakeoverContextProvider>
  )
}

const LeaseTakeoverComponent = ({ ChildComponent, ...props }) => {
  const navigate = useNavigate()
  const onClose = () => navigate('/')

  return (
    <TakeoverContextProvider onClose={onClose}>
      <ChildComponent {...props} />
    </TakeoverContextProvider>
  )
}

const BookingRoommateIntroTakeoverComponent = ({ ChildComponent, ...props }) => {
  const { bookingId } = useParams()
  const navigate = useNavigate()

  const onClose = () => {
    navigate('/')
  }

  return (
    <TakeoverContextProvider onClose={onClose}>
      <BookingRoommateContextProvider bookingId={bookingId}>
        <ChildComponent {...props} />
      </BookingRoommateContextProvider>
    </TakeoverContextProvider>
  )
}

const BookingInvitedLandlordTakeoverComponent = ({ ChildComponent, ...props }) => {
  const { bookingId } = useParams()
  const navigate = useNavigate()

  const onClose = () => {
    navigate('/')
  }

  return (
    <TakeoverContextProvider onClose={onClose}>
      <BookingJwtContextProvider bookingId={bookingId}>
        <ApplicationComponentsContextProvider queryKey='booking'>
          <ChildComponent {...props} />
        </ApplicationComponentsContextProvider>
      </BookingJwtContextProvider>
    </TakeoverContextProvider>
  )
}

export const routes = {
  LeaseGuarantor: {
    ...defaultRouteConfig,
    path: ':bookingId/lease/guarantor',
    Component: (props) => <LeaseTakeoverComponent ChildComponent={GuarantorLeaseComponent} {...props} />,
  },
  LeaseInvitedLessor: {
    ...defaultRouteConfig,
    path: ':bookingId/lease/invited-lessor',
    Component: (props) => <LeaseTakeoverComponent ChildComponent={InvitedLessorLeaseComponent} {...props} />,
  },
  BookingInvitedLandlord: {
    path: ':bookingId',
    exact: true,
    Component: (props) => (
      <BookingInvitedLandlordTakeoverComponent ChildComponent={BookingInvitedLandlordScreen} {...props} />
    ),
  },
  BookingRoommateIntro: {
    path: ':bookingId/roommate-intro-review',
    exact: true,
    Component: (props) => (
      <BookingRoommateIntroTakeoverComponent ChildComponent={BookingRoommateIntroReviewScreen} {...props} />
    ),
  },
  BookingId: {
    ...defaultRouteConfig,
    path: ':bookingId/*',
    Component: BookingNestedRouter,
    routes: {
      BookingApplicants: {
        ...defaultRouteConfig,
        path: 'applicants/*',
        Component: (props) => <BookingApplicantComponent {...props} />,
        externalRoutes: {
          edit: 'applicants/edit',
          view: 'applicants/application',
          applicant: 'applicants/:userId/application',
          applications: 'applicants/applications',
        },
      },
      BookingDocument: {
        ...defaultRouteConfig,
        path: 'document/*',
        Component: (props) => <BookingDocumentComponent {...props} />,
        externalRoutes: {
          createSuccess: 'create-success',
          newBookingDocument: 'new',
          viewBookingDocuments: 'documents',
        },
      },
      BookingModule: {
        ...defaultRouteConfig,
        requireAuthentication: true,
        path: 'embedded',
        exact: true,
        Component: (props) => <BookingModuleComponent {...props} />,
      },
      BookingPayment: {
        ...defaultRouteConfig,
        path: 'booking-payment/*',
        Component: (props) => (
          <TakeoverComponent ChildComponent={BookingPaymentComponent} forcedRoute={true} {...props} />
        ),
        externalRoutes: {
          root: 'booking-payment',
          contribution: 'booking-payment/contribution',
        },
      },
      BookingRentPayment: {
        ...defaultRouteConfig,
        path: 'rent-payment/*',
        Component: (props) => <BookingRentPaymentComponent {...props} />,
        externalRoutes: {
          root: 'rent-payment',
          invoice: 'rent-payment/invoice/:invoiceId',
        },
      },
      Lease: {
        ...defaultRouteConfig,
        exact: true,
        path: 'lease/*',
        Component: (props) => <BookingLeaseTakeoverComponent ChildComponent={LeaseComponent} {...props} />,
        externalRoutes: {
          root: 'lease',
          sign: 'lease/sign',
        },
      },
    },
  },
  ListBookings: {
    path: 'list',
    exact: true,
    Component: (props) => <ListBookingsScreen {...props} />,
  },
} as const

export const bookingApplicationRoutes = {
  BookingApplications: {
    ...defaultRouteConfig,
    path: 'booking-applications/*',
    routes: {
      SelectApplication: {
        ...defaultRouteConfig,
        requireAuthentication: true,
        requireAccountActivation: true,
        path: '/',
        Component: (props) => <TakeoverComponent ChildComponent={BookingApplications} forcedRoute={true} {...props} />,
      },
    },
    externalRoutes: {
      selectApplication: 'booking-applications',
      application: '/rental-applications/:bookingId/application/:rentalApplicationId',
      applications: '/rental-applications/:bookingId/application',
    },
  },
}
