import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useAuthenticationContext, useLeaseApiClient } from '../../../hooks'
import { useQuebecLeaseContext } from '../../../hooks/use-quebec-lease-context'
import { getCurrentParticipant } from '../../../utils/get-current-participant'
import { Step, StepProps } from '../../common'
import { FormContent } from './FormContent'

type Props = StepProps

const StepACurrentUser: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()
  const { user } = useAuthenticationContext()

  const {
    leaseId,
    lease: {
      leaseInformation: { lessors, lessees },
    },
  } = useQuebecLeaseContext()

  const onSubmit = (payload: QuebecSchemas.Participant) =>
    apiClient.updateQuebecLeaseCurrentParticipant({ pathParams: { leaseId }, payload })

  return (
    <Step
      hasBackButton={false}
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(QuebecSchemas.participant),
        defaultValues: getCurrentParticipant({ lessors, lessees, userId: user.id }),
      }}
      formName={'QuebecLeaseFormStepA'}
      {...props}
    >
      <FormContent lessors={lessors} lessees={lessees} />
    </Step>
  )
}

export { StepACurrentUser }
