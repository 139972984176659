import React from 'react'

import { ApiForm, Box, Grid } from '../../../components'
import { FormContent as FormContentA } from '../../../components/quebec-lease/step-a/FormContent'
import { FormContent as FormContentB } from '../../../components/quebec-lease/step-b/FormContent'
import { FormContent as FormContentC } from '../../../components/quebec-lease/step-c/FormContent'
import { FormContent as FormContentD } from '../../../components/quebec-lease/step-d/FormContent'
import { FormContent as FormContentE } from '../../../components/quebec-lease/step-e/FormContent'
import { FormContent as FormContentF } from '../../../components/quebec-lease/step-f/FormContent'
import { FormContent as FormContentH } from '../../../components/quebec-lease/step-h/FormContent'
import { Quebec } from '../../../types'

type Props = {
  id: string
  data: Quebec.LeaseInformation
}

export const ViewLeaseContent: React.FC<Props> = ({ data }) => {
  return (
    <ApiForm formName={'ViewLeaseForm'}>
      <Box px={4} pb={4}>
        <Grid container spacing={4} direction='column'>
          <Grid item>
            <FormContentA lessors={data.lessors} lessees={data.lessees} />
          </Grid>
          <Grid item>
            <FormContentB dwelling={data.dwelling} lessors={data.lessors} lessees={data.lessees} />
          </Grid>
          <Grid item>
            <FormContentC leaseTerm={data.leaseTerm} />
          </Grid>
          <Grid item>
            <FormContentD rent={data.rent} />
          </Grid>
          <Grid item>
            <FormContentE servicesAndConditions={data.servicesAndConditions} />
          </Grid>
          <Grid item>
            <FormContentF
              lessors={data.lessors}
              leaseRestrictionsAndModifications={data.leaseRestrictionsAndModifications}
              newLesseeNotice={data.newLesseeNotice}
            />
          </Grid>
          <Grid item>
            <FormContentH lessors={data.lessors} lessees={data.lessees} liability={data.liability} />
          </Grid>
        </Grid>
      </Box>
    </ApiForm>
  )
}
