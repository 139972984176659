import { LeaseFeedbackScreen } from './LeaseFeedbackScreen'
import {
  OntarioCreateLease,
  OntarioGuarantorSignLease,
  OntarioInvitedLessorSignLease,
  OntarioSignLeaseScreen,
  OntarioViewLeaseScreen,
} from './ontario-lease'
import {
  QuebecCreateLease,
  QuebecGuarantorSignLease,
  QuebecInvitedLessorSignLease,
  QuebecSignLeaseScreen,
  QuebecViewLeaseScreen,
} from './quebec-lease'

export type BookingAddressState = 'QC' | 'ON'

const quebecScreens = {
  CreateLease: QuebecCreateLease,
  SignLeaseScreen: QuebecSignLeaseScreen,
  GuarantorSignLease: QuebecGuarantorSignLease,
  InvitedLessorSignLease: QuebecInvitedLessorSignLease,
  ViewLeaseScreen: QuebecViewLeaseScreen,
  LeaseFeedbackScreen,
}

const ontarioScreens = {
  CreateLease: OntarioCreateLease,
  SignLeaseScreen: OntarioSignLeaseScreen,
  GuarantorSignLease: OntarioGuarantorSignLease,
  InvitedLessorSignLease: OntarioInvitedLessorSignLease,
  ViewLeaseScreen: OntarioViewLeaseScreen,
  LeaseFeedbackScreen,
}

export const getLeaseScreens = (state: BookingAddressState) => {
  switch (state) {
    case 'QC':
      return quebecScreens
    case 'ON':
      return ontarioScreens
    default:
      throw new Error(`No lease for the given state[${state}]`)
  }
}
