import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { Flex } from '@guiker/react-framework'
import { RentCollectionPerTenantTable } from '@guiker/statistic-components'
import { findGroupNameByStatus, RentCollectionStatusGroupName } from '@guiker/statistic-shared'

import { DashboardLayout } from '../../components'
import { RentCollectionContextProvider, RentCollectionMonthPicker, useRentCollectionContext } from '../../context'
import { useT } from '../../i18n'

const PerTenantContent: React.FC = () => {
  const { date, region, rentStatuses, setRentStatuses } = useRentCollectionContext()
  const statusGroupName = findGroupNameByStatus<RentCollectionStatusGroupName>(rentStatuses ?? [])

  return (
    <Flex flexDirection='column' gap={4} pt={2}>
      <RentCollectionMonthPicker />
      <RentCollectionPerTenantTable
        region={region}
        date={date}
        isPaginated={false}
        selectedStatusGroup={statusGroupName}
        onSelect={setRentStatuses}
      />
    </Flex>
  )
}

export const RentCollectionScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens' })

  return (
    <DashboardLayout
      breadcrumbItems={[
        { to: mainPathBuilder.root.path, label: tMain('dashboard.title') },
        {
          to: mainPathBuilder.root.rentalDashboard.path,
          label: tMain('dashboard.rentalDashboard.title'),
        },
        { label: tMain('rentCollection.title') },
      ]}
      title={tMain('rentCollection.title')}
    >
      <RentCollectionContextProvider>
        <PerTenantContent />
      </RentCollectionContextProvider>
    </DashboardLayout>
  )
}
