import React, { PropsWithChildren } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'

import { PageLayout, PageLayoutProps } from '../../components'
import { useSideMenuItems } from '../../hooks'

type DashboardLayoutProps = PropsWithChildren & Pick<PageLayoutProps, 'breadcrumbItems' | 'title'>

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ breadcrumbItems, children, title }) => {
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)

  return (
    <PageLayout sideMenuProps={{ items: sideMenuNavItems }} breadcrumbItems={breadcrumbItems} title={title}>
      {children}
    </PageLayout>
  )
}
