import { ChannelType, NotificationPreference, PreferenceType, PreferenceTypes } from '@guiker/notification-shared'

const filterPreferences = (notificationPreference: NotificationPreference, group: PreferenceType[]) => {
  return notificationPreference.preferences.filter((p) => group.includes(p.type))
}

export const transformNotificationPreference = (notificationPreference: NotificationPreference) => {
  return {
    bookingUpdates: {
      email: false,
      sms: filterPreferences(notificationPreference, [PreferenceTypes.BOOKING.BOOKING]).every(
        ({ channels }) => channels?.find((channel) => channel.type === ChannelType.SMS)?.isEnabled,
      ),
    },
    listingRecommendations: {
      sms: false,
      email: filterPreferences(notificationPreference, [PreferenceTypes.LISTING.RECOMMENDED_LISTING]).every(
        ({ channels }) => channels?.find((channel) => channel.type === ChannelType.EMAIL)?.isEnabled,
      ),
    },
  }
}
