import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { FormSection3 } from '@guiker/components-library'
import { ScopeType } from '@guiker/contract-shared'
import { PaginatedTable, Pagination } from '@guiker/react-query-components'

import { useAuthenticatedContractApiClient } from '../../hooks'
import { useT } from '../../i18n'
import { useGetContractTableColumns } from '../../utils'

const LeaseDocumentsTable: React.FC = () => {
  const apiClient = useAuthenticatedContractApiClient()
  const { user } = useAuthenticationContext()
  const { tMain } = useT({ screenName: 'screens.documents' })
  const columns = useGetContractTableColumns()

  const fetcher = (paginate: Required<Pagination>) =>
    apiClient.readAllByScope({
      queryParams: { ...paginate, locale: null, scopeTypes: [ScopeType.LEASE] },
    })

  return (
    <FormSection3 title={tMain('leaseTable')}>
      <PaginatedTable
        queryKey={`lease-${user?.id}`}
        fetcher={fetcher}
        columns={columns}
        pagination={{
          perPage: 10,
          sort: 'createdAt',
          sortOrder: -1,
        }}
        isRowCollapsible
      />
    </FormSection3>
  )
}

export { LeaseDocumentsTable }
