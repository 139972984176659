import React from 'react'

import { FormSection3, OneColumnGridLayout, P, PBold } from '@guiker/components-library'
import { Trans } from '@guiker/i18n'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { TextField } from '../../'

type Props = {
  defaultValue: Quebec.WorkAndRepairs
  name: string
  readOnly: boolean
}

export const WorkRepairs: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const workAndRepairs = useWatch({ name: `${name}.workAndRepairs`, defaultValue })

  return (
    <FormSection3
      title={t('quebecLeaseScreenE:workRepairsLabel')}
      subtitle={t('quebecLeaseScreenE:workRepairsByLessorLabel')}
    >
      <OneColumnGridLayout>
        <TextField
          defaultValue={workAndRepairs?.repairBeforeDwellingDelivered}
          readOnly={readOnly}
          label={
            <P>
              <Trans i18nKey={'quebecLeaseScreenE:repairBeforeDwellingDelivered'}>
                <PBold component='span' />
              </Trans>
            </P>
          }
          name={`${name}.workAndRepairs.repairBeforeDwellingDelivered`}
        />
        <TextField
          defaultValue={workAndRepairs?.repairDuringLease}
          readOnly={readOnly}
          label={
            <P>
              <Trans i18nKey={'quebecLeaseScreenE:repairDuringLeaseLabel'}>
                <PBold component='span' />
              </Trans>
            </P>
          }
          name={`${name}.workAndRepairs.repairDuringLease`}
        />
      </OneColumnGridLayout>
    </FormSection3>
  )
}
