import React from 'react'

import { AuthType } from '@guiker/authentication-context'
import { legacyRoutes, MyListingRoute } from '@guiker/legacy-routes'
import { ButtonContainer, PageLayout, useNavigate } from '@guiker/react-framework'
import { TakeoverContextProvider } from '@guiker/takeover-context'

import { ListingContent } from '../../components/ListingContent'
import { useAuthenticatedListingContext, useAuthenticationContext, useAuthenticationModalContext } from '../../hooks'
import { SignContractButton } from './SignContractButton'

const ReviewLeaseContractListingScreen: React.FC = () => {
  const { data: listing } = useAuthenticatedListingContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const { user: currentUser } = useAuthenticationContext()
  const navigate = useNavigate()

  const onClickEdit = (route?: MyListingRoute) => {
    if (currentUser) {
      navigate(`${legacyRoutes.MyListingEdit(Number(listing.id), route)}`, { isExternalLink: true })
    } else {
      openAuthenticationModal(AuthType.ACTIVATE)
    }
  }

  return (
    <TakeoverContextProvider onClose={() => navigate('..')}>
      <PageLayout maxWidth={900}>
        <ListingContent listing={listing} isRentedOut={false} onClickEdit={onClickEdit} />
        <ButtonContainer>
          <SignContractButton />
        </ButtonContainer>
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { ReviewLeaseContractListingScreen }
