import React from 'react'

import { hasDocument } from '@guiker/propsharing-shared'
import { PageSection1 } from '@guiker/react-framework'

import {
  CommonQuestions,
  InvestmentPerformance,
  ListingDocuments,
  OfferingDetails,
  PropertyDescription,
  RentalDetails,
} from '../../../components'
import { useListingScreenContext } from '../../../context'

export const DetailsTab: React.FC = () => {
  const { listing } = useListingScreenContext()

  return (
    <PageSection1>
      <PropertyDescription />
      <InvestmentPerformance />
      <RentalDetails />
      <OfferingDetails />
      {hasDocument(listing) && <ListingDocuments listing={listing} />}
      <CommonQuestions />
    </PageSection1>
  )
}
