import React from 'react'

import { useAuthApiClient } from '@guiker/propsharing-context'
import { webappRoutes } from '@guiker/propsharing-shared'
import { Button, Card, Flex, H3, Link, P, useQuery } from '@guiker/react-framework'

import { useAuthenticationContext } from '../../hooks'
import { useT } from '../../i18n'

const FundingAccountEmptyState: React.FC = () => {
  const { tMain } = useT({ screenName: 'components.fundingAccountEmptyState' })
  const apiClient = useAuthApiClient()
  const { user } = useAuthenticationContext()

  const { data, isFetching } = useQuery(
    [user.id, 'read-user-investor-profile'],
    (_) => apiClient.readOneInvestorProfile(),
    {
      retry: 1,
      enabled: !!user,
    },
  )
  if (isFetching) return <></>

  const shouldLinkToInvestorProfile = !data || (!!data && data.status !== 'INFORMATION_COMPLETED')

  return (
    <Card shadow='medium' p={4}>
      <Flex flexDirection='column' gap={5}>
        <H3 mb={0}>{tMain('title')}</H3>
        <div>
          <P>{tMain('text')}</P>
          <P mb={0}>{tMain(shouldLinkToInvestorProfile ? 'text2' : 'text3')}</P>
        </div>
        {shouldLinkToInvestorProfile && (
          <Link to={webappRoutes.findOrCreateInvestorProfile.path} hoverUnderline={false}>
            <Button>{tMain('cta')}</Button>
          </Link>
        )}
      </Flex>
    </Card>
  )
}

export { FundingAccountEmptyState }
