import consent from '../screens/ConsentBackgroundCheck/en.i18n.json'
import creditReport from '../screens/CreditReport/en.i18n.json'
import notConsent from '../screens/NotConsentBackgroundCheck/en.i18n.json'
import report from '../screens/Report/en.i18n.json'
import reports from '../screens/Reports/en.i18n.json'
import request from '../screens/RequestBackgroundCheck/en.i18n.json'
import softcheckReport from '../screens/SoftcheckReport/en.i18n.json'
import step01 from '../screens/Step01/en.i18n.json'
import step02 from '../screens/Step02/en.i18n.json'
import common from './common/en.i18n.json'

export default {
  common,
  creditReport,
  report,
  reports,
  request,
  softcheckReport,
  consent,
  notConsent,
  step01,
  step02,
}
