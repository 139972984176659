import React from 'react'

import { AppContextProvider } from './context/AppContext'
import { i18n, I18nextProvider } from './i18n'
import { Router } from './routes'

const App: React.FC<React.PropsWithChildren> = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <AppContextProvider>
        <Router />
      </AppContextProvider>
    </I18nextProvider>
  )
}

export default App
