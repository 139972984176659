import { useAuthenticationContext } from '@guiker/authentication-context'
import { ApiClient, JwtAuthenticatedApiClient } from '@guiker/background-check-shared'
import { useConfig } from '@guiker/config-context'
import { useJwtAuthenticatedApiClient } from '@guiker/react-framework'
import { v4 as uuidv4 } from '@guiker/uuid'

export const useBackgroundCheckApiClient = (token?: string) => {
  const { user } = useAuthenticationContext()
  const config = useConfig()

  return ApiClient({
    accessToken: token || user.accessToken,
    baseUrl: config.apiBaseUrl,
    correlatedRequestId: uuidv4(),
    logger: config.debug ? console : undefined,
  })
}

export const useBackgroundCheckJwtAuthenticatedApiClient = useJwtAuthenticatedApiClient(JwtAuthenticatedApiClient)
