import React, { Ref } from 'react'

import { clsx, lighten, makeStyles, theme } from '../../../style'
import { TextField } from '../../'

export interface SignatureInputProps {
  className?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
  name: string
  label?: string
  defaultValue?: string
  value?: string
  inputRef?: Ref<any> | (() => Ref<any>)
}

const useStyles = makeStyles({
  enabled: {
    '& .MuiInputBase-root': {
      border: `2px solid ${theme.palette.status.success.main}`,
      backgroundColor: lighten(theme.palette.status.success.background, 0.5),
      boxShadow: 'none',
      '& fieldset': {
        color: theme.palette.status.success.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.status.success.background,
      },
      '&:focus-within': {
        backgroundColor: theme.palette.status.success.background,
      },
    },
  },
  root: {
    boxSizing: 'border-box',
  },
})

const SignatureInput: React.FC<SignatureInputProps> = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <TextField
      className={clsx(
        classes.root,
        {
          [classes.enabled]: !props.disabled,
        },
        className,
      )}
      {...props}
    />
  )
}

export { SignatureInput }
