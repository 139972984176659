import { Location } from '@guiker/base-entity'
import { addressFormatter } from '@guiker/base-entity'
import { RentalListing, webappRoutes } from '@guiker/rental-listing-shared'

import { getLocalizedCity, getLocalizedNeighbourhood, TFunction } from '../i18n'

export const getCityBreadcrumbItems = (
  t: TFunction,
  { countryCode, citySlug, stateCode }: { countryCode: string; citySlug: string; stateCode: string },
) => {
  return [
    {
      label: `${getLocalizedCity(t, { countryCode, citySlug })}, ${stateCode}`,
      to: webappRoutes.listings.countryNested(countryCode).cityNested(citySlug, stateCode).path,
    },
  ]
}

export const getCityBreadcrumbItemsFromLocation = (t: TFunction, location: Location) => {
  return getCityBreadcrumbItems(t, {
    countryCode: location.country.countryCode,
    citySlug: location.city.slug,
    stateCode: location.state.stateCode,
  })
}

export const getNeighbourhoodBreadcrumbItems = (
  t: TFunction,
  {
    countryCode,
    citySlug,
    neighbourhoodSlug,
    stateCode,
  }: { countryCode: string; citySlug: string; stateCode: string; neighbourhoodSlug: string },
) => {
  return [
    ...getCityBreadcrumbItems(t, { countryCode, citySlug, stateCode }),
    {
      label: getLocalizedNeighbourhood(t, { countryCode, citySlug, neighbourhoodSlug }),
      to: webappRoutes.listings
        .countryNested(countryCode)
        .cityNested(citySlug, stateCode)
        .neighbourhoodNested(neighbourhoodSlug).path,
    },
  ]
}

export const getNeighbourhoodBreadcrumbItemsFromLocation = (t: TFunction, location: Location) => {
  return getNeighbourhoodBreadcrumbItems(t, {
    countryCode: location.country.countryCode,
    citySlug: location.city.slug,
    stateCode: location.state.stateCode,
    neighbourhoodSlug: location.neighbourhood?.slug,
  })
}

export const getListingBreadcrumbItems = (
  t: TFunction,
  {
    countryCode,
    citySlug,
    neighbourhoodSlug,
    stateCode,
    listing,
  }: { countryCode: string; citySlug: string; stateCode: string; neighbourhoodSlug: string; listing?: RentalListing },
) => {
  const listingBreadcrumb = listing
    ? [
        {
          label: addressFormatter.printStreetInformation(listing.address),
          to: webappRoutes.listings
            .countryNested(countryCode)
            .cityNested(citySlug, stateCode)
            .neighbourhoodNested(neighbourhoodSlug)
            .withId(listing.id).path,
        },
      ]
    : []

  return [
    ...getNeighbourhoodBreadcrumbItems(t, { countryCode, citySlug, stateCode, neighbourhoodSlug }),
    ...listingBreadcrumb,
  ]
}

export const getListingBreadcrumbItemsFromLocation = (t: TFunction, location: Location, listing?: RentalListing) => {
  return getListingBreadcrumbItems(t, {
    countryCode: location.country.countryCode,
    citySlug: location.city.slug,
    stateCode: location.state.stateCode,
    neighbourhoodSlug: location.neighbourhood?.slug,
    listing,
  })
}
