import React from 'react'

import { ColumnGridItem, Note, OneColumnGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useAuthenticationContext, useFormContext, useLeaseContext, useTranslation, useWatch } from '../../../hooks'
import { Quebec } from '../../../types'
import { CurrencyTextField, CurrencyTextFieldProps, RadioGroup } from '../../'
import { SubsidyEligibility } from './SubsidyEligibility'

type Props = {
  name: 'rent'
  defaultValue: Quebec.Rent
}

export const Rent: React.FC<Props> = ({ name, defaultValue: rent }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenD'])
  const { can } = useLeaseContext()
  const { user } = useAuthenticationContext()
  const isAdmin = user?.isAdmin

  const canDraftLease = can(LeaseActions.DraftLease)

  const form = useFormContext<Quebec.Rent>()
  const rentFrequency = useWatch({ name: 'rent.rentFrequency', defaultValue: rent.rentFrequency })
  const rentFrequencyUnit = t(`quebecLeaseScreenD:${rentFrequency}`)

  return (
    <OneColumnGridLayout>
      <CurrencyTextField
        label={t('quebecLeaseScreenD:rentAmountLabel', { unit: rentFrequencyUnit })}
        defaultValue={rent.rentAmount}
        readOnly={isAdmin ? false : !canDraftLease}
        disabled={isAdmin ? false : !canDraftLease}
        name={`${name}.rentAmount`}
      />

      <CurrencyTextField
        label={t('quebecLeaseScreenD:serviceCostLabel', { unit: rentFrequencyUnit })}
        name={`${name}.serviceCharge`}
        defaultValue={rent.serviceCharge}
        helperText={form.formState.errors.serviceCharge?.type === 'typeError' && 'Required'}
        readOnly={isAdmin ? false : !canDraftLease}
        disabled={isAdmin ? false : !canDraftLease}
      />

      <TotalCostTextField
        label={t('quebecLeaseScreenD:totalCostLabel', { unit: rentFrequencyUnit })}
        name={`${name}.totalCost`}
        readOnly={isAdmin ? false : !canDraftLease}
        disabled
        rent={rent}
      />

      <RadioGroup
        defaultValue={rent.rentFrequency}
        readOnly={isAdmin ? false : !canDraftLease}
        disabled={isAdmin ? false : !canDraftLease}
        name={`${name}.rentFrequency`}
        options={[
          { value: Quebec.Frequency.perMonth, label: t('quebecLeaseScreenD:rentPerMonth') },
          { value: Quebec.Frequency.perWeek, label: t('quebecLeaseScreenD:rentPerWeek') },
        ]}
      />
      <ColumnGridItem md={2}>
        <Note statusColor='warning'>{t('quebecLeaseScreenD:secondaryHeaderLabel')}</Note>
      </ColumnGridItem>
      <SubsidyEligibility name={name} defaultValue={rent} />
    </OneColumnGridLayout>
  )
}

type TotalCostTextFieldProps = CurrencyTextFieldProps & {
  rent: Quebec.Rent
}

const TotalCostTextField: React.FC<TotalCostTextFieldProps> = ({ rent, ...props }) => {
  const rentAmount = useWatch({ name: 'rent.rentAmount', defaultValue: rent.rentAmount })
  const serviceChargeAmount = useWatch({ name: 'rent.serviceCharge', defaultValue: rent.serviceCharge })
  const value = Number(rentAmount || 0) + Number(serviceChargeAmount || 0)

  return <CurrencyTextField defaultValue={value} value={value} {...props} />
}
