import React from 'react'

import { buildLocationNestedRoutes } from '@guiker/base-listing-components'
import { PageNotFound } from '@guiker/error-pages'
import { usePublicListingContext } from '@guiker/rental-listing-context'
import { slugifyListing } from '@guiker/rental-listing-shared'
import { RedirectNoIndex, RouteConfig } from '@guiker/router'

import {
  ListingScreen,
  NeighbourhoodScreen,
  ReviewLeaseContractListingScreen,
  SetTenantPreferenceScreen,
} from '../screens'
import { CityScreen } from '../screens/CityScreen/CityScreen'
import { CountryScreen } from '../screens/CountryScreen'
import { AuthListingNestedRouter } from './AuthListingNestedRouter'
import { DeprecatedListingRoute } from './DeprecatedListingRoute'
import { PublicListingNestedRouter } from './PublicListingNestedRouter'

const ListingInquiryComponent = React.lazy(() => import('../components/ListingInquiry'))

const defaultRouteConfig: Partial<RouteConfig> = {
  requireAuthentication: true,
  requireAccountActivation: false,
  authenticationMethod: 'user',
}

const { countryNested } = buildLocationNestedRoutes({
  routes: {
    router: PublicListingNestedRouter,
    path: {
      id: () => {
        const { data: listing } = usePublicListingContext()
        const slug = slugifyListing(listing)
        return slug ? <RedirectNoIndex path={`../${slug}`} /> : <PageNotFound />
      },
      slugified: ListingScreen,
      country: CountryScreen,
      city: CityScreen,
      neighbourhood: NeighbourhoodScreen,
    },
  },
})

const routes = {
  listingNested: {
    path: '/:id',
    Component: PublicListingNestedRouter,
    displayFooter: false,
    routes: {
      tenantPreferences: {
        ...defaultRouteConfig,
        path: 'tenant-preferences/*',
        Component: SetTenantPreferenceScreen,
      },
      listingInquiry: {
        ...defaultRouteConfig,
        path: `listing-inquiries/*`,
        Component: (props) => <ListingInquiryComponent {...props} />,
      },
      deprecatedListingRoute: {
        path: '/',
        Component: DeprecatedListingRoute,
      },
    },
  },
  countryNested,
  listingAuthNested: {
    path: '/:id',
    Component: AuthListingNestedRouter,
    displayFooter: false,
    routes: {
      reviewLeaseContractListing: {
        path: 'review-lease-contract-listing',
        Component: ReviewLeaseContractListingScreen,
        requireAccountActivation: false,
      },
    },
  },
} as const

export { routes }
