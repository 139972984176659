import React from 'react'

import { AppBar } from '../AppBar'

type AppLayoutProps = React.PropsWithChildren & {
  categories: string[]
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, categories }) => {
  return (
    <>
      <AppBar categories={categories} />
      {children}
    </>
  )
}

export { AppLayout }
