import React from 'react'

import { FormSection1, FormSection2 } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useLeaseContext, useTranslation } from '../../../hooks'
import { Quebec as Types } from '../../../types'
import { HeaderSecondaryDescriptionBar } from '../header'
import { FormPerson } from './FormPerson'

type Props = {
  lessors: Types.Lessor[]
  lessees: Types.Lessee[]
}

const FormContent: React.FC<Props> = ({ lessors = [], lessees = [] }) => {
  const { t } = useTranslation()
  const { disabledForCurrentUser } = useAppContext()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)
  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)

  return (
    <FormSection1 hasDivider={false}>
      <FormSection2
        title={
          <HeaderSecondaryDescriptionBar index='A.' description={t('quebecLeaseScreenA:secondaryHeaderDescription')} />
        }
      >
        {lessors.map((lessor: Types.Lessor, index: number) => (
          <FormPerson
            key={index}
            fullNameFieldLabel={t('quebecLeaseScreenA:lessorFullNameLabel')}
            defaultValue={lessor}
            readOnly={!canDraftLease}
          />
        ))}
      </FormSection2>
      <FormSection2
        title={<HeaderSecondaryDescriptionBar index='A.' description={t('quebecLeaseScreenA:andTheLessee')} />}
        hasDivider={true}
      >
        {lessees.map((lessee: Types.Lessee, index: number) => (
          <FormPerson
            key={index}
            fullNameFieldLabel={t('quebecLeaseScreenA:lesseeFullNameLabel')}
            defaultValue={lessee}
            readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
          />
        ))}
      </FormSection2>
    </FormSection1>
  )
}

export { FormContent }
