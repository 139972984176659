import React from 'react'

import { CollapsibleDescription, ListOfProjectAttributes, Location } from '@guiker/base-listing-components'
import { isCrawlerUserAgent } from '@guiker/prerender'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { FormSection3, safelyGetWindow, useTranslation } from '@guiker/react-framework'
import { projectHasDocument, ProjectWithDeveloper } from '@guiker/real-estate-shared'
import { pascalCase } from '@guiker/shared-framework'

import { ProjectDocuments } from '../../../components'

type ProjectAndDocumentsTabProps = {
  project: ProjectWithDeveloper
  listing: PropertySaleListing
}

const ProjectAndDocumentsTab: React.FC<ProjectAndDocumentsTabProps> = ({ project, listing }) => {
  const { t } = useTranslation('main-propsharing')
  const userAgent = safelyGetWindow()?.navigator.userAgent
  const isCrawler = userAgent && isCrawlerUserAgent(userAgent, true)

  return (
    <>
      {project && (
        <>
          <FormSection3 title={t('components.projectTab.aboutProject', { projectName: project.name })}>
            <CollapsibleDescription description={project.description} />
          </FormSection3>
          <ListOfProjectAttributes project={project} listing={listing} />
          {!isCrawler && (
            <FormSection3 title={t('components.projectTab.location')}>
              <Location
                address={listing.property?.building?.address}
                coordinates={listing.property?.building?.geocoordinates}
              />
            </FormSection3>
          )}
          <FormSection3
            title={t('components.projectTab.aboutDeveloper', {
              developerName: pascalCase(project.developer.name),
            })}
          >
            <CollapsibleDescription defaultCollapsed={false} description={project.developer.description} />
          </FormSection3>
        </>
      )}
      {projectHasDocument(project) && (
        <FormSection3 title={t('components.projectTab.projectDocuments.header')}>
          <ProjectDocuments project={project} />
        </FormSection3>
      )}
    </>
  )
}

export { ProjectAndDocumentsTab }
