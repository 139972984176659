import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { Divider, FormSection2, HeaderDescriptionBar } from '../../'
import { LandlordContact } from './LandlordContact'
import { NoticeAddress } from './NoticeAddress'
import { NoticeEmail } from './NoticeEmail'

type Props = {
  contactInformation: Types.ContactInformation
}

export const FormContent: React.FC<Props> = ({ contactInformation }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()

  const canDraftLease = can(LeaseActions.DraftLease)

  return (
    <FormSection2
      title={<HeaderDescriptionBar index='3.' description={t('ontarioLeaseScreenB:contactInformation')} />}
      subtitle={t('ontarioLeaseScreenB:addressForNotice')}
    >
      <NoticeAddress defaultValue={contactInformation?.address} name='address' readOnly={!canDraftLease} />
      <Divider />
      <NoticeEmail defaultValue={contactInformation} readOnly={!canDraftLease} />
      <Divider />
      <LandlordContact defaultValue={contactInformation} readOnly={!canDraftLease} />
    </FormSection2>
  )
}
