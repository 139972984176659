import React from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import { useNavigate } from '@guiker/router'

import {
  CurrentAddressStep,
  FullScreenSpinner,
  GuarantorAndBackgroundCheckStep,
  MyProfileStep,
  SupportingDocumentsStep,
  TenantApplicationStepper,
} from '../../components'
import { useTenantApplicationContext, useTranslation } from '../../hooks'

const CreateTenantApplicationScreen: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const { isLoading, tenantApplication } = useTenantApplicationContext()

  const steps = [
    {
      name: '1',
      component: MyProfileStep,
    },
    {
      name: '2',
      component: CurrentAddressStep,
    },
    {
      name: '3',
      component: GuarantorAndBackgroundCheckStep,
    },
    {
      name: '4',
      component: SupportingDocumentsStep,
    },
  ]

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <TenantApplicationStepper
      takeoverProps={{ onClose: () => navigate(mainPathBuilder.root.messages.path()) }}
      isLoading={isLoading}
      onLastStepClicked={() => navigate('../tenant-application/view')}
      nextLabel={t('common:next')}
      backLabel={t('common:back')}
      steps={steps}
      tenantApplication={tenantApplication}
    />
  )
}

export { CreateTenantApplicationScreen }
