import React from 'react'

import { Modal, P, PBold, Trans, useT } from '@guiker/react-framework'

type DocumentExplanationModalProp = {
  isOpen: boolean
  onClose: () => void
}

const DocumentExplanationModal: React.FC<DocumentExplanationModalProp> = ({ isOpen, onClose }) => {
  const { t } = useT({ domain: 'payout', screenName: 'payoutMethod.verificationDocuments.faq' })
  const tPrefix = 'payout:payoutMethod.verificationDocuments.faq'
  const points = ['point1', 'point2', 'point3']

  return (
    <Modal open={isOpen} onClose={onClose} title={t(`${tPrefix}.title`)}>
      <P> {t(`${tPrefix}.intro`)}</P>
      {['canada', 'us'].map((type) => (
        <>
          <P component='span'>
            <Trans i18nKey={`${tPrefix}.${type}.intro`}>
              <PBold component='span' />
            </Trans>
          </P>
          <ol>
            {points.map((point) => (
              <li>{t(`${tPrefix}.${type}.${point}`)}</li>
            ))}
          </ol>
        </>
      ))}
    </Modal>
  )
}

export { DocumentExplanationModal }
