import React from 'react'

import { useTranslation } from '../../../hooks'
import { Address } from '../../../types'
import { TextField, TwoColumnsGridLayout } from '../../'

type Props = {
  defaultValue: Address
  name: string
  readOnly?: boolean
}

export const NoticeAddress: React.FC<Props> = ({ defaultValue, name, readOnly }) => {
  const { t } = useTranslation()
  return (
    <TwoColumnsGridLayout>
      <TextField
        defaultValue={defaultValue?.apartmentNumber}
        name={`${name}.apartmentNumber`}
        label={t('ontarioLeaseScreenB:unit')}
        readOnly={readOnly}
        maxWidth='100%'
      />
      <TextField
        defaultValue={defaultValue?.streetNumber}
        name={`${name}.streetNumber`}
        label={t('common:address.streetNumber')}
        readOnly={readOnly}
        maxWidth='100%'
      />
      <TextField
        defaultValue={defaultValue?.street}
        name={`${name}.street`}
        label={t('common:address.street')}
        readOnly={readOnly}
        maxWidth='100%'
      />
      <TextField
        defaultValue={defaultValue?.city}
        name={`${name}.city`}
        label={t('common:address.city')}
        readOnly={readOnly}
        maxWidth='100%'
      />
      <TextField
        defaultValue={defaultValue?.province}
        name={`${name}.province`}
        label={t('common:address.province')}
        readOnly={readOnly}
        maxWidth='100%'
      />
      <TextField
        defaultValue={defaultValue?.postalCode}
        name={`${name}.postalCode`}
        label={t('common:address.postalCode')}
        readOnly={readOnly}
        maxWidth='100%'
      />
      <TextField
        defaultValue={defaultValue?.poBox}
        name={`${name}.poBox`}
        label={t('common:address.poBox')}
        readOnly={readOnly}
        maxWidth='100%'
      />
    </TwoColumnsGridLayout>
  )
}
