import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext } from '../../../hooks'
import { StepProps } from '../../common'
import { StepACurrentUser } from './StepACurrentUser'
import { StepAEdit } from './StepAEdit'

type Props = StepProps

export const StepA: React.FC<Props> = (props) => {
  const { can } = useLeaseContext()

  if (can(LeaseActions.DraftLease)) {
    return <StepACurrentUser {...props} />
  } else {
    return <StepAEdit {...props} />
  }
}
