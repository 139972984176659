import React from 'react'

import { AuthenticatedApi } from '@guiker/payout-shared'
import { ApiForm, Step, StepProps, useTranslation } from '@guiker/react-framework'
import { yupResolver } from '@guiker/react-hook-form'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { SelectTypeFormContent } from '../SelectTypeFormContent'

export type SelectTypeStepProps = StepProps

const SelectTypeStep: React.FC<SelectTypeStepProps> = ({ onClickNext, ...props }) => {
  const apiClient = useAuthenticatedPayoutApiClient()
  const { payoutMethod, updatePayoutMethod } = usePayoutMethodNestedContext()
  const { t } = useTranslation(['main-payout'])

  const resolver = yupResolver(AuthenticatedApi.Schemas.updateTypeSchema)
  const onSubmit = (payload: AuthenticatedApi.Schemas.UpdateTypeSchema) =>
    apiClient.updatePayoutMethodType({ pathParams: { payoutMethodId: payoutMethod.id }, payload })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formName={'PayoutSelectType'}
      formOptions={{
        resolver,
        defaultValues: payoutMethod,
      }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext()
        },
        onSubmitWithoutChange: () => onClickNext(),
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step
          {...props}
          hasBackButton={false}
          isSubmitting={isSubmitting}
          title={t('main-payout:payoutMethodScreen.typeForm.subtitle')}
        >
          {payoutMethod && <SelectTypeFormContent defaultValue={payoutMethod} readOnly={false} />}
        </Step>
      )}
    </ApiForm>
  )
}

export { SelectTypeStep }
