import { RouteConfig } from '@guiker/router'

import {
  ApplicantViewBookingApplicationScreen,
  EditBookingApplicationScreen,
  ListBookingApplicationScreen,
  UnitManagerViewBookingApplicationRoommateIntroScreen,
  UnitManagerViewBookingApplicationScreen,
} from '../screens'
import { ApplicantNestedRouter } from './ApplicantNestedRouter'

const defaultRouteConfig: Partial<RouteConfig> = {
  exact: true,
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes: { [key: string]: RouteConfig } = {
  bookingApplicantNested: {
    ...defaultRouteConfig,
    path: '/',
    Component: ApplicantNestedRouter,
    routes: {
      viewBookingApplicationScreen: {
        ...defaultRouteConfig,
        path: 'application',
        Component: ApplicantViewBookingApplicationScreen,
      },
      editBookingApplicationScreen: {
        ...defaultRouteConfig,
        path: 'edit',
        Component: EditBookingApplicationScreen,
      },
      unitManagerViewBookingApplicationScreen: {
        ...defaultRouteConfig,
        path: ':applicantId/application',
        Component: UnitManagerViewBookingApplicationScreen,
      },
      unitManagerViewBookingApplicationRoommateIntroScreen: {
        ...defaultRouteConfig,
        path: ':applicantId/roommate-profile',
        Component: UnitManagerViewBookingApplicationRoommateIntroScreen,
      },
      listBookingApplicationsScreen: {
        ...defaultRouteConfig,
        path: 'applications',
        Component: ListBookingApplicationScreen,
      },
    },
  },
}

export { routes }
