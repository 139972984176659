import React from 'react'

import { BookingApplication, getTitleStatusColor, HistoryModal } from '@guiker/booking-components'
import { ApplicationStatus } from '@guiker/booking-shared'
import { Flex, IconButton, PageLayoutContextProvider } from '@guiker/components-library'
import { ArrowLeftIcon } from '@guiker/icons'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { Box, Divider, H3, PSmall, Slide, theme, useMediaQuery } from '../../components'
import { useBookingInvitedLandlordScreenContext } from '../../context'
import { useTranslation } from '../../hooks'

export const BookingViewByLandlordApplication: React.FC = () => {
  const { t } = useTranslation(['main-bookingApplicant', 'main-booking'])
  const { handleCloseApplication, selectedApplication, booking, actionOnSuccess } =
    useBookingInvitedLandlordScreenContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmallerMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const tenantApplication = selectedApplication?.content as TenantApplication
  const titleStatus = [ApplicationStatus.ACCEPTED, ApplicationStatus.CHANGE_REQUESTED]

  return (
    <PageLayoutContextProvider maxWidth={theme.dimensions.pageLayout.maxWidth.desktop}>
      <Slide direction='left' in={!!selectedApplication} mountOnEnter unmountOnExit>
        <Box width={'100%'} mb={10}>
          <Flex justifyContent='space-between' alignItems='center' px={4} pt={6}>
            <Flex gap={2} alignItems='center' pb={2}>
              <IconButton onClick={handleCloseApplication}>
                <ArrowLeftIcon />
              </IconButton>
              <H3 mb={0}>
                {t('main-booking:screens.bookingInvitedLandlord.application.header', {
                  fullLegalName: [tenantApplication?.profile?.legalFirstName, tenantApplication?.profile?.legalLastName]
                    .join(' ')
                    .trim(),
                })}
              </H3>
            </Flex>
            {!isSmallerMobile && <HistoryModal bookingId={booking?.id} applicationId={selectedApplication?.userId} />}
          </Flex>
          {titleStatus.includes(selectedApplication?.status) && (
            <PSmall
              color={getTitleStatusColor(selectedApplication?.status)}
              mb={2}
              ml={isMobile ? 7 : 2}
              fontStyle='italic'
            >
              {t(`main-bookingApplicant:status.${selectedApplication?.status}`)}
            </PSmall>
          )}
          {selectedApplication?.status !== ApplicationStatus.CHANGE_REQUESTED && !isMobile && <Divider />}
          <Box px={4} py={4}>
            <BookingApplication
              bookingApplication={selectedApplication}
              bookingId={booking?.id}
              onActionSuccess={actionOnSuccess}
            />
          </Box>
        </Box>
      </Slide>
    </PageLayoutContextProvider>
  )
}
