import React from 'react'

import { Flex } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useLeaseContext, useTranslation } from '../../../hooks'
import { Quebec } from '../../../types'
import { SignatureContainer } from '../../common/SignatureContainer'

interface Props {
  lessors?: Quebec.Lessor[]
  lessees?: Quebec.Lessee[]
}

export const Signatures: React.FC<Props> = ({ lessors, lessees }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenH'])
  const { disabledForCurrentUser } = useAppContext()
  const { can } = useLeaseContext()

  const canSignAsLessor = can(LeaseActions.SignLeaseAsLessor)
  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)

  return (
    <Flex flexDirection='column' gap={4}>
      <SignatureContainer
        type='signature'
        name='signature'
        readOnly={!canSignAsLessor || disabledForCurrentUser(lessors[0])}
        defaultValue={lessors[0]?.signature}
        label={t('quebecLeaseScreenH:lessorSignature', {
          lessorName: lessors[0].fullName || '',
        })}
      />
      <SignatureContainer
        type='initials'
        name='initials'
        readOnly={!canSignAsLessor || disabledForCurrentUser(lessors[0])}
        defaultValue={lessors[0]?.initials}
        label={t('quebecLeaseScreenH:initialsOfLessor')}
      />
      {(lessees || []).map((lessee) => {
        const props = {
          readOnly: !canSignAsLessee || disabledForCurrentUser(lessee),
          defaultValue: lessee.signature,
        }

        return (
          <>
            <SignatureContainer
              key={lessee.userId}
              type='signature'
              name='signature'
              label={t('quebecLeaseScreenH:lesseeSignature', {
                lesseeName: lessee.fullName || '',
              })}
              {...props}
            />
            <SignatureContainer
              type='initials'
              name='initials'
              label={t('quebecLeaseScreenH:initialsOfLessee')}
              {...props}
            />
          </>
        )
      })}
    </Flex>
  )
}
