import React from 'react'

import { GuarantorAppContextProvider } from './context'
import { i18n, I18nextProvider } from './i18n'
import { GuarantorRouter } from './routes'

const GuarantorApp: React.FC = () => {
  return (
    <GuarantorAppContextProvider>
      <I18nextProvider i18n={i18n}>
        <GuarantorRouter />
      </I18nextProvider>
    </GuarantorAppContextProvider>
  )
}

export { GuarantorApp }
