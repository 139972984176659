import { RouteConfig } from '@guiker/router'

const defaultRouteConfig: Partial<RouteConfig> = {
  exact: true,
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes: { [key: string]: RouteConfig } = {
  createLease: {
    ...defaultRouteConfig,
    path: '/',
  },
  signLease: {
    ...defaultRouteConfig,
    path: 'sign',
  },
  signLeaseFromTokenLink: {
    ...defaultRouteConfig,
    path: '/',
    authenticationMethod: 'token',
    requireAccountActivation: false,
  },
  viewLease: {
    ...defaultRouteConfig,
    path: 'view',
  },
  leaseFeedbackScreen: {
    ...defaultRouteConfig,
    path: 'feedback',
  },
}

export { routes }
