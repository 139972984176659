import { Listing as BaseListing } from '@guiker/base-entity'
import { BuildingAmenities } from '@guiker/real-estate-shared'

const mapLegacyAmenityToBuildingAmenity = {
  bikeParking: BuildingAmenities.BIKE_PARKING,
  communalOutdoorSpace: BuildingAmenities.COMMUNAL_OUTDOOR_SPACE,
  concierge: BuildingAmenities.CONCIERGE,
  elevator: BuildingAmenities.ELEVATOR,
  gym: BuildingAmenities.GYM,
  laundryRoom: BuildingAmenities.LAUNDRY_ROOM,
  storage: BuildingAmenities.STORAGE,
  indoorParking: BuildingAmenities.INDOOR_PARKING,
  outdoorParking: BuildingAmenities.OUTDOOR_PARKING,
  pool: BuildingAmenities.POOL,
  recreationRoom: BuildingAmenities.RECREATION_ROOM,
  security: BuildingAmenities.SECURITY,
  wheelchairAccessible: BuildingAmenities.WHEEL_CHAIR_ACCESSIBLE,
}

export const mapLegacyAmenitiesToBuildingAmenities = (
  buildingAmenities: BaseListing.BuildingAmenities<boolean>,
): BuildingAmenities[] => {
  const amenities: BuildingAmenities[] = []
  for (const [key, value] of Object.entries(buildingAmenities)) {
    if (!!value) amenities.push(mapLegacyAmenityToBuildingAmenity[key])
  }
  return amenities
}
