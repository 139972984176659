import React from 'react'

import { Box, H3 } from '@guiker/components-library'
import { buildDisplayName, PropertySaleListing } from '@guiker/property-sale-shared'
import { ProjectWithDeveloper } from '@guiker/real-estate-shared'

type HeaderProp = {
  listing: PropertySaleListing
  project: ProjectWithDeveloper
}

const Header: React.FC<HeaderProp> = ({ listing, project }) => {
  return (
    <Box maxWidth={'100%'}>
      <H3 mb={0}>{buildDisplayName(listing?.property, project)}</H3>
    </Box>
  )
}

export { Header }
