import React from 'react'

import { Article } from '@guiker/blog-entity'

import { ArticleCard, Box, Grid, TablePagination, useLayoutContext } from '..'

export type ArticlesLayoutProps = {
  totalPage: number
  page: number
  articles: Article[] | undefined
}

const PaginatedArticleGrid: React.FC<ArticlesLayoutProps> = ({ articles, page, totalPage }) => {
  const { isMobile } = useLayoutContext()

  return (
    <>
      <Grid container spacing={isMobile ? 4 : 8}>
        {articles?.map((article) => (
          <Grid item xs={12} md={6} xl={4} key={article.slug}>
            <ArticleCard article={article} />
          </Grid>
        ))}
      </Grid>
      <Box mt={4} display='flex' justifyContent='center'>
        <TablePagination withLink={true} totalPages={totalPage} page={page} />
      </Box>
    </>
  )
}

export { PaginatedArticleGrid }
