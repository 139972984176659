import React from 'react'

import { Roommate } from '@guiker/base-entity'
import { RoommateCard } from '@guiker/base-listing-components'
import { H4, TabLayout, ThreeColumnsGridLayout, useT } from '@guiker/react-framework'

type RoommatesSectionProps = {
  roommates: Roommate[]
}

export const RoommatesSection: React.FC<RoommatesSectionProps> = ({ roommates }) => {
  const { tMain } = useT({ domain: 'rentalListing' })
  const roommatesPerUnit = roommates.reduce((acc, roommate) => {
    const apartmentNumber = roommate?.address.apartmentNumber ?? ''
    return { ...acc, [apartmentNumber]: [...(acc[apartmentNumber] ?? []), roommate] }
  }, {} as { [key in string]: Roommate[] })

  const roommatesTabs = Object.entries(roommatesPerUnit).map(([unitRoom, roommates]) => ({
    content: (
      <ThreeColumnsGridLayout gap={2}>
        {roommates.map((roommate) => (
          <RoommateCard roommate={roommate} width={'100%'} />
        ))}
      </ThreeColumnsGridLayout>
    ),
    label: tMain('roommate.unitRoom', { room: unitRoom }),
  }))

  return (
    <div>
      <H4 mb={2}>{tMain('roommate.title')}</H4>
      <TabLayout tabs={roommatesTabs} />
    </div>
  )
}
