import React, { createContext } from 'react'

import { generateUseContext, useQuery } from '@guiker/react-framework'
import { RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'

import { useAuthenticatedStatisticApiClient } from '../hooks'

type Context = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  refetch: () => void
  isLoading: boolean
}

export const RentPaymentsPlanContext = createContext<Context>(undefined)

type RentPaymentsPlanContextProviderProps = React.PropsWithChildren & {
  bookingId?: string
}

export const RentPaymentsPlanContextProvider: React.FC<RentPaymentsPlanContextProviderProps> = ({
  bookingId,
  children,
}) => {
  const apiClient = useAuthenticatedStatisticApiClient()

  const {
    data: rentPaymentsPlan,
    isFetching,
    isLoading,
    refetch,
  } = useQuery(
    ['rent-payment-plan-with-tenant-instalment', bookingId],
    () => apiClient.readRentPaymentsPlanByScope({ pathParams: { bookingId } }),
    {
      enabled: !!bookingId,
      retry: 1,
    },
  )

  const value = {
    rentPaymentsPlan,
    refetch,
    isLoading: isFetching || isLoading,
  }

  return <RentPaymentsPlanContext.Provider value={value}>{children}</RentPaymentsPlanContext.Provider>
}

export const useRentPaymentsPlanContext = generateUseContext(RentPaymentsPlanContext)
