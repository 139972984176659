import React from 'react'

import { ButtonContainer, FormSection1 } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { OntarioSchemas } from '@guiker/lease-shared'
import { extractAllLeaseEmails, Lease } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { ApiForm, ButtonWithLoader } from '../../../components'
import { FormContent as FormContentA } from '../../../components/ontario-lease/step-a/FormContent'
import { FormContent as FormContentB } from '../../../components/ontario-lease/step-b/FormContent'
import { FormContent as FormContentC } from '../../../components/ontario-lease/step-c/FormContent'
import { FormContent as FormContentD } from '../../../components/ontario-lease/step-d/FormContent'
import { FormContent as FormContentE } from '../../../components/ontario-lease/step-e/FormContent'
import { FormContent as FormContentF } from '../../../components/ontario-lease/step-f/FormContent'
import { FormContent as FormContentG } from '../../../components/ontario-lease/step-g/FormContent'
import { FormContent as FormContentH } from '../../../components/ontario-lease/step-h/FormContent'
import { FormContent as FormContentI } from '../../../components/ontario-lease/step-i/FormContent'
import { useAppContext, useLeaseApiClient, useNavigate, useOntarioLeaseContext } from '../../../hooks'
import { Ontario } from '../../../types'

type Props = {
  id: string
  data: Ontario.LeaseInformation
}

export const SignLeaseForm: React.FC<Props> = ({ data, id }) => {
  const apiClient = useLeaseApiClient()
  const { user } = useAppContext()
  const { setLease } = useOntarioLeaseContext()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const lesseeData = data.lessees.find((l) => l.userId === user.id)

  const onSubmit = (payload: OntarioSchemas.LesseeSignature) =>
    apiClient.updateOntarioLeaseLesseeSignature({
      pathParams: { leaseId: id },
      payload: OntarioSchemas.lesseeSignature.cast(payload, { stripUnknown: true }),
    })

  const resolver = yupResolver(OntarioSchemas.lesseeSignature, {
    context: { emails: [user.emailAddress, ...extractAllLeaseEmails(data as unknown as Lease)] },
  })

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{
        resolver,
        defaultValues: { ...lesseeData, guarantorWithoutSignature: lesseeData.guarantor },
      }}
      apiOptions={{
        onSuccess: (lease) => {
          setLease(lease)
          navigate('../feedback')
        },
      }}
      formName={'OntarioSignLeaseForm'}
    >
      {({ isLoading }) => (
        <>
          <FormSection1 hasDivider={false}>
            <FormContentA lessors={data.lessors} lessees={data.lessees} rentalUnit={data.rentalUnit} />
            <FormContentB contactInformation={data.contactInformation} />
            <FormContentC leaseTerm={data.leaseTerm} rent={data.rent} />
            <FormContentD servicesAndUtilities={data.servicesAndUtilities} />
            <FormContentE
              rentDiscounts={data.rentDiscounts}
              rentDeposit={data.rentDeposit}
              keyDeposit={data.keyDeposit}
            />
            <FormContentF smoking={data.smoking} tenantsInsurance={data.tenantsInsurance} />
            <FormContentG />
            <FormContentH additionalTerms={data.additionalTerms} />
            <FormContentI lessors={data.lessors} lessees={data.lessees} />
          </FormSection1>
          <ButtonContainer>
            <ButtonWithLoader isLoading={isLoading}>{t('common:confirmAndSign')}</ButtonWithLoader>
          </ButtonContainer>
        </>
      )}
    </ApiForm>
  )
}
