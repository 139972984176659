import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { webappRoutes as accountRoutes, webappRoutes } from '@guiker/account-shared'
import { ListPaymentMethods, TopInvoicesTable } from '@guiker/payment-app-components'
import { ListLateInvoices } from '@guiker/payment-app-components'
import { InvoiceStatus } from '@guiker/payment-shared'
import {
  Button,
  FullScreenSpinner,
  Link,
  PageSection1,
  RouterLink,
  TextButton,
  useNavigate,
} from '@guiker/react-framework'

import { PaymentEmptyState } from '../../components'
import { useT } from '../../i18n'
import { useAccountWalletPayments } from './utils'

const PAYMENT_URL = accountRoutes.root.wallet().payments().path

const PaymentsScreenContent: React.FC = () => {
  const { tBase, tMain } = useT({ screenName: 'components.listLateInvoices' })
  const { isEmptyState, isLoading, lateInvoices, topInvoices, paymentMethods } = useAccountWalletPayments()
  const navigate = useNavigate()
  const invoicesUrl = webappRoutes.root.wallet().payments().invoice().path

  if (isLoading) return <FullScreenSpinner />
  if (isEmptyState) return <PaymentEmptyState />

  return (
    <>
      {!!lateInvoices?.length ? (
        <ListLateInvoices
          invoices={lateInvoices}
          renderAction={(invoiceId, invoiceStatus) => (
            <RouterLink to={webappRoutes.root.wallet().payments().invoice().byId(invoiceId).path}>
              <Button size='small'>
                {invoiceStatus === InvoiceStatus.SENT ? tMain('action.pay') : tMain('action.retry')}
              </Button>
            </RouterLink>
          )}
        />
      ) : undefined}
      {!!paymentMethods?.length ? (
        <ListPaymentMethods
          paymentMethods={paymentMethods}
          renderViewAction={(payInMethodId) => (
            <Link to={`${PAYMENT_URL}/${payInMethodId}`}>
              <TextButton>{tBase('actions.view')}</TextButton>
            </Link>
          )}
        />
      ) : undefined}
      <TopInvoicesTable
        invoices={topInvoices?.data}
        type='PAYMENTS'
        invoicesUrl={invoicesUrl}
        onTopActionClick={() => navigate('./history')}
      />
    </>
  )
}

export const PaymentsScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.payments' })
  const { tMain: tNavigation } = useT({ screenName: 'sideMenu.navItem' })

  return (
    <AccountPageLayout breadcrumbItems={[{ label: tNavigation('wallet') }, { label: tNavigation('payments') }]}>
      <PageSection1 hasDivider={false} title={tMain(`title`)}>
        <PaymentsScreenContent />
      </PageSection1>
    </AccountPageLayout>
  )
}
