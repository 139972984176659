import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  return (
    <Routes>
      <Route {...routes.listingInquiryNested} {...props}>
        <Route {...routes.listingInquiryNested.routes.confirm} {...props} />
      </Route>
    </Routes>
  )
}

export { Router }
