import React from 'react'

import { Outlet, useParams } from '@guiker/router'

import { PayoutContextProvider } from '../context'

const PayoutNestedRouter: React.FC = () => {
  const { payoutId } = useParams()

  return (
    <PayoutContextProvider payoutId={payoutId}>
      <Outlet />
    </PayoutContextProvider>
  )
}

export { PayoutNestedRouter }
