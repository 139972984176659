import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { useAuthenticatedPropSharingStockOrderIdContext } from '@guiker/propsharing-context'
import { AuthApi, isStockOrderPaid, StockOrder, StockOrderType } from '@guiker/propsharing-shared'
import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  Flex,
  HiddenInput,
  PageLayout,
  Redirect,
  SecondaryButton,
  useFormContext,
  useNavigate,
  useQuery,
  useT as baseUseT,
  yupResolver,
} from '@guiker/react-framework'

import { ClaimContent } from './ClaimContent'

type Props = {
  stockOrder: StockOrder
  isPromotional: boolean
  onClose: () => void
  isLoading: boolean
  errors: { [x: string]: any }
}

const useT = () =>
  baseUseT({
    domain: 'propsharing',
    screenName: 'screens.promotionalStockOrderClaim',
  })

const FormContent: React.FC<Props> = ({ stockOrder, isPromotional, onClose, isLoading, errors }) => {
  const { tMain, tBase } = useT()
  const { apiClient } = useAuthenticatedPropSharingStockOrderIdContext()
  const { setValue } = useFormContext()

  const { data: riskAcknowledgement, isFetching } = useQuery(
    [`stock-order-${stockOrder.id}-risk-acknowledgement`],
    () =>
      apiClient.previewRiskAcknowledgement({
        pathParams: { id: stockOrder.id },
        queryParams: { quantity: stockOrder.share.quantity },
      }),
    {
      retry: 1,
      enabled: isPromotional,
      onSuccess: (res) => {
        setValue('consent.fingerprint', res.fingerprint)
        setValue('consent.date', res.date)
      },
    },
  )

  return (
    <Flex flexDirection='column'>
      <HiddenInput name='consent.date' defaultValue='' />
      <HiddenInput name='consent.fingerprint' defaultValue='' />
      <ClaimContent
        defaultValue={stockOrder}
        riskAcknowledgement={riskAcknowledgement?.content}
        isRiskAcknowledgementFetching={isFetching}
      />
      <ButtonContainer>
        <SecondaryButton onClick={() => onClose()}>{tBase('actions.back')}</SecondaryButton>
        <ButtonWithLoader disabled={isLoading || isFetching} isLoading={isLoading} errors={errors}>
          {tMain('cta')}
        </ButtonWithLoader>
      </ButtonContainer>
    </Flex>
  )
}

export const PromotionlStockOrderClaimScreen: React.FC = () => {
  const { tMain } = useT()
  const { data: stockOrder, apiClient } = useAuthenticatedPropSharingStockOrderIdContext()
  const navigate = useNavigate()
  const isPromotional = !!stockOrder?.id && stockOrder.type === StockOrderType.PROMOTION

  const onClose = () => navigate(-1, { fallback: mainPathBuilder.root.investmentDashboard.path })

  const onSubmit = async (payload: AuthApi.Schema.ClaimPromotionalStockOrderSchema) => {
    return apiClient.claimPromotionalStockOrder({
      pathParams: { id: stockOrder.id },
      payload: { consent: payload.consent },
    })
  }

  if (stockOrder && (!isPromotional || isStockOrderPaid(stockOrder))) {
    return <Redirect path={mainPathBuilder.root.investmentDashboard.path} />
  }

  return (
    <PageLayout
      maxWidth={900}
      title={tMain('title')}
      subtitle={tMain('subtitle')}
      takeoverProps={{
        onClose,
        hideSubtitle: true,
        subtitleProps: { mb: 0 },
      }}
      noBreadcrumpSpacer
      hasButtonContainer
      forceTakeover
    >
      <ApiForm
        formName='PromotionalStockOrderClaimForm'
        onSubmit={onSubmit}
        apiOptions={{
          onSuccess: () => navigate(mainPathBuilder.root.investmentDashboard.path),
        }}
        formOptions={{
          defaultValues: stockOrder,
          resolver: yupResolver(AuthApi.Schema.claimPromotionalStockOrderSchema),
        }}
      >
        {({ isLoading, formState: { errors } }) => (
          <FormContent
            stockOrder={stockOrder}
            isPromotional={isPromotional}
            isLoading={isLoading}
            errors={errors}
            onClose={onClose}
          />
        )}
      </ApiForm>
    </PageLayout>
  )
}
