import React from 'react'

import { AuthenticatedApi } from '@guiker/payout-shared'
import { ApiForm, Step, StepProps, useTranslation, yupResolver } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient, usePayoutMethodNestedContext } from '../../hooks'
import { SelectCapabilitiesFormContent } from '../SelectCapabilitiesFormContent'

export type SelectCapabilitiesStepProps = StepProps

const SelectCapabilitiesStep: React.FC<SelectCapabilitiesStepProps> = ({ onClickNext, ...props }) => {
  const apiClient = useAuthenticatedPayoutApiClient()
  const { payoutMethod, updatePayoutMethod } = usePayoutMethodNestedContext()
  const { t } = useTranslation(['main-payout'])

  const resolver = yupResolver(AuthenticatedApi.Schemas.updateCapabiltiesSchema)
  const onSubmit = (payload: AuthenticatedApi.Schemas.UpdateCapabiltiesSchema) => {
    return apiClient.updatePayoutMethodCapabilities({ pathParams: { payoutMethodId: payoutMethod.id }, payload })
  }

  return (
    <ApiForm
      onSubmit={onSubmit}
      formName={'PayoutSelectAccountHolderType'}
      formOptions={{ resolver, defaultValues: payoutMethod }}
      apiOptions={{
        onSuccess: (res) => {
          updatePayoutMethod(res)
          onClickNext()
        },
        onSubmitWithoutChange: () => onClickNext(),
      }}
    >
      {({ formState: { isSubmitting, errors } }) => (
        <Step
          {...props}
          hasBackButton
          errors={errors}
          isSubmitting={isSubmitting}
          title={t('main-payout:payoutMethodScreen.capabilitiesForm.subtitle')}
        >
          {payoutMethod && <SelectCapabilitiesFormContent defaultValue={payoutMethod} readOnly={false} />}
        </Step>
      )}
    </ApiForm>
  )
}

export { SelectCapabilitiesStep }
