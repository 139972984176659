import React, { useState } from 'react'

import { AuthenticatedApi } from '@guiker/booking-shared'
import { Template } from '@guiker/document-shared'
import { useFormContext, yupResolver } from '@guiker/react-hook-form'

import { ApiForm, ApiFormAction, Box, Button, Checkbox, ConfirmDialog, TextField } from '../../components'
import { useBookingApiClient, useFieldArray } from '../../hooks'
import { useTranslation } from '../../i18n'

type TemplateListFormProps = {
  templates: Template[]
  bookingId: string
  tPrefix: string
}

const TemplateList: React.FC<{ templates: Template[]; tPrefix: string }> = ({ templates, tPrefix }) => {
  const { t } = useTranslation(['main-bookingDocument', 'common'])
  const { control } = useFormContext<{ templates: Template[] }>()
  const {
    fields: selectedTemplates,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'templates',
  })

  const handleSelection = (template: Template) => {
    const index = selectedTemplates.findIndex((t) => t.uid === template.uid)
    if (index >= 0) remove(index)
    else append(template)
  }

  return (
    <Box display='flex' flexDirection='column' flex='1'>
      <Box display='none'>
        {selectedTemplates.map((template, index) => (
          <>
            <TextField type='hidden' name={`templates.${index}.uid`} defaultValue={template.uid} />
            <TextField type='hidden' name={`templates.${index}.name`} defaultValue={template.name} />
          </>
        ))}
      </Box>
      {templates.map((template) => (
        <Checkbox
          key={`${template.uid}`}
          name={`${template.uid}`}
          label={template.name}
          description={t(`${tPrefix}.templateId`, { uid: template.uid })}
          maxWidth={450}
          onClick={() => handleSelection(template)}
        />
      ))}
    </Box>
  )
}

export const TemplateListForm: React.FC<TemplateListFormProps> = ({ templates, bookingId, tPrefix }) => {
  const { t } = useTranslation(['main-bookingDocument', 'common'])
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const apiClient = useBookingApiClient()
  const validator = AuthenticatedApi.Schema.attachBookingDocumentPayloadValidator

  const handleConfirmModal = () => setOpenConfirmModal(!openConfirmModal)

  const navigate = (url: string) => {
    window.top.location.href = url
  }

  const submitSelectedTemplates = (payload: AuthenticatedApi.Schema.AttachBookingDocumentPayload) =>
    apiClient
      .attachBookingDocuments({
        pathParams: { bookingId },
        payload: validator.cast(payload, { stripUnknown: true }),
      })
      .then(({ editUrl }) => {
        handleConfirmModal()
        editUrl && navigate(editUrl)
      })

  return (
    <ApiForm
      formName='BookingDocumentNew'
      onSubmit={submitSelectedTemplates}
      formOptions={{
        resolver: yupResolver(validator),
      }}
    >
      <Box display='flex' flexDirection='column' height='100%'>
        <TemplateList templates={templates} tPrefix={tPrefix} />
        <Box display='flex' justifyContent='flex-end'>
          <Button onClick={handleConfirmModal}>{t('common:actions.next')}</Button>
        </Box>
      </Box>
      <ConfirmDialog
        open={openConfirmModal}
        title={t(`${tPrefix}.confirmModel.cta`)}
        description={t(`${tPrefix}.confirmModel.description`)}
        cancelButtonProps={{ children: t('common:back'), onClick: handleConfirmModal }}
      >
        <ApiFormAction onClick={submitSelectedTemplates}>{t('common:confirm')}</ApiFormAction>
      </ConfirmDialog>
    </ApiForm>
  )
}
