import React, { useMemo } from 'react'

import { AuthBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { getApplicationStatusAfterSubmit } from '@guiker/booking-components'
import { yupResolver } from '@guiker/react-hook-form'
import { GuarantorAndBackgroundCheckFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, buildAssetGroups, TenantApplication } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useBookingApplicationContext } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '..'

export type GuarantorAndBackgroundCheckStepProps = StepProps

const GuarantorAndBackgroundCheckContent: React.FC<GuarantorAndBackgroundCheckStepProps> = ({
  onClickNext,
  ...props
}) => {
  const { isLoading, bookingApplication, setBookingApplication } = useBookingApplicationContext()
  const { bulkUpdate } = useBulkAssetUploaderContext()
  const apiClient = useAuthenticatedTenantApplicationApiClient()
  const validator = AuthenticatedApi.Schema.updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator

  const resolver = yupResolver(validator, { context: { email: bookingApplication?.content?.profile?.emailAddress } })

  const handleSubmit = (
    payload: AuthenticatedApi.Schema.UpdateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator,
  ) => apiClient.updateGuarantorAndBackgroundCheck({ payload: validator.cast(payload) })

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      formName='BookingApplicationGuarantorAndBackgroundCheckStep'
      formOptions={{
        resolver,
        defaultValues: bookingApplication?.content as TenantApplication,
      }}
      onSubmit={handleSubmit}
      beforeValidation={() => bulkUpdate()}
      apiOptions={{
        onSuccess: (res) => {
          setBookingApplication({
            ...bookingApplication,
            content: res,
            status: getApplicationStatusAfterSubmit(bookingApplication?.status),
          })
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { isSubmitting, errors } }) => (
        <Step isSubmitting={isSubmitting} errors={errors} {...props}>
          <GuarantorAndBackgroundCheckFormContent
            tenantApplication={bookingApplication?.content}
            schema={AuthenticatedApi.Schema.updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator}
          />
        </Step>
      )}
    </ApiForm>
  )
}

const GuarantorAndBackgroundCheckStep: React.FC<GuarantorAndBackgroundCheckStepProps> = (props) => {
  const { bookingApplication } = useBookingApplicationContext()

  const assetGroups = useMemo(
    () =>
      buildAssetGroups(
        (bookingApplication?.content as TenantApplication)?.guarantor?.supportingDocuments,
        'guarantor.supportingDocuments',
      ),
    [bookingApplication],
  )

  return (
    <AuthBulkAssetUploaderContextProvider
      assetGroups={assetGroups}
      scope={{
        type: 'TenantApplication',
        id: (bookingApplication?.content as TenantApplication)?.id,
      }}
    >
      <GuarantorAndBackgroundCheckContent {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}

export { GuarantorAndBackgroundCheckStep }
