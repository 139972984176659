import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'

import { useTranslation } from '../i18n'
import { Box, H4, PBold, PSmall } from '.'

const useStyles = makeStyles({
  container: {
    marginTop: theme.spacing(4),
  },
})

interface ReportInformationCardProps {
  firstName: string
  lastName: string
  dateOfBirth: string
  email?: string
  ssn?: string
}

export const ReportInformationCard: React.FC<ReportInformationCardProps> = ({
  firstName,
  lastName,
  dateOfBirth,
  email,
  ssn,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const informations = [
    { label: 'firstName', value: firstName },
    { label: 'lastName', value: lastName },
    { label: 'email', value: email },
    { label: 'dateOfBirth', value: dateOfBirth },
    { label: 'sinSsn', value: ssn },
  ]

  return (
    <Box className={classes.container}>
      <H4 mb={4}>{t('softcheckReport:reportInfo')}</H4>
      {informations.map(({ label, value }) => (
        <Box key={label}>
          <PSmall mb={0}>{t(`common:softcheck.information.${label}`)}</PSmall>
          <PBold>{value || '-'}</PBold>
        </Box>
      ))}
    </Box>
  )
}
