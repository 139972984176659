import React from 'react'

import { AuthenticatedApi, User } from '@guiker/authentication-shared'
import {
  ButtonContainer,
  ButtonWithLoader,
  Flex,
  ThreeColumnsGridLayout,
  useNavigate,
} from '@guiker/components-library'
import { ApiForm, DatePicker, TextField, useMutation, yupResolver } from '@guiker/react-framework'

import { useAuthenticationApiClient, useAuthenticationContext } from '../../hooks'
import { useT } from '../../i18n'

type Prop = {
  user: User
}

const UpdateProfileFormContent: React.FC<Prop> = ({ user }) => {
  const { tBase } = useT({ screenName: 'screens.profile' })
  return (
    <Flex flexDirection='column' gap={2}>
      <TextField name='emailAddress' readOnly defaultValue={user?.emailAddress} label={tBase('user.emailAddress')} />
      <TextField name='firstName' defaultValue={user?.firstName} label={tBase('user.firstName')} />
      <TextField name='lastName' defaultValue={user?.lastName} label={tBase('user.lastName')} />
      <DatePicker disableFuture name='birthDate' defaultValue={user?.birthDate} label={tBase('user.dateOfBirth')} />
      <TextField name='phone' defaultValue={user?.phoneNumber} label={tBase('user.phoneNumber')} />
    </Flex>
  )
}

const UpdateProfileForm: React.FC = () => {
  const { tBase } = useT({ screenName: 'screens.profile' })

  const { user, setUser } = useAuthenticationContext()
  const apiClient = useAuthenticationApiClient(user?.accessToken)
  const resolver = yupResolver(AuthenticatedApi.Schemas.updateProfileSchema)
  const navigate = useNavigate()

  const { mutate: updateProfile, isLoading } = useMutation(
    async (payload: AuthenticatedApi.Schemas.UpdateProfileSchema) =>
      apiClient.updateProfile({
        pathParams: { userId: user.id },
        payload,
      }),
    {
      onSuccess: (res) => {
        setUser({
          ...user,
          firstName: res.firstName,
          lastName: res.lastName,
          birthDate: res.birthDate,
          phoneNumber: res.phoneNumber,
        })
        navigate('/success')
      },
    },
  )

  return (
    <ApiForm formName='updateProfile' formOptions={{ resolver }} onSubmit={updateProfile}>
      <ThreeColumnsGridLayout>
        <div>
          <UpdateProfileFormContent user={user} />
          <ButtonContainer>
            <ButtonWithLoader type='submit' isLoading={isLoading}>
              {tBase('actions.save')}
            </ButtonWithLoader>
          </ButtonContainer>
        </div>
      </ThreeColumnsGridLayout>
    </ApiForm>
  )
}

export { UpdateProfileForm }
