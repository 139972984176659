import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { buildLocationNestedRoutes } from '@guiker/base-listing-components'
import {
  AuthenticatedPropSharingInvestorProfileRouter,
  PublicPropSharingListingRouter,
  useAuthenticatedPropSharingListingIdContext,
  usePublicPropSharingListingIdContext,
} from '@guiker/propsharing-context'
import { buildNestedListingRoutes, slugifyListing } from '@guiker/propsharing-shared'
import { RedirectNoIndex, routeConfigBuilder } from '@guiker/router'

import {
  CreateInvestorProfileScreen,
  FindOrCreateInvestorProfileAndRedirectScreen,
  IdentityVerificationScreen,
  InvestorProfileOnboardScreen,
  ListingScreen,
  PromotionlStockOrderClaimScreen,
  RequestTINScreen,
  StockOrderCheckoutScreen,
  WireTransferInstructionScreen,
} from '../screens'
import { ListingIdNestedRouter } from './ListingIdNestedRouter'
import { StockOrderIdNestedRouter } from './StockOrderIdNestedRouter'

const { defaultRouteConfig, countryNested } = buildLocationNestedRoutes({
  routes: {
    router: ListingIdNestedRouter,
    path: {
      id: () => {
        const { user } = useAuthenticationContext()
        const { data: listing } = user
          ? useAuthenticatedPropSharingListingIdContext()
          : usePublicPropSharingListingIdContext()

        const slug = slugifyListing(listing) ?? 'listing'
        return <RedirectNoIndex path={`../${slug}`} />
      },
      slugified: ListingScreen,
    },
  },
})

const routes = {
  root: {
    ...defaultRouteConfig,
    path: '/',
    Component: () => <RedirectNoIndex path={`./listings`} />,
  },
  listings: {
    ...defaultRouteConfig,
    path: '/listings',
    Component: PublicPropSharingListingRouter,
    displayFooter: true,
    routes: {
      countryNested,
      idNested: {
        ...defaultRouteConfig,
        path: '/:id',
        Component: ListingIdNestedRouter,
        displayFooter: true,
        routes: {
          id: {
            ...defaultRouteConfig,
            path: '/',
            Component: () => {
              const { user } = useAuthenticationContext()
              const { data: listing } = user
                ? useAuthenticatedPropSharingListingIdContext()
                : usePublicPropSharingListingIdContext()

              return <RedirectNoIndex path={buildNestedListingRoutes(listing).listing} />
            },
          },
        },
      },
    },
  },
  findOrCreateInvestorProfile: {
    ...routeConfigBuilder.authGuarded('user').build({ path: '/find-investor-profile' }),
    Component: FindOrCreateInvestorProfileAndRedirectScreen,
    displayFooter: false,
  },
  investorProfile: {
    ...routeConfigBuilder.authGuarded('user').build({ path: '/investor-profile' }),
    Component: AuthenticatedPropSharingInvestorProfileRouter,
    displayFooter: false,
    routes: {
      root: {
        path: '/',
        Component: InvestorProfileOnboardScreen,
      },
      onboard: {
        path: '/onboard',
        Component: CreateInvestorProfileScreen,
        displayFooter: false,
      },
      identityVerification: {
        path: '/onboard/identity-verification',
        Component: IdentityVerificationScreen,
        displayFooter: false,
      },
      requestTIN: {
        path: '/request-tin',
        Component: RequestTINScreen,
        displayFooter: false,
      },
    },
  },
  stockOrder: {
    ...routeConfigBuilder.authGuarded('user').build({ path: '/stock-order' }),
    displayFooter: false,
    routes: {
      idNested: {
        path: '/:id',
        Component: StockOrderIdNestedRouter,
        displayFooter: false,
        routes: {
          checkout: {
            ...defaultRouteConfig,
            displayFooter: false,
            path: '/checkout',
            Component: StockOrderCheckoutScreen,
          },
          claimPromotion: {
            ...defaultRouteConfig,
            displayFooter: false,
            path: '/claim-promotion',
            Component: PromotionlStockOrderClaimScreen,
          },
          wireTransferInstruction: {
            ...defaultRouteConfig,
            path: '/how-to-wire-transfer',
            Component: WireTransferInstructionScreen,
          },
        },
      },
    },
  },
} as const

export { routes }
