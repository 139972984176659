import React from 'react'

import { Flex, H3, Link, P, Trans } from '@guiker/react-framework'

type QuestionProp = {
  question: string
  answer: string
}

const Question: React.FC<QuestionProp> = ({ question, answer }) => {
  return (
    <Flex flexDirection='column'>
      <H3>{question}</H3>
      <P>{answer}</P>
    </Flex>
  )
}

type QuestionWithLinkProp = QuestionProp & {
  location: string
}

const QuestionWithLink: React.FC<QuestionWithLinkProp> = ({ question, answer, location }) => {
  return (
    <Flex flexDirection='column'>
      <H3>{question}</H3>
      <P>
        <Trans i18nKey={answer}>
          <Link to={location} />
        </Trans>
      </P>
    </Flex>
  )
}

type QuestionWithListProp = {
  question: string
  list: string[]
  topAnswer: string
  bottomAnswer?: string
}

const QuestionWithList: React.FC<QuestionWithListProp> = ({ question, topAnswer, bottomAnswer, list }) => {
  return (
    <Flex flexDirection='column'>
      <H3>{question}</H3>
      <P>{topAnswer}</P>
      <ul>
        {list.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
      {bottomAnswer && <P>{bottomAnswer}</P>}
    </Flex>
  )
}

export { Question, QuestionWithLink, QuestionWithList }
