import React from 'react'

import { FormSection3, OneColumnGridLayout, P } from '@guiker/components-library'
import { Quebec } from '@guiker/lease-shared'

import { useTranslation, useWatch } from '../../../hooks'
import { TextField } from '../../'

type Props = {
  defaultValue: Quebec.JanitorialServices
  name: string
  readOnly: boolean
}

export const JanitorServices: React.FC<Props> = ({ defaultValue, name: prefix, readOnly }) => {
  const { t } = useTranslation(['common', 'quebecLeaseScreenE'])
  const name = `${prefix}.janitorialServices`
  const janitorialService = useWatch({ name, defaultValue })

  return (
    <FormSection3 title={t('quebecLeaseScreenE:janitorServicesLabel')}>
      <OneColumnGridLayout>
        <TextField
          readOnly={readOnly}
          defaultValue={janitorialService?.specification}
          label={t('common:specify')}
          name={`${name}.specification`}
        />
        <P mb={0}>{t('quebecLeaseScreenE:contactPersonLabel')}</P>
        <TextField
          defaultValue={janitorialService?.janitor?.name}
          readOnly={readOnly}
          label={t('quebecLeaseScreenE:janitorName')}
          name={`${name}.janitor.name`}
        />
        <TextField
          defaultValue={janitorialService?.janitor?.phoneNumber}
          readOnly={readOnly}
          label={t('quebecLeaseScreenE:janitorPhoneNumber')}
          name={`${name}.janitor.phoneNumber`}
        />
        <TextField
          defaultValue={janitorialService?.janitor?.emailAddress}
          readOnly={readOnly}
          label={t('quebecLeaseScreenE:janitorEmailAddress')}
          name={`${name}.janitor.emailAddress`}
        />
        <TextField
          defaultValue={janitorialService?.janitor?.otherPhoneNumber}
          readOnly={readOnly}
          label={t('quebecLeaseScreenE:janitorOtherPhoneNumber')}
          name={`${name}.janitor.otherPhoneNumber`}
        />
      </OneColumnGridLayout>
    </FormSection3>
  )
}
