import React from 'react'

import { OntarioSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useOntarioLeaseContext } from '../../../hooks'
import { FormSection1, Step, StepProps } from '../../common'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepACurrentUser: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { lessors, lessees, rentalUnit },
    },
  } = useOntarioLeaseContext()

  const onSubmit = (payload: OntarioSchemas.PartiesToTheAgreementAndRentalUnit) =>
    apiClient.updateOntarioLeasePartiesToTheAgreementAndRentalUnit({ pathParams: { leaseId }, payload })

  return (
    <Step
      hasBackButton={false}
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.partiesToTheAgreementAndRentalUnit),
        defaultValues: { lessors, lessees, rentalUnit },
      }}
      formName={'OntarioLeaseFormStepA'}
      {...props}
    >
      <FormSection1>
        <FormContent lessors={lessors} lessees={lessees} rentalUnit={rentalUnit} />
      </FormSection1>
    </Step>
  )
}
