import React, { useEffect, useState } from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { useTranslation } from '@guiker/i18n'
import { money } from '@guiker/money'
import { ApiForm, ButtonWithLoader, Divider, Flex, Modal, P, PBold, SecondaryButton } from '@guiker/react-framework'
import {
  useAuthRentPaymentApiClient,
  useJwtBookingRentPaymentsPlanScopeNestedContext,
} from '@guiker/rent-payment-context'
import { AuthApi, ScopeType } from '@guiker/rent-payment-shared'

import { useAuthenticationContext } from '../../hooks'
import { Contribution } from './Contribution'
import { findContribution, getOccupiedAmount, isPositiveNumber } from './utils'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ChangeContributionForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation(['common', 'main-bookingRentPayment'])
  const commonPrefix = 'common:actions'
  const paymentPrefix = 'main-bookingRentPayment:components.changeContribution'
  const { user: currentUser } = useAuthenticationContext()
  const { data: rentPaymentsPlan, refetch } = useJwtBookingRentPaymentsPlanScopeNestedContext()
  const { bookingId } = useBookingContext()
  const [amount, setAmount] = useState(findContribution(rentPaymentsPlan, currentUser.id))
  const [isValidContribution, setIsValidContribution] = useState(false)

  const apiClient = useAuthRentPaymentApiClient()
  const currency = money.currency[rentPaymentsPlan.total.currency]

  const isSingleTenant = rentPaymentsPlan.payers.length === 1
  const occupiedAmount = money.fromAmount(getOccupiedAmount(rentPaymentsPlan, currentUser.id), currency).amount
  const remainingRentAmount = money.fromAmount(rentPaymentsPlan.total.amount, currency).amount - occupiedAmount - amount

  useEffect(() => {
    setIsValidContribution(
      isPositiveNumber(amount.toString()) &&
        (isSingleTenant ? remainingRentAmount === 0 : rentPaymentsPlan.total.amount - amount >= 0),
    )
  }, [amount])

  const onSubmit = async (payload: AuthApi.Schema.RequestContributionResplitSchema) =>
    apiClient.requestContributionResplit({
      pathParams: { scopeType: ScopeType.BOOKING, scopeId: bookingId },
      payload,
    })

  const onSuccess = async () => {
    await refetch()
    onClose()
  }

  if (isSingleTenant) {
    return <></>
  }

  return (
    <ApiForm formName='ChangeRentPaymentsPlanContributionForm' onSubmit={onSubmit} apiOptions={{ onSuccess }}>
      {({ isLoading, formState: { errors } }) => (
        <Flex flexDirection='column' gap={2}>
          {rentPaymentsPlan.payers.map((payer) => (
            <Contribution
              payer={payer}
              currency={currency}
              setAmount={setAmount}
              total={rentPaymentsPlan.total.amount}
            />
          ))}
          <Divider />
          <Flex mt={2} alignItems='center' justifyContent='space-between'>
            <PBold>{t(`${paymentPrefix}.remainingAmount`)}</PBold>
            <P>{money.fromAmount(remainingRentAmount, currency).toString()}</P>
          </Flex>
          <Flex gap={2} justifyContent='flex-end'>
            <SecondaryButton onClick={() => onClose()}>{t(`${commonPrefix}.cancel`)}</SecondaryButton>
            <ButtonWithLoader disabled={!isValidContribution} isLoading={isLoading} errors={errors}>
              {t(`${commonPrefix}.submit`)}
            </ButtonWithLoader>
          </Flex>
        </Flex>
      )}
    </ApiForm>
  )
}

export const ChangeContributionModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['main-bookingRentPayment'])

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      title={t('rentPaymentsPlanTable.actions.changePaymentMethod')}
    >
      <ChangeContributionForm onClose={onClose} />
    </Modal>
  )
}
