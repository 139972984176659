import React from 'react'

import { Route, Routes } from '@guiker/router'

import { routes } from './routes'

const Router: React.FC = (props) => {
  const neighbourhoodNested = routes.countryNested.routes.cityNested.routes.neighbourhoodNested

  return (
    <Routes>
      <Route {...routes.countryNested} {...props}>
        <Route {...routes.countryNested.routes.cityNested} {...props}>
          <Route {...routes.countryNested.routes.cityNested.routes.root} {...props} />
          <Route {...neighbourhoodNested} {...props}>
            <Route {...neighbourhoodNested.routes.root} {...props} />
            <Route {...neighbourhoodNested.routes.idNested} {...props}>
              <Route {...neighbourhoodNested.routes.idNested.routes.id} {...props} />
              <Route {...neighbourhoodNested.routes.idNested.routes.slugified} {...props} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route {...routes.listingNested} {...props}>
        <Route {...routes.listingNested.routes.listingInquiry} {...props} />
        <Route {...routes.listingNested.routes.tenantPreferences} {...props} />
        <Route {...routes.listingNested.routes.deprecatedListingRoute} {...props} />
      </Route>

      <Route {...routes.listingAuthNested} {...props}>
        <Route {...routes.listingAuthNested.routes.reviewLeaseContractListing} {...props} />
      </Route>
    </Routes>
  )
}

export { Router }
