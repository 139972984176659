import parse, { DomElement } from 'html-react-parser'

import { config } from '../config'

export const replaceCanonicalUrl = (element: DomElement, pagePath: string) => {
  element.attribs.href = `https://${config.blogDomain}/${pagePath}`

  return element
}

export const replaceOgUrl = (element: DomElement, pagePath: string) => {
  element.attribs.content = `https://${config.blogDomain}/${pagePath}`

  return element
}

export const replaceSchemaGraph = (element: DomElement, pagePath: string) => {
  element.attribs.content = `https://${config.blogDomain}/${pagePath}`

  return element
}

export const sanitizeHeadElements = (headHtml: string, pagePath: string) => {
  const parsed = parse(headHtml, {
    replace: (domNode) => {
      if (domNode.name === 'meta' && domNode.attribs?.property === 'og:url') {
        return replaceOgUrl(domNode, pagePath)
      }

      if (
        domNode.name === 'script' &&
        domNode.attribs?.type === 'application/ld+json' &&
        domNode.attribs?.class === 'yoast-schema-graph'
      ) {
        return replaceSchemaGraph(domNode, pagePath)
      }
    },
  })

  if (Array.isArray(parsed)) {
    return parsed.filter(
      (ele) =>
        !(ele.type === 'meta' && ele.props?.name === 'robots') ||
        (ele.type === 'link' && ele.props?.rel === 'canonical'),
    )
  }

  return parsed
}
