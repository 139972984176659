import React from 'react'

import { FeedbackComponent, PageLayout } from '../../../components'
import { useQuebecLeaseContext } from '../../../hooks'
import { ViewLeaseContent } from './ViewLeaseContent'

const ViewLeaseContentScreen: React.FC = () => {
  const { lease, isLoading } = useQuebecLeaseContext()

  return (
    <PageLayout maxWidth={900}>
      <FeedbackComponent loading={isLoading} />
      {lease?.id && <ViewLeaseContent id={lease.id} data={lease.leaseInformation} />}
    </PageLayout>
  )
}

export { ViewLeaseContentScreen }
