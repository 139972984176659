import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import {
  Divider,
  Flex,
  PageLayout,
  PageSection2,
  RouterLink,
  TextButton,
  TwoColumnsGridLayout,
  useParams,
} from '@guiker/react-framework'
import { useAuthenticatedListingContext } from '@guiker/rental-listing-context'
import { SearchableEntities } from '@guiker/search-shared'
import { addressFormatter } from '@guiker/shared-framework'
import { RentCollectionPerTenantTable } from '@guiker/statistic-components'

import { MonthlyPerformanceSearchBar, RentCollectionPieChart } from '../../components'
import { RentCollectionContextProvider, RentCollectionMonthPicker, useRentCollectionContext } from '../../context'
import { useSideMenuItems } from '../../hooks'
import { useT } from '../../i18n'

export const PropertyRentalScreenContent: React.FC = () => {
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)
  const { listingId } = useParams()
  const { data: listing } = useAuthenticatedListingContext()
  const { tMain } = useT({ screenName: 'sideMenu.navItem' })
  const { date, region } = useRentCollectionContext()
  const { tMain: tSearch } = useT({ screenName: 'components.monthlyPerformanceSearchBar' })
  const { tMain: tRentCollectionTable } = useT({ screenName: 'screens.dashboard.rentalDashboard.monthlyPerformances' })

  const address = addressFormatter.printStreetInformation(listing.address)
  const breadcrumbItems = [
    { label: tMain('dashboard'), to: '../../' },
    { label: tMain('rental'), to: '../../' },
    { label: address },
  ]
  return (
    <PageLayout sideMenuProps={{ items: sideMenuNavItems }} breadcrumbItems={breadcrumbItems} title={address}>
      <PageSection2
        title={tRentCollectionTable('title')}
        titleMb={0}
        headerAlignItems='flex-end'
        topActions={
          <Flex gap={4} alignItems='flex-end'>
            <Flex mb={1.5}>
              <RentCollectionMonthPicker />
            </Flex>
            <MonthlyPerformanceSearchBar
              searchableEntities={[SearchableEntities.LISTING_UNIT]}
              label={tSearch('unitLabel')}
              scope={{ id: listingId, type: 'listing' }}
            />
          </Flex>
        }
      >
        <Divider mt={2} />

        <TwoColumnsGridLayout>
          <RentCollectionPieChart region={region} date={date} listingId={listingId} />
        </TwoColumnsGridLayout>

        {listingId && (
          <RentCollectionPerTenantTable
            region={region}
            listingId={listingId}
            date={date}
            header={
              <RouterLink to={mainPathBuilder.root.rentCollection.path}>
                <TextButton>{tRentCollectionTable('title')}</TextButton>
              </RouterLink>
            }
          />
        )}
      </PageSection2>
    </PageLayout>
  )
}

export const PropertyRentalScreen: React.FC = () => {
  return (
    <RentCollectionContextProvider>
      <PropertyRentalScreenContent />
    </RentCollectionContextProvider>
  )
}
