import React from 'react'

import { Card, Flex, H3, P } from '@guiker/components-library'

import { useT } from '../../i18n'

export const PaymentEmptyState: React.FC = () => {
  const { tMain } = useT({ screenName: 'components.paymentEmptyState' })

  return (
    <Card shadow='medium' p={4}>
      <Flex flexDirection='column' gap={4}>
        <H3 mb={0}>{tMain(`title`)}</H3>
        <div>
          <P>{tMain(`text`)}</P>
          <P mb={0}>{tMain(`text2`)}</P>
        </div>
      </Flex>
    </Card>
  )
}
