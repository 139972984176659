import React from 'react'

import { Flex, H4, numberFormatter, P, Progress } from '@guiker/components-library'
import { PropSharingListingWithFundingData } from '@guiker/propsharing-shared'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'

type FundingDataProps = {
  listing: PropSharingListingWithFundingData
}

export const FundingData: React.FC<FundingDataProps> = ({ listing }) => {
  const { MainTrans } = useT({ screenName: 'components.inquiryCard' })
  const {
    helpers: {
      stock: { percentFunded },
    },
  } = useListingScreenContext()

  return (
    <Flex flexDirection='column' gap={1}>
      <Flex gap={2} justifyContent='space-between'>
        <P mb={0}>
          <MainTrans
            i18nKey='percentFunded'
            values={{
              percent: numberFormatter.percentage(2).toDisplay(percentFunded),
              investorCount: listing.investorCount,
            }}
          >
            <H4 mb={0} component='span' />
          </MainTrans>
        </P>
        <P mb={0}>
          <MainTrans
            i18nKey='investorCount'
            values={{
              percent: numberFormatter.percentage(2).toDisplay(listing.percentFunded),
              investorCount: listing.investorCount,
            }}
          >
            <H4 mb={0} component='span' />
          </MainTrans>
        </P>
      </Flex>
      <Progress statusColor='success' value={percentFunded * 100} />
    </Flex>
  )
}
