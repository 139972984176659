import React from 'react'

import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useLeaseContext, useTranslation } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import {
  Divider,
  FormSection2,
  FormSection3,
  HeaderDescriptionBar,
  P,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
} from '../../'
import { FormAddress } from './FormAddress'
import { FormPerson } from './FormPerson'

type Props = {
  lessors: Types.Lessor[]
  lessees: Types.Lessee[]
  rentalUnit: Types.RentalUnit
}

export const FormContent: React.FC<Props> = ({ lessors = [], lessees = [], rentalUnit }) => {
  const { t } = useTranslation()
  const { can } = useLeaseContext()
  const { disabledForCurrentUser } = useAppContext()

  const canDraftLease = can(LeaseActions.DraftLease)
  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)

  return (
    <>
      <FormSection2
        title={<HeaderDescriptionBar index='1.' description={t('ontarioLeaseScreenA:partiesToTheAgreement')} />}
        subtitle={t('ontarioLeaseScreenA:agreementBetween')}
        hasDivider={false}
      >
        <FormSection3>
          <P>{t('ontarioLeaseScreenA:landlords')}</P>
          {lessors.map((_, index: number) => (
            <FormPerson
              defaultValue={lessors[index]}
              index={index}
              readOnly={!canDraftLease}
              name='lessors'
              key={index}
              mb={index === lessors.length - 1 ? 0 : 2}
            />
          ))}
        </FormSection3>
        <Divider />
        <FormSection3>
          <P>{t('ontarioLeaseScreenA:tenants')}</P>
          {lessees.map((_, index: number) => (
            <FormPerson
              defaultValue={lessees[index]}
              index={index}
              readOnly={!canDraftLease || (canSignAsLessee && disabledForCurrentUser(lessees[index]))}
              name='lessees'
              key={index}
              mb={index === lessees.length - 1 ? 0 : 2}
            />
          ))}
        </FormSection3>
      </FormSection2>
      <FormSection2 title={<HeaderDescriptionBar index='2. ' description={t('ontarioLeaseScreenA:rentalUnit')} />}>
        <FormAddress defaultValue={rentalUnit?.address} name='rentalUnit.address' readOnly={!canDraftLease} />
        <TwoColumnsGridLayout>
          <TextField
            defaultValue={rentalUnit?.numberOfParkingSpaceAndDescription}
            name='rentalUnit.numberOfParkingSpaceAndDescription'
            label={t('ontarioLeaseScreenA:numberOfParking')}
            readOnly={!canDraftLease}
            maxWidth='100%'
          />
        </TwoColumnsGridLayout>
        <Divider />
        <TwoColumnsGridLayout>
          <RadioGroup
            options={[
              { value: true, label: t('common:yes') },
              { value: false, label: t('common:no') },
            ]}
            name='rentalUnit.isInCondominium'
            defaultValue={rentalUnit?.isInCondominium}
            label={t('ontarioLeaseScreenA:isAUnitInCondo')}
            readOnly={!canDraftLease}
            maxWidth='100%'
            mb={2}
          />
        </TwoColumnsGridLayout>
        <P>{t('ontarioLeaseScreenA:tenantAgreesToComply')}</P>
      </FormSection2>
    </>
  )
}
