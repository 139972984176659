import React, { useEffect, useState } from 'react'

import { PasswordTextField } from '@guiker/authentication-components'
import { AuthenticatedApi, passwordRequirements } from '@guiker/authentication-shared'
import { User } from '@guiker/base-entity'
import { useConfig } from '@guiker/config-context'
import { CheckmarkSmallIcon } from '@guiker/icons'
import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  CLTextField,
  Features,
  Flex,
  makeStyles,
  PBold,
  PSmall,
  ThreeColumnsGridLayout,
  toPx,
  useFeatureFlag,
  useFormContext,
  useMutation,
  useNavigate,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'

import { useAuthenticationApiClient, useAuthenticationContext } from '../../hooks'
import { useT } from '../../i18n'

type RequirementProp = {
  isCompleted: boolean
  label: string
}

const useStyles = makeStyles((theme) => ({
  bullet: {
    fontSize: toPx(theme.spacing(5)),
    fontWeight: 700,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.status.alert.main,
  },
  mark: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

const Requirement: React.FC<RequirementProp> = ({ isCompleted, label }) => {
  const classes = useStyles()
  return (
    <Flex height='fit-content' alignItems={'center'}>
      {isCompleted ? (
        <CheckmarkSmallIcon className={classes.mark} />
      ) : (
        <PBold mb={0} className={classes.bullet}>
          {'·'}
        </PBold>
      )}
      <PSmall mb={0}>{label}</PSmall>
    </Flex>
  )
}

type ChangePasswordFormContentProps = {
  user: User
}

const ChangePasswordFormContent: React.FC<ChangePasswordFormContentProps> = ({ user }) => {
  const { tMain, tBase } = useT({ screenName: 'screens.security' })
  const { formState } = useFormContext()
  const [minError, setMinError] = useState(true)
  const [maxError, setMaxError] = useState(true)
  const [lowerCaseError, setLowerCaseError] = useState(false)
  const [upperCaseError, setUpperCaseError] = useState(false)
  const [digitError, setDigitError] = useState(false)
  const [specialCharacterError, setSpecialCharacterError] = useState(false)
  const isPasswordStrengthEnabled = useFeatureFlag().isEnabled(Features.TestPasswordStrength)

  const password = useWatch({
    name: 'password',
  })

  useEffect(() => {
    if (!password) return
    setMinError(password.length < 8)
    setMaxError(password.length > 32)
    setLowerCaseError(passwordRequirements.hasLowercase(password))
    setUpperCaseError(passwordRequirements.hasUppercase(password))
    setDigitError(passwordRequirements.hasDigits(password))
    setSpecialCharacterError(passwordRequirements.hasSpecialCharacters(password))
  }, [password])

  return (
    <ThreeColumnsGridLayout>
      <Flex flexDirection='column' gap={2}>
        <CLTextField label={tBase('email')} value={user.emailAddress} readOnly />
        <PasswordTextField name='currentPassword' label={tMain('currentPassword')} />
        <PasswordTextField label={tMain('password')} />
        {isPasswordStrengthEnabled && formState?.errors['password']?.type ? (
          <>
            <Requirement isCompleted={!minError} label={tMain('requirement.min')} />
            <Requirement isCompleted={!maxError} label={tMain('requirement.max')} />
            <Requirement isCompleted={lowerCaseError} label={tMain('requirement.lowercase')} />
            <Requirement isCompleted={upperCaseError} label={tMain('requirement.uppercase')} />
            <Requirement isCompleted={digitError} label={tMain('requirement.digits')} />
            <Requirement isCompleted={specialCharacterError} label={tMain('requirement.specialCharacter')} />
          </>
        ) : (
          <></>
        )}
        <PasswordTextField name='confirmPassword' label={tMain('confirmPassword')} />
      </Flex>
    </ThreeColumnsGridLayout>
  )
}

const ChangePasswordForm: React.FC = () => {
  const { tBase } = useT({ screenName: 'screens.security' })
  const { user, setUser } = useAuthenticationContext()
  const { stage } = useConfig()
  const apiClient = useAuthenticationApiClient(user?.accessToken)
  const resolver = yupResolver(AuthenticatedApi.Schemas.changePasswordSchema, { context: { stage } })

  const navigate = useNavigate()

  const { mutate: changePassword, isLoading } = useMutation(
    async (payload: AuthenticatedApi.Schemas.ChangePasswordSchema) =>
      apiClient.changePassword({
        pathParams: { userId: user.id },
        payload,
      }),
    {
      onSuccess: (res) => {
        setUser({ ...user, ...res })
        navigate('/success')
      },
    },
  )

  return (
    <ApiForm formName='changePassword' formOptions={{ resolver }} onSubmit={changePassword}>
      <ChangePasswordFormContent user={user} />
      <ButtonContainer>
        <ButtonWithLoader type='submit' isLoading={isLoading}>
          {tBase('actions.submit')}
        </ButtonWithLoader>
      </ButtonContainer>
    </ApiForm>
  )
}

export { ChangePasswordForm }
