import React from 'react'

import { Box, P, PBig } from '@guiker/react-framework'
import { TenantPreference } from '@guiker/tenant-preference-shared'

import { useT } from '../../i18n'

type EmptyStateProps = {
  tenantPreference: TenantPreference
}

const EmptyState: React.FC<EmptyStateProps> = ({ tenantPreference }) => {
  const { tMain } = useT()
  return (
    <Box
      width='100%'
      height='100%'
      flexGrow={1}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <PBig mb={1}>{!!tenantPreference ? tMain('emptyState.title') : tMain('emptyState.noTenantPreferenceTitle')}</PBig>
      <P color={60}>{tMain('emptyState.description')}</P>
    </Box>
  )
}

export { EmptyState }
