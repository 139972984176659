import React from 'react'

import { Box, H4, P, PageLayout, TakeoverContextProvider } from '../../components'
import { useNavigate } from '../../hooks'
import { useTranslation } from '../../i18n'

const Content: React.FC = () => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])

  return (
    <PageLayout maxWidth={900 as const}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <H4>{t(`main-tenantApplication:screens.steps.guarantor.noConsentFeedback.title`)}</H4>
        <Box m={3}>
          <P textAlign='center'>{t(`main-tenantApplication:screens.steps.guarantor.noConsentFeedback.description1`)}</P>
          <P textAlign='center'>{t(`main-tenantApplication:screens.steps.guarantor.noConsentFeedback.description2`)}</P>
        </Box>
      </Box>
    </PageLayout>
  )
}

export const GuarantorNoConsentScreen: React.FC = () => {
  const navigate = useNavigate()

  return (
    <TakeoverContextProvider onClose={() => navigate('/')}>
      <Content />
    </TakeoverContextProvider>
  )
}
