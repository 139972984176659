import { RentalListing } from '@guiker/rental-listing-shared'
import { DeepPartial, money } from '@guiker/shared-framework'
import { TenantPreference } from '@guiker/tenant-preference-shared'

export const transformListingToSimplifiedTenantPreference = (listing: RentalListing): DeepPartial<TenantPreference> => {
  const { address, leadOption } = { ...listing }
  const { priceOption } = { ...leadOption }
  const currency = priceOption && money.currency[priceOption.price.currency]

  return {
    leasePreferences: leadOption
      ? {
          rentalType: leadOption.type,
          budget: money.fromAmount(priceOption.price.amount, currency).monetized,
          leaseStart: priceOption.availablityDate,
          leaseDuration: null,
          location: address.city.toLowerCase(),
          neighbourhoods: [address.neighbourhood],
          floorPlan: null,
          furniture: ['fully', 'partially'].includes(priceOption.furnished),
        }
      : address
      ? {
          location: address.city.toLowerCase(),
          neighbourhoods: [address.neighbourhood],
        }
      : {},
    unitPreferences: {
      utilities: {
        hotWater: null,
        electricity: null,
        heating: null,
        wifi: null,
      },
      appliances: {
        airConditioner: null,
        balcony: null,
        dishwasher: null,
        fridge: null,
        petsAllowedSmall: null,
        petsAllowedLarge: null,
        washer: null,
        oven: null,
      },
    },
    buildingPreferences: {
      elevator: null,
      gym: null,
      storage: null,
      pool: null,
      recreationRoom: null,
      wheelchairAccessible: null,
    },
  }
}
