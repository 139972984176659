import React, { useEffect } from 'react'

import { CountryCode } from '@guiker/base-entity'
import { useGlosseryDrawerContext } from '@guiker/glossery-drawer'
import { PhoneNumber } from '@guiker/phone-number-input'
import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import { AuthApi, InvestorProfile, Personal } from '@guiker/propsharing-shared'
import {
  ApiFormStep,
  DatePicker,
  FormSection,
  FormSection2,
  H3,
  StepProps,
  TextField,
  ThreeColumnsGridLayout,
  useApiFormStepContext,
  useGetInputProps,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'

import { SearchAddressComponent } from '../../../components'
import { Paragraph, Title } from './Typography'

type AboutYouFormContentProps = {
  defaultValue?: Personal
  readOnly?: boolean
}

const schema = AuthApi.Schema.updateInvestorProfileStepperSchema.AboutYou

const DrawerContent: React.FC = () => {
  return <Paragraph transKey='main-propsharing:screens.createInvestorProfileScreen.step2.helperText.personalInfo' />
}

const AboutYouFormContent: React.FC<AboutYouFormContentProps> = ({ defaultValue, readOnly }) => {
  const { t } = useTranslation(['common-propsharing', 'main-propsharing'])
  const tPrefix = 'main-propsharing:screens.createInvestorProfileScreen.step2'
  const inputProps = useGetInputProps({ defaultValue, schema, readOnly, tPrefix: `${tPrefix}.form` })
  const { onOpen } = useGlosseryDrawerContext()

  return (
    <FormSection>
      <FormSection2
        title={<Title header={t(`${tPrefix}.personalInfo.header`)} onClick={onOpen} TitleComponent={H3} />}
        subtitle={t(`${tPrefix}.personalInfo.description`)}
      >
        <ThreeColumnsGridLayout>
          <TextField {...inputProps('legalName.firstName')} />
          <TextField {...inputProps('legalName.middleName')} />
          <TextField {...inputProps('legalName.lastName')} />
          <TextField {...inputProps('preferredName')} />
          <DatePicker {...inputProps('dateOfBirth')} disableFuture />
          <PhoneNumber {...inputProps<Personal['phone']>('phone')} disableFlags />
        </ThreeColumnsGridLayout>
      </FormSection2>
      <FormSection2 title={t(`${tPrefix}.currentAddress.header`)}>
        <SearchAddressComponent />
      </FormSection2>
    </FormSection>
  )
}

export const AboutYou: React.FC<StepProps> = (props) => {
  const { data: investorProfile } = useApiFormStepContext<InvestorProfile>()
  const { apiClient } = useAuthenticatedPropSharingInvestorProfileContext()
  const resolver = yupResolver(schema)
  const { anchor, setContent, onClose } = useGlosseryDrawerContext()

  useEffect(() => {
    setContent(DrawerContent)
    return () => {
      onClose()
    }
  }, [])

  const onSubmit = (payload: AuthApi.Schema.UpdateInvestorProfileStepperSchema['AboutYou']) => {
    return apiClient.updateInvestorProfileStepAboutYou({
      payload: schema.cast(payload, { stripUnknown: true }),
    })
  }

  return (
    <ApiFormStep
      {...props}
      isContentDrawerOpened={!!anchor}
      apiFormProps={{
        formName: 'CreateInvestorProfile',
        onSubmit,
        formOptions: {
          resolver,
          defaultValues: {
            ...investorProfile?.personal,
            address: {
              ...investorProfile?.personal?.address,
              country: CountryCode.CA,
            },
          },
        },
      }}
    >
      <AboutYouFormContent defaultValue={investorProfile?.personal} />
    </ApiFormStep>
  )
}
