import React from 'react'

import { Booking } from '@guiker/booking-shared'
import { Event } from '@guiker/event-handler'
import { ActivityTracker } from '@guiker/react-framework'

import { useTransformEventsToActivityItems } from './utils'

type BookingActivityLogProps = {
  events: Event[]
  booking: Booking
  isPaidOffline?: boolean
}

export const BookingActivityLog: React.FC<BookingActivityLogProps> = ({ events, booking, isPaidOffline = false }) => {
  const items = useTransformEventsToActivityItems({ events, booking, isPaidOffline })

  return <ActivityTracker items={items} />
}
