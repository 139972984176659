import React from 'react'

import { BookingApplicationNavigationList } from '@guiker/booking-components'
import { Box, Flex } from '@guiker/components-library'

import { useBookingInvitedLandlordScreenContext } from '../../context'
import { useBookingContext } from '../../hooks'
import { BookingViewByLandlordApplication } from './BookingInvitedLandlordApplication'

export const BookingInvitedLandlord: React.FC = () => {
  const { booking } = useBookingContext()
  const { handleSelectApplicant, selectedApplication } = useBookingInvitedLandlordScreenContext()

  return (
    <Flex fullWidth>
      {!!selectedApplication ? (
        <BookingViewByLandlordApplication />
      ) : (
        <Box pt={4} width='100%'>
          <BookingApplicationNavigationList booking={booking} onClickApplicant={handleSelectApplicant} />
        </Box>
      )}
    </Flex>
  )
}
