import React from 'react'

import { ParticipantAppContextProvider } from './context'
import { i18n, I18nextProvider } from './i18n'
import { Router } from './routes'

const App: React.FC<React.PropsWithChildren> = () => {
  return (
    <ParticipantAppContextProvider>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </ParticipantAppContextProvider>
  )
}

export { App }
