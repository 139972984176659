import { RouteConfig } from '@guiker/router'

import { BookingPanel } from '../components'

const routes: { [key: string]: RouteConfig } = {
  BookingModule: {
    path: '/',
    Component: BookingPanel,
    authenticationMethod: 'user',
  },
}

export { routes }
