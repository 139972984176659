import React from 'react'

import { Box, Dialog, Grid, H4, SecondaryButton } from '@guiker/components-library'
import { SuccessCheckmarkIcon } from '@guiker/icons'

import { useTakeoverContext, useTranslation } from '../../hooks'

type Props = {
  open: boolean
}

const BookingPaymentUpdatedModal: React.FC<Props> = ({ open }) => {
  const { t } = useTranslation('common')
  const { onClose } = useTakeoverContext()

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding={3}>
        <Grid container direction='column' alignItems='center' justify='center'>
          <Box mt={1} width={256} display='flex' alignItems='center' justifyContent='center'>
            <SuccessCheckmarkIcon />
          </Box>
          <Box mt={3} width={180} textAlign='center'>
            <H4>{t('success')}</H4>
          </Box>
          <Box mt={4}>
            <Grid container justify='center'>
              <SecondaryButton onClick={onClose}>{t('ok')}</SecondaryButton>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  )
}

export { BookingPaymentUpdatedModal }
