import React from 'react'

import { useT } from '../../i18n'
import { PercentRangeSlider } from '../PercentRangeSlider'

export const PROJECTED_IRR_RANGE = {
  min: 0,
  max: 0.3,
}

type ProjectedIRRSliderProps = {
  readOnly?: boolean
}

export const ProjectedIRRSlider: React.FC<ProjectedIRRSliderProps> = ({ readOnly }) => {
  const { tMain } = useT({ screenName: 'components.projectedIRR' })

  return (
    <PercentRangeSlider
      name='projectedIRR'
      label={tMain('label')}
      step={0.01}
      readOnly={readOnly}
      {...PROJECTED_IRR_RANGE}
    />
  )
}
