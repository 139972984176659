import React from 'react'

import { FormSection2 } from '@guiker/components-library'
import { MessagesBubbleIcon } from '@guiker/icons'
import { Quebec } from '@guiker/lease-shared'
import { LeaseActions } from '@guiker/lease-shared'

import { useLeaseContext, useTranslation } from '../../../hooks'
import { Note, Typography } from '../../'
import { SignatureContainer } from '../../common/SignatureContainer'
import { HeaderSecondaryDescriptionBar } from '../header'
import { Conditions } from './Conditions'
import { LowestPrecedingRent } from './LowestPrecedingRent'

type Props = {
  newLesseeNotice: Quebec.NewLesseeNotice
  lessors: Quebec.Lessor[]
}

export const FormContent: React.FC<Props> = ({ newLesseeNotice: defaultValue, lessors }) => {
  const name = 'newLesseeNotice'
  const { t } = useTranslation(['common', 'quebecLeaseScreenF'])

  const { can } = useLeaseContext()
  const canDraftLease = can(LeaseActions.DraftLease)
  const canSignAsLessor = can(LeaseActions.SignLeaseAsLessor)

  return (
    <FormSection2
      title={
        <HeaderSecondaryDescriptionBar index='G.' color='warning' description={t('quebecLeaseScreenG:headerLabel')} />
      }
    >
      <LowestPrecedingRent name={name} defaultValue={defaultValue} readOnly={!canDraftLease} />
      <Conditions name={name} defaultValue={defaultValue} readOnly={!canDraftLease} />
      <SignatureContainer
        defaultValue={defaultValue?.lessorSignature}
        readOnly={!canSignAsLessor}
        label={t('quebecLeaseScreenG:lessorSignature', {
          fullName: lessors[0].fullName || '',
        })}
        name={`${name}.lessorSignature`}
        type='signature'
      />
      <Note statusColor='warning' title={t('common:note')} icon={<MessagesBubbleIcon />}>
        <Typography>{t('quebecLeaseScreenG:text1')}</Typography>
        <Typography>{t('quebecLeaseScreenG:text2')}</Typography>
        <Typography>{t('quebecLeaseScreenG:text3')}</Typography>
      </Note>
    </FormSection2>
  )
}
