import { RentalListing } from '@guiker/rental-listing-shared'

type CanadaMLSProviders = 'MLS'
type UnitedStatesMLSProviders = 'ChicagoMLS'

type MLSInfo = {
  identifier: string
  disclaimer?: string
}

const mlsCompliance: {
  CA: {
    [key in CanadaMLSProviders]: MLSInfo
  }
  US: {
    [key in UnitedStatesMLSProviders]: MLSInfo
  }
} = {
  CA: {
    MLS: {
      identifier: 'MLS®#',
      disclaimer: 'main-rentalListing:mlsCompliance.CA.TRREB',
    },
  },
  US: {
    ChicagoMLS: {
      identifier: 'MLS®#',
      disclaimer: 'main-rentalListing:mlsCompliance.US.MRED',
    },
  },
}

type MLSCompliance = {
  identifier: {
    label: string
    value: string
  }
  disclaimer: {
    i18nKey: string
  }
}

const findMlsCompliance = (listing: RentalListing): MLSCompliance => {
  const country = listing.address.country.toUpperCase()
  const source = listing.source
  const hasCompliance = Object.keys(mlsCompliance).includes(country) && !!source
  const compliance = hasCompliance ? (mlsCompliance[country][source.name] as MLSInfo) || null : null

  return compliance
    ? {
        identifier: {
          label: compliance.identifier,
          value: source.reference,
        },
        disclaimer: {
          i18nKey: compliance.disclaimer,
        },
      }
    : undefined
}

export { MLSCompliance, findMlsCompliance }
