import { deprecatedRouterBuilder } from '@guiker/react-framework'

import {
  InsufficientFundFlaggedScreen,
  PayInvoiceScreen,
  PlaidTokenExpiredScreen,
  UpdatePaymentVerificationScreen,
} from '../screens'
import { InvoiceNestedRouter } from './InvoiceNestedRouter'
import { PaymentPayInMethodNestedRouter } from './PayInMethodNestedRouter'

export const { routes, Router } = deprecatedRouterBuilder({
  invoices: {
    path: '/invoice',
    isOutlet: true,
    routes: {
      invoiceNested: {
        path: '/:invoiceId',
        Component: InvoiceNestedRouter,
        isOutlet: true,
        routes: {
          invoice: {
            path: '/',
            Component: PayInvoiceScreen,
          },
        },
      },
    },
  },
  payInMethods: {
    path: '/pay-in-method',
    isOutlet: true,
    routes: {
      payInMethodNested: {
        path: '/:payInMethodId',
        Component: PaymentPayInMethodNestedRouter,
        isOutlet: true,
        routes: {
          payInMethod: {
            path: '/verification',
            Component: UpdatePaymentVerificationScreen,
          },
          resolve: {
            path: '/resolve',
            routes: {
              plaidTokenExpired: {
                path: '/plaid-token-expired',
                Component: PlaidTokenExpiredScreen,
              },
              insufficientFundFlagged: {
                path: '/insufficient-fund-flagged',
                Component: InsufficientFundFlaggedScreen,
              },
            },
          },
        },
      },
    },
  },
})
