import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { InvoiceContent } from '@guiker/payment-main-components'
import { PageLayout } from '@guiker/react-framework'

export const PayInvoiceScreen: React.FC = () => {
  const { user } = useAuthenticationContext()

  return (
    <PageLayout maxWidth={650}>
      <InvoiceContent user={user} />
    </PageLayout>
  )
}
