import { RouteConfig } from '@guiker/router'

import { CreateSuccessScreen, NewBookingDocumentScreen, ViewBookingDocumentsScreen } from '../screens'

const defaultRouteConfig: Partial<RouteConfig> = {
  exact: true,
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes: { [key: string]: RouteConfig } = {
  createSuccess: {
    ...defaultRouteConfig,
    path: 'create-success',
    Component: CreateSuccessScreen,
  },
  newBookingDocument: {
    ...defaultRouteConfig,
    path: 'new',
    Component: NewBookingDocumentScreen,
  },
  viewBookingDocuments: {
    ...defaultRouteConfig,
    path: 'documents',
    Component: ViewBookingDocumentsScreen,
  },
}

export { routes }
