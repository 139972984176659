import React, { useEffect } from 'react'

import { buildBankInformation, PayoutMethod } from '@guiker/payout-shared'
import { Box, FormSection, RadioGroup, useFormContext, useGetInputProps, useWatch } from '@guiker/react-framework'
import { DeepPartial } from '@guiker/ts-utils'
import { CountryCode } from '@guiker/use-plaid-link'

import { useEditLinkContext } from '../../context'
import { useTranslation } from '../../i18n'
import { ConnectOnlineBankingContent, ManualInputContent } from '..'
import { EditButton } from '../EditButton'

type FormContentProps = {
  defaultValue: DeepPartial<PayoutMethod> & { bankInformation: ReturnType<typeof buildBankInformation> }
  readOnly?: boolean
  countryCode?: CountryCode
  isLoading?: boolean
}

type BankInformationFormContentProps = FormContentProps & {
  title?: string
}

const country = {
  CANADIAN_BANK_ACCOUNT: CountryCode.Canada,
  US_BANK_ACCOUNT: CountryCode.UnitedStates,
}

const tPrefix = 'payout:payoutMethod.bankInformation'
const namespaces = ['payout', 'main-payout', 'common']

const BankInformationFormContent: React.FC<BankInformationFormContentProps> = ({
  defaultValue,
  readOnly,
  title,
  isLoading,
}) => {
  const { t } = useTranslation(namespaces)
  const contentProps = { readOnly, defaultValue }
  const countryCode = country[defaultValue?.type]
  const formContext = readOnly ? undefined : useFormContext()
  const formPrefix = 'bankInformation'
  const { editUrl } = useEditLinkContext({ shouldThrowIfUndefined: false }) ?? {}

  const inputProps = useGetInputProps({ formPrefix, namespaces, tPrefix, readOnly, defaultValue })

  const defaultInputTypeProps = {
    ...inputProps('isManualInput'),
    defaultValue: defaultValue.bankInformation?.isManualInput ?? true,
  }

  const isManualInput = readOnly
    ? defaultInputTypeProps.defaultValue
    : useWatch({
        name: `${formPrefix}.isManualInput`,
        defaultValue: defaultInputTypeProps.defaultValue,
      })

  useEffect(() => {
    if (!defaultValue?.details && !isLoading) {
      formContext?.setValue(`bankInformation.isManualInput`, isManualInput, { shouldDirty: true })
    }
  }, [isLoading, defaultValue])

  return (
    <FormSection title={title} hasDivider={!readOnly} topActions={readOnly && <EditButton url={editUrl} />}>
      <Box hidden={readOnly || countryCode === CountryCode.UnitedStates}>
        <RadioGroup
          {...defaultInputTypeProps}
          label={null}
          condensed={readOnly}
          options={[
            { value: true, label: t(`${tPrefix}.manualInput`) },
            { value: false, label: t(`${tPrefix}.onlineBanking`) },
          ]}
        />
      </Box>

      {(isManualInput && countryCode === CountryCode.Canada) || readOnly ? (
        <ManualInputContent {...contentProps} countryCode={countryCode} />
      ) : (
        <ConnectOnlineBankingContent {...contentProps} countryCode={countryCode} />
      )}
    </FormSection>
  )
}

export { BankInformationFormContentProps, BankInformationFormContent }
