import React from 'react'

import { ColumnGridItem, Note } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { useWatch } from '@guiker/react-hook-form'

import { Quebec } from '../../../types'
import { Checkbox, Collapse, TextField } from '../../'

interface Props {
  readOnly?: boolean
  name: string
  defaultValue: Quebec.LockerOrStorageSpace
}

const LockerOrStorageSpace: React.FC<Props> = ({ defaultValue, readOnly, name }) => {
  const lockerOrStorageSpace = useWatch({ name: `${name}.lockerOrStorageSpace`, defaultValue })
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])

  return (
    <ColumnGridItem md={2}>
      <div>
        <Checkbox
          defaultValue={lockerOrStorageSpace?.isAvailable}
          name={`${name}.lockerOrStorageSpace.isAvailable`}
          label={t('quebecLeaseScreenB:lockerStorage')}
          readOnly={readOnly}
        />
        <Collapse in={lockerOrStorageSpace?.isAvailable} unmountOnExit>
          <Note>
            <TextField
              name={`${name}.lockerOrStorageSpace.description`}
              defaultValue={lockerOrStorageSpace?.description}
              readOnly={readOnly}
              label={t('quebecLeaseScreenB:specify')}
            />
          </Note>
        </Collapse>
      </div>
    </ColumnGridItem>
  )
}

export { LockerOrStorageSpace }
