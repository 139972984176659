import React from 'react'

import { Display3, Flex, PaginatedGrid, RouterLink, SecondaryButton, useLayoutContext } from '@guiker/react-framework'
import { CarouselListings } from '@guiker/rental-listing-components'

import { useT } from '../../i18n'

type ListListingsProps<T extends { id: string }> = {
  cacheKey: string
  CellComponent
  fetcher: ({
    page,
    perPage,
  }: {
    page: number
    perPage: number
  }) => Promise<{ data: T[]; meta: { totalPages: number } }>
  i18nKey: string
  seeMoreI18nKey?: string
  i18nValues?: Record<string, unknown>
  to: string
}

export const ListListings = <T extends { id: string }>({
  i18nKey,
  i18nValues,
  seeMoreI18nKey,
  to,
  ...props
}: ListListingsProps<T>) => {
  const { tMain, tBase } = useT({ screenName: i18nKey })
  const { isMobile } = useLayoutContext()

  const GridComponent = isMobile ? (
    <CarouselListings {...props} />
  ) : (
    <PaginatedGrid {...props} withPagination={false} rows={1} maxColumns={3} minColumns={3} />
  )

  return (
    <Flex flexDirection='column'>
      <Display3>{tMain('title', i18nValues)}</Display3>
      <Flex flexDirection='column' gap={4} alignItems='center'>
        {GridComponent}
        <RouterLink to={to}>
          <SecondaryButton>{seeMoreI18nKey || tBase('actions.seeMore')}</SecondaryButton>
        </RouterLink>
      </Flex>
    </Flex>
  )
}
