import React from 'react'

import { TFunction } from '../../../hooks'
import { Ontario as Types } from '../../../types'
import { P, RadioGroup, TextField } from '../../'
import { FormSection3, OneColumnGridLayout } from '../../common'

type Props = {
  defaultValue: Types.Rent
  name: string
  readOnly?: boolean
  disabled?: boolean
  t: TFunction
}

export const RentToBePaid: React.FC<Props> = ({ defaultValue, name, readOnly, disabled, t }) => (
  <FormSection3>
    <P>{t('ontarioLeaseScreenC:rentToBePaidOn')}</P>
    <OneColumnGridLayout>
      <TextField
        defaultValue={defaultValue?.paymentDate}
        name={`${name}.paymentDate`}
        label={t('ontarioLeaseScreenC:paymentDate')}
        disabled={disabled}
        readOnly={readOnly}
        maxWidth={420}
      />
      <RadioGroup
        defaultValue={defaultValue?.rentFrequency}
        name={`${name}.rentFrequency`}
        label={t('ontarioLeaseScreenC:ofEach')}
        options={[
          { value: Types.RentFrequency.month, label: t('ontarioLeaseScreenC:month') },
          { value: Types.RentFrequency.other, label: t('ontarioLeaseScreenC:rentOther') },
        ]}
        disabled={disabled}
        readOnly={readOnly}
        maxWidth={328}
      />
    </OneColumnGridLayout>
  </FormSection3>
)
