import { mainPathBuilder } from '@guiker/rental-tenancy-shared'

import { useT } from '../i18n'

export const useSideMenuItems = () => {
  const { tMain } = useT()

  return [
    {
      text: tMain('screens.home.title'),
      href: mainPathBuilder.root.home.path,
    },
    {
      text: tMain('screens.tenantPreferences.title'),
      href: mainPathBuilder.root.tenantPreferences.path,
    },
  ]
}
