import React from 'react'

import { buildLocationNestedRoutes } from '@guiker/base-listing-components'
import { PublicPropertySaleListingRouter } from '@guiker/property-sale-context'
import { buildNestedListingRoutes, slugifyListing } from '@guiker/property-sale-shared'
import { RedirectNoIndex } from '@guiker/router'

import { usePublicPropertySaleListingIdContext } from '../hooks'
import { ListingScreen } from '../screens'
import { ListingIdNestedRouter } from './ListingIdNestedRouter'

const { defaultRouteConfig, countryNested } = buildLocationNestedRoutes({
  routes: {
    router: ListingIdNestedRouter,
    path: {
      id: () => {
        const { data: listing } = usePublicPropertySaleListingIdContext()
        const slug = slugifyListing(listing) ?? 'listing'
        return <RedirectNoIndex path={`../${slug}`} />
      },
      slugified: ListingScreen,
    },
  },
})

const routes = {
  root: {
    ...defaultRouteConfig,
    path: '/',
    Component: () => <RedirectNoIndex path={`./listings`} />,
  },
  listings: {
    ...defaultRouteConfig,
    path: '/listings',
    Component: PublicPropertySaleListingRouter,
    displayFooter: true,
    routes: {
      countryNested,
      idNested: {
        ...defaultRouteConfig,
        path: '/:id',
        Component: ListingIdNestedRouter,
        displayFooter: true,
        routes: {
          id: {
            ...defaultRouteConfig,
            path: '/',
            Component: () => {
              const { data: listing } = usePublicPropertySaleListingIdContext()
              return <RedirectNoIndex path={buildNestedListingRoutes(listing).listing} />
            },
          },
        },
      },
    },
  },
}

export { routes }
