import React from 'react'

import { OntarioSchemas } from '@guiker/lease-shared'
import { LeaseRoleNames } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useOntarioLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepAEdit: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { lessees, lessors, rentalUnit },
    },
  } = useOntarioLeaseContext()

  const onSubmit = (payload: OntarioSchemas.PartiesToTheAgreementAndRentalUnit) => {
    if (leaseId) {
      return apiClient.updateOntarioLeasePartiesToTheAgreementAndRentalUnit({ pathParams: { leaseId }, payload })
    } else {
      return apiClient.createOntarioLease({ payload: { leaseInformation: payload, draftedAs: LeaseRoleNames.DRAFTER } })
    }
  }

  return (
    <Step
      hasBackButton={false}
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.partiesToTheAgreementAndRentalUnit),
        defaultValues: { lessors, lessees, rentalUnit },
      }}
      formName={'OntarioLeaseFormStepA'}
      {...props}
    >
      <FormContent lessors={lessors} lessees={lessees} rentalUnit={rentalUnit} />
    </Step>
  )
}
