import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import {
  Divider,
  Flex,
  PageLayout,
  PageSection2,
  RouterLink,
  TextButton,
  TwoColumnsGridLayout,
  useParams,
} from '@guiker/react-framework'
import { useAuthenticatedListingContext, useAuthenticatedListingUnitContext } from '@guiker/rental-listing-context'
import { addressFormatter } from '@guiker/shared-framework'
import { RentCollectionPerTenantTable } from '@guiker/statistic-components'

import { RentCollectionPieChart } from '../../components'
import { RentCollectionContextProvider, RentCollectionMonthPicker, useRentCollectionContext } from '../../context'
import { useSideMenuItems } from '../../hooks'
import { useT } from '../../i18n'

const PropertyUnitRentalScreenContent: React.FC = () => {
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)
  const { listingId, inventoryId } = useParams()
  const { data: listing } = useAuthenticatedListingContext()
  const { data: unit } = useAuthenticatedListingUnitContext()
  const { date, region } = useRentCollectionContext()
  const { tMain } = useT({ screenName: 'screens.dashboard.rentalDashboard.monthlyPerformances' })
  const { tMain: tNav } = useT({ screenName: 'sideMenu.navItem' })

  const suite = unit.suiteNumber ? String(unit.suiteNumber) : undefined
  const address = addressFormatter.printStreetInformation(listing.address)
  const suiteAddress = addressFormatter.printStreetInformation({ ...listing.address, suite })
  const breadcrumbItems = [
    { label: tNav('dashboard'), to: '../../../../' },
    { label: tNav('rental'), to: '../../../../' },
    { label: address, to: '../../' },
    { label: suiteAddress },
  ]

  return (
    <PageLayout sideMenuProps={{ items: sideMenuNavItems }} breadcrumbItems={breadcrumbItems} title={suiteAddress}>
      <PageSection2
        title={tMain('title')}
        titleMb={0}
        headerAlignItems='flex-end'
        topActions={
          <Flex gap={4} alignItems='flex-end'>
            <Flex mb={1.5}>
              <RentCollectionMonthPicker />
            </Flex>
          </Flex>
        }
      >
        <Divider mt={2} />

        <TwoColumnsGridLayout>
          <RentCollectionPieChart region={region} date={date} listingId={listingId} unitId={inventoryId} />
        </TwoColumnsGridLayout>

        <RentCollectionPerTenantTable
          region={region}
          listingId={listingId}
          unitId={inventoryId}
          date={date}
          header={
            <RouterLink to={mainPathBuilder.root.rentCollection.path}>
              <TextButton>{tMain('title')}</TextButton>
            </RouterLink>
          }
        />
      </PageSection2>
    </PageLayout>
  )
}

export const PropertyUnitRentalScreen: React.FC = () => (
  <RentCollectionContextProvider>
    <PropertyUnitRentalScreenContent />
  </RentCollectionContextProvider>
)
