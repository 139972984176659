import React from 'react'

import { AnyColor, Grid, Label, makeStyles, PBold } from '@guiker/react-framework'

const useStyles = makeStyles({
  notAvailable: {
    width: 16,
    textAlign: 'center',
  },
})

const InfoLabel: React.FC<{
  adornment?: React.FC<{ color: AnyColor }>
  label: string
  isAvailable: boolean
}> = ({ adornment: Adornment, label, isAvailable }) => {
  const classes = useStyles()
  const color = isAvailable ? undefined : 30

  return (
    <Grid item sm={4} xs={12}>
      <Label
        adornment={{
          node: Adornment ? <Adornment color={color} /> : <div className={classes.notAvailable}>-</div>,
          position: 'start',
          align: 'center',
        }}
        text={
          <PBold color={color} mb={0}>
            {label}
          </PBold>
        }
      />
    </Grid>
  )
}

export { InfoLabel }
