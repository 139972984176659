import { initialize } from '@guiker/i18n'
import { en, fr, zh } from '@guiker/i18n-resources'
export * from '@guiker/i18n'

const i18n = initialize({
  resources: {
    en,
    fr,
    zh,
  },
})

export { i18n }
