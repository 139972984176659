import React from 'react'

import { Booking } from '@guiker/booking-shared'

import { Table } from '../'
import { getBookingDocumentColumns, parseBookingDocumentColumns } from './use-columns'

type BookingDocumentsTableProps = {
  booking: Booking
  accessControlToken: string
  applicantId?: string
}

const BookingDocumentsTableComponent: React.FC<BookingDocumentsTableProps> = ({
  booking,
  accessControlToken,
  applicantId = null,
}) => {
  const { documents } = booking
  return (
    <Table
      columns={getBookingDocumentColumns({
        scope: { type: 'booking', id: booking.id },
        accessControlToken,
        isApplicant: !!applicantId,
      })}
      data={parseBookingDocumentColumns(documents, applicantId)}
    />
  )
}

export const BookingDocumentsTable = React.memo(BookingDocumentsTableComponent)
