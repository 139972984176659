import React, { useState } from 'react'

import { CountryCode, getLocationService, Locations } from '@guiker/base-entity'
import {
  Flex,
  getLocalizedCity,
  getLocalizedCountry,
  getLocalizedState,
  H1,
  H2,
  H3,
  makeStyles,
  P,
  PageLayout,
  PageMetaTags,
  Redirect,
  theme,
  useTranslation,
} from '@guiker/react-framework'
import { PaginatedListingsGrid } from '@guiker/rental-listing-components'
import { LivingSection, SearchListing } from '@guiker/rental-listing-components'
import { webappRoutes } from '@guiker/rental-listing-shared'
import { Paginate, slugifyLowercase, upperFirst } from '@guiker/shared-framework'
import { useStaticAssetContext } from '@guiker/static-asset-context'

import { EmptyState, HeroSection, NeighbourhoodComponent } from '../../components'
import { usePaginationQueryParams, useRecommendedListingApiClient } from '../../hooks'
import { useLocationFromPath } from '../../services'
import { getCityBreadcrumbItemsFromLocation } from '../../utils/breadcrumb-helper'

const useStyle = makeStyles(
  {
    h2: {
      ...theme.typography.variants.h2,
    },
    pBig: {
      ...theme.typography.variants.bodyBig,
    },
  },
  { name: 'ListingCityScreen' },
)

const CityScreen: React.FC = () => {
  const location = useLocationFromPath()
  const { city, country, state } = location
  const { t } = useTranslation(['common', 'main-rentalListing'])
  const apiClient = useRecommendedListingApiClient()
  const { page } = usePaginationQueryParams()
  const classes = useStyle()
  const locationService = getLocationService()

  const cities = locationService.getCities()
  const [changedLocation, setChangedLocation] = useState<(typeof cities)[number]>(
    cities.find((loc) => loc.city.slug === location?.city?.slug),
  )
  const cityLabel = getLocalizedCity(t, { countryCode: country.countryCode, citySlug: city.slug })
  const stateLabel = getLocalizedState(t, { countryCode: country.countryCode, stateCode: state.stateCode })
  const contryLabel = getLocalizedCountry(t, { countryCode: country.countryCode })
  const seoI18nBase = `main-rentalListing:cityScreen.seo.${country.countryCode}.${state.stateCode}.${city.slug}`
  const baseDescription = t(`${seoI18nBase}.description`)
  const keywords = t(`${seoI18nBase}.keywords`)
  const onLocationChange = (citySlug: string) =>
    setChangedLocation(cities.find((location) => location.city.slug === citySlug))

  const fetcher = ({ page, perPage }: Paginate) =>
    apiClient.readAllRecommendedListingPerCity({
      pathParams: { cityId: city.slug },
      queryParams: { page, perPage },
    })

  const { getAsset } = useStaticAssetContext()
  const home = getAsset('home')
  const neighbourhoods = locationService.getCityNeighbourhoods(country.countryCode, state.stateCode, city.slug)

  if (locationService.newCities.includes(city.slug))
    return (
      <Redirect
        path={
          webappRoutes.listings
            .countryNested(country.countryCode)
            .cityNested(city.slug, slugifyLowercase(state.stateCode))
            .neighbourhoodNested(city.defaultNeighbourhood).path
        }
      />
    )

  if (city.slug === 'gatineau')
    return (
      <Redirect
        path={
          webappRoutes.listings
            .countryNested(CountryCode.CA)
            .cityNested(Locations.CA.states.ON.cities.ottawa.slug, slugifyLowercase(Locations.CA.states.ON.stateCode))
            .path
        }
      />
    )

  return (
    <>
      <PageMetaTags
        subtitle={t('main-rentalListing:cityScreen.seo.subtitle', {
          page,
          city: cityLabel,
          state: getLocalizedState(t, {
            countryCode: country.countryCode,
            stateCode: state.stateCode,
          }),
        })}
        description={`${baseDescription || cityLabel} - ${page}`}
        keywords={keywords.split(',')}
      />
      <HeroSection backgroundUrl={home.v1.explore[city.slug]} />
      <PageLayout breadcrumbItems={getCityBreadcrumbItemsFromLocation(t, location)}>
        <Flex flexDirection='column' alignItems='center'>
          <H1 mb={2} className={classes.h2}>
            {t('main-rentalListing:cityScreen.search.title', { cityName: cityLabel })}
          </H1>
          <P color={60} textAlign='center'>
            {t('main-rentalListing:cityScreen.search.subtitle', {
              neighborhoodCount: neighbourhoods.length,
              stateName: stateLabel.toLocaleUpperCase(),
              countryName: contryLabel.toLocaleUpperCase(),
            })}
          </P>
          <H2 textAlign='center' className={classes.pBig}>
            {keywords
              .split(',')
              .map((s) => upperFirst(s.trim()))
              .join(' / ')}
          </H2>
          <Flex mt={4} justifyContent='center' width='100%'>
            <SearchListing currentLocation={changedLocation} locations={cities} onLocationChange={onLocationChange} />
          </Flex>
        </Flex>
        <Flex mt={12} mb={8}>
          <LivingSection location={location} tPrefix={seoI18nBase} />
        </Flex>
        <H3 textAlign='center' mx={2}>
          {t(`main-rentalListing:cityScreen.recentListings.title`, { cityName: cityLabel })}
        </H3>

        <Flex mb={10}>
          <PaginatedListingsGrid
            cacheKey={['readAllRecommendedListingPerCity', city.slug]}
            fetcher={fetcher}
            emptyState={<EmptyState />}
            withLink={false}
          />
        </Flex>
        <div>
          <NeighbourhoodComponent city={city.slug} country={country.countryCode} state={state.stateCode} />
        </div>
      </PageLayout>
    </>
  )
}

export { CityScreen }
