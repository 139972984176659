import React from 'react'

import { LegacyIFrame } from '@guiker/legacy-iframe'
import { PageLayout, PageSection1, theme, toPx } from '@guiker/react-framework'

import { useSideMenuItems } from '../../hooks'
import { useT } from '../../i18n'

export const Home: React.FC = () => {
  const sideMenuNavItems = useSideMenuItems()
  const { tMain } = useT({ screenName: 'screens.home' })

  return (
    <PageLayout sideMenuProps={{ items: sideMenuNavItems }}>
      <PageSection1 title={tMain('title')}>
        <LegacyIFrame
          src={'/myaccount/home'}
          height={`calc(100vh - ${toPx(theme.dimensions.appBar.height.desktop + theme.spacing(16))})`}
        />
      </PageSection1>
    </PageLayout>
  )
}
