import React from 'react'

import {
  clsx,
  Divider,
  Flex,
  FurnishedIcon,
  H4,
  HandShakingIcon,
  Link,
  makeStyles,
  P,
  PBold,
  theme,
} from '@guiker/react-framework'
import { printBedAndBathCount } from '@guiker/rental-listing-components'
import { routesFromListing } from '@guiker/rental-listing-shared'
import { DateTime, money, parseDateTime, startCase } from '@guiker/shared-framework'

import { useConfirmListingInquiryScreenContext } from '../../../context'
import { getLocalizedCity, getLocalizedNeighbourhood, getLocalizedState, useTranslation } from '../../../i18n'

const useStyles = makeStyles({
  detailContainer: {
    width: '100%',
    maxWidth: 520,
    height: 'fit-content',
  },
  listingImage: {
    borderRadius: 4,
    width: '100%',
    maxWidth: 520,
    maxHeight: 425,
    objectFit: 'cover',
    objectPosition: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '18px',
  },
  suiteNumber: {
    color: theme.palette.grey[30],
    marginLeft: theme.spacing(2),
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

type ListingDetailProps = {
  className?: string
}

export const ListingDetail: React.FC<ListingDetailProps> = ({ className }) => {
  const { t } = useTranslation(['main-rentalListingInquiry', 'listing', 'common-rentalListing'])
  const { listing, selectedOption: rentalOption } = useConfirmListingInquiryScreenContext()
  const classes = useStyles()
  const {
    streetNumber,
    street,
    city: citySlug,
    neighbourhood: neighbourhoodSlug,
    state: stateCode,
    postalCode,
    country: countryCode,
  } = listing.address

  if (!rentalOption) {
    return <></>
  }

  const link = routesFromListing(listing).listing

  const { priceOption, roomCount, suiteNumber } = rentalOption
  const availability = DateTime.fromISO(priceOption.availablityDate)

  return (
    <div className={classes.detailContainer}>
      <img src={listing.pictures[0].url} className={classes.listingImage} alt='listing' />
      <div className={className}>
        <H4 color='primary'>{`${money.fromPrice(priceOption.price)}/${priceOption.duration.unit}`}</H4>
        <Flex mb={2}>
          <PBold className={classes.subtitle} mb={0}>
            {printBedAndBathCount(t, roomCount)}
          </PBold>
          {suiteNumber && (
            <P className={classes.suiteNumber} mb={0}>
              {t('listing:unitNumber', { unit: suiteNumber })}
            </P>
          )}
        </Flex>
        <Link
          className={clsx(classes.link, className)}
          isExternalLink
          underline={false}
          to={link}
          color={'textPrimary'}
        >
          <Flex flexDirection='column' gap={1}>
            <P>{`${getLocalizedNeighbourhood(t, {
              countryCode,
              citySlug,
              neighbourhoodSlug,
            })} \u2022 ${getLocalizedCity(t, { countryCode, citySlug })}`}</P>
            <P>{`${suiteNumber ? `${startCase(suiteNumber)} - ` : ''}${streetNumber} ${startCase(street)}`}</P>
            <P>{`${getLocalizedState(t, {
              countryCode,
              stateCode,
            })} ${postalCode.toUpperCase()}`}</P>
          </Flex>
        </Link>
        <Divider my={3} />

        <Flex gap={2}>
          <PBold>{`${t('main-rentalListingInquiry:components.listingDetail.available')}: `}</PBold>
          <P>
            {availability.diffNow('day').days > 0
              ? parseDateTime(priceOption.availablityDate).toUTC().toFormat('MMMM yyyy')
              : t('main-rentalListing:listingDetails.availableNow')}
          </P>
        </Flex>
        <Flex gap={2}>
          <HandShakingIcon color='info' />
          <P>
            {`${t(`listing:durations.${priceOption.duration.unit}`, {
              count: priceOption.duration.count,
            })} ${t('listing:lease')}`}
          </P>
        </Flex>
        <Flex gap={2}>
          <FurnishedIcon color={priceOption.furnished === 'none' ? 10 : 'info'} />
          <P>
            {t(
              `common-rentalListing:${
                priceOption.furnished === 'fully'
                  ? 'furnished'
                  : priceOption.furnished === 'partially'
                  ? 'partiallyFurnished'
                  : 'unfurnished'
              }`,
            )}
          </P>
        </Flex>
      </div>
    </div>
  )
}
