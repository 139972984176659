import React from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { Divider } from '@guiker/react-framework'
import { MobileRentPaymentsPlanTable } from '@guiker/rent-payment-components'
import { Payer, RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'

import { MobileRentPaymentsPlanDetails } from './MobileRentPaymentsPlanDetails'

type MobileRentPaymentsPlanProps = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
  payInMethod: PayInMethod
}

export const MobileRentPaymentsPlan: React.FC<MobileRentPaymentsPlanProps> = ({
  rentPaymentsPlan,
  payer,
  payInMethod,
}) => {
  const {
    total: { currency },
  } = rentPaymentsPlan

  return (
    <div>
      <MobileRentPaymentsPlanDetails rentPaymentsPlan={rentPaymentsPlan} payer={payer} payInMethod={payInMethod} />
      <Divider my={3} color={15} />
      <MobileRentPaymentsPlanTable rentPaymentsPlan={rentPaymentsPlan} payer={payer} currency={currency} />
    </div>
  )
}
