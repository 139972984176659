import React from 'react'

import { Tab, Tabs, useLayoutContext, useNavigate } from '@guiker/react-framework'

import { useInvestmentScreenContext } from './InvestmentScreenContext'

export const InvestmentsSearchTabs: React.FC = () => {
  const { tabs, currentIndex, setCurrentIndex } = useInvestmentScreenContext()
  const { isMobile } = useLayoutContext()
  const navigate = useNavigate()

  const onTabClick = (_, index) => {
    setCurrentIndex(index as number)
    navigate('./')
  }

  return (
    <Tabs
      value={currentIndex}
      withBorder={false}
      onChange={onTabClick as any}
      variant={isMobile ? 'fullWidth' : 'standard'}
    >
      {tabs.map(({ label, key, icon }) => (
        <Tab label={label} key={key} icon={isMobile ? undefined : icon} />
      ))}
    </Tabs>
  )
}
