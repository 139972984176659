import React, { useEffect } from 'react'

import { HistoryModal } from '@guiker/booking-components'
import {
  Application,
  ApplicationStatusGroup,
  AuthenticatedApi,
  BookingStatus,
  isRoommatable,
  RoommateIntroStatusGroup,
} from '@guiker/booking-shared'
import {
  ButtonContainer,
  FullScreenSpinner,
  MessagesBubbleIcon,
  useModal,
  useQuery,
  yupResolver,
} from '@guiker/react-framework'
import { useApplicationComponentsContext, ViewTenantApplication } from '@guiker/tenant-application-components'

import {
  ApiForm,
  ApiFormAction,
  Button,
  ConfirmDialog,
  FormSection2,
  Note,
  PageLayout,
  SecondaryButton,
  TextField,
} from '../../components'
import { useAuthenticationContext, useBookingApplicationContext, useBookingContext, useTranslation } from '../../hooks'
import { useBookingApiClient } from '../../lib'

const isSubmitted = (application: Application, hasRoommateStep: boolean) => {
  const applicationSubmitted = ApplicationStatusGroup.REVIEWABLE.includes(application?.status)
  if (hasRoommateStep) {
    const roommateSubmitted = RoommateIntroStatusGroup.REVIEWABLE.includes(application?.roommateIntro?.status)
    return applicationSubmitted && roommateSubmitted
  }
  return applicationSubmitted
}

export const ApplicantViewBookingApplicationScreen: React.FC = () => {
  const { t } = useTranslation(['common', 'main-bookingApplicant', 'main-tenantApplication'])
  const { bookingId, booking, bookingStatus, bookingChat, refetchBooking } = useBookingContext()
  const { user } = useAuthenticationContext()
  const { isLoading, bookingApplication } = useBookingApplicationContext()
  const { setCanViewBackgroundCheck, setEditRoutePath, editRoutePath } = useApplicationComponentsContext()
  const { isOpen: isWithdrawOpen, openModal: openWithdrawModal, closeModal: closeWithdrawModal } = useModal()

  const bookingApiClient = useBookingApiClient()
  const hasRoommateStep = booking.hasEnabledStep.roommate && isRoommatable(booking.listing)
  const canViewDoneButton = isSubmitted(bookingApplication, hasRoommateStep)

  const withdrawApplication = (payload: AuthenticatedApi.Schema.CancelBookingRequestPayload) =>
    bookingApiClient.withdrawBooking({ pathParams: { bookingId }, payload })
  const withdrawResolver = yupResolver(AuthenticatedApi.Schema.cancelBookingRequestSchema)

  const submitResolver = yupResolver(AuthenticatedApi.Schema.submitBookingApplicationPayloadSchema)
  const submitApplication = () =>
    bookingApiClient.submitBookingApplication({
      pathParams: { bookingId, applicantUserId: user?.id },
      payload: { leasePromise: null },
    })

  useEffect(() => {
    setCanViewBackgroundCheck(false)
    setEditRoutePath('../edit')
  }, [])

  const { data: roommates, isFetching } = useQuery(
    `roommates-${booking?.id}`,
    () => bookingApiClient.readBookingRoommates({ pathParams: { bookingId: booking.id } }),
    {
      retry: 1,
      enabled: !!booking,
    },
  )

  if (isFetching || isLoading) return <FullScreenSpinner />

  const tenantIds = bookingApplication?.roommateIntro?.tenants?.map((t) => t.userId) || []
  const myRoommates = bookingStatus.isBooked ? roommates : roommates.filter((r) => tenantIds.includes(r.userId))

  return (
    <PageLayout
      maxWidth={900}
      hasButtonContainer
      forceTakeover
      title={t('main-tenantApplication:screens.createTenantApplication.title')}
      subtitle={
        ApplicationStatusGroup.REVIEWED.includes(bookingApplication?.status) &&
        t(`main-bookingApplicant:statusSubHeader.${bookingApplication.status}`)
      }
      takeoverProps={{
        onClose: bookingChat.navigate,
      }}
    >
      <HistoryModal bookingId={bookingId} applicationId={bookingApplication?.userId} />
      <ApiForm formName='SubmitBookingApplication' formOptions={{ resolver: submitResolver }}>
        <FormSection2>
          {bookingApplication?.reviewalComments && (
            <Note
              icon={<MessagesBubbleIcon />}
              title={t('main-bookingApplicant:screens.viewBookingApplication.reviewalComments.title')}
              statusColor='info'
            >{`"${bookingApplication?.reviewalComments}"`}</Note>
          )}

          <ViewTenantApplication
            isLoading={isLoading}
            showRoommateProfile={hasRoommateStep}
            roommates={myRoommates?.filter((r) => r.userId !== user.id)}
            tenantApplication={bookingApplication?.content}
            editRoutePath={bookingStatus.isBooked ? undefined : editRoutePath}
          />

          {![BookingStatus.CONFIRM_REQUESTED, BookingStatus.BOOKED].includes(booking.status) && (
            <ButtonContainer>
              <SecondaryButton type='button' onClick={openWithdrawModal}>
                {t('main-bookingApplicant:actions.withdrawApplication')}
              </SecondaryButton>
              {canViewDoneButton ? (
                <Button onClick={bookingChat.navigate}>{t('main-bookingApplicant:actions.done')}</Button>
              ) : (
                <ApiFormAction
                  onClick={submitApplication}
                  options={{
                    onSuccess: () => {
                      refetchBooking()
                      bookingChat.navigate()
                    },
                  }}
                >
                  {t('main-bookingApplicant:actions.submit')}
                </ApiFormAction>
              )}
            </ButtonContainer>
          )}
        </FormSection2>
      </ApiForm>
      <ApiForm formName='WithdrawBookingApplication' formOptions={{ resolver: withdrawResolver }}>
        <ConfirmDialog
          open={isWithdrawOpen}
          title={t('main-bookingApplicant:screens.viewBookingApplication.modal.title')}
          onClose={closeWithdrawModal}
          description={
            <TextField
              name='cancelReason'
              label={t('main-bookingApplicant:screens.viewBookingApplication.modal.description')}
              rows={5}
              multiline
            />
          }
          cancelButtonProps={{ onClick: closeWithdrawModal, children: t('common:cancel') }}
        >
          <ApiFormAction
            onClick={withdrawApplication}
            options={{ onSuccess: bookingChat.navigate }}
            buttonProps={{ color: 'alert' }}
          >
            {t('main-bookingApplicant:actions.withdraw')}
          </ApiFormAction>
        </ConfirmDialog>
      </ApiForm>
    </PageLayout>
  )
}
