import React, { useEffect } from 'react'

import { useAuthenticationModalContext } from '@guiker/authentication-components'
import { AuthType, useAuthenticationContext } from '@guiker/authentication-context'
import { User } from '@guiker/authentication-shared'
import { ListingBanner, ListingPictures } from '@guiker/base-listing-components'
import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { mainPathBuilder } from '@guiker/investment-shared'
import { PayInMethodModals } from '@guiker/payment-app-components'
import { PayInMethodContextProvider } from '@guiker/payment-context'
import { SupportedCountries } from '@guiker/payment-shared'
import { useAuthApiClient } from '@guiker/propsharing-context'
import {
  allowedPaymentTypes,
  AuthApi,
  AuthApiClient,
  buildDisplayName,
  findLatestSeries,
  getDisplayName,
  SeriesStatus,
  UnavailableReason,
} from '@guiker/propsharing-shared'
import {
  AnnouncementIcon,
  ApiForm,
  CheckBadgeIcon,
  DisplayBody,
  Flex,
  FullScreenSpinner,
  H3,
  makeStyles,
  Modal,
  Note,
  PageLayout,
  PageMetaTags,
  TabLayout,
  theme,
  useConfig,
  useDateFormatter,
  useMediaQuery,
  useTranslation,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import { projectHasDocument } from '@guiker/real-estate-shared'

import { PropSharingInquiryCard, ReservationDisclosure } from '../../components'
import { isListingWithInquiry } from '../../components/utils'
import { ListingScreenContextProvider, useListingScreenContext } from '../../context'
import { usePayInMethodContext } from '../../hooks'
import { DetailsTab, FinancialDetailsTab, ProjectAndDocumentsTab } from './tabs'

const useStyles = makeStyles(
  (theme) => ({
    address: {
      ...theme.typography.variants.h3,
      fontWeight: 400,
      color: theme.palette.grey[60],
    },
    divider: {
      ...theme.typography.variants.h3,
      fontWeight: 300,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'PropSharingListingScreen' },
)

const Content: React.FC = () => {
  const classes = useStyles()
  const tPrefix = 'main-propsharing:screens.listingScreen'
  const { t } = useTranslation(['common-propsharing', 'main-propsharing'])
  const { project, listing, stockAllowance } = useListingScreenContext()
  const { setInvestmentAssumptions } = useInvestmentAssumptionContext()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'))
  const assumptions = useWatch({ name: 'assumptions', defaultValue: listing.assumptions })
  const canPurchaseStock = !stockAllowance?.unavailableReason

  useEffect(() => {
    setInvestmentAssumptions(assumptions)
  }, [assumptions])

  const address = getDisplayName(listing, project)

  return (
    <Flex flexDirection='column' maxWidth='100%' pb={isExtraSmall && canPurchaseStock ? 21 : 0}>
      <Flex flexDirection='column' gap={6}>
        <ListingPictures listing={listing} />
        <Flex fullWidth flexDirection='column' gap={2}>
          <H3 mb={0}>
            {listing.stock.tickerSymbol} <span className={classes.divider}>|</span>{' '}
            <span className={classes.address}>{address}</span>
          </H3>
          <Note statusColor='alabaster'>
            <ReservationDisclosure />
          </Note>
        </Flex>
        <TabLayout
          tabs={[
            {
              content: (
                <Flex flexDirection='column' gap={isMobile ? 10 : 16} pt={6}>
                  <DetailsTab />
                </Flex>
              ),
              label: t(`${tPrefix}.tab1.label`),
            },
            project || projectHasDocument(project)
              ? {
                  content: (
                    <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
                      <ProjectAndDocumentsTab project={project} listing={listing} />
                    </Flex>
                  ),
                  label: t(`${tPrefix}.tab2.label.${project ? 'project' : 'documents'}`),
                }
              : undefined,
            {
              content: (
                <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
                  <FinancialDetailsTab />
                </Flex>
              ),
              label: t(`${tPrefix}.tab3.label`),
            },
          ].filter((t) => !!t)}
        />
      </Flex>
    </Flex>
  )
}

export const ListingScreenFormWrapped: React.FC = () => {
  const { t } = useTranslation(['common-propsharing', 'main-propsharing'])
  const { formatDate } = useDateFormatter()
  const { project, listing, refetchInvestorProfile, setInquiry, stockAllowance, isNoteOpen, noteContent, handleNote } =
    useListingScreenContext()
  const {
    helpers: {
      stock: { latestSeries },
    },
  } = useListingScreenContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const { user: currentUser } = useAuthenticationContext()
  const { selected: selectedPayInMethod } = usePayInMethodContext()

  const apiClient = useAuthApiClient()
  const config = useConfig()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const resolver = yupResolver(AuthApi.Schema.upsertPropSharingInquirySchema)

  const onSubmit = async (formData: Required<AuthApi.Schema.UpsertPropSharingInquirySchema>) => {
    const payload = { ...formData, payInMethod: selectedPayInMethod?.id }

    return isListingWithInquiry(listing) && inquiry?.id
      ? apiClient.updateInquiry({ pathParams: { id: inquiry.id }, payload })
      : apiClient.createInquiry({ payload: { ...payload, payInMethod: selectedPayInMethod?.id } })
  }

  if (!listing) {
    return <FullScreenSpinner />
  }

  const inquiry = isListingWithInquiry(listing) ? listing?.fractionalOwnershipInquiry : undefined
  const address = getDisplayName(listing, project)
  const leadPicture = listing.pictures[0]

  return (
    <PageLayout
      breadcrumbItems={[
        {
          to: mainPathBuilder.root.propSharing.path,
          label: t('main-investments:breadcrumb.investments'),
        },
        {
          label: `${listing.stock.tickerSymbol} - ${listing.stock.label}`,
        },
      ]}
    >
      <PageMetaTags
        subtitle={address}
        description={`${listing.description} - ${address}`}
        imageUrl={leadPicture?.url}
      />
      <Flex flexDirection='column' gap={4}>
        {latestSeries.status === SeriesStatus.COMING_SOON ? (
          <ListingBanner
            color='alabaster'
            text={t('main-propsharing:components.inquiryCard.unavailableReason.COMING_SOON', {
              when: !latestSeries.startDate
                ? t('main-propsharing:components.inquiryCard.unavailableReason.SOON')
                : t('main-propsharing:components.inquiryCard.unavailableReason.COMING_SOON_DATE', {
                    date: formatDate(latestSeries.startDate),
                  }),
            })}
            icon={<AnnouncementIcon size='medium' />}
          />
        ) : stockAllowance?.unavailableReason ? (
          <ListingBanner
            color={stockAllowance.unavailableReason === UnavailableReason.FULLY_FUNDED ? 'alabaster' : 'cancelled'}
            text={t(`main-propsharing:components.inquiryCard.unavailableReason.${stockAllowance.unavailableReason}`)}
            icon={
              stockAllowance.unavailableReason === UnavailableReason.FULLY_FUNDED && <CheckBadgeIcon size='medium' />
            }
          />
        ) : isListingWithInquiry(listing) ? (
          <ListingBanner color='alabaster' text={t('main-propsharing:components.inquiryCard.existingInquiry')} />
        ) : undefined}
        <ApiForm
          formName='PropSharingInquiryForm'
          skipIfIsNotDirty={false}
          onSubmit={onSubmit}
          beforeValidation={{
            run: async ({ resolve, reject: onDismiss, formData }) => {
              const onSuccess = async (user: User) => {
                const authApiClient = AuthApiClient({
                  baseUrl: config.apiBaseUrl,
                  logger: config.debug ? console : undefined,
                  accessToken: user.accessToken,
                })
                await authApiClient.createInquiry({ payload: formData }).then(resolve)
              }
              currentUser ? resolve() : openAuthenticationModal(AuthType.LOGIN, { onSuccess, onDismiss })
            },
          }}
          formOptions={{
            resolver,
            defaultValues: {
              listingId: listing?.id,
              stockId: listing?.stock?.id,
              askedPrice: listing?.stock ? findLatestSeries(listing?.stock)?.pricePerUnit : undefined,
              unitAmount: inquiry?.unitAmount ?? listing?.stock.minPerTransaction,
              assumptions: listing?.assumptions ?? {},
              results: listing?.results ?? {},
            },
          }}
          apiOptions={{
            onSuccess: async (inquiry) => {
              setInquiry(inquiry)
              refetchInvestorProfile()
              return inquiry
            },
          }}
        >
          {({ formState, isSuccess }) => (
            <Flex>
              <Content />
              {(!stockAllowance?.unavailableReason || !isSmallScreen) && (
                <PropSharingInquiryCard
                  isLoading={formState.isSubmitting}
                  isSuccess={isSuccess}
                  displayName={buildDisplayName(listing.property, project)}
                />
              )}
            </Flex>
          )}
        </ApiForm>
      </Flex>
      {isSmallScreen && (
        <Modal open={isNoteOpen} onClose={() => handleNote(noteContent)} title={noteContent.title}>
          <DisplayBody mb={0}>{noteContent.content}</DisplayBody>
        </Modal>
      )}
    </PageLayout>
  )
}

export const ListingScreen: React.FC = () => {
  return (
    <ListingScreenContextProvider>
      <PayInMethodContextProvider allowedTypes={allowedPaymentTypes} region={SupportedCountries.Canada}>
        <ListingScreenFormWrapped />
        <PayInMethodModals />
      </PayInMethodContextProvider>
    </ListingScreenContextProvider>
  )
}
