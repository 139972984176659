import React from 'react'

import {
  Card,
  Display3,
  DisplayBody,
  Flex,
  isAtLeastMidSizeDesktop,
  isAtMostTablette,
  isMobile,
  makeStyles,
  padding,
  ThemeContextProvider,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { useInvestmentScreenContext } from './InvestmentScreenContext'

const TEXT_BLOCK_WIDTH = 600

const useStyles = makeStyles(
  (theme) => ({
    root: ({ imageUrl }: { imageUrl: string }) => ({
      padding: padding(0, 8),
      display: 'flex',
      justifyContent: 'center',
      [isMobile]: {
        padding: padding(0, 2),
      },
      [isAtLeastMidSizeDesktop]: {
        height: 316,
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
      },
    }),
    textBlock: {
      padding: padding(8, 0),
      maxWidth: TEXT_BLOCK_WIDTH,
      background: `linear-gradient(90deg, ${theme.palette.common.black} 0px, ${theme.palette.common.black} ${
        TEXT_BLOCK_WIDTH - 100
      }px, ${theme.palette.common.black}00 ${TEXT_BLOCK_WIDTH}px, ${theme.palette.common.black}00 100%)`,
      [isAtMostTablette]: {
        padding: padding(4, 0),
      },
    },
    textBlockContainer: {
      width: '100%',
      maxWidth: theme.dimensions.pageLayout.maxWidth.desktop,
    },
  }),
  { name: 'SearchHeader' },
)
export const SearchHeader: React.FC = () => {
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('investments')
  const classes = useStyles({ imageUrl: assets.header })

  const {
    currentTab: { label, content },
  } = useInvestmentScreenContext()

  return (
    <ThemeContextProvider isDarkMode={true}>
      <Card className={classes.root}>
        <Flex className={classes.textBlockContainer}>
          <Flex flexDirection='column' gap={3} className={classes.textBlock}>
            <Display3 mb={0}>{label}</Display3>
            <DisplayBody mb={0}>{content}</DisplayBody>
          </Flex>
        </Flex>
      </Card>
    </ThemeContextProvider>
  )
}
