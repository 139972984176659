import React, { createContext, useEffect, useState } from 'react'

import { isPaidOffline as checkIsPaidOffline, Payout, WithPayoutMethod, WithTransfers } from '@guiker/payout-shared'
import { generateUseContext, useQuery } from '@guiker/react-framework'
import { Event } from '@guiker/shared-framework'

import { useAuthenticatedStatisticApiClient } from '../hooks'

type Context = {
  payout: WithPayoutMethod<WithTransfers<Payout>>
  isLoading: boolean
  events: Event<Payout>[]
  isPaidOffline: boolean
}

type PayoutContextProviderProps = React.PropsWithChildren & {
  payoutId: string
}

export const PayoutContext = createContext<Context>(undefined)

export const PayoutContextProvider: React.FC<PayoutContextProviderProps> = ({ payoutId, children }) => {
  const apiClient = useAuthenticatedStatisticApiClient()
  const [isPaidOffline, setIsPaidOffline] = useState(false)

  const {
    data: payout,
    isFetching,
    isLoading,
  } = useQuery(
    ['my-investment-read-one-payout', payoutId],
    () => apiClient.readOnePayout({ pathParams: { payoutId } }),
    {
      enabled: !!payoutId,
      retry: 1,
    },
  )
  const {
    data: events,
    isFetching: isPayoutEventsFetching,
    isLoading: isPayoutEventsLoading,
  } = useQuery(
    ['me-investment-read-payout-events', payoutId],
    () => apiClient.readOnePayoutEvents({ pathParams: { payoutId } }),
    {
      enabled: !!payoutId,
      retry: 1,
    },
  )

  useEffect(() => {
    payout && setIsPaidOffline(checkIsPaidOffline(payout))
  }, [payout])

  const value = {
    payout,
    events,
    isPaidOffline,
    isLoading: isFetching || isLoading || isPayoutEventsFetching || isPayoutEventsLoading,
  }

  return <PayoutContext.Provider value={value}>{children}</PayoutContext.Provider>
}

export const usePayoutContext = generateUseContext(PayoutContext)
