import React from 'react'

import { RoommateCard } from '@guiker/base-listing-components'
import { TimerContextProvider } from '@guiker/booking-components'
import { useAuthenticatedBookingApiClient } from '@guiker/booking-context'
import { RoommateIntroStatusGroup } from '@guiker/booking-shared'
import { ButtonContainer, Divider, Flex, FormSection, useNavigate, useQuery, useT } from '@guiker/react-framework'
import { RoommateProfileFormContent } from '@guiker/tenant-application-components'

import { ApiForm, FullScreenSpinner, PageLayout, TakeoverContextProvider } from '../../components'
import { useBookingApplicationContext, useBookingContext } from '../../hooks'
import { UnitManagerActions } from './UnitManagerActions'

const UnitManagerViewBookingApplicationRoommateIntroScreen: React.FC = () => {
  const { tMain } = useT({ domain: 'tenantApplication' })
  const { tMain: tBookingMain } = useT({ domain: 'bookingApplicant' })
  const { isLoading, bookingApplication } = useBookingApplicationContext()
  const { booking, bookingChat } = useBookingContext()
  const navigate = useNavigate()
  const apiClient = useAuthenticatedBookingApiClient()

  const { data: roommates, isFetching } = useQuery(
    `roommates-${booking?.id}`,
    () => apiClient.readBookingRoommates({ pathParams: { bookingId: booking.id } }),
    {
      retry: 1,
      enabled: !!booking,
    },
  )

  if (isLoading || isFetching) {
    return <FullScreenSpinner />
  }

  if (!isLoading && !bookingApplication) {
    return <></>
  }

  return (
    <TakeoverContextProvider
      onClose={bookingChat.navigate}
      onBack={booking.applicants.length === 1 ? bookingChat.navigate : () => navigate('../../applications')}
      title={tMain('screens.roommateProfile.title')}
      subtitle={
        RoommateIntroStatusGroup.REVIEWABLE.includes(bookingApplication.roommateIntro?.status) &&
        tBookingMain(`statusSubHeader.${bookingApplication.roommateIntro.status}`)
      }
    >
      <PageLayout
        maxWidth={900}
        hasButtonContainer={
          RoommateIntroStatusGroup.IMMUTABLE.includes(bookingApplication?.roommateIntro?.status) ? false : true
        }
      >
        <ApiForm formName='UnitManagerViewRoommateProfile'>
          <RoommateProfileFormContent tenantApplication={bookingApplication?.content} readOnly />
          {roommates?.length && (
            <>
              <Divider mt={6} mb={6} />
              <FormSection title={tMain('screens.roommateProfile.roommates')}>
                <Flex flexWrap='wrap' gap={2}>
                  {roommates.map((roommate) => (
                    <RoommateCard
                      roommate={roommate}
                      tenant={bookingApplication?.roommateIntro?.tenants?.find((t) => t.userId === roommate.userId)}
                    />
                  ))}
                </Flex>
              </FormSection>
            </>
          )}

          {!RoommateIntroStatusGroup.IMMUTABLE.includes(bookingApplication?.roommateIntro?.status) && (
            <ButtonContainer>
              <TimerContextProvider timer={bookingApplication?.roommateIntro?.timer}>
                <UnitManagerActions />
              </TimerContextProvider>
            </ButtonContainer>
          )}
        </ApiForm>
      </PageLayout>
    </TakeoverContextProvider>
  )
}

export { UnitManagerViewBookingApplicationRoommateIntroScreen }
