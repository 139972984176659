import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { Route, Routes } from '@guiker/router'

import { FullScreenSpinner } from '../components'
import { useLeaseContext } from '../hooks'
import { getLeaseScreens } from '../screens'
import { routes } from '.'

const InvitedLessorRouter: React.FC = (props) => {
  const { lease, isLoading } = useLeaseContext()

  if (isLoading) {
    return <FullScreenSpinner />
  }

  if (!lease) {
    return <PageNotFound />
  }

  const { InvitedLessorSignLease } = getLeaseScreens(lease.type === 'canada-quebec' ? 'QC' : 'ON')

  return (
    <Routes>
      <Route {...routes.signLeaseFromTokenLink} Component={InvitedLessorSignLease} {...props} />
    </Routes>
  )
}

export { InvitedLessorRouter }
