import React from 'react'

import { AnyColor, padding, theme } from '@guiker/components-core'
import { Flex } from '@guiker/components-library'

import { clsx, makeStyles } from '../../../style'
import { H4 } from '../../'

type HeaderSecondaryDescriptionBarProps = {
  className?: string
  index: string
  description: string
  color?: string
}

const useStyles = makeStyles(
  {
    root: {
      padding: padding(1, 2),
      backgroundColor: ({ color }: { color: AnyColor }) => theme.palette.getColor(color),
    },
  },
  { name: 'HeaderSecondaryDescriptionBar' },
)

export const HeaderSecondaryDescriptionBar: React.FC<HeaderSecondaryDescriptionBarProps> = (
  props: HeaderSecondaryDescriptionBarProps,
) => {
  const { className, index, description, color = 'info', ...others } = props

  const classes = useStyles({ color })

  return (
    <Flex flexDirection='row' fullWidth gap={2} className={clsx([className, classes.root])} {...others}>
      <H4 mb={0} color='white'>
        {index}
      </H4>
      <H4 mb={0} color='white'>
        {description}
      </H4>
    </Flex>
  )
}
