import React, { useState } from 'react'

import { Payment } from '@guiker/base-entity'
import { money } from '@guiker/money'
import { FundingAccount } from '@guiker/payment-shared'
import { Payout } from '@guiker/payout-app-components'
import { PayoutContextProvider, usePayoutContext } from '@guiker/payout-context'
import { Capabilities } from '@guiker/payout-shared'

import { useAuthenticatedFundingAccountContext, useAuthenticatedPaymentApiClient } from '../../hooks'
import { useT } from '../../i18n'
import { ApiForm, ButtonWithLoader, CurrencyTextField, Flex, H4, Modal, ModalProps, PSmall, SecondaryButton } from '..'

export type WithdrawalModalProps = ModalProps & {
  fundingAccount: FundingAccount
  refetch: () => void
}

export type WithdrawalFormProps = {
  fundingAccount: FundingAccount
  onClose: () => void
  refetch: () => void
}

const WithdrawalForm: React.FC<WithdrawalFormProps> = ({ fundingAccount, onClose, refetch }) => {
  const { tMain } = useT({ screenName: 'components.withdrawalModal' })
  const { refetch: refetchFundingAccount } = useAuthenticatedFundingAccountContext()
  const { selected } = usePayoutContext()
  const apiClient = useAuthenticatedPaymentApiClient()
  const [amount, setAmount] = useState(0)

  const onSubmit = async () =>
    selected &&
    apiClient.createPayoutWithdrawal({
      pathParams: { fundingAccountId: fundingAccount.id },
      payload: { payoutMethodId: selected.id, amount },
    })

  const onSuccess = () => {
    refetch()
    refetchFundingAccount()
    onClose()
  }

  const handleAmountChange = (input: string) => {
    const numberInput = Number(input) ?? 0
    setAmount(numberInput)
  }

  return (
    <ApiForm onSubmit={onSubmit} apiOptions={{ onSuccess }} formName='CreateFundingAccountWithdrawal'>
      {({ isLoading, formState: { errors } }) => (
        <Flex flexDirection='column' gap={5}>
          <div>
            <CurrencyTextField
              label={tMain('withdrawalAmount')}
              name='amount'
              defaultValue={amount}
              onChange={(v) => handleAmountChange(v)}
            />
            <PSmall color={60} mb={0} mt={2}>
              {tMain('availableToWithdraw', {
                amount: money.fromAmount(fundingAccount.balance, fundingAccount.currency).toString(),
              })}
            </PSmall>
          </div>
          <div>
            <H4>{tMain('selectPaymentDestination')}</H4>
            <Payout.PayoutMethodSelector capabilities={[Capabilities.INVESTMENT]} />
          </div>
          <Flex gap={2} justifyContent='flex-end'>
            <SecondaryButton onClick={() => onClose()}>{tMain('cancel')}</SecondaryButton>
            <ButtonWithLoader disabled={!selected} isLoading={isLoading} errors={errors} type='submit'>
              {tMain('cta')}
            </ButtonWithLoader>
          </Flex>
        </Flex>
      )}
    </ApiForm>
  )
}

const WithdrawalModal: React.FC<WithdrawalModalProps> = ({ open, onClose, fundingAccount, refetch }) => {
  const { tMain } = useT({ screenName: 'components.withdrawalModal' })

  return (
    <Modal open={open} keepMounted title={tMain('title')} onClose={onClose} maxWidth={500}>
      <PayoutContextProvider capabilities={[Capabilities.INVESTMENT]} regions={[Payment.SupportedCountries.Canada]}>
        <WithdrawalForm onClose={onClose} fundingAccount={fundingAccount} refetch={refetch} />
      </PayoutContextProvider>
    </Modal>
  )
}

export { WithdrawalModal }
