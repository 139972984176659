import React from 'react'

import { yupResolver } from '@guiker/react-hook-form'
import { MyProfileFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useTenantApplicationContext } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '../'

export type MyProfileStepStepProps = StepProps

const MyProfileStep: React.FC<MyProfileStepStepProps> = ({ onClickNext, ...props }) => {
  const { isLoading, tenantApplication, setTenantApplication } = useTenantApplicationContext()
  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const handleSubmit = async (payload: AuthenticatedApi.Schema.UpdateTenantApplicationProfilePayload) =>
    apiClient.updateProfile({ payload })

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      formName='TenantApplicationProfileStep'
      formOptions={{
        resolver: yupResolver(AuthenticatedApi.Schema.updateTenantApplicationProfilePayloadValidator),
        defaultValues: tenantApplication ? tenantApplication : undefined,
      }}
      onSubmit={handleSubmit}
      apiOptions={{
        onSuccess: (res) => {
          setTenantApplication(res)
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step isSubmitting={isSubmitting} {...props}>
          <MyProfileFormContent
            tenantApplication={tenantApplication}
            schema={AuthenticatedApi.Schema.updateTenantApplicationProfilePayloadValidator}
          />
        </Step>
      )}
    </ApiForm>
  )
}

export { MyProfileStep }
