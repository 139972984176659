import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { PageMetaTags } from '@guiker/react-framework'

const CountryScreen: React.FC = () => {
  return (
    <>
      <PageMetaTags noIndex />
      <PageNotFound />
    </>
  )
}

export { CountryScreen }
