import { NAV_CATEGORIES } from '../config'
import { getApiClient } from './get-api-client'

export const getFilteredCategories = async () => {
  const apiClient = getApiClient()

  const categories = await apiClient.readAllCategories({})

  return NAV_CATEGORIES.filter((slug) => categories.body.find((category) => category.slug === slug).count > 0)
}
