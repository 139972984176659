import React from 'react'

import { ColumnConfig, Link, TextButton } from '@guiker/components-library'
import { Contract } from '@guiker/contract-shared'

import { useDateFormatter, useT } from '../i18n'

export const useGetContractTableColumns = (): ColumnConfig<Contract>[] => {
  const { tMain } = useT({ screenName: 'screens.documents' })
  const { formatDate } = useDateFormatter()

  return [
    {
      label: tMain(`filename`),
      name: 'displayName',
      size: 3,
      renderValue: (contract) => {
        return contract.displayName
      },
    },
    {
      label: tMain(`createdAt`),
      name: 'createdAt',
      size: 1,
      renderValue: (contract) => {
        return formatDate(contract.createdAt)
      },
    },
    {
      name: 'action',
      size: 0.5,
      renderValue: (contract) => {
        return (
          <Link to={contract.publicReadUrlResult.url} isExternalLink target='_blank'>
            <TextButton size='small'>{tMain(`viewFile`)}</TextButton>
          </Link>
        )
      },
    },
  ]
}
