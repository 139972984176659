import { RouteConfig } from '@guiker/router'

import { PayInMethod, PaymentContributions, ReconnectEFT } from '../screens'

type Routes = {
  [key: string]: RouteConfig & {
    routes?: Routes
  }
}

const defaultRouteConfig: Partial<RouteConfig> = {
  exact: true,
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes: Routes = {
  Root: {
    ...defaultRouteConfig,
    requireAuthentication: false,
    requireAccountActivation: false,
    exact: false,
    path: 'contribution/*',
    routes: {
      Contribution: {
        ...defaultRouteConfig,
        path: '/',
        Component: PaymentContributions,
      },
      PayInMethod: {
        ...defaultRouteConfig,
        path: 'method',
        Component: PayInMethod,
      },
    },
  },
  ReconnectEFT: {
    ...defaultRouteConfig,
    path: 'reconnect',
    Component: ReconnectEFT,
  },
} as const

export { routes }
