import React from 'react'

import { GlosseryDrawerContextProvider } from '@guiker/glossery-drawer'
import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import { InvestorProfile, StockOrderStatus, webappRoutes } from '@guiker/propsharing-shared'
import {
  ApiFormStepContextProvider,
  StepperLayout,
  theme,
  useApiFormStepContext,
  useNavigate,
} from '@guiker/react-framework'

import { useTranslation } from '../../i18n'
import { getSteps } from './get-steps'

const Content: React.FC = () => {
  const { t } = useTranslation(['common', 'common-propsharing'])
  const { steps } = useApiFormStepContext<InvestorProfile>()
  const { apiClient } = useAuthenticatedPropSharingInvestorProfileContext()
  const navigate = useNavigate()

  const title = t('main-propsharing:screens.createInvestorProfileScreen.title')
  const onClose = () => navigate('../')
  const onLastStepClicked = async () => {
    const inquiries = await apiClient.readAllInquiries({})
    const stockOrder = inquiries.find(({ stockOrder }) => stockOrder?.status === StockOrderStatus.CREATED)?.stockOrder
    const path = stockOrder ? webappRoutes.stockOrder.byId(stockOrder.id).checkout.path : '/my-investments'
    navigate(path)
  }

  return (
    <StepperLayout
      asTakeover
      steps={steps}
      pageLayoutProps={{
        hasButtonContainer: true,
        withSideMenu: true,
        sideMenuProps: {
          width: 300,
        },
        maxWidth: theme.dimensions.pageLayout.maxWidth.desktop,
      }}
      takeoverProps={{
        onClose,
        onBack: undefined,
      }}
      onLastStepClicked={onLastStepClicked}
      nextLabel={t('common:next')}
      backLabel={t('common:back')}
      title={title}
    />
  )
}

export const CreateInvestorProfileScreen: React.FC = () => {
  const { t } = useTranslation(['common-propsharing'])
  const { data } = useAuthenticatedPropSharingInvestorProfileContext()
  const steps = getSteps(t)
  const formName = 'CreateInvestorProfile'

  return (
    <ApiFormStepContextProvider formName={formName} steps={steps} defaultValue={data}>
      <GlosseryDrawerContextProvider>
        <Content />
      </GlosseryDrawerContextProvider>
    </ApiFormStepContextProvider>
  )
}
