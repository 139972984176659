import React from 'react'

import { InvestorProfileContextProvider } from '@guiker/propsharing-context'

import { AppProvider } from './AppProvider'
import { Router } from './routes'

const App: React.FC = () => {
  return (
    <AppProvider>
      <InvestorProfileContextProvider>
        <Router />
      </InvestorProfileContextProvider>
    </AppProvider>
  )
}

export { App }
