import React from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { Booking, Participant, ParticipantType } from '@guiker/booking-shared'
import { Chip, ColumnConfig, Flex, Inline, Link, P } from '@guiker/components-library'
import { parseDateTime } from '@guiker/date'
import { useNavigate } from '@guiker/router'

const getBookingColumns = (): ColumnConfig<Booking>[] => {
  const navigate = useNavigate()

  return [
    {
      label: 'Address',
      name: 'listing',
      size: 1,
      renderValue: ({ listing }) => (
        <P mb={0}>
          {addressFormatter.printStreetInformation({
            ...listing.address,
            suite: listing.address.apartmentNumber,
            country: listing.address.countryCode,
          })}
        </P>
      ),
      collapsibleValues: ({ applicants, unitManagers }) => [
        ...applicants.map(() => ({ type: ParticipantType.APPLICANT })),
        ...unitManagers.map(() => ({ type: ParticipantType.UNIT_MANAGER })),
      ],
      renderCollapsibleValue: ({ type }: { type: ParticipantType }) => <Chip color='info'>{type.toLowerCase()}</Chip>,
    },
    {
      label: 'Status',
      name: 'status',
      size: 1.5,
      renderValue: ({ status }) => <P mb={0}>{status}</P>,
      collapsibleValues: ({ applicants, unitManagers }) => [...applicants, ...unitManagers],
      renderCollapsibleValue: ({ firstName, lastName, emailAddress }: Participant) => (
        <Flex gap={2} justifyContent='space-between'>
          <Inline>{[firstName, lastName].join(' ').trim()}</Inline>
          <Inline>{emailAddress}</Inline>
        </Flex>
      ),
    },
    {
      label: 'Last updated at',
      name: 'updatedAt',
      size: 1,
      renderValue: ({ updatedAt }) => <Inline>{parseDateTime(updatedAt).toISODate()}</Inline>,
    },
    {
      label: '',
      name: 'action',
      size: 0.4,
      renderValue: ({ id }) => (
        <Link onClick={() => navigate(`../${id}/embedded`)} target={'_blank'}>
          Open
        </Link>
      ),
    },
  ]
}

export { getBookingColumns }
