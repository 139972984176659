import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import {
  AuthenticatedPropSharingListingIdNestedRouter,
  PublicPropSharingListingIdNestedRouter,
} from '@guiker/propsharing-context'
import { useParams } from '@guiker/router'

const ListingIdNestedRouter: React.FC = () => {
  const { id } = useParams()
  const { user: currentUser } = useAuthenticationContext()

  return currentUser ? (
    <AuthenticatedPropSharingListingIdNestedRouter id={id} />
  ) : (
    <PublicPropSharingListingIdNestedRouter id={id} />
  )
}

export { ListingIdNestedRouter }
