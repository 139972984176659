import React, { useEffect } from 'react'

import { useGlosseryDrawerContext } from '@guiker/glossery-drawer'
import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import {
  AccreditedReason,
  AuthApi,
  EligibleReason,
  Financial,
  HighLowScale,
  InvestmentReason,
  InvestorProfile,
  MissingTINReason,
  TaxResidentInOtherCountry,
  TimeScale,
} from '@guiker/propsharing-shared'
import {
  ApiFormFragments,
  Checkbox,
  Collapse,
  CurrencyTextField,
  Dropdown,
  Flex,
  FormSection2,
  FormSection4,
  FragmentComponentProps,
  InfoMiniIcon,
  OneColumnGridLayout,
  PBold,
  RadioGroup,
  Step,
  StepProps,
  TextField,
  theme,
  Trans,
  useApiFormStepContext,
  useFormContext,
  useMediaQuery,
  useWatch,
} from '@guiker/react-framework'
import { currency } from '@guiker/shared-framework'

import { Paragraph, SpanDefinition } from './Typography'

const schema = AuthApi.Schema.updateInvestorProfileStepperSchema.FinancialProfile
const tPrefix = {
  common: 'common-propsharing:investorProfile.financial',
  main: 'main-propsharing:screens.createInvestorProfileScreen.step4',
}

const DrawerContent: React.FC = () => (
  <>
    <Paragraph transKey={`${tPrefix.main}.helperText.annualIncome`} id={'annualIncome'} />
    <Paragraph transKey={`${tPrefix.main}.helperText.liquidAssets`} id={'liquidAssets'} />
    <Paragraph transKey={`${tPrefix.main}.helperText.fixedAssets`} id={'fixedAssets'} />
    <Paragraph transKey={`${tPrefix.main}.helperText.liabilities`} id={'liabilities'} />
    <Paragraph transKey={`${tPrefix.main}.helperText.investmentExperience`} id={'investmentExperience'} />
    <Paragraph transKey={`${tPrefix.main}.helperText.accreditedInvestor.header`} id={'accreditedInvestor'} />
    <SpanDefinition transKey={`${tPrefix.main}.helperText.accreditedInvestor.list1`} />
    <SpanDefinition transKey={`${tPrefix.main}.helperText.accreditedInvestor.list2`} />
    <SpanDefinition transKey={`${tPrefix.main}.helperText.accreditedInvestor.list3`} />
  </>
)

const Fragment1: React.FC<FragmentComponentProps<Financial>> = ({ defaultValue, inputProps, t }) => {
  const { taxInfo } = { ...defaultValue }

  const { onOpen } = useGlosseryDrawerContext()
  const { setValue } = useFormContext()

  const taxResidentInOtherCountry = useWatch({
    name: 'taxInfo.taxResidentInOtherCountry',
    defaultValue: taxInfo?.taxResidentInOtherCountry,
  })

  useEffect(() => {
    if ([TaxResidentInOtherCountry.WithoutTIN].includes(taxResidentInOtherCountry)) {
      setValue('taxInfo.details.taxIdNumber', null)
    }
    if ([TaxResidentInOtherCountry.NotApplicable].includes(taxResidentInOtherCountry)) {
      setValue('taxInfo.details.country', null)
      setValue('taxInfo.details.reasonForNoTIN', null)
    }
  }, [taxResidentInOtherCountry])

  return (
    <FormSection2 title={t(`${tPrefix.main}.section1.title`)}>
      <OneColumnGridLayout>
        <Dropdown
          {...inputProps('investmentReason')}
          options={Object.values(InvestmentReason).map((reason) => ({
            label: t(`${tPrefix.common}.investmentReason.options.${reason}`),
            value: reason,
          }))}
        />
        <Dropdown
          {...inputProps('withdrawalTimeline')}
          helperText={t(`${tPrefix.main}.form.withdrawalTimelineDesc`)}
          options={Object.values(TimeScale)
            .filter((s) => s !== TimeScale.VeryLong)
            .map((scale) => ({
              label: t(`${tPrefix.common}.withdrawalTimeline.options.${scale}`),
              value: scale,
            }))}
        />
      </OneColumnGridLayout>

      <FormSection4
        title={t(`${tPrefix.main}.section1.balance.title`)}
        subtitle={t(`${tPrefix.main}.section1.balance.description`)}
      >
        <OneColumnGridLayout>
          <CurrencyTextField
            {...inputProps('balance.annualIncome')}
            labelAdornment={{
              node: <InfoMiniIcon onClick={(e) => onOpen(e, 'annualIncome')} />,
              align: 'baseline',
            }}
            currency={currency.CAD}
          />
          <CurrencyTextField
            {...inputProps('balance.liquidAssets')}
            labelAdornment={{
              node: <InfoMiniIcon onClick={(e) => onOpen(e, 'liquidAssets')} />,
              align: 'baseline',
            }}
            currency={currency.CAD}
          />
          <CurrencyTextField
            {...inputProps('balance.fixedAssets')}
            labelAdornment={{
              node: <InfoMiniIcon onClick={(e) => onOpen(e, 'fixedAssets')} />,
              align: 'baseline',
            }}
            currency={currency.CAD}
          />
          <CurrencyTextField
            {...inputProps('balance.liabilities')}
            labelAdornment={{
              node: <InfoMiniIcon onClick={(e) => onOpen(e, 'liabilities')} />,
              align: 'baseline',
            }}
            currency={currency.CAD}
          />
        </OneColumnGridLayout>
      </FormSection4>

      <FormSection4 title={t(`${tPrefix.main}.section1.taxInfo.title`)}>
        <OneColumnGridLayout>
          <RadioGroup
            {...inputProps('taxInfo.taxResidentInOtherCountry')}
            maxWidth={'100%'}
            options={Object.values(TaxResidentInOtherCountry).map((taxResidence) => ({
              label: t(`${tPrefix.common}.taxInfo.taxResidentInOtherCountry.options.${taxResidence}`),
              value: taxResidence,
            }))}
          />
        </OneColumnGridLayout>
        <Collapse in={!!taxResidentInOtherCountry}>
          <Flex gap={4} flexDirection='column'>
            <OneColumnGridLayout>
              {taxResidentInOtherCountry !== TaxResidentInOtherCountry.NotApplicable && (
                <TextField {...inputProps('taxInfo.details.country')} />
              )}
              {[TaxResidentInOtherCountry.WithTIN, TaxResidentInOtherCountry.NotApplicable].includes(
                taxResidentInOtherCountry,
              ) && (
                <TextField
                  {...inputProps('taxInfo.details.taxIdNumber')}
                  helperText={
                    TaxResidentInOtherCountry.NotApplicable === taxResidentInOtherCountry
                      ? t(`${tPrefix.common}.taxInfo.taxIdNumber.helperText`)
                      : undefined
                  }
                />
              )}
            </OneColumnGridLayout>
            {taxResidentInOtherCountry === TaxResidentInOtherCountry.WithoutTIN && (
              <RadioGroup
                {...inputProps('taxInfo.details.reasonForNoTIN')}
                maxWidth={'100%'}
                options={Object.values(MissingTINReason).map((reason) => ({
                  label: t(`${tPrefix.common}.taxInfo.reasonForNoTIN.options.${reason}`),
                  value: reason,
                }))}
              />
            )}
          </Flex>
        </Collapse>
        <Checkbox maxWidth={'100%'} {...inputProps('taxInfo.hasCertifiedDetails')} />
      </FormSection4>
    </FormSection2>
  )
}

const Fragment2: React.FC<FragmentComponentProps<Financial>> = ({ inputProps, t }) => {
  const { onOpen } = useGlosseryDrawerContext()
  return (
    <FormSection2 title={t(`${tPrefix.main}.section2.title`)} subtitle={t(`${tPrefix.main}.section2.subtitle`)}>
      <OneColumnGridLayout>
        <Dropdown
          {...inputProps('investmentExperience')}
          labelAdornment={{
            node: <InfoMiniIcon onClick={(e) => onOpen(e, 'investmentExperience')} />,
            align: 'baseline',
          }}
          options={Object.values(TimeScale).map((scale) => ({
            label: t(`${tPrefix.common}.investmentExperience.options.${scale}`),
            value: scale,
          }))}
        />
      </OneColumnGridLayout>
      <RadioGroup
        {...inputProps('investmentKnowledge')}
        maxWidth={'100%'}
        options={Object.values(HighLowScale).map((scale) => ({
          label: t(`${tPrefix.common}.investmentKnowledge.options.${scale}.label`),
          description: t(`${tPrefix.common}.investmentKnowledge.options.${scale}.description`),
          value: scale,
        }))}
      />
      <RadioGroup
        {...inputProps('riskAppetite')}
        maxWidth={'100%'}
        options={Object.values(HighLowScale).map((scale) => ({
          label: t(`${tPrefix.common}.riskAppetite.options.${scale}`),
          value: scale,
        }))}
      />
    </FormSection2>
  )
}

const Fragment3: React.FC<FragmentComponentProps<Financial>> = ({ defaultValue, inputProps, t }) => {
  const { investorType } = { ...defaultValue }
  const { onOpen } = useGlosseryDrawerContext()
  const accreditedReason = useWatch({ name: 'investorType.accredited', defaultValue: investorType?.accredited })

  return (
    <FormSection2
      title={t(`${tPrefix.main}.section3.title`)}
      subtitle={
        <Trans i18nKey={`${tPrefix.main}.section3.subtitle`}>
          <PBold />
        </Trans>
      }
    >
      <RadioGroup
        {...inputProps('investorType.accredited')}
        labelAdornment={{
          node: <InfoMiniIcon onClick={(e) => onOpen(e, 'accreditedInvestor')} />,
          align: 'baseline',
        }}
        maxWidth={'100%'}
        options={Object.values(AccreditedReason).map((reason) => ({
          label: t(`${tPrefix.common}.investorType.accredited.options.${reason}`),
          value: reason,
        }))}
      />
      <Collapse in={!!accreditedReason && accreditedReason === AccreditedReason.NotApplicable}>
        <RadioGroup
          {...inputProps('investorType.eligible')}
          maxWidth={'100%'}
          options={Object.values(EligibleReason).map((reason) => ({
            label: t(`${tPrefix.common}.investorType.eligible.options.${reason}`),
            value: reason,
          }))}
        />
      </Collapse>
    </FormSection2>
  )
}

export const FinancialProfile: React.FC<StepProps> = (stepProps) => {
  const { data: investorProfile, setData, formName } = useApiFormStepContext<InvestorProfile>()
  const { apiClient, refetch, data: updatedInvestorProfile } = useAuthenticatedPropSharingInvestorProfileContext()
  const { anchor, setContent, onClose } = useGlosseryDrawerContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onSubmit = (payload: AuthApi.Schema.UpdateInvestorProfileStepperSchema['FinancialProfile']) => {
    return apiClient.updateInvestorProfileStepFinancialProfile({
      payload: schema.cast(payload, { stripUnknown: true }),
    })
  }

  useEffect(() => {
    setContent(DrawerContent)
    return () => {
      onClose()
    }
  }, [])

  return (
    <Step {...stepProps} isContentDrawerOpened={!!anchor} hasButtonContainer={false}>
      <ApiFormFragments<InvestorProfile, Financial>
        isFragmented={isMobile}
        onSubmit={onSubmit}
        onClickBackFirstFragment={() => stepProps.onClickBack()}
        apiOptions={{
          onSuccess: async () => {
            await refetch()
            setData(updatedInvestorProfile)
            stepProps.onClickNext()
          },
          onSubmitWithoutChange: () => stepProps.onClickNext(),
        }}
        defaultValue={investorProfile?.financial}
        formName={`${formName}::${stepProps.name ?? 'step'}`}
        tNamespaces={['common', 'common-propsharing', 'main-propsharing']}
        tPrefix={`${tPrefix.main}.form`}
        backLabel={stepProps.backLabel}
        nextLabel={stepProps.nextLabel}
        schema={schema}
        fragments={[
          { component: Fragment1, fields: ['investmentReason', 'withdrawalTimeline', 'balance', 'taxInfo'] },
          { component: Fragment2, fields: ['investmentExperience', 'investmentKnowledge', 'riskAppetite'] },
          { component: Fragment3, fields: ['investorType'] },
        ]}
      />
    </Step>
  )
}
