import React from 'react'

import { mainPathBuilder } from '@guiker/investment-shared'
import { config as propertySaleShared } from '@guiker/property-sale-shared'
import {
  Portfolio,
  PositionQuantityPieChart,
  PositionValuePieChart,
  StockOrderTable,
} from '@guiker/propsharing-components'
import { config as propSharingConfig, StockOrderStatus } from '@guiker/propsharing-shared'
import {
  Button,
  FullScreenSpinner,
  PageSection2,
  PageSection3,
  RouterLink,
  TwoColumnsGridLayout,
  useQuery,
  useT as baseUseT,
} from '@guiker/react-framework'
import { Paginate } from '@guiker/shared-framework'
import { hasPropSharingInvestments } from '@guiker/user-metadata-shared'

import { InvestmentEmptyState, TaskSection } from '../../components'
import { useAuthenticatedPropSharingApiClient, useAuthenticationContext } from '../../hooks'
import { useT } from '../../i18n'
import { InvestmentKpis } from '../InvestmentKpis'

const statuses = [
  StockOrderStatus.FULFILLED,
  StockOrderStatus.PENDING_EXECUTION_COMPLETION,
  StockOrderStatus.PENDING_SHARE_ISSUANCE,
  StockOrderStatus.CANCELLED,
]

export const InvestmentDashboard: React.FC = () => {
  const apiClient = useAuthenticatedPropSharingApiClient()
  const { tMain } = useT({ screenName: 'investments' })
  const { user } = useAuthenticationContext()

  const {
    data: portfolio,
    isError,
    isLoading,
  } = useQuery('readOnePortfolio', () => apiClient.readOnePortfolio(), { retry: 1 })
  const fetcher = (paginate: Paginate) => apiClient.readAllStockOrders({ queryParams: { ...paginate, statuses } })

  const userHasPropSharingInvestments = hasPropSharingInvestments(user?.metadata)
  const { tShared } = baseUseT({ domain: 'task' })

  if (isLoading) return <FullScreenSpinner />

  if (isError || !portfolio || !userHasPropSharingInvestments) {
    return <InvestmentEmptyState />
  }

  return (
    <PageSection2>
      <InvestmentKpis portfolio={portfolio} />
      <TaskSection
        header={tShared('tasks.investmentTaskHeader')}
        taskContext={[propSharingConfig.context, propertySaleShared.context]}
      />
      <PageSection3
        title={tMain('portfolio.title')}
        topActions={
          <RouterLink to={mainPathBuilder.root.propSharing.path}>
            <Button>{tMain('portfolio.cta')}</Button>
          </RouterLink>
        }
      >
        {portfolio?.length > 0 && (
          <TwoColumnsGridLayout>
            <PositionValuePieChart portfolio={portfolio} />
            <PositionQuantityPieChart portfolio={portfolio} />
          </TwoColumnsGridLayout>
        )}
        <Portfolio
          portfolio={portfolio}
          emptyState={{
            label: tMain('portfolio.emptyState.label'),
            description: tMain('portfolio.emptyState.description'),
          }}
        />
      </PageSection3>
      <PageSection3 title={tMain('transactions.title')}>
        <StockOrderTable fetcher={fetcher} queryKey='readAllStockOrders' />
      </PageSection3>
    </PageSection2>
  )
}
