import React from 'react'

import { H1 } from '@guiker/components-library'
import { ErrorPage } from '@guiker/error-pages'

import { makeStyles, theme } from '../../styles'

export type NotFoundProps = {
  primaryText?: string
  secondaryText?: string
}

const useStyles = makeStyles(
  {
    root: {
      height: '100%',
      padding: theme.spacing(8),
    },
    content: {
      margin: '100px 0',
      minHeight: '100%',
    },
  },
  { name: 'NotFound' },
)

const NotFound: React.FC<NotFoundProps> = ({ primaryText, secondaryText }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ErrorPage
        className={classes.content}
        illustration={<H1>404</H1>}
        primaryText={primaryText}
        secondaryText={secondaryText}
      />
    </div>
  )
}

export { NotFound }
