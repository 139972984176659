import { initialize, useT as baseUseT } from '@guiker/i18n'

export * from '@guiker/i18n'

const i18n = initialize()

export const useT = (args: { screenName?: string; entity?: string }) => baseUseT({ ...args, domain: 'account' })
export const usePaymentT = (args: { screenName?: string; entity?: string }) => baseUseT({ ...args, domain: 'base' })

export { i18n }
