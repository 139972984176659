import React, { useEffect, useState } from 'react'

import { getLocationService, Neighbourhood } from '@guiker/base-entity'
import {
  Flex,
  H3,
  Link,
  makeStyles,
  P,
  SecondaryButton,
  theme,
  useMediaQuery,
  VerticalGrid,
} from '@guiker/react-framework'
import { webappRoutes } from '@guiker/rental-listing-shared'

import { getLocalizedCity, getLocalizedNeighbourhood, getLocalizedState, useTranslation } from '../../i18n'

type NeighbourhoodComponentProps = {
  city: string
  country: string
  state: string
  otherNeighbourhoodTitle?: boolean
}

const useStyle = makeStyles(
  {
    root: {
      maxWidth: '75%',
    },
    link: {
      marginBottom: theme.spacing(1),
    },
  },
  {
    name: 'NeighbourhoodComponent',
  },
)

const NeighbourhoodComponent: React.FC<NeighbourhoodComponentProps> = ({
  city,
  country,
  state,
  otherNeighbourhoodTitle = false,
}) => {
  const { t } = useTranslation(['main-rentalListing', 'common'])
  const classes = useStyle()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  const neighbourhoods = getLocationService().getCityNeighbourhoods(country, state, city)
  const lessNeighbourhoods = neighbourhoods.slice(0, 7)
  const [viewNeighbourhoods, setViewNeighbourhoods] = useState<Neighbourhood[]>(neighbourhoods)

  const handleExpand = () => {
    if (isExpanded) {
      setIsExpanded(false)
      setViewNeighbourhoods(lessNeighbourhoods)
    } else {
      setIsExpanded(true)
      setViewNeighbourhoods(neighbourhoods)
    }
  }

  //even if mobile, useState sets first before isMobile is properly updated
  useEffect(() => {
    setViewNeighbourhoods(isTablet ? lessNeighbourhoods : neighbourhoods)
    setIsExpanded(isTablet ? false : true)
  }, [isTablet])

  return (
    <Flex flexDirection='column' gap={4}>
      <Flex flexDirection='column' gap={2}>
        <H3 mb={0}>
          {t(
            `main-rentalListing:neighbourhoodsComponent.${
              otherNeighbourhoodTitle ? 'otherNeighbourhoodTitle' : 'title'
            }`,
            {
              city: getLocalizedCity(t, { countryCode: country, citySlug: city }),
              state: getLocalizedState(t, { countryCode: country, stateCode: state }),
            },
          )}
        </H3>
        <P color={60} mb={0}>
          {t('main-rentalListing:neighbourhoodsComponent.description')}
        </P>
      </Flex>
      <Flex flexDirection='column'>
        <VerticalGrid
          gridRowGap={1}
          gridColumnGap={2}
          display='inline-grid'
          columnsPerBreakpoints={{ lg: 3, md: 2, sm: 2 }}
        >
          {viewNeighbourhoods.map((neighbourhood) => (
            <Link
              key={neighbourhood.slug}
              underline={false}
              className={classes.link}
              to={
                webappRoutes.listings
                  .countryNested(country)
                  .cityNested(city, state)
                  .neighbourhoodNested(neighbourhood.slug).path
              }
            >
              {getLocalizedNeighbourhood(t, {
                countryCode: country,
                citySlug: city,
                neighbourhoodSlug: neighbourhood.slug,
              })}
            </Link>
          ))}
        </VerticalGrid>
        {isTablet && (
          <Flex width='100%' justifyContent='center' mt={2}>
            <SecondaryButton onClick={handleExpand}>
              {t(`common:actions.${isExpanded ? 'seeLess' : 'seeMore'}`)}
            </SecondaryButton>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export { NeighbourhoodComponent }
