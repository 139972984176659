import React from 'react'

import { AnyColor, clsx, makeStyles, theme } from '../../../style'
import { Box, Divider, H4 } from '../../'

type HeaderDescriptionBarProps = {
  className?: string
  index: string
  description: string
  color?: string
}

const useStyles = makeStyles({
  root: {
    backgroundColor: ({ color }: { color: AnyColor }) => theme.palette.getColor(color),
  },
  divider: {
    backgroundColor: 'transparent',
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 22,
    lineHeight: '31.95px',
    marginBottom: 0,
  },
})

export const HeaderDescriptionBar: React.FC<HeaderDescriptionBarProps> = (props: HeaderDescriptionBarProps) => {
  const { className, index, description, color = 'info', ...others } = props

  const classes = useStyles({ color })

  return (
    <Box
      px={2}
      py={1}
      mb={3}
      display='flex'
      flexDirection='row'
      className={clsx([className, classes.root])}
      width='100%'
      {...others}
    >
      <Box display='flex' alignItems='flex-start'>
        <H4 className={classes.text}>{index}</H4>
      </Box>
      <Box pl={1}>
        <Divider className={classes.divider} orientation='vertical' />
      </Box>
      <Box display='flex' alignItems='center'>
        <H4 className={classes.text}>{description}</H4>
      </Box>
    </Box>
  )
}
