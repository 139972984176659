import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { LegacyIFrame } from '@guiker/legacy-iframe'
import { checkCapabilities, payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { Flex, useParams } from '@guiker/react-framework'
import { CountryCode } from '@guiker/shared-framework'
import { getRegion } from '@guiker/user-metadata-shared'

import { PayoutMethodNestedContextProvider, useAuthenticationContext, usePayoutMethodNestedContext } from '../../hooks'
import { useT } from '../../i18n'
import { PayoutMethodInfo } from './PayoutMethodInfo'

const PayoutMethodScreenContent: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.payoutMethod' })
  const { tMain: tNavigation } = useT({ screenName: 'sideMenu.navItem' })
  const { isFetching, payoutMethod } = usePayoutMethodNestedContext()
  const { user } = useAuthenticationContext()
  const region = getRegion(user?.metadata)

  if (isFetching || !payoutMethod) {
    return <AccountPageLayout isLoading />
  }

  return (
    <AccountPageLayout
      breadcrumbItems={[
        { label: tNavigation('wallet') },
        { to: '../../', label: tMain('payoutPreferences') },
        { to: '/', label: payoutMethodInfoBuilder(payoutMethod).getDisplay() },
      ]}
    >
      <Flex flexDirection='column' gap={3}>
        {region === CountryCode.CA && !checkCapabilities.hasOnly.investment(payoutMethod.capabilities) ? (
          <LegacyIFrame src={`/myaccount/listings/transactions`} />
        ) : (
          <PayoutMethodInfo payoutMethod={payoutMethod} />
        )}
      </Flex>
    </AccountPageLayout>
  )
}

const PayoutMethodScreen: React.FC = () => {
  const { id } = useParams()

  return (
    <PayoutMethodNestedContextProvider id={id}>
      <PayoutMethodScreenContent />
    </PayoutMethodNestedContextProvider>
  )
}

export { PayoutMethodScreen }
