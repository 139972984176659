import React, { useMemo } from 'react'

import { AuthBulkAssetUploaderContextProvider, useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { getApplicationStatusAfterSubmit } from '@guiker/booking-components'
import { ApplicationStatusGroup } from '@guiker/booking-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { SupportingDocumentsContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi, buildAssetGroups, TenantApplication } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useBookingApplicationContext } from '../../hooks'
import { useBookingApiClient } from '../../lib'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '..'

export type SupportingDocumentsStepProps = StepProps

const SupportingDocumentsStepContent: React.FC<SupportingDocumentsStepProps> = ({ onClickNext, ...props }) => {
  const { booking, bookingApplication, refetchBooking, setBookingApplication } = useBookingApplicationContext()
  const { bulkUpdate, isUploading } = useBulkAssetUploaderContext()
  const bookingApiClient = useBookingApiClient()
  const apiClient = useAuthenticatedTenantApplicationApiClient()
  const resolver = yupResolver(AuthenticatedApi.Schema.updateTenantApplicationSupportingDocumentsPayloadValidator)

  const submitApplication = async () =>
    bookingApiClient.submitBookingApplication({
      pathParams: { bookingId: booking.id, applicantUserId: bookingApplication.userId },
      payload: { leasePromise: null },
    })

  const handleSubmit = async (payload: unknown) => {
    const tenantApplication = await apiClient.updateSupportingDocuments({
      payload: AuthenticatedApi.Schema.updateTenantApplicationSupportingDocumentsPayloadValidator.cast(payload, {
        stripUnknown: true,
      }),
    })
    !ApplicationStatusGroup.REVIEWED.includes(bookingApplication?.status) && (await submitApplication())
    return tenantApplication
  }

  return (
    <ApiForm
      formName='TenantApplicationSupportingDocumentStep'
      formOptions={{
        resolver,
        defaultValues: bookingApplication?.content as TenantApplication,
      }}
      onSubmit={handleSubmit}
      beforeValidation={() => bulkUpdate()}
      apiOptions={{
        onSuccess: (res) => {
          refetchBooking()
          setBookingApplication({
            ...bookingApplication,
            content: res,
            status: getApplicationStatusAfterSubmit(bookingApplication?.status),
          })
          onClickNext()
        },
        onSubmitWithoutChange: () => {
          !ApplicationStatusGroup.REVIEWED.includes(bookingApplication.status) && submitApplication()
          refetchBooking()
          onClickNext()
        },
      }}
    >
      {({ formState: { isSubmitting, isSubmitSuccessful, errors } }) => (
        <Step isSubmitting={isUploading || isSubmitting || isSubmitSuccessful} errors={errors} {...props}>
          <SupportingDocumentsContent defaultValue={bookingApplication.content.supportingDocuments} readOnly={false} />
        </Step>
      )}
    </ApiForm>
  )
}

const SupportingDocumentsStep: React.FC<SupportingDocumentsStepProps> = (props) => {
  const { isLoading, bookingApplication } = useBookingApplicationContext()

  if (isLoading) {
    return <FullScreenSpinner />
  }

  const assetGroups = useMemo(
    () =>
      buildAssetGroups((bookingApplication?.content as TenantApplication)?.supportingDocuments, 'supportingDocuments'),
    [bookingApplication],
  )

  if (!assetGroups) {
    return <FullScreenSpinner />
  }

  return (
    <AuthBulkAssetUploaderContextProvider
      assetGroups={assetGroups}
      scope={{
        type: 'TenantApplication',
        id: (bookingApplication?.content as TenantApplication)?.id,
      }}
    >
      <SupportingDocumentsStepContent {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}

export { SupportingDocumentsStep }
