import React from 'react'

import { PayoutMethod, PayoutMethodType } from '@guiker/payout-shared'
import { FormSection, RadioGroup, useTranslation } from '@guiker/react-framework'

export type SelectTypeFormContentProps = {
  defaultValue: Partial<PayoutMethod>
  readOnly: boolean
  title?: string
}

const SelectTypeFormContent: React.FC<SelectTypeFormContentProps> = ({ defaultValue, readOnly, title }) => {
  const { t } = useTranslation(['main-payout'])

  return (
    <FormSection hasDivider title={title}>
      <RadioGroup
        name='type'
        defaultValue={defaultValue?.type}
        readOnly={readOnly}
        options={[
          {
            value: PayoutMethodType.CANADIAN_BANK_ACCOUNT,
            label: t('main-payout:selectAccountHolderType.bankAccountCountry.options.CA'),
          },
          {
            value: PayoutMethodType.US_BANK_ACCOUNT,
            label: t('main-payout:selectAccountHolderType.bankAccountCountry.options.US'),
          },
        ]}
      />
    </FormSection>
  )
}

export { SelectTypeFormContent }
