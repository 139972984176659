import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { LegacyIFrame } from '@guiker/legacy-iframe'
import { PageLayout, theme, toPx } from '@guiker/react-framework'
import { canAccessRentalListing } from '@guiker/user-metadata-shared'

import { RentalEmptyState } from '../../components/RentalEmptyState'
import { useSideMenuItems } from '../../hooks'

type Props = {}

export const RentalListingsScreen: React.FC<Props> = () => {
  const { user } = useAuthenticationContext()
  const sideMenuNavItems = useSideMenuItems(user?.metadata)

  return (
    <PageLayout sideMenuProps={{ items: sideMenuNavItems }}>
      {canAccessRentalListing(user?.metadata) ? (
        <LegacyIFrame
          src={`/myaccount/listings/manage-embedded`}
          height={`calc(100vh - ${toPx(theme.dimensions.appBar.height.desktop + theme.spacing(16))})`}
        />
      ) : (
        <RentalEmptyState />
      )}
    </PageLayout>
  )
}
