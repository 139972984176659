import React from 'react'

import { useTranslation } from '../../hooks'
import { NotFound } from '../NotFound'

const CategoryNotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <NotFound
      primaryText={t('main-blog:errors.categoryNotFound.primaryText')}
      secondaryText={t('main-blog:errors.categoryNotFound.secondaryText')}
    />
  )
}

export { CategoryNotFound }
