import { yup } from '@guiker/yup-util'

export const checkoutStockOrderSchema = yup.object({
  payInMethodId: yup.string().required(),
  share: yup.object({
    quantity: yup.number().required(),
  }),
  consent: yup.object({
    isConsentGiven: yup.boolean().oneOf([true], 'The risk acknowledgement must be accepted.').required(),
  }),
})

export type CheckoutStockOrderSchema = yup.InferType<typeof checkoutStockOrderSchema>
