import React from 'react'

import { clsx } from '@guiker/clsx'
import { padding } from '@guiker/components-core'
import { Box, makeStyles, P, PBold, theme } from '@guiker/components-library'
import { compareDate, DateTime, parseDateTime } from '@guiker/date'
import { InfoTriangle } from '@guiker/icons'
import { currency as BaseCurrency, money } from '@guiker/money'
import { Payer, RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'

import { useBookingContext } from '../hooks'
import { Trans, useTranslation } from '../i18n'

const useStyles = makeStyles({
  statusContainer: {
    maxWidth: 450,
    border: `1px solid ${theme.palette.grey[10]}`,
    padding: padding(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: `${theme.spacing(4)}px`,
    alignItems: 'center',
  },
  statusLeft: {
    width: '30%',
  },
  statusRight: {
    marginLeft: theme.spacing(2),
    width: `calc(70% - ${theme.spacing(2)}px)`,
  },
  outstanding: {
    display: 'flex',
    fontWeight: 'bold',
    color: theme.palette.status.alert.main,
  },
  infoTriangleIcon: {
    margin: `auto ${theme.spacing(1)}px auto 0`,
  },
})

type Prop = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
}

const RentPaymentStatus: React.FC<Prop> = ({ rentPaymentsPlan, payer }) => {
  const { t } = useTranslation(['common'])
  const classes = useStyles()
  const { booking } = useBookingContext()

  const { tenantInstalments, total } = rentPaymentsPlan

  const rent = money.fromAmount(payer.amount, BaseCurrency[total.currency]).toString()

  const outstandingTenantInstalments = tenantInstalments.filter(
    ({ status, chargeDate, events }) =>
      (['CREATED', 'FAILED'].includes(status) || (status === 'PROCESSING' && events?.length === 0)) &&
      DateTime.local() > parseDateTime(chargeDate),
  )
  const outstandingAmount = outstandingTenantInstalments.reduce((prev, current) => {
    const { processedWith } = { ...current }
    const amount = processedWith ? processedWith.amount : payer.amount
    return prev + amount
  }, 0)
  const outstandingBalance = money.fromAmount(outstandingAmount, BaseCurrency[total.currency]).toString()

  const { from, to } = booking.info.period
  const lease = `${parseDateTime(from).toFormat('MMM dd yyyy')} - ${parseDateTime(to).toFormat('MMM dd yyyy')}`
  const { months, days } = compareDate(to).getDifference(DateTime.local(), ['months', 'days'])
  const monthToDate = `${months && `${months} ${t('common:duration.month_plural')} `}${
    days && `${days} ${t('common:duration.day_plural')}`
  }`

  const details = { lease, monthToDate, rent, outstanding: outstandingBalance }

  return (
    <Box className={classes.statusContainer}>
      {Object.keys(details).map((key: string) => (
        <Trans
          i18nKey={`main-bookingRentPayment:components.rentPaymentStatus.${key}`}
          values={{ [key]: details[key] }}
          key={`rentPaymentStatus-${key}`}
        >
          <PBold mb={0} className={classes.statusLeft} />
          {key === 'outstanding' ? (
            <P
              mb={0}
              className={clsx([classes.statusRight, { [classes.outstanding]: !!outstandingTenantInstalments.length }])}
            >
              {outstandingTenantInstalments.length && (
                <InfoTriangle color='alert' className={classes.infoTriangleIcon} />
              )}
            </P>
          ) : (
            <P mb={0} className={classes.statusRight} />
          )}
        </Trans>
      ))}
    </Box>
  )
}

export { RentPaymentStatus }
