import { ConfirmListingInquiryScreen } from '../screens'
import { ListingInquiryNestedRouter } from './ListingInquiryNestedRouter'

const routes = {
  listingInquiryNested: {
    path: '/',
    Component: ListingInquiryNestedRouter,
    routes: {
      confirm: {
        path: 'confirm',
        exact: true,
        Component: ConfirmListingInquiryScreen,
      },
    },
  },
} as const

export { routes }
