import React from 'react'

import { PublicPropertySaleListingIdNestedRouter } from '@guiker/property-sale-context'
import { useParams } from '@guiker/router'

const ListingIdNestedRouter: React.FC = () => {
  const { id } = useParams()

  return <PublicPropertySaleListingIdNestedRouter id={id} />
}

export { ListingIdNestedRouter }
