import React from 'react'

import { OntarioSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'

import { useLeaseApiClient, useOntarioLeaseContext } from '../../../hooks'
import { FormSection1, Step, StepProps } from '../../'
import { FormContent } from './FormContent'

type Props = StepProps

export const StepH: React.FC<Props> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { additionalTerms },
    },
  } = useOntarioLeaseContext()

  const onSubmit = (payload: OntarioSchemas.AdditionalTerms) =>
    apiClient.updateOntarioAdditionalTerms({
      pathParams: { leaseId },
      payload,
    })

  return (
    <Step
      hasBackButton={true}
      formOptions={{
        resolver: yupResolver(OntarioSchemas.additionalTerms),
        defaultValues: { additionalTerms },
      }}
      formName={'OntarioLeaseFormStepH'}
      onSubmit={onSubmit}
      {...props}
    >
      <FormSection1>
        <FormContent additionalTerms={additionalTerms} />
      </FormSection1>
    </Step>
  )
}
