import React from 'react'

import { PayoutMethod } from '@guiker/payout-shared'
import { optionalConcat } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { Chip } from '..'

type Prop = {
  payoutMethod: PayoutMethod
}
const CapabilityChip: React.FC<Prop> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'components.listPayoutMethods.capabilities' })

  if (!payoutMethod.capabilities?.length) return

  return (
    <Chip size='small' color={'positive'} variant='outlined'>
      {`${tMain(`label`)} | ${optionalConcat(
        payoutMethod.capabilities.map((c) => tMain(c)),
        ', ',
      )}`}
    </Chip>
  )
}

export { CapabilityChip }
