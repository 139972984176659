import React from 'react'

import { ColumnGridLayout } from '@guiker/components-library'
import { LeaseActions } from '@guiker/lease-shared'

import { useAppContext, useLeaseContext, useTranslation } from '../../../hooks'
import { Quebec as Types } from '../../../types'
import { SignatureContainer } from '../../common/SignatureContainer'

type Props = {
  name: string
  lessors: Types.Lessor[]
  lessees: Types.Lessee[]
}

export const Signatures: React.FC<Props> = ({ lessors, lessees, name }) => {
  const { t } = useTranslation(['quebecLeaseScreenB', 'common'])
  const { disabledForCurrentUser } = useAppContext()
  const { can } = useLeaseContext()

  const canSignAsLessor = can(LeaseActions.SignLeaseAsLessor)
  const canSignAsLessee = can(LeaseActions.SignLeaseAsLessee)

  return (
    <ColumnGridLayout>
      <SignatureContainer
        type='initials'
        name={name}
        readOnly={!canSignAsLessor || disabledForCurrentUser(lessors[0])}
        defaultValue={lessors[0]?.dwelling?.smokeDetectorsInitials || null}
        label={t('quebecLeaseScreenB:lessorFullName')}
      />
      {(lessees || []).map((lessee) => (
        <SignatureContainer
          key={lessee.userId}
          type='initials'
          name={name}
          readOnly={!canSignAsLessee || disabledForCurrentUser(lessee)}
          defaultValue={lessee.dwelling.smokeDetectorsInitials || null}
          label={t('quebecLeaseScreenB:lesseeFullName')}
        />
      ))}
    </ColumnGridLayout>
  )
}
