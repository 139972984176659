import { Quebec } from '@guiker/lease-shared'

export const getCurrentParticipant = ({
  lessors,
  lessees,
  userId,
}: {
  lessors: Quebec.Person[]
  lessees: Quebec.Person[]
  userId: string
}): Quebec.Person => {
  return lessors.find((lessor) => lessor.userId === userId) || lessees.find((lessee) => lessee.userId === userId)
}
