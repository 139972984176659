import React from 'react'

import { Box, makeStyles, P, PBig, theme, useTranslation } from '@guiker/react-framework'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '30vh',
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 22,
  },
  description: {
    fontSize: 16,
  },
})

const EmptyState: React.FC = () => {
  const { t } = useTranslation(['main-rentalListing'])
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <PBig className={classes.title} mb={0}>
        {t('main-rentalListing:emptyState.title')}
      </PBig>
      <P className={classes.description} color={60}>
        {t('main-rentalListing:emptyState.description')}
      </P>
    </Box>
  )
}

export { EmptyState }
