import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'

import { AllInvoicesTable, Flex, PageSection1 } from '../../components'
import { useT } from '../../i18n'

const PaymentHistoryScreen: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.paymentHistory' })
  const { tMain: tNavigation } = useT({ screenName: 'sideMenu.navItem' })

  return (
    <AccountPageLayout
      breadcrumbItems={[
        { label: tNavigation('wallet') },
        { to: '../', label: tMain('payments') },
        { to: '/', label: tMain('paymentHistory') },
      ]}
    >
      <Flex flexDirection='column' gap={3}>
        <PageSection1 title={tMain('title')}>
          <AllInvoicesTable />
        </PageSection1>
      </Flex>
    </AccountPageLayout>
  )
}

export { PaymentHistoryScreen }
