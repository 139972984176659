import React from 'react'

import { AuthBulkAssetUploaderContextProvider } from '@guiker/asset-uploader'
import { FormSectionGroup, Note } from '@guiker/components-library'
import { buildBankInformation, checkCapabilities, PayoutMethod, PayoutMethodStatus } from '@guiker/payout-shared'
import { MessagesBubbleIcon, useT } from '@guiker/react-framework'

import {
  AccountHolderForm,
  AccountHolderFormContent,
  BankInformationFormContent,
  SelectAccountHolderTypeFormContent,
  SelectCapabilitiesFormContent,
  TermsAndConditionsFormContent,
  VerificationDocumentsFormContent,
} from '../../components'
import { EditUrlContextProvider } from '../../context'
import { getPayoutMethodAssetData } from '../../utils'

type AllFormContentProps = {
  payoutMethod: PayoutMethod
  readOnly: boolean
}

const AllFormContent: React.FC<AllFormContentProps> = ({ payoutMethod, readOnly }) => {
  const { tMain } = useT({ domain: 'payout' })
  const bankInformation = buildBankInformation(payoutMethod)
  const { assetGroups, scope } = getPayoutMethodAssetData(payoutMethod)
  const isOnlyInvestment = checkCapabilities.hasOnly.investment(payoutMethod.capabilities)

  const isMutable = [
    PayoutMethodStatus.CHANGE_REQUESTED,
    PayoutMethodStatus.CREATED,
    PayoutMethodStatus.PENDING_SUBMISSION,
  ].includes(payoutMethod.status)

  return (
    <EditUrlContextProvider url={isMutable ? './edit' : undefined}>
      <FormSectionGroup>
        {payoutMethod?.review?.comment && payoutMethod.status === PayoutMethodStatus.CHANGE_REQUESTED && (
          <Note
            icon={<MessagesBubbleIcon />}
            title={tMain('payoutMethodScreen.review')}
            statusColor='warning'
          >{`"${payoutMethod?.review?.comment}"`}</Note>
        )}
        <SelectAccountHolderTypeFormContent
          defaultValue={payoutMethod}
          readOnly={readOnly}
          title={tMain('payoutMethodScreen.accountHolderType.subtitle')}
        />
        {readOnly ? (
          <AccountHolderFormContent
            defaultValue={payoutMethod}
            addresses={payoutMethod.accountHolder.addresses}
            readOnly={readOnly}
            title={tMain('payoutMethodScreen.accountHolderForm.subtitle')}
          />
        ) : (
          <AccountHolderForm
            defaultValue={payoutMethod}
            readOnly={readOnly}
            title={tMain('payoutMethodScreen.accountHolderForm.subtitle')}
          />
        )}
        {!isOnlyInvestment && (
          <SelectCapabilitiesFormContent
            defaultValue={payoutMethod}
            readOnly={readOnly}
            title={tMain('payoutMethodScreen.capabilitiesForm.title')}
          />
        )}
        <BankInformationFormContent
          defaultValue={{ ...payoutMethod, bankInformation }}
          readOnly={readOnly}
          title={tMain('payoutMethodScreen.bankInformation.subtitle')}
        />
        {(!isOnlyInvestment || (isOnlyInvestment && !!payoutMethod.verificationDocuments.length)) && (
          <AuthBulkAssetUploaderContextProvider assetGroups={assetGroups} scope={scope}>
            <VerificationDocumentsFormContent
              defaultValue={payoutMethod}
              readOnly={readOnly}
              title={tMain('payoutMethodScreen.verificationDocuments.subtitle')}
            />
          </AuthBulkAssetUploaderContextProvider>
        )}
        {!isOnlyInvestment && (
          <TermsAndConditionsFormContent
            readOnly={readOnly}
            title={tMain('payoutMethodScreen.termsAndConditions.subtitle')}
          />
        )}
      </FormSectionGroup>
    </EditUrlContextProvider>
  )
}

export { AllFormContent }
