import React from 'react'

import { AppProvider } from './AppProvider'
import { Router } from './routes'

const App: React.FC<React.PropsWithChildren> = () => {
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  )
}

export { App }
