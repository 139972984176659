import React from 'react'

import { yupResolver } from '@guiker/react-hook-form'
import { CurrentAddressFormContent } from '@guiker/tenant-application-components'
import { AuthenticatedApi } from '@guiker/tenant-application-shared'

import { useAuthenticatedTenantApplicationApiClient, useTenantApplicationContext } from '../../hooks'
import { ApiForm, FullScreenSpinner, Step, StepProps } from '../'

type CurrentAddressStepProps = StepProps
const CurrentAddressStep: React.FC<CurrentAddressStepProps> = ({ onClickNext, ...props }) => {
  const { isLoading, tenantApplication, setTenantApplication } = useTenantApplicationContext()

  const apiClient = useAuthenticatedTenantApplicationApiClient()

  const resolver = yupResolver(AuthenticatedApi.Schema.updateTenantApplicationCurrentAddressOccupationPayloadValidator)
  const handleSubmit = async (
    payload: AuthenticatedApi.Schema.UpdateTenantApplicationCurrentAddressOccupationPayloadValidator,
  ) =>
    await apiClient.updateAddressAndOccupation({
      payload: AuthenticatedApi.Schema.updateTenantApplicationCurrentAddressOccupationPayloadValidator.cast(payload),
    })

  if (isLoading) {
    return <FullScreenSpinner />
  }

  return (
    <ApiForm
      formName='TenantApplicationCurrentAddressStep'
      formOptions={{
        resolver,
        defaultValues: tenantApplication,
      }}
      onSubmit={handleSubmit}
      apiOptions={{
        onSuccess: (res) => {
          setTenantApplication(res)
          onClickNext()
        },
        onSubmitWithoutChange: onClickNext,
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <Step isSubmitting={isSubmitting} {...props}>
          <CurrentAddressFormContent
            tenantApplication={tenantApplication}
            schema={AuthenticatedApi.Schema.updateTenantApplicationCurrentAddressOccupationPayloadValidator}
            {...props}
          />
        </Step>
      )}
    </ApiForm>
  )
}

export { CurrentAddressStep }
