import React from 'react'

import {
  ApiForm,
  ButtonWithLoader,
  CLDropdown,
  Flex,
  FormSection3,
  FullScreenSpinner,
  isAtMostTablette,
  isMobile,
  Link,
  makeStyles,
  P,
  PBold,
  SecondaryButton,
  theme,
  Trans,
  useMediaQuery,
  useT,
} from '@guiker/react-framework'
import { RentalOptionLabel } from '@guiker/rental-option-dropdown'
import { compareDate, DateTime, money, parseDateTime } from '@guiker/shared-framework'

import { Sticky } from '../../../components'
import { useConfirmListingInquiryScreenContext } from '../../../context'

const useStyles = makeStyles({
  bullet: {
    fontSize: `${theme.spacing(5)}px`,
    fontWeight: 700,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  point: {
    fontSize: '22px',
    [isMobile]: {
      fontSize: '18px',
    },
  },
  button: {
    [isAtMostTablette]: {
      width: '100%',
    },
  },
  buttonContainer: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
})

type BulletPointProp = {
  i18nKey: string
  values: {}
}

type ConfirmListingDetailProp = {
  primaryButtonCta?: string
  withPrice?: boolean
}

const BulletPoint: React.FC<BulletPointProp> = ({ i18nKey, values }) => {
  const classes = useStyles()

  return (
    <Flex height='fit-content'>
      <PBold className={classes.bullet}>{'· '}</PBold>
      <P className={classes.point}>
        <Trans i18nKey={i18nKey} values={values}>
          <PBold m={0} className={classes.point} component='span' />
        </Trans>
      </P>
    </Flex>
  )
}

const ConfirmListingDetail: React.FC<ConfirmListingDetailProp> = ({ primaryButtonCta, withPrice = false }) => {
  const { listing, createListingInquiry, isLoading, rentalOptions, selectedOption, setSelectedOptionId } =
    useConfirmListingInquiryScreenContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { tMain: tMainInquiry } = useT({ domain: 'rentalListingInquiry' })
  const { tMain, tShared, t } = useT({ domain: 'rentalListing' })

  if (!selectedOption || isLoading) return <FullScreenSpinner />

  const { roomCount, priceOption, unitAmenities } = selectedOption
  const { buildingAmenities } = listing

  const tPrefix = 'components.confirmListingDetail'
  const leaseStartDate = compareDate(priceOption.availablityDate).isAfter(DateTime.local())
    ? parseDateTime(priceOption.availablityDate).toFormat('MMM dd yyyy')
    : tMainInquiry(`${tPrefix}.now`)

  return (
    <ApiForm
      formName='ConfirmListingInquiry'
      onSubmit={() => createListingInquiry(selectedOption.priceOption.legacyOptionId.toString())}
      formOptions={{}}
    >
      <FormSection3>
        {rentalOptions?.length > 1 && (
          <CLDropdown
            label={tMain('rentalOptions')}
            defaultValue={selectedOption?.priceOption?.legacyOptionId?.toString()}
            options={rentalOptions.map((rentalOption) => ({
              ...rentalOption,
              label: <RentalOptionLabel rentalOption={rentalOption} />,
            }))}
            onChange={(e) => setSelectedOptionId(e.target.value)}
          />
        )}
        <FormSection3 title={tMainInquiry(`${tPrefix}.title`)}>
          <Flex flexDirection='column' gap={2}>
            {withPrice && (
              <BulletPoint
                i18nKey={`${tPrefix}.rent`}
                values={{ amount: money.fromPrice(priceOption.price).toString('onlySymbol') }}
              />
            )}
            <BulletPoint
              i18nKey={`main-rentalListingInquiry:${tPrefix}.bedroom${roomCount.bedroom > 1 ? '_plural' : ''}`}
              values={{ bedroom: roomCount.bedroom, bathroom: roomCount.bathroom }}
            />
            <BulletPoint
              i18nKey={`main-rentalListingInquiry:${tPrefix}.duration`}
              values={{
                date: leaseStartDate,
                duration: t(`listing:durations.${priceOption.duration.unit}`, {
                  count: priceOption.duration.count,
                }),
              }}
            />
            <BulletPoint
              i18nKey={`main-rentalListingInquiry:${tPrefix}.furnished`}
              values={{
                furnished: tShared(
                  `${
                    priceOption.furnished === 'fully'
                      ? 'furnished'
                      : priceOption.furnished === 'partially'
                      ? 'partiallyFurnished'
                      : 'unfurnished'
                  }`,
                ).toLowerCase(),
              }}
            />
            <BulletPoint
              i18nKey={`${tPrefix}.parking.description`}
              values={{
                with: t(
                  `${tPrefix}.parking.${
                    buildingAmenities.indoorParking || buildingAmenities.outdoorParking ? 'with' : 'without'
                  }`,
                ),
                parking: buildingAmenities.indoorParking
                  ? tMainInquiry(`${tPrefix}.parking.indoorParking`)
                  : buildingAmenities.outdoorParking
                  ? tMainInquiry(`${tPrefix}.parking.outdoorParking`)
                  : tMainInquiry(`${tPrefix}.parking.parking`),
              }}
            />
            <BulletPoint
              i18nKey={`main-rentalListingInquiry:${tPrefix}.pets.description`}
              values={{
                allowed: tMainInquiry(
                  `${tPrefix}.pets.${
                    unitAmenities.petsAllowedLarge
                      ? 'allowed'
                      : unitAmenities.petsAllowedSmall
                      ? 'smallPets'
                      : 'notAllowed'
                  }`,
                ),
              }}
            />
          </Flex>

          <Sticky mode='bottom' disabled={!isMobile}>
            <Flex
              className={classes.buttonContainer}
              flexDirection={isMobile ? 'column-reverse' : 'row-reverse'}
              gap={2}
            >
              <ButtonWithLoader type='submit' isLoading={isLoading}>
                {primaryButtonCta ? primaryButtonCta : tMainInquiry(`${tPrefix}.primaryCTA`)}
              </ButtonWithLoader>
              <Link
                className={classes.button}
                color='secondary'
                isExternalLink
                to={`/listings/${listing.id}/tenant-preferences`}
                underline={false}
                hoverUnderline={false}
                fullWidth
              >
                <SecondaryButton className={classes.button} fullWidth>
                  {tMainInquiry(`${tPrefix}.secondaryCTA`)}
                </SecondaryButton>
              </Link>
            </Flex>
          </Sticky>
        </FormSection3>
      </FormSection3>
    </ApiForm>
  )
}

export { ConfirmListingDetail }
