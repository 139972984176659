import { RouteConfig } from '@guiker/router'

import {
  ConsentBackgroundCheck,
  CreditReport,
  NotConsentBackgroundCheck,
  Report,
  Reports,
  RequestBackgroundCheck,
  RequestSINScreen,
  RequestSSNScreen,
  SoftcheckReport,
} from '../screens'

const defaultConfig: Partial<RouteConfig> = {
  requireAuthentication: true,
  requireAccountActivation: true,
  authenticationMethod: 'user',
}

const routes = {
  reports: {
    ...defaultConfig,
    path: '/',
    Component: Reports,
  },
  report: {
    ...defaultConfig,
    path: 'report/:applicantId',
    Component: Report,
  },
  softcheckReport: {
    ...defaultConfig,
    path: 'report/:applicantId/softcheck-report',
    Component: SoftcheckReport,
  },
  creditReport: {
    ...defaultConfig,
    path: 'report/:applicantId/credit-report',
    Component: CreditReport,
  },
  requestBackgroundCheck: {
    ...defaultConfig,
    path: 'request',
    Component: RequestBackgroundCheck,
  },
  requestSIN: {
    ...defaultConfig,
    path: '/:backgroundCheckId/request-sin',
    Component: RequestSINScreen,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
  requestSSN: {
    ...defaultConfig,
    path: '/:backgroundCheckId/request-info',
    Component: RequestSSNScreen,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
  consent: {
    ...defaultConfig,
    path: '/:backgroundCheckId/consent',
    Component: ConsentBackgroundCheck,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
  notConsent: {
    ...defaultConfig,
    path: '/:backgroundCheckId/not-consent',
    Component: NotConsentBackgroundCheck,
    requireAuthentication: false,
    requireAccountActivation: false,
  },
}

export { routes }
