import React from 'react'

import { PageSection2 } from '@guiker/react-framework'
import { TaskList } from '@guiker/task-components'
import { useAuthenticatedTaskContext } from '@guiker/task-context'
import { Status } from '@guiker/task-shared'

export const TaskDashboard: React.FC = () => {
  const { getFilteredTasks } = useAuthenticatedTaskContext()
  const tasks = getFilteredTasks({ status: [Status.CREATED, Status.COMPLETED] })

  return (
    <PageSection2>
      <TaskList tasks={tasks} />
    </PageSection2>
  )
}
