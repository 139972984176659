import React from 'react'

import { StepperLayout } from '@guiker/components-library'

import { useNavigate, useQuebecLeaseContext, useTakeoverContext, useTranslation } from '../../../hooks'
import { StepConfig, StepperProps as BaseStepperProps } from '../../common'
import { PrepareAsStep } from '../prepare-as-step'
import { StepA } from '../step-a/StepA'
import { StepB } from '../step-b/StepB'
import { StepC } from '../step-c/StepC'
import { StepD } from '../step-d/StepD'
import { StepE } from '../step-e/StepE'
import { StepF } from '../step-f/StepF'
import { StepH } from '../step-h/StepH'

type Props = Omit<BaseStepperProps, 'steps' | 'nextLabel' | 'backLabel' | 'closeLabel'> & {
  skipPrepare?: boolean
}

const getSteps = (t: (s: string) => string, skipPrepare: boolean): StepConfig[] => {
  const steps = [
    {
      name: 'a',
      component: StepA,
    },
    {
      name: 'b',
      component: StepB,
    },
    {
      name: 'c',
      component: StepC,
    },
    {
      name: 'd',
      component: StepD,
    },
    {
      name: 'e',
      component: StepE,
    },
    {
      name: 'f',
      component: StepF,
    },
    {
      name: 'h',
      component: StepH,
    },
  ]

  if (skipPrepare) {
    return steps
  }

  return [
    {
      name: 'prepare',
      component: PrepareAsStep,
      label: t('quebecLease:PrepareAsStepLabel'),
    },
    ...steps,
  ]
}

export const Stepper: React.FC<Props> = ({ skipPrepare, ...props }) => {
  const { t } = useTranslation()
  const { onClose } = useTakeoverContext()
  const { lease, hasParticipantSigned } = useQuebecLeaseContext()
  const navigate = useNavigate()

  return (
    <StepperLayout
      takeoverProps={{
        onClose,
      }}
      steps={getSteps(t, skipPrepare)}
      nextLabel={t('common:next')}
      backLabel={t('common:back')}
      onLastStepClicked={() => {
        hasParticipantSigned(lease) ? navigate('../lease/feedback') : onClose()
      }}
      {...props}
    />
  )
}
