import React from 'react'

import { QuebecSchemas } from '@guiker/lease-shared'
import { yupResolver } from '@guiker/react-hook-form'
import { yup } from '@guiker/yup-util'

import { useLeaseApiClient, useQuebecLeaseContext } from '../../../hooks'
import { Step, StepProps } from '../../common'
import { FormContent } from './FormContent'

export type StepDProps = StepProps

export const StepD: React.FC<StepDProps> = (props) => {
  const apiClient = useLeaseApiClient()

  const {
    leaseId,
    lease: {
      leaseInformation: { rent },
    },
  } = useQuebecLeaseContext()

  const onSubmit = ({ rent }: { rent: QuebecSchemas.Rent }) =>
    apiClient.updateQuebecLeaseRent({ pathParams: { leaseId }, payload: rent })

  return (
    <Step
      onSubmit={onSubmit}
      formOptions={{
        resolver: yupResolver(yup.object({ rent: QuebecSchemas.rent })),
        defaultValues: { rent },
      }}
      formName={'QuebecLeaseFormStepD'}
      {...props}
    >
      <FormContent rent={rent} />
    </Step>
  )
}
