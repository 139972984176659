import React from 'react'

import { Step as BaseStep, StepProps as BaseStepProps } from '@guiker/components-library'
import { Status } from '@guiker/lease-shared'

import { useLeaseContext } from '../../../hooks'
import { ApiForm, ApiFormProps } from '../..'

export type StepProps = BaseStepProps & {
  onSubmit: ApiFormProps<unknown, unknown, unknown>['onSubmit']
  formOptions: ApiFormProps<unknown, unknown, unknown>['formOptions']
  formName: string
  skipMutation?: boolean
}

const Step: React.FC<StepProps> = ({
  onSubmit,
  formOptions,
  onClickNext,
  skipMutation = false,
  formName,
  ...props
}) => {
  const { setLease, lease } = useLeaseContext()
  const shouldForceSubmit = !lease || lease.status === Status.STARTED

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={formOptions}
      apiOptions={{
        onSuccess: (lease) => {
          setLease(lease)
          onClickNext()
        },
        skipMutation,
        onSubmitWithoutChange: shouldForceSubmit ? undefined : onClickNext,
      }}
      formName={formName}
    >
      {({ isLoading, formState: { errors } }) => <BaseStep isSubmitting={isLoading} errors={errors} {...props} />}
    </ApiForm>
  )
}

export { Step }
